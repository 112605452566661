import React, { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { Card, HorizontalStack } from "@shopify/polaris";

import api from "../../api";
import AddButton from "../../components/AddButton/AddButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import Button from "../../components/extensions/Button";
import FilterContainer from "../../components/FilterContainer/FilterContainer";
import ImportQuestionnairesButton from "../../components/ImportQuestionnairesButton/ImportQuestionnairesButton";
import QuestionnairePageSkeleton from "../../components/QuestionnairePageSkeleton/QuestionnairePageSkeleton";
import QuestionnairePreviewModal from "../../components/QuestionnairePreviewModal/QuestionnairePreviewModal";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { NEW_QUESTIONNAIRE_ID } from "../../constants/questionnaires";
import { ROUTES } from "../../constants/routes";
import useExportQuestionnaireMutation from "../../hooks/useExportQuestionnaireMutation";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useQuestionnaireRestoreMutation from "../../hooks/useQuestionnaireRestoreMutation";
import useSnackbar from "../../hooks/useSnackbar";
import { ActionType } from "../../types/Settings";
import { sortByName } from "../../utils/collectionUtils";
import { isEmptyString } from "../../utils/stringUtils";

import QuestionnaireArchiveConfirm from "./QuestionnaireArchiveConfirm";
import QuestionnairesTable from "./QuestionnairesTable";

const SettingsQuestionnairesListPage = ({ data }: AsyncPageChild<api.QuestionnaireListResponse>) => {
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<
    { questionnaire: api.Questionnaire; actionType: ActionType } | undefined
  >();

  const clearSelectedQuestionnaire = () => setSelectedQuestionnaire(undefined);

  const f = useFormatMessage();
  const features = useFeatures();
  const { isPermittedTo } = usePermissions();
  const { setSnackbar } = useSnackbar();

  const [isShowingArchivedQuestionnaires, toggleShowArchivedQuestionnaires] = useOpenClose();

  const restoreQuestionnaireMutation = useQuestionnaireRestoreMutation();
  const exportQuestionnaireMutation = useExportQuestionnaireMutation();

  const [queryText, setQueryText] = useState("");

  const hasArchivedQuestionnaires = data.questionnaires.some((questionnaire) => questionnaire.is_archived);

  const questionnairesToDisplay = useMemo(() => {
    let questionnaires = isShowingArchivedQuestionnaires
      ? data.questionnaires
      : data.questionnaires.filter((questionnaire) => !questionnaire.is_archived);

    if (!isEmptyString(queryText)) {
      questionnaires = questionnaires.filter((questionnaire) =>
        questionnaire.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
      );
    }

    return questionnaires.sort(sortByName);
  }, [isShowingArchivedQuestionnaires, data.questionnaires, queryText]);

  const handlePreview = (questionnaire: api.Questionnaire) =>
    setSelectedQuestionnaire({ questionnaire, actionType: "Preview" });

  const handleArchive = (questionnaire: api.Questionnaire) =>
    setSelectedQuestionnaire({ questionnaire, actionType: "Archive" });

  const handleRestore = (questionnaire: api.Questionnaire) => restoreQuestionnaireMutation.mutate(questionnaire);

  const handleExport = (questionnaire: api.Questionnaire) =>
    exportQuestionnaireMutation.mutate(questionnaire, {
      //  No need for translation since this action is used to move questionnaire between tenants
      onSuccess: () => setSnackbar("questionnaire exported successfully!")
    });

  const showPreviewQuestionnaireModal = selectedQuestionnaire?.actionType === "Preview";
  const showArchiveQuestionnaireModal = selectedQuestionnaire?.actionType === "Archive";

  const readonly = !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const filterQuestionnairesLabel = isShowingArchivedQuestionnaires
    ? f("questionnaire.page.filter.hide-archived-questionnaires")
    : f("questionnaire.page.filter.show-archived-questionnaires");

  return (
    <SettingsPage
      readonly={readonly}
      title={f("questionnaire.page.title")}
      subtitle={f("questionnaire.page.header.description")}
      primaryAction={
        <AddButton
          url={generatePath(ROUTES.SETTINGS_QUESTIONNAIRE_DETAILS, { id: NEW_QUESTIONNAIRE_ID })}
          disabled={readonly}
        >
          {f("questionnaire.page.add-questionnaire.button.label")}
        </AddButton>
      }
      secondaryActions={features.SETTINGS__REVIEW_CHECKLISTS_IMPORT_EXPORT ? <ImportQuestionnairesButton /> : undefined}
    >
      <FilterContainer>
        <SearchTextField
          placeholder={f("questionnaire.page.filter.placeholder")}
          value={queryText}
          clearButton
          labelHidden
          onChange={setQueryText}
          onClearButtonClick={() => setQueryText("")}
        />
      </FilterContainer>
      <Card>
        <QuestionnairesTable
          questionnaires={questionnairesToDisplay}
          queryText={queryText}
          readonly={readonly}
          isRestoringQuestionnaire={restoreQuestionnaireMutation.isLoading}
          selectedQuestionnaireId={selectedQuestionnaire?.questionnaire.id}
          onPreview={handlePreview}
          onArchive={handleArchive}
          onRestore={handleRestore}
          onExport={handleExport}
        />
      </Card>
      {hasArchivedQuestionnaires && (
        <HorizontalStack>
          <Button
            onClick={toggleShowArchivedQuestionnaires}
            disclosure={isShowingArchivedQuestionnaires ? "up" : "down"}
          >
            {filterQuestionnairesLabel}
          </Button>
        </HorizontalStack>
      )}
      {showPreviewQuestionnaireModal && (
        <QuestionnairePreviewModal
          questionnaire={selectedQuestionnaire.questionnaire}
          onClose={clearSelectedQuestionnaire}
        />
      )}
      {showArchiveQuestionnaireModal && (
        <QuestionnaireArchiveConfirm
          questionnaire={selectedQuestionnaire.questionnaire}
          onClose={clearSelectedQuestionnaire}
        />
      )}
    </SettingsPage>
  );
};

export default withAsyncPage<api.QuestionnaireListResponse>(SettingsQuestionnairesListPage, {
  queryKey: QUERIES_KEYS.QUESTIONNAIRES,
  apiFunction: api.listQuestionnaires,
  paramNames: [],
  skeleton: <QuestionnairePageSkeleton />
});
