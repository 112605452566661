import React, { useState } from "react";

import api from "../../api";
import { createNewRole } from "../../helpers/person.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData from "../../hooks/useQueryData";
import { getCustomerQueryKey } from "../../types/utilities";
import { noop } from "../../utils/util";
import FloatPrimaryButton from "../FloatPrimaryButton/FloatPrimaryButton";
import RoleModal from "../RoleModal/RoleModal";

interface AddRoleButtonProps {
  customer: api.CompanyCustomerDetailResponse;
  readonly?: boolean;
}

const AddRoleButton = (props: AddRoleButtonProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();

  const { updateQueryData } = useQueryData<api.CompanyCustomerDetailResponse>(getCustomerQueryKey(customer));
  const [modalRole, setModalRole] = useState<api.Role>();

  const openModal = () => setModalRole(createNewRole(customer.country));
  const closeModal = () => setModalRole(undefined);

  const handleCreateRole = (createdRole: api.Role) => {
    updateQueryData((queryData) => (queryData.roles = queryData.roles.concat(createdRole)));
    closeModal();
  };

  return (
    <>
      <FloatPrimaryButton onClick={openModal} disabled={readonly}>
        {f("screening.buttons.add-role")}
      </FloatPrimaryButton>
      {modalRole && (
        <RoleModal
          customer={customer}
          dataRole={modalRole}
          onClose={closeModal}
          isDeleteDisabled
          onCreate={handleCreateRole}
          onUpdate={noop}
          onDelete={noop}
        />
      )}
    </>
  );
};

export default AddRoleButton;
