import { CSSProperties } from "react";
import styled from "styled-components";

export default styled.div<{ color: CSSProperties["color"]; border?: CSSProperties["color"] }>`
  background-color: ${({ color }) => color};
  width: var(--p-space-2);
  height: 100%;
  border-radius: var(--p-border-radius-4);
  border: solid thin ${({ border }) => border || "transparent"};
`;
