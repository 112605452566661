import { EntityWithId, Optional } from "../types/utilities";

// see: https://www.30secondsofcode.org/js/s/toggle-element
export function toggleElement<T>(items: T[], item: T) {
  return items.includes(item) ? items.filter((el) => el !== item) : [...items, item];
}

/**
 * swaps elements of indexes in an array
 */
export function swapElements<T>(elements: T[], sourceIndex: number, targetIndex: number) {
  const result = [...elements];
  result[sourceIndex] = result.splice(targetIndex, 1, result[sourceIndex])[0];

  return result;
}

/**
 * replaces an item in an array using the item id and returns a new array
 */
export function updateItem<T extends Optional<EntityWithId, "id">>(items: T[], item: T): T[] {
  return items.map((exitingItem) => (exitingItem.id === item.id ? item : exitingItem));
}

/**
 * removes an item in an array using the item id and returns a new array
 */
export function removeItem<T extends EntityWithId>(items: T[], item: T): T[] {
  return items.filter((exitingItem) => exitingItem.id !== item.id);
}

/**
 *
 */
export function insertAt<T>(elements: T[], item: T, index: number): T[] {
  const clone = [...elements];

  clone.splice(index + 1, 0, item);

  return clone;
}

/**
 * returns the first element of a given items
 */
export function first<T>(items?: T[]) {
  return !Array.isArray(items) || items.length === 0 ? undefined : items[0];
}

/**
 * returns the last element of a given items
 */
export function last<T>(items?: T[]) {
  return !Array.isArray(items) || items.length === 0 ? undefined : items[items.length - 1];
}

/**
 * returns if the array is not empty
 */
export function hasContent<T>(items?: T[]) {
  return Array.isArray(items) && items.length > 0;
}

/**
 * return the difference between two arrays as a list of 2 arrays:
 * the first array has all elements that are in the first but not in the second array
 * the second array has all the elements that are in the second array but not the first array
 */
export function delta<T>(arrayA: T[], arrayB: T[]) {
  return [arrayA.filter((a) => !arrayB.includes(a)), arrayB.filter((b) => !arrayA.includes(b))];
}

/**
 * return a symmetrical difference between two arrays (all elements from both arrays that do not intersect)
 */
export function difference<T>(arrayA: T[], arrayB: T[]) {
  return arrayA.filter((x) => !arrayB.includes(x)).concat(arrayB.filter((x) => !arrayA.includes(x)));
}

export const pickIds = <T extends EntityWithId>(items: T[]) => items.map((item) => item.id);

/**
 * Check if an array includes all elements from another array
 *
 * @see https://www.30secondsofcode.org/js/s/array-includes-any-or-all-values/
 */
export function includesAllItems<T extends EntityWithId>(arrayA: T[] = [], arrayB: T[] = []) {
  if (!hasContent(arrayA) || !hasContent(arrayB)) return false;

  const array = pickIds(arrayA);
  const values = pickIds(arrayB);

  return values.every((value) => array.includes(value));
}

/**
 * convert an array of items to a map where key is calculated using keyGetter and value is a list
 * of items that have the same key
 */
export function groupBy<K, V>(items: V[], keyGetter: (input: V) => K) {
  const map = new Map<K, V[]>();

  items.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
}

/**
 * Check if two arrays intersect
 *
 * @see: https://www.30secondsofcode.org/js/s/arrays-intersect/
 */
export function intersects<T>(a: T[], b: T[]) {
  const s = new Set(b);
  return [...new Set(a)].some((x) => s.has(x));
}

/**
 *  Sort function that compares 2 items with a name property
 */
export const sortByName = (q1: { name: string }, q2: { name: string }) =>
  q1.name.toLocaleLowerCase().localeCompare(q2.name.toLocaleLowerCase());
