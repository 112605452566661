import React, { CSSProperties, ReactElement } from "react";
import {
  ActionList,
  ActionListItemDescriptor,
  ActionListSection,
  ButtonGroup,
  ButtonProps,
  HorizontalStack,
  Popover,
  StackProps,
  Text
} from "@shopify/polaris";
import { HorizontalDotsMinor } from "@shopify/polaris-icons";

import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export interface ActionsDropdownProps extends Omit<ButtonProps, "icon"> {
  items: ActionListItemDescriptor[];
  sections?: ActionListSection[];
  hideTitle?: boolean;
  hideIcon?: boolean;
  forceOpen?: boolean;
  icon?: string;
  iconWidth?: CSSProperties["width"];
  iconHeight?: CSSProperties["height"];
  title?: string;
  // the number of actions to render as individual buttons
  cutoff?: number;
  distribution?: StackProps["distribution"];
}

const ActionsDropdown = (props: ActionsDropdownProps) => {
  const {
    items = [],
    sections,
    hideTitle,
    hideIcon,
    forceOpen = false,
    icon,
    iconWidth,
    iconHeight,
    title,
    size = "slim",
    cutoff = 0,
    // distribution,
    ...buttonProps
  } = props;
  const [popoverActive, togglePopoverActive, closePopover] = useOpenClose();

  const f = useFormatMessage();

  const showButtonGroup = cutoff > 0;
  const showPopover = items.length - cutoff > 0;

  const buttonIcon = hideIcon ? undefined : icon ? (
    <Icon source={icon} width={iconWidth || "12px"} height={iconHeight || "12px"} />
  ) : (
    HorizontalDotsMinor
  );

  const actionsLink = (
    <Button icon={buttonIcon} size={size} onClick={togglePopoverActive} {...buttonProps}>
      {hideTitle ? undefined : title || f("common.buttons.actions.button")}
    </Button>
  );

  return (
    <HorizontalStack gap="2">
      {showButtonGroup && (
        <ButtonGroup>
          {items.slice(0, cutoff).map((item, index) => (
            <TooltipWrapper
              active={Boolean(item.suffix || item.helpText)}
              content={
                <HorizontalStack gap="1" blockAlign="center" wrap={false}>
                  {item.suffix}
                  <Text as="span" color="subdued">
                    {item.helpText}
                  </Text>
                </HorizontalStack>
              }
              preferredPosition={"mostSpace"}
              key={index}
            >
              <Button
                key={index}
                onClick={item.onAction}
                size={size}
                icon={item.icon || item.image || (item.prefix as ReactElement)}
                {...item}
              >
                {item.content}
              </Button>
            </TooltipWrapper>
          ))}
        </ButtonGroup>
      )}
      {showPopover && (
        <Popover
          active={popoverActive || forceOpen}
          activator={actionsLink}
          onClose={closePopover}
          ariaHaspopup={false}
          sectioned={false}
          preferredAlignment="left"
        >
          <Popover.Pane>
            <ActionList items={items.slice(cutoff)} sections={sections} onActionAnyItem={closePopover} />
          </Popover.Pane>
        </Popover>
      )}
    </HorizontalStack>
  );
};

export default ActionsDropdown;
