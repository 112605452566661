import React, { PropsWithChildren, useRef } from "react";
import { HorizontalStack } from "@shopify/polaris";
import { Cell } from "@tanstack/react-table";
import classNames from "classnames";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";
import { SELECTION_COLUMN_ID } from "../../constants/tables";
import ExpandButton from "../ExpandButton/ExpandButton";

import TreeLine from "./TreeLine";

interface TableCellProps<T> {
  cell: Cell<T, unknown>;
  columnLabel: string;
  className?: string;
  isExpandableCell?: boolean;
  showTreeLines?: boolean;
  getRowLineLabel?(row: T): JSX.Element | undefined;
}

function TableCell<T>(props: PropsWithChildren<TableCellProps<T>>) {
  const { cell, columnLabel, className, isExpandableCell, showTreeLines, getRowLineLabel, ...rest } = props;

  const { row, column } = cell;

  const classes = classNames(
    "Polaris-DataTable__Cell",
    "Polaris-DataTable__Cell--verticalAlignTop",
    "Polaris-DataTable__Cell--firstColumn",
    className
  );

  const isSelectionColumn = column.id === SELECTION_COLUMN_ID;

  const canExpand = isExpandableCell && row.getCanExpand();
  const isLeaf = isExpandableCell && !row.getCanExpand();

  // @ts-ignore
  const centered = column.columnDef.meta?.centered === true;

  const parent = row.getParentRow();

  // endNodeRef could either be of the expand/collapse button or of a leaf node
  const endNodeRef = useRef(null);

  if (isExpandableCell) {
    return (
      <StyledTableCell
        {...rest}
        className={classes}
        data-col-label={columnLabel}
        isSelection={isSelectionColumn}
        depth={row.depth}
        isExpandableCell
        centered={centered}
      >
        <HorizontalStack gap="1" blockAlign="center" wrap={false}>
          {canExpand && (
            <ExpandButton
              id={`parent-id-${row.id}`}
              ref={endNodeRef}
              expanded={row.getIsExpanded()}
              onChange={row.toggleExpanded}
            />
          )}
          {/* if the current node is a leaf - wrap it with a ref that will be passed to the tree line */}
          {isLeaf && <StyledLeafWrapper ref={endNodeRef}>{rest.children}</StyledLeafWrapper>}

          {/* if the current node is not a leaf - the Expand button would be the ref for the tree line */}
          {!isLeaf && rest.children}

          {showTreeLines && parent && (
            <TreeLine
              start={`parent-id-${parent.id}`}
              end={endNodeRef}
              labels={getRowLineLabel ? { end: getRowLineLabel(row.original) } : undefined}
              isLeaf={isLeaf}
            />
          )}
        </HorizontalStack>
      </StyledTableCell>
    );
  } else {
    return (
      <StyledTableCell
        {...rest}
        className={classes}
        data-col-label={columnLabel}
        isSelection={isSelectionColumn}
        centered={centered}
      />
    );
  }
}

const StyledTableCell = styled.td<{
  depth?: number;
  isExpandableCell?: boolean;
  isSelection: boolean;
  centered?: boolean;
}>`
  --horizontal-offset: ${({ depth }) => (depth ? `calc(${depth} * var(--kdk-table-tree-node-gap))` : undefined)};
  padding-left: ${({ isExpandableCell }) =>
    isExpandableCell ? "var(--horizontal-offset, var(--p-space-2))" : undefined};

  text-align: ${({ centered }) => (centered ? "center" : undefined)};

  // For small viewports - hide the selection (checkbox) cell, reduce cell padding, add a pseudo element
  // with the column label above the cell content
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: ${({ isSelection }) => (isSelection ? "none" : undefined)};

    padding: var(--p-space-2);

    max-width: unset;

    text-align: left;

    &::before {
      content: attr(data-col-label);
      display: block;
      color: var(--p-text-subdued);
      font-size: var(--kdk-font-size-50);
      font-weight: var(--p-font-weight-semibold);
      line-height: 1.2;
    }
  }
`;

const StyledLeafWrapper = styled.div`
  padding-left: var(--p-space-4);
`;

export default TableCell;
