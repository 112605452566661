export const PLAN_CONVERSION_PAGE = "https://www.beaufort.io/priser";

/**
 * External link for support portal
 */
export const SUPPORT_PORTAL = "https://support.beaufort.io/knowledge";

/**
 * Email address for support cases
 */
export const SUPPORT_EMAIL = "support@beaufort.io";

/**
 * Shares classes support article
 */
export const SHARES_CLASSES_SUPPORT_PAGE = "https://support.beaufort.io/knowledge/aksjeklasser";

/**
 * External link to support page for unknown owner
 */
export const UNKNOWN_OWNER_SUPPORT_PAGE =
  "https://support.beaufort.io/knowledge/uidentifiserte-eiere-fra-aksjonaerregisteret";

/**
 * External link to a trello board that contains the latest changes in KDK
 */
export const WHATS_NEW_TRELLO_BOARD =
  "https://trello.com/invite/b/XEoBlM8S/ATTI43094f2ae0add5043c39ebda9455b96eB44B6FDD/🚀-nyheter-i-kjenn-din-kunde";
