import React from "react";

import api from "../../../api";
import UsersSelect from "../../../components/UsersSelect/UsersSelect";
import { QUERIES_KEYS } from "../../../constants/queries-keys";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useQueryData from "../../../hooks/useQueryData";

interface ProjectResponsibleUsersSelectProps {
  projectId: string;
  responsibleUsers: api.User[];
  readonly?: boolean;
}

const ProjectResponsibleUsersSelect = (props: ProjectResponsibleUsersSelectProps) => {
  const { projectId, responsibleUsers, readonly } = props;

  const f = useFormatMessage();
  const { updateQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, projectId]);

  const updateResponsibleUsers = (selectedUserIds: string[]) =>
    api
      .updateProject(projectId, { responsible_users: selectedUserIds })
      .then((response) => response.responsible_users || []);

  const updateQueryCache = (responsibleUsers: api.User[]) =>
    updateQueryData((queryData) => (queryData.responsible_users = responsibleUsers));

  return (
    <UsersSelect
      selectedUsers={responsibleUsers}
      emptySelectionLabel={f("project.info.manager.empty.user")}
      updateErrorMessage={f("project.info.manager.error")}
      readonly={readonly}
      strong
      onUserSelection={updateResponsibleUsers}
      onAfterUserSelection={updateQueryCache}
    />
  );
};

export default ProjectResponsibleUsersSelect;
