import React, { useState } from "react";
import { Checkbox, HorizontalStack } from "@shopify/polaris";
import { useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getCustomerName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import { Customer } from "../../types/utilities";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

interface DeleteCustomersProps {
  customers: Customer[];
  onClose(): void;
  onSuccess(): void;
}

const DeleteCustomers = (props: DeleteCustomersProps) => {
  const { customers, onClose, onSuccess } = props;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const queryClient = useQueryClient();
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const customerIds = customers.map((customer) => customer.id);
  const customersNamesLabel = formatMultipleNames(customers.map(getCustomerName), 2);

  const handleSuccess = async () => {
    await queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMERS_LIST]);
    onSuccess();
  };

  return (
    <ConfirmAction<api.CustomerListResponse>
      apiCall={() => api.deleteCustomers({ customer_ids: customerIds })}
      onSuccess={handleSuccess}
      isActionDisabled={!confirmDelete}
      title={f("bulk.actions.delete.confirmation.title")}
      description={f("bulk.actions.delete.confirmation.description", { names: customersNamesLabel })}
      onNo={onClose}
      actionTitle={f("default.delete")}
      useSuccessToast
      successTitle={f("bulk.actions.delete.success.message", { count: customers.length })}
    >
      <HorizontalStack>
        <Checkbox
          label={f("bulk.actions.delete.confirmation.check")}
          checked={confirmDelete}
          onChange={setConfirmDelete}
        />
      </HorizontalStack>
    </ConfirmAction>
  );
};

export default DeleteCustomers;
