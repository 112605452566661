import React, { PropsWithChildren } from "react";
import { HorizontalStack } from "@shopify/polaris";
import { MutationFunction } from "@tanstack/react-query";

import useFormatMessage from "../../../../hooks/useFormatMessage";
import { isEmptyString } from "../../../../utils/stringUtils";
import ConfirmAction from "../../../ConfirmAction/ConfirmAction";
import KDKTextField from "../../../KDKTextField/KDKTextField";

export interface PersonPhoneNumberModalProps<T> {
  apiCall: MutationFunction<T>;
  phoneNumber: string;
  onChange(phoneNumber: string): void;
  onClose(): void;
  onUpdate(response: T): void;
}

function PersonPhoneNumberModal<T>(props: PropsWithChildren<PersonPhoneNumberModalProps<T>>) {
  const { apiCall, phoneNumber, onChange, onClose, onUpdate, children } = props;

  const f = useFormatMessage();

  const invalidPhoneNumber = isEmptyString(phoneNumber);

  const error = invalidPhoneNumber ? f("common.errors.invalid.empty.mobile-phone") : undefined;

  return (
    <ConfirmAction<T>
      apiCall={apiCall}
      title={f("common.labels.missing-information")}
      actionTitle={f("default.save")}
      isActionDisabled={invalidPhoneNumber}
      isDestructive={false}
      onNo={onClose}
      onSuccess={onUpdate}
    >
      {children}
      <HorizontalStack>
        <KDKTextField
          label={f("common.labels.mobile-phone")}
          value={phoneNumber}
          onChange={onChange}
          autoComplete="on"
          autoFocus
          error={error}
        />
      </HorizontalStack>
    </ConfirmAction>
  );
}

export default PersonPhoneNumberModal;
