import React from "react";
import { Card } from "@shopify/polaris";

import CompanyIcon from "../../../assets/icons/company.svg";
import MatchIcon from "../../../assets/icons/match.svg";
import PersonIcon from "../../../assets/icons/person.svg";
import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useRDCMatch from "../../hooks/useRDCMatch";
import ComparisonTable from "../ComparisonTable/ComparisonTable";
import { createComparisonTableEntry } from "../ComparisonTable/ComparisonTable.helpers";
import ComparisonTableHeader from "../ComparisonTable/ComparisonTableHeader";

type MatchDetailsComparisonSectionProps = {
  matchDetails: api.MatchCaseDetailResponse;
};

const MatchDetailsComparisonSection = (props: MatchDetailsComparisonSectionProps) => {
  const { matchDetails } = props;

  const f = useFormatMessage();
  const { match, aliases, subjectPersonBirthDate, birthdates, isSubjectPerson, hasNewInfo } = useRDCMatch(matchDetails);

  const subject = match.subject;
  const isPerson = isSubjectPerson(subject);

  const rows = [
    createComparisonTableEntry<api.MatchAlias>(
      f("common.labels.name"),
      isSubjectPerson(subject) ? getFullName(subject) : subject.name,
      aliases,
      (alias, first) => (first ? alias.name : `${alias?.type}: ${alias?.name}`),
      hasNewInfo
    )
  ];

  if (isSubjectPerson(subject)) {
    rows.push(
      createComparisonTableEntry(
        f("common.labels.birth_date"),
        subjectPersonBirthDate,
        birthdates,
        (attribute) => attribute?.value,
        hasNewInfo
      )
    );
  }

  const customerIcon = isPerson ? PersonIcon : CompanyIcon;
  const customerLabel = isPerson ? f("matches.titles.person-customer") : f("matches.titles.company-customer");

  return (
    <Card>
      <ComparisonTable
        headings={[
          <ComparisonTableHeader icon={customerIcon} label={customerLabel} />,
          <ComparisonTableHeader icon={MatchIcon} label={f("matches.titles.data-source")} />
        ]}
        rows={rows}
      />
    </Card>
  );
};

export default MatchDetailsComparisonSection;
