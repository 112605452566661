import React from "react";
import { Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useCustomerPendingTasks from "../../hooks/useCustomerPendingTasks";
import { Customer } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import CondensedText from "../CondensedText/CondensedText";
import Status from "../Status/Status";

type CaseStatusProps = {
  customer: Customer;
  haveOwnershipUpdates?: boolean;
};

const CustomerPendingTaskStatus = (props: CaseStatusProps) => {
  const { customer, haveOwnershipUpdates } = props;

  const taskStatuses = useCustomerPendingTasks(customer, haveOwnershipUpdates);

  if (taskStatuses.length === 0) {
    return <Text as="span">-</Text>;
  }

  const topTaskStatus = taskStatuses[0];

  const hasSubtitle = !isEmptyString(topTaskStatus.subtitle);

  return (
    <VerticalStack>
      <Status title={topTaskStatus.title} icon={topTaskStatus.icon} />
      {hasSubtitle && (
        <StyledTruncatedText title={topTaskStatus.subtitle}>{topTaskStatus.subtitle}</StyledTruncatedText>
      )}
    </VerticalStack>
  );
};

const StyledTruncatedText = styled(CondensedText)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default CustomerPendingTaskStatus;
