import React from "react";
import { Button, Card, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import ChevronIcon from "../../../assets/icons/chevron-right.svg";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useAcknowledgeCompanyInfoChangeMutation from "../../hooks/useAcknowledgeCompanyInfoChangeMutation";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePageNavigation from "../../hooks/usePageNavigation";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Icon from "../extensions/Icon";

type CompanyInfoValueTooltipProps = {
  customerId: string;
  infoUpdateIds: number[];
  onClose(): void;
};

const CompanyInfoValueTooltip = (props: CompanyInfoValueTooltipProps) => {
  const { customerId, infoUpdateIds, onClose } = props;

  const f = useFormatMessage();

  const { navigateInPage } = usePageNavigation();
  const acknowledgeCompanyInfoChangeMutation = useAcknowledgeCompanyInfoChangeMutation(customerId, infoUpdateIds);

  const scrollToReviews = () => navigateInPage(PAGE_ANCHORS.REVIEWS_SECTION);

  const acknowledgeChange = () => acknowledgeCompanyInfoChangeMutation.mutate(undefined, { onSuccess: onClose });

  return (
    <Card>
      <Card.Section>
        <VerticalStack gap="2">
          <Text variant="bodyMd" as="span">
            {f("company.details.changes.tooltip.banner")}
          </Text>
          <HorizontalStack align={"space-between"} blockAlign={"center"}>
            <HorizontalStack gap="1" blockAlign="center">
              <Button plain onClick={scrollToReviews} disabled={acknowledgeCompanyInfoChangeMutation.isLoading}>
                {f("company.details.changes.tooltip.button.label")}
              </Button>
              <Icon source={ChevronIcon} width="8px" height="14px" />
            </HorizontalStack>
            <Button
              outline
              size={"slim"}
              onClick={acknowledgeChange}
              loading={acknowledgeCompanyInfoChangeMutation.isLoading}
            >
              {f("common.labels.change-seen")}
            </Button>
          </HorizontalStack>
          {acknowledgeCompanyInfoChangeMutation.isError && (
            <ErrorPanel message={acknowledgeCompanyInfoChangeMutation.error} />
          )}
        </VerticalStack>
      </Card.Section>
    </Card>
  );
};

export default CompanyInfoValueTooltip;
