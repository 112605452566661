import { ActionListItemDescriptor, HorizontalStack, Text } from "@shopify/polaris";

import RejectMatchCaseIcon from "../../../assets/icons/xmark-circle.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import Icon from "../extensions/Icon";
import RejectMatchCasesConfirm from "../RejectMatchCasesConfirm/RejectMatchCasesConfirm";

type MatchCasesBulkActionsProps = {
  customerId: string;
  customerType: api.CustomerType;
  totalMatchCasesCount: number;
  selectedMatchCasesIds: string[];
  readonly?: boolean;
  onAfterSuccess(): void;
};

const MatchCasesBulkActions = (props: MatchCasesBulkActionsProps) => {
  const { customerId, customerType, totalMatchCasesCount, selectedMatchCasesIds, readonly, onAfterSuccess } = props;

  const f = useFormatMessage();

  const [showRejectConfirm, toggleRejectConfirm, closeRejectConfirm] = useOpenClose();

  const bulkActions: ActionListItemDescriptor[] = [
    {
      content: f("bulk.actions.reject-match-cases.confirmation.title"),
      prefix: <Icon source={RejectMatchCaseIcon} useMask />,
      onAction: toggleRejectConfirm,
      disabled: selectedMatchCasesIds.length === 0
    }
  ];

  return (
    <HorizontalStack gap="8" blockAlign="center">
      <Text as="span">
        {f("match-cases-section.count.label", { count: totalMatchCasesCount, selected: selectedMatchCasesIds.length })}
      </Text>
      <ActionsDropdown items={bulkActions} cutoff={1} disabled={readonly} />
      <RejectMatchCasesConfirm
        isOpen={showRejectConfirm}
        customerId={customerId}
        customerType={customerType}
        matchCasesIds={selectedMatchCasesIds}
        onClose={closeRejectConfirm}
        onAfterSuccess={onAfterSuccess}
      />
    </HorizontalStack>
  );
};

export default MatchCasesBulkActions;
