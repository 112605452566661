import React, { useState } from "react";
import { Card } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import PreviewModal from "../PreviewModal/PreviewModal";
import ReviewChecklistDuplicateModal from "../ReviewChecklistDuplicateModal/ReviewChecklistDuplicateModal";
import ReviewChecklistForm from "../ReviewChecklistForm/ReviewChecklistForm";

type ReviewChecklistCreateInstanceModalProps = {
  checklistTemplate: api.ReviewChecklist;
  onClose(): void;
};

const ReviewChecklistCreateInstanceModal = (props: ReviewChecklistCreateInstanceModalProps) => {
  const { checklistTemplate, onClose } = props;

  const f = useFormatMessage();

  const [showDuplicateModal, toggleDuplicateModal, closeDuplicateModal] = useOpenClose();
  const [previewAnswers, setPreviewAnswers] = useState<Map<string, api.ChecklistQuestionAnswerRequest>>(new Map());

  const onChange = (questionId: string, answer: boolean, text?: string) => {
    setPreviewAnswers(
      (prevState) =>
        new Map([...prevState.entries(), [questionId, { question_id: questionId, answer, additional_info: text }]])
    );
  };

  const handleClose = () => {
    closeDuplicateModal();
    onClose();
  };

  return (
    <PreviewModal
      primaryAction={{
        content: f("component.review.checklist.create-instance"),
        onAction: toggleDuplicateModal
      }}
      secondaryActions={[
        {
          content: f("default.cancel"),
          onAction: onClose
        }
      ]}
      title={f("review.checklist.template.preview.modal.title", { name: checklistTemplate.name })}
      onClose={onClose}
    >
      <Card>
        <Card.Section subdued>
          <ReviewChecklistForm checklist={checklistTemplate} answers={previewAnswers} onChange={onChange} />
        </Card.Section>
      </Card>

      {showDuplicateModal && (
        <ReviewChecklistDuplicateModal checklist={checklistTemplate} onClose={handleClose} isTemplate />
      )}
    </PreviewModal>
  );
};

export default ReviewChecklistCreateInstanceModal;
