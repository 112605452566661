import React from "react";
import { Banner, Text } from "@shopify/polaris";

import useFormatMessage from "../../../hooks/useFormatMessage";

const CompanyFinishedOwnershipUpdatesBanner = () => {
  const f = useFormatMessage();

  return (
    <Banner status="info">
      <Text variant="bodyMd" as="span" fontWeight="semibold">
        {f("ownership-update.banner.finished.description")}
      </Text>
    </Banner>
  );
};

export default CompanyFinishedOwnershipUpdatesBanner;
