import api from "../../api";
import { getPersonKey, isUBO } from "../../helpers/person.helpers";

export const isRelatedRole = (relatedData: api.RelatedRole | api.RelatedOwner): relatedData is api.RelatedRole => {
  return relatedData.result_type === "ROLE";
};

export const isRelatedSearchUBO = (searchResult: api.RelatedRole | api.RelatedOwner) =>
  !isRelatedRole(searchResult) && isUBO(searchResult);

export type RelatedSearchResultEntity = (api.RelatedRole | api.RelatedOwner) & { types: string[] };

export const getRelatedRoleOrOwnerKey = (entity: api.RelatedRole | api.RelatedOwner) => getPersonKey(entity);
