import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextFieldProps } from "@shopify/polaris";
import { OptionDescriptor } from "@shopify/polaris/build/ts/latest/src/types";

import useFormatMessage from "../../hooks/useFormatMessage";
import useGetAssignmentIndustriesAndPurposes from "../../hooks/useGetAssignmentIndustriesAndPurposes";
import { first } from "../../utils/collectionUtils";
import { filterRegEx, isEmptyString } from "../../utils/stringUtils";
import TextHighlighter from "../TextHighlighter/TextHighlighter";

type PurposeChoicesSelectProps = Omit<TextFieldProps, "onChange" | "autoComplete" | "label" | "placeholder"> & {
  label?: string;
  // indicates that the purpose value is not from the list of purposes but a free text
  hasPurposeDesc: boolean;
  // disables the option to enter free text as a purpose description thus making this component behave like a select input
  disablePurposeDescription?: boolean;
  // allows filtering the list of purposes based on their industry type
  industryType?: string;
  showDeprecatedPurposes?: boolean;
  // options to exclude from list
  excludedOptions?: string[];
  onChange(purpose?: string, purposeDescription?: string): void;
};

const PurposeChoicesSelect = (props: PurposeChoicesSelectProps) => {
  const {
    hasPurposeDesc,
    industryType,
    disablePurposeDescription,
    showDeprecatedPurposes,
    value,
    excludedOptions,
    onChange,
    ...textFieldProps
  } = props;

  const f = useFormatMessage();
  const { getPurposes, getPurposeLabel, isLoading } = useGetAssignmentIndustriesAndPurposes();

  const purposes = getPurposes(industryType, !showDeprecatedPurposes);

  // list of all available purposes options
  const deselectedOptions: OptionDescriptor[] = isLoading
    ? []
    : purposes
        .filter((purpose) => purpose.value === value || !excludedOptions?.includes(purpose.value))
        .map((purpose) => ({
          label: getPurposeLabel(purpose.value),
          value: purpose.value,
          disabled: purpose.is_deprecated
        }));

  const [selectedOptions, setSelectedOptions] = useState(value ? [value] : []);
  const [inputValue, setInputValue] = useState(() => (value ? getPurposeLabel(value) : value));
  const [options, setOptions] = useState(deselectedOptions);

  // update inputValue if industries/purposes were not loaded yet
  useEffect(() => {
    if (isLoading) return;

    if (value) {
      setInputValue(getPurposeLabel(value));
    }
  }, [isLoading, getPurposeLabel, isEmptyString(value)]);

  useEffect(() => {
    if (industryType) {
      setOptions(deselectedOptions);
      if (!value?.includes(industryType) && !hasPurposeDesc) {
        updateText("");
      }
    } else if (options.length === 0) {
      setOptions(deselectedOptions);
    }
  }, [industryType, deselectedOptions.length, isLoading]);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);
      setSelectedOptions([]);

      // do not trigger an update event if filter value changes
      if (!disablePurposeDescription) {
        onChange(undefined, value);
      }

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const resultOptions = deselectedOptions
        .filter((option) => (option.label as string).match(filterRegEx(value)))
        .map((option) => ({
          ...option,
          label: <TextHighlighter searchWords={[value]} textToHighlight={option.label} />
        }));
      setOptions(resultOptions);
    },
    [deselectedOptions, onChange]
  );

  // reset the selected value to the last known value
  const handleBlur = useCallback(() => {
    setOptions(deselectedOptions);
    updateSelection(value ? [value] : []);
  }, [value, getPurposeLabel, isLoading]);

  const updateSelection = useCallback(
    (selected: string[]) => {
      setSelectedOptions(selected);
      setInputValue(getPurposeLabel(first(selected)));
      onChange(first(selected), undefined);
    },
    [getPurposeLabel, isLoading, onChange]
  );

  const clearInput = () => {
    updateText("");
    onChange(undefined, undefined);
  };

  const placeholder = disablePurposeDescription
    ? f("customer-assignments.modal.assignment-purpose.placeholder-without-description")
    : f("customer-assignments.modal.assignment-purpose.placeholder");

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      onBlur={disablePurposeDescription ? handleBlur : undefined}
      label={f("customer-assignments.modal.assignment-purpose")}
      {...textFieldProps}
      autoComplete="off"
      placeholder={placeholder}
      value={inputValue}
      clearButton
      onClearButtonClick={clearInput}
    />
  );

  return <Autocomplete options={options} selected={selectedOptions} onSelect={updateSelection} textField={textField} />;
};

export default PurposeChoicesSelect;
