import React from "react";
import { generatePath } from "react-router-dom";
import { HorizontalStack, Stack, Text, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import CheckMarkGreenIcon from "../../../assets/icons/checkmark-green.svg";
import CrossMarkRedIcon from "../../../assets/icons/circle-cross.svg";
import api from "../../api";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getCustomerQueryKey } from "../../types/utilities";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import ReviewCard from "../ReviewCard/ReviewCard";
import Risk from "../Risk/Risk";

type AssignmentReviewsSectionProps = {
  assignment: api.CustomerAssignmentDetails;
  readonly?: boolean;
};

const AssignmentReviewsSection = (props: AssignmentReviewsSectionProps) => {
  const { assignment, readonly } = props;

  const f = useFormatMessage();

  // TODO temporary workaround for the lack of Customer Reviews not returning in the assignment
  const { data } = useQuery(getCustomerQueryKey(assignment), () => api.getCustomerDetails(assignment.customer_id));

  const customerReviews = data?.reviews.filter((review) => review.assignment?.id === assignment.id) || [];

  const reviews = [...assignment.reviews, ...customerReviews];
  const hasReviews = reviews.length > 0;

  const customerReviewPageURL = generatePath(ROUTES.CUSTOMER_ASSIGNMENT_REVIEW_NEW, {
    customerId: assignment.customer_id,
    assignmentId: assignment.id
  });

  return (
    <VerticalStack gap="4">
      <Stack distribution="equalSpacing">
        <Text variant="headingMd" as="h2">
          {f("customer-assignments.review.title")}
        </Text>
        {hasReviews && (
          <Button url={customerReviewPageURL} disabled={readonly} primary size="slim">
            {f("reviews.labels.change-review")}
          </Button>
        )}
      </Stack>

      {!hasReviews && (
        <VerticalStack gap="4" inlineAlign="center">
          <Text as="span">{f("customer-assignments.review.not-reviewed")}</Text>
          <Button url={customerReviewPageURL} primary disabled={readonly}>
            {f("customer-assignments.review.button.action")}
          </Button>
        </VerticalStack>
      )}
      {hasReviews && (
        <VerticalStack gap="4">
          {assignment.reviews.map((review) => (
            <ReviewCard
              key={review.id}
              reviewedBy={review.reviewed_by}
              reviewDate={review.created_dt}
              reviewContent={review.comment}
              reviewStatus={review.is_accepted ? "success" : "critical"}
              reviewHeader={
                <HorizontalStack gap="1" blockAlign="center">
                  <Icon source={review.is_accepted ? CheckMarkGreenIcon : CrossMarkRedIcon} />
                  <Text variant="bodyMd" as="span">
                    {review.is_accepted
                      ? f("details.reviews.assignment-review.accepted")
                      : f("details.reviews.assignment-review.rejected")}
                  </Text>
                  {review.risk_level && (
                    <Risk risk={review.risk_level} labelSuffix={f("common.labels.customer.risk")} />
                  )}
                </HorizontalStack>
              }
            />
          ))}
          {/* // TODO temporary workaround for the lack of Customer Reviews not returning in the assignment */}
          {customerReviews.map((review) => (
            <ReviewCard
              key={review.id}
              reviewedBy={review.reviewed_by}
              reviewDate={review.date}
              reviewContent={review.comment}
              reviewStatus={review.accepted ? "success" : "critical"}
              reviewHeader={
                <HorizontalStack gap="1" blockAlign="center">
                  <Icon source={review.accepted ? CheckMarkGreenIcon : CrossMarkRedIcon} />
                  <Text variant="bodyMd" as="span">
                    {review.accepted
                      ? f("details.reviews.assignment-review.accepted")
                      : f("details.reviews.assignment-review.rejected")}
                  </Text>
                  {review.risk_level && (
                    <Risk risk={review.risk_level} labelSuffix={f("common.labels.customer.risk")} />
                  )}
                </HorizontalStack>
              }
            />
          ))}
        </VerticalStack>
      )}
    </VerticalStack>
  );
};

export default AssignmentReviewsSection;
