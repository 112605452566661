import React, { useMemo } from "react";
import { HorizontalStack } from "@shopify/polaris";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import api from "../../../api";
import { MAIN_COLUMN_SIZE, ROLES_AND_OWNERS_INFO_COLUMN_SIZE } from "../../../constants/tables";
import { isVitecProject } from "../../../helpers/integration.helpers";
import { isAllowScreeningOwner, isOwnerPerson } from "../../../helpers/owners.helpers";
import { isUBO } from "../../../helpers/person.helpers";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { noop } from "../../../utils/util";
import ReactTable from "../../ReactTable/ReactTable";

import OwnerActions from "./OwnerActions/OwnerActions";
import OwnerName from "./OwnerName/OwnerName";
import VitecOwnerSync from "./VitecOwnerSync/VitecOwnerSync";
import OwnerCountries from "./OwnerCountries";
import OwnerPercentShare from "./OwnerPercentShare";
import useGetOwnerWarning from "./useGetOwnerWarning";
import WarningWrapper from "./WarningWrapper";

export interface OwnersTableProps {
  customer: api.CompanyCustomerDetailResponse;
  owners: api.Owner[];
  readonly?: boolean;
  selectable?: boolean;
  selectedOwners?: string[];
  screenedOwnersIds?: string[];
  allowOwnershipTree?: boolean;
  getOwnershipUpdate?(owner: api.Owner): api.OwnershipUpdate | undefined;
  onEdit(owner: api.Owner): void;
  onUpdate(owner: api.Owner): void;
  onArchive(ownerId: string): void;
  onSelectionChange?(selectedOwnersIds: string[]): void;
  onOwnershipUpdateReject?(update: api.OwnershipUpdate): void;
  onOwnershipUpdateConfirm?(update: api.OwnershipUpdate, updatedOwner: api.Owner): void;
}

const OwnersTable = (props: OwnersTableProps) => {
  const {
    customer,
    owners,
    readonly,
    selectable,
    selectedOwners = [],
    screenedOwnersIds,
    getOwnershipUpdate = () => undefined,
    onEdit,
    onUpdate,
    onArchive,
    onSelectionChange = noop,
    onOwnershipUpdateReject,
    onOwnershipUpdateConfirm,
    ...dataTableProps
  } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const { getOwnerWarning, hasWarnings } = useGetOwnerWarning();

  const showVitecSync = features.VITEC_ROLES_SYNC && features.VITEC_PROJECTS && isVitecProject(customer);

  const screenableOwners = owners.filter(isOwnerPerson);

  const handleSelection = (selectedIds: string[], fromSelectAll?: boolean) => {
    if (fromSelectAll && selectedIds.length === 0) {
      const selection = screenableOwners.filter((owner) => isUBO(owner)).map((owner) => owner.id!);
      onSelectionChange(selection);
      return;
    }

    const validSelection = selectedIds.filter((ownerId) =>
      isAllowScreeningOwner(screenableOwners.find((owner) => owner.id === ownerId))
    );

    onSelectionChange(validSelection);
  };

  const getOwnerScreenType = (owner: api.Owner): api.ScreeningType | undefined => {
    if (!screenedOwnersIds || owner.is_unknown) {
      return undefined;
    }

    if (screenedOwnersIds.includes(owner.id!)) {
      return "MONITORING";
    } else {
      return "NONE";
    }
  };

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<api.Owner>();

    const columns = [
      columnHelper.accessor((row) => row.id!, {
        header: f("table.column.name"),
        enableSorting: false,
        size: MAIN_COLUMN_SIZE,
        cell: (props) => {
          const owner = props.row.original;

          const update = getOwnershipUpdate(owner);
          const showWarning = hasWarnings(owner);
          const warning = getOwnerWarning(owner);
          const screeningType = getOwnerScreenType(owner);

          return (
            <WarningWrapper showWarning={showWarning} description={warning?.description} url={warning?.url}>
              <OwnerName owner={owner} update={update} screeningType={screeningType} wrap />
            </WarningWrapper>
          );
        }
      }),
      columnHelper.accessor((row) => row.id!, {
        header: f("table.column.shares"),
        enableSorting: false,
        size: ROLES_AND_OWNERS_INFO_COLUMN_SIZE,
        id: "shares",
        cell: (props) => {
          const owner = props.row.original;
          return <OwnerPercentShare owner={owner} update={getOwnershipUpdate(owner)} />;
        }
      }),
      columnHelper.accessor((row) => row.id!, {
        header: f("table.column.country"),
        enableSorting: false,
        id: "countries",
        cell: (props) => {
          return <OwnerCountries owner={props.row.original} />;
        }
      }),
      columnHelper.accessor((row) => row.id!, {
        id: "actions",
        header: "",
        size: 1,
        enableSorting: false,
        cell: (props) => {
          const owner = props.row.original;

          const update = getOwnershipUpdate(owner);

          return (
            <HorizontalStack gap="4" blockAlign="center" align="space-between" wrap={false}>
              {showVitecSync && (
                <VitecOwnerSync customerId={customer.id} owner={owner} onUpdate={onUpdate} readonly={readonly} />
              )}
              <OwnerActions
                customer={customer}
                owner={owner}
                update={update}
                readonly={readonly}
                onEdit={onEdit}
                onOwnershipUpdateReject={onOwnershipUpdateReject}
                onOwnershipUpdateConfirm={onOwnershipUpdateConfirm}
                onAfterArchive={onArchive}
              />
            </HorizontalStack>
          );
        }
      })
    ] as ColumnDef<api.Owner>[];

    return columns;
  }, [getOwnershipUpdate, readonly]);

  return (
    <ReactTable<api.Owner>
      columns={columns}
      data={owners}
      selectable={selectable}
      selectedIds={selectedOwners}
      getRowId={(owner) => owner.id!}
      isRowSelectable={(row) => isAllowScreeningOwner(row)}
      getRowProps={(owner) => ({ warningBanner: hasWarnings(owner) })}
      onSelectionChange={handleSelection}
      {...dataTableProps}
    />
  );
};

export default OwnersTable;
