import React, { useCallback, useState } from "react";
import { Card } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import ScreeningTypeCard from "../../components/ScreeningTypeCard/ScreeningTypeCard";
import SectionTile from "../../components/SectionTile/SectionTile";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import SignatureConfigurationForm from "../../components/SignatureConfigurationForm/SignatureConfigurationForm";
import { DEFAULT_ONBOARDING_CONFIGURATION } from "../../constants/onboarding";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import { ValueOf } from "../../types/utilities";

type SettingsOnboardingProps = {
  settings: api.PersonOnboardingSettings;
  readonly?: boolean;
  loading?: boolean;
};

const SettingsOnboarding = (props: SettingsOnboardingProps) => {
  const { settings: initialSettings, readonly, loading } = props;

  const f = useFormatMessage();
  const { isPermittedTo } = usePermissions();
  const [settings, setSettings] = useState({ ...initialSettings });

  const { setQueryData } = useQueryData<api.PersonOnboardingSettings>([QUERIES_KEYS.ONBOARDING_SETTINGS]);

  const onFieldChange = useCallback(
    (field: keyof Partial<api.PersonOnboardingSettings>, value: ValueOf<Partial<api.PersonOnboardingSettings>>) =>
      setSettings((prevState) => ({ ...prevState, [field]: value })),
    [settings]
  );

  const onSignatureFieldChange = useCallback(
    (value: api.OrganizationSignatureSettings) =>
      setSettings((prevState) => ({
        ...prevState,
        signature_settings: value
      })),
    [settings]
  );

  const disabled = readonly || loading || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  return (
    <SettingsPage<api.PersonOnboardingSettings>
      readonly={disabled}
      apiCall={() => api.updatePersonOnboardingSettings(settings)}
      onSaveAfter={setQueryData}
      title={f("onboarding-settings.page.title")}
      subtitle={f("onboarding-settings.page.description")}
    >
      <Card>
        <Card.Section>
          <SectionTile title={f("screening.titles.screening-frequency")} gap="4">
            <ScreeningTypeCard
              readonly={disabled}
              screeningType={settings.screening_type}
              onScreeningTypeChange={(newValue) => onFieldChange("screening_type", newValue)}
            />
          </SectionTile>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section>
          <SignatureConfigurationForm
            signature={settings.signature_settings}
            customerType="person"
            readonly={disabled}
            onSignatureChange={onSignatureFieldChange}
          />
        </Card.Section>
      </Card>
    </SettingsPage>
  );
};

const SettingsOnboardingPage = ({ data }: AsyncPageChild<api.PersonOnboardingSettings>) => {
  return <SettingsOnboarding settings={data} />;
};

export default withAsyncPage<api.PersonOnboardingSettings>(SettingsOnboardingPage, {
  queryKey: QUERIES_KEYS.ONBOARDING_SETTINGS,
  apiFunction: api.getPersonOnboardingSettings,
  paramNames: [],
  skeleton: <SettingsOnboarding readonly loading settings={DEFAULT_ONBOARDING_CONFIGURATION} />
});
