import React, { PropsWithChildren } from "react";
import FlipMove from "react-flip-move";
import { Card, Spinner } from "@shopify/polaris";
import styled from "styled-components";

import CondensedText from "../../components/CondensedText/CondensedText";

interface DashboardWidgetProps {
  title: string;
  loading?: boolean;
}

const DashboardWidget = (props: PropsWithChildren<DashboardWidgetProps>) => {
  const { title, loading, children } = props;

  return (
    <StyledCard className="Polaris-Card">
      <Card.Section flush>
        <StyledHeader>{title}</StyledHeader>
      </Card.Section>
      <Card.Section flush>
        <StyledFlipMove>
          {loading && (
            <StyledLoadingContainer>
              <Spinner />
            </StyledLoadingContainer>
          )}
          {!loading && children}
        </StyledFlipMove>
      </Card.Section>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;

  & .Polaris-Card__Section:last-child {
    flex: 1;
    overflow: hidden;
  }

  & .Polaris-Card__Section:last-child > div {
    height: 100%;
  }
`;

const StyledHeader = styled(CondensedText)`
  text-transform: uppercase;
  display: inline-block;
  padding: var(--p-space-1) var(--p-space-4);
`;

const StyledFlipMove = styled(FlipMove)`
  height: 100%;
`;

const StyledLoadingContainer = styled.div`
  min-height: 16rem;
  display: grid;
  grid-template-rows: auto min-content;
  place-items: center;
`;

export default DashboardWidget;
