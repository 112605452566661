import React, { PropsWithChildren } from "react";
import { HorizontalStack, Link, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import { ComplyMatchEvent } from "../../types/MatchDetails";
import { parseWebsite } from "../../utils/stringUtils";
import CondensedText from "../CondensedText/CondensedText";
import CustomDate from "../CustomDate/CustomDate";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";

type MatchEventProps = {
  event: ComplyMatchEvent;
  showNewInfo?: boolean;
};

const MatchEvent = (props: PropsWithChildren<MatchEventProps>) => {
  const { event, showNewInfo, children } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="1">
      <HorizontalStack gap="2" blockAlign={"baseline"}>
        {event.date && (
          <VerticalStack>
            <Text as={"span"} fontWeight="bold" variant="bodySm">
              <CustomDate date={event.date} />
            </Text>
            {event.amendedDate && (
              <CondensedText>
                {f("match.basic-info.last-updated") + " "}
                <CustomDate date={event.amendedDate} />
              </CondensedText>
            )}
          </VerticalStack>
        )}
        {showNewInfo && <NewInfoBadge />}
      </HorizontalStack>
      {/* TODO check is there's a need for Norwegian translation for program, chamber, function and institution type*/}
      <StyledInfoContainer>
        {event.program && (
          <>
            <Text as="span">Program:</Text>
            <Text as="span">{event.program}</Text>
          </>
        )}
        {event.chamber && (
          <>
            <Text as="span">Chamber:</Text>
            <Text as="span">{event.chamber}</Text>
          </>
        )}
        {event.pepFunction && (
          <>
            <Text as="span">Function:</Text>
            <Text as="span">{event.pepFunction}</Text>
          </>
        )}
        {event.institutionType && (
          <>
            <Text as="span">Institution Type:</Text>
            <Text as="span">{event.institutionType}</Text>
          </>
        )}
        {event.otherInformation && (
          <>
            <Text as="span">Other information:</Text>
            <Text as="span">{event.otherInformation}</Text>
          </>
        )}
      </StyledInfoContainer>
      {event.description && <Text as="p">{event.description.replace(": ", "")}</Text>}
      {/* don't show source_name twice if it is going to be shown as a URL */}
      {!event.source?.source_url && event.source?.source_name && (
        <HorizontalStack gap="1">
          <Text as="span" color="subdued">
            {f("matches.labels.source.name")}
          </Text>
          <Text as="span" truncate>
            {event.source?.source_name}
          </Text>
        </HorizontalStack>
      )}
      {event.source?.publisher && (
        <HorizontalStack gap="1">
          <Text as="span" color="subdued">
            {f("matches.labels.source.publisher")}
          </Text>
          <Text as="span" truncate>
            {event.source?.publisher}
          </Text>
        </HorizontalStack>
      )}
      {event.source?.source_url && (
        <HorizontalStack gap="1">
          <Text as="span">{f("matches.labels.source.url")}</Text>
          <Text as="span">
            <Link url={parseWebsite(event.source?.source_url)} external>
              {event.source?.source_name || event.source?.source_url}
            </Link>
          </Text>
        </HorizontalStack>
      )}
      {children}
    </VerticalStack>
  );
};

const StyledInfoContainer = styled.div`
  display: grid;
  gap: var(--p-space-1) var(--p-space-4);
  grid-template-columns: auto 1fr;
  margin-bottom: var(--p-space-4);
`;

export default MatchEvent;
