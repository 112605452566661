import { Helmet } from "react-helmet";

import { getFullName } from "../../helpers/display.helpers";
import useAuth from "../../hooks/useAuth";

const API_KEY = process.env.PENDO_CLIENT_ID;

const PendoAnalytics = () => {
  const { isAuthenticated, user: currentUser } = useAuth();

  // if the feature is not enabled do not even load the initial analytics code
  if (!isAuthenticated) {
    return null;
  }

  // this script initializes pendo widget
  const PendoScript = `(function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');    
            pendo.initialize({
                visitor: {
                    id: '${currentUser?.id}',
                    email: '${currentUser?.email}',
                    full_name: '${getFullName(currentUser)}',
                    group: '${currentUser?.groups.map((group) => group.role_key).join(", ") || ""}'
                },
                account: {
                    id: '${currentUser?.organization.id}',
                    name: '${currentUser?.organization.name}',
                }
            });
    })('${API_KEY}');`;

  return (
    <Helmet>
      <script>{PendoScript}</script>
    </Helmet>
  );
};

export default PendoAnalytics;
