import React from "react";
import { Banner, Stack, Text } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";

interface PendingDisclosuresBannerProps {
  pendingDisclosuresCount: number;
  onNavigateToDisclosuresSection(): void;
}

const PendingDisclosuresBanner = (props: PendingDisclosuresBannerProps) => {
  const { pendingDisclosuresCount, onNavigateToDisclosuresSection } = props;

  const f = useFormatMessage();

  return (
    <Banner status="info">
      <Stack alignment="center" distribution="equalSpacing">
        <Text as="span">
          {f("reviews.header.banner.pending-disclosures.description", { count: pendingDisclosuresCount })}
        </Text>
        <Button plain onClick={onNavigateToDisclosuresSection}>
          {f("reviews.header.banner.pending-disclosures.button.label", { count: pendingDisclosuresCount })}
        </Button>
      </Stack>
    </Banner>
  );
};
export default PendingDisclosuresBanner;
