import styled from "styled-components";

const ComparisonCellContent = styled.div<{ emphasis?: boolean }>`
  &:empty:before {
    content: "---";
  }

  padding: var(--p-space-1) var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  margin-left: calc(-1 * var(--p-space-2));
  background-color: ${({ emphasis }) => (emphasis ? "var(--p-color-bg-app)" : undefined)};
  font-weight: ${({ emphasis }) => (emphasis ? "var(--p-font-weight-semibold)" : undefined)};
`;

export default ComparisonCellContent;
