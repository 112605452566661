import React, { useCallback, useState } from "react";
import { TextField, TextFieldProps } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import { isSafari } from "../../utils/browserUtils";
import { formatDateInput } from "../../utils/dateUtils";
import { isEmptyString } from "../../utils/stringUtils";

type DateInputProps = Omit<TextFieldProps, "min" | "max" | "placeholder"> & {
  minDate?: Date;
  maxDate?: Date;
};

const DateInput = (props: DateInputProps) => {
  const { minDate, maxDate, ...textFieldProps } = props;

  const f = useFormatMessage();
  const [isFocused, setIsFocused] = useState(false);

  // Safari on desktop does not show a placeholder when value is empty (is shows the current date instead)
  // For this edge case, if isEmptySafari === true, render the input date as a text field with a placeholder
  const isEmptySafari = isSafari() && isEmptyString(textFieldProps.value) && !isFocused;

  const handleOnFocus = useCallback(
    (event?: React.FocusEvent) => {
      setIsFocused(true);
      textFieldProps.onFocus && textFieldProps.onFocus(event);
    },
    [textFieldProps.onFocus]
  );

  const handleOnBlur = useCallback(
    (event?: React.FocusEvent) => {
      setIsFocused(false);
      textFieldProps.onBlur && textFieldProps.onBlur(event);
    },
    [textFieldProps.onBlur]
  );

  return (
    <TextField
      min={minDate ? formatDateInput(minDate) : undefined}
      max={maxDate ? formatDateInput(maxDate) : undefined}
      maxLength={10}
      minLength={10}
      type={isEmptySafari ? "text" : "date"}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      placeholder={f("common.date-input.placeholder")}
      {...textFieldProps}
    />
  );
};

export default DateInput;
