import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";

const useGetSigningMethodDisplayLabel = (signature: api.Signature) => {
  const f = useFormatMessage();

  switch (signature.signing_method) {
    case "NO_BANKID":
      return f("signature.signing-method.no");
    case "SIGNICAT_NO_BANKID":
      return f("signature.signing-method.no");
    case "SE_BANKID":
      return f("signature.signing-method.se");
    default:
      return "";
  }
};

export default useGetSigningMethodDisplayLabel;
