import api from "../api";
import { Valuable } from "../types/utilities";

import { isEmptyString } from "./stringUtils";

export function noop() {
  // do nothing!
}

export function isEmailEnabled(d: { send_email?: boolean }) {
  return d.send_email === undefined || d.send_email;
}

let lastId = 0;

export function uniqueId(prefix = "id") {
  lastId++;
  return `${prefix}${lastId}`;
}

export function isTranslationTextEmpty(object?: api.TranslationText) {
  if (object === undefined || object === null) {
    return true;
  }

  return Object.values(object).every((text) => isEmptyString(text));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(object?: any) {
  if (object === undefined || object === null) {
    return true;
  }

  const objectType = typeof object;

  switch (objectType) {
    case "string":
      return isEmptyString(object);
    case "object":
      return Object.values(object).filter(Boolean).length === 0;
    // for all other types, if they're not null or undefined, then they're not empty
    // for example, a number with the value of 0 is not considered an empty value
    default:
      return false;
  }
}

export function isNil<T>(x: T | undefined | null): x is undefined | null {
  return x === undefined || x === null;
}

/**
 * Checks if an unknown value is an object with a given property
 */
export function hasOwnProperty<X, Y extends PropertyKey>(x: unknown, prop: Y): x is X & Record<Y, unknown> {
  if (typeof x === "object" && x) {
    return Object.prototype.hasOwnProperty.call(x, prop);
  } else {
    return false;
  }
}

/**
 * removes all empty values from an object
 */
export function removeEmptyValues<T extends object, V = Valuable<T>>(obj: T) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => typeof value !== "undefined" && value !== null)
  ) as V;
}

/**
 * Removes a property from an object
 */
export function removeProperty<T extends object, K extends keyof T | string>(obj: T, key: K): Omit<T, K> {
  // this method uses destructuring in order to remove the property in [key] name
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...newObj } = obj;

  return newObj;
}

/**
 * Deep clones an item
 */
export function clone<T>(item: T) {
  return JSON.parse(JSON.stringify(item)) as T;
}
