import React, { PropsWithChildren } from "react";

import { FeaturesTypes } from "../../constants/features";
import useAuth from "../../hooks/useAuth";
import useFeatures from "../../hooks/useFeatures";
import AccessDenied from "../../pages/AccessDenied";
import { hasContent } from "../../utils/collectionUtils";

type RequireAuthProps = {
  requiredFeatures?: FeaturesTypes[]; // if populated, the user/org must have at least one of the features
};

const RequireAuth = (props: PropsWithChildren<RequireAuthProps>) => {
  const { requiredFeatures = [], children } = props;

  const { isAuthenticated } = useAuth();
  const features = useFeatures();

  const hasRequiredFeatures = requiredFeatures.some((feature) => features[feature]);

  // if the user is authenticated and doesn't have the required features for this route (if exists)
  // then return AccessDenied page
  if (isAuthenticated && hasContent(requiredFeatures) && !hasRequiredFeatures) {
    return <AccessDenied />;
  }

  return React.isValidElement(children) ? children : <>{children}</>;
};

export default RequireAuth;
/***************************************
 * 🚨🚨🚨🚨 wrapping RequireAuth with withAuthenticationRequired
 * will cause an infinite loop when trying to login
 * this is an Auth0 issue caused when configuring it with refresh tokens
 ***************************************/
// export default withAuthenticationRequired(RequireAuth, {
//   returnTo: () => `${window.location.pathname}${window.location.search}${window.location.hash}`,
// });
