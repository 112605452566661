import { Dispatch, SetStateAction, useMemo, useState } from "react";

/**
 * Same as useState, but with an additional changed value that indicates if the current state value
 * differs from the initial value
 *
 * @param initialState
 */
function useChangedState<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, boolean] {
  // @ts-ignore
  const initialStateJSONStr = JSON.stringify(typeof initialState === "function" ? initialState() : initialState);

  const [value, setValue] = useState<S>(initialState);

  const changed = useMemo(() => JSON.stringify(value) !== initialStateJSONStr, [value]);

  return [value, setValue, changed];
}

export default useChangedState;
