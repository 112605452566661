import React from "react";
import { matchPath, useHistory } from "react-router-dom";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

import CompanyIcon from "../../../../assets/icons/company.svg";
import DashboardIcon from "../../../../assets/icons/dashboard.svg";
import FolderIcon from "../../../../assets/icons/folder.svg";
import { ROUTES } from "../../../constants/routes";
import { MOBILE_BREAKPOINT } from "../../../constants/styles";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useProjectPermissions from "../../../hooks/useProjectPermissions";
import { isEmptyString } from "../../../utils/stringUtils";
import Icon from "../../extensions/Icon";

const MainToolbar = () => {
  const history = useHistory();
  const f = useFormatMessage();
  const { isProjectsEnabled } = useProjectPermissions();
  const path = history.location.pathname;

  const showProjectsUpgradePlan = !isProjectsEnabled;
  const showProjectsLink = isProjectsEnabled || showProjectsUpgradePlan;

  const isOverviewActive =
    isEmptyString(path) || matchPath(path, { path: [ROUTES.HOME, ROUTES.CUSTOMERS], exact: true }) !== null;

  const isProjectsLinkActive = showProjectsUpgradePlan
    ? !!matchPath(path, ROUTES.PLAN_UPGRADE_PROJECTS_LIST)
    : !!matchPath(path, ROUTES.PROJECTS_LIST);

  const isDashboardActive = !!matchPath(path, ROUTES.DASHBOARD);

  const handleProjectsLinkClick = () =>
    history.push(showProjectsUpgradePlan ? ROUTES.PLAN_UPGRADE_PROJECTS_LIST : ROUTES.PROJECTS_LIST);

  return (
    <StyledToolbarWrapper>
      <StyledMainToolbarLink isActive={isOverviewActive} onClick={() => history.push(ROUTES.CUSTOMERS)}>
        <Icon source={CompanyIcon} useMask />
        <Text as="span">{f("nav-bar.overview")}</Text>
      </StyledMainToolbarLink>
      {showProjectsLink && (
        <StyledMainToolbarLink isActive={isProjectsLinkActive} onClick={handleProjectsLinkClick}>
          <Icon source={FolderIcon} useMask />
          <Text as="span">{f("projects.page.title")}</Text>
        </StyledMainToolbarLink>
      )}
      <StyledMainToolbarLink isActive={isDashboardActive} onClick={() => history.push(ROUTES.DASHBOARD)}>
        <Icon source={DashboardIcon} useMask />
        <Text as="span">{f("nav-bar.dashboard")}</Text>
      </StyledMainToolbarLink>
    </StyledToolbarWrapper>
  );
};

const StyledToolbarWrapper = styled.div`
  display: flex;
  gap: var(--p-space-8);
  flex-wrap: nowrap;

  // For small viewports - reduce gap and set buttons width to be equal
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    gap: var(--p-space-1);

    & > * {
      flex: 1;
    }
  }
`;

const StyledMainToolbarLink = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  height: var(--kdk-toolbar-height);
  align-items: center;
  justify-content: center;
  padding: 0 var(--p-space-4);
  gap: var(--p-space-1);

  color: ${(props) => (props.isActive ? "white" : "inherit")};
  background-color: ${(props) => (props.isActive ? "var(--kdk-red)" : "inherit")};

  ${({ isActive }) =>
    !isActive &&
    `
      &:hover {
        background-color: var(--p-surface-hovered);
      }
    
      &:active {
        background-color: var(--p-surface-pressed);
      }
  `}

  // For small viewports - remove paddings and change font size to compact main toolbar
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0 var(--p-space-1);
    font-size: var(--p-font-size-75);
    flex-direction: column;
    gap: 0;
  }
`;

export default MainToolbar;
