import React from "react";
import { Text } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { BadgeProps } from "../extensions/Badge";
import StatusBadge from "../StatusBadge/StatusBadge";

const RISK_LEVEL_TO_STATUS: Record<api.RiskLevel, BadgeProps["status"] | undefined> = {
  LOW: "success",
  MEDIUM: "warning",
  HIGH: "critical",
  NOT_SET: "info" // use status info for "NOT_SET" since no status styles will be applied to all statues
};

type RiskProps = {
  risk?: api.RiskLevel;
  labelSuffix?: string;
};

const Risk = (props: RiskProps) => {
  const { risk, labelSuffix } = props;

  const f = useFormatMessage();

  if (!risk) {
    return <Text as={"span"}>-</Text>;
  }

  const riskLabel = f(`risklabel.${risk}`);
  const displayLabel = labelSuffix ? `${riskLabel} ${labelSuffix}` : riskLabel;

  return (
    <StatusBadge size="xsmall" status={risk ? RISK_LEVEL_TO_STATUS[risk] : undefined}>
      {displayLabel.toUpperCase()}
    </StatusBadge>
  );
};

export default Risk;
