import api from "../api";
import { NEW_CUSTOMER_REVIEW } from "../constants/session-state";
import { OptionalCustomerReviewRequest, Review } from "../types/CustomerReview";
import { OptionalChecklistAnswersRequest, OptionalChecklistQuestionAnswerRequest } from "../types/ReviewChecklist";
import { CustomerDetails } from "../types/utilities";
import { isEmptyString } from "../utils/stringUtils";

import { isActiveAssignment } from "./assignments.helpers";
import { convertChecklistAnswerToChecklistAnswerRequest } from "./checklists.helpers";

const isPendingDisclosure = (disclosure: api.CustomerDisclosureDocument) =>
  !disclosure.is_signed && disclosure.signature_required;

const isPendingDisclosureSignature = (signature: api.Signature) => signature.status !== "SIGNED";

const isSignatureWithoutAssignment = (signature: api.Signature) => isEmptyString(signature.assignment_id);

const isSignatureAttachedToUser = (signature: api.Signature, assignments?: api.Assignment[], userId?: string) =>
  assignments?.find((assignment) => assignment.id === signature.assignment_id)?.created_by?.id === userId;

export const getPendingDisclosuresCount = (customer: CustomerDetails, userId?: string) =>
  customer.disclosure_documents.filter(isPendingDisclosure).reduce(
    (count, disclosure) =>
      count +
      disclosure.signatures.filter(
        (signature) =>
          // count disclosures that are pending and either don't have an assignment attached to them
          // or they have an assignment that was created by the current user
          // this means that pending disclosures that were created by other users WILL NOT BE COUNTED
          isPendingDisclosureSignature(signature) &&
          (isSignatureWithoutAssignment(signature) ||
            isSignatureAttachedToUser(signature, customer.assignments, userId))
      ).length,
    0
  );

// a checklist answer is invalid if the answer is true and the description (additional_info) is empty
// OR if the entire checklist is required and there is no answer
const isInvalidReviewChecklistAnswer = (checklistAnswer: OptionalChecklistQuestionAnswerRequest, required: boolean) => {
  const invalidAnswer = checklistAnswer.answer === true && isEmptyString(checklistAnswer.additional_info);
  return (required && checklistAnswer.answer === undefined) || invalidAnswer;
};

export const isInvalidReviewChecklistAnswers = (
  checklistAnswers: OptionalChecklistAnswersRequest,
  checklists?: api.ReviewChecklist[]
) => {
  const checklistId = checklistAnswers.checklist_id;
  const required = Boolean(checklists?.find((checklist) => checklist.id === checklistId)?.is_required);

  return checklistAnswers.answers.some((checklistAnswer) => isInvalidReviewChecklistAnswer(checklistAnswer, required));
};

// convert accepted from optional (undefined | true | false) to a boolean value
export const convertCustomerReviewToOptionalCustomerReviewRequest = (
  review: Review
): OptionalCustomerReviewRequest => ({
  id: review.id,
  accepted: review.accepted,
  risk_level: review.risk_level || "NOT_SET",
  is_pep: Boolean(review.is_pep),
  is_sanctioned: Boolean(review.is_sanctioned),
  requires_approval: review.requires_approval,
  approval_user_ids: review.approval_users?.map((user) => user.id),
  labels: review.labels,
  measures: review.measures,
  comment: review.comment || "",
  assignment_id: review.assignment?.id,
  checklist_answers: review.checklist_answers?.map(convertChecklistAnswerToChecklistAnswerRequest)
});

export const isPendingReview = (review: Review) => review.status !== "COMPLETE" && review.status !== "ARCHIVED";
export const isCompletedReview = (review: Review) => review.status === "COMPLETE" || review.status === "ARCHIVED";

export const isReviewApprovalRequired = (review: Review) =>
  isCompletedReview(review) && review.requires_approval && review.approval === undefined;

export const isReviewRejected = (review: Review) => review.approval?.is_approved === false;

export const canCreateNewReview = (customer: CustomerDetails) => {
  // if there are no pending reviews and there is at least one existing review then EmptyReviewsBanner will not
  // be rendered and the new customer review button should be visible
  if (!customer.reviews.some(isPendingReview) && customer.reviews.length > 0) {
    return true;
  }

  // at least one pending review is not defined for an assignment or customer relationship
  const activeAssignmentsCount = customer.assignments?.filter(isActiveAssignment).length || 0;
  const reviewAssignmentsCount = customer.reviews.filter(
    (review) => isPendingReview(review) && Boolean(review.assignment?.id)
  ).length;

  const hasCustomerRelationshipReview = customer.reviews.some(
    (review) => isPendingReview(review) && !review.assignment
  );

  return !hasCustomerRelationshipReview || activeAssignmentsCount !== reviewAssignmentsCount;
};

// sort reviews by their dates but if a review is rejected then move it to the end of the
export const sortByReviewDate = (reviewA: Review, reviewB: Review) =>
  isReviewRejected(reviewA) ? 1 : isReviewRejected(reviewB) ? -1 : reviewB.date.localeCompare(reviewA.date);

export const getReviewStorageKey = (customerId: string, reviewId?: string) =>
  [NEW_CUSTOMER_REVIEW, customerId, reviewId || ""].join("-");
