import React, { createContext, PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";

import EnglishTranslations from "../../assets/i18n/en.json";
import NorwegianTranslations from "../../assets/i18n/no.json";
import api from "../api";
import { DEFAULT_LANGUAGE, LANGUAGE_KEY } from "../constants/languages";
import { useLocalStorage } from "../hooks/useStorage";
import { noop } from "../utils/util";

const Messages: Record<api.LanguageEnum, Record<string, string>> = {
  nb: NorwegianTranslations,
  en: EnglishTranslations
};

export type LocaleMessages = typeof NorwegianTranslations;
export type MessageKey = keyof LocaleMessages;

export const LanguageContext = createContext<[api.LanguageEnum, (language: api.LanguageEnum) => void]>([
  DEFAULT_LANGUAGE,
  noop
]);

export const LanguageContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [language, setLanguage] = useLocalStorage<api.LanguageEnum>(LANGUAGE_KEY, DEFAULT_LANGUAGE);

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      <IntlProvider locale={language} messages={Messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
