import { PropsWithChildren } from "react";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";

const ShowOnMobile = (props: PropsWithChildren<unknown>) => {
  const { children, ...rest } = props;

  return <StyledWrapper {...rest}>{children}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  display: none;

  // For small viewports - show
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: revert;
  }
`;

export default ShowOnMobile;
