import React, { useState } from "react";
import { generatePath } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { Page, Text, VerticalStack } from "@shopify/polaris";

import CompanyCustomerForm from "../../components/CompanyCustomerForm/CompanyCustomerForm";
import KDKTabs from "../../components/KDKTabs/KDKTabs";
import { COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS } from "../../constants/integrations";
import { ROUTES } from "../../constants/routes";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useFormatMessage from "../../hooks/useFormatMessage";
import { MessageKey } from "../../providers/LanguageProvider";
import HistoryLocationState from "../../types/HistoryLocationState";

import CompanyLookupForm from "./CompanyLookupForm";
import { hasIntegration } from "./OnboardingCompanyPage.helpers";

const OnboardingCompanyPage = () => {
  const history = useHistory();
  const f = useFormatMessage();
  const defaultCountry = useDefaultCountry();
  const location = useLocation<HistoryLocationState>();

  const createdFromProject = location.state?.createdFromProject;

  const companyCountryOptions = [
    ...COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS.map((countryCode) => ({
      id: countryCode,
      content: f(`new-company-customer.country-options.${countryCode.toLowerCase()}` as MessageKey)
    })),
    { id: "other", content: f("new-company-customer.country-options.other") }
  ];

  const [companyCountry, setCompanyCountry] = useState<string>(defaultCountry);

  return (
    <Page>
      <KDKTabs
        tabs={companyCountryOptions}
        selected={companyCountryOptions.findIndex((t) => t.id === companyCountry)}
        onSelect={(i) => setCompanyCountry(companyCountryOptions[i].id)}
      />

      {hasIntegration(companyCountry) && (
        <Page title={f("new-company-customer.titles.norwegian")}>
          <CompanyLookupForm projectId={createdFromProject?.id} projectName={createdFromProject?.name} />
        </Page>
      )}
      {!hasIntegration(companyCountry) && (
        <Page title={f("new-company-customer.titles.manual")}>
          <VerticalStack gap="4">
            <Text variant="bodyMd" as="span">
              {f("new-company-customer.texts.manual")}
            </Text>
            <CompanyCustomerForm
              projectId={createdFromProject?.id}
              projectName={createdFromProject?.name}
              onAfterSubmit={(createdCompanyCustomer) => {
                history.push(
                  generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING, { id: createdCompanyCustomer.id })
                );
              }}
            />
          </VerticalStack>
        </Page>
      )}
    </Page>
  );
};

export default OnboardingCompanyPage;
