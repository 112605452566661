import { PropsWithChildren, ReactNode } from "react";
import { Button, HorizontalStack, Page, PageProps, Toast, VerticalStack } from "@shopify/polaris";
import { MutationFunction, useMutation } from "@tanstack/react-query";

import { PERMISSIONS_TYPES } from "../../constants/permissions";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useSettingsPage from "../../hooks/useSettingsPage";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import MissingRequiredPermissionsBanner from "../MissingRequiredPermissionsBanner/MissingRequiredPermissionsBanner";

type PolarisPageProps = Omit<PageProps, "subtitle"> & { subtitle?: ReactNode };

type SettingsPageProps<T> = {
  readonly?: boolean;
  permissions?: PERMISSIONS_TYPES | false;
  overrideRoleNames?: string[];
  footer?: ReactNode;
  apiCall?: MutationFunction<T>;
  onSaveAfter?(response: T): void;
};

const SettingsPage = <T,>(props: PropsWithChildren<SettingsPageProps<T> & PolarisPageProps>) => {
  const {
    readonly,
    permissions = PERMISSIONS_TYPES.EDIT_SETTINGS,
    overrideRoleNames,
    footer,
    apiCall,
    onSaveAfter,
    children,
    ...pageProps
  } = props;

  const f = useFormatMessage();
  const { isPermittedTo } = usePermissions();

  const [showSuccessMessageToast, toggleShowSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();

  const updateSettingMutation = useMutation(apiCall || Promise.reject<T>, {
    onSuccess: (response) => {
      onSaveAfter && onSaveAfter(response);
      toggleShowSuccessMessageToast();
    }
  });

  useSettingsPage();

  const showMissingPermissionsBanner = !readonly && permissions !== false && !isPermittedTo(permissions);
  const disabled = readonly || (permissions !== false && !isPermittedTo(permissions));

  return (
    // @ts-ignore
    // TS ignore here because subtitle property type is changed from string to ReactNode (which is actually supported
    <Page {...pageProps}>
      <VerticalStack gap="4">
        {showMissingPermissionsBanner && <MissingRequiredPermissionsBanner overrideRoleNames={overrideRoleNames} />}
        {children}
        {updateSettingMutation.isError && <ErrorPanel message={updateSettingMutation.error} />}
        {apiCall && (
          <HorizontalStack gap="4" align={footer ? "space-between" : "end"}>
            {footer}
            <Button
              primary
              loading={updateSettingMutation.isLoading}
              onClick={() => updateSettingMutation.mutate({})}
              disabled={disabled}
            >
              {f("common.labels.save-settings")}
            </Button>
          </HorizontalStack>
        )}
      </VerticalStack>
      {showSuccessMessageToast && (
        <Toast content={f("common.labels.saved-successfully")} onDismiss={closeSuccessMessageToast} />
      )}
    </Page>
  );
};

export default SettingsPage;
