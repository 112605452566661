import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";
import { sortByName } from "../utils/collectionUtils";

// set the stale time to 1 hour to avoid multiple calls to listQuestionnaireNames
const useGetQuestionnairesSummary = (customerType?: api.CustomerType) => {
  const query = useQuery([QUERIES_KEYS.QUESTIONNAIRES_SUMMARY], api.getQuestionnairesSummary, {
    staleTime: STALE_TIME
  });

  const filterQuestionnaire = ({ customer_type, is_archived }: api.QuestionnaireSummary) =>
    !is_archived && (customerType ? customer_type === "both" || customer_type === customerType : true);

  const questionnairesSummary = query.data?.questionnaire_summaries
    ? query.data.questionnaire_summaries.filter(filterQuestionnaire).sort(sortByName)
    : [];

  const questionnairesSummaryByIdMap = useMemo(
    () =>
      Object.fromEntries(
        questionnairesSummary.map((questionnaireSummary) => [questionnaireSummary.id, questionnaireSummary])
      ),
    [questionnairesSummary]
  );

  return { ...query, questionnairesSummary, questionnairesSummaryByIdMap };
};

export default useGetQuestionnairesSummary;
