import { useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useQueryData from "../../hooks/useQueryData";
import { getCustomerQueryKey } from "../../types/utilities";
import { scrollInPage } from "../../utils/browserUtils";

const useMatchReview = (customerId: string, customerType: api.CustomerType, id: string) => {
  const queryClient = useQueryClient();
  const { updateQueryData } = useQueryData<api.MatchCaseDetailResponse>([QUERIES_KEYS.MATCH_DETAILS, customerId, id]);

  const [resolvedReview, setResolvedReview] = useState<api.MatchReview>();
  const clearResolvedReview = useCallback(() => setResolvedReview(undefined), []);

  const onMatchReviewed = async (review: api.MatchReview) => {
    setResolvedReview(review);

    // update the current match details page with the new review
    updateQueryData((queryData) => {
      queryData.case.status = "RESOLVED";
      queryData.case.resolved_by = review.reviewed_by;
      queryData.case.reviews = [review, ...queryData.case.reviews];
    });

    // invalidate the customer match cases query in order to get the latest matches
    await queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMER_MATCH_CASES, customerId]);
    // also invalidate the customer details page query in order to update the matches list
    await queryClient.invalidateQueries([getCustomerQueryKey({ id: customerId, type: customerType })]);

    requestAnimationFrame(() => scrollInPage(PAGE_ANCHORS.REVIEWS_SECTION, { behavior: "smooth" }));
  };

  return { onMatchReviewed, resolvedReview, clearResolvedReview };
};

export default useMatchReview;
