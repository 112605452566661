import { CSSProperties } from "react";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, ChartData, ChartOptions, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

ChartJS.defaults.font.family = `"BatonTurboWeb", "Arial", "sans-serif"`;
ChartJS.defaults.font.size = 15;
ChartJS.defaults.font.weight = "bold";

type PieChartProps<T> = {
  values: T[];
  valueKey: keyof T;
  labels: string[];
  colors: CSSProperties["color"][];
  tooltipPrefix: string;
  onItemClick?(value?: T): void;
};

function PieChart<T>(props: PieChartProps<T>) {
  const { values, valueKey, labels, colors, tooltipPrefix, onItemClick } = props;

  const data: ChartData<"doughnut"> = {
    labels,
    datasets: [
      {
        // @ts-ignore
        data: values,
        backgroundColor: colors,
        label: tooltipPrefix
      }
    ]
  };

  const options: ChartOptions<"doughnut"> = {
    onClick: (_, elements, chart) => {
      if (onItemClick) {
        if (elements[0]) {
          const index = elements[0].index;
          const dateset = chart.data.datasets[0].data;
          onItemClick(dateset[index] as T);
        } else {
          onItemClick();
        }
      }
    },
    onHover: (event, chartElements) => {
      if (chartElements.length === 1 && event.native?.target) {
        // @ts-ignore
        event.native.target.style.cursor = "pointer";
      }
    },
    parsing: {
      key: valueKey as string
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        color: "white",
        formatter: (value: T) => (value ? value[valueKey] : 0)
      },
      tooltip: {}
    }
  };

  return <Doughnut data={data} options={options} />;
}

export default PieChart;
