import api from "../api";
import { DEFAULT_LANGUAGE } from "../constants/languages";
import { CustomerDetails, isPersonCustomerDetails } from "../types/utilities";
import { isEmptyString } from "../utils/stringUtils";

import { convertPersonToCreateRequest } from "./person.helpers";

export const getCompanyType = (language: api.LanguageEnum, companyType?: api.CompanyType) => {
  let description: string | undefined;

  switch (language) {
    case "en":
      description = companyType?.description_en;
      break;
    case DEFAULT_LANGUAGE:
    default:
      description = companyType?.description_nb;
      break;
  }

  return description || companyType?.code || "-";
};

export const getTotalReviewsCount = (customer: CustomerDetails) => customer.reviews.length;

export const convertToCreatePersonCustomerRequest = (
  person: api.CreatePersonCustomerRequest,
  defaultCountry: api.CountryEnum
): api.CreatePersonCustomerRequest => {
  const personCreateRequest = convertPersonToCreateRequest(person);

  return {
    ...personCreateRequest,
    address: personCreateRequest.address || { country: defaultCountry },
    external_id: person.external_id,
    project_id: person.project_id
  };
};

const isMatchCasePEP = (match: api.MatchCaseSummary) => match.status === "RESOLVED" && match.review?.is_pep;
const isRolePEP = (role: api.Role) => role.status !== "EXPIRED" && role.person.is_pep;
const isOwnerPEP = (owner: api.Owner) => !owner.deleted && owner.person?.is_pep;

// return true if a customer is marked as PEP, has a confirmed match that was reviewed as PEP
// or has a role/owner that were marked as PEP
export const isCustomerPEP = (customer: CustomerDetails) => {
  if (customer.is_pep) {
    return true;
  }

  // check if there are match cases for the company
  if (customer.match_cases.some(isMatchCasePEP)) {
    return true;
  }

  // check for active roles/owners (for company customer) that are marked as PEP
  if (
    !isPersonCustomerDetails(customer) &&
    (customer.roles.some(isRolePEP) || customer.beneficial_owners.some(isOwnerPEP))
  ) {
    return true;
  }

  return false;
};

const isMatchCaseSanctioned = (match: api.MatchCaseSummary) =>
  match.status === "RESOLVED" && match.review?.is_sanctioned;
const isRoleSanctioned = (role: api.Role) => role.status !== "EXPIRED" && role.person.is_sanctioned;
const isOwnerSanctioned = (owner: api.Owner) => !owner.deleted && owner.person?.is_sanctioned;

// return true if a customer is marked as Sanctioned, has a confirmed match that was reviewed as Sanctioned
// or has a role/owner that were marked as Sanctioned
export const isCustomerSanctioned = (customer: CustomerDetails) => {
  if (customer.is_sanctioned) {
    return true;
  }

  // check if there are match cases for the company
  if (customer.match_cases.some(isMatchCaseSanctioned)) {
    return true;
  }

  // check for active roles/owners (for company customer) that are marked as Sanctioned
  if (
    !isPersonCustomerDetails(customer) &&
    (customer.roles.some(isRoleSanctioned) || customer.beneficial_owners.some(isOwnerSanctioned))
  ) {
    return true;
  }

  return false;
};

export const isPartialAddress = (address: api.Address) =>
  [address.address_line_1, address.address_line_2, address.city, address.county, address.postal_code].some(
    (part) => !isEmptyString(part)
  );
