import React from "react";
import { VerticalStack } from "@shopify/polaris";

import useCustomerReviewBanner from "../../hooks/useCustomerReviewBanner";
import { CustomerDetails } from "../../types/utilities";
import PendingDisclosuresBanner from "../PendingDisclosuresBanner/PendingDisclosuresBanner";
import UnreviewedMatchCasesBanner from "../UnreviewedMatchCasesBanner/UnreviewedMatchCasesBanner";

interface CustomerReviewPageBannerProps {
  customer: CustomerDetails;
}

const CustomerReviewPageBanner = (props: CustomerReviewPageBannerProps) => {
  const { customer } = props;

  const {
    hasUnresolvedMatchCases,
    hasPendingDisclosures,
    unresolvedCaseCount,
    pendingDisclosuresCount,
    navigateToMatchesSection,
    navigateToDisclosuresSection
  } = useCustomerReviewBanner(customer, true);

  return (
    <VerticalStack gap="2">
      {hasUnresolvedMatchCases && (
        <UnreviewedMatchCasesBanner
          unresolvedCaseCount={unresolvedCaseCount}
          onNavigateToMatchesSection={navigateToMatchesSection}
        />
      )}
      {hasPendingDisclosures && (
        <PendingDisclosuresBanner
          pendingDisclosuresCount={pendingDisclosuresCount}
          onNavigateToDisclosuresSection={navigateToDisclosuresSection}
        />
      )}
    </VerticalStack>
  );
};

export default CustomerReviewPageBanner;
