import React from "react";
import FlipMove from "react-flip-move";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";

import api from "../../../../../api";
import { createNewChoice } from "../../../../../helpers/questions.helpers";
import useFormatMessage from "../../../../../hooks/useFormatMessage";
import { removeItem, swapElements, updateItem } from "../../../../../utils/collectionUtils";
import AddItemButton from "../../../../AddItemButton/AddItemButton";
import { Direction } from "../../../../MoveButtons/MoveButtons";

import ChoiceEditor from "./ChoiceEditor/ChoiceEditor";

type ChoicesTableProps = {
  choices: api.QuestionChoice[];
  readonly?: boolean;
  onChange(choices: api.QuestionChoice[]): void;
};

const ChoicesTable = (props: ChoicesTableProps) => {
  const { choices, readonly, onChange } = props;

  const f = useFormatMessage();

  const addChoice = () => onChange([...choices, createNewChoice()]);

  const handleChoiceChange = (choice: api.QuestionChoice) => onChange(updateItem(choices, choice));

  const handleChoiceOrderChange = (choice: api.QuestionChoice, direction: Direction) => {
    const index = choices.indexOf(choice);
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    onChange(swapElements<api.QuestionChoice>(choices, index, targetIndex));
  };

  const handleChoiceDelete = (choice: api.QuestionChoice) => onChange(removeItem(choices, choice));

  return (
    <VerticalStack gap="4">
      <FlipMove>
        {choices.map((choice, index) => (
          <ChoiceEditor
            key={choice.id}
            choice={choice}
            position={index + 1}
            isFirst={index === 0}
            isLast={index === choices.length - 1}
            readonly={readonly}
            onChange={handleChoiceChange}
            onOrderChange={handleChoiceOrderChange}
            onDelete={handleChoiceDelete}
          />
        ))}
      </FlipMove>
      <HorizontalStack>
        <AddItemButton onClick={addChoice} disabled={readonly}>
          {f("questionnaire.choices-table.button.add.label")}
        </AddItemButton>
      </HorizontalStack>
    </VerticalStack>
  );
};

export default ChoicesTable;
