import React from "react";
import { HorizontalStack, TabsProps as PolarisTabsProps } from "@shopify/polaris";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";
import Button from "../extensions/Button";

type KDKTabsProps = PolarisTabsProps & {
  className?: string;
  centered?: boolean;
};

const KDKTabs = (props: KDKTabsProps) => {
  const { className, centered, ...tabsProps } = props;

  const { tabs, selected, fitted, children, onSelect } = tabsProps;

  return (
    <TabsContainer>
      <TabsHeader fitted={fitted} className={className}>
        <HorizontalStack gap="8" align={centered ? "center" : undefined} blockAlign={"stretch"}>
          {tabs.map(({ id, content, accessibilityLabel }, index) => (
            <Button
              onClick={() => onSelect && onSelect(index)}
              plain
              monochrome
              removeUnderline
              pressed={index === selected}
              role={accessibilityLabel}
              key={id}
            >
              {content}
            </Button>
          ))}
        </HorizontalStack>
      </TabsHeader>
      {children}
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  & > * + * {
    margin-top: var(--p-space-4);
  }
`;

const TabsHeader = styled.div<{ fitted?: boolean }>`
  padding: var(--p-space-4) var(--p-space-3) var(--p-space-2) var(--p-space-3);
  border-bottom: var(--p-border-divider);

  // // For small viewports
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    // layout the tabs vertically
    & > .Polaris-HorizontalStack {
      flex-direction: column;
      justify-content: start;
    }
  }

  & .Polaris-Button {
    position: relative;
    padding-bottom: var(--p-space-4);
    flex: ${({ fitted }) => (fitted ? "1 1 0px" : undefined)};

    color: var(--p-color-text-subdued);

    &::after {
      position: absolute;
      content: "";
      top: calc(100% - var(--p-border-width-3) / 2);

      height: 1px;

      border-radius: var(--p-border-radius-1) var(--p-border-radius-1) 0 0;
      border-top: solid var(--p-border-width-3) transparent;
    }
  }

  & .Polaris-Button:hover {
    color: var(--p-color-text);

    &::after {
      border-color: var(--p-border-subdued);
    }
  }

  & .Polaris-Button--pressed {
    color: var(--p-color-text);

    &::after {
      border-color: var(--p-color-bg-primary);
    }
  }
`;

export default KDKTabs;
