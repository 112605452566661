import { useCallback, useMemo, useState } from "react";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import { RowSelectionState } from "@tanstack/react-table";

import api from "../../api";
import { IGNORE_COLUMN } from "../../constants/import-customers";
import useErrorParser from "../../hooks/useErrorParser";
import { IMPORT_STATUS, ImportRowData } from "../../types/ImportRow";
import { ErrorType } from "../../types/utilities";
import { isNil } from "../../utils/util";

import { isValidColumnsNames } from "./ImportCustomersPage.helpers";
import useGetDefaultImportColumnsNames from "./useGetDefaultImportColumnsNames";
import useImportCompanies from "./useImportCompanies";

const useImportCustomersPage = (customerType: api.CustomerType) => {
  const mutationsCount = useIsMutating();

  const { defaultColumns, validationColumns } = useGetDefaultImportColumnsNames(customerType);

  const [rowsMap, setRowsMap] = useState<Record<string, ImportRowData>>({});

  const [useFirstRowAsHeaders, setUseFirstRowAsHeaders] = useState(false);
  const [filename, setFilename] = useState("");
  const [columnsNames, setColumnNames] = useState([...defaultColumns]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const rows = useMemo(
    () => Object.values(rowsMap).slice(useFirstRowAsHeaders ? 1 : 0),
    [rowsMap, useFirstRowAsHeaders]
  );
  const headers: string[] = useMemo(
    () => (useFirstRowAsHeaders ? Object.values(rowsMap)[0].record : []),
    [rows, rowsMap.length]
  );

  // TODO use different hook for importing people if customerType === "person"
  const { validateRows, importRow } = useImportCompanies(columnsNames);

  const [actionItemsCount, setActionItemsCount] = useState(0);

  const parseError = useErrorParser(customerType);

  const [searchText, setSearchText] = useState("");
  const [statuses, setStatuses] = useState<Array<IMPORT_STATUS>>([]);

  const updateRow = (row: ImportRowData, selected?: boolean) => {
    setRowsMap((prevState) => ({ ...prevState, [row.id]: row }));

    if (!isNil(selected)) {
      setRowSelection((prevState) => {
        if (selected) {
          return { ...prevState, [row.id]: true };
        }

        const result = { ...prevState };
        delete result[row.id];

        return result;
      });
    }
  };

  const handleFileRead = (rows: ImportRowData[], filename: string, columnsCount: number) => {
    reset();

    const importedRowsMap: Record<string, ImportRowData> = {};

    validateRows(rows, columnsNames, (row) => (importedRowsMap[row.id] = row));

    setRowsMap(importedRowsMap);

    // do not auto select rows that failed validation
    // the code is using for each instead of filter in order to improve performance for large files
    const selection: Record<string, boolean> = {};
    Object.values(importedRowsMap).forEach((row) => {
      if (row.status === "READY_FOR_IMPORT") {
        selection[row.id] = true;
      }
    });
    setRowSelection(selection);

    setFilename(filename);

    const ignoreColumnCount = Math.max(0, columnsCount - defaultColumns.length);
    // fill the columns names array with columns for all the found cells in the file
    setColumnNames((prevState) => [...prevState, ...Array(ignoreColumnCount).fill(IGNORE_COLUMN)]);
  };

  const clearSelection = (row: ImportRowData) =>
    setRowSelection((prevState) => (delete prevState[row.id] ? prevState : prevState));

  const handleSetColumnNames = useCallback(
    (updatedColumnNames: string[]) => {
      setColumnNames(updatedColumnNames);

      // previous columns were invalid and updated columns are valid
      const previousColumnsStateChanged =
        !isValidColumnsNames(columnsNames, validationColumns) &&
        isValidColumnsNames(updatedColumnNames, validationColumns);

      const validationColumnsChanged = validationColumns.some(
        (columnName) => columnsNames.indexOf(columnName) !== updatedColumnNames.indexOf(columnName)
      );

      // if validation columns have changed or previous columns were invalid (but now they are valid) -
      // re-run rows validation
      const shouldValidateRows = validationColumnsChanged || previousColumnsStateChanged;

      if (shouldValidateRows) {
        validateRows(rows, updatedColumnNames, (row, hasError) => updateRow(row, !hasError));
      }
    },
    [rowsMap, columnsNames.join("-"), validationColumns.join("-")]
  );

  // Import customers mutation
  const importRowMutation = useMutation(importRow, {
    onMutate: (row) => updateRow({ ...row, status: "IMPORTING" }),
    onSettled: (response, error, row) => clearSelection(row),
    onSuccess: (response, row) => updateRow({ ...row, status: "IMPORTED" }, false),
    onError: (error: ErrorType, row) => {
      // error status 409 means company already exists
      const { generalError, fieldsErrorsMap } = parseError(error);
      updateRow({
        ...row,
        status: "IMPORT_ERROR",
        errorMessage: generalError || [...fieldsErrorsMap.values()].join(", ")
      });
    }
  });

  const importRows = useCallback(() => {
    let count = 0;

    Object.keys(rowSelection).forEach((rowId) => {
      const row = rowsMap[rowId];
      if (rowSelection[rowId] && row.status === "READY_FOR_IMPORT") {
        count++;
        importRowMutation.mutate(rowsMap[rowId]);
      }
    });

    setActionItemsCount(count);
  }, [rowsMap, rowSelection]);

  const reset = useCallback(() => {
    setRowsMap({});
    setFilename("");
    setRowSelection({});
    setColumnNames([...defaultColumns]);
    setUseFirstRowAsHeaders(false);
    setSearchText("");
    setStatuses([]);
    setActionItemsCount(0);
  }, []);

  const importedCount = useMemo(
    () => (actionItemsCount > 0 && mutationsCount === 0 ? rows.filter((row) => row.status === "IMPORTED").length : 0),
    [rows, actionItemsCount, mutationsCount]
  );

  return {
    rows,
    columnsNames,
    headers,
    rowSelection,
    filters: {
      searchText,
      statuses
    },
    filename,
    actionItemsCount,
    validationColumns,
    importedCount,
    useFirstRowAsHeaders,
    setUseFirstRowAsHeaders,
    handleFileRead,
    setRowSelection,
    setColumnNames: handleSetColumnNames,
    setStatuses,
    setSearchText,
    importRows,
    reset
  };
};

export default useImportCustomersPage;
