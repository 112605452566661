import React from "react";
import FlipMove from "react-flip-move";
import { Card, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { createNewMeasure, isMeasureCompleted } from "../../helpers/measures.helpers";
import { swapElements } from "../../utils/collectionUtils";
import MeasuresHeader from "../CustomerReviewMeasures/MeasuresHeader";
import MeasurePopover from "../MeasurePopover/MeasurePopover";
import { Direction } from "../MoveButtons/MoveButtons";

import Measure from "./Measure/Measure";

type CustomerReviewMeasuresSectionProps = {
  measures: api.Measure[];
  readonly?: boolean;
  onChange(measures: api.Measure[]): void;
};

const CustomerReviewMeasuresSection = (props: CustomerReviewMeasuresSectionProps) => {
  const { measures, readonly, onChange } = props;

  const handleAdd = () => onChange([createNewMeasure(), ...measures]);

  const handleRemove = (id?: string) => onChange(measures.filter((measure) => measure.id !== id));

  const handleMeasureChange = (measure: api.Measure) =>
    onChange(measures.map((currentMeasure) => (currentMeasure.id === measure.id ? measure : currentMeasure)));

  const handleOrderChange = (index: number, direction: Direction) => {
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    onChange(swapElements(measures, index, targetIndex));
  };

  return (
    <Card>
      <Card.Section>
        <VerticalStack gap="4">
          <MeasuresHeader measures={measures}>
            <MeasurePopover measures={measures} disabled={readonly} onChange={onChange} onNewMeasure={handleAdd} />
          </MeasuresHeader>
          <FlipMove>
            {measures.map((measure, index) => (
              <Measure
                key={measure.id}
                existingMeasures={measures}
                isFirst={index === 0}
                isLast={index === measures.length - 1}
                measure={measure}
                readonly={readonly || isMeasureCompleted(measure)}
                onRemove={() => handleRemove(measure.id)}
                onChange={handleMeasureChange}
                onOrderChange={(direction) => handleOrderChange(index, direction)}
              />
            ))}
          </FlipMove>
        </VerticalStack>
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewMeasuresSection;
