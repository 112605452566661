import React, { useMemo } from "react";
import { Card, Page, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import ImportCustomersTable from "../../components/ImportCustomersTable/ImportCustomersTable";
import { isImportDataError } from "../../helpers/import-customers.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ImportRowData } from "../../types/ImportRow";

import FileImport from "./FileImport";
import ImportCustomersFooter from "./ImportCustomersFooter";
import ImportCustomersHeader from "./ImportCustomersHeader";
import ImportCustomersInstructionsBanner from "./ImportCustomersInstructionsBanner";
import { isValidColumnsNames } from "./ImportCustomersPage.helpers";
import ImportCustomersPagination from "./ImportCustomersPagination";
import ImportCustomersValidationBanner from "./ImportCustomersValidationBanner";
import useImportCustomersPage from "./useImportCustomersPage";
import useImportCustomersTable from "./useImportCustomersTable";

const ImportCustomersPage = () => {
  const f = useFormatMessage();

  const customerType: api.CustomerType = "company";

  const {
    rows,
    rowSelection,
    columnsNames,
    headers,
    filters,
    filename,
    actionItemsCount,
    validationColumns,
    importedCount,
    useFirstRowAsHeaders,
    setUseFirstRowAsHeaders,
    handleFileRead,
    setRowSelection,
    setColumnNames,
    setStatuses,
    setSearchText,
    importRows,
    reset
  } = useImportCustomersPage(customerType);

  const table = useImportCustomersTable({
    rows,
    columnsNames,
    customerType,
    headers,
    filters,
    rowSelection,
    onSelectionChange: setRowSelection,
    onColumnChange: setColumnNames
  });

  const visibleRowsCount = table.getFilteredRowModel().rows.length;

  const errorsCount = useMemo(
    () => table.getFilteredRowModel().rows.filter((row) => isImportDataError(row.original)).length,
    [table.getFilteredRowModel()]
  );

  const handleReset = () => {
    table.reset();
    reset();
  };

  const handleRowsRead = (rows: ImportRowData[], filename: string, columnsCounts: number) => {
    table.reset();
    handleFileRead(rows, filename, columnsCounts);
  };

  const selectedRowsCount = Object.keys(rowSelection).length;
  const isValidColumns = isValidColumnsNames(columnsNames, validationColumns);

  const disableImport = !isValidColumns || selectedRowsCount === 0;

  return (
    <Page
      title={
        customerType === "company"
          ? f("import-customers.wizard.page.title.companies")
          : f("import-customers.wizard.page.title.people")
      }
    >
      <VerticalStack gap="4">
        <FileImport onRowsRead={handleRowsRead} filename={filename} />
        <Card>
          {rows.length === 0 && (
            <Card.Section>
              <ImportCustomersInstructionsBanner />
            </Card.Section>
          )}
          {rows.length > 0 && (
            <Card.Section>
              <VerticalStack gap="4">
                <ImportCustomersHeader
                  statuses={filters.statuses}
                  useFirstRowAsHeaders={useFirstRowAsHeaders}
                  setUseFirstRowAsHeaders={setUseFirstRowAsHeaders}
                  totalCount={rows.length}
                  visibleRowsCount={visibleRowsCount}
                  selectedRowsCount={selectedRowsCount}
                  errorsCount={errorsCount}
                  importedCount={importedCount}
                  onFilterQueryChange={setSearchText}
                  onStatusesChanges={setStatuses}
                />
                <ImportCustomersValidationBanner columnsNames={columnsNames} validationColumns={validationColumns} />
                <ImportCustomersTable table={table} />
                <ImportCustomersPagination table={table} />
              </VerticalStack>
            </Card.Section>
          )}
        </Card>
        <ImportCustomersFooter
          customerType={customerType}
          disableImport={disableImport}
          actionItemsCount={actionItemsCount}
          onReset={handleReset}
          onImport={importRows}
        />
      </VerticalStack>
    </Page>
  );
};

export default ImportCustomersPage;
