import React from "react";

import useCustomerPendingTasks from "../../hooks/useCustomerPendingTasks";
import { Customer } from "../../types/utilities";
import Button from "../extensions/Button";

type PendingTasksButtonProps = {
  customer: Customer;
  haveOwnershipUpdates?: boolean;
};

const PendingTasksButton = (props: PendingTasksButtonProps) => {
  const { customer, haveOwnershipUpdates } = props;

  const taskStatuses = useCustomerPendingTasks(customer, haveOwnershipUpdates);

  if (taskStatuses.length === 0) {
    return null;
  }

  // TODO render as Split button if there is more than 1 task statuses

  const topTaskStatus = taskStatuses[0];
  const { url, urlLabel } = topTaskStatus;

  if (url && urlLabel) {
    return (
      <Button size="slim" url={url}>
        {urlLabel}
      </Button>
    );
  }

  return null;
};

export default PendingTasksButton;
