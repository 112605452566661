import React from "react";
import { Text } from "@shopify/polaris";

import api from "../api";
import { getTranslationText } from "../helpers/display.helpers";
import useGetAssignmentIndustryChoices from "../hooks/useGetAssignmentIndustriesAndPurposes";
import useLanguage from "../hooks/useLanguage";

interface AssignmentIndustryProps {
  assignment: api.Assignment | api.CustomerAssignmentDetails;
}

const AssignmentIndustry = (props: AssignmentIndustryProps) => {
  const { assignment } = props;

  const language = useLanguage();
  const { industries } = useGetAssignmentIndustryChoices();

  const industry = industries.find((industry) => industry.value === assignment.industry_type);

  return (
    <Text as="span" fontWeight="bold">
      {industry ? getTranslationText(language, industry.label) : ""}
    </Text>
  );
};

export default AssignmentIndustry;
