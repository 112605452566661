import React, { useState } from "react";
import { Button, HorizontalStack, Modal, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../../../api";
import RoleTypes from "../../../../constants/role-types";
import { getBirthDate, getFullName } from "../../../../helpers/display.helpers";
import useDisclosureAssignment from "../../../../hooks/useDisclosureAssignment";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import { useCustomerDetailsQueryData } from "../../../../hooks/useQueryData";
import useValidateDisclosureForm from "../../../../hooks/useValidateDisclosureForm";
import { updateItem } from "../../../../utils/collectionUtils";
import DisclosureSignatureForm from "../../../DisclosureSignatureForm/DisclosureSignatureForm";
import DisplayName from "../../../DisplayName/DisplayName";
import ErrorPanel from "../../../ErrorPanel/ErrorPanel";
import SectionTile from "../../../SectionTile/SectionTile";

export type UpdateSignatureModalProps = {
  customerId: string;
  customerType: api.CustomerType;
  signature: api.Signature;
  existingEmails: string[];
  assignments?: api.Assignment[];
  onSignatureUpdated(signature: api.Signature): void;
  onClose(): void;
};

const UpdateSignatureModal = (props: UpdateSignatureModalProps) => {
  const {
    customerId,
    customerType,
    signature: initialSignature,
    existingEmails,
    assignments,
    onSignatureUpdated,
    onClose
  } = props;

  const f = useFormatMessage();
  const validateForm = useValidateDisclosureForm();

  const updateCustomerQueryData = useCustomerDetailsQueryData({ type: customerType, id: customerId });

  const updateAssignmentInCustomerDetails = (assignment: api.Assignment) =>
    updateCustomerQueryData((queryData) => {
      if (queryData.assignments) {
        queryData.assignments = updateItem(queryData.assignments, assignment);
      }
    });

  const [signature, setSignature] = useState(initialSignature);
  const { selectedAssignment, setSelectedAssignment, shouldUpdateAssignment, updateAssignmentMutation } =
    useDisclosureAssignment(customerId, signature.assignment_id, assignments, updateAssignmentInCustomerDetails);
  const [isValid, setIsValid] = useState(() => validateForm([signature]));
  const [validationError, setValidationError] = useState<string>();

  const checkForChanges = (signature: api.Signature): boolean => {
    return (
      signature.use_standard_disclosure !== initialSignature.use_standard_disclosure ||
      signature.use_pep_disclosure !== initialSignature.use_pep_disclosure ||
      signature.use_verification !== initialSignature.use_verification ||
      signature.email !== initialSignature.email ||
      signature.send_email !== initialSignature.send_email ||
      signature.language !== initialSignature.language ||
      signature.questionnaire_ids?.join() !== initialSignature.questionnaire_ids?.join()
    );
  };

  const onSignatureChange = (signature: api.Signature, isValid: boolean) => {
    let hasNoDuplicates = true;
    const containsChanges = checkForChanges(signature);

    // Handle duplicate e-mails
    if (existingEmails.includes(signature.email || "")) {
      // If the signature being updates, uses the same duplicate
      // email, allow it
      if (initialSignature.email === signature.email) {
        setValidationError(undefined);
      } else {
        hasNoDuplicates = false;
        setValidationError(f("modals.update.signature.error.duplicate-email"));
      }
    } else {
      setValidationError(undefined);
    }

    setSignature(signature);
    setIsValid(isValid && hasNoDuplicates && containsChanges);
  };

  const updateSignatureMutation = useMutation(
    async () => {
      setValidationError(undefined);

      const payload: api.UpdateSignatureRequest = {
        email: signature.email,
        send_email: signature.send_email,
        use_standard_disclosure: signature.use_standard_disclosure,
        use_pep_disclosure: signature.use_pep_disclosure,
        use_verification: signature.use_verification,
        language: signature.language,
        questionnaire_ids: signature.questionnaire_ids,
        assignment_id: selectedAssignment?.id
      };

      const apiCall = signature.role ? api.updateCompanySignature : api.updatePersonSignature;

      if (shouldUpdateAssignment) {
        await updateAssignmentMutation.mutateAsync();
      }

      return apiCall(customerId, signature.id, payload);
    },
    {
      onSuccess: (signature) => {
        onSignatureUpdated(signature);
        updateCustomerQueryData((customer) =>
          customer.disclosure_documents.forEach(
            (disclosureDocument) =>
              (disclosureDocument.signatures = updateItem(disclosureDocument.signatures, signature))
          )
        );
        onClose();
      }
    }
  );

  const title = signature.role ? f(RoleTypes[signature.role.type]) : f("modals.update.signature.customer");

  return (
    <Modal large open={true} onClose={onClose} title={f("modals.update.signature.title")}>
      <Modal.Section>
        <VerticalStack gap="4">
          <SectionTile title={title} gap="1">
            <DisplayName
              type="person"
              name={getFullName(signature.person)}
              birthDate={getBirthDate(signature.person)}
            />
          </SectionTile>

          <DisclosureSignatureForm
            signature={signature}
            customerId={customerId}
            customerType={customerType}
            signatureName={getFullName(signature.person)}
            onChange={onSignatureChange}
            assignments={assignments}
            selectedAssignment={selectedAssignment}
            onAssignmentChange={setSelectedAssignment}
            readonly={updateSignatureMutation.isLoading}
          />

          {(validationError || updateSignatureMutation.isError) && (
            <ErrorPanel message={validationError || updateSignatureMutation.error} />
          )}

          <HorizontalStack gap="4" align="end">
            <Button onClick={onClose} disabled={updateSignatureMutation.isLoading}>
              {f("default.cancel")}
            </Button>
            <Button
              primary
              onClick={updateSignatureMutation.mutate}
              loading={updateSignatureMutation.isLoading}
              disabled={!isValid || updateSignatureMutation.isLoading}
            >
              {f("modals.update.signature.button")}
            </Button>
          </HorizontalStack>
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};

export default UpdateSignatureModal;
