import { ReactNode } from "react";
import { Button, Card, Collapsible, Divider, HorizontalStack } from "@shopify/polaris";

import { MATCH_ATTRIBUTE_ITEMS_CUTOFF } from "../../constants/matches";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import CondensedText from "../CondensedText/CondensedText";

type MatchAttributesCollapsibleCardSectionsProps<T> = {
  title: string;
  elements: T[] | undefined;
  renderElement(element: T): ReactNode;
};

function MatchAttributesCollapsibleCardSections<T>(props: MatchAttributesCollapsibleCardSectionsProps<T>) {
  const { title, elements, renderElement } = props;
  const f = useFormatMessage();

  const [showMoreSources, toggleShowMoreSources] = useOpenClose();

  if (!elements || elements.length === 0) {
    return null;
  }

  return (
    <>
      {elements.slice(0, MATCH_ATTRIBUTE_ITEMS_CUTOFF).map((element, i) => (
        <Card.Section title={i === 0 ? <CondensedText>{title}</CondensedText> : undefined} key={i}>
          {renderElement(element)}
        </Card.Section>
      ))}
      {elements.length > MATCH_ATTRIBUTE_ITEMS_CUTOFF && (
        <>
          <Divider />
          <Collapsible id={`match-card-sections-${title}`} open={showMoreSources}>
            {elements.slice(MATCH_ATTRIBUTE_ITEMS_CUTOFF).map((element, i) => (
              <Card.Section key={MATCH_ATTRIBUTE_ITEMS_CUTOFF + i}>{renderElement(element)}</Card.Section>
            ))}
          </Collapsible>
          <Card.Section>
            <HorizontalStack>
              <Button onClick={toggleShowMoreSources} disclosure={showMoreSources ? "up" : "down"}>
                {showMoreSources
                  ? f("default.hide")
                  : f("common.labels.show-more-with-count", {
                      count: elements.length - MATCH_ATTRIBUTE_ITEMS_CUTOFF
                    })}
              </Button>
            </HorizontalStack>
          </Card.Section>
        </>
      )}
    </>
  );
}

export default MatchAttributesCollapsibleCardSections;
