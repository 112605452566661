import React from "react";
import { VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../../api";
import { hasOwnershipUpdates } from "../../../helpers/ownership-updates.helpers";
import useOpenClose from "../../../hooks/useOpenClose";
import useQueryData from "../../../hooks/useQueryData";
import { getCustomerQueryKey } from "../../../types/utilities";
import ErrorPanel from "../../ErrorPanel/ErrorPanel";

import CompanyFinishedOwnershipUpdatesBanner from "./CompanyFinishedOwnershipUpdatesBanner";
import { hasMultipleShareClasses } from "./CompanyOwnersHeader.helpers";
import CompanyOwnershipUpdatesBanner from "./CompanyOwnershipUpdatesBanner";
import OutstandingSharesBanner from "./OutstandingSharesBanner";
import OwnershipUpdatesCompleteModal from "./OwnershipUpdatesCompleteModal";
import SharesClassesBanner from "./SharesClassesBanner";

interface CompanyOwnersHeaderProps {
  customer: api.CompanyCustomerDetailResponse;
  onConfirmAllLoading(loading: boolean): void;
}

const CompanyOwnersHeader = (props: CompanyOwnersHeaderProps) => {
  const { customer, onConfirmAllLoading } = props;

  const { updateQueryData } = useQueryData<api.CompanyCustomerDetailResponse>(getCustomerQueryKey(customer));

  const [isConfirmedAllModalOpen, toggleConfirmedAllModal, closeConfirmedAllModal] = useOpenClose();

  const confirmAllOwnershipUpdatesMutation = useMutation(() => api.confirmAllOwnershipUpdates(customer.id), {
    onMutate: () => onConfirmAllLoading(true),
    onSuccess: (updatedOwners) => {
      updateQueryData((queryData) => {
        queryData.beneficial_owners = updatedOwners.owners;
        queryData.updates.ownership = [];
      });
      toggleConfirmedAllModal();
      onConfirmAllLoading(false);
    }
  });

  // ownershipUpdatesExist means that the company has unhandled_ownership_updates > 0
  const ownershipUpdatesExist = hasOwnershipUpdates(customer);

  // hasUnresolvedOwnersUpdates means that there are one or more updates in updates.ownership
  const hasUnresolvedOwnersUpdates =
    ownershipUpdatesExist && Boolean(customer.updates.ownership && customer.updates.ownership.length > 0);

  // finishedResolvingOwnershipUpdates - the company HAD ownership updates and they were all resolved
  const finishedResolvingOwnershipUpdates = ownershipUpdatesExist && !hasUnresolvedOwnersUpdates;

  const showSharesClassesBanner = hasMultipleShareClasses(customer.share_classes);

  return (
    <VerticalStack gap="4">
      {customer.has_outstanding_shares && (
        <OutstandingSharesBanner
          outstandingShares={customer.outstanding_share_count}
          totalShares={customer.total_share_count}
        />
      )}
      {showSharesClassesBanner && <SharesClassesBanner customer={customer} />}
      {ownershipUpdatesExist && (
        <VerticalStack gap="4">
          {hasUnresolvedOwnersUpdates && (
            <CompanyOwnershipUpdatesBanner
              disabled={!hasUnresolvedOwnersUpdates}
              loading={confirmAllOwnershipUpdatesMutation.isLoading}
              onConfirmAll={() => confirmAllOwnershipUpdatesMutation.mutate()}
            />
          )}
          {finishedResolvingOwnershipUpdates && <CompanyFinishedOwnershipUpdatesBanner />}
          {confirmAllOwnershipUpdatesMutation.isError && (
            <ErrorPanel message={confirmAllOwnershipUpdatesMutation.error} />
          )}
        </VerticalStack>
      )}
      {isConfirmedAllModalOpen && (
        <OwnershipUpdatesCompleteModal customerId={customer.id} onClose={closeConfirmedAllModal} />
      )}
    </VerticalStack>
  );
};

export default CompanyOwnersHeader;
