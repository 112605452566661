import { useId, useState } from "react";
import { InlineError, Label, TextField } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import useNonInitialEffect from "../../hooks/useNonInitialEffect";
import { ErrorType } from "../../types/utilities";
import { formatDate } from "../../utils/dateUtils";
import { isNumberInRange } from "../../utils/numbersUtils";
import { isEmptyString } from "../../utils/stringUtils";
import { noop } from "../../utils/util";
import CondensedText from "../CondensedText/CondensedText";

interface PartialDateInputProps {
  // date value should be in the format of yyyy-mm-dd
  // (with leading zeroes for single digits months and days, example: 1989-07-01)
  dateValue?: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: ErrorType;
  onChange(value: string, year: string): void;
}

const PartialDateInput = (props: PartialDateInputProps) => {
  const { dateValue, label, required, disabled, error, onChange } = props;

  const f = useFormatMessage();
  const id = useId();

  const [touched, setTouched] = useState(false);

  const initialYear = dateValue?.split("-")[0];

  const [year, setYear] = useState(initialYear || "");
  const [month, setMonth] = useState(dateValue?.split("-")[1] || "");
  const [day, setDay] = useState(dateValue?.split("-")[2] || "");

  const isPartiallyFilled = (isEmptyString(day) && isEmptyString(month)) || year.length < 4;

  useNonInitialEffect(() => {
    // always return the changed year, but if the form is partially filled
    // return an empty value
    onChange(isPartiallyFilled ? "" : formatDate(year, month, day), year);
  }, [day, month, year]);

  const handleOnBlur = () => setTouched(true);

  const getOnChangeHandler = (setter: (value: string) => void, max: number) => (value: string) => {
    if (!isPartiallyFilled) {
      setTouched(true);
    }

    const numericValue = parseInt(value, 10);

    if (isEmptyString(value) || isNumberInRange(numericValue, 0, max)) {
      setter(value);
    }
  };

  return (
    <Wrapper>
      <Label id={id}>
        <StyledLabel required={required}>{label}</StyledLabel>
      </Label>
      <TextFieldsWrapper>
        <TextField
          type="number"
          label={f("default.days", { days: 1 })}
          min={1}
          max={31}
          maxLength={2}
          inputMode={"numeric"}
          value={day}
          onChange={getOnChangeHandler(setDay, 31)}
          autoComplete="off"
          disabled={disabled}
          onBlur={initialYear ? handleOnBlur : noop}
          error={touched && !!error}
        />
        <TextField
          type="number"
          label={f("default.months", { months: 1 })}
          min={1}
          max={12}
          maxLength={2}
          inputMode={"numeric"}
          value={month}
          onChange={getOnChangeHandler(setMonth, 12)}
          autoComplete="off"
          disabled={disabled}
          onBlur={initialYear ? handleOnBlur : noop}
          error={touched && !!error}
        />
        <TextField
          type="number"
          label={f("default.years", { years: 1 })}
          maxLength={4}
          inputMode={"numeric"}
          value={year}
          onChange={getOnChangeHandler(setYear, 9999)}
          autoComplete="off"
          disabled={disabled}
          onBlur={handleOnBlur}
          error={touched && !!error}
        />
      </TextFieldsWrapper>
      {touched && error && <InlineError fieldID={id} message={error} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--p-space-1);
  position: relative;

  & .Polaris-Labelled__LabelWrapper {
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(CondensedText)<{ required?: boolean }>`
  ${Wrapper} & {
    position: absolute;
    top: calc(-1 * var(--p-space-4));
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }

  ${({ required }) =>
    required &&
    `
      &::after {
        content: "*";
        color: var(--p-text-critical);
        margin-left: var(--p-space-1);
      }
  `}
`;

const TextFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: var(--p-space-2);

  & > * {
    flex: 1;
  }

  & input {
    max-width: calc(6ch + 2 * var(--p-space-3));
    text-align: center;
  }

  & .Polaris-TextField__Spinner {
    display: none;
  }

  & .Polaris-Label {
    text-transform: capitalize;
  }
`;
export default PartialDateInput;
