export enum ROUTES {
  HOME = "/",
  SETTINGS_USERS = "/settings/users",
  SETTINGS_TAGS = "/settings/tags",
  SETTINGS_QUESTIONNAIRES_LIST = "/settings/questionnaires",
  SETTINGS_QUESTIONNAIRE_DETAILS = "/settings/questionnaire/:id",
  SETTINGS_REVIEW_CHECKLISTS_LIST = "/settings/checklists",
  SETTINGS_REVIEW_CHECKLIST_DETAILS_NEW = "/settings/checklist/new",
  SETTINGS_REVIEW_CHECKLIST_DETAILS = "/settings/checklist/:id",
  SETTINGS_FOLLOWUP = "/settings/followup",
  SETTINGS_FOLLOWUP_NOTIFICATIONS = "/settings/followup-notifications",
  SETTINGS_AUTO_REMINDERS = "/settings/reminders",
  SETTINGS_MEASURES = "/settings/measures",
  SETTINGS_ONBOARDING = "/settings/onboarding",
  SETTINGS_DISCLOSURE = "/settings/disclosure",
  SETTINGS_GUIDELINE = "/settings/guideline",
  SETTINGS_CUSTOMER_NOTIFICATIONS = "/settings/customer-info-notifications",
  DASHBOARD = "/dashboard",
  COMPANY_DETAILS = "/companies/:id",
  PERSON_DETAILS = "/persons/:id",
  MATCH_CASE_DETAILS = "/customers/:customerId/match-cases/:id",
  ASSIGNMENT_DETAILS = "/customers/:customerId/assignment/:id",
  CUSTOMER_REVIEW = "/customers/:customerId/review/:id",
  CUSTOMER_REVIEW_NEW = "/customers/:customerId/review/new",
  CUSTOMER_ASSIGNMENT_REVIEW_NEW = "/customers/:customerId/assignment/:assignmentId/review/new",
  COMPANY_ONBOARDING_ASSIGNMENTS = "/companies/:id/assignments",
  COMPANY_ONBOARDING_SCREENING = "/companies/:id/screening",
  COMPANY_ONBOARDING_DISCLOSURES = "/companies/:id/request-onboarding-disclosures",
  COMPANY_ONBOARDING_SUMMARY = "/companies/:id/summary",
  COMPANY_REQUEST_DISCLOSURES = "/companies/:id/request-disclosure",
  IMPORT_CUSTOMERS = "/import/companies",
  COMPANY_CHANGE_SCREENING = "/companies/:id/change-screening",
  COMPANY_ASSIGNMENT_REQUEST_DISCLOSURES = "/companies/:id/assignment/:assignmentId/request-disclosure",
  PERSON_ASSIGNMENT_REQUEST_DISCLOSURES = "/persons/:id/assignment/:assignmentId/request-disclosure",
  PERSON_REQUEST_DISCLOSURES = "/persons/:id/request-disclosure",
  PERSON_CHANGE_SCREENING = "/persons/:id/change-screening",
  ONBOARDING_NEW_PERSON = "/onboarding/person/new",
  ONBOARDING_SAVED_PERSON = "/onboarding/person/:id",
  MANUAL_COMPANY_ONBOARDING_EDIT = "/manual-companies/:id/edit",
  MANUAL_COMPANY_ONBOARDING_ASSIGNMENTS = "/manual-companies/:id/assignments",
  MANUAL_COMPANY_ONBOARDING_SCREENING = "/manual-companies/:id/screening",
  MANUAL_COMPANY_ONBOARDING_DISCLOSURES = "/manual-companies/:id/request-onboarding-disclosures",
  MANUAL_COMPANY_ONBOARDING_SUMMARY = "/manual-companies/:id/summary",
  CUSTOMERS = "/customers",
  COMPANY_LOOKUP = "/lookup",
  COMPANY_LOOKUP_RESULT = "/lookup/:orgId",
  PROJECTS_LIST = "/projects",
  PLAN_UPGRADE_PROJECTS_LIST = "/projects-upgrade-plan",
  PROJECT_DETAILS = "/project/:id",
  ACCESS_DENIED = "/denied",
  EXPIRED = "/expired",
  LOGOUT = "/logout"
}

// list of company onboarding steps urls - in order
export const ONBOARDING_COMPANY_WIZARD_ROUTES = [
  ROUTES.COMPANY_ONBOARDING_SCREENING,
  ROUTES.COMPANY_ONBOARDING_ASSIGNMENTS,
  ROUTES.COMPANY_ONBOARDING_DISCLOSURES,
  ROUTES.COMPANY_ONBOARDING_SUMMARY
];

// list of manual company onboarding steps urls - in order
export const ONBOARDING_MANUAL_COMPANY_WIZARD_ROUTES = [
  ROUTES.MANUAL_COMPANY_ONBOARDING_EDIT,
  ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING,
  ROUTES.COMPANY_ONBOARDING_ASSIGNMENTS,
  ROUTES.MANUAL_COMPANY_ONBOARDING_DISCLOSURES,
  ROUTES.MANUAL_COMPANY_ONBOARDING_SUMMARY
];

export const ONBOARDING_PERSON_ROUTES = [ROUTES.ONBOARDING_NEW_PERSON, ROUTES.ONBOARDING_SAVED_PERSON];
