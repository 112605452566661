import React from "react";
import { Stack } from "@shopify/polaris";

import PhoneIcon from "../../../assets/icons/phone.svg";
import { MAX_LENGTH_OF_TEXT_INPUT_FIELD } from "../../constants/person";
import useFormatMessage from "../../hooks/useFormatMessage";
import { PersonInformation } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import EmailField from "../EmailField/EmailField";
import Icon from "../extensions/Icon";
import KDKTextField from "../KDKTextField/KDKTextField";

import NationalIdInput from "./NationalIdInput/NationalIdInput";

type PersonContactDetailsProps = {
  value: PersonInformation;
  disabled?: boolean;
  fieldsErrorMap?: Map<keyof PersonInformation, string>;
  onChange(value: PersonInformation, isValid?: boolean): void;
};

const PersonContactDetails = (props: PersonContactDetailsProps) => {
  const { value, disabled, fieldsErrorMap = new Map(), onChange } = props;

  const f = useFormatMessage();

  return (
    <Stack distribution={"fillEvenly"}>
      {/* set key={value.national_id_country} to re-render the NationalIdInput if value.national_id_country changes */}
      <NationalIdInput
        key={value.national_id_country}
        country={value.national_id_country}
        nationalId={value.national_id}
        onChange={(isValid, country, nationalId) => {
          onChange({ ...value, national_id_country: country, national_id: nationalId }, isValid);
        }}
        disabled={disabled}
        enableCountrySelection={isEmptyString(value.national_id_country)}
        error={fieldsErrorMap.get("national_id") || fieldsErrorMap.get("national_id_country")}
        maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
      />
      <KDKTextField
        value={value.phone || ""}
        label={f("common.labels.mobile-phone")}
        onChange={(newValue) => onChange({ ...value, phone: newValue })}
        placeholder={f("common.labels.mobile-phone")}
        disabled={disabled}
        error={fieldsErrorMap.get("mobile_number")}
        prefix={<Icon source={PhoneIcon} />}
        autoComplete="off"
        maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
      />
      <EmailField
        value={value.email || ""}
        label={f("common.labels.email")}
        onChange={(newValue) => onChange({ ...value, email: newValue })}
        disabled={disabled}
        error={fieldsErrorMap.get("email")}
      />
    </Stack>
  );
};

export default PersonContactDetails;
