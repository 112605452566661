import { useEffect, useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { HIGH_RISK_FIELDS } from "../../constants/customer-reviews";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { REVIEW_FIELDS_INCLUDED_IN_CHECKLISTS } from "../../constants/review-checklists";
import { ROUTES } from "../../constants/routes";
import { isActiveAssignment } from "../../helpers/assignments.helpers";
import { createChecklistAnswers, isChecklistPartiallyFilled } from "../../helpers/checklists.helpers";
import { getCustomerName } from "../../helpers/display.helpers";
import {
  convertCustomerReviewToOptionalCustomerReviewRequest,
  getReviewStorageKey,
  isInvalidReviewChecklistAnswers
} from "../../helpers/reviews.helpers";
import useCustomerPath from "../../hooks/useCustomerPath";
import useCustomerReview from "../../hooks/useCustomerReview";
import useCustomerReviewBanner from "../../hooks/useCustomerReviewBanner";
import useFeatures from "../../hooks/useFeatures";
import useGetMLROUsers from "../../hooks/useGetMLROUsers";
import useGetReviewChecklists from "../../hooks/useGetReviewChecklists";
import useGetUsers from "../../hooks/useGetUsers";
import useInitialCustomerReview from "../../hooks/useInitialCustomerReview";
import useIsMLROUser from "../../hooks/useIsMLROUser";
import useReviewChecklistConditions from "../../hooks/useReviewChecklistConditions";
import { useSessionStorage } from "../../hooks/useStorage";
import { OptionalCustomerReviewRequest } from "../../types/CustomerReview";
import { OptionalChecklistAnswersRequest } from "../../types/ReviewChecklist";
import { RiskIndicatorsType } from "../../types/RiskIndicators";
import { CustomerDetails, ValueOf } from "../../types/utilities";
import { delta, hasContent, pickIds } from "../../utils/collectionUtils";
import { isEmptyString } from "../../utils/stringUtils";

const useCustomerReviewPage = (
  customer: CustomerDetails,
  riskIndicators: RiskIndicatorsType,
  reviewId?: string,
  assignmentId?: string
) => {
  const features = useFeatures();

  // search for an existing review in the reviews list
  const exitingReview = reviewId ? customer.reviews.find((review) => review.id === reviewId) : undefined;

  const isNew = !reviewId || !exitingReview;

  const initialCustomerReview = useInitialCustomerReview(customer, riskIndicators);
  // if an assignment id was passed from a URL param - set it as the initial selected assignment for the review
  initialCustomerReview.assignment_id = assignmentId;
  const [review, setReview, resetReviewIsChanged, isReviewChanged] = useSessionStorage(
    getReviewStorageKey(customer.id, reviewId),
    () => (isNew ? initialCustomerReview : convertCustomerReviewToOptionalCustomerReviewRequest(exitingReview))
  );

  //////// check if review is readonly (can be edited)
  const { isNewCustomerReviewDisabled } = useCustomerReviewBanner(customer);
  const { isEditable } = useCustomerReview(exitingReview);
  const isPossibleToApprove = Boolean(exitingReview?.is_possible_to_approve && !exitingReview.approval?.approved_by);

  const readonly = Boolean(isNewCustomerReviewDisabled || (!isNew && !isEditable));

  const originalReviewRef = useRef({ ...review });

  //////// navigate to customer details page or the assignment details page
  const history = useHistory();
  const { getCustomerPath } = useCustomerPath();

  const customerDetailsPageUrl = `${getCustomerPath(customer.id, customer.type!)}#${PAGE_ANCHORS.REVIEWS_SECTION}`;
  const previousPageURL = assignmentId
    ? generatePath(ROUTES.ASSIGNMENT_DETAILS, { customerId: customer.id, id: assignmentId })
    : customerDetailsPageUrl;

  const assignmentName = customer.assignments?.find((assignment) => assignment.id === assignmentId)?.name;
  const previousPageLabel = assignmentName || getCustomerName(customer);

  const onBackNavigation = () => {
    resetReviewIsChanged();
    history.push(previousPageURL);
  };

  //////// assignments
  // include active assignments and a previously selected assignment (even if it is not active)
  const assignments = (customer.assignments || []).filter(
    (assignment) => isActiveAssignment(assignment) || assignment.id === review.assignment_id
  );

  // use to determine if an assignment was selected (or customer relationship)
  // this is used because initially the customer relationship is not selected in the assignment selector
  const [isAssignmentRelationSelected, setIsAssignmentRelationSelected] = useState(
    // if the customer has no assignments or this is not a new review it means that isAssignmentRelationSelected is selected
    // it's either undefined which means it is the customer relation or already selected assignment
    !hasContent(assignments) || !isNew || review.assignment_id !== undefined
  );

  //////// checklists
  // save the list of checklists that were added as a result of a change in the review to they could be marked as "new"
  const [addedChecklistsIds, setAddedChecklistsIds] = useState<string[]>([]);
  const resetAddedChecklists = () => setAddedChecklistsIds([]);

  // save a list of checklists that were manually removed by the user so they would not be automatically added again
  const [manuallyRemovedChecklistIds, setManuallyRemovedChecklistsIds] = useState<string[]>([]);

  const getMatchedReviewChecklists = useReviewChecklistConditions(customer, riskIndicators);

  // check for changes in the pre-selected review checklists based on changes in the review
  // for example: if the assignment changes, or the user is marked as PEP / Sanction
  const calculateReviewChecklists = (
    checklistAnswers: OptionalChecklistAnswersRequest[] | undefined = [],
    updatedReview: OptionalCustomerReviewRequest
  ) => {
    const prevMatchedReviewChecklistsIds = checklistAnswers.map((checklistAnswer) => checklistAnswer.checklist_id);

    // get a list of checklists that were not manually removed by the user
    const newMatchedReviewChecklists = getMatchedReviewChecklists(updatedReview).filter(
      (checklist) => !manuallyRemovedChecklistIds.includes(checklist.id)
    );
    const newMatchedReviewChecklistsIds = pickIds(newMatchedReviewChecklists);

    const [checklistsIdsToAdd, checklistsIdsToRemove] = delta(
      newMatchedReviewChecklistsIds,
      prevMatchedReviewChecklistsIds
    );

    const checklistsToAdd = newMatchedReviewChecklists.filter((checklist) => checklistsIdsToAdd.includes(checklist.id));

    const reviewChecklistsAnswers = updatedReview.checklist_answers || [];

    const defaultAnswer = features.REVIEW__CHECKLIST_DEFAULT_TO_NO ? false : undefined;

    // add checklists that were added
    reviewChecklistsAnswers.push(
      ...checklistsToAdd.map((checklist) => createChecklistAnswers(checklist, defaultAnswer))
    );

    // remove checklists that removed
    checklistsIdsToRemove.forEach((checklistId) => {
      // find the checklist answers to be removed
      const removedChecklistAnswerCandidate = reviewChecklistsAnswers?.find(
        (checklistAnswer) => checklistAnswer.checklist_id == checklistId
      );

      // if the answers are not filled the they can be removed - but if they are partially filled then do not remove them
      if (!isChecklistPartiallyFilled(removedChecklistAnswerCandidate?.answers)) {
        const indexOfChecklistAnswer = removedChecklistAnswerCandidate
          ? reviewChecklistsAnswers.indexOf(removedChecklistAnswerCandidate)
          : -1;

        if (indexOfChecklistAnswer > -1) {
          reviewChecklistsAnswers?.splice(indexOfChecklistAnswer, 1);
        }
      }
    });

    setAddedChecklistsIds(checklistsIdsToAdd);

    return reviewChecklistsAnswers;
  };

  // load the list of review checklists
  // if the review checklists are not fetched yet - update the review once the checklists have been fetched
  const { data: reviewChecklists } = useGetReviewChecklists();
  const reviewChecklistsWereLoaded = hasContent(reviewChecklists?.checklists);
  useEffect(() => {
    if (!readonly && reviewChecklistsWereLoaded) {
      const checklistsAnswers = calculateReviewChecklists(originalReviewRef.current.checklist_answers, review);

      setReview({ ...review, checklist_answers: checklistsAnswers });
    }
  }, [reviewChecklistsWereLoaded, readonly]);

  //////// automatic risk and approvers
  const isMLROUser = useIsMLROUser();
  const { isSingleUser } = useGetUsers();
  const mlroUsers = useGetMLROUsers();

  // the current user is not MLRO AND it is not the single user of the system
  const canBeMarkedForApproval = features.AUTOMATIC_CUSTOMER_REVIEW_APPROVAL && !isMLROUser && !isSingleUser;

  const setAutomaticRiskAndApprovers = (
    updatedReview: OptionalCustomerReviewRequest,
    fieldName: keyof OptionalCustomerReviewRequest,
    value: ValueOf<OptionalCustomerReviewRequest>
  ) => {
    const isMarkedAsPepOrSanctioned = value === true;
    const wasUnmarkedAsPepOrSanctioned = value === false && originalReviewRef.current[fieldName] === true;

    // if the customer is marked as pep or sanctioned they should have a HIGH risk level
    // this is not a validation - so the user can change the risk level after these changes are set
    if (isMarkedAsPepOrSanctioned) {
      updatedReview.risk_level = "HIGH";
    }

    // if the customer is marked as pep or sanctioned or was previously marked as pep or sanctioned
    // and the current user is not MLRO AND it is not the single user of the system
    // they should require approval
    // this is not a validation - so the user can change the required approval after these changes are set
    if ((isMarkedAsPepOrSanctioned || wasUnmarkedAsPepOrSanctioned) && canBeMarkedForApproval) {
      updatedReview.requires_approval = true;

      // if MLRO user is defined and not included in the approval user ids - add it
      if (hasContent(mlroUsers)) {
        const approvalUserIds = updatedReview.approval_user_ids || [];

        // add mlro users if not selected
        updatedReview.approval_user_ids = [
          ...new Set([...approvalUserIds, ...mlroUsers.map((user) => user.id)]).values()
        ];
      }
    }
  };

  const handleReviewChange = (
    fieldName: keyof OptionalCustomerReviewRequest,
    value: ValueOf<OptionalCustomerReviewRequest>
  ) => {
    const updatedReview = { ...review, [fieldName]: value };

    if (HIGH_RISK_FIELDS.includes(fieldName)) {
      setAutomaticRiskAndApprovers(updatedReview, fieldName, value);
    }

    if (fieldName === "assignment_id") {
      setIsAssignmentRelationSelected(true);
    }

    if (REVIEW_FIELDS_INCLUDED_IN_CHECKLISTS.includes(fieldName)) {
      updatedReview.checklist_answers = calculateReviewChecklists(review.checklist_answers, updatedReview);
    }

    if (fieldName === "checklist_answers") {
      resetAddedChecklists();
    }

    setReview(updatedReview);
  };

  const isInvalidChecklistAnswers =
    features.REVIEW_CHECKLIST &&
    review.checklist_answers?.some((checklistAnswer) =>
      isInvalidReviewChecklistAnswers(checklistAnswer, reviewChecklists?.checklists)
    );

  const isInvalidReview =
    isEmptyString(review.comment) ||
    review.risk_level === "NOT_SET" ||
    review.accepted === undefined ||
    !isAssignmentRelationSelected ||
    // the approval users list should not be empty
    (review.requires_approval && !hasContent(review.approval_user_ids)) ||
    isInvalidChecklistAnswers;

  const reviewedBy = isPossibleToApprove || !isEditable ? exitingReview?.reviewed_by : undefined;
  const reviewApproval = exitingReview?.approval;
  const reviewDate = exitingReview?.date;

  const signatures = customer.disclosure_documents
    .filter((disclosure) => disclosure.is_signed)
    .flatMap((disclosure) => disclosure.signatures)
    .filter((signature) =>
      Boolean(signature.disclosure || signature.disclosure_answers || signature.questionnaire_answers)
    );

  // a list of checklist answers from a saved/pending review
  const savedChecklistAnswers = exitingReview?.checklist_answers;

  return {
    previousPageLabel,
    readonly,
    review,
    reviewedBy,
    reviewDate,
    reviewApproval,
    isInvalidReview,
    isPossibleToApprove,
    isReviewChanged,
    canBeDeleted: isEditable,
    assignments,
    signatures,
    savedChecklistAnswers,
    addedChecklistsIds,
    manuallyRemovedChecklistIds,
    resetAddedChecklists,
    setManuallyRemovedChecklistsIds,
    handleReviewChange,
    resetReviewIsChanged,
    setReview,
    onBackNavigation
  };
};

export default useCustomerReviewPage;
