import api from "../api";
import { isEmptyString } from "../utils/stringUtils";

import useFormatMessage from "./useFormatMessage";

const useGetOnboardingWarnings = () => {
  const f = useFormatMessage();

  const getOnboardingWarnings = (lookupInfo: api.CompanyInformationLookupInfo) => {
    const warnings: string[] = [];

    if (lookupInfo.is_bankrupt) {
      warnings.push(f("lookup.error.company.bankrupt"));
    }

    if (lookupInfo.under_liquidation) {
      warnings.push(f("lookup.error.company.under-liquidation"));
    }

    if (lookupInfo.under_forced_liquidation_or_dissolution) {
      warnings.push(f("lookup.error.company.under-forced-liquidation"));
    }

    if (!isEmptyString(lookupInfo.closure_date)) {
      warnings.push(f("lookup.error.company.closed"));
    }

    return warnings;
  };

  return getOnboardingWarnings;
};

export default useGetOnboardingWarnings;
