// CSS custom variables are not supported inside media queries, so use a JS constant instead
// value is equivalent to Polaris medium breakpoint: --p-breakpoints-md

export const MOBILE_BREAKPOINT = "48rem";

export const COPY_LABEL_MAX_WIDTH_IN_PX = 250;
export const COPY_LABEL_MOBILE_MAX_WIDTH_IN_PX = 180;

export const DEFAULT_TAG_COLOR = "#FFEA8A";

export const TREE_LINE_COLOR = "rgba(140, 145, 150, 1)";

export const PRESET_COLORS_FOR_TAG_PICKER = [
  "#FF6900",
  DEFAULT_TAG_COLOR,
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#4A90E2",
  "#ABB8C3",
  "#D0021B",
  "#F78DA7",
  "#9900EF"
];

export const PRESET_COLORS_FOR_INDUSTRY_TYPE_PIE_CHART = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600"
];
