import React from "react";

import StarIcon from "../../../assets/icons/star-filled.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useProjectPermissions from "../../hooks/useProjectPermissions";
import { CustomerDetails } from "../../types/utilities";
import PlanConversionModal from "../CustomerDetailsHeader/CustomerActionsMenu/PlanConversionModal";
import AddToProjectModal from "../CustomersListBulkActions/AddToProjectModal/AddToProjectModal";
import FloatPrimaryButton from "../FloatPrimaryButton/FloatPrimaryButton";

interface AddToProjectButtonProps {
  customer: CustomerDetails;
  readonly?: boolean;
}

const AddToProjectButton = (props: AddToProjectButtonProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();
  const { isProjectsEnabled, isCreateOrAddToProjectEnabled } = useProjectPermissions();

  const [showAddToProjectModal, toggleAddToProjectModal, closeAddToProjectModal] = useOpenClose();

  const [showPlanConversionModal, togglePlanConversionModal, closePlanConversionModal] = useOpenClose();

  const showAMLConversionAction = !isProjectsEnabled;
  const disabled = readonly || (!isCreateOrAddToProjectEnabled && !showAMLConversionAction);

  const buttonIcon = showAMLConversionAction ? StarIcon : undefined; // will default to plus icon
  const buttonAction = showAMLConversionAction ? togglePlanConversionModal : toggleAddToProjectModal;
  const buttonLabel = showAMLConversionAction
    ? f("plan-conversion.customer-action.label")
    : f("bulk.actions.add-to-existing-project.label");

  return (
    <>
      <FloatPrimaryButton icon={buttonIcon} onClick={buttonAction} disabled={disabled}>
        {buttonLabel}
      </FloatPrimaryButton>
      {showAddToProjectModal && (
        <AddToProjectModal customers={[customer]} hideCustomersCount onClose={closeAddToProjectModal} />
      )}
      {showPlanConversionModal && (
        <PlanConversionModal
          onClose={closePlanConversionModal}
          content={f("plan-conversion.add-to-projects.description")}
        />
      )}
    </>
  );
};

export default AddToProjectButton;
