import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";

const useGetReviewChecklists = () => {
  return useQuery([QUERIES_KEYS.REVIEW_CHECKLISTS], api.getReviewChecklists, {
    staleTime: STALE_TIME
  });
};

export default useGetReviewChecklists;
