import React, { useState } from "react";
import { Card, Checkbox, Collapsible, HorizontalStack, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { COMPLIANCE_OFFICER_GROUP_KEY, MLRO_GROUP_KEY } from "../../constants/permissions";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import useGetMLROUsers from "../../hooks/useGetMLROUsers";
import useGetUserName from "../../hooks/useGetUserName";
import useIsMLROUser from "../../hooks/useIsMLROUser";
import { OptionalCustomerReviewRequest } from "../../types/CustomerReview";
import PopoverButton from "../PopoverButton/PopoverButton";
import UsersList from "../UsersList/UsersList";

type CustomerReviewApprovalFormProps = {
  review: OptionalCustomerReviewRequest;
  readonly?: boolean;
  onChange(review: OptionalCustomerReviewRequest): void;
};

const CustomerReviewApprovalForm = (props: CustomerReviewApprovalFormProps) => {
  const { review, readonly, onChange } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const isMLROUser = useIsMLROUser();
  const mlroUsers = useGetMLROUsers();
  const { getUserFullName } = useGetUserName();
  const formatMultipleNames = useFormatMultipleNames();
  const [selectionChanged, setSelectionChanged] = useState(false);

  const { approval_user_ids = [] } = review;

  const handleRequiresApproval = (checked: boolean) => {
    // pre-select the MLRO admins for approval if:
    // 1. User checked the approval checkbox
    // 2. The current user is NOT an MLRO admin
    // 3. REVIEW__DISABLE_MLRO_ADMINS_PRE_SELECTION_FOR_REVIEW_APPROVAL is disabled
    const shouldPreselectMLROAdmins =
      checked && !isMLROUser && !features.REVIEW__DISABLE_MLRO_ADMINS_PRE_SELECTION_FOR_REVIEW_APPROVAL;

    const initialApprovalUserIds = shouldPreselectMLROAdmins ? mlroUsers.map((user) => user.id) : [];

    onChange({ ...review, requires_approval: checked, approval_user_ids: initialApprovalUserIds });
  };

  const popoverLabel =
    approval_user_ids.length === 0
      ? f("reviews.labels.select-user-for-confirmation")
      : formatMultipleNames(
          approval_user_ids.map((userId) => getUserFullName(userId)),
          1
        );

  const handleSelectionChange = (selectedIds: string[]) => {
    setSelectionChanged(true);
    onChange({ ...review, approval_user_ids: selectedIds });
  };

  const filteredGroups: api.GroupRoleKey[] | undefined = features.REVIEW__ONLY_LIST_COMPLIANCE_ADMIN_APPROVAL_USERS
    ? [MLRO_GROUP_KEY, COMPLIANCE_OFFICER_GROUP_KEY]
    : undefined;

  return (
    <VerticalStack gap="2" align="start">
      <HorizontalStack>
        <Checkbox
          label={f("reviews.labels.ask-for-confirmation")}
          checked={review.requires_approval}
          onChange={handleRequiresApproval}
          disabled={readonly}
        />
      </HorizontalStack>
      <Collapsible id={""} open={Boolean(review.requires_approval)}>
        {review.requires_approval && (
          <PopoverButton
            label={popoverLabel}
            disclosure
            disabled={readonly}
            preferredAlignment={"left"}
            render={() => (
              <Card>
                <Card.Section>
                  <UsersList
                    selectedUserIds={approval_user_ids}
                    allowMultiple
                    excludeCurrentUser
                    selectedFirst={!selectionChanged}
                    filterGroups={filteredGroups}
                    onChange={handleSelectionChange}
                  />
                </Card.Section>
              </Card>
            )}
          />
        )}
      </Collapsible>
    </VerticalStack>
  );
};

export default CustomerReviewApprovalForm;
