import React from "react";
import { ActionListItemDescriptor } from "@shopify/polaris";

import { OnboardingType } from "../../hooks/useNavigationToolbar";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";

type OnboardingWizardMenuProps = {
  menuItems: Record<OnboardingType, string[]>;
  onboardingType: OnboardingType;
  onboardingStepIndex: number;
  goToStep(stepIndex: number): void;
};

const OnboardingWizardMenu = (props: OnboardingWizardMenuProps) => {
  const { menuItems, onboardingType, onboardingStepIndex, goToStep } = props;

  const currentMenuLabel = menuItems[onboardingType][onboardingStepIndex];

  const items: ActionListItemDescriptor[] = menuItems[onboardingType].map((item, index) => ({
    disabled: index > onboardingStepIndex,
    onClick: () => goToStep(index),
    content: item,
    active: onboardingStepIndex === index
  }));

  return (
    <ActionsDropdown
      items={items}
      hideIcon
      title={currentMenuLabel}
      size="medium"
      // plain
      monochrome
      disclosure
      removeUnderline
    />
  );
};

export default OnboardingWizardMenu;
