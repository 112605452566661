import React from "react";
import { useQuery } from "@tanstack/react-query";

import PreviewModal, { PreviewModalProps } from "../PreviewModal/PreviewModal";

type PreviewModalAsyncProps = PreviewModalProps & {
  getPreviewURLPromise(): Promise<string>;
};

const PreviewModalAsync = (props: PreviewModalAsyncProps) => {
  const { getPreviewURLPromise, ...previewModalProps } = props;

  const { data, isFetching, error } = useQuery(["preview-async-modal"], getPreviewURLPromise, {
    refetchOnWindowFocus: false
  });

  return <PreviewModal previewURL={data} {...previewModalProps} loading={isFetching} error={error} />;
};

export default PreviewModalAsync;
