import React, { useState } from "react";
import { Card, RadioButton, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import { EMPTY_FOLLOWUP_SETTINGS } from "../SettingsFollowupPage/SettingsFollowupPage";

type SettingsFollowupNotificationsProps = {
  settings: api.FollowUpSettings;
  readonly?: boolean;
};

const SettingsFollowupNotifications = (props: SettingsFollowupNotificationsProps) => {
  const { settings, readonly } = props;

  const f = useFormatMessage();
  const { isPermittedTo } = usePermissions();

  const [notificationsFrequency, setNotificationsFrequency] = useState(settings.email_notification_frequency);

  const { setQueryData } = useQueryData<api.FollowUpSettings>([QUERIES_KEYS.FOLLOW_UP_SETTINGS]);

  const updateSettingMutation = () =>
    api.updateFollowUpSettings({ ...settings, email_notification_frequency: notificationsFrequency });

  const disabled = readonly || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  return (
    <SettingsPage<api.FollowUpSettings>
      readonly={readonly}
      apiCall={updateSettingMutation}
      onSaveAfter={setQueryData}
      title={f("followup-notifications.settings.page.title")}
      subtitle={f("followup-notifications.settings.intervals.description")}
    >
      <Card>
        <Card.Section>
          <VerticalStack gap="8">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {f("notifications-settings.followup.form.label")}
            </Text>
            <VerticalStack gap="2">
              <RadioButton
                label={f("notifications-settings.followup.form.label.no-notification")}
                checked={!readonly && notificationsFrequency === "NOT_SET"}
                onChange={() => setNotificationsFrequency("NOT_SET")}
                disabled={disabled}
              />
              <RadioButton
                label={f("notifications-settings.followup.form.label.weekly")}
                checked={!readonly && notificationsFrequency === "WEEKLY"}
                onChange={() => setNotificationsFrequency("WEEKLY")}
                disabled={disabled}
              />
              <RadioButton
                label={f("notifications-settings.followup.form.label.monthly")}
                checked={!readonly && notificationsFrequency === "MONTHLY"}
                onChange={() => setNotificationsFrequency("MONTHLY")}
                disabled={disabled}
              />
            </VerticalStack>
          </VerticalStack>
        </Card.Section>
      </Card>
    </SettingsPage>
  );
};

const SettingsFollowupNotificationsPage = ({ data }: AsyncPageChild<api.FollowUpSettings>) => {
  return <SettingsFollowupNotifications settings={data} />;
};

export default withAsyncPage<api.FollowUpSettings>(SettingsFollowupNotificationsPage, {
  queryKey: QUERIES_KEYS.FOLLOW_UP_SETTINGS,
  apiFunction: api.getFollowUpSettings,
  paramNames: [],
  skeleton: <SettingsFollowupNotifications readonly settings={EMPTY_FOLLOWUP_SETTINGS} />
});
