import { Card } from "@shopify/polaris";

import CustomerReviewChecklistForms, {
  CustomerReviewChecklistFormsProps
} from "../CustomerReviewChecklistForms/CustomerReviewChecklistForms";

const CustomerReviewChecklistSection = (props: CustomerReviewChecklistFormsProps) => {
  return (
    <Card>
      <Card.Section>
        <CustomerReviewChecklistForms {...props} />
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewChecklistSection;
