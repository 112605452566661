import * as React from "react";
import { useState } from "react";
import { HorizontalStack, Modal, VerticalStack } from "@shopify/polaris";
import { CircleTickOutlineMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { DURATION_SHORT_IN_MS } from "../../constants/durations";
import useFormatMessage from "../../hooks/useFormatMessage";
import useTimeout from "../../hooks/useTimeout";
import { copyToClipboard } from "../../utils/browserUtils";
import Button from "../extensions/Button";

type CopyLinkModalProps = {
  title: string;
  url?: string;
  open: boolean;
  onModalClose(): void;
};

const CopyLinkModal = (props: CopyLinkModalProps) => {
  const { title, url, open, onModalClose } = props;

  const [isCopied, setCopied] = useState(false);

  const f = useFormatMessage();

  useTimeout(() => setCopied(false), DURATION_SHORT_IN_MS, isCopied);

  const copyLink = () => {
    copyToClipboard(url);
    setCopied(true);
  };

  const closeModal = () => onModalClose();

  return (
    <Modal open={open} onClose={closeModal} title={title}>
      <Modal.Section>
        <VerticalStack gap="4">
          <StyledLink>{url}</StyledLink>
          <HorizontalStack gap="4" align="end">
            <Button onClick={closeModal}>{f("default.close")}</Button>
            <StyledCopyButton
              monochrome={isCopied}
              outline={isCopied}
              primary={!isCopied}
              success={isCopied}
              icon={isCopied ? CircleTickOutlineMinor : undefined}
              onClick={copyLink}
            >
              {isCopied ? f("modals.copy.link.button.copied") : f("modals.copy.link.button")}
            </StyledCopyButton>
          </HorizontalStack>
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};

const StyledLink = styled.div`
  background-color: var(--p-surface-neutral);
  color: var(--p-text-subdued);
  padding: var(--p-space-2);
  border: var(--p-border-divider);
`;

const StyledCopyButton = styled(Button)<{ success: boolean }>`
  ${({ success }) => success && `color: var(--kdk-dark-success);`}
`;

export default CopyLinkModal;
