import React from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePreviewQuestionnaireMutation from "../../hooks/usePreviewQuestionnaireMutation";
import PreviewModalAsync from "../PreviewModalAsync/PreviewModalAsync";

type PreviewQuestionnaireModalProps = {
  questionnaire: api.Questionnaire;
  onClose(): void;
};

const QuestionnairePreviewModal = (props: PreviewQuestionnaireModalProps) => {
  const { questionnaire, onClose } = props;

  const f = useFormatMessage();
  const previewQuestionnaireMutation = usePreviewQuestionnaireMutation();

  return (
    <PreviewModalAsync
      getPreviewURLPromise={() => previewQuestionnaireMutation.mutateAsync(questionnaire)}
      title={f("questionnaire.preview.modal.title", { name: questionnaire.name })}
      loading={previewQuestionnaireMutation.isLoading}
      onClose={onClose}
    />
  );
};

export default QuestionnairePreviewModal;
