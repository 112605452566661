import { useMemo } from "react";
import { useIntl } from "react-intl";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import api from "../api";
import COUNTRIES from "../helpers/countries.helpers";
import { getPersonCountries } from "../helpers/person.helpers";
import { PersonInformation } from "../types/utilities";

import useFormatMessage from "./useFormatMessage";
import useFormatMultipleNames from "./useFormatMultipleNames";
import useGetCountryRiskAndTaxHavens from "./useGetCountryRiskAndTaxHavens";

const useCountries = () => {
  const intl = useIntl();
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();
  const { getCountryRiskAndTaxHavenList } = useGetCountryRiskAndTaxHavens();

  /**
   * Gets country name (in the current locale) given a country code
   * If no country is found for the country code it returns the country code
   */
  const getCountryName = (countryCode: string, withFlag?: boolean) => {
    const country = localeCountries.find((country) => country.code === countryCode);

    if (!country) {
      return countryCode;
    }

    return withFlag ? `${getUnicodeFlagIcon(countryCode)} ${country.name}` : country.name;
  };

  const localeCountries = useMemo(() => {
    return COUNTRIES[intl.locale];
  }, [intl.locale]);

  const allCountriesOptions = useMemo(() => {
    const selectOptions = localeCountries.map((country) => ({
      label: getCountryName(country.code),
      value: country.code,
      disabled: false
    }));

    return selectOptions;
  }, [localeCountries]);

  const getCountriesOptions = (excludedCountries?: string[]) => {
    if (!excludedCountries) {
      return allCountriesOptions;
    }

    return allCountriesOptions.filter((countryOption) =>
      typeof countryOption === "string"
        ? !excludedCountries.includes(countryOption)
        : !excludedCountries.includes(countryOption.value)
    );
  };

  const getCountryRiskDescription = (country?: api.CountryEnum) =>
    country
      ? f("risk-indicators.geographic-risk.title", {
          country: getCountryName(country, true),
          listNames: formatMultipleNames(
            getCountryRiskAndTaxHavenList(country).map((listName) => f(`high-risk-countries.list.${listName}`))
          )
        })
      : undefined;

  const isHighRiskCountry = (country?: api.CountryEnum) => getCountryRiskAndTaxHavenList(country).length > 0;

  const hasHighRiskCountries = (person?: PersonInformation) => {
    const countries = getPersonCountries(person);
    const highRiskCountriesSet = new Set([...countries.values()].flat().filter(isHighRiskCountry));

    return highRiskCountriesSet.size > 0;
  };

  return {
    getCountriesOptions,
    getCountryName,
    getCountryRiskDescription,
    isHighRiskCountry,
    hasHighRiskCountries
  };
};

export default useCountries;
