import React, { PropsWithChildren, useRef } from "react";
import {
  Button,
  Checkbox,
  Collapsible,
  Divider,
  HorizontalStack,
  RadioButton,
  Text,
  VerticalStack
} from "@shopify/polaris";

import DocumentIcon from "../../../assets/icons/doc-text.svg";
import FileIcon from "../../../assets/icons/file.svg";
import IdentificationIcon from "../../../assets/icons/identity.svg";
import ProjectIcon from "../../../assets/icons/project.svg";
import api from "../../api";
import { isKYCSelected } from "../../helpers/disclosures.helpers";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useNonInitialEffect from "../../hooks/useNonInitialEffect";
import useOpenClose from "../../hooks/useOpenClose";
import { SignatureConfiguration } from "../../types/Disclosure";
import { ValueOf } from "../../types/utilities";
import { hasContent, toggleElement } from "../../utils/collectionUtils";
import { cleanupFilename } from "../../utils/filenameUtils";
import { noop, uniqueId } from "../../utils/util";
import AssignmentSelector from "../AssignmentSelector/AssignmentSelector";
import DisclosurePreviewModal from "../DisclosurePreviewModal/DisclosurePreviewModal";
import DisclosureQuestionnaireSelect from "../DisclosureSignatureForm/DisclosureQuestionnaireSelect/DisclosureQuestionnaireSelect";
import LanguageSelect from "../DisclosureSignatureForm/LanguageSelect";
import EmailOrLinkButtonGroupInput from "../EmailOrLinkButtonGroupInput/EmailOrLinkButtonGroupInput";
import Icon from "../extensions/Icon";
import PurposeChoicesSelect from "../PurposeChoicesSelect/PurposeChoicesSelect";
import SignatureFormWrapper from "../SignatureFormWrapper/SignatureFormWrapper";

type Signature = SignatureConfiguration | api.OrganizationSignatureSettings;

export type SignatureConfigurationFormProps = {
  signature: Signature;
  customerType: api.CustomerType;
  customerId?: string;
  signatureName?: string;
  readonly?: boolean;
  assignments?: api.Assignment[];
  selectedAssignment?: api.Assignment;
  disableAssignmentSelection?: boolean;
  attachments?: api.AttachmentDetails[];
  selectedAttachmentsIds?: string[];
  onSignatureChange(signature: Signature): void;
  onAssignmentChange?(assignment?: api.Assignment): void;
  onAttachmentsSelectionChange?(selectedAttachmentsIds: string[]): void;
};

const SignatureConfigurationForm = (props: PropsWithChildren<SignatureConfigurationFormProps>) => {
  const {
    signature,
    customerId,
    customerType,
    signatureName,
    readonly,
    assignments,
    selectedAssignment,
    disableAssignmentSelection,
    attachments,
    selectedAttachmentsIds,
    onSignatureChange,
    onAssignmentChange = noop,
    onAttachmentsSelectionChange = noop,
    children
  } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const disclosureTypeInputName = uniqueId("disclosure_type");
  const [showKYCOptions, toggleKYCOptions] = useOpenClose(isKYCSelected(signature));
  const initialSignature = useRef({ ...signature });

  const onFieldChange = (field: keyof Signature, value: ValueOf<Signature>) =>
    onSignatureChange({ ...signature, [field]: value });

  const onAssignmentPurposeChange = (purpose?: string) => onAssignmentChange({ ...selectedAssignment!, purpose });

  const [showPreviewModal, togglePreviewModal, closePreviewModal] = useOpenClose();

  useNonInitialEffect(() => {
    // whenever use_standard_disclosure state changes - reset use_pep_disclosure to be false
    // because use_pep_disclosure cannot be true when use_standard_disclosure is true
    // and use_pep_disclosure cannot be true if showKYCOptions is false (the radio buttons are hidden)
    if (showKYCOptions) {
      onSignatureChange({
        ...signature,
        use_standard_disclosure: initialSignature.current.use_standard_disclosure,
        use_pep_disclosure: initialSignature.current.use_pep_disclosure
      });
    } else {
      onSignatureChange({ ...signature, use_standard_disclosure: false, use_pep_disclosure: false });
    }
  }, [showKYCOptions]);

  const showAssignments = hasContent(assignments);

  return (
    <SignatureFormWrapper>
      <VerticalStack gap="8">
        {showAssignments && (
          <>
            <VerticalStack gap="4">
              <AssignmentSelector
                assignments={assignments}
                selectedAssignment={selectedAssignment}
                onSelectionChange={onAssignmentChange}
                readonly={readonly || disableAssignmentSelection}
              />
              <VerticalStack gap="2">
                <HorizontalStack gap="1" blockAlign="center" wrap={false}>
                  <Icon source={ProjectIcon} width="16px" height="12px" />
                  <Text as="span" fontWeight="semibold">
                    {f("customer-assignments.details.assignment-purpose")}
                  </Text>
                </HorizontalStack>
                <PurposeChoicesSelect
                  industryType={selectedAssignment?.industry_type}
                  hasPurposeDesc={false}
                  disablePurposeDescription
                  disabled={readonly || selectedAssignment === undefined}
                  labelHidden
                  multiline={3}
                  value={selectedAssignment?.purpose || ""}
                  onChange={onAssignmentPurposeChange}
                  key={selectedAssignment?.id || "selected-assignment"}
                />
              </VerticalStack>
            </VerticalStack>
            <Divider />
          </>
        )}
        <VerticalStack gap="2">
          <HorizontalStack gap="1" blockAlign="center" wrap={false}>
            <Icon source={IdentificationIcon} width="25px" height="18px" />
            <Text as="span" fontWeight="semibold">
              {f("signature.configuration.form.labels.verification")}
            </Text>
          </HorizontalStack>
          <Checkbox
            label={f("common.labels.bank-id")}
            checked={signature.use_verification}
            onChange={(value) => onFieldChange("use_verification", value)}
            disabled={readonly}
          />
        </VerticalStack>
        {hasContent(attachments) && (
          <>
            <Divider />
            <VerticalStack gap="2">
              <Text as="span" fontWeight="semibold">
                {f("signature.configuration.form.labels.e-signatures")}
              </Text>
              {attachments?.map((attachment) => (
                <Checkbox
                  label={
                    <HorizontalStack gap="1" wrap={false} blockAlign={"center"}>
                      <Icon source={FileIcon} />
                      <Text as={"span"} breakWord>
                        {cleanupFilename(attachment.filename)}
                      </Text>
                    </HorizontalStack>
                  }
                  checked={selectedAttachmentsIds?.includes(attachment.id)}
                  onChange={() =>
                    onAttachmentsSelectionChange(toggleElement(selectedAttachmentsIds || [], attachment.id))
                  }
                  disabled={readonly}
                  key={attachment.id}
                />
              ))}
            </VerticalStack>
          </>
        )}
      </VerticalStack>
      <VerticalStack gap="8">
        <VerticalStack gap="2">
          <HorizontalStack gap="1" blockAlign="center" wrap={false}>
            <Icon source={FileIcon} width="25px" height="18px" />
            <Text as="span" fontWeight="semibold">
              {f("disclosure-request.field.use_standard_disclosure")}
            </Text>
          </HorizontalStack>
          <Checkbox
            label={f("signature.configuration.form.labels.disclosure")}
            checked={showKYCOptions}
            onChange={toggleKYCOptions}
            disabled={readonly}
          />
          <Collapsible id="use_standard_disclosure" open={showKYCOptions}>
            <VerticalStack gap="2">
              <RadioButton
                disabled={readonly}
                checked={signature.use_standard_disclosure}
                onChange={() =>
                  onSignatureChange({ ...signature, use_standard_disclosure: true, use_pep_disclosure: false })
                }
                label={f("signature.configuration.form.labels.disclosure.full")}
                name={disclosureTypeInputName}
              />
              <RadioButton
                disabled={readonly}
                checked={signature.use_pep_disclosure === true}
                onChange={() =>
                  onSignatureChange({ ...signature, use_standard_disclosure: false, use_pep_disclosure: true })
                }
                label={f("signature.configuration.form.labels.disclosure.pep")}
                name={disclosureTypeInputName}
              />
            </VerticalStack>
          </Collapsible>
        </VerticalStack>
        <VerticalStack gap="2">
          <HorizontalStack gap="1" blockAlign="center" wrap={false}>
            <Icon source={DocumentIcon} width="25px" height="18px" />
            <Text as="span" fontWeight="semibold">
              {f("disclosure-request.column.custom-questionnaires")}
            </Text>
          </HorizontalStack>
          <DisclosureQuestionnaireSelect
            customerType={customerType}
            questionnairesIds={signature.questionnaire_ids || []}
            readonly={readonly}
            onChange={(questionnaireIds) => onFieldChange("questionnaire_ids", questionnaireIds)}
          />
        </VerticalStack>
      </VerticalStack>
      <VerticalStack gap="8">
        <VerticalStack gap="2">
          <Text as="span" fontWeight="semibold">
            {f("signature.configuration.form.labels.choose.language")}
          </Text>
          <LanguageSelect
            value={signature.language}
            onChange={(language) => onFieldChange("language", language)}
            disabled={readonly}
          />
        </VerticalStack>
        <VerticalStack gap="2">
          <Text as="span" fontWeight="semibold">
            {f("signature.configuration.form.labels.choose.send.method")}
          </Text>
          <EmailOrLinkButtonGroupInput
            fullWidth
            value={signature.send_email ? "email" : "link"}
            onChange={(value) => onFieldChange("send_email", value === "email")}
            disabled={readonly}
          />
          {children}
        </VerticalStack>
        {features.DISCLOSURE__PREVIEW && (
          <>
            <Divider />
            <VerticalStack gap="2">
              <Text as="span" fontWeight="semibold">
                {f("signature.configuration.form.labels.preview")}
              </Text>
              <Button primary textAlign="left" disabled={readonly} onClick={togglePreviewModal}>
                {f("signature.configuration.form.buttons.preview")}
              </Button>
              {showPreviewModal && (
                <DisclosurePreviewModal
                  signature={{
                    ...signature,
                    subject_id: customerId,
                    subject_type: customerType,
                    subject_name: signatureName,
                    assignment_id: selectedAssignment?.id
                  }}
                  onClose={closePreviewModal}
                />
              )}
            </VerticalStack>
          </>
        )}
      </VerticalStack>
    </SignatureFormWrapper>
  );
};

export default SignatureConfigurationForm;
