import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import { REVIEW_CHECKLIST_TEMPLATES } from "../../constants/review-checklist-templates";
import useFormatMessage from "../../hooks/useFormatMessage";
import ReviewChecklistTemplateCard, {
  ReviewChecklistTemplateCardProps
} from "../ReviewChecklistTemplateCard/ReviewChecklistTemplateCard";

type ReviewChecklistTemplatesSectionProps = Omit<ReviewChecklistTemplateCardProps, "templateChecklist">;

const ReviewChecklistTemplatesSection = (props: ReviewChecklistTemplatesSectionProps) => {
  const { onCreateInstance, ...templateCardProps } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="2">
      <Text as="h2" variant={"headingMd"}>
        {f("component.review.checklist.template.title")}
      </Text>
      <HorizontalStack gap="8">
        {REVIEW_CHECKLIST_TEMPLATES.map((template) => (
          <ReviewChecklistTemplateCard
            templateChecklist={template}
            onCreateInstance={() => onCreateInstance(template)}
            {...templateCardProps}
            key={template.id}
          />
        ))}
      </HorizontalStack>
    </VerticalStack>
  );
};

export default ReviewChecklistTemplatesSection;
