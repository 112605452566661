import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import { Card, HorizontalStack, Link, Scrollable, Text } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { INTERVALS_IN_MONTHS } from "../../constants/followup";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import { first, last } from "../../utils/collectionUtils";
import { isNumber } from "../../utils/numbersUtils";

import RiskIntervalsEditor from "./RiskIntervalsEditor/RiskIntervalsEditor";

export const EMPTY_FOLLOWUP_SETTINGS: api.FollowUpSettings = {
  email_notification_frequency: "NOT_SET"
};

const convertMonthsToIntervalType = (months?: number): IntervalType => ({
  months,
  custom: isNumber(months) && !INTERVALS_IN_MONTHS.includes(months)
});

export type IntervalType = {
  months: number | undefined; // undefined means no followup
  custom: boolean;
};

interface SettingsFollowupProps {
  followupSettings: api.FollowUpSettings;
  readonly?: boolean;
}

const SettingsFollowup = (props: SettingsFollowupProps) => {
  const { followupSettings, readonly } = props;

  const f = useFormatMessage();
  const { isPermittedTo } = usePermissions();

  const [intervals, setIntervals] = useState<Map<api.RiskLevel, IntervalType>>(
    () =>
      new Map([
        ["LOW", convertMonthsToIntervalType(followupSettings.low_risk_level_interval)],
        ["MEDIUM", convertMonthsToIntervalType(followupSettings.medium_risk_level_interval)],
        ["HIGH", convertMonthsToIntervalType(followupSettings.high_risk_level_interval)]
      ])
  );

  const { setQueryData } = useQueryData<api.FollowUpSettings>([QUERIES_KEYS.FOLLOW_UP_SETTINGS]);

  const getInterval = (risk: api.RiskLevel) => intervals.get(risk)?.months;

  const updateSettingMutation = () =>
    api.updateFollowUpSettings({
      ...followupSettings,
      low_risk_level_interval: getInterval("LOW"),
      medium_risk_level_interval: getInterval("MEDIUM"),
      high_risk_level_interval: getInterval("HIGH")
    });

  const disabled = readonly || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const updateInterval = (risk: api.RiskLevel, months: number | undefined, custom = false) =>
    setIntervals(new Map(intervals).set(risk, { ...intervals.get(risk)!, months, custom }));

  // split label into 2 parts in order to insert a link between them
  const messages = f("followup-settings.page.link-to-notifications").split("<link>");

  return (
    <SettingsPage<api.FollowUpSettings>
      readonly={readonly}
      apiCall={updateSettingMutation}
      onSaveAfter={setQueryData}
      title={f("followup-settings.page.title")}
      subtitle={f("followup-settings.intervals.description")}
      footer={
        <HorizontalStack gap="1" blockAlign={"center"}>
          <Text as={"span"}>{first(messages)}</Text>
          <Link url={generatePath(ROUTES.SETTINGS_FOLLOWUP_NOTIFICATIONS)}>
            {f("followup-notifications.settings.page.title")}
          </Link>
          <Text as={"span"}>{last(messages)}</Text>
        </HorizontalStack>
      }
    >
      <Card>
        <Card.Section>
          <Scrollable vertical={false}>
            <RiskIntervalsEditor intervals={intervals} readonly={disabled} onIntervalChange={updateInterval} />
          </Scrollable>
        </Card.Section>
      </Card>
    </SettingsPage>
  );
};

const SettingsFollowupPage = ({ data }: AsyncPageChild<api.FollowUpSettings>) => {
  return <SettingsFollowup followupSettings={data} />;
};

export default withAsyncPage<api.FollowUpSettings>(SettingsFollowupPage, {
  queryKey: QUERIES_KEYS.FOLLOW_UP_SETTINGS,
  apiFunction: api.getFollowUpSettings,
  paramNames: [],
  skeleton: <SettingsFollowup readonly followupSettings={EMPTY_FOLLOWUP_SETTINGS} />
});
