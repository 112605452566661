import React from "react";
import { generatePath } from "react-router-dom";
import { HorizontalStack, Link, Text } from "@shopify/polaris";
import styled from "styled-components";

import IconProject from "../../../assets/icons/project.svg";
import api from "../../api";
import { ROUTES } from "../../constants/routes";
import { isVitecProject } from "../../helpers/integration.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";
import ExternalNavigationLink from "../ExternalNavigationLink/ExternalNavigationLink";

interface ProjectNameProps {
  project: api.ProjectListItem;
  renderAsLink?: boolean;
}

const ProjectName = (props: ProjectNameProps) => {
  const { project, renderAsLink } = props;

  const f = useFormatMessage();

  const name = project.name;
  const isVitec = isVitecProject(project);
  const vitecUrl = project.integrations?.vitec_data?.vitec_url;

  const projectDisplayName = (
    <HorizontalStack gap="1" blockAlign="center" wrap={false}>
      <Icon source={IconProject} width="20px" height="20px" />
      <StyledProjectName>{name}</StyledProjectName>
    </HorizontalStack>
  );

  return (
    <HorizontalStack gap="2" blockAlign="center" wrap={false}>
      {renderAsLink && <Link url={generatePath(ROUTES.PROJECT_DETAILS, { id: project.id })}>{projectDisplayName}</Link>}
      {!renderAsLink && <Text as="span">{projectDisplayName}</Text>}
      {isVitec && (
        <ExternalNavigationLink url={vitecUrl}>{f("project.button.imported-from-vitec")}</ExternalNavigationLink>
      )}
    </HorizontalStack>
  );
};

const StyledProjectName = styled.div`
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ProjectName;
