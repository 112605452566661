import React from "react";
import { Stack, Text } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import Button from "../extensions/Button";

const CLAMP_CHARACTERS_WIDTH = 100; // in characters

interface CollapsibleTextProps {
  text?: string;
  clampLength?: number;
}

const CollapsibleText = (props: CollapsibleTextProps) => {
  const { text = "", clampLength = CLAMP_CHARACTERS_WIDTH, ...rest } = props;

  const f = useFormatMessage();
  const [isClamped, toggleClamped] = useOpenClose(true);

  const showClampButton = text && text.length > clampLength;

  return (
    <Stack spacing={isClamped ? "extraTight" : "none"} wrap={!isClamped}>
      <Stack.Item fill>
        <StyledClampedText clamped={isClamped} {...rest}>
          <Text as="span">{text}</Text>
        </StyledClampedText>
      </Stack.Item>
      {showClampButton && (
        <Button plain monochrome onClick={toggleClamped}>
          {isClamped ? f("common.labels.show-more") : f("common.labels.show-less")}
        </Button>
      )}
    </Stack>
  );
};

const StyledClampedText = styled.span<{ clamped?: boolean }>`
  transition: max-height var(--p-ease-out) var(--p-duration-500);

  -webkit-box-orient: vertical;
  box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;

  max-height: ${({ clamped }) => (clamped ? "2em" : "40em")};
  -webkit-line-clamp: ${({ clamped }) => (clamped ? "1" : "none")};
  line-clamp: ${({ clamped }) => (clamped ? "1" : "none")};
`;

export default CollapsibleText;
