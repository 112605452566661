import React from "react";
import { HorizontalStack } from "@shopify/polaris";
import { Table } from "@tanstack/react-table";

import Button from "../../components/extensions/Button";
import PlainSelect from "../../components/PlainSelect/PlainSelect";
import { PAGE_SIZES } from "../../constants/import-customers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ImportRowData } from "../../types/ImportRow";

interface ImportCustomersPaginationProps {
  table: Table<ImportRowData>;
}

const ImportCustomersPagination = (props: ImportCustomersPaginationProps) => {
  const { table } = props;

  const f = useFormatMessage();

  const start = table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1;
  const end = Math.min(
    (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
    table.getFilteredRowModel().rows.length
  );
  const count = table.getFilteredRowModel().rows.length;

  return (
    <HorizontalStack gap="4">
      <PlainSelect
        label={f("component.pagination.labels.select-limit", { limit: table.getState().pagination.pageSize })}
        labelInline
        options={PAGE_SIZES}
        onChange={(limitValue) => table.setPageSize(parseInt(limitValue, 10))}
        value={table.getState().pagination.pageSize + ""}
      />
      <Button plain removeUnderline onClick={() => table.setPageIndex(0)}>
        {f("component.pagination.labels.showing", { start, end, count })}
      </Button>
      <Button plain onClick={table.previousPage} disabled={!table.getCanPreviousPage()}>
        {f("default.previous")}
      </Button>
      <Button plain onClick={table.nextPage} disabled={!table.getCanNextPage()}>
        {f("default.next")}
      </Button>
    </HorizontalStack>
  );
};

export default ImportCustomersPagination;
