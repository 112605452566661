import React from "react";
import { ChoiceList, FilterInterface } from "@shopify/polaris";

import {
  ARCHIVED_NAME_PARAMETER,
  FOLLOW_UP_EXPIRED_NAME_PARAMETER,
  OWNERSHIP_UPDATES_NAME_PARAMETER,
  PENDING_MEASURES_NAME_PARAMETER,
  UNRESOLVED_NAME_PARAMETER
} from "../../constants/customers-list-search-params";
import { AppliedFilter, createFilter } from "../../helpers/filters.helpers";
import { getTagTitle } from "../../helpers/tag.helpers";
import useCountries from "../../hooks/useCountries";
import useCreatedDateAppliedFilters from "../../hooks/useCreatedDateAppliedFilters";
import useCustomersListParams from "../../hooks/useCustomersListParams";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import useIndustryTypes from "../../hooks/useIndustryTypes";
import useShowResponsibleUserFilter from "../../hooks/useShowResponsibleUserFilter";
import useTags from "../../hooks/useTags";
import { toggleElement } from "../../utils/collectionUtils";
import CountrySelect from "../CountrySelect/CountrySelect";
import CreatedDateFilter from "../CreatedDateFilter/CreatedDateFilter";
import IndustryTypesFilter from "../IndustryTypesFilter/IndustryTypesFilter";
import TagsFilter from "../TagsFilter/TagsFilter";
import UsersList from "../UsersList/UsersList";

import FollowupDateFilter from "./FollowupDateFilter";
import useFiltersOptions from "./useFiltersOptions";
import useFollowupDataAppliedFilters from "./useFollowupDataAppliedFilters";

const useCustomersListFilters = (isLoading: boolean) => {
  const {
    q,
    setQ,
    status,
    setStatus,
    customerType,
    setCustomerType,
    risk,
    setRisk,
    screeningType,
    setScreeningType,
    isArchived,
    setIsArchived,
    unresolved,
    setUnresolved,
    isPep,
    setIsPep,
    isSanctioned,
    setIsSanctioned,
    responsibleUserIds,
    setResponsibleUserIds,
    countryCode,
    setCountryCode,
    industryCodes,
    setIndustryCodes,
    tagIds,
    setTagIds,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    followupRange,
    setFollowupRange,
    followupAfter,
    setFollowupAfter,
    followupBefore,
    setFollowupBefore,
    isFollowupExpired,
    setIsFollowupExpired,
    isPendingMeasures,
    setIsPendingMeasures,
    haveOwnershipUpdates,
    setHaveOwnershipUpdates,
    markedAsFilters,
    handleMarkedAsFiltersChange,
    resetAllFilters
  } = useCustomersListParams();

  const appliedCreatedDateFilters = useCreatedDateAppliedFilters({
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore
  });

  const appliedFollowupDateFilters = useFollowupDataAppliedFilters({
    followupRange,
    setFollowupRange,
    followupAfter,
    setFollowupAfter,
    followupBefore,
    setFollowupBefore
  });

  const features = useFeatures();
  const f = useFormatMessage();
  const filterOptions = useFiltersOptions();
  const { getCountryName } = useCountries();
  const { getUserFullName } = useGetUserName(features.RESPONSIBLE_USER);
  const { findTag } = useTags();
  // only call get all industry codes end point if there are industry codes in the filters
  const { getIndustryLabel } = useIndustryTypes(industryCodes.length > 0);

  const showResponsibleUserFilter = useShowResponsibleUserFilter();

  const tagsFilter: FilterInterface = {
    key: "tags",
    label: f("component.filters.tags.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: <TagsFilter tagIds={tagIds} setTagIds={setTagIds} />
  };

  const industryCodesFilter: FilterInterface = {
    key: "industry-types",
    label: f("component.filters.industry-type.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: <IndustryTypesFilter industryCodes={industryCodes} setIndustryCodes={setIndustryCodes} />
  };

  const [statusFilter, statusAppliedFilter] = createFilter(
    "status",
    f("component.filters.status.title"),
    status,
    isLoading,
    filterOptions.customerStatus,
    setStatus
  );

  const [customerTypeFilter, customerTypeAppliedFilter] = createFilter(
    "customerType",
    f("component.filter.customer.title"),
    customerType,
    isLoading,
    filterOptions.customerType,
    setCustomerType
  );

  const [riskFilter, riskAppliedFilter] = createFilter(
    "risk",
    f("component.filter.risk.title"),
    risk,
    isLoading,
    filterOptions.risk,
    setRisk
  );

  const [screeningTypeFilter, screeningTypeAppliedFilter] = createFilter(
    "screening",
    f("component.filter.screening.title"),
    screeningType,
    isLoading,
    filterOptions.screening,
    setScreeningType
  );

  const countryCodeFilter: FilterInterface = {
    key: "country",
    label: f("component.filter.country.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <CountrySelect
        labelHidden
        label={f("component.filter.country.title")}
        value={countryCode}
        onSelect={(country) => setCountryCode(country)}
      />
    )
  };

  const unresolvedMatchesFilter: FilterInterface = {
    key: "unresolved-matches",
    label: f("component.filters.unresolved-matched.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={f("component.filters.unresolved-matched.title")}
        titleHidden
        choices={filterOptions.unresolvedMatches}
        allowMultiple
        selected={unresolved ? [UNRESOLVED_NAME_PARAMETER] : []}
        onChange={(selected) => setUnresolved(selected.includes(UNRESOLVED_NAME_PARAMETER))}
      />
    )
  };

  const markedAsFilter: FilterInterface = {
    key: "marked-as",
    label: f("component.filters.marked-as.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={f("component.filters.marked-as.title")}
        titleHidden
        choices={filterOptions.markedAs}
        allowMultiple
        selected={markedAsFilters}
        onChange={(selected) => handleMarkedAsFiltersChange(selected)}
      />
    )
  };

  const createdDateFilter: FilterInterface = {
    key: "created",
    label: f("component.filters.created.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <CreatedDateFilter
        isLoading={isLoading}
        createdRange={createdRange}
        createdAfter={createdAfter}
        createdBefore={createdBefore}
        setCreatedRange={setCreatedRange}
        setCreatedAfter={setCreatedAfter}
        setCreatedBefore={setCreatedBefore}
      />
    )
  };

  const archivedCustomerFilter: FilterInterface = {
    key: "archived-customer",
    label: f("component.filters.archive.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={f("component.filters.archive.title")}
        titleHidden
        choices={filterOptions.archive}
        allowMultiple
        selected={isArchived ? [ARCHIVED_NAME_PARAMETER] : []}
        onChange={(selected) => setIsArchived(selected.includes(ARCHIVED_NAME_PARAMETER))}
      />
    )
  };

  const filters = [
    tagsFilter,
    statusFilter,
    customerTypeFilter,
    riskFilter,
    unresolvedMatchesFilter,
    markedAsFilter,
    screeningTypeFilter,
    createdDateFilter,
    countryCodeFilter,
    archivedCustomerFilter
  ];

  filters.push(industryCodesFilter);

  const appliedFilters: AppliedFilter[] = [
    statusAppliedFilter,
    customerTypeAppliedFilter,
    riskAppliedFilter,
    screeningTypeAppliedFilter
  ].filter(Boolean) as AppliedFilter[];

  // find Tags from ids, filter missing tags, sort by tag title and add to applied filters
  tagIds
    .map(findTag)
    .filter(Boolean)
    .sort((tagA, tagB) => getTagTitle(tagB).localeCompare(getTagTitle(tagA)))
    .forEach((tag) => {
      appliedFilters.unshift({
        key: "tag-" + tag.id,
        label: getTagTitle(tag),
        tagColor: tag.color,
        isTag: true,
        onRemove: () => setTagIds(tagIds.filter((id) => id !== tag.id))
      });
    });

  industryCodes.forEach((industryCode) => {
    appliedFilters.unshift({
      key: "industry-code-" + industryCode,
      label: `${f("component.filters.industry-type.title")}: ${getIndustryLabel(industryCode)}`,
      onRemove: () => setIndustryCodes(industryCodes.filter((id) => id !== industryCode))
    });
  });

  if (unresolved) {
    appliedFilters.push({
      key: "match-is-unresolved",
      label: f("component.filters.match.text.open.matches"),
      onRemove: () => setUnresolved(false)
    });
  }

  if (isPep) {
    appliedFilters.push({
      key: "match-is-pep",
      label: f("component.filters.match.text.pep.matches"),
      onRemove: () => setIsPep(false)
    });
  }

  if (isSanctioned) {
    appliedFilters.push({
      key: "match-is-sanctioned",
      label: f("component.filters.match.text.sanction.matches"),
      onRemove: () => setIsSanctioned(false)
    });
  }

  appliedFilters.push(...appliedCreatedDateFilters);

  if (countryCode) {
    appliedFilters.push({
      key: "country",
      label: `${f("component.filter.country.title")}: ${getCountryName(countryCode)}`,
      onRemove: () => setCountryCode(undefined)
    });
  }

  if (isArchived) {
    appliedFilters.push({
      key: "archived-customer",
      label: f("badge.archived"),
      onRemove: () => setIsArchived(false)
    });
  }

  if (showResponsibleUserFilter) {
    const responsibleUserFilter: FilterInterface = {
      key: "responsible-user",
      label: f("responsible.label"),
      shortcut: true,
      disabled: isLoading,
      hideClearButton: true,
      filter: (
        <UsersList
          showAllUsersOption
          selectedUserIds={responsibleUserIds}
          allowMultiple
          onChange={setResponsibleUserIds}
        />
      )
    };

    // responsible users filter should be visible in the "default" filters group - add it after the status (first) filter
    filters.splice(1, 0, responsibleUserFilter);

    responsibleUserIds.forEach((userId) => {
      const userName = getUserFullName(userId);
      appliedFilters.push({
        key: "responsible-user",
        label: `${f("responsible.label")}: ${userName}`,
        onRemove: () => setResponsibleUserIds(toggleElement(responsibleUserIds, userId))
      });
    });
  }

  const isFollowupExpiredFilter: FilterInterface = {
    key: "followup-expired",
    label: f("component.filters.followup-expired.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={f("component.filters.followup-expired.title")}
        titleHidden
        choices={filterOptions.followup}
        allowMultiple
        selected={isFollowupExpired ? [FOLLOW_UP_EXPIRED_NAME_PARAMETER] : []}
        onChange={(selected) => setIsFollowupExpired(selected.includes(FOLLOW_UP_EXPIRED_NAME_PARAMETER))}
      />
    )
  };

  const followupDateFilter: FilterInterface = {
    key: "followup",
    label: f("component.filters.followup.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <FollowupDateFilter
        isLoading={isLoading}
        followupRange={followupRange}
        followupAfter={followupAfter}
        followupBefore={followupBefore}
        setFollowupRange={setFollowupRange}
        setFollowupAfter={setFollowupAfter}
        setFollowupBefore={setFollowupBefore}
      />
    )
  };

  filters.push(isFollowupExpiredFilter);
  filters.push(followupDateFilter);

  if (isFollowupExpired) {
    appliedFilters.push({
      key: "followup-expired",
      label: f("component.filters.followup-expired.title"),
      onRemove: () => setIsFollowupExpired(false)
    });
  }

  appliedFilters.push(...appliedFollowupDateFilters);

  if (features.MEASURES) {
    const isPendingMeasuresFilter: FilterInterface = {
      key: "pending-measures",
      label: f("component.filters.pending-measures.title"),
      shortcut: true,
      disabled: isLoading,
      hideClearButton: true,
      filter: (
        <ChoiceList
          title={f("component.filters.pending-measures.title")}
          titleHidden
          choices={filterOptions.measures}
          allowMultiple
          selected={isPendingMeasures ? [PENDING_MEASURES_NAME_PARAMETER] : []}
          onChange={(selected) => setIsPendingMeasures(selected.includes(PENDING_MEASURES_NAME_PARAMETER))}
        />
      )
    };

    filters.push(isPendingMeasuresFilter);
  }

  if (isPendingMeasures) {
    appliedFilters.push({
      key: "pending-measures",
      label: f("component.filters.pending-measures.title"),
      onRemove: () => setIsPendingMeasures(false)
    });
  }

  const ownershipUpdatesFilter: FilterInterface = {
    key: "ownership-updates",
    label: f("component.filters.ownership-updates.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={f("component.filters.ownership-updates.title")}
        titleHidden
        choices={filterOptions.ownershipUpdates}
        allowMultiple
        selected={haveOwnershipUpdates ? [OWNERSHIP_UPDATES_NAME_PARAMETER] : []}
        onChange={(selected) => setHaveOwnershipUpdates(selected.includes(OWNERSHIP_UPDATES_NAME_PARAMETER))}
      />
    )
  };

  filters.push(ownershipUpdatesFilter);

  if (haveOwnershipUpdates) {
    appliedFilters.push({
      key: "ownership-updates",
      label: f("ownership-update.info.label"),
      onRemove: () => setHaveOwnershipUpdates(false)
    });
  }

  return { q, setQ, filters, appliedFilters, resetAllFilters };
};

export default useCustomersListFilters;
