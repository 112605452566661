import React from "react";
import { Text } from "@shopify/polaris";

import EditIcon from "../../../assets/icons/pencil-24-circle.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import ShowOnMobile from "../ShowOnMobile/ShowOnMobile";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface EditButtonProps {
  readonly?: boolean;
  onEdit(): void;
}

const EditButton = (props: EditButtonProps) => {
  const { readonly, onEdit } = props;

  const f = useFormatMessage();

  return (
    <TooltipWrapper content={f("common.buttons.action.edit")}>
      <Button
        plain
        onClick={onEdit}
        size="slim"
        disabled={readonly}
        removeUnderline
        monochrome
        hoverable
        icon={<Icon source={EditIcon} />}
      >
        <ShowOnMobile>
          <Text as="span">{f("common.buttons.action.edit")}</Text>
        </ShowOnMobile>
      </Button>
    </TooltipWrapper>
  );
};

export default EditButton;
