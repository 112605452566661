import React, { CSSProperties } from "react";
import { Tag as PolarisTag, TagProps as PolarisTagProps } from "@shopify/polaris";
import styled from "styled-components";

import useGetFontColorContrast from "../../hooks/useGetFontColorContrast";

type TagProps = PolarisTagProps & {
  color?: CSSProperties["color"];
  className?: string;
};

const Tag = (props: TagProps) => {
  const { color, className, ...tagProps } = props;

  const textColor = useGetFontColorContrast(color);

  return (
    <StyledTagWrapper color={textColor} backgroundColor={color} className={className}>
      <PolarisTag {...tagProps} />
    </StyledTagWrapper>
  );
};

const StyledTagWrapper = styled.span<{ color?: CSSProperties["color"]; backgroundColor?: CSSProperties["color"] }>`
  & .Polaris-Tag {
    padding: var(--p-space-05) var(--p-space-2);
    --p-color-bg-strong: ${({ backgroundColor }) => backgroundColor || "var(--kdk-surface-tag)"};
    --p-color-text: ${({ color }) => color};
    border-radius: var(--p-border-radius-05);
    line-height: var(--kdk-tag-height);
    min-height: var(--kdk-tag-height);
  }

  & .Polaris-Tag.Polaris-Tag--removable {
    padding-right: 0;
  }

  & .Polaris-Tag.Polaris-Tag--removable .Polaris-Tag__Button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);
  }

  & .Polaris-Tag__TagText {
    font-size: var(--p-font-size-75);
    line-height: var(--kdk-tag-height);
    min-height: var(--kdk-tag-height);
    padding: 0;
    user-select: none;
  }

  & .Polaris-Tag__Button .Polaris-Icon {
    width: 1rem;
    height: 1rem;
  }

  & .Polaris-Tag__Button .Polaris-Icon svg {
    fill: ${({ color }) => color};
  }
`;

export default Tag;
