import api from "../../../api";
import { CRITICAL_COMPANY_DETAILS_FIELDS } from "../../../constants/company-details";

// returns true if there are critical, unreviewed updates to critical company details fields
const useCompanyHasCriticalUpdates = (companyDetails?: api.CompanyCustomerDetailResponse) =>
  Boolean(
    companyDetails?.info_updates &&
      // find at least one info update
      companyDetails.info_updates.some((infoUpdate) => {
        return (
          !infoUpdate.is_reviewed &&
          // that contains updates to critical fields that were false and now are true
          infoUpdate.field_changes.find((fieldChange) => {
            const fieldName = fieldChange.field_name as keyof api.CompanyCustomerDetailResponse;
            return fieldChange.new && !fieldChange.old && CRITICAL_COMPANY_DETAILS_FIELDS.includes(fieldName);
          })
        );
      })
  );

export default useCompanyHasCriticalUpdates;
