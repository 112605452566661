import { DependencyList, EffectCallback, useEffect, useRef } from "react";

const useNonInitialEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const initialRender = useRef<boolean>(true);

  return useEffect(() => {
    let effectReturns: ReturnType<EffectCallback> | undefined;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === "function") {
      return effectReturns;
    }
  }, deps);
};

export default useNonInitialEffect;
