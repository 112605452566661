import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import WarningIcon from "../../../../assets/icons/triangle-filled-exclamation.svg";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { CountryRiskInformation } from "../../../types/RiskIndicators";
import Icon from "../../extensions/Icon";
import KDKBanner from "../../KDKBanner/KDKBanner";
import RiskIndicatorPersonName from "../../RiskIndicatorPersonName/RiskIndicatorPersonName";
import SignedByLabel from "../../SignedByLabel/SignedByLabel";

import CountryRisk from "./CountryRisk";

interface GeographicRiskIndicatorProps {
  countryRiskInformation: CountryRiskInformation;
}

const GeographicRiskIndicator = (props: GeographicRiskIndicatorProps) => {
  const { countryRiskInformation } = props;

  const f = useFormatMessage();

  const { signature, customerType } = countryRiskInformation;

  return (
    <KDKBanner status="warning" hideIcon>
      <VerticalStack gap="2">
        {signature && (
          <HorizontalStack gap="2" blockAlign="center" wrap={false}>
            <Icon source={WarningIcon} />
            <Text as="span" fontWeight="semibold">
              {f("common.labels.kyc")}
            </Text>
          </HorizontalStack>
        )}
        {customerType && <RiskIndicatorPersonName riskInformation={countryRiskInformation} />}
        <CountryRisk countryFields={countryRiskInformation.fields} />
        <SignedByLabel signature={signature} />
      </VerticalStack>
    </KDKBanner>
  );
};

export default GeographicRiskIndicator;
