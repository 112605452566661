import api from "../api";
import { SignatureConfiguration } from "../types/Disclosure";
import { CustomerDetails } from "../types/utilities";
import { hasContent } from "../utils/collectionUtils";

export const traverseSignedSignatures = (customer: CustomerDetails, callback: (signature: api.Signature) => void) => {
  customer.disclosure_documents
    .sort((doc1, doc2) => {
      const doc1Date = doc1.signed_dt ? new Date(doc1.signed_dt).getTime() : 0;
      const doc2Date = doc2.signed_dt ? new Date(doc2.signed_dt).getTime() : 0;

      return doc2Date - doc1Date;
    })
    .filter((disclosure) => disclosure.is_signed)
    .forEach((disclosure) =>
      disclosure.signatures.filter((signature) => signature.status == "SIGNED").forEach(callback)
    );
};

export const getRiskDescriptions = ({ description, additional_description = [] }: api.DisclosureRiskIndicator) =>
  [description, additional_description].flat().filter(Boolean);

export const isKYCSelected = (signature: { use_standard_disclosure?: boolean; use_pep_disclosure?: boolean }) =>
  Boolean(signature.use_standard_disclosure || signature.use_pep_disclosure);

export const isValidSignatureConfiguration = (signature: SignatureConfiguration) =>
  signature.use_verification || isKYCSelected(signature) || hasContent(signature.questionnaire_ids);
