import { useIntl } from "react-intl";
import { AppliedFilterInterface } from "@shopify/polaris";

import api from "../api";

import useFormatMessage from "./useFormatMessage";

type useCreatedDateAppliedFiltersProps = {
  dateInterval?: api.DashboardDateInterval;
  startDate?: string;
  endDate?: string;
  setDateInterval(dateInterval?: api.DashboardDateInterval): void;
  setStartDate(startDate?: string): void;
  setEndDate(startDate?: string): void;
};

const useDashboardDateAppliedFilters = (props: useCreatedDateAppliedFiltersProps) => {
  const { dateInterval, startDate, endDate, setDateInterval, setStartDate, setEndDate } = props;

  const f = useFormatMessage();
  const { formatDate } = useIntl();

  const appliedCreatedDateFilters: AppliedFilterInterface[] = [];

  if (dateInterval && dateInterval !== "CUSTOM_DATE_RANGE") {
    appliedCreatedDateFilters.push({
      key: "date-interval",
      label: f(`dashboard.filters.date.interval.${dateInterval}`),
      onRemove: () => setDateInterval(undefined)
    });
  }

  if (startDate && dateInterval === "CUSTOM_DATE_RANGE") {
    appliedCreatedDateFilters.push({
      key: "start-date",
      label: f("dashboard.filters.date.from", { date: formatDate(startDate) }),
      onRemove: () => {
        setStartDate(undefined);
        if (!endDate) {
          setDateInterval(undefined);
        }
      }
    });
  }

  if (endDate && dateInterval === "CUSTOM_DATE_RANGE") {
    appliedCreatedDateFilters.push({
      key: "end-date",
      label: f("dashboard.filters.date.until", { date: formatDate(endDate) }),
      onRemove: () => {
        setEndDate(undefined);
        if (!startDate) {
          setDateInterval(undefined);
        }
      }
    });
  }

  return appliedCreatedDateFilters;
};

export default useDashboardDateAppliedFilters;
