import React from "react";
import { HorizontalStack, Spinner, Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import KDKBanner from "../KDKBanner/KDKBanner";

const ScreeningStartedBanner = () => {
  const f = useFormatMessage();

  return (
    <KDKBanner status="info" hideIcon>
      <HorizontalStack gap="2">
        <Spinner size={"small"} />
        <VerticalStack gap="2">
          <Text as="span" fontWeight="semibold">
            {f("details.banners.screening.in.progress.title")}
          </Text>
          <p>{f("details.banners.screening.in.progress.content")}</p>
        </VerticalStack>
      </HorizontalStack>
    </KDKBanner>
  );
};

export default ScreeningStartedBanner;
