import { useCallback } from "react";

import ImportedIcon from "../../assets/icons/checkmark-green.svg";
import NotFoundIcon from "../../assets/icons/circle-cross.svg";
import FileIcon from "../../assets/icons/file.svg";
import InitialIcon from "../../assets/icons/screening_inactive.svg";
import ValidationErrorIcon from "../../assets/icons/triangle-filled-exclamation.svg";
import { IMPORT_STATUS } from "../types/ImportRow";

import useFormatMessage from "./useFormatMessage";

const StatusIcons: Record<IMPORT_STATUS, string> = {
  INITIAL: FileIcon,
  VALIDATION_ERROR: ValidationErrorIcon,
  READY_FOR_IMPORT: InitialIcon,
  IMPORTING: InitialIcon,
  IMPORTED: ImportedIcon,
  IMPORT_ERROR: NotFoundIcon
};

const useGetImportCustomerStatusLabelAndIcon = () => {
  const f = useFormatMessage();

  const getStatusLabelAndIcon = useCallback((status: IMPORT_STATUS) => {
    const label = f(`import-customers.wizard.status.${status}`);
    const icon = StatusIcons[status];

    return { label, icon };
  }, []);

  return getStatusLabelAndIcon;
};

export default useGetImportCustomerStatusLabelAndIcon;
