import { useMutation } from "@tanstack/react-query";
import FileSaver from "file-saver";

import api from "../api";
import { convertChecklistToCreateChecklistRequest } from "../helpers/checklists.helpers";
import { ReviewChecklistExport } from "../types/ReviewChecklist";
import { convertToValidFilename } from "../utils/filenameUtils";

import useTags from "./useTags";

const useExportReviewChecklistMutation = () => {
  const { findTag } = useTags();

  const exportChecklist = async (checklist: api.ReviewChecklist) => {
    const checklistRequest = convertChecklistToCreateChecklistRequest(checklist);

    const exportData: ReviewChecklistExport = {
      checklist: checklistRequest,
      labels: checklistRequest.display_settings?.label_ids?.map(findTag) || []
    };

    const checklistExportString = JSON.stringify(exportData, null, "\t");

    const filename = convertToValidFilename(checklist.name) || "checklist";

    // @ts-ignore
    if (window.showSaveFilePicker) {
      const myblob = new Blob([checklistExportString], { type: "text/plain" });
      const opts = {
        types: [
          {
            description: "Checklist",
            accept: { "text/plain": [".json"] }
          }
        ],
        suggestedName: filename
      };
      // @ts-ignore
      const handle = await window.showSaveFilePicker(opts);
      // @ts-ignore
      const writable = await handle.createWritable();
      // @ts-ignore
      writable.write(myblob);

      writable.close();
    } else {
      const file = new File([checklistExportString], `${filename}.json`, { type: "text/plain" });
      FileSaver.saveAs(file);
    }
  };

  return useMutation(exportChecklist);
};

export default useExportReviewChecklistMutation;
