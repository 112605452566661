import { PropsWithChildren } from "react";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";

const HideOnMobile = (props: PropsWithChildren<unknown>) => {
  const { children, ...rest } = props;

  return <StyledWrapper {...rest}>{children}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  // For small viewports - hide
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

export default HideOnMobile;
