import { Text } from "@shopify/polaris";

import api from "../../api";
import { getCustomerName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import useRichFormatMessage from "../../hooks/useRichFormatMessage";
import { CustomerDetails } from "../../types/utilities";
import CondensedText from "../CondensedText/CondensedText";
import KDKBanner from "../KDKBanner/KDKBanner";

type KarLookupFailureBannerProps = {
  customer: CustomerDetails;
  lookupResult: api.KarLookupResult;
};

const KarLookupFailureBanner = (props: KarLookupFailureBannerProps) => {
  const { customer, lookupResult } = props;

  const f = useFormatMessage();
  const richFormat = useRichFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const customerName = getCustomerName(customer);

  return (
    <KDKBanner status="critical">
      <Text as={"p"}>
        {richFormat("kar.form.result.failure", {
          accountNumber: lookupResult.account_number,
          name: customerName,
          nationalId: lookupResult.national_id
        })}
      </Text>
      <CondensedText>
        {f("kar.form.result.by", {
          name: getUserDisplayName(lookupResult.created_by),
          timestamp: new Date(lookupResult.timestamp)
        })}
      </CondensedText>
    </KDKBanner>
  );
};

export default KarLookupFailureBanner;
