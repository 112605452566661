import { validateNorwegianIdNumber } from "norwegian-national-id-validator";

import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { isEmptyString } from "../../../utils/stringUtils";

const useNationalIdValidator = (country?: api.CountryEnum) => {
  const f = useFormatMessage();

  switch (country) {
    case "NO": {
      return {
        placeholder: f("personal.number.placeholder.no"),
        validator: validateNorwegianIdNumber,
        length: 11
      };
    }
    default: {
      return {
        placeholder: f("person.customer.details.personal.number"),
        validator: (nationalId: string) => !isEmptyString(nationalId),
        length: undefined
      };
    }
  }
};

export default useNationalIdValidator;
