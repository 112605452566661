import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

type DashboardNumbersItemProps = {
  value: number;
  percent: number;
  label: ReactNode;
};

const DashboardNumbersItem = (props: DashboardNumbersItemProps) => {
  const { value = 0, percent = 0, label } = props;

  const { formatNumber } = useIntl();

  return (
    <VerticalStack>
      <HorizontalStack gap="1" blockAlign="start">
        <Text variant="heading2xl" as="p">
          {formatNumber(value)}
        </Text>
        <StyledPercentWrapper>
          <Text variant="bodyMd" as="span" fontWeight="bold">
            {formatNumber(percent, { minimumFractionDigits: 1, maximumFractionDigits: 1, style: "percent" })}
          </Text>
        </StyledPercentWrapper>
      </HorizontalStack>
      <Text as="span" variant="bodyMd" fontWeight="bold" truncate>
        {label}
      </Text>
    </VerticalStack>
  );
};

const StyledPercentWrapper = styled.div`
  padding-left: var(--p-space-2);
  display: block;
  margin-left: var(--p-space-2);
  border-left: var(--p-border-divider);
`;

export default DashboardNumbersItem;
