import React, { useState } from "react";
import { HorizontalStack, RadioButton, TextField, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../api";
import Risk from "../../../components/Risk/Risk";
import { INTERVALS_IN_MONTHS, MAX_FOLLOWUP_INTERVAL_IN_MONTHS } from "../../../constants/followup";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { isNil } from "../../../utils/util";

interface RiskFollowupIntervalProps {
  risk: api.RiskLevel;
  intervalInMonths: number | undefined;
  isCustom: boolean;
  readonly?: boolean;
  onIntervalChange(intervalInMonths: number | undefined, isCustom: boolean): void;
}

const RiskFollowupInterval = (props: RiskFollowupIntervalProps) => {
  const { risk, isCustom, intervalInMonths, readonly, onIntervalChange } = props;

  const [customInterval, setCustomInterval] = useState(intervalInMonths || 1);

  const f = useFormatMessage();

  const getIntervalLabel = (months: number) =>
    months < 12 ? f("common.month", { months: months }) : f("common.year", { years: months / 12 });

  const handleCustomIntervalChange = (value: string) => {
    const months = parseInt(value);
    setCustomInterval(months);
    onIntervalChange(months, true);
  };

  return (
    <VerticalStack gap="8" key={risk}>
      <Risk risk={risk} labelSuffix={f("default.risk")} />
      <VerticalStack gap="2">
        <RadioButton
          label={f("followup-settings.intervals.labels.no-interval")}
          checked={isNil(intervalInMonths)}
          onChange={() => onIntervalChange(undefined, false)}
          disabled={readonly}
        />
        {INTERVALS_IN_MONTHS.map((interval) => (
          <RadioButton
            key={interval}
            label={getIntervalLabel(interval)}
            checked={intervalInMonths === interval && !isCustom}
            onChange={() => onIntervalChange(interval, false)}
            disabled={readonly}
          />
        ))}
        <HorizontalStack gap="4" blockAlign="center">
          <RadioButton
            label={f("followup-settings.intervals.labels.custom-interval")}
            checked={isCustom}
            onChange={() => onIntervalChange(customInterval, true)}
            disabled={readonly}
          />
          <StyledCustomIntervalWrapper>
            <TextField
              type="number"
              label={f("followup-settings.intervals.labels.custom-interval")}
              labelHidden
              placeholder={f("followup-settings.intervals.labels.custom-interval.placeholder")}
              autoComplete="off"
              suffix={f("default.months", { months: customInterval })}
              min={1}
              max={MAX_FOLLOWUP_INTERVAL_IN_MONTHS}
              value={Number(customInterval).toFixed(0)}
              onChange={handleCustomIntervalChange}
              disabled={readonly || !isCustom}
              focused={isCustom && !readonly}
            />
          </StyledCustomIntervalWrapper>
        </HorizontalStack>
      </VerticalStack>
    </VerticalStack>
  );
};

// limit the width of the custom text field
const StyledCustomIntervalWrapper = styled.div`
  width: var(--kdk-followup-number-max-width);
  padding-bottom: var(--p-space-1);
`;

export default RiskFollowupInterval;
