import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Badge,
  Button,
  Card,
  DataTable,
  HorizontalStack,
  Layout,
  Page,
  Stack,
  Text,
  VerticalStack
} from "@shopify/polaris";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import BackButton from "../../components/BackButton/BackButton";
import DisplayName from "../../components/DisplayName/DisplayName";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import ScreeningSkeleton from "../../components/ScreeningSkeleton/ScreeningSkeleton";
import ScreeningStatusBadge from "../../components/ScreeningStatusBadge/ScreeningStatusBadge";
import ScreeningTypeCard from "../../components/ScreeningTypeCard/ScreeningTypeCard";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import StopScreeningModal from "../../components/StopScreeningModal/StopScreeningModal";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getBirthDate, getPersonDisplayName } from "../../helpers/display.helpers";
import { isScreeningActive } from "../../helpers/screening";
import useCustomerDetailsBackButton from "../../hooks/useCustomerDetailsBackButton";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import ErrorPage from "../ErrorPage";

const PERSON_DETAILS_RESPONSE_INDEX = 0;
const PERSON_SCREENING_RESPONSE_INDEX = 1;

const PersonChangeScreeningPage = () => {
  const { id } = useParams<IDParams>();

  const f = useFormatMessage();
  const queryClient = useQueryClient();

  const [screeningType, setScreeningType] = useState<api.ScreeningType>("MONITORING");

  const [isControlConfirmationActive, setControlConfirmationActive] = useState(false);

  const { backButtonURL, goToCustomerDetails } = useCustomerDetailsBackButton(id, "person");

  const { isLoading, isError, error, data } = useQuery([QUERIES_KEYS.PERSON_CHANGE_SCREENING, id], () =>
    Promise.all([api.getPersonCustomerDetails(id), api.getPersonScreeningConfiguration(id)])
  );

  const changeScreeningMutation = useMutation(
    () => api.updatePersonScreeningConfiguration(id, { screening_type: screeningType }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES_KEYS.PERSON_CHANGE_SCREENING, id]);
        goToCustomerDetails(f("screening.snackbar.started"));
      }
    }
  );

  const screening = data ? data[PERSON_SCREENING_RESPONSE_INDEX] : undefined;
  const isNewScreening = screening?.screening_status && screening.screening_status === "DONE";
  const customerName = screening?.customer_name || "";

  useEffect(() => {
    if (screening?.screening_type) {
      setScreeningType(screening.screening_type);
    }
  }, [screening?.screening_type]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (isLoading || !data) {
    return <ScreeningSkeleton />;
  }

  const person = data[PERSON_DETAILS_RESPONSE_INDEX];

  return (
    <Page>
      <StopScreeningModal
        id={id}
        isOpen={isControlConfirmationActive}
        isPerson={true}
        onStop={() => {
          setControlConfirmationActive(false);
          goToCustomerDetails(f("screening.snackbar.stopped"));
        }}
        onClose={() => {
          setControlConfirmationActive(false);
        }}
      />
      <VerticalStack gap="4">
        <BackButton url={backButtonURL} displayLabel={customerName} showAsLink />

        <Text variant="heading2xl" as="p">
          {!isNewScreening ? f("change-screening.titles.screening-title") : f("change-screening.titles.new-screening")}
        </Text>

        <Layout>
          <Layout.Section>
            <VerticalStack gap="4">
              {!isNewScreening && (
                <Stack distribution="equalSpacing">
                  <Stack alignment="center" spacing="tight">
                    <Text variant="bodyMd" as="span" fontWeight="semibold">
                      Status:
                    </Text>
                    <div>{f(`screening-type.${screeningType}`)}</div>
                    <ScreeningStatusBadge
                      screening={{
                        screening_status: screening!.screening_status,
                        screening_type: screeningType
                      }}
                    />
                    {isScreeningActive(screening) && (
                      <Badge status="success" progress="complete">
                        {f("screening.status.active")}
                      </Badge>
                    )}
                  </Stack>
                  <Button onClick={() => setControlConfirmationActive(true)}>
                    {f("change-screening.buttons.stop-monitoring")}
                  </Button>
                </Stack>
              )}

              <Text variant="headingLg" as="p">
                {f("change-screening.titles.screening-person")}
              </Text>

              <Card>
                <DataTable
                  columnContentTypes={["text"]}
                  headings={[]}
                  rows={[
                    [
                      <DisplayName
                        type={"person"}
                        name={getPersonDisplayName(person)}
                        birthDate={getBirthDate(person)}
                      />
                    ]
                  ]}
                />
              </Card>

              {isNewScreening && (
                <VerticalStack gap="4">
                  <SectionMargin />
                  <Text variant="headingXl" as="p">
                    {f("change-screening.titles.choose-screening-type")}
                  </Text>
                  <Card>
                    <Card.Section>
                      <ScreeningTypeCard
                        screeningType={screeningType}
                        onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
                      />
                    </Card.Section>
                  </Card>

                  {changeScreeningMutation.isError && <ErrorPanel message={changeScreeningMutation.error} />}

                  <HorizontalStack gap="4" align="end">
                    <Button disabled={changeScreeningMutation.isLoading} onClick={() => goToCustomerDetails()}>
                      {f("default.cancel")}
                    </Button>
                    <Button
                      primary
                      disabled={changeScreeningMutation.isLoading || !screeningType}
                      loading={changeScreeningMutation.isLoading}
                      onClick={changeScreeningMutation.mutate}
                    >
                      {f("change-screening.buttons.start-screening")}
                    </Button>
                  </HorizontalStack>
                </VerticalStack>
              )}
            </VerticalStack>
          </Layout.Section>
        </Layout>
      </VerticalStack>
    </Page>
  );
};

export default PersonChangeScreeningPage;
