import React from "react";
import { Button, ButtonGroup, ButtonGroupProps, ButtonProps } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

export type EmailOrLink = "email" | "link";

interface EmailOrLinkButtonGroupInputProps extends ButtonGroupProps {
  value?: EmailOrLink;
  className?: string;
  disabled?: boolean;
  onChange(value: EmailOrLink): void;
  buttonsSize?: ButtonProps["size"];
}

const EmailOrLinkButtonGroupInput = (props: EmailOrLinkButtonGroupInputProps) => {
  const { value, onChange, disabled, className, ...buttonGroupProps } = props;

  const f = useFormatMessage();

  const isEmail = value === "email";

  return (
    <span className={className}>
      <ButtonGroup segmented {...buttonGroupProps}>
        <Button disabled={disabled} size={"medium"} primary={isEmail} onClick={() => onChange("email")}>
          {f("default.email")}
        </Button>
        <Button disabled={disabled} size={"medium"} primary={!isEmail} onClick={() => onChange("link")}>
          {f("default.link")}
        </Button>
      </ButtonGroup>
    </span>
  );
};

export default EmailOrLinkButtonGroupInput;
