import React from "react";
import { Card, Checkbox, HorizontalStack, Page, Stack, Text, TextField, VerticalStack } from "@shopify/polaris";

import ClockIcon from "../../../assets/icons/clock.svg";
import PersonIcon from "../../../assets/icons/person.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import useGetUserName from "../../hooks/useGetUserName";
import useOpenClose from "../../hooks/useOpenClose";
import { isEmptyString } from "../../utils/stringUtils";
import ConfirmAction from "../ConfirmAction/ConfirmAction";
import DisclosureSignatureForm from "../DisclosureSignatureForm/DisclosureSignatureForm";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import PersonForm from "../PersonForm/PersonForm";
import PopoverButton from "../PopoverButton/PopoverButton";
import ScreeningTypeCard from "../ScreeningTypeCard/ScreeningTypeCard";
import UsersList from "../UsersList/UsersList";

import useOnboardingPersonForm from "./useOnboardingPersonForm";

type PersonKeys = keyof api.CreatePersonCustomerRequest;

type PersonValues = api.CreatePersonCustomerRequest[keyof api.CreatePersonCustomerRequest];

export type OnboardingPersonFormProps = {
  personId?: string;
  personValues: api.CreatePersonCustomerRequest;
  screeningType: api.ScreeningType;
  isSignatureRequired: boolean;
  signature: api.PersonSignatureConfiguration;
  disableCancelOnboarding?: boolean;
  responsibleUsersIds: string[];
  assignments?: api.Assignment[];
};

const OnboardingPersonForm = (props: OnboardingPersonFormProps) => {
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();
  const features = useFeatures();
  const { getUserFullName } = useGetUserName();

  const {
    createdFromProject,
    isSavedPerson,
    person,
    setPerson,
    changed,
    responsibleUsersIds,
    setResponsibleUsersIds,
    screeningType,
    setScreeningType,
    signature,
    setSignature,
    selectedAssignment,
    setSelectedAssignment,
    setIsFormValid,
    invalid,
    shouldSendDisclosure,
    setShouldSendDisclosure,
    finishOnboarding,
    isLoading,
    generalError,
    fieldsErrorsMap,
    registerPerson,
    savePerson,
    cancelOnboarding,
    handleCancel
  } = useOnboardingPersonForm(props);

  const [showCancelConfirm, toggleCancelConfirm] = useOpenClose();

  const customerName = isEmptyString(getFullName(person)) ? f("screening.titles.customer") : getFullName(person);

  const popoverLabel =
    responsibleUsersIds.length === 0
      ? f("responsible.empty.users")
      : formatMultipleNames(
          responsibleUsersIds.map((userId) => getUserFullName(userId)),
          1
        );

  const handlePersonChange = (key: PersonKeys, value: PersonValues) =>
    setPerson((prevPerson) => ({ ...prevPerson, [key]: value }));

  return (
    <Page>
      <VerticalStack gap="4">
        <HorizontalStack gap="1" blockAlign="center" wrap={false}>
          <Icon source={PersonIcon} />
          <Text variant="headingMd" as="h2">
            {f("onboarding.new.person.title")}
          </Text>
        </HorizontalStack>

        {createdFromProject && (
          <Card.Section>
            <Text variant="headingMd" as="h2">
              {createdFromProject.name}
            </Text>
          </Card.Section>
        )}

        <VerticalStack>
          <PersonForm<api.CreatePersonCustomerRequest>
            renderAsCards
            value={person}
            birthDateIsRequired
            isCreating
            disabled={isLoading}
            onChange={setPerson}
            onValidityChange={setIsFormValid}
            fieldsErrorMap={fieldsErrorsMap}
          />

          <Card>
            <Card.Section
              title={
                <Text variant="headingXs" as="h3">
                  {f("screening.titles.screening-frequency")}
                </Text>
              }
            >
              <ScreeningTypeCard
                screeningType={screeningType}
                onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
              />
            </Card.Section>
          </Card>

          <Card>
            <Card.Section>
              <VerticalStack gap="4">
                <VerticalStack gap="2">
                  <Text variant="headingXs" as="h3">
                    {f("onboarding.disclosures.title")}
                  </Text>
                  <Text as="span" color="subdued">
                    {f("onboarding.disclosures.person-description")}
                  </Text>
                </VerticalStack>
                <Checkbox
                  checked={shouldSendDisclosure}
                  onChange={setShouldSendDisclosure}
                  label={
                    <Text as="span" fontWeight="semibold">
                      {f("onboarding.disclosures.person.require-disclosure")}
                    </Text>
                  }
                />
                <DisclosureSignatureForm
                  readonly={!shouldSendDisclosure}
                  signature={signature}
                  customerId={props.personId}
                  customerType="person"
                  signatureName={customerName}
                  onChange={setSignature}
                  assignments={props.assignments}
                  selectedAssignment={selectedAssignment}
                  onAssignmentChange={setSelectedAssignment}
                />
              </VerticalStack>
            </Card.Section>
          </Card>

          {features.EXTERNAL_ID && (
            <Card>
              <Card.Section
                title={
                  <Text variant="headingXs" as="h3">
                    {f("company-form.labels.external-id")}
                  </Text>
                }
              >
                <HorizontalStack>
                  <TextField
                    value={person.external_id || ""}
                    onChange={(newValue) => handlePersonChange("external_id", newValue)}
                    placeholder={f("company-form.labels.external-id")}
                    label={f("company-form.labels.external-id")}
                    labelHidden
                    disabled={isLoading}
                    maxLength={50}
                    error={fieldsErrorsMap.get("external_id")}
                    autoComplete="off"
                  />
                </HorizontalStack>
              </Card.Section>
            </Card>
          )}

          {features.RESPONSIBLE_USER && (
            <Card>
              <Card.Section
                title={
                  <Text variant="headingXs" as="h3">
                    {f("summary.responsible-user")}
                  </Text>
                }
              >
                <PopoverButton
                  fullWidth={false}
                  label={popoverLabel}
                  disclosure
                  disabled={isLoading}
                  render={() => (
                    <Card>
                      <Card.Section>
                        <UsersList
                          selectedUserIds={responsibleUsersIds}
                          onChange={setResponsibleUsersIds}
                          allowMultiple
                          selectedFirst
                        />
                      </Card.Section>
                    </Card>
                  )}
                />
              </Card.Section>
            </Card>
          )}
        </VerticalStack>

        <VerticalStack gap="4">
          {generalError && (
            <Card>
              <Card.Section>
                <ErrorPanel message={generalError} />
              </Card.Section>
            </Card>
          )}
        </VerticalStack>
      </VerticalStack>

      <Stack>
        <Button
          onClick={changed ? toggleCancelConfirm : handleCancel}
          disabled={isLoading}
          icon={<Icon source={TrashIcon} useMask />}
          destructive={isSavedPerson && changed}
        >
          {changed ? f("onboarding.cancel.and.delete") : f("default.cancel")}
        </Button>
        <Button
          onClick={savePerson}
          icon={<Icon source={ClockIcon} width={"16px"} height={"16px"} useMask />}
          disabled={isLoading || invalid}
          loading={isLoading && !finishOnboarding}
        >
          {f("onboarding.cancel.and.save")}
        </Button>
        <Stack.Item fill />
        <Button
          onClick={registerPerson}
          disabled={isLoading || invalid}
          loading={isLoading && finishOnboarding}
          primary
        >
          {f("summary.signers.start.screening")}
        </Button>
      </Stack>

      {showCancelConfirm && (
        <ConfirmAction
          apiCall={cancelOnboarding}
          title={f("onboarding.cancel.confirm.title")}
          description={f("onboarding.cancel.confirm.description", { name: customerName })}
          actionTitle={f("onboarding.cancel.and.delete")}
          onNo={toggleCancelConfirm}
          onSuccess={handleCancel}
        />
      )}
    </Page>
  );
};

export default OnboardingPersonForm;
