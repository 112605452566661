import React from "react";
import { Text } from "@shopify/polaris";

import api from "../../api";
import useGetUserName from "../../hooks/useGetUserName";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface AttachmentUploaderProps {
  user?: api.User;
}

const AttachmentUploader = (props: AttachmentUploaderProps) => {
  const { user } = props;

  const { getUserDisplayName } = useGetUserName();

  const username = getUserDisplayName(user);

  return (
    <TooltipWrapper content={username} dismissOnMouseOut preferredPosition="above">
      <Text as="span">{username}</Text>
    </TooltipWrapper>
  );
};

export default AttachmentUploader;
