import React, { PropsWithChildren } from "react";
import { Link, LinkProps, Stack } from "@shopify/polaris";
import styled from "styled-components";

import ExternalIcon from "../../../assets/icons/external-link-white-icon.svg";
import Icon from "../extensions/Icon";

const ExternalNavigationLink = (props: PropsWithChildren<LinkProps>) => {
  const { children, ...linkProps } = props;

  return (
    <StyledButton>
      <Link external removeUnderline {...linkProps}>
        <Stack wrap={false} spacing="extraTight">
          <Stack.Item>{children}</Stack.Item>
          <Icon source={ExternalIcon} />
        </Stack>
      </Link>
    </StyledButton>
  );
};

const StyledButton = styled.div`
  & .Polaris-Stack {
    color: var(--p-text-on-primary);
    background-color: var(--kdk-blue-500);
    border-radius: var(--p-border-radius-05);
    line-height: 1;

    &:hover {
      background-color: var(--kdk-blue-525);
    }

    &:active {
      background-color: var(--kdk-blue-550);
    }
  }
`;

export default ExternalNavigationLink;
