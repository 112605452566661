import SignedIcon from "../../../assets/icons/checkmark-green.svg";
import WaitingIcon from "../../../assets/icons/hourglass.svg";
import ExpiredIcon from "../../../assets/icons/subtract-red.svg";
import api from "../../api";

export const AttachmentSignatureStatusIconsMap: Record<api.AttachmentSignatureStatus, string> = {
  PENDING: WaitingIcon,
  SIGNED: SignedIcon,
  EXPIRED: ExpiredIcon,
  INVALID_FOR_SIGNING: ExpiredIcon
};
