import React, { PropsWithChildren, useEffect, useState } from "react";
import { HorizontalStack } from "@shopify/polaris";

import api from "../../api";
import { DEFAULT_LANGUAGE } from "../../constants/languages";
import LanguageSelect from "../DisclosureSignatureForm/LanguageSelect";
import MarkdownEditor from "../MarkdownEditor/MarkdownEditor";

type TranslationTextMarkdownEditorProps = {
  translationText?: api.TranslationText;
  language?: api.LanguageEnum;
  readonly?: boolean;
  onTextChange(translationText: api.TranslationText): void;
  onLanguageChange?(language: api.LanguageEnum): void;
};

const TranslationTextMarkdownEditor = (props: PropsWithChildren<TranslationTextMarkdownEditorProps>) => {
  const { translationText, language = DEFAULT_LANGUAGE, readonly, onTextChange, onLanguageChange, children } = props;

  const [selectedLanguage, setSelectedLanguage] = useState<api.LanguageEnum>(language);

  useEffect(() => setSelectedLanguage(language), [language]);
  useEffect(() => onLanguageChange && onLanguageChange(selectedLanguage), [selectedLanguage]);

  const handleChange = (value: string) =>
    onTextChange({ ...(translationText || { nb: "", en: "" }), [selectedLanguage]: value });

  return (
    <MarkdownEditor
      value={translationText?.[selectedLanguage] || ""}
      onChange={handleChange}
      toolbarRightSection={
        <HorizontalStack align={"end"}>
          <LanguageSelect onChange={setSelectedLanguage} value={selectedLanguage} disabled={readonly} />
        </HorizontalStack>
      }
      disabled={readonly}
      key={selectedLanguage}
    >
      {children}
    </MarkdownEditor>
  );
};

export default TranslationTextMarkdownEditor;
