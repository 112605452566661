export const isNumber = (value: number | string | undefined): value is number => {
  if (typeof value === "number" && !isNaN(value)) {
    return true;
  } else if (typeof value === "string") {
    try {
      const parsedValue = parseFloat(value);
      return !isNaN(parsedValue);
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};

// excluding min, including max
export const isNumberInRange = (value: number | string | undefined, min: number, max: number) => {
  return isNumber(value) && value > min && value <= max;
};
