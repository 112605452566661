import React, { PropsWithChildren } from "react";
import { HorizontalStack, Stack, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import PlusIcon from "../../../assets/icons/plus-major.svg";
import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getTagTitle } from "../../helpers/tag.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData from "../../hooks/useQueryData";
import useTags from "../../hooks/useTags";
import { removeItem } from "../../utils/collectionUtils";
import Icon from "../extensions/Icon";
import KDKTag from "../KDKTag/KDKTag";
import TagsEditorPopover from "../TagsEditorPopover/TagsEditorPopover";

interface TagsEditorProps {
  tags?: api.Label[];
  readonly?: boolean;
  onChange(tags: api.Label[]): void;
}

const TagsEditor = (props: PropsWithChildren<TagsEditorProps>) => {
  const { tags = [], readonly, onChange, children } = props;

  const f = useFormatMessage();
  const { allTags } = useTags();
  const { updateQueryData } = useQueryData<api.LabelList>([QUERIES_KEYS.ALL_TAGS]);

  const createNewTagMutation = useMutation(api.createLabel, {
    onSuccess: (newTag) => {
      updateQueryData((queryData) => {
        queryData.labels = [...queryData.labels, newTag].sort((tagA, tagB) =>
          getTagTitle(tagA).toLocaleLowerCase().localeCompare(getTagTitle(tagB).toLocaleLowerCase())
        );
        queryData.count += 1;
      });
      onChange([...tags, newTag]);
    }
  });

  return (
    <VerticalStack gap="4">
      <Stack distribution="equalSpacing" alignment="center">
        {children}
        <StyledTagsEditorPopover
          tags={allTags}
          selectedTags={tags}
          disabled={readonly}
          label={f("common.labels.create-tag")}
          icon={<Icon source={PlusIcon} useMask height="12px" width="12px" />}
          onSelectionChange={onChange}
          onNewTag={(title) => createNewTagMutation.mutate({ title })}
        />
      </Stack>
      <HorizontalStack gap="1">
        {tags.map((tag) => (
          <KDKTag tag={tag} key={tag.id} onRemove={!readonly ? () => onChange(removeItem(tags, tag)) : undefined} />
        ))}
      </HorizontalStack>
    </VerticalStack>
  );
};

const StyledTagsEditorPopover = styled(TagsEditorPopover)`
  & .Polaris-Button {
    box-shadow: none;
  }
`;

export default TagsEditor;
