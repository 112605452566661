import React, { useState } from "react";
import { ActionListItemDescriptor, Toast } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import ArchiveIcon from "../../../../assets/icons/archivebox.svg";
import DownloadIcon from "../../../../assets/icons/arrow-down-rectangle.svg";
import ExistingProjectIcon from "../../../../assets/icons/folders.svg";
import PencilIcon from "../../../../assets/icons/pencil.svg";
import RestoreIcon from "../../../../assets/icons/restore.svg";
import StarIcon from "../../../../assets/icons/star-filled.svg";
import TrashIcon from "../../../../assets/icons/trash.svg";
import api from "../../../api";
import { PERMISSIONS_TYPES } from "../../../constants/permissions";
import { isVitecProject } from "../../../helpers/integration.helpers";
import useErrorParser from "../../../hooks/useErrorParser";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import usePermissions from "../../../hooks/usePermissions";
import useProjectPermissions from "../../../hooks/useProjectPermissions";
import useQueryData from "../../../hooks/useQueryData";
import { CustomerDetails, getCustomerQueryKey, isPersonCustomerDetails } from "../../../types/utilities";
import ActionsDropdown from "../../ActionsDropdown/ActionsDropdown";
import AddToProjectModal from "../../CustomersListBulkActions/AddToProjectModal/AddToProjectModal";
import EditCompanyCustomerInfoModal from "../../EditCompanyCustomerInfoModal/EditCompanyCustomerInfoModal";
import EditPersonCustomerDetailsModal from "../../EditPersonCustomerDetailsModal/EditPersonCustomerDetailsModal";
import Icon from "../../extensions/Icon";

import CustomerArchiveConfirmation from "./CustomerArchiveConfirmation";
import CustomerDeleteConfirmation from "./CustomerDeleteConfirmation";
import PlanConversionModal from "./PlanConversionModal";
import useDownload from "./useDownload";

type CustomerActionsMenuProps = {
  customer: CustomerDetails;
  isMonitoring: boolean;
};

const CustomerActionsMenu = (props: CustomerActionsMenuProps) => {
  const { customer, isMonitoring } = props;

  const [showCustomerEditModal, toggleCustomerEditModal, closeCustomerEditModal] = useOpenClose();
  const [showArchiveCustomerConfirm, toggleArchiveCustomerConfirm, closeArchiveCustomerConfirm] = useOpenClose();
  const [showDeleteCustomerConfirm, toggleDeleteCustomerConfirm, closeDeleteCustomerConfirm] = useOpenClose();
  const [showAddToProjectModal, toggleAddToProjectModal, closeAddToProjectModal] = useOpenClose();
  const [showPlanConversionModal, togglePlanConversionModal, closePlanConversionModal] = useOpenClose();
  const [planConversionMessage, setPlanConversionMessage] = useState("");

  const parseError = useErrorParser(customer.type);
  const f = useFormatMessage();
  const features = useFeatures();
  const { setQueryData } = useQueryData<CustomerDetails>(getCustomerQueryKey(customer));
  const { isCreateOrAddToProjectEnabled } = useProjectPermissions();
  const { isPermittedTo } = usePermissions();

  const download = useDownload();
  const downloadReportMutation = useMutation(() => download(`/customers/${customer.id}/report`));

  const restoreCustomerMutation = useMutation(
    () =>
      api.updateCustomerDetails(customer.id, {
        is_archived: false
      }),
    {
      onSuccess: async (response) => setQueryData(response)
    }
  );

  const downloadReport = () => downloadReportMutation.mutate();
  const restoreCustomer = () => restoreCustomerMutation.mutate();
  const openPlanConversionModal = (content: string) => {
    setPlanConversionMessage(content);
    togglePlanConversionModal();
  };

  const editableCustomer = customer.type === "person" || (customer.type === "company" && customer.is_manual);
  const editCustomerInfoAction: ActionListItemDescriptor = {
    content:
      customer.type === "person"
        ? f("common.buttons.actions.edit-person-info")
        : f("common.buttons.actions.edit-company-info"),
    onAction: toggleCustomerEditModal,
    image: PencilIcon,
    disabled: customer.is_archived
  };

  const wrapAMLPackageAction = (
    action: ActionListItemDescriptor,
    planConversionContent: string,
    featureEnabled?: boolean
  ) => {
    if (featureEnabled) {
      return action;
    } else {
      // Show info about upgrading AML plan/package if needed feature is NOT enabled, and
      // feature for "nudging" the user/org about upgrading its AML package IS enabled
      return {
        ...action,
        onAction: () => openPlanConversionModal(planConversionContent),
        suffix: <Icon source={StarIcon} />,
        helpText: f("plan-conversion.customer-action.label")
      };
    }
  };

  const isCustomerFromVitecProject = Boolean(customer.projects?.some(isVitecProject));

  const actions: ActionListItemDescriptor[] = [];

  if (!features.CUSTOMER__HIDE_DOWNLOAD_REPORT_BUTTON) {
    actions.push({
      content: f("common.buttons.actions.download.report"),
      onAction: downloadReport,
      image: DownloadIcon,
      disabled: downloadReportMutation.isLoading
    });
  }

  if (editableCustomer) {
    actions.push(editCustomerInfoAction);
  }

  const addToProjectAction = wrapAMLPackageAction(
    {
      content: f("bulk.actions.add-to-existing-project.label"),
      image: ExistingProjectIcon,
      onAction: toggleAddToProjectModal,
      disabled: customer.is_archived
    },
    f("plan-conversion.add-to-projects.description"),
    isCreateOrAddToProjectEnabled
  );

  // hide action to add a customer to a project if the customer has one or mote Vitec projects associated with them
  if (!isCustomerFromVitecProject && addToProjectAction) {
    actions.push(addToProjectAction);
  }

  if (customer.is_archived) {
    actions.push({
      content: f("common.buttons.actions.restore-customer"),
      onAction: restoreCustomer,
      image: RestoreIcon,
      disabled: restoreCustomerMutation.isLoading
    });

    if (isPermittedTo(PERMISSIONS_TYPES.DELETE_CUSTOMER)) {
      actions.push({
        content: f("common.buttons.actions.delete-customer"),
        onAction: toggleDeleteCustomerConfirm,
        image: TrashIcon
      });
    }
  }

  if (!customer.is_archived) {
    actions.push({
      content: f("common.buttons.actions.archive-customer"),
      onAction: toggleArchiveCustomerConfirm,
      image: ArchiveIcon
    });
  }

  const isLoading = downloadReportMutation.isLoading || restoreCustomerMutation.isLoading;
  const isError = downloadReportMutation.isError || restoreCustomerMutation.isError;
  const { generalError = f("common.errors.generic") } = parseError(
    downloadReportMutation.error || restoreCustomerMutation.error
  );

  const actionLabel = restoreCustomerMutation.isError
    ? f("common.buttons.actions.restore-customer").toLocaleLowerCase()
    : f("common.buttons.actions.download.report").toLocaleLowerCase();

  const displayMessage = f("common.errors.generic.failed-action", {
    actionLabel,
    message: generalError
  });

  const resetErroneousMutation = () => {
    if (downloadReportMutation.isError) {
      downloadReportMutation.reset();
    } else if (restoreCustomerMutation.isError) {
      restoreCustomerMutation.reset();
    }
  };

  // if there's only 1 action - set cutoff to 1 (which will render the single action menu as a button)
  // otherwise, set cutoff to 0 (which will render the action menu as a dropdown menu)
  const showSingleActionAsButton = actions.length === 1 ? 1 : 0;

  return (
    <>
      <ActionsDropdown items={actions} loading={isLoading} cutoff={showSingleActionAsButton} primary size={"medium"} />
      {showCustomerEditModal && isPersonCustomerDetails(customer) && (
        <EditPersonCustomerDetailsModal person={customer} onClose={closeCustomerEditModal} />
      )}
      {showCustomerEditModal && !isPersonCustomerDetails(customer) && (
        <EditCompanyCustomerInfoModal companyCustomer={customer} onClose={closeCustomerEditModal} />
      )}
      {showAddToProjectModal && (
        <AddToProjectModal customers={[customer]} hideCustomersCount onClose={closeAddToProjectModal} />
      )}
      {isError && <Toast content={displayMessage} error onDismiss={resetErroneousMutation} />}
      {showArchiveCustomerConfirm && (
        <CustomerArchiveConfirmation
          customer={customer}
          isMonitoring={isMonitoring}
          onClose={closeArchiveCustomerConfirm}
        />
      )}
      {showDeleteCustomerConfirm && (
        <CustomerDeleteConfirmation customer={customer} onClose={closeDeleteCustomerConfirm} />
      )}
      {showPlanConversionModal && (
        <PlanConversionModal onClose={closePlanConversionModal} content={planConversionMessage} />
      )}
    </>
  );
};

export default CustomerActionsMenu;
