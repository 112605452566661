import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import WarningIcon from "../../../assets/icons/triangle-filled-exclamation.svg";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";
import RowHighlighter from "../extensions/RowHighlighter";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export type InfoRowProps = {
  label?: string;
  isWarning?: boolean;
  warningDescription?: ReactNode;
  isHighlight?: boolean;
  useCondensedLabels?: boolean;
  prefix?: ReactElement;
  suffix?: ReactElement;
};

const InfoRow = (props: PropsWithChildren<InfoRowProps>) => {
  const { label, isWarning, warningDescription, isHighlight, useCondensedLabels, prefix, children } = props;

  return (
    <RowHighlighter warning={isWarning} highlight={isHighlight}>
      <HorizontalStack gap="2" blockAlign="center" wrap={false}>
        {isWarning && (
          <TooltipWrapper content={warningDescription} preferredPosition="above" dismissOnMouseOut>
            <Icon source={WarningIcon}></Icon>
          </TooltipWrapper>
        )}
        {prefix}
        {label && useCondensedLabels && <CondensedText>{label}</CondensedText>}
        {label && !useCondensedLabels && (
          <Text as="span" color="subdued">
            {label}
          </Text>
        )}
        {children}
      </HorizontalStack>
    </RowHighlighter>
  );
};

export default InfoRow;
