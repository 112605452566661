import * as React from "react";
import {
  Banner,
  Card,
  DataTable,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  VerticalStack
} from "@shopify/polaris";

const OnboardingWizardPageSkeleton = () => (
  <Page>
    <VerticalStack gap="4">
      <SkeletonDisplayText size="large" />

      <Text as="span">
        <SkeletonBodyText lines={1} />
      </Text>

      <Banner status="info">
        <VerticalStack gap="4">
          <Text as="span" fontWeight="semibold">
            <SkeletonBodyText lines={1} />
          </Text>
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
        </VerticalStack>
      </Banner>

      <Card>
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={[]}
          rows={[[<SkeletonBodyText lines={1} />], [<SkeletonBodyText lines={1} />], [<SkeletonBodyText lines={1} />]]}
        />
      </Card>
    </VerticalStack>
  </Page>
);

export default OnboardingWizardPageSkeleton;
