import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { Entity } from "../types/utilities";
import download from "../utils/downloadUtils";
import { addSuffixToFilename, cleanupFilename } from "../utils/filenameUtils";

import useAttachmentsAPI from "./useAttachmentsAPI";

type DownloadAttachmentParams = {
  downloadSignedAttachment: boolean;
};

const useAttachmentDownload = (entity: Entity, attachment: api.AttachmentDetails) => {
  const { entityId, downloadAttachment, downloadSignedDocumentAttachment } = useAttachmentsAPI(entity, attachment);

  return useMutation<unknown, unknown, DownloadAttachmentParams>(async (params) => {
    const data: { download_url: string } = params?.downloadSignedAttachment
      ? await downloadSignedDocumentAttachment(entityId, attachment.id)
      : await downloadAttachment(entityId, attachment.id);

    const filename = addSuffixToFilename(
      cleanupFilename(attachment.filename),
      params?.downloadSignedAttachment ? " - signed" : ""
    );

    return download(data?.download_url, filename);
  });
};

export default useAttachmentDownload;
