import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { DEFAULT_ONBOARDING_CONFIGURATION } from "../constants/onboarding";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";

const useGetOnboardingConfiguration = (customerType: api.CustomerType) => {
  const { isError, data, error } = useQuery([QUERIES_KEYS.ONBOARDING_SETTINGS], api.getPersonOnboardingSettings, {
    staleTime: STALE_TIME
  });

  if (isError) {
    Sentry.captureException(error, { level: "warning" });
    return DEFAULT_ONBOARDING_CONFIGURATION;
  }

  return customerType === "person" ? data : DEFAULT_ONBOARDING_CONFIGURATION;
};

export default useGetOnboardingConfiguration;
