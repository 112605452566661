import React from "react";
import { Button, HorizontalStack, Modal, Toast, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import DisplayName from "../DisplayName/DisplayName";
import EmailView from "../EmailView/EmailView";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

type RemindSignerModalProps = {
  customerId: string;
  signatureId: string;
  email: string;
  name?: string;
  birthdate?: string;
  open: boolean;
  onModalClose(): void;
};

const RemindSignerModal = (props: RemindSignerModalProps) => {
  const { customerId, signatureId, email, name, birthdate, open, onModalClose } = props;

  const [showSendEmailsToast, toggleSendEmailsToast, closeSendEmailsToast] = useOpenClose();

  const sendReminderMutation = useMutation(() => api.remindSigner(customerId, signatureId), {
    onSuccess: () => toggleSendEmailsToast()
  });

  const f = useFormatMessage();

  const sendReminder = () => sendReminderMutation.mutate();

  const handleToastDismiss = () => {
    closeSendEmailsToast();
    onModalClose();
  };

  return (
    // modal should remain visible until send email success Toast is displayed
    <>
      <Modal open={open && !showSendEmailsToast} onClose={onModalClose} title={f("modals.remind.signer.title")}>
        <Modal.Section>
          <VerticalStack gap="4">
            <DisplayName type="person" name={name} birthDate={birthdate} />
            <EmailView>{email}</EmailView>

            {sendReminderMutation.isError && <ErrorPanel message={sendReminderMutation.error} />}

            <HorizontalStack gap="4" align="end">
              <Button onClick={onModalClose} disabled={sendReminderMutation.isLoading}>
                {f("default.cancel")}
              </Button>
              <Button primary onClick={sendReminder} loading={sendReminderMutation.isLoading}>
                {f("modals.remind.signer.button")}
              </Button>
            </HorizontalStack>
          </VerticalStack>
        </Modal.Section>
      </Modal>
      {showSendEmailsToast && (
        <Toast
          content={f("attachments.signatures.modal.labels.email-sent", { count: 1 })}
          onDismiss={handleToastDismiss}
          duration={4_000}
        />
      )}
    </>
  );
};

export default RemindSignerModal;
