import React from "react";
import { Text } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import useValidateDisclosureForm from "../../hooks/useValidateDisclosureForm";
import { SignatureConfiguration } from "../../types/Disclosure";
import { ValueOf } from "../../types/utilities";
import EmailField from "../EmailField/EmailField";
import SignatureConfigurationForm, {
  SignatureConfigurationFormProps
} from "../SignatureConfigurationForm/SignatureConfigurationForm";

export type DisclosureSignatureFormProps = Omit<SignatureConfigurationFormProps, "signature" | "onSignatureChange"> & {
  signature: SignatureConfiguration;
  onChange(signature: SignatureConfiguration, isValid: boolean): void;
};

const DisclosureSignatureForm = (props: DisclosureSignatureFormProps) => {
  const { signature, readonly, onChange, ...signatureFormProps } = props;

  const f = useFormatMessage();
  const validateForm = useValidateDisclosureForm();

  const onFieldChange = (field: keyof SignatureConfiguration, value?: ValueOf<SignatureConfiguration>) => {
    const changedSignature = {
      ...signature,
      [field]: value
    };

    // Clear email if `send_email` is set to False
    if (field === "send_email" && value === false) {
      changedSignature["email"] = undefined;
    }

    // Perform validation
    const isValid = validateForm([changedSignature]);

    onChange(changedSignature, isValid);
  };

  return (
    <SignatureConfigurationForm
      signature={signature}
      readonly={readonly}
      onSignatureChange={(updateSignature) => onChange(updateSignature, validateForm([updateSignature]))}
      {...signatureFormProps}
    >
      {signature.send_email && (
        <EmailField
          required
          placeholder={f("disclosure-request.field.email.placeholder")}
          value={signature.email}
          disabled={readonly}
          onChange={(newEmail) => onFieldChange("email", newEmail)}
        />
      )}
      {!signature.send_email && (
        <Text as="span" color="subdued">
          {f("signature.email.not.provided")}
        </Text>
      )}
    </SignatureConfigurationForm>
  );
};

export default DisclosureSignatureForm;
