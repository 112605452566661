import React from "react";
import { Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";

import IndustryInfo from "./IndustryInfo";

type IndustryRiskProps = {
  industryRisk?: api.IndustryRiskInformation;
  title?: string;
  showAllIndustryTypes?: boolean;
};

const IndustryRisk = (props: IndustryRiskProps) => {
  const { industryRisk, title, showAllIndustryTypes } = props;

  return (
    <VerticalStack gap="2">
      {title && (
        <Text as="span" color="subdued">
          {title}
        </Text>
      )}
      {(showAllIndustryTypes || industryRisk?.industry_types?.industry_code_1?.has_risk) && (
        <IndustryInfo industry={industryRisk?.industry_types?.industry_code_1} />
      )}
      {(showAllIndustryTypes || industryRisk?.industry_types?.industry_code_2?.has_risk) && (
        <IndustryInfo industry={industryRisk?.industry_types?.industry_code_2} />
      )}
      {(showAllIndustryTypes || industryRisk?.industry_types?.industry_code_3?.has_risk) && (
        <IndustryInfo industry={industryRisk?.industry_types?.industry_code_3} />
      )}
    </VerticalStack>
  );
};

export default IndustryRisk;
