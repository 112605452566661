import React, { forwardRef, PropsWithChildren } from "react";
import { Card, HorizontalStack } from "@shopify/polaris";
import styled from "styled-components";

import CopyIcon from "../../../../assets/icons/copy.svg";
import TrashIcon from "../../../../assets/icons/trash.svg";
import useFormatMessage from "../../../hooks/useFormatMessage";
import Button from "../../extensions/Button";
import Icon from "../../extensions/Icon";
import MoveButtons, { Direction } from "../../MoveButtons/MoveButtons";
import TooltipWrapper from "../../TooltipWrapper/TooltipWrapper";
import VerticalDivider from "../../VerticalDivider/VerticalDivider";

type QuestionsTableRowProps = {
  isFirst: boolean;
  isLast: boolean;
  readonly?: boolean;
  onOrderChange(direction: Direction): void;
  onDuplicate(): void;
  onDelete(): void;
};

const QuestionsTableRow = forwardRef<HTMLDivElement, PropsWithChildren<QuestionsTableRowProps>>((props, ref) => {
  const { isFirst, isLast, readonly, onOrderChange, onDuplicate, onDelete, children } = props;

  const f = useFormatMessage();

  return (
    // use a div element with className "Polaris-Card" to mimic Polaris Card component but with the ability to set a ref
    <StyledCardWrapper ref={ref} className="Polaris-Card">
      <Card.Section fullWidth>
        <StyledCardToolbar>
          <HorizontalStack gap="4" blockAlign={"stretch"}>
            <TooltipWrapper content={f("default.copy")} dismissOnMouseOut>
              <Button onClick={onDuplicate} icon={CopyIcon} plain hoverable disabled={readonly} />
            </TooltipWrapper>
            <VerticalDivider />
            <MoveButtons
              disableMoveUp={isFirst}
              disableMoveDown={isLast}
              readonly={readonly}
              onOrderChange={onOrderChange}
            />
          </HorizontalStack>
        </StyledCardToolbar>
        {children}
        <StyledCardFooter>
          <Button
            destructive
            plain
            removeUnderline
            disabled={readonly}
            icon={<Icon source={TrashIcon} useMask />}
            onClick={onDelete}
          >
            {f("question.editor.buttons.delete.label")}
          </Button>
        </StyledCardFooter>
      </Card.Section>
    </StyledCardWrapper>
  );
});

const StyledCardWrapper = styled.div`
  position: relative;
`;

const StyledCardToolbar = styled.div`
  position: absolute;

  top: var(--p-space-4);
  right: var(--p-space-4);
`;

const StyledCardFooter = styled.div`
  position: absolute;

  bottom: var(--p-space-4);
  right: var(--p-space-4);
`;

export default QuestionsTableRow;
