import React from "react";
import { Card, Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import { CustomerDetails } from "../../types/utilities";
import NewCustomerReviewButton from "../NewCustomerReviewButton/NewCustomerReviewButton";

type EmptyReviewsBannerProps = {
  customer: CustomerDetails;
  readonly?: boolean;
};

const EmptyReviewsBanner = (props: EmptyReviewsBannerProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();

  return (
    <Card.Section>
      <VerticalStack inlineAlign="center" gap="4">
        <Text as="span">{f("reviews.labels.no-reviews")}</Text>
        <NewCustomerReviewButton customer={customer} disabled={readonly}>
          {f("reviews.labels.perform-a-review")}
        </NewCustomerReviewButton>
      </VerticalStack>
    </Card.Section>
  );
};

export default EmptyReviewsBanner;
