import { MLRO_GROUP_KEY } from "../constants/permissions";

import useAuth from "./useAuth";

const useIsMLROUser = () => {
  const { user } = useAuth();

  return Boolean(user?.groups?.find((group) => group.role_key === MLRO_GROUP_KEY) !== undefined);
};

export default useIsMLROUser;
