import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";

import useTranslatedText from "./useTranslatedText";

const useGetAssignmentIndustriesAndPurposes = () => {
  const t = useTranslatedText();

  const getAllAssignmentIndustriesAndPurposesQuery = useQuery(
    [QUERIES_KEYS.ALL_ASSIGNMENT_INDUSTRIES_AND_PURPOSES],
    () => api.getAssignmentIndustries(),
    { staleTime: STALE_TIME }
  );

  const isLoading = getAllAssignmentIndustriesAndPurposesQuery.isLoading;

  const industries = getAllAssignmentIndustriesAndPurposesQuery.data?.industries || [];
  const default_purposes = getAllAssignmentIndustriesAndPurposesQuery.data?.default_purposes || [];

  const allPurposes = useMemo(
    () => [default_purposes, industries.flatMap((industry) => industry.purposes)].flat(),
    [isLoading]
  );

  const getPurposes = useCallback(
    (industryType?: string, filterDeprecated?: boolean) => {
      // Return the default purposes for the organization the user is signed in as.
      const purposes = industryType
        ? industries.find((industry) => industry.value === industryType)?.purposes || []
        : default_purposes;

      return filterDeprecated ? purposes.filter((purpose) => !purpose.is_deprecated) : purposes;
    },
    [industries, default_purposes]
  );

  const getPurposeLabel = useCallback(
    (purpose?: string) => {
      if (!purpose || isLoading) return "";

      const purposeLabel = allPurposes.find((entry) => entry.value === purpose)?.label;

      return purposeLabel ? t(purposeLabel) : purpose;
    },
    [allPurposes, isLoading]
  );

  return { industries, getPurposes, getPurposeLabel, isLoading };
};

export default useGetAssignmentIndustriesAndPurposes;
