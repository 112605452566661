export enum PAGE_ANCHORS {
  TOP_SECTION = "root", // element with id="root" is added automatically by Polaris
  COMPANY_ROLES_AND_OWNERS_SECTION = "roles",
  CUSTOMER_ASSIGNMENTS = "assignments",
  CUSTOMER_DETAILS = "details",
  CUSTOMER_DOCUMENTS = "documents",
  CUSTOMER_MATCH_CASES = "match-cases",
  DISCLOSURES_SECTION = "disclosures",
  PENDING_REVIEWS_SECTION = "pending-reviews",
  REVIEWS_SECTION = "reviews",
  REVIEW_CHECKLIST_FORM = "review-checklist-from"
}
