import { useEffect } from "react";

const useAppTitle = (suffix?: string) => {
  const baseTitle = `Kjenn din kunde${process.env.NINJA_DEV == "true" ? " - staging" : ""}`;

  useEffect(() => {
    document.title = baseTitle + (suffix ? ` - ${suffix}` : "");

    return () => {
      document.title = baseTitle;
    };
  }, [baseTitle, suffix]);
};

export default useAppTitle;
