import React, { PropsWithChildren } from "react";
import { Stack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useLanguage from "../../hooks/useLanguage";
import KDKTag from "../KDKTag/KDKTag";

interface DisplayTagsWrapperProps {
  tags?: api.Label[];
  readonly?: boolean;
  onRemove?(tag: api.Label): void;
}

const DisplayTagsWrapper = (props: PropsWithChildren<DisplayTagsWrapperProps>) => {
  const { tags, readonly, onRemove, children } = props;

  const language = useLanguage();

  if (!tags || tags.length === 0) {
    return <>{children}</>;
  }

  return (
    <Stack spacing="extraTight" alignment="leading">
      <Stack.Item>{children}</Stack.Item>
      <StyledLabelsContainer>
        {tags.map((tag) => (
          <KDKTag
            tag={tag}
            key={tag.id}
            language={language}
            onRemove={onRemove && !readonly ? () => onRemove(tag) : undefined}
          />
        ))}
      </StyledLabelsContainer>
    </Stack>
  );
};

const StyledLabelsContainer = styled.div`
  display: flex;
  gap: var(--p-space-1);

  // shrink all tags evenly but show at least 4 characters of each tag (if possible)
  & > * {
    transition: flex-shrink var(--p-ease-in-out) var(--p-duration-150);
    min-width: 4ch;
    flex-shrink: 1;
  }

  // expand the hovered tag to show all of its content
  & > *:hover {
    flex-shrink: 0;
  }
`;

export default DisplayTagsWrapper;
