import {
  CREATED_AFTER_NAME_PARAMETER,
  CREATED_BEFORE_NAME_PARAMETER,
  CREATED_RANGE_NAME_PARAMETER,
  CUSTOMERS_PER_PAGE,
  DateRange,
  LIMIT_NAME_PARAMETER,
  ORDER_NAME_PARAMETER,
  PAGE_NAME_PARAMETER,
  QUERY_NAME_PARAMETER
} from "../constants/pagination";
import { getRangeDate } from "../utils/dateUtils";
import { isEmptyString } from "../utils/stringUtils";

import useNonInitialEffect from "./useNonInitialEffect";
import { useNumericURLSearchParam, useURLSearchParam } from "./useURLSearchParam";

/**
 * hook to handle basic server side url list params such as pagination, ordering, name filter and created filter
 */
const useListParams = () => {
  const [order, setOrder] = useURLSearchParam(ORDER_NAME_PARAMETER);
  const [page, setPage] = useNumericURLSearchParam(PAGE_NAME_PARAMETER);
  const [limit, setLimit] = useNumericURLSearchParam(LIMIT_NAME_PARAMETER, CUSTOMERS_PER_PAGE);
  const [q, setQ] = useURLSearchParam(QUERY_NAME_PARAMETER);
  const [createdRange, setCreatedRange] = useURLSearchParam<DateRange>(CREATED_RANGE_NAME_PARAMETER);
  const [createdAfter, setCreatedAfter] = useURLSearchParam(CREATED_AFTER_NAME_PARAMETER);
  const [createdBefore, setCreatedBefore] = useURLSearchParam(CREATED_BEFORE_NAME_PARAMETER);

  useNonInitialEffect(() => {
    setPage(0);
  }, [order, limit, q, createdRange, createdAfter, createdBefore]);

  const getCreatedAfterValue = () => getRangeDate(createdRange, createdAfter, true);

  const searchParams = {
    ordering: order,
    limit,
    offset: page && limit ? page * limit : undefined,
    q,
    createdAfter: getCreatedAfterValue(),
    createdBefore
  };

  const isEmptySearch =
    isEmptyString(q) && isEmptyString(createdRange) && isEmptyString(createdAfter) && isEmptyString(createdBefore);

  const resetAllFilters = () => {
    setQ(undefined);
    setOrder(undefined);
    setPage(undefined);
    setLimit(CUSTOMERS_PER_PAGE);
    setCreatedRange(undefined);
    setCreatedAfter(undefined);
    setCreatedBefore(undefined);
  };

  const handleSetCreatedRange = (range: DateRange) => {
    if (range !== DateRange.CUSTOM) {
      setCreatedAfter(undefined);
      setCreatedBefore(undefined);
    }
    setCreatedRange(range);
  };

  return {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    createdRange,
    setCreatedRange: handleSetCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams,
    isEmptySearch,
    resetAllFilters
  };
};

export default useListParams;
