import api from "../../api";
import {
  EXTERNAL_ID_COLUMN,
  NAME_COLUMN,
  ORG_ID_COLUMN,
  RESPONSIBLE_USER_EMAIL_COLUMN
} from "../../constants/import-customers";
import useFeatures from "../../hooks/useFeatures";

const useGetDefaultImportColumnsNames = (customerType: api.CustomerType) => {
  const features = useFeatures();

  // TODO add different columns for person customer type
  const defaultColumns = customerType === "company" ? [ORG_ID_COLUMN, NAME_COLUMN] : [];
  const validationColumns = customerType === "company" ? [ORG_ID_COLUMN] : [];

  if (features.EXTERNAL_ID) {
    defaultColumns.push(EXTERNAL_ID_COLUMN);
  }

  if (features.RESPONSIBLE_USER) {
    defaultColumns.push(RESPONSIBLE_USER_EMAIL_COLUMN);
    validationColumns.push(RESPONSIBLE_USER_EMAIL_COLUMN);
  }

  return {
    defaultColumns,
    validationColumns
  };
};

export default useGetDefaultImportColumnsNames;
