import api from "../api";

import useFormatMessage from "./useFormatMessage";

const useTranslateGroup = () => {
  const f = useFormatMessage();

  return (group: api.Group) => {
    switch (group.role_key) {
      case "MLRO":
        return f("common.group.role.key.mlro");
      case "COMPLIANCE_OFFICER":
        return f("common.group.role.key.compliance-officer");
      case "KDK_ADMIN":
        return f("common.group.role.key.kdk-admin");
      default:
        return group.name;
    }
  };
};

export default useTranslateGroup;
