import { PendingTask } from "../types/PendingTask";
import { Customer } from "../types/utilities";

export const hasPendingTasks = (customer: Customer) =>
  !customer.is_archived && (customer.pending_tasks || []).length > 0;

const isActionableTask = (pendingTask: PendingTask) => {
  switch (pendingTask.type) {
    case "APPROVE_CUSTOMER_REVIEW": {
      return pendingTask.is_possible_to_approve;
    }
    default: {
      return true;
    }
  }
};

export const hasActionablePendingTasks = (customer: Customer | Customer) =>
  !customer.is_archived && (customer.pending_tasks || []).filter(isActionableTask).length > 0;
