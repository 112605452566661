import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import ProjectIcon from "../../../assets/icons/project.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import AssignmentSelector from "../AssignmentSelector/AssignmentSelector";
import Icon from "../extensions/Icon";
import PurposeChoicesSelect from "../PurposeChoicesSelect/PurposeChoicesSelect";
import SignatureFormWrapper from "../SignatureFormWrapper/SignatureFormWrapper";

export type CompanyDisclosureAssignmentSelectorProps = {
  selectedAssignment?: api.Assignment;
  assignments?: api.Assignment[];
  readonly?: boolean;
  setSelectedAssignment(selectedAssignment?: api.Assignment): void;
};

const CompanyDisclosureAssignmentSelector = (props: CompanyDisclosureAssignmentSelectorProps) => {
  const { selectedAssignment, assignments, readonly, setSelectedAssignment } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="4">
      <Text as="span" fontWeight="semibold">
        {f("common.labels.include-assignment")}
      </Text>
      <SignatureFormWrapper>
        <AssignmentSelector
          assignments={assignments}
          selectedAssignment={selectedAssignment}
          onSelectionChange={setSelectedAssignment}
          readonly={readonly}
        />
        <VerticalStack gap="2">
          <HorizontalStack gap="1" blockAlign="center" wrap={false}>
            <Icon source={ProjectIcon} width="16px" height="12px" />
            <Text as="span" fontWeight="semibold">
              {f("customer-assignments.details.assignment-purpose")}
            </Text>
          </HorizontalStack>
          <PurposeChoicesSelect
            industryType={selectedAssignment?.industry_type}
            hasPurposeDesc={false}
            disablePurposeDescription
            disabled={readonly || selectedAssignment === undefined}
            labelHidden
            multiline={2}
            value={selectedAssignment?.purpose || selectedAssignment?.purpose_desc}
            onChange={(purpose) => setSelectedAssignment({ ...selectedAssignment!, purpose: purpose || "" })}
            key={selectedAssignment?.id || "selected-assignment"}
          />
        </VerticalStack>
      </SignatureFormWrapper>
    </VerticalStack>
  );
};

export default CompanyDisclosureAssignmentSelector;
