import React from "react";
import { generatePath } from "react-router-dom";
import { HorizontalStack, InlineError, Text, VerticalStack } from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import EyeIcon from "../../../assets/icons/eye.svg";
import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import BackButton from "../../components/BackButton/BackButton";
import Badge from "../../components/extensions/Badge";
import Button from "../../components/extensions/Button";
import QuestionnaireEditor from "../../components/QuestionnaireEditor/QuestionnaireEditor";
import QuestionnairePageHeader from "../../components/QuestionnairePageHeader/QuestionnairePageHeader";
import QuestionnairePageSkeleton from "../../components/QuestionnairePageSkeleton/QuestionnairePageSkeleton";
import QuestionnairePreviewModal from "../../components/QuestionnairePreviewModal/QuestionnairePreviewModal";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { NEW_QUESTIONNAIRE_ID } from "../../constants/questionnaires";
import { ROUTES } from "../../constants/routes";
import {
  convertQuestionnaireToCreateQuestionnaireRequest,
  convertQuestionnaireToUpdateQuestionnaireRequest
} from "../../helpers/questionnaire.helpers";
import useChangedState from "../../hooks/useChangedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import useSnackbar from "../../hooks/useSnackbar";
import { updateItem } from "../../utils/collectionUtils";
import history from "../../utils/history";
import { clone } from "../../utils/util";

import {
  createNewQuestionnaire,
  isInvalidQuestionnaire,
  isNewQuestionnaire
} from "./SettingsQuestionnaireDetailsPage.helpers";

const SettingsQuestionnaireDetailsPage = ({ data }: AsyncPageChild<api.Questionnaire>) => {
  const isNew = isNewQuestionnaire(data);

  const { isPermittedTo } = usePermissions();
  const f = useFormatMessage();
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [questionnaire, setQuestionnaire, changed] = useChangedState<api.Questionnaire>(() => clone(data));

  const { updateQueryData } = useQueryData<api.QuestionnaireListResponse>([QUERIES_KEYS.QUESTIONNAIRES]);

  const [showPreviewModal, togglePreviewModal, closePreviewModal] = useOpenClose();

  const saveQuestionnaireMutation = useMutation(
    () =>
      isNew
        ? api.createQuestionnaire(convertQuestionnaireToCreateQuestionnaireRequest(questionnaire))
        : api.updateQuestionnaire(questionnaire.id, convertQuestionnaireToUpdateQuestionnaireRequest(questionnaire)),
    {
      onSuccess: (newQuestionnaire) => {
        updateQueryData((queryData) => {
          if (isNew) {
            queryData.questionnaires = [newQuestionnaire, ...queryData.questionnaires];
            // after a new questionnaire is saved, route to the same page but with the saved questionnaire id
            history.push(generatePath(ROUTES.SETTINGS_QUESTIONNAIRE_DETAILS, { id: newQuestionnaire.id }));
          } else {
            queryData.questionnaires = updateItem(queryData.questionnaires, newQuestionnaire);
          }
        });
        setSnackbar(f("common.labels.saved-successfully"));
        Promise.all([
          queryClient.invalidateQueries([QUERIES_KEYS.QUESTIONNAIRES_SUMMARY]),
          queryClient.invalidateQueries([QUERIES_KEYS.QUESTIONNAIRES])
        ]);
      }
    }
  );

  const readonly = !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const handleChange = (updatedQuestionnaire: api.Questionnaire) => {
    setQuestionnaire(() => clone(updatedQuestionnaire));
    saveQuestionnaireMutation.reset();
  };

  const navigateToQuestionnairesListPage = () => history.push(ROUTES.SETTINGS_QUESTIONNAIRES_LIST);

  const invalid = isInvalidQuestionnaire(questionnaire);

  return (
    <SettingsPage>
      <VerticalStack gap="8">
        <BackButton
          showAsLink
          url={generatePath(ROUTES.SETTINGS_QUESTIONNAIRES_LIST)}
          displayLabel={f("questionnaire.page.title")}
        />
        <HorizontalStack gap="2" blockAlign="baseline">
          <Text variant="headingLg" as="h2">
            {f("questionnaire.details.page.title.new-questionnaire")}
          </Text>
          {questionnaire.is_archived && <Badge size="xsmall">{f("badge.archived")}</Badge>}
        </HorizontalStack>
        <QuestionnairePageHeader />
        <QuestionnaireEditor
          questionnaire={questionnaire}
          readonly={saveQuestionnaireMutation.isLoading || questionnaire.is_archived || readonly}
          onChange={handleChange}
          error={saveQuestionnaireMutation.error}
        />
        {invalid && changed && <InlineError message={f("questionnaire.details.page.invalid")} fieldID="" />}
        <HorizontalStack gap="4" align="end">
          <Button
            onClick={togglePreviewModal}
            icon={EyeIcon}
            disabled={invalid || saveQuestionnaireMutation.isLoading || questionnaire.is_archived}
          >
            {f("default.preview")}
          </Button>
          <Button onClick={navigateToQuestionnairesListPage} disabled={saveQuestionnaireMutation.isLoading}>
            {f("default.cancel")}
          </Button>
          <Button
            primary
            onClick={() => saveQuestionnaireMutation.mutate()}
            loading={saveQuestionnaireMutation.isLoading}
            disabled={invalid || !changed || readonly}
          >
            {f("default.save")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
      {showPreviewModal && <QuestionnairePreviewModal questionnaire={questionnaire} onClose={closePreviewModal} />}
    </SettingsPage>
  );
};

export default withAsyncPage<api.Questionnaire>(SettingsQuestionnaireDetailsPage, {
  queryKey: QUERIES_KEYS.QUESTIONNAIRES,
  apiFunction: (id, options) =>
    id === NEW_QUESTIONNAIRE_ID ? Promise.resolve(createNewQuestionnaire()) : api.getQuestionnaire(id, options),
  paramNames: ["id"],
  skeleton: <QuestionnairePageSkeleton />
});
