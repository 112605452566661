import React, { useState } from "react";
import { ActionList, ActionListItemDescriptor, Popover } from "@shopify/polaris";

import FileIcon from "../../../assets/icons/file.svg";
import api from "../../api";
import useAttachmentDownload from "../../hooks/useAttachmentDownload";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Entity } from "../../types/utilities";
import { cleanupFilename } from "../../utils/filenameUtils";
import Button from "../extensions/Button";

import { hasSignedAttachment } from "./AttachmentsSection.helpers";

interface AttachmentDownloadButtonProps {
  entity: Entity;
  attachment: api.AttachmentDetails;
}

const AttachmentDownloadButton = (props: AttachmentDownloadButtonProps) => {
  const { entity, attachment } = props;

  const [showMenu, setShowMenu] = useState(false);

  const toggleShowMenu = () => setShowMenu(!showMenu);

  const f = useFormatMessage();
  const downloadAttachmentMutation = useAttachmentDownload(entity, attachment);

  const isLoading = downloadAttachmentMutation.isLoading;
  const filename = cleanupFilename(attachment.filename);

  const showDownloadMenu = hasSignedAttachment(attachment);

  const downloadOriginalAttachmentActionItem: ActionListItemDescriptor = {
    content: f("attachments.actions.download-attachment"),
    onAction: () => downloadAttachmentMutation.mutate({ downloadSignedAttachment: false }),
    disabled: isLoading
  };

  const downloadSignedAttachmentActionItem: ActionListItemDescriptor = {
    content: f("attachments.actions.download-signed-attachment"),
    onAction: () => downloadAttachmentMutation.mutate({ downloadSignedAttachment: true }),
    disabled: isLoading
  };

  const handleDownloadButtonClick = () => {
    showDownloadMenu ? toggleShowMenu() : downloadOriginalAttachmentActionItem.onAction!();
  };

  const downloadUnsignedAttachButton = (
    <Button
      plain
      icon={FileIcon}
      truncate
      disclosure={showDownloadMenu}
      loading={isLoading}
      textAlign="left"
      onClick={handleDownloadButtonClick}
    >
      {filename}
    </Button>
  );

  if (showDownloadMenu) {
    return (
      <Popover active={showMenu} activator={downloadUnsignedAttachButton} onClose={toggleShowMenu}>
        <ActionList items={[downloadOriginalAttachmentActionItem, downloadSignedAttachmentActionItem]} />
      </Popover>
    );
  } else {
    return downloadUnsignedAttachButton;
  }
};

export default AttachmentDownloadButton;
