import React from "react";

import api from "../../api";
import ConfirmAction from "../../components/ConfirmAction/ConfirmAction";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQuestionnaireArchiveMutation from "../../hooks/useQuestionnaireArchiveMutation";

type QuestionnaireArchiveConfirmProps = {
  questionnaire: api.Questionnaire;
  onClose(): void;
};

const QuestionnaireArchiveConfirm = (props: QuestionnaireArchiveConfirmProps) => {
  const { questionnaire, onClose } = props;

  const f = useFormatMessage();

  const archiveQuestionnaireMutation = useQuestionnaireArchiveMutation();

  return (
    <ConfirmAction
      apiCall={() => archiveQuestionnaireMutation.mutateAsync(questionnaire)}
      title={f("questionnaire.archive.confirmation.title")}
      description={f("questionnaire.archive.confirmation.description", { name: questionnaire.name })}
      onNo={onClose}
      actionTitle={f("default.archive")}
    />
  );
};

export default QuestionnaireArchiveConfirm;
