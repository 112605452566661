import React from "react";
import { Card, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import useGetAttachmentsDisplayTitle from "../../hooks/useGetAttachmentsDisplayTitle";
import PlanConversionBanner from "../PlanConversionBanner/PlanConversionBanner";

const AttachmentsPlanConversionBanner = () => {
  const f = useFormatMessage();

  const attachmentsDisplayTitle = useGetAttachmentsDisplayTitle();

  return (
    <VerticalStack gap="4">
      <HorizontalStack>
        <Text variant="headingMd" as="h2">
          {attachmentsDisplayTitle}
        </Text>
      </HorizontalStack>
      <Card>
        <Card.Section>
          <PlanConversionBanner content={f("plan-conversion.attachments.description")} />
        </Card.Section>
      </Card>
    </VerticalStack>
  );
};

export default AttachmentsPlanConversionBanner;
