import { HorizontalStack, Text } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";

type CustomerReviewApprovalReasonProps = {
  customerId: string;
  reviewId: string;
};

const CustomerReviewApprovalReason = (props: CustomerReviewApprovalReasonProps) => {
  const { customerId, reviewId } = props;

  const f = useFormatMessage();

  const { data: notes } = useQuery([QUERIES_KEYS.CUSTOMER_REVIEW_NOTES, customerId, reviewId], () =>
    api.listCustomerReviewNotations(customerId, reviewId)
  );

  const comment = notes?.map((note) => note.comment).join("\n");

  if (isEmptyString(comment)) return null;

  return (
    <HorizontalStack gap="1" blockAlign={"center"}>
      <Text as={"span"} fontWeight={"semibold"}>
        {f("reviews.labels.review-label")}:
      </Text>
      <Text as={"span"}>{comment}</Text>
    </HorizontalStack>
  );
};

export default CustomerReviewApprovalReason;
