import api from "../api";

import useAuth from "./useAuth";

const convertAuthUserToUser = ({ id, email, first_name, last_name, groups }: api.AuthUser): api.User => ({
  id,
  email,
  first_name,
  last_name,
  is_active: true,
  groups: groups?.map((group) => group.id) || []
});

function useConvertToDeletedEntity<T>() {
  const { user: currentUser } = useAuth();

  const updated_by = convertAuthUserToUser(currentUser!);

  const convertToDeletedEntity = (entity: T) => {
    const updated_dt = new Date().toISOString();
    return { ...entity, updated_by, updated_dt };
  };

  return convertToDeletedEntity;
}

export default useConvertToDeletedEntity;
