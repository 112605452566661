import React from "react";
import { Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import { RiskIndicatorsType } from "../../types/RiskIndicators";
import CondensedText from "../CondensedText/CondensedText";
import IndustryRisk from "../IndustryRisk/IndustryRisk";

import GeographicRiskIndicator from "./GeographicRiskIndicator/GeographicRiskIndicator";
import DisclosureRiskIndicator from "./DisclosureRiskIndicator";
import MatchCaseRiskIndicator from "./MatchCaseRiskIndicator";
import PersonRiskIndicator from "./PersonRiskIndicator";
import useRiskIndicators from "./useRiskIndicators";

type RiskIndicatorsProps = {
  customerId: string;
  riskIndicators: RiskIndicatorsType;
};

const RiskIndicators = (props: RiskIndicatorsProps) => {
  const { customerId, riskIndicators } = props;

  const {
    showConfirmedMatches,
    confirmedMatchCases,
    showIndustryRisk,
    industryRiskCount,
    showGeographicRisk,
    countriesRisk,
    showDisclosuresRisk,
    disclosuresRisk,
    showPersonnelRisk,
    personnelRisk
  } = useRiskIndicators(riskIndicators);

  const f = useFormatMessage();

  return (
    <VerticalStack gap="8">
      {showConfirmedMatches && (
        <VerticalStack gap="2">
          <CondensedText>
            {f("risk-indicators.labels.confirmed-matches", { count: confirmedMatchCases.length })}
          </CondensedText>
          {confirmedMatchCases.map((matchCase) => (
            <MatchCaseRiskIndicator key={matchCase.id} customerId={customerId} matchCase={matchCase} />
          ))}
        </VerticalStack>
      )}
      {showIndustryRisk && (
        <VerticalStack gap="2">
          <CondensedText>{f("risk-indicators.labels.industry-risk", { count: industryRiskCount })}</CondensedText>
          <IndustryRisk industryRisk={riskIndicators.industryRisk} />
        </VerticalStack>
      )}
      {showDisclosuresRisk && (
        <VerticalStack gap="2">
          <CondensedText>
            {f("risk-indicators.labels.disclosure-risk", { count: disclosuresRisk.length })}
          </CondensedText>
          <VerticalStack gap="2">
            {disclosuresRisk.map((disclosuresRiskInformation, index) => (
              <DisclosureRiskIndicator key={index} disclosureRiskInformation={disclosuresRiskInformation} />
            ))}
          </VerticalStack>
        </VerticalStack>
      )}
      {showGeographicRisk && (
        <VerticalStack gap="2">
          <CondensedText>{f("risk-indicators.labels.geographic-risk", { count: countriesRisk.length })}</CondensedText>
          <VerticalStack gap="4">
            <Text as="span" fontWeight="semibold">
              {f("common.labels.editorial-explanation")}:
            </Text>
            <Text as="span" color="subdued">
              {f("risk-indicators.geographic-risk.description")}
            </Text>
          </VerticalStack>
          <VerticalStack gap="2">
            {countriesRisk.map((countryRiskInformation, index) => (
              <GeographicRiskIndicator key={index} countryRiskInformation={countryRiskInformation} />
            ))}
          </VerticalStack>
        </VerticalStack>
      )}

      {showPersonnelRisk && (
        <VerticalStack gap="2">
          <CondensedText>{f("risk-indicators.labels.personnel-risk", { count: personnelRisk.length })}</CondensedText>
          <VerticalStack gap="4">
            {personnelRisk.map((personRiskInformation, index) => (
              <PersonRiskIndicator key={index} personRiskInformation={personRiskInformation} />
            ))}
          </VerticalStack>
        </VerticalStack>
      )}
    </VerticalStack>
  );
};

export default RiskIndicators;
