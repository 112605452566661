import React from "react";

import useFormatMessage from "../../hooks/useFormatMessage";
import Confirm, { ConfirmProps } from "../Confirm/Confirm";

type ReviewChecklistRemoveConfirm = Omit<ConfirmProps, "actionTitle" | "title" | "description"> & {
  checklistName: string;
};

const ReviewChecklistRemoveConfirm = (props: ReviewChecklistRemoveConfirm) => {
  const { checklistName, ...confirmProps } = props;
  const f = useFormatMessage();

  return (
    <Confirm
      title={f("component.review.checklist.form.remove.confirmation.title")}
      description={f("component.review.checklist.form.remove.confirmation.description", {
        name: checklistName
      })}
      actionTitle={f("default.remove")}
      {...confirmProps}
    />
  );
};

export default ReviewChecklistRemoveConfirm;
