import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AppProvider, Frame, Loading, VerticalStack } from "@shopify/polaris";
import englishTranslations from "@shopify/polaris/locales/en.json";
import norwegianTranslations from "@shopify/polaris/locales/nb.json";
import styled from "styled-components";

import { shouldShowToolbar } from "../../helpers/navigation.helpers";
import useAppTitle from "../../hooks/useAppTitle";
import useAuth from "../../hooks/useAuth";
import useLanguage from "../../hooks/useLanguage";
import usePageNavigation from "../../hooks/usePageNavigation";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import GlobalStyles from "../GlobalStyles/GlobalStyles";
import PendoAnalytics from "../PendoAnalytics/PendoAnalytics";
import Routes from "../Routes/Routes";
import SectionMargin from "../SectionMargin/SectionMargin";

import NavigationToolbar from "./NavigationToolbar/NavigationToolbar";
import PageNavigationMenu from "./PageNavigationMenu/PageNavigationMenu";
import AdapterLink from "./AdapterLink";
import LiveChat from "./LiveChat";
import Snackbar from "./Snackbar";

import "@shopify/polaris/build/esm/styles.css";

const isDevelopment = process.env.NODE_ENV === "development";

const App = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const { showNavigationMenu } = usePageNavigation();
  const language = useLanguage();
  const showToolbar = shouldShowToolbar();

  useAppTitle();

  const translations = language === "en" ? englishTranslations : norwegianTranslations;

  return (
    <AppProvider linkComponent={AdapterLink} i18n={translations}>
      <ErrorBoundary>
        {!isDevelopment && <LiveChat />}
        <PendoAnalytics />
        <Frame
          topBar={isAuthenticated && !showToolbar ? <NavigationToolbar /> : null}
          navigation={showNavigationMenu ? <PageNavigationMenu /> : null}
        >
          <ErrorBoundary>
            <VerticalStack gap="8">
              <StyledPageWrapper>{isLoading ? <Loading /> : <Routes />}</StyledPageWrapper>
            </VerticalStack>
            <SectionMargin />
            <Snackbar />
          </ErrorBoundary>
        </Frame>
        <GlobalStyles />
      </ErrorBoundary>
    </AppProvider>
  );
};

const StyledPageWrapper = styled.div`
  // allow page wrapper to extend to the entire width of the viewport
  max-width: 100%;

  margin-top: var(--p-space-8);
`;

/**
 * Wrap with Auth0's withAuthenticationRequired.
 * Anonymous users will be redirected to the Auth0-login page
 * and returned to the page they were redirected from after login
 */
export default withAuthenticationRequired(App, {
  returnTo: `${window.location.pathname}${window.location.search}${window.location.hash}`
});
