import React, { useMemo, useState } from "react";
import { Badge, HorizontalStack, Stack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import GearIcon from "../../../assets/icons/gear-white.svg";
import api from "../../api";
import { MATCH_CASES_BULK_ACTIONS_CUTOFF } from "../../constants/matches";
import { isRejectedMatchCase, isUnresolvedMatchCase } from "../../helpers/match.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import Button from "../extensions/Button";
import MatchCasesBulkActions from "../MatchCasesBulkActions/MatchCasesBulkActions";
import MatchCasesTable from "../MatchCasesTable/MatchCasesTable";
import PossibleMatchBanner from "../PossibleMatchBanner/PossibleMatchBanner";
import ScreeningStartedBanner from "../ScreeningStartedBanner/ScreeningStartedBanner";

type MatchCasesSectionProps = {
  customerId: string;
  customerType: api.CustomerType;
  hasFinishedOnboarding: boolean;
  matchCases: Array<api.MatchCaseSummary>;
  isScreeningStarting: boolean;
  isScreeningActive: boolean;
  readonly?: boolean;
};

const MatchCasesSection = (props: MatchCasesSectionProps) => {
  const {
    customerId,
    customerType,
    hasFinishedOnboarding,
    matchCases,
    isScreeningStarting,
    isScreeningActive,
    readonly
  } = props;

  const [showRejectedMatches, toggleShowRejectedMatches] = useOpenClose();

  const f = useFormatMessage();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const clearSelection = () => setSelectedIds([]);

  const hasUnresolvedMatchedCases =
    matchCases.length > 0 && matchCases.some((matchCase) => matchCase.status !== "RESOLVED");

  const rejectedMatchCount = useMemo(() => matchCases.filter(isRejectedMatchCase).length, [matchCases]);

  const unresolvedMatchCasesCount = useMemo(() => matchCases.filter(isUnresolvedMatchCase).length, [matchCases]);

  const showBulkActions = unresolvedMatchCasesCount > MATCH_CASES_BULK_ACTIONS_CUTOFF;

  let rowsToDisplay = matchCases;
  if (!showRejectedMatches) {
    rowsToDisplay = matchCases.filter((m) => !m.review || (m.review && m.review.is_match));
  }

  const showRejectedMatchCasesButton =
    matchCases.find((matchCase) => matchCase.review?.is_match === false) !== undefined;

  const toggleRejectedMatchCasesButtonLabel = !showRejectedMatches
    ? f(`matches.button.show.rejected.matches`, { count: rejectedMatchCount })
    : f(`matches.button.hide.rejected.matches`, { count: rejectedMatchCount });

  return (
    <VerticalStack gap="4">
      <Stack alignment={"center"}>
        <Text variant="headingMd" as="h2">
          {f("screening.titles.screening")}
        </Text>
        {isScreeningActive && (
          <Badge status="success" progress="complete">
            {f("screening.status.active")}
          </Badge>
        )}
        <Stack.Item fill />
        {hasFinishedOnboarding && (
          <Button icon={GearIcon} primary url={`${customerId}/change-screening`} disabled={readonly}>
            {f("common.buttons.actions.change-screening")}
          </Button>
        )}
      </Stack>
      {isScreeningStarting && <ScreeningStartedBanner />}
      {hasUnresolvedMatchedCases && <PossibleMatchBanner />}
      {rowsToDisplay.length > 0 && (
        <>
          {showBulkActions && (
            <StyledBulkActionsWrapper>
              <MatchCasesBulkActions
                customerId={customerId}
                customerType={customerType}
                totalMatchCasesCount={matchCases.length - rejectedMatchCount}
                selectedMatchCasesIds={selectedIds}
                readonly={readonly}
                onAfterSuccess={clearSelection}
              />
            </StyledBulkActionsWrapper>
          )}
          <MatchCasesTable
            matchCases={rowsToDisplay}
            customerId={customerId}
            unresolvedMatchCasesCount={unresolvedMatchCasesCount}
            selectedIds={selectedIds}
            onSelectionChange={setSelectedIds}
          />
        </>
      )}
      {rowsToDisplay.length === 0 && (
        <Text as="span">
          {rejectedMatchCount > 0
            ? f("matches.text.no.confirmed.matches")
            : isScreeningStarting
              ? ""
              : f("matches.text.no.matches")}
        </Text>
      )}
      {showRejectedMatchCasesButton && (
        <HorizontalStack>
          <Button
            onClick={toggleShowRejectedMatches}
            textAlign="left"
            disclosure={!showRejectedMatches ? "down" : "up"}
          >
            {toggleRejectedMatchCasesButtonLabel}
          </Button>
        </HorizontalStack>
      )}
    </VerticalStack>
  );
};

const StyledBulkActionsWrapper = styled.div`
  margin: var(--p-space-4);
`;

export default MatchCasesSection;
