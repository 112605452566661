export enum QUERIES_KEYS {
  ALL_ASSIGNMENT_INDUSTRIES_AND_PURPOSES = "all-assignment-industries-purposes",
  ALL_INDUSTRY_TYPES = "all-industry-types",
  ALL_TAGS = "all-tags",
  ASSIGNMENT_DETAILS = "assignment-details",
  ATTACHMENTS = "attachments",
  ATTACHMENT_SIGNATURES = "attachment-signatures",
  COMPANY_DETAILS = "company-details",
  COMPANY_ONBOARDING_DISCLOSURES = "company-onboarding-disclosures",
  COMPANY_ONBOARDING_SUMMARY = "company-onboarding-summary",
  COMPANY_OWNERSHIP_TREE = "company-ownership-tree",
  COMPANY_REQUEST_DISCLOSURES = "company-request-disclosures",
  COMPANY_SCREENING = "company-screening",
  COUNTRY_RISK_AND_TAX_HAVENS = "country-risk-tax-havens",
  CUSTOMERS_LIST = "customers",
  CUSTOMER_MATCH_CASES = "customer-match-cases",
  CUSTOMER_NOTES = "customer-notes",
  CUSTOMER_NOTIFICATIONS_SETTINGS = "customer-notifications-settings",
  CUSTOMER_REVIEW = "customer-review",
  CUSTOMER_REVIEW_NOTES = "customer-review-notes",
  CUSTOMER_REVIEW_GUIDELINE = "customer-review-guideline",
  CUSTOMER_REVIEW_GUIDELINES_SETTINGS = "customer-review-guidelines-settings",
  DASHBOARD = "dashboard",
  DISCLOSURE_SETTINGS = "disclosure-settings",
  FOLLOW_UP_SETTINGS = "followup-settings",
  HUBSPOT_LIVE_CHAT = "hubspot-live-chat",
  KYC_PREVIEW = "KYC-PREVIEW",
  MATCH_DETAILS = "match-details",
  MEASURES_SETTINGS = "measures-settings",
  ONBOARDING_SETTINGS = "onboarding-settings",
  PERSON_CHANGE_SCREENING = "person-change-screening",
  PERSON_DETAILS = "person-details",
  PERSON_ONBOARDING_DETAILS = "person-onboarding-details",
  POSTAL_CODES_SUGGESTIONS = "postal-codes-suggestions",
  PROJECTS = "projects",
  PROJECT_DETAILS = "project-details",
  QUESTIONNAIRES = "questionnaires",
  QUESTIONNAIRES_SUMMARY = "questionnaires-summary",
  REVIEW_CHECKLISTS = "review-checklists",
  STATUS_MESSAGE = "status-message",
  SUGGESTED_SIGNERS = "suggested-signers",
  USERS = "users"
}

export const STALE_TIME = 3_600_000; // 1 hour;

export const RETRY_TIME = 5_000; // 5 seconds

export const MAX_RETRIES = 10;
