import { useMemo } from "react";
import stringSimilarity from "string-similarity-js";

import api from "../api";
import { isPartialAddress } from "../helpers/customer.helpers";
import { getFullName } from "../helpers/display.helpers";
import {
  getSortMatchEventByRelevance,
  getSortMatchSourceByRelevance,
  groupMatchAttributesByCode,
  isEmptyMatchSource,
  isNotComplyAdvantageSource,
  isPartialMatchAddress,
  sortAddressesByRelevance,
  sortBirthDatesByRelevance
} from "../helpers/match.helpers";
import { difference, groupBy } from "../utils/collectionUtils";

import useMatchPage from "./useMatchPage";

const useRDCMatch = (data: api.MatchCaseDetailResponse) => {
  const match = data.case.match;
  const subject = match.subject;
  const { isSubjectPerson, hasNewInfo } = useMatchPage(data);

  // RDC attributes
  const { riskography, links, remarks, nationalities, citizenships, countries_of_incorporations } = useMemo(
    () => groupMatchAttributesByCode(match),
    [match]
  );

  const subjectPersonBirthDate = isSubjectPerson(subject) ? subject.birth_date || subject.birth_year?.toFixed(0) : "";

  // sort birth dates by relevance
  const birthdates = match.birth_dates
    .filter(isNotComplyAdvantageSource)
    .sort(sortBirthDatesByRelevance(subjectPersonBirthDate));

  const subjectAddresses: api.Address[] = (
    isSubjectPerson(subject)
      ? [subject.address]
      : [subject.business_address, subject.office_address, subject.mailing_address]
  )
    .filter(Boolean)
    .filter(isPartialAddress);

  // sort addresses by relevance
  const addresses = match.addresses.filter(isPartialMatchAddress);
  addresses.sort(sortAddressesByRelevance(subjectAddresses));

  const { pepEvents, sanctionEvents, adverseMediaEvents, otherEvents } = useMemo(() => {
    // if event category is "WLT" (watch list) then use the sub_category code for grouping
    const groupedEvents = groupBy(match.events, (event) =>
      event.category.code === "WLT" ? event.sub_category.code : event.category.code
    );

    const pepEvents = groupedEvents.get("PEP") || [];
    pepEvents.sort(getSortMatchEventByRelevance(hasNewInfo));

    const sanctionEvents = groupedEvents.get("SAN") || [];
    sanctionEvents.sort(getSortMatchEventByRelevance(hasNewInfo));

    const adverseMediaEvents = groupedEvents.get("ADV") || [];
    adverseMediaEvents.sort(getSortMatchEventByRelevance(hasNewInfo));

    const otherEvents = difference([...groupedEvents.keys()], ["PEP", "SAN", "ADV"]).flatMap(
      (key) => groupedEvents.get(key) || []
    );
    otherEvents.sort(getSortMatchEventByRelevance(hasNewInfo));

    return { pepEvents, sanctionEvents, adverseMediaEvents, otherEvents };
  }, [match.id]);

  const adverseMediaCategories = [...new Set(adverseMediaEvents.map((event) => event.sub_category.description))];

  const sources = match.sources.filter(isEmptyMatchSource);
  sources.sort(getSortMatchSourceByRelevance(hasNewInfo));

  const positions = match.positions.sort();

  // sort aliases by proximity to subject name
  const aliases = useMemo(() => {
    const matchName = (isSubjectPerson(subject) ? getFullName(subject) : subject.name) || "";

    return match.aliases.sort(
      (aliasA, aliasB) => stringSimilarity(aliasB.name, matchName) - stringSimilarity(aliasA.name, matchName)
    );
  }, [match]);

  return {
    match,
    aliases,
    isSubjectPerson,
    hasNewInfo,
    subjectPersonBirthDate,
    birthdates,
    subjectAddresses,
    addresses,
    riskography,
    links,
    remarks,
    nationalities,
    citizenships,
    countries_of_incorporations,
    adverseMediaCategories,
    pepEvents,
    positions,
    sanctionEvents,
    adverseMediaEvents,
    otherEvents,
    sources
  };
};

export default useRDCMatch;
