import React from "react";
import { Stack, Text } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { MOBILE_BREAKPOINT } from "../../constants/styles";
import useGetUserName from "../../hooks/useGetUserName";
import UserInitials from "../UserInitials/UserInitials";

interface UserInitialsProps {
  users: (api.User | undefined)[];
  responsive?: boolean; // will show the full username in small view ports
}

const MultipleUsersInitials = (props: UserInitialsProps) => {
  const { users, responsive } = props;

  const { getUserDisplayName } = useGetUserName();

  const usersToDisplay = users.filter(Boolean) as api.User[];

  if (usersToDisplay.length === 0) {
    return null;
  }

  return (
    <StyledInitialsContainerWrapper border={usersToDisplay.length > 1} responsive={responsive}>
      <Stack spacing="extraTight" wrap={false} alignment={"fill"}>
        {usersToDisplay.map((user) => (
          <StyledElement key={user.id}>
            <UserInitials user={user} tooltip={getUserDisplayName(user)} />
            {responsive && (
              <Text as="span" fontWeight={responsive ? undefined : "semibold"} truncate>
                {getUserDisplayName(user)}
              </Text>
            )}
          </StyledElement>
        ))}
      </Stack>
    </StyledInitialsContainerWrapper>
  );
};

const StyledInitialsContainerWrapper = styled.div<{ border?: boolean; responsive?: boolean }>`
  // set spacing to be negative to create overlapping effect when multiple initials are rendered
  & > .Polaris-Stack {
    --pc-stack-spacing: calc(var(--p-space-1) * -2);

    // For small viewports - and only if set as responsive -
    // render responsible users as a list without overlapping UserInitials
    ${({ responsive }) =>
      responsive &&
      `
      @media (max-width: ${MOBILE_BREAKPOINT}) {
        --pc-stack-spacing: var(--p-space-1);
        flex-direction: column;
      }
    `};
  }

  & .Polaris-Avatar {
    border-color: ${({ border }) => (border ? "var(--p-surface)" : undefined)};
  }
`;

const StyledElement = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: var(--p-space-1);

  & > .Polaris-Text--root {
    display: none;

    // For small viewports - show responsible user name
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      display: revert;
    }
  }
`;

export default MultipleUsersInitials;
