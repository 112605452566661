import { useCallback, useMemo } from "react";

import useFormatMessage from "./useFormatMessage";
import useLanguage from "./useLanguage";

const useMatchRelationLabel = () => {
  const f = useFormatMessage();
  const language = useLanguage();

  // a translations map for match relation type (which is an unknown string)
  const translationsMap = useMemo(
    () =>
      new Map<string, string>([
        ["PARENT", f("match.relations.PARENT")],
        ["FATHER", f("match.relations.FATHER")],
        ["MOTHER", f("match.relations.MOTHER")],
        ["CHILD", f("match.relations.CHILD")],
        ["DAUGHTER", f("match.relations.DAUGHTER")],
        ["SPOUSE", f("match.relations.SPOUSE")],
        ["WIFE", f("match.relations.WIFE")],
        ["SIBLING", f("match.relations.SIBLING")],
        ["ACTING FOR OR ON BEHALF OF", f("match.relations.ACTING")],
        ["RELATIVE", f("match.relations.RELATIVE")]
      ]),
    [language]
  );

  const getRelationshipLabel = useCallback(
    (relationType: string) => translationsMap.get(relationType.toLocaleUpperCase()) || relationType.toLocaleUpperCase(),
    [language]
  );

  return getRelationshipLabel;
};

export default useMatchRelationLabel;
