import { createChecklistTemplate } from "../helpers/checklists-templates.helpers";

const HIGH_RISK_PERSON_TEMPLATE = createChecklistTemplate(
  "Momenter som kan indikere høy risiko (person)",
  "Momenter knyttet til type kunde, som kan indikere at risikoen for hvitvasking og terrorfinansiering er høy.\n" +
    "Kilder: Hvitvaskingsforskriften § 4-9 og veileder til hvitvaskingsloven.",
  "person",
  [
    {
      en: "Are there unusual circumstances relating to the customer relationship?",
      nb: "Er det uvanlige omstendigheter knyttet til kundeforholdet?"
    },
    {
      en: "Is the information received from the customer incomplete or unclear?",
      nb: "Er opplysningene mottatt fra kunden mangelfulle eller uklare?"
    },
    {
      en: "Is there reason to doubt whether the information received from the customer is correct?",
      nb: "Er det grunn til å tvile på om opplysningene mottatt fra kunden er korrekte?"
    },
    {
      en: "Are there other, objective circumstances with the customer that may indicate an increased risk (for example, relevant negative media coverage, known connection to criminal circles or criminal circumstances)?",
      nb: "Er det andre, objektive forhold ved kunden som kan indikere forhøyet risiko (eksempelvis relevant negativ medieomtale, kjent tilknytning til kriminelle miljøer eller straffbare forhold)?"
    }
  ]
);

const HIGH_RISK_COMPANY_TEMPLATE = createChecklistTemplate(
  "Momenter som kan indikere høy risiko (selskap)",
  "Momenter knyttet til typen kunde, som kan indikere at risikoen for hvitvasking og terrorfinansiering er høy. Kilder: Hvitvaskingsforskriften § 4-9 og veileder til hvitvaskingsloven.",
  "company",
  [
    {
      en: 'Is the customer a "personal wealth management company"?',
      nb: 'Er kunden et "personlig formuesforvaltningsselskap"?'
    },
    {
      en: "Does the customer have trustee-registered shareholders or bearer shares?",
      nb: "Har kunden forvalterregistrerte aksjonærer eller ihendehaveraksjer?"
    },
    {
      en: "Does the customer have a cash-based business?",
      nb: "Har kunden kontantbasert virksomhet?"
    },
    {
      en: "Is the customer's ownership structure unusual or unnecessarily complex based on the nature of the business (without a natural business explanation)?",
      nb: "Er kundens eierstruktur uvanlig eller unødvendig kompleks ut fra virksomhetens art (uten at det har en naturlig forretningsmessig forklaring)?"
    },
    {
      en: "Are there unusual circumstances relating to the customer relationship?",
      nb: "Er det uvanlige omstendigheter knyttet til kundeforholdet?"
    },
    {
      en: "Is the information received from the customer incomplete or unclear?",
      nb: "Er opplysningene mottatt fra kunden mangelfulle eller uklare?"
    },
    {
      en: "Is there reason to doubt whether the information received from the customer is correct?",
      nb: "Er det grunn til å tvile på om opplysningene mottatt fra kunden er korrekte?"
    },
    {
      en: "Are there other, objective circumstances with the customer that may indicate an increased risk (for example, relevant negative media coverage, known connection to criminal circles or criminal circumstances)?",
      nb: "Er det andre, objektive forhold ved kunden som kan indikere forhøyet risiko (eksempelvis relevant negativ medieomtale, kjent tilknytning til kriminelle miljøer eller straffbare forhold)?"
    }
  ]
);

export const REVIEW_CHECKLIST_TEMPLATES = [HIGH_RISK_PERSON_TEMPLATE, HIGH_RISK_COMPANY_TEMPLATE];
