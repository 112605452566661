import React, { useState } from "react";
import { ActionListItemDescriptor } from "@shopify/polaris";

import CopyLinkIcon from "../../../../../../assets/icons/link.svg";
import EditIcon from "../../../../../../assets/icons/pencil.svg";
import RemindIcon from "../../../../../../assets/icons/remind.svg";
import TrashIcon from "../../../../../../assets/icons/trash.svg";
import useFormatMessage from "../../../../../hooks/useFormatMessage";
import useOpenClose from "../../../../../hooks/useOpenClose";
import ActionsDropdown from "../../../../ActionsDropdown/ActionsDropdown";
import Confirm from "../../../../Confirm/Confirm";
import CopyLinkModal from "../../../../CopyLinkModal/CopyLinkModal";
import RemindSignerModal from "../../../../RemindSignerModal/RemindSignerModal";
import { AttachmentSignature } from "../../../AttachmentsSection.types";

interface AttachmentSignatureActionsProps {
  customerId: string;
  attachmentSignature: AttachmentSignature;
  showCopyLinkModal: boolean;
  readonly?: boolean;
  setShowCopyLinkModalRowId(rowId?: string): void;
  onModalClose(): void;
  onEdit(): void;
  onDelete(): void;
}

const AttachmentSignatureActions = (props: AttachmentSignatureActionsProps) => {
  const {
    customerId,
    attachmentSignature,
    showCopyLinkModal,
    readonly,
    setShowCopyLinkModalRowId,
    onModalClose,
    onEdit,
    onDelete
  } = props;

  const [showReminder, toggleReminderModal, closeReminderModal] = useOpenClose();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const f = useFormatMessage();

  const items: ActionListItemDescriptor[] = [
    {
      content: f("signature.actions.copy.link"),
      image: CopyLinkIcon,
      disabled: attachmentSignature.signing_page_url === undefined,
      onAction: () => setShowCopyLinkModalRowId(attachmentSignature.id!)
    },
    {
      content: f("signature.actions.remind.signer"),
      image: RemindIcon,
      disabled: !attachmentSignature.send_email || !attachmentSignature.id,
      onAction: () => toggleReminderModal()
    },
    {
      content: f("default.edit"),
      image: EditIcon,
      onAction: onEdit,
      disabled: readonly
    },
    {
      content: f("modals.delete.signature.button"),
      image: TrashIcon,
      onAction: () => setShowDeleteConfirmation(true),
      disabled: readonly
    }
  ];

  const handleDelete = () => {
    onDelete();
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <ActionsDropdown items={items} hideTitle />
      <CopyLinkModal
        title={f("attachments.signatures.modal.copy-link")}
        url={attachmentSignature.signing_page_url}
        open={showCopyLinkModal}
        onModalClose={onModalClose}
      />
      {showReminder && (
        <RemindSignerModal
          open
          customerId={customerId}
          signatureId={attachmentSignature.id!}
          email={attachmentSignature.email!}
          name={attachmentSignature.fullName}
          onModalClose={closeReminderModal}
        />
      )}
      {showDeleteConfirmation && (
        <Confirm
          title={f("modals.delete.signature.button")}
          description={f("modals.delete.signature.description", { name: attachmentSignature.fullName })}
          onYes={() => handleDelete()}
          onNo={() => setShowDeleteConfirmation(false)}
          actionTitle={f("modals.delete.signature.button")}
        />
      )}
    </>
  );
};

export default AttachmentSignatureActions;
