import React, { PropsWithChildren, useState } from "react";
import { Button, Card, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useAttachmentsAPI from "../../hooks/useAttachmentsAPI";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetAttachmentsDisplayTitle from "../../hooks/useGetAttachmentsDisplayTitle";
import useOpenClose from "../../hooks/useOpenClose";
import { CustomerDetails, Entity, isPersonCustomer } from "../../types/utilities";

import AttachmentSignaturesModal from "./AttachmentActions/AttachmentSignaturesModal/AttachmentSignaturesModal";
import AttachmentsPlanConversionBanner from "./AttachmentsPlanConversionBanner";
import AttachmentsTable from "./AttachmentsTable";

type FilterLabels = {
  show: string;
  hide: string;
};

type AttachmentsSectionProps = {
  entity: Entity;
  fetchSuggestedSigners?: () => Promise<CustomerDetails[]>;
  readonly?: boolean;
  hideTitle?: boolean;
  uploadingFilesCount: number;
  hideSignatures?: boolean;
  hideEmptyState?: boolean;
  filterAttachment?(attachment: api.AttachmentDetails): boolean;
  getFilterLabels?(filteredAttachmentCount: number): FilterLabels;
};

const AttachmentsSection = (props: PropsWithChildren<AttachmentsSectionProps>) => {
  const {
    entity,
    fetchSuggestedSigners,
    readonly,
    hideTitle,
    uploadingFilesCount,
    hideSignatures,
    hideEmptyState,
    filterAttachment,
    getFilterLabels,
    children
  } = props;

  const [selectedAttachment, setSelectedAttachment] = useState<api.AttachmentDetails>();

  const f = useFormatMessage();
  const features = useFeatures();
  const { entityId, getAttachments } = useAttachmentsAPI(entity);
  const attachmentsDisplayTitle = useGetAttachmentsDisplayTitle();

  const attachmentsQueryKey = [QUERIES_KEYS.ATTACHMENTS, entityId];
  const { isLoading, isError, error, data } = useQuery(attachmentsQueryKey, () => getAttachments(entityId));

  const [isShowingFilteredAttachments, toggleShowFilteredAttachments] = useOpenClose();

  const allAttachments = data?.attachment_list || [];
  const attachments = filterAttachment ? allAttachments.filter(filterAttachment) : allAttachments;

  const filteredAttachmentsCount = allAttachments.length - attachments.length;
  const hasFilteredAttachments = filteredAttachmentsCount > 0;
  const filterLabels = getFilterLabels
    ? getFilterLabels(filteredAttachmentsCount)
    : { hide: f("default.hide"), show: f("default.show") };
  const filterAttachmentsLabel = isShowingFilteredAttachments ? filterLabels.hide : filterLabels.show;

  const attachmentsToDisplay = isShowingFilteredAttachments ? allAttachments : attachments;

  const clearSelectedAttachmentIdToOpen = () => setSelectedAttachment(undefined);

  if (isError) {
    console.error(error);
  }

  const showPlanConversion = !features.ATTACHMENTS;

  const isEmpty = !isLoading && uploadingFilesCount === 0 && attachmentsToDisplay.length === 0;

  const showTable = attachmentsToDisplay.length > 0 || uploadingFilesCount > 0;

  if (showPlanConversion) {
    return <AttachmentsPlanConversionBanner />;
  }

  return (
    <VerticalStack gap="2">
      <VerticalStack gap={hideTitle ? "0" : "4"}>
        <HorizontalStack blockAlign={"baseline"} align={"space-between"}>
          <HorizontalStack gap="4">
            {!hideTitle && (
              <Text variant="headingMd" as="h2">
                {attachmentsDisplayTitle}
              </Text>
            )}
            {isEmpty && hideTitle && !hideEmptyState && <Card.Section>{f("attachments.labels.empty")}</Card.Section>}
          </HorizontalStack>
          <VerticalStack inlineAlign={"end"}>{children}</VerticalStack>
        </HorizontalStack>
        {showTable && (
          <AttachmentsTable
            entity={entity}
            attachments={attachmentsToDisplay}
            uploadingFilesCount={uploadingFilesCount}
            hideSignatures={hideSignatures}
            onAttachmentSelected={setSelectedAttachment}
            readonly={readonly}
          />
        )}
        {!hideTitle && attachmentsToDisplay.length === 0 && (
          <Text as="span">
            {isLoading || uploadingFilesCount > 0 ? f("attachments.labels.loading") : f("attachments.labels.empty")}
          </Text>
        )}
      </VerticalStack>
      {hasFilteredAttachments && (
        <HorizontalStack>
          <Button onClick={toggleShowFilteredAttachments} disclosure={isShowingFilteredAttachments ? "up" : "down"}>
            {filterAttachmentsLabel}
          </Button>
        </HorizontalStack>
      )}
      {selectedAttachment && (
        <AttachmentSignaturesModal
          entity={entity}
          attachment={selectedAttachment}
          fetchSuggestedSigners={fetchSuggestedSigners}
          readonly={readonly}
          initialPerson={isPersonCustomer(entity) ? entity : undefined}
          onModalClose={clearSelectedAttachmentIdToOpen}
        />
      )}
    </VerticalStack>
  );
};

export default AttachmentsSection;
