import React, { ReactElement } from "react";
import { DataTable, DataTableProps, Spinner, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import { MAIN_COLUMN_WIDTH } from "../../constants/tables";
import DataTableCellChild from "../DataTableCellChild/DataTableCellChild";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import InfoRow, { InfoRowProps } from "../InfoRow/InfoRow";

export type InfoTableRowValue = string | ReactElement;

export type InfoTableRow = (InfoRowProps & { value: InfoTableRowValue; isHeading?: boolean }) | undefined;

export type InfoTableProps = Omit<DataTableProps, "rows" | "columnContentTypes" | "headings"> & {
  rows: InfoTableRow[];
  loading?: boolean;
  error?: unknown;
  useCondensedLabels?: boolean;
  showActionsColumn?: boolean;
  vertical?: boolean;
};

const InfoTable = (props: InfoTableProps) => {
  const { rows, loading, error, useCondensedLabels, showActionsColumn, vertical, ...dataTableProps } = props;

  const createRow = (infoRow?: InfoTableRow) => {
    if (!infoRow) {
      return [];
    }

    const { value, isHeading, label, suffix } = infoRow;

    const labelOrHeadingElement = isHeading ? (
      <Text variant="headingSm" as="h3">
        {label}
      </Text>
    ) : (
      <InfoRow {...infoRow} useCondensedLabels={useCondensedLabels} />
    );

    const valueElement = <StyledValueContainer>{value}</StyledValueContainer>;

    const row: (ReactElement | null)[] = vertical
      ? [
          <VerticalStack align="start" gap="4">
            {labelOrHeadingElement}
            {value && valueElement}
            {suffix}
          </VerticalStack>
        ]
      : [
          <DataTableCellChild width={MAIN_COLUMN_WIDTH}>{labelOrHeadingElement}</DataTableCellChild>,
          <VerticalStack align="start">
            {valueElement}
            {suffix}
          </VerticalStack>
        ];

    return row;
  };

  const displayRows =
    loading || error
      ? loading
        ? [[<Spinner size="small" />]]
        : [[<ErrorPanel message={error} />]]
      : rows.filter(Boolean).map((infoRow) => createRow(infoRow!));

  return (
    <StyledDataTableWrapper fixed={!showActionsColumn}>
      <DataTable
        columnContentTypes={vertical ? ["text"] : ["text", "text"]}
        headings={[]}
        rows={displayRows}
        stickyHeader
        {...dataTableProps}
      />
    </StyledDataTableWrapper>
  );
};

const StyledValueContainer = styled.div`
  white-space: normal;
`;

const StyledDataTableWrapper = styled.div<{ fixed?: boolean }>`
  & .Polaris-DataTable__Table {
    table-layout: ${({ fixed }) => (fixed ? "fixed" : undefined)};
  }
`;

export default InfoTable;
