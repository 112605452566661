import api from "../api";
import { isEmptyString } from "../utils/stringUtils";

import { isValidPerson, isValidPersonDateOfBirth } from "./person.helpers";

export const isDateOfBirthRequired = (roleType: api.RoleType) => roleType !== "REPRESENTATIVE";

export const isValidRole = (role: api.Role) => {
  if (!isValidPerson(role)) return false;
  if (isEmptyString(role.type)) return false;
  if (isDateOfBirthRequired(role.type)) {
    if (!isValidPersonDateOfBirth(role)) return false;
  }

  return true;
};
