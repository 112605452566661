import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import { removeProperty } from "../../utils/util";

import { AttachmentSignature } from "./AttachmentsSection.types";

export const hasSignedAttachment = (attachment: api.AttachmentDetails) =>
  attachment.signed_document_status === "EXISTS";

export const isAttachmentSigned = (attachment: api.AttachmentDetails) => attachment.signature_status === "SIGNED";

export const isAttachmentSignable = (attachment: api.AttachmentDetails) =>
  attachment.signature_status !== "INVALID_FOR_SIGNING";

export const wasSignedAttachmentDeleted = (attachment: api.AttachmentDetails) =>
  attachment.signed_document_status === "DELETED";

export const mapToAttachmentSignature = (saveSignature: api.SavedAttachmentSignature): AttachmentSignature => ({
  ...saveSignature,
  fullName: getFullName(saveSignature)
});

export const mapToCreateOrUpdateAttachmentSignature = (
  signature: AttachmentSignature
): api.CreateOrUpdateAttachmentSignatureRequest => {
  const { fullName, ...savedAttachmentProps } = removeProperty(signature, "role");

  const indexOfSeparator = fullName.lastIndexOf(" ");
  // don't send first and last name if role is selected
  const first_name = signature.role ? undefined : fullName.substring(0, indexOfSeparator).trim();
  const last_name = signature.role ? undefined : fullName.substring(indexOfSeparator + 1).trim();

  // return all the signature props except fullName which is locally added
  return { ...savedAttachmentProps, first_name, last_name, role_id: signature.role?.id };
};
