import { useMemo } from "react";
import { useIntl } from "react-intl";
import { HorizontalStack, Spinner, Text } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import DateIcon from "../../../assets/icons/calendar.svg";
import CompanyIcon from "../../../assets/icons/company.svg";
import PersonIcon from "../../../assets/icons/person.svg";
import api from "../../api";
import { formatKARNationalNumber } from "../../helpers/kar.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import CondensedText from "../CondensedText/CondensedText";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Icon from "../extensions/Icon";
import ReactTable from "../ReactTable/ReactTable";

import KarLookupResultStatus from "./KarLookupResultStatus";

export type KarLookupHistoryTableProps = {
  customerId: string;
  customerType: api.CustomerType;
};

const KarLookupHistoryTable = (props: KarLookupHistoryTableProps) => {
  const { customerId, customerType } = props;

  const f = useFormatMessage();
  const { formatDate, formatTime } = useIntl();
  const { getUserDisplayName } = useGetUserName();

  const { isLoading, data, isError, error } = useQuery([], () => api.listKarLookups(customerId));

  const rows = data?.results || [];

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<api.KarLookupResult>();

    const columns = [
      columnHelper.accessor("account_number", {
        header: f("table.column.previous-search"),
        cell: (props) => <KarLookupResultStatus result={props.row.original} />
      }),
      columnHelper.accessor("national_id", {
        header: f("table.column.customer-number"),
        cell: (props) => {
          const isPerson = customerType === "person";

          return (
            <HorizontalStack gap="1" blockAlign="center" wrap={false}>
              <Icon source={isPerson ? PersonIcon : CompanyIcon} />
              {isPerson ? props.getValue() : formatKARNationalNumber(props.getValue())}
            </HorizontalStack>
          );
        }
      }),
      columnHelper.accessor("timestamp", {
        header: f("table.column.time-of-search"),
        cell: (props) => {
          return (
            <HorizontalStack gap="1" blockAlign="center" wrap={false}>
              <Icon source={DateIcon} />
              <Text as={"span"}>
                {formatDate(props.getValue())} {formatTime(props.getValue())}
              </Text>
            </HorizontalStack>
          );
        }
      }),
      columnHelper.accessor("created_by", {
        header: f("table.column.search-by"),
        cell: (props) => {
          return <CondensedText>{getUserDisplayName(props.getValue())}</CondensedText>;
        }
      })
    ] as ColumnDef<api.KarLookupResult>[];

    return columns;
  }, []);

  if (isLoading) {
    return <Spinner size={"small"} />;
  }

  if (isError) {
    return <ErrorPanel message={error} />;
  }

  return <ReactTable<api.KarLookupResult> columns={columns} data={rows} getRowId={(row) => row.id} />;
};

export default KarLookupHistoryTable;
