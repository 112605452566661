import React from "react";

import api from "../../../../api";
import EntitiesTagPopover, { AssignedTags } from "../../../../components/EntitiesTagPopover/EntitiesTagPopover";
import { QUERIES_KEYS } from "../../../../constants/queries-keys";
import useQueryData from "../../../../hooks/useQueryData";
import { Customer } from "../../../../types/utilities";

interface ProjectCustomersLabelPopoverProps {
  project: api.Project;
  customers: Customer[];
  readonly?: boolean;
}

const ProjectCustomersLabelPopover = (props: ProjectCustomersLabelPopoverProps) => {
  const { project, customers, readonly } = props;

  const { updateQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, project.id]);

  const updateProjectCustomers = (updatedCustomers: (api.PersonCustomer | api.CompanyCustomer)[]) =>
    updateQueryData(
      (queryData) =>
        // merge the updatedCustomers with existing project query cached customers
        (queryData.customers = queryData.customers.map(
          (customer) => updatedCustomers.find((updatedCustomer) => updatedCustomer.id === customer.id) || customer
        ))
    );

  const onAssignLabels = ({ entitiesIds, tagIds }: AssignedTags) => {
    return api
      .batchSetLabelsOnProjectCustomers(project.id, {
        target_ids: entitiesIds,
        label_ids: tagIds
      })
      .then(({ customers }) => customers);
  };

  const onCreatedAndAssignLabel = (customersIds: string[], title: api.TranslationText) =>
    api
      .batchCreateAndAssignLabelOnProjectCustomers(project.id, {
        target_ids: customersIds,
        title
      })
      .then(({ customers }) => customers);

  return (
    <EntitiesTagPopover<Customer>
      entities={customers}
      readonly={readonly}
      onUpdateEntities={updateProjectCustomers}
      onAssignTags={onAssignLabels}
      onCreatedAndAssignTag={onCreatedAndAssignLabel}
    />
  );
};

export default ProjectCustomersLabelPopover;
