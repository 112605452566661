import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";

const SignatureFormWrapper = styled.div`
  --min: 15ch;

  display: grid;
  grid-gap: 0 var(--p-space-4);

  /* min() with 100% prevents overflow
  in extra narrow spaces */
  grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--min)), 1fr));

  // in mobile view - set a vertical gap between components
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-gap: var(--p-space-8) 0;
  }

  // draw lines between components
  & > *:not(:last-child) {
    padding-right: var(--p-space-4);
    border-right: var(--p-border-divider);

    @media (max-width: ${MOBILE_BREAKPOINT}) {
      border-right: revert;
      padding-right: revert;
    }
  }

  & .Polaris-Choice {
    border: var(--p-border-width-1) solid var(--p-border-subdued);
    border-radius: var(--p-border-radius-1);
    box-shadow: var(--p-shadow-button);
    padding: var(--p-space-2);
    width: 100%;

    &:hover:not(.Polaris-Choice--disabled) {
      background: var(--p-action-secondary-hovered);
    }

    &:active:not(.Polaris-Choice--disabled) {
      background: var(--p-action-secondary-pressed);
    }
  }
`;

export default SignatureFormWrapper;
