import React from "react";
import styled from "styled-components";

import useDisplayName from "../../hooks/useDisplayName";
import useFormatMessage from "../../hooks/useFormatMessage";
import DisplayName from "../DisplayName/DisplayName";
import Badge from "../extensions/Badge";
import Position from "../Position/Position";
import TextHighlighter from "../TextHighlighter/TextHighlighter";

import { isRelatedSearchUBO, RelatedSearchResultEntity } from "./RelatedSearchResults.helpers";

interface CustomerNameProps {
  searchResult: RelatedSearchResultEntity;
  searchTerm?: string;
}

const RelatedSearchResult = (props: CustomerNameProps) => {
  const { searchResult, searchTerm } = props;

  const f = useFormatMessage();
  const formatDisplayName = useDisplayName();

  const searchWords = searchTerm ? searchTerm.split(" ") : [];
  const typesLabel = searchResult.types.join(", ") + (searchResult.archived ? ` (${f("default.expired")})` : "");
  const displayName = formatDisplayName(searchResult);

  return (
    <DisplayName
      type="person"
      subdued={searchResult.archived}
      subtitle={<Position title={typesLabel}>{typesLabel}</Position>}
    >
      <StyledTextHighlighter
        style={{ "--text-color": searchResult.archived ? "var(--p-text-subdued)" : "inherit" }}
        searchWords={searchWords}
        textToHighlight={displayName}
        title={displayName}
      />
      {isRelatedSearchUBO(searchResult) && (
        <Badge size="xsmall" status="warning">
          {f("common.labels.ubo")}
        </Badge>
      )}
    </DisplayName>
  );
};

const StyledTextHighlighter = styled(TextHighlighter)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color);
`;

export default RelatedSearchResult;
