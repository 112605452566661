import React, { useMemo } from "react";
import { Card, ColumnContentType, DataTable, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import DataTableCellChild from "../DataTableCellChild/DataTableCellChild";
import DisplayTagsWrapper from "../DisplayTagsWrapper/DisplayTagsWrapper";
import ProjectName from "../ProjectName/ProjectName";
import ProjectStatus from "../ProjectStatus/ProjectStatus";

interface ProjectsSectionProps {
  projects?: api.ProjectListItem[];
}

const ProjectsSection = (props: ProjectsSectionProps) => {
  const { projects = [] } = props;

  const f = useFormatMessage();

  const columnContentTypes: ColumnContentType[] = ["text", "text", "text"];

  const headings = [f("table.column.name"), f("table.column.role"), f("table.column.status")];

  const renderProjectRow = (project: api.ProjectListItem) => [
    <ProjectName project={project} renderAsLink />,
    <DisplayTagsWrapper tags={project.customer_labels} />,
    <DataTableCellChild width={"15%"}>
      <ProjectStatus status={project.status} />
    </DataTableCellChild>
  ];

  const rows = useMemo(() => projects.map(renderProjectRow), [projects]);

  return (
    <VerticalStack gap="4">
      {projects.length > 0 && <DataTable columnContentTypes={columnContentTypes} headings={headings} rows={rows} />}
      {projects.length === 0 && <Card.Section>{f("customer-projects.empty")}</Card.Section>}
    </VerticalStack>
  );
};

export default ProjectsSection;
