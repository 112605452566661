import React from "react";

import { Customer } from "../../types/utilities";
import CustomerName from "../CustomerName/CustomerName";
import Tree from "../Tree/Tree";

interface VitecProjectProxiesProps {
  customer: Customer;
}

const VitecProjectProxies = (props: VitecProjectProxiesProps) => {
  const { customer } = props;

  const labels = [...(customer.labels || [])];

  return (
    <Tree>
      <Tree.Item>
        <CustomerName customer={{ ...customer, labels }} />
      </Tree.Item>
    </Tree>
  );
};

export default VitecProjectProxies;
