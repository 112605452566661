import React, { PropsWithChildren, useMemo } from "react";
import { ChoiceList, ChoiceListProps, HorizontalStack, Popover, Spinner, Text } from "@shopify/polaris";

import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import ErrorPanel from "../../ErrorPanel/ErrorPanel";
import PopoverStickyWrapper from "../../PopoverStickyWrapper/PopoverStickyWrapper";

import useGetMeasures from "./useGetMeasures";

export type GlobalMeasuresListProps = {
  selectedGlobalMeasuresIds?: string[];
  disabled?: boolean;
  onSelectionChange(selectedGlobalMeasures: Record<string, api.MeasureConfig>): void;
};

const GlobalMeasuresList = (props: PropsWithChildren<GlobalMeasuresListProps>) => {
  const { selectedGlobalMeasuresIds = [], disabled, onSelectionChange, children } = props;

  const f = useFormatMessage();
  const { data, isLoading, error } = useGetMeasures();

  const globalMeasures = data?.measure_configs || [];

  const filterGlobalMeasure = (measure: api.MeasureConfig) =>
    !measure.is_archived || selectedGlobalMeasuresIds.includes(measure.id);

  const choices: ChoiceListProps["choices"] = useMemo(
    () =>
      globalMeasures.filter(filterGlobalMeasure).map((measure) => ({
        label: <Text as="span">{measure.title}</Text>,
        value: measure.id,
        disabled: selectedGlobalMeasuresIds.includes(measure.id)
      })),
    [globalMeasures, selectedGlobalMeasuresIds]
  );

  const isEmpty = choices.length === 0;

  if (isLoading) {
    return (
      <Popover.Section>
        <HorizontalStack gap="2" blockAlign="center">
          <Spinner size="small"></Spinner>
          <Text as="span">{f("measures.list.loading.label")}</Text>
        </HorizontalStack>
      </Popover.Section>
    );
  }

  if (error) {
    return <ErrorPanel message={error} actionTitle={f("measures.list.loading.error-action")} />;
  }

  const handleSelectionChange = (selectedGlobalMeasuresIds: string[]) =>
    // convert the selected ids to an object in which key = global measure id and value = global measure config
    // this is done in order to export the global measures configs outside this component
    onSelectionChange(
      Object.fromEntries(
        globalMeasures
          .filter((measure) => selectedGlobalMeasuresIds.includes(measure.id))
          .map((measure) => [measure.id, measure])
      )
    );

  return (
    <PopoverStickyWrapper filter={children} width="40ch" height={isEmpty ? "8rem" : undefined}>
      {!isEmpty && (
        <ChoiceList
          title={
            <Text as="span" fontWeight="semibold">
              {f("measures.popover.labels.global-list.title")}
            </Text>
          }
          allowMultiple
          choices={choices}
          selected={selectedGlobalMeasuresIds}
          onChange={handleSelectionChange}
          disabled={disabled}
        />
      )}
      {isEmpty && (
        <Text as="span" color="subdued">
          {f("measures.popover.labels.global-list.empty")}
        </Text>
      )}
    </PopoverStickyWrapper>
  );
};

export default GlobalMeasuresList;
