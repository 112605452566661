import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Page } from "@shopify/polaris";
import { useQueryClient } from "@tanstack/react-query";

import LogoutImage from "../../assets/images/logout.svg";
import KDKEmptyState from "../components/KDKEmptyState/KDKEmptyState";
import useFormatMessage from "../hooks/useFormatMessage";
import HistoryLocationState from "../types/HistoryLocationState";

const Expired = () => {
  const queryClient = useQueryClient();
  const f = useFormatMessage();

  // Figure out where the user should be redirected after reauthenticating
  // If `sessionExpiryURL` is set in location state, use it. Else default to `window.location.origin`
  const location = useLocation<HistoryLocationState>();
  const locationState = location.state || {};
  const returnToUrl = locationState?.sessionExpiryURL || window.location.origin;

  useEffect(() => {
    queryClient.clear();
  }, []);

  return (
    <Page>
      <KDKEmptyState
        header={f("expired.heading")}
        description={f("expired.description")}
        image={LogoutImage}
        altImage={"Session expired"}
      >
        <Button
          primary
          onClick={() => {
            // Try to open the session expiry URL in this tab. Use `window.open` instead of logout because:
            // 1. If the user's session has expired (which it should have unless he/she is active in another tab)
            //      opening the session expiry URL will automatically redirect the user to the login page, and, after
            //      logging in again there, automatically redirect the user to the session expiry URL.
            // 2. If the user's session has _not_ expired, the user will be redirected to the session expiry URL without having
            //    to log in again. This is not the best UX, but it's a compromise we can live with
            window.open(returnToUrl, "_self");
          }}
        >
          {f("expired.actions.logout")}
        </Button>
      </KDKEmptyState>
    </Page>
  );
};

export default Expired;
