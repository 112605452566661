import { Card, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { getCustomerName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { CustomerDetails } from "../../types/utilities";
import AssignmentInfo from "../AssignmentInfo/AssignmentInfo";
import DisplayName from "../DisplayName/DisplayName";
import FullWidthRadioButton from "../FullWidthRadioButton/FullWidthRadioButton";

import useCustomerReviewAssignmentSelection from "./useCustomerReviewAssignmentSelection";

type CustomerReviewAssignmentSectionProps = {
  customer: CustomerDetails;
  reviewId?: string;
  assignments: api.Assignment[];
  selectedAssignmentId?: string;
  readonly?: boolean;
  onAssignmentsChange(assignmentId?: string): void;
};

const CustomerReviewAssignmentSection = (props: CustomerReviewAssignmentSectionProps) => {
  const { customer, reviewId, assignments, selectedAssignmentId, readonly, onAssignmentsChange } = props;

  const f = useFormatMessage();

  const {
    isCustomerRelationshipSelected,
    isCustomerRelationshipDisabled,
    isAssignmentDisabled,
    onAssignmentSelectionChange
  } = useCustomerReviewAssignmentSelection(customer.reviews, onAssignmentsChange, reviewId, selectedAssignmentId);

  return (
    <Card>
      <Card.Section
        title={
          <Text variant="headingSm" as="h2">
            {f("customer-review-assignment.labels.include-assignment")}
          </Text>
        }
      >
        <VerticalStack gap="2">
          <FullWidthRadioButton
            label={
              <DisplayName name={getCustomerName(customer)} strong>
                ({f("common.labels.customer-relationship")})
              </DisplayName>
            }
            checked={isCustomerRelationshipSelected}
            disabled={readonly || isCustomerRelationshipDisabled}
            onChange={() => onAssignmentSelectionChange(undefined)}
          />
          {assignments.map((assignment) => (
            <FullWidthRadioButton
              label={
                <AssignmentInfo
                  assignmentName={assignment.name}
                  assignmentPurpose={assignment.purpose}
                  isArchived={assignment.is_archived}
                />
              }
              checked={selectedAssignmentId === assignment.id}
              onChange={() => onAssignmentSelectionChange(assignment?.id)}
              disabled={readonly || isAssignmentDisabled(assignment)}
              key={assignment.id}
            />
          ))}
        </VerticalStack>
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewAssignmentSection;
