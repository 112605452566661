import api from "../api";

export const CRITICAL_COMPANY_DETAILS_FIELDS: (keyof api.CompanyCustomerDetailResponse)[] = [
  "is_bankrupt",
  "under_liquidation",
  "under_forced_liquidation_or_dissolution",
  "closure_date"
];

export const NORWEGIAN_COMPANY_NATIONAL_ID_REGEX = /^(?=.*\d)[\d ]+$/;
