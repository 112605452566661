import React, { ReactNode } from "react";
import { List, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useTranslatedText from "../../hooks/useTranslatedText";
import useYesNo from "../../hooks/useYesNo";
import { isEmptyString } from "../../utils/stringUtils";
import Country from "../Country/Country";

const valueWithDescription = (values: ReactNode[], additionalValue?: string) => {
  return (
    <VerticalStack gap="2">
      {values.length === 1 && <Text as="p">{values[0]}</Text>}
      {values.length > 1 && (
        <List spacing="extraTight">
          {values.map((value, index) => (
            <List.Item key={index}>{value}</List.Item>
          ))}
        </List>
      )}
      {!isEmptyString(additionalValue) && (
        <StyledItalicWrapper>
          <Text as="span">{additionalValue}</Text>
        </StyledItalicWrapper>
      )}
    </VerticalStack>
  );
};

type DisclosureAnswerProps = {
  answer?: api.DisclosureAnswer;
};

const DisclosureAnswer = (props: DisclosureAnswerProps) => {
  const { answer } = props;

  const t = useTranslatedText();
  const yesNo = useYesNo();

  switch (answer?.question.type) {
    case "YES_NO":
      return valueWithDescription([yesNo(answer.raw_answer as boolean)], answer.additional_info);
    case "TEXT":
      return valueWithDescription([answer.raw_answer], answer.additional_info);
    case "SINGLE_CHOICE":
      return valueWithDescription([t(answer.answer as api.TranslationText)], answer.additional_info);
    case "MULTIPLE_CHOICE": {
      const choices = answer.answer as api.TranslationText[];
      return valueWithDescription(
        choices?.map((choice) => <Text as="span">{t(choice)}</Text>),
        answer.additional_info
      );
    }
    case "SINGLE_COUNTRY_CHOICE":
    case "MULTIPLE_COUNTRY_CHOICE": {
      // verify that countries is an array without empty cells
      const countries = (
        (Array.isArray(answer.raw_answer) ? answer.raw_answer : [answer.raw_answer]) as api.CountryEnum[]
      ).filter(Boolean);

      return valueWithDescription(
        countries?.map((country) => <Country country={country} />),
        answer.additional_info
      );
    }
    default:
      return " - ";
  }
};

const StyledItalicWrapper = styled.span`
  font-style: italic;
  white-space: pre-line;
`;

export default DisclosureAnswer;
