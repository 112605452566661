import { useState } from "react";
import { Autocomplete, TextFieldProps } from "@shopify/polaris";

import { first } from "../../utils/collectionUtils";
import { uniqueId } from "../../utils/util";

type Suggestion = {
  value: string;
  label: string;
};

type AutoCompleteTextFieldProps = TextFieldProps & {
  isLoading?: boolean;
  suggestions?: Suggestion[];
  onSelection?(value: string): void;
};

const AutoCompleteTextField = (props: AutoCompleteTextFieldProps) => {
  const {
    isLoading,
    suggestions = [],
    id = uniqueId("AutoCompleteTextField"),
    value,
    onChange,
    onSelection,
    ...textFieldProps
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (text: string) => {
    setInputValue(text);
    if (onChange) {
      onChange(text, id);
    }
  };

  const updateText = async (value: string) => {
    handleChange(value);
  };

  const updateSelection = (selected: string[]) => {
    const selectedTexts = selected.map(
      (selectedItem) => suggestions.find((option) => option.value.match(selectedItem))?.label
    );

    const selectedValue = first(selectedTexts) || "";

    setSelectedOptions(selected);
    handleChange(selectedValue);

    if (onSelection) {
      requestAnimationFrame(() => onSelection(selectedValue));
    }
  };

  return (
    <Autocomplete
      options={suggestions}
      selected={selectedOptions}
      onSelect={updateSelection}
      loading={isLoading}
      textField={<Autocomplete.TextField onChange={updateText} value={inputValue} id={id} {...textFieldProps} />}
    />
  );
};

export default AutoCompleteTextField;
