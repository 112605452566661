import React, { CSSProperties } from "react";
import { Stack } from "@shopify/polaris";

import api from "../../api";
import { ReversedRiskTypes } from "../../constants/risk-types";
import useFormatMessage from "../../hooks/useFormatMessage";

import DashboardBarChart, { ChartBar } from "./DashboardBarChart";
import DashboardColorBlock from "./DashboardColorBlock";
import DashboardNavigation from "./DashboardNavigation";
import DashboardNumbersItem from "./DashboardNumbersItem";
import DashboardStack from "./DashboardStack";
import DashboardVerticalStack from "./DashboardVerticalStack";
import DashboardWidget from "./DashboardWidget";
import useDashboardNavigation from "./useDashboardNavigation";

const RISK_COLORS: Record<api.RiskLevel, { start: CSSProperties["color"]; end: CSSProperties["color"] }> = {
  HIGH: { start: "var(--kdk-dashboard-risk-high-gradient-start)", end: "var(--kdk-dashboard-risk-high-gradient-end)" },
  MEDIUM: {
    start: "var(--kdk-dashboard-risk-medium-gradient-start)",
    end: "var(--kdk-dashboard-risk-medium-gradient-end)"
  },
  LOW: { start: "var(--kdk-dashboard-risk-low-gradient-start)", end: "var(--kdk-dashboard-risk-low-gradient-end)" },
  NOT_SET: { start: "darkgray", end: "black" }
};

interface CustomersByRiskWidgetProps {
  values: api.RiskLevelMetric;
  customersCount: number;
  loading?: boolean;
}

const CustomersByRiskWidget = (props: CustomersByRiskWidgetProps) => {
  const { values, customersCount, loading } = props;

  const f = useFormatMessage();

  const { navigateToCustomersByRisk } = useDashboardNavigation();

  const bars: ChartBar[] = ReversedRiskTypes.map((risk) => ({
    value: !customersCount ? 0 : Math.round((values[risk] / customersCount) * 100),
    startColor: RISK_COLORS[risk].start,
    endColor: RISK_COLORS[risk].end
  }));

  return (
    <DashboardWidget title={f("dashboard.widgets.risk-levels.title")} loading={loading}>
      <DashboardStack>
        <Stack.Item fill>
          <DashboardVerticalStack>
            {ReversedRiskTypes.map((risk) => (
              <DashboardNavigation key={risk} onClick={() => navigateToCustomersByRisk(risk)}>
                <Stack distribution={"leading"} wrap={false}>
                  <DashboardColorBlock color={RISK_COLORS[risk].start} />
                  <DashboardNumbersItem
                    value={values[risk]}
                    percent={!customersCount ? 0 : values[risk] / customersCount}
                    label={f(`risklabel.${risk}`)}
                  />
                </Stack>
              </DashboardNavigation>
            ))}
          </DashboardVerticalStack>
        </Stack.Item>
        <DashboardBarChart bars={bars} />
      </DashboardStack>
    </DashboardWidget>
  );
};

export default CustomersByRiskWidget;
