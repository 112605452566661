import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "@shopify/polaris";

import api from "../../api";
import CompanyCustomerForm from "../../components/CompanyCustomerForm/CompanyCustomerForm";
import DetailsSkeleton from "../../components/DetailsSkeleton/DetailsSkeleton";
import useFormatMessage from "../../hooks/useFormatMessage";
import useNavigationToolbar from "../../hooks/useNavigationToolbar";
import { IDParams } from "../../types/params";

/**
 * Page for updating manually created company customer during onboarding
 */
const OnboardingManualCompanyPage = () => {
  const f = useFormatMessage();
  const { id } = useParams<IDParams>();
  const { onboardingStepIndex, goToStep } = useNavigationToolbar();

  const [companyCustomerDetails, setCompanyCustomerDetails] = useState<api.CompanyCustomerDetailResponse>();

  // Get existing company customer by the ID param
  const [isLoading, setIsLoading] = useState(id ? true : false);
  useEffect(() => {
    // Set loading while fetching
    setIsLoading(true);

    api.getCompanyCustomerDetails(id).then((data) => {
      setCompanyCustomerDetails(data);

      // Disable loading state
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    // Display skeleton while loading company customer
    return <DetailsSkeleton />;
  } else {
    // Not loading - display form with input fields
    return (
      <Page title={f("onboarding.bar.manual-company.information.title")}>
        <CompanyCustomerForm
          companyCustomer={companyCustomerDetails}
          onAfterSubmit={() => {
            // Navigate to the next step after the company customer has successfully been updated
            goToStep(onboardingStepIndex + 1);
          }}
        />
      </Page>
    );
  }
};

export default OnboardingManualCompanyPage;
