import React from "react";
import { Collapsible, Label, Stack, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import { uniqueId } from "../../utils/util";
import FullWidthRadioButton from "../FullWidthRadioButton/FullWidthRadioButton";
import KDKTextField from "../KDKTextField/KDKTextField";

type ReviewChecklistAnswerFormProps = {
  label: string;
  answer?: boolean;
  text?: string;
  readonly?: boolean;
  onChange(answer: boolean, text?: string): void;
};

const ReviewChecklistAnswerForm = (props: ReviewChecklistAnswerFormProps) => {
  const { label, answer, text, readonly, onChange } = props;

  const f = useFormatMessage();
  const id = uniqueId("ReviewChecklistAnswerForm");

  const name = `${label}-answer`;

  const isEmptyText = answer === true && isEmptyString(text);

  const error = isEmptyText ? f("review.checklist.answer.error.empty-additional-info") : undefined;

  return (
    <VerticalStack gap="2">
      <Label id={id}>{label}</Label>
      <Stack distribution="fill">
        <FullWidthRadioButton
          label={f("default.yes")}
          checked={answer === true}
          name={name}
          onChange={() => onChange(true, text)}
          disabled={readonly}
        />
        <FullWidthRadioButton
          label={f("default.no")}
          name={name}
          checked={answer === false}
          onChange={() => onChange(false, text)}
          disabled={readonly}
        />
      </Stack>
      <Collapsible id={id} open={answer === true}>
        <KDKTextField
          label={label}
          placeholder={f("review.checklist.answer.placeholder")}
          labelHidden
          autoComplete="on"
          multiline={3}
          autoFocus
          value={text || ""}
          disabled={readonly}
          debounceError
          error={error}
          onChange={(value) => onChange(answer === true, value)}
        />
      </Collapsible>
    </VerticalStack>
  );
};

export default ReviewChecklistAnswerForm;
