import React from "react";
import { HorizontalStack, Popover, Text, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import WarningIcon from "../../../../assets/icons/triangle-filled-exclamation.svg";
import api from "../../../api";
import { MAX_FOLLOWUP_INTERVAL_IN_MONTHS } from "../../../constants/followup";
import useChangedState from "../../../hooks/useChangedState";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { useCustomerDetailsQueryData } from "../../../hooks/useQueryData";
import useSnackbar from "../../../hooks/useSnackbar";
import { CustomerDetails } from "../../../types/utilities";
import { isEmptyString } from "../../../utils/stringUtils";
import { isNil } from "../../../utils/util";
import DateInput from "../../DateInput/DateInput";
import ErrorPanel from "../../ErrorPanel/ErrorPanel";
import Button from "../../extensions/Button";
import PopoverButton from "../../PopoverButton/PopoverButton";

const MIN_FOLLOWUP_DATE = new Date();

interface FollowupSelectProps {
  customer: CustomerDetails;
  readonly?: boolean;
}

const FollowupSelect = (props: FollowupSelectProps) => {
  const { customer, readonly } = props;

  const { follow_up_date, is_follow_up_expired } = customer;

  const [followupDate, setFollowupDate, changed] = useChangedState(follow_up_date || "");
  const followupNotSet = isNil(follow_up_date);

  const f = useFormatMessage();
  const { setSnackbar } = useSnackbar();
  const updateQueryData = useCustomerDetailsQueryData(customer);

  const updateFollowupMutation = useMutation(
    () => api.updateCustomerDetails(customer.id, { follow_up_date: followupDate }),
    {
      onSuccess: (response) => {
        updateQueryData((queryData) => (queryData.follow_up_date = response.follow_up_date));
        setSnackbar(f("followup.select.success.message"));
      }
    }
  );

  const followup = follow_up_date ? new Date(follow_up_date) : new Date();

  const maxFollowupDate = new Date(
    new Date(followup).setFullYear(followup.getFullYear() + MAX_FOLLOWUP_INTERVAL_IN_MONTHS / 12)
  );

  const invalidDate =
    isEmptyString(followupDate) ||
    new Date(followupDate).getTime() < MIN_FOLLOWUP_DATE.getTime() ||
    new Date(followupDate).getTime() > maxFollowupDate.getTime();

  const displayLabel = followupNotSet
    ? f("followup.select.empty.label")
    : is_follow_up_expired
      ? f("followup.select.expired.label", { followup })
      : f("followup.select.label", { followup });

  return (
    <PopoverButton
      id="followup-select"
      label={
        <Text as="span" fontWeight="semibold">
          {" "}
          {displayLabel}
        </Text>
      }
      size="slim"
      textAlign={"left"}
      plain
      monochrome
      useCaret
      removeUnderline
      disabled={readonly}
      icon={is_follow_up_expired ? WarningIcon : undefined}
      onClose={() => {
        updateFollowupMutation.reset();
        setFollowupDate(follow_up_date || "");
      }}
      render={(onClose) => (
        <Popover.Section>
          <VerticalStack gap="4">
            <Text as="span">{f("followup.select.title")}</Text>
            <DateInput
              label={f("followup.select.title")}
              labelHidden
              onChange={setFollowupDate}
              minDate={MIN_FOLLOWUP_DATE}
              maxDate={maxFollowupDate}
              value={followupDate}
              autoComplete="off"
              disabled={updateFollowupMutation.isLoading}
            />
            {updateFollowupMutation.isError && (
              <ErrorPanel
                message={updateFollowupMutation.error}
                actionTitle={f("followup.select.error-action")}
                fieldID="followup-select"
              />
            )}
            <HorizontalStack gap="2" align="end">
              <Button disabled={updateFollowupMutation.isLoading} onClick={onClose}>
                {f("default.cancel")}
              </Button>
              <Button
                primary
                loading={updateFollowupMutation.isLoading}
                disabled={invalidDate || !changed}
                onClick={() => updateFollowupMutation.mutate(undefined, { onSuccess: onClose })}
              >
                {f("default.save")}
              </Button>
            </HorizontalStack>
          </VerticalStack>
        </Popover.Section>
      )}
    />
  );
};

export default FollowupSelect;
