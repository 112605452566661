import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { ActionListItemDescriptor } from "@shopify/polaris";

import ClockIcon from "../../../assets/icons/clock.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import { ROUTES } from "../../constants/routes";
import { getCustomerName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import HistoryLocationState from "../../types/HistoryLocationState";
import { Customer } from "../../types/utilities";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import ConfirmAction from "../ConfirmAction/ConfirmAction";
import Icon from "../extensions/Icon";

interface CancelOnboardingMenuProps {
  customer?: Customer;
  allowCancelOnboarding: boolean;
  onClose(): void;
}

const CancelOnboardingMenu = (props: CancelOnboardingMenuProps) => {
  const { customer, allowCancelOnboarding, onClose } = props;

  const [showCancelConfirm, toggleCancelConfirm] = useOpenClose();

  const f = useFormatMessage();
  const location = useLocation<HistoryLocationState>();

  const canCancelOnboarding = allowCancelOnboarding && customer !== undefined;

  const isPersonOnboarding =
    matchPath(location.pathname, { path: [ROUTES.ONBOARDING_SAVED_PERSON, ROUTES.ONBOARDING_NEW_PERSON] }) !== null;

  const items: ActionListItemDescriptor[] = isPersonOnboarding
    ? [
        {
          content: f("default.cancel"),
          onAction: onClose
        }
      ]
    : [
        {
          content: f("onboarding.cancel.and.save"),
          onAction: onClose,
          prefix: <Icon source={ClockIcon} useMask />,
          disabled: !customer
        },
        {
          content: f("onboarding.cancel.and.delete"),
          onAction: canCancelOnboarding ? toggleCancelConfirm : onClose,
          prefix: <Icon source={TrashIcon} useMask />
        }
      ];

  return (
    <>
      <ActionsDropdown
        items={items}
        hideIcon
        title={f("onboarding.cancel.onboarding")}
        disclosure="down"
        size="medium"
        forceOpen={showCancelConfirm}
      />
      {showCancelConfirm && (
        <ConfirmAction
          apiCall={() => api.cancelOnboarding(customer!.id)}
          title={f("onboarding.cancel.confirm.title")}
          description={f("onboarding.cancel.confirm.description", { name: getCustomerName(customer!) })}
          actionTitle={f("onboarding.cancel.and.delete")}
          onNo={toggleCancelConfirm}
          onSuccess={onClose}
        />
      )}
    </>
  );
};

export default CancelOnboardingMenu;
