import React, { PropsWithChildren } from "react";
import { Banner, Collapsible, Link, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useOpenClose from "../../hooks/useOpenClose";

interface InfoBoxProps {
  title: string;
}

const InfoBox = (props: PropsWithChildren<InfoBoxProps>) => {
  const { title, children } = props;

  const [isExpanded, toggleExpanded] = useOpenClose();

  return (
    <Banner status="info">
      <Link onClick={toggleExpanded}>
        <StyledTitleWrapper>
          <Text as="span" fontWeight="semibold">
            {title}
          </Text>
        </StyledTitleWrapper>
      </Link>
      <Collapsible open={isExpanded} id="">
        <StyledInfoBoxContent>
          <VerticalStack gap="4">{children}</VerticalStack>
        </StyledInfoBoxContent>
      </Collapsible>
    </Banner>
  );
};

const StyledInfoBoxContent = styled.div`
  margin-top: var(--p-space-4);
  white-space: pre-wrap;
`;

const StyledTitleWrapper = styled.div`
  & > * {
    flex-wrap: unset;
    max-width: 200px;
    overflow-wrap: anywhere;
  }
`;

export default InfoBox;
