import React from "react";
import { Card, HorizontalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import useRDCMatch from "../../hooks/useRDCMatch";
import { MatchDetailsProps } from "../../types/MatchDetails";
import CondensedText from "../CondensedText/CondensedText";
import MatchAttributesCollapsibleCardSections from "../MatchAttributesCollapsibleCardSections/MatchAttributesCollapsibleCardSections";
import MatchCategorySection from "../MatchCategorySection/MatchCategorySection";
import MatchDetailsBadge from "../MatchDetailsBadge/MatchDetailsBadge";
import MatchEvent from "../MatchEvent/MatchEvent";

const MatchDetailsNegativeNewsSection = (props: MatchDetailsProps) => {
  const { matchDetails } = props;

  const f = useFormatMessage();

  const { adverseMediaCategories, adverseMediaEvents, hasNewInfo } = useRDCMatch(matchDetails);

  return (
    <MatchCategorySection title={f("matches.labels.negative-news")}>
      {adverseMediaCategories.length > 0 && (
        <Card.Section title={<CondensedText>{f("matches.titles.categories")}</CondensedText>}>
          <HorizontalStack gap="4">
            {adverseMediaCategories.map((category) => (
              <MatchDetailsBadge key={category}>{category}</MatchDetailsBadge>
            ))}
          </HorizontalStack>
        </Card.Section>
      )}
      {adverseMediaEvents.length > 0 && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.events")}
          elements={adverseMediaEvents}
          renderElement={(event) => (
            <MatchEvent event={event} showNewInfo={hasNewInfo(event)}>
              <MatchDetailsBadge>{event.sub_category.description}</MatchDetailsBadge>
            </MatchEvent>
          )}
        />
      )}
    </MatchCategorySection>
  );
};

export default MatchDetailsNegativeNewsSection;
