import { useMemo } from "react";

import api from "../../../api";
import { getTranslationText } from "../../../helpers/display.helpers";
import useLanguage from "../../../hooks/useLanguage";
import { groupBy } from "../../../utils/collectionUtils";
import { isEmptyString } from "../../../utils/stringUtils";

export const isCategoryCode = (code: api.IndustryCodeEnum) => !code.includes(".");

const useFilterIndustries = (
  industries: api.IndustryCodeMetric[],
  categories: api.IndustryCategories[],
  filter: string,
  selectedCategory?: api.IndustryCategories
) => {
  const language = useLanguage();

  // group industries by their level 2 code (value before ".")
  // example: "44.087" -> "44"
  const industriesMap = useMemo(
    () => groupBy(industries, (industry) => industry.industry_code.split(".")[0]),
    [industries, categories]
  );

  const categoriesIndustriesItems: api.IndustryCodeMetric[] = useMemo(
    () =>
      categories.map((category) => ({
        industry_code: category.code as api.IndustryCodeEnum,
        industry_name: category.name,
        count: (industriesMap.get(category.code) || []).reduce((result, industry) => result + industry.count, 0)
      })),
    [industriesMap]
  );

  // filter list based of text filter
  const filteredItems = useMemo(() => {
    const categoryIndustries: api.IndustryCodeMetric[] = selectedCategory
      ? industriesMap.get(selectedCategory.code) || []
      : categoriesIndustriesItems;

    if (isEmptyString(filter)) {
      return categoryIndustries;
    }

    return categoryIndustries.filter((industry) =>
      getTranslationText(language, industry.industry_name).toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  }, [industriesMap, filter, language, selectedCategory]);

  const filteredIndustries = useMemo(() => {
    // sort list
    filteredItems.sort((industryA, industryB) => industryB.count - industryA.count);

    return filteredItems;
  }, [filteredItems]);

  return {
    filteredIndustries,
    filteredIndustriesCount: filteredItems.length
  };
};

export default useFilterIndustries;
