import useAuth from "./useAuth";
import useFeatures from "./useFeatures";

const useShowResponsibleUserFilter = () => {
  const features = useFeatures();
  const { user } = useAuth();

  // admin users are user with more or more groups
  const isAdminUser = user?.groups !== undefined && user.groups.length > 0;

  if (!features.RESPONSIBLE_USER) {
    return false;
  }

  // if ENFORCE_RESPONSIBLE_USER_FILTER is enabled, only show the responsible users filter for
  // users with groups (KDK Admin, MLRO, etc.) and hide it for regular users (without groups).
  return features.ENFORCE_RESPONSIBLE_USER_FILTER ? isAdminUser : true;
};

export default useShowResponsibleUserFilter;
