import * as React from "react";
import { useMutation } from "@tanstack/react-query";

import api from "../../../../api";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import Confirm from "../../../Confirm/Confirm";

type DeleteSignatureModalProps = {
  customerId: string;
  signatureId: string;
  open: boolean;
  signatureName: string;
  onSignatureDeleted(): void;
  onModalClose(): void;
};

const DeleteSignatureModal = (props: DeleteSignatureModalProps) => {
  const { customerId, signatureId, open, signatureName, onSignatureDeleted, onModalClose } = props;

  const f = useFormatMessage();

  const mutation = useMutation(() => api.deleteCustomerSignature(customerId, signatureId), {
    onSuccess: () => {
      closeModal();
      onSignatureDeleted();
    }
  });

  const closeModal = () => onModalClose();

  const deleteSignature = () => mutation.mutate();

  if (!open) {
    return null;
  }

  return (
    <Confirm
      title={f("modals.delete.signature.title")}
      description={f("modals.delete.signature.description", { name: signatureName })}
      onYes={deleteSignature}
      onNo={closeModal}
      actionTitle={f("modals.delete.signature.button")}
      isActionInProgress={mutation.isLoading}
      error={mutation.error}
    />
  );
};

export default DeleteSignatureModal;
