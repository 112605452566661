import { useState } from "react";

import api from "../../api";
import { isActiveAssignment } from "../../helpers/assignments.helpers";
import { isPendingReview } from "../../helpers/reviews.helpers";
import { Review } from "../../types/CustomerReview";

const useCustomerReviewAssignmentSelection = (
  reviews: Review[],
  onAssignmentsChange: (assignmentId?: string) => void,
  reviewId?: string,
  selectedAssignmentId?: string
) => {
  // if there is a pending review that is NOT attached to an assignment then it is the customer relationship pending review
  const hasCustomerRelationshipPendingReview = reviews.some((review) => isPendingReview(review) && !review.assignment);

  // set default touched to true in case there's already a customer relationship pending review
  // or that an assignment was already selected
  // touched is used to force the user to select what the review applies to by disabling checked of customer relationship
  // for new review
  const [touched, setTouched] = useState(
    Boolean((reviewId && hasCustomerRelationshipPendingReview) || selectedAssignmentId)
  );

  // do not allow selecting an assignment that is not active or already has a pending review
  const isAssignmentDisabled = (assignment: api.Assignment) =>
    !isActiveAssignment(assignment) ||
    reviews.some((review) => isPendingReview(review) && review.assignment?.id === assignment.id);

  const onAssignmentSelectionChange = (assignmentId?: string) => {
    setTouched(true);
    onAssignmentsChange(assignmentId);
  };

  const isCustomerRelationshipSelected = touched && selectedAssignmentId === undefined;

  return {
    isCustomerRelationshipSelected,
    isCustomerRelationshipDisabled: hasCustomerRelationshipPendingReview,
    isAssignmentDisabled,
    onAssignmentSelectionChange
  };
};

export default useCustomerReviewAssignmentSelection;
