import api from "../../api";
import { DEFAULT_LANGUAGE } from "../../constants/languages";
import { NEW_REVIEW_CHECKLIST_PREFIX } from "../../constants/review-checklists";
import { EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS } from "../../helpers/checklists.helpers";
import { isEmptyString } from "../../utils/stringUtils";
import { uniqueId } from "../../utils/util";

export const createNewReviewChecklist = (): api.ReviewChecklist => ({
  id: uniqueId(NEW_REVIEW_CHECKLIST_PREFIX),
  name: "",
  customer_type: "both",
  questions: [],
  is_archived: false,
  is_required: false,
  created_dt: new Date().toString(),
  version: 0,
  display_settings: EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS
});

const isInvalidReviewChecklistQuestion = (question: api.ReviewChecklistQuestion) =>
  isEmptyString(question.text[DEFAULT_LANGUAGE]);

export const isInvalidReviewChecklist = (checklist: api.ReviewChecklist) =>
  isEmptyString(checklist.name) ||
  checklist.questions.length === 0 ||
  checklist.questions.some(isInvalidReviewChecklistQuestion) ||
  checklist.display_settings.assignment_purposes.some(isEmptyString);
