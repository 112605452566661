import { forwardRef, PropsWithChildren } from "react";

import PlusMajorIcon from "../../../assets/icons/plus-major-white.svg";
import Button, { ButtonProps } from "../extensions/Button";
import Icon from "../extensions/Icon";

const AddButton = forwardRef<HTMLSpanElement, PropsWithChildren<ButtonProps>>((props, ref) => {
  return <Button ref={ref} {...props} icon={<Icon source={PlusMajorIcon} width="12px" height="12px" />} primary />;
});

export default AddButton;
