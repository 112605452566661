import { useMemo } from "react";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import { groupBy } from "../../utils/collectionUtils";

import { getRelatedRoleOrOwnerKey, isRelatedRole, RelatedSearchResultEntity } from "./RelatedSearchResults.helpers";

const useRelatedSearchResults = (searchResults: (api.RelatedRole | api.RelatedOwner)[]) => {
  const f = useFormatMessage();

  const searchResultsToDisplay = useMemo(() => {
    const entitiesMap = groupBy(searchResults, getRelatedRoleOrOwnerKey);

    const entitiesToDisplay: RelatedSearchResultEntity[] = [...entitiesMap.values()].map((entities) => ({
      ...Object.assign({}, ...entities),
      types: entities.map((role) => (isRelatedRole(role) ? f(RoleTypes[role.type]) : f("table.row.owner")))
    }));

    return entitiesToDisplay;
  }, [searchResults]);

  return { searchResultsToDisplay };
};

export default useRelatedSearchResults;
