import React, { CSSProperties, PropsWithChildren } from "react";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

interface TableColumnHeaderProps {
  centered?: boolean;
  minWidth?: CSSProperties["minWidth"];
}

const TableColumnHeader = (props: PropsWithChildren<TableColumnHeaderProps>) => {
  const { centered, minWidth, children } = props;

  return (
    <StyledTableHeaderCell centered={centered} minWidth={minWidth}>
      <Text as="span" color="subdued">
        {children}
      </Text>
    </StyledTableHeaderCell>
  );
};

const StyledTableHeaderCell = styled.div<TableColumnHeaderProps>`
  white-space: normal;
  text-align: ${({ centered }) => (centered ? "center" : "inherited")};
  min-width: ${({ minWidth }) => minWidth || "auto"};
`;

export default TableColumnHeader;
