import React, { useMemo } from "react";
import { Button, DropZone, VerticalStack } from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isChecklistActive } from "../../helpers/checklists.helpers";
import useGetReviewChecklists from "../../hooks/useGetReviewChecklists";
import useSnackbar from "../../hooks/useSnackbar";
import useTags from "../../hooks/useTags";
import { ReviewChecklistExport } from "../../types/ReviewChecklist";
import { hasContent } from "../../utils/collectionUtils";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

const ImportChecklistsButton = () => {
  const { setSnackbar } = useSnackbar();
  const { data } = useGetReviewChecklists();
  const { findTagByTitle } = useTags();

  const queryClient = useQueryClient();

  const existingNames = useMemo(
    () => data?.checklists.filter(isChecklistActive).map((checklist) => checklist.name) || [],
    [data]
  );

  const getValidChecklistName = (name: string) => (existingNames.includes(name) ? `imported-${name}` : name);

  const createChecklistsMutation = useMutation<unknown, unknown, File[]>(
    (files) => Promise.all(files.map(handleFile)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES_KEYS.REVIEW_CHECKLISTS]);
        //  No need for translation since this action is used to move checklists between tenants
        setSnackbar("Imported successfully.");
      }
    }
  );

  const handleFilesUpload = async (files: File[]) => {
    createChecklistsMutation.mutate(files);
  };

  const importLabels = async (labels: api.Label[]) => {
    return new Promise<Record<string, string>>((resolve) => {
      const labelsMap: Record<string, string> = {};
      let wereNewLabelsAdded = false;

      labels.forEach((checklistLabel) => {
        const existingLabel = findTagByTitle(checklistLabel.title.nb);
        if (existingLabel) {
          // existing label was found so replace checklistLabel id with the existing label id
          labelsMap[checklistLabel.id] = existingLabel.id;
        } else {
          // create a new label and replace checklistLabel id with the new label id
          const labelData: api.LabelData = {
            title: checklistLabel.title,
            color: checklistLabel.color
          };
          api.createLabel(labelData).then((newLabel) => {
            wereNewLabelsAdded = true;
            labelsMap[checklistLabel.id] = newLabel.id;
          });
        }
      });

      if (wereNewLabelsAdded) {
        queryClient.invalidateQueries([QUERIES_KEYS.ALL_TAGS]);
      }

      resolve(labelsMap);
    });
  };

  const handleFile = async (file: File) => {
    return new Promise<api.ReviewChecklist>((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            const importData = JSON.parse(reader.result as string) as ReviewChecklistExport;

            const { checklist, labels } = importData;

            // find or create the labels from the checklist before adding it
            if (checklist.display_settings?.label_ids && hasContent(labels)) {
              // find / create labels
              const labelsMap: Record<string, string> = await importLabels(labels);

              // replace original labels ids with existing labels ids
              const checklistLabels = checklist.display_settings.label_ids;
              checklist.display_settings.label_ids = checklistLabels.map((oldLabelId) => labelsMap[oldLabelId]);
            }

            const createdChecklist = await api.createReviewChecklist({
              ...checklist,
              name: getValidChecklistName(checklist.name)
            });

            resolve(createdChecklist);
          },
          false
        );

        if (file) {
          reader.readAsText(file);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <VerticalStack gap="1">
      <DropZone
        outline={false}
        variableHeight
        type="file"
        accept={"application/json"}
        onDropAccepted={handleFilesUpload}
      >
        {/* No need for translation since this button is used to move checklists between tenants*/}
        <Button loading={createChecklistsMutation.isLoading}>Import</Button>
      </DropZone>
      {createChecklistsMutation.isError && <ErrorPanel message={createChecklistsMutation.error} />}
    </VerticalStack>
  );
};

export default ImportChecklistsButton;
