import React, { ReactNode } from "react";
import { HorizontalStack, TextFieldProps, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../../constants/languages";
import KDKTextField from "../KDKTextField/KDKTextField";
import Language from "../Language/Language";

const createDefaultTranslationText = (): api.TranslationText => ({ [DEFAULT_LANGUAGE]: "" });

interface TranslationTextFieldProps extends Omit<TextFieldProps, "value" | "onChange" | "label"> {
  text: api.TranslationText | undefined;
  vertical?: boolean;
  showFlagPrefix?: boolean;
  getLabel?(language: api.LanguageEnum): ReactNode;
  onChange(value: api.TranslationText): void;
}

const TranslationTextField = (props: TranslationTextFieldProps) => {
  const {
    text = createDefaultTranslationText(),
    vertical,
    showFlagPrefix,
    getLabel = () => "",
    onChange,
    requiredIndicator,
    focused,
    autoFocus,
    prefix,
    error,
    ...textFieldProps
  } = props;

  if (vertical) {
    return (
      <VerticalStack gap="4">
        {SUPPORTED_LANGUAGES.map((language) => (
          <KDKTextField
            key={language}
            value={text ? text[language] || "" : ""}
            label={getLabel(language)}
            prefix={showFlagPrefix ? <Language language={language} /> : prefix}
            requiredIndicator={language === DEFAULT_LANGUAGE && requiredIndicator}
            focused={language === DEFAULT_LANGUAGE && focused}
            autoFocus={language === DEFAULT_LANGUAGE && autoFocus}
            error={language === DEFAULT_LANGUAGE && error}
            onChange={(value) => onChange({ ...text, [language]: value })}
            {...textFieldProps}
          />
        ))}
      </VerticalStack>
    );
  } else {
    return (
      <HorizontalStack gap="4" wrap={false}>
        {SUPPORTED_LANGUAGES.map((language) => (
          <KDKTextField
            key={language}
            value={text ? text[language] || "" : ""}
            label={getLabel(language)}
            prefix={showFlagPrefix ? <Language language={language} /> : prefix}
            requiredIndicator={language === DEFAULT_LANGUAGE && requiredIndicator}
            focused={language === DEFAULT_LANGUAGE && focused}
            autoFocus={language === DEFAULT_LANGUAGE && autoFocus}
            error={language === DEFAULT_LANGUAGE && error}
            onChange={(value) => onChange({ ...text, [language]: value })}
            {...textFieldProps}
          />
        ))}
      </HorizontalStack>
    );
  }
};

export default TranslationTextField;
