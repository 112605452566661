import React from "react";
import { generatePath } from "react-router-dom";
import { Button, Card, DataTable, HorizontalStack, Link, VerticalStack } from "@shopify/polaris";

import CalendarIcon from "../../../assets/icons/calendar.svg";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { CustomerDetails } from "../../types/utilities";
import ArchivedBadge from "../ArchivedBadge/ArchivedBadge";
import CustomDate from "../CustomDate/CustomDate";
import DataTableCellChild from "../DataTableCellChild/DataTableCellChild";
import Icon from "../extensions/Icon";
import MultipleUsersInitials from "../MultipleUsersInitials/MultipleUsersInitials";

type AssignmentsSectionProps = {
  customer: CustomerDetails;
};

const AssignmentsSection = (props: AssignmentsSectionProps) => {
  const { customer } = props;

  const assignments = customer.assignments || [];
  const archivedAssignmentCount = assignments.filter((assignment) => assignment.is_archived).length;

  const f = useFormatMessage();
  // show archived assignments by default is all the assignments are archived
  const [showArchivedAssignments, toggleArchivedAssignments] = useOpenClose(
    archivedAssignmentCount === assignments.length
  );

  const hasAssignments = assignments.length > 0;

  const assignmentsToDisplay = showArchivedAssignments
    ? assignments
    : assignments.filter((assignment) => !assignment.is_archived);

  const showFilter = archivedAssignmentCount > 0;

  const filterAssignmentsLabel = showArchivedAssignments
    ? f("customer-assignments.list.filter.hide-archived-assignments", { count: archivedAssignmentCount })
    : f("customer-assignments.list.filter.show-archived-assignments", { count: archivedAssignmentCount });

  return (
    <VerticalStack gap="4">
      {!hasAssignments && <Card.Section>{f("customer-assignments.empty")}</Card.Section>}

      {hasAssignments && (
        <DataTable
          verticalAlign="middle"
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={[
            f("customer-assignments.list.header.name"),
            // f("customer-assignments.list.header.risk"),
            f("customer-assignments.list.header.responsible"),
            // f("customer-assignments.list.header.status"),
            f("customer-assignments.list.header.added")
          ]}
          rows={assignmentsToDisplay.map((assignment) => {
            return [
              // <DataTableCellChild width={"40%"}>
              <DataTableCellChild width={"60%"}>
                <HorizontalStack blockAlign={"baseline"} gap={"2"}>
                  <Link url={generatePath(ROUTES.ASSIGNMENT_DETAILS, { id: assignment.id, customerId: customer.id })}>
                    {assignment.name}
                  </Link>
                  {assignment.is_archived && <ArchivedBadge />}
                </HorizontalStack>
              </DataTableCellChild>,
              // <Risk risk={assignment.risk_level} />,
              <MultipleUsersInitials users={assignment.responsible_users} />,
              // <AssignmentStatus assignment={assignment} />,
              <HorizontalStack gap="1" blockAlign="center" wrap={false}>
                <Icon source={CalendarIcon} />
                <CustomDate date={assignment.created_dt} />
              </HorizontalStack>
            ];
          })}
        />
      )}
      {showFilter && (
        <HorizontalStack>
          <Button onClick={toggleArchivedAssignments} disclosure={showArchivedAssignments ? "up" : "down"}>
            {filterAssignmentsLabel}
          </Button>
        </HorizontalStack>
      )}
    </VerticalStack>
  );
};

export default AssignmentsSection;
