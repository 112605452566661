import { useState } from "react";
import { TextField } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import { isEmptyString } from "../../utils/stringUtils";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

type RejectMatchCasesConfirmProps = {
  isOpen: boolean;
  customerId: string;
  customerType: api.CustomerType;
  matchCasesIds: string[];
  onClose(): void;
  onAfterSuccess(): void;
};

const RejectMatchCasesConfirm = (props: RejectMatchCasesConfirmProps) => {
  const { isOpen, customerId, customerType, matchCasesIds, onClose, onAfterSuccess } = props;

  const f = useFormatMessage();

  const [description, setDescription] = useState("");

  const updateQueryData = useCustomerDetailsQueryData({ id: customerId, type: customerType });

  const rejectMatchCaseMutation = useMutation<api.MatchReview, unknown, { matchCaseId: string }>(
    ({ matchCaseId }) => api.createMatchReview(customerId, matchCaseId, { is_match: false, description }),
    {
      onSuccess: (matchReview, { matchCaseId }) => {
        updateQueryData((customerDetails) => {
          customerDetails.match_cases = customerDetails.match_cases.map((matchCase) =>
            matchCase.id === matchCaseId ? { ...matchCase, review: matchReview, status: "RESOLVED" } : matchCase
          );
        });
      }
    }
  );

  const rejectMatchCasesPromise = () =>
    Promise.all(matchCasesIds.map((matchCaseId) => rejectMatchCaseMutation.mutateAsync({ matchCaseId })));

  const handleNo = () => {
    setDescription("");
    onClose();
  };

  const handleSuccess = () => {
    setDescription("");
    onAfterSuccess();
  };

  return (
    <ConfirmAction
      isOpen={isOpen}
      apiCall={rejectMatchCasesPromise}
      title={f("bulk.actions.reject-match-cases.confirmation.title")}
      description={f("bulk.actions.reject-match-cases.confirmation.description", { count: matchCasesIds.length })}
      isDestructive
      isActionDisabled={isEmptyString(description)}
      actionTitle={f("default.reject")}
      onNo={handleNo}
      onSuccess={handleSuccess}
      useSuccessToast
      successTitle={f("bulk.actions.reject-match-cases.success.message", { count: matchCasesIds.length })}
    >
      <TextField
        label={f("matches.labels.review.description.placeholder")}
        autoComplete="on"
        multiline={4}
        value={description}
        onChange={setDescription}
        requiredIndicator
      />
    </ConfirmAction>
  );
};

export default RejectMatchCasesConfirm;
