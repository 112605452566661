import React, { useState } from "react";
import { AppliedFilterInterface, FilterInterface, Filters } from "@shopify/polaris";

import CreatedDateFilter from "../../components/CreatedDateFilter/CreatedDateFilter";
import useFiltersOptions from "../../components/CustomersListFilters/useFiltersOptions";
import UsersList from "../../components/UsersList/UsersList";
import { QUERY_SEARCH_DEBOUNCE_DELAY } from "../../constants/durations";
import { createFilter } from "../../helpers/filters.helpers";
import useCreatedDateAppliedFilters from "../../hooks/useCreatedDateAppliedFilters";
import useDebounce from "../../hooks/useDebounce";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import useShowResponsibleUserFilter from "../../hooks/useShowResponsibleUserFilter";
import { toggleElement } from "../../utils/collectionUtils";

import useProjectListParams from "./useProjectListParams";

interface ProjectsListFiltersProps {
  isLoading: boolean;
}

const ProjectsListFilters = (props: ProjectsListFiltersProps) => {
  const { isLoading } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const { getUserFullName } = useGetUserName(features.RESPONSIBLE_USER);
  const filterOptions = useFiltersOptions();
  const showResponsibleUserFilter = useShowResponsibleUserFilter();

  const {
    q,
    setQ,
    status,
    setStatus,
    responsibleUserIds,
    setResponsibleUserIds,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    resetAllFilters
  } = useProjectListParams();

  const appliedCreatedDateFilters = useCreatedDateAppliedFilters({
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore
  });

  const [queryText, setQueryText] = useState(q ?? "");

  useDebounce(() => setQ(queryText), QUERY_SEARCH_DEBOUNCE_DELAY, [queryText]);

  const [statusFilter, statusAppliedFilter] = createFilter(
    "status",
    f("component.filters.status.title"),
    status || "",
    isLoading,
    filterOptions.projectStatus,
    setStatus
  );

  const createdDateFilter: FilterInterface = {
    key: "created",
    label: f("component.filters.created.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <CreatedDateFilter
        isLoading={isLoading}
        createdRange={createdRange}
        createdAfter={createdAfter}
        createdBefore={createdBefore}
        setCreatedRange={setCreatedRange}
        setCreatedAfter={setCreatedAfter}
        setCreatedBefore={setCreatedBefore}
      />
    )
  };

  const filters: FilterInterface[] = [statusFilter, createdDateFilter];
  const appliedFilters = [statusAppliedFilter].filter(Boolean) as AppliedFilterInterface[];

  appliedFilters.push(...appliedCreatedDateFilters);

  if (showResponsibleUserFilter) {
    const responsibleUserFilter: FilterInterface = {
      key: "responsible-user",
      label: f("responsible.label"),
      shortcut: true,
      disabled: isLoading,
      hideClearButton: true,
      filter: (
        <UsersList
          showAllUsersOption
          selectedUserIds={responsibleUserIds}
          allowMultiple
          onChange={setResponsibleUserIds}
        />
      )
    };

    // responsible users filter should be visible in the "default" filters group - add it after the status (first) filter
    filters.splice(1, 0, responsibleUserFilter);

    responsibleUserIds.forEach((userId) => {
      const userName = getUserFullName(userId);
      appliedFilters.push({
        key: "responsible-user",
        label: `${f("responsible.label")}: ${userName}`,
        onRemove: () => setResponsibleUserIds(toggleElement(responsibleUserIds, userId))
      });
    });
  }

  return (
    <Filters
      queryValue={queryText}
      queryPlaceholder={f("projects.list.filters.search.hint")}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={setQueryText}
      onQueryClear={() => setQueryText("")}
      onClearAll={resetAllFilters}
    />
  );
};

export default ProjectsListFilters;
