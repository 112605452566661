import React from "react";
import { Banner } from "@shopify/polaris";

import useFormatMessage from "../../../hooks/useFormatMessage";

const ExpiredDisclosureDocumentSignatureBanner = () => {
  const f = useFormatMessage();

  return (
    <Banner status={"warning"} title={f("disclosure-card.expired-banner.title")}>
      {f("disclosure-card.expired-banner.description")}
    </Banner>
  );
};

export default ExpiredDisclosureDocumentSignatureBanner;
