import React from "react";

import useFormatMessage from "../../hooks/useFormatMessage";
import HelpText from "../HelpText/HelpText";
import MarkdownContent from "../MarkdownContent/MarkdownContent";

const MatchDetailsPEPHelpText = () => {
  const f = useFormatMessage();

  return (
    <HelpText title={f("matches.help-text.pep.title")}>
      <MarkdownContent content={f("matches.help-text.pep.description")} />
    </HelpText>
  );
};

export default MatchDetailsPEPHelpText;
