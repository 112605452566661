/**
 * The number of unresolved match cases after which bulk action for rejecting match cases will be enabled
 */
export const MATCH_CASES_BULK_ACTIONS_CUTOFF = 3;

/**
 * The number of match attributes (events, sources, links, etc.) items to display initially
 */
export const MATCH_ATTRIBUTE_ITEMS_CUTOFF = 3;

/**
 * The number of initial positions and relations to display initially
 */
export const MATCH_POSITIONS_AND_RELATIONS_CUTOFF = 10;
