import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Country from "../Country/Country";

type MatchAddressInfoProps = {
  address?: api.MatchAddress;
};

const MatchAddressInfo = (props: MatchAddressInfoProps) => {
  const { address } = props;

  const f = useFormatMessage();

  if (!address) {
    return <>{f("not.specified")}</>;
  }

  const addressLabel = [address.addr1, address.addr2, address.city, address.state_prov].filter(Boolean).join(", ");

  return (
    <HorizontalStack gap="1" wrap={false}>
      {addressLabel && (
        <Text variant="bodyMd" as="span">
          {addressLabel}
        </Text>
      )}
      <Country country={address.country_code} shortName />
    </HorizontalStack>
  );
};

export default MatchAddressInfo;
