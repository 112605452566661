import api from "../api";
import { LATEST_OWNERSHIP_REGISTRY_RECORD_YEAR } from "../constants/ownership-updates";
import { isValidDate, parseYear } from "../utils/dateUtils";
import { isNumberInRange } from "../utils/numbersUtils";

import { getFullName } from "./display.helpers";
import {
  convertPersonToUpdateRequest,
  getPersonCountries,
  isValidPerson,
  isValidPersonDateOfBirth
} from "./person.helpers";

export const getOwnerCountries = (owner?: api.Owner) => {
  const countriesMap = getPersonCountries(owner?.person);

  if (owner?.unknown_owner_address?.country) {
    countriesMap.set("ADDRESS", owner?.unknown_owner_address?.country);
  }

  if (owner?.company?.address?.country) {
    countriesMap.set("ADDRESS", owner?.company?.address?.country);
  }

  return countriesMap;
};

export const isOwnerPerson = (owner?: api.Owner) =>
  Boolean(owner && !owner.is_unknown && !owner.company && owner.person !== undefined);

export const isAllowScreeningOwner = (owner?: api.Owner) => Boolean(owner?.id) && isOwnerPerson(owner);

export const getOwnerName = (owner: api.Owner) =>
  owner.unknown_owner_name || owner.company?.name || getFullName(owner.person);

export const isValidOwner = (owner: api.Owner) => {
  if (!isValidPerson(owner)) return false;
  if (!isValidPersonDateOfBirth(owner)) return false;
  if (!isNumberInRange(owner.percent_share, 0, 100)) return false;

  return true;
};

// returns true if the founded year is after the ownerships updates year
export const wasFoundedAfterOwnershipRegistryWasPublished = (foundedDate?: string) => {
  if (!isValidDate(foundedDate)) {
    return false;
  }

  const foundedYear = parseYear(foundedDate!);

  return foundedYear > LATEST_OWNERSHIP_REGISTRY_RECORD_YEAR;
};

export const convertOwnerToUpdateOwnerRequest = ({
  percent_share,
  person,
  deleted,
  comment,
  custom_is_ubo
}: api.Owner): api.UpdateOwnerRequest => ({
  person: person ? convertPersonToUpdateRequest(person) : undefined,
  deleted,
  comment,
  custom_is_ubo,
  percent_share: percent_share ? parseFloat(percent_share) : undefined
});
