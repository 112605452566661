import { useCallback, useState } from "react";
import FlipMove from "react-flip-move";
import { Button, HorizontalStack, Scrollable, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import ChevronIcon from "../../../../assets/icons/chevron-left.svg";
import api from "../../../api";
import CondensedText from "../../../components/CondensedText/CondensedText";
import Icon from "../../../components/extensions/Icon";
import PieChart from "../../../components/PieChart/PieChart";
import SearchTextField from "../../../components/SearchTextField/SearchTextField";
import TextHighlighter from "../../../components/TextHighlighter/TextHighlighter";
import { MOBILE_BREAKPOINT, PRESET_COLORS_FOR_INDUSTRY_TYPE_PIE_CHART } from "../../../constants/styles";
import { getTranslationText } from "../../../helpers/display.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useLanguage from "../../../hooks/useLanguage";
import { isEmptyString } from "../../../utils/stringUtils";
import DashboardColorBlock from "../DashboardColorBlock";
import DashboardNavigation from "../DashboardNavigation";
import DashboardNumbersItem from "../DashboardNumbersItem";
import DashboardVerticalStack from "../DashboardVerticalStack";
import DashboardWidget from "../DashboardWidget";
import useDashboardNavigation from "../useDashboardNavigation";

import useFilterIndustries, { isCategoryCode } from "./useFilterIndustries";

type CustomersByIndustryTypeWidgetProps = {
  industries: api.IndustryCodeMetric[];
  categories: api.IndustryCategories[];
  loading?: boolean;
  customersCount: number;
};

const CustomersByIndustryTypeWidget = (props: CustomersByIndustryTypeWidgetProps) => {
  const { loading, industries, categories, customersCount } = props;

  const f = useFormatMessage();
  const language = useLanguage();

  const { navigateToCustomersByIndustryType } = useDashboardNavigation();

  const [filter, setFilter] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<api.IndustryCategories>();

  const clearCategory = () => setSelectedCategory(undefined);

  const clearQueryText = useCallback(() => setFilter(""), []);

  const { filteredIndustries, filteredIndustriesCount } = useFilterIndustries(
    industries,
    categories,
    filter,
    selectedCategory
  );

  const dataLabels = filteredIndustries.map((industry) => getTranslationText(language, industry.industry_name));
  const dataColors = PRESET_COLORS_FOR_INDUSTRY_TYPE_PIE_CHART;

  const clickHandler = (industry?: api.IndustryCodeMetric) => {
    if (industry) {
      if (isCategoryCode(industry.industry_code)) {
        setSelectedCategory({ code: industry.industry_code, name: industry.industry_name });
      } else {
        navigateToCustomersByIndustryType(industry.industry_code);
      }
    } else {
      clearCategory();
    }
  };

  return (
    <DashboardWidget title={f("dashboard.widgets.industry-types.title")} loading={loading}>
      <StyledGridContainer>
        <StyledPieContainer>
          {industries.length === 0 && (
            <CondensedText>{f("dashboard.widgets.industry-types.empty.message")}</CondensedText>
          )}
          {industries.length > 0 && (
            <PieChart<api.IndustryCodeMetric>
              values={filteredIndustries}
              valueKey="count"
              labels={dataLabels}
              colors={dataColors}
              tooltipPrefix={f("customers.list.title")}
              onItemClick={clickHandler}
            />
          )}
        </StyledPieContainer>
        <StyledListContainer>
          <StyledFilterContainer>
            <VerticalStack gap="2">
              <SearchTextField
                labelHidden
                placeholder={f("dashboard.widgets.industry-types.filter.placeholder")}
                value={filter}
                onChange={setFilter}
                disabled={industries.length === 0}
                clearButton
                onClearButtonClick={clearQueryText}
              />
              {selectedCategory && (
                <HorizontalStack gap="2" blockAlign="center" wrap={false}>
                  <Button onClick={clearCategory} plain icon={<Icon source={ChevronIcon} width="8px" height="12px" />}>
                    {f("button.go.back")}
                  </Button>
                  <Text as="span" fontWeight="semibold" truncate>
                    {getTranslationText(language, selectedCategory.name)}
                  </Text>
                </HorizontalStack>
              )}
            </VerticalStack>
          </StyledFilterContainer>
          <Scrollable vertical horizontal={false}>
            <FlipMove
              enterAnimation={{
                from: { transform: selectedCategory ? "translateX(100%)" : "translateX(-100%)" },
                to: { transform: "" }
              }}
              leaveAnimation={{
                from: { transform: "" },
                to: { transform: selectedCategory ? "translateX(-100%)" : "translateX(100%)" }
              }}
            >
              <DashboardVerticalStack key={selectedCategory?.code}>
                {filteredIndustries.map((industry) => (
                  <StyledDashboardNavigation
                    spacing="medium"
                    onClick={() => clickHandler(industry)}
                    hideCaret={selectedCategory === undefined}
                    key={industry.industry_code}
                  >
                    <HorizontalStack gap="4" blockAlign="stretch" wrap={false}>
                      <div>
                        <DashboardColorBlock
                          color={dataColors[filteredIndustries.indexOf(industry) % dataColors.length]}
                          border={isEmptyString(industry.industry_code) ? "black" : undefined}
                        />
                      </div>
                      <DashboardNumbersItem
                        value={industry.count}
                        percent={!customersCount ? 0 : industry.count / customersCount}
                        label={
                          <StyledIndustryName
                            searchWords={[filter]}
                            textToHighlight={getTranslationText(language, industry.industry_name)}
                            title={getTranslationText(language, industry.industry_name)}
                          />
                        }
                      />
                    </HorizontalStack>
                  </StyledDashboardNavigation>
                ))}
              </DashboardVerticalStack>
            </FlipMove>
          </Scrollable>
          <StyledFooterContainer>
            <Text as="span">
              {filteredIndustriesCount} {selectedCategory ? f("default.industries") : f("default.categories")}
            </Text>
          </StyledFooterContainer>
        </StyledListContainer>
      </StyledGridContainer>
    </DashboardWidget>
  );
};

const StyledGridContainer = styled.div`
  height: 100%;
  min-height: 16rem;

  display: grid;
  grid-template-columns: 1fr 2fr;

  // For small viewports - use vertical layout
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  & > * + * {
    border-left: var(--p-border-divider);
  }
`;

const StyledPieContainer = styled.div`
  height: 100%;
  padding: var(--p-space-4);
  margin: 0 auto;
  aspect-ratio: 1 / 1;
  text-align: center;

  min-height: 16rem;
`;

const StyledListContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  max-height: 16rem;
`;

const StyledFilterContainer = styled.div`
  padding: var(--p-space-4) var(--p-space-4) var(--p-space-2) var(--p-space-4);
`;

const StyledFooterContainer = styled.div`
  padding: var(--p-space-1) var(--p-space-4);
`;

const StyledDashboardNavigation = styled(DashboardNavigation)`
  height: auto;
  padding-left: var(--p-space-4);
  padding-right: var(--p-space-4);

  display: grid;
  grid-template-columns: 1fr auto;
`;

const StyledIndustryName = styled(TextHighlighter)`
  & > mark {
    background: inherit;
    color: inherit;
    text-decoration: underline;
  }
`;

export default CustomersByIndustryTypeWidget;
