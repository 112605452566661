import React from "react";
import { useIntl } from "react-intl";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../api";
import DetailsGrid from "../../../components/DetailsGrid/DetailsGrid";
import KDKBanner from "../../../components/KDKBanner/KDKBanner";
import ProjectStatus from "../../../components/ProjectStatus/ProjectStatus";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";

import ProjectResponsibleUsersSelect from "./ProjectResponsibleUsersSelect";

interface BasicProjectInfoProps {
  project: api.Project;
  readonly?: boolean;
}

const BasicProjectInfo = (props: BasicProjectInfoProps) => {
  const { project, readonly } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const { formatDate } = useIntl();

  return (
    <KDKBanner hideIcon hideBorder>
      <DetailsGrid rows={2}>
        <Text variant="bodyMd" as="span">
          {f("project.info.labels.status")}:
        </Text>
        <Text as="span" fontWeight="bold">
          <ProjectStatus status={project.status} />
        </Text>
        <Text variant="bodyMd" as="span">
          {f("project.info.labels.created")}:
        </Text>
        <Text as="span" fontWeight="bold">
          <Text variant="bodyMd" as="span">
            {formatDate(project.created_dt)}
          </Text>
        </Text>
        <Text variant="bodyMd" as="span">
          {f("project.info.labels.description")}:
        </Text>
        <StyledDescription>{project.description}</StyledDescription>
        {features.RESPONSIBLE_USER && (
          <>
            <Text variant="bodyMd" as="span">
              {f("project.info.labels.project-managers")}:
            </Text>
            <ProjectResponsibleUsersSelect
              projectId={project.id}
              responsibleUsers={project.responsible_users || []}
              readonly={readonly}
            />
          </>
        )}
      </DetailsGrid>
    </KDKBanner>
  );
};

const StyledDescription = styled.p`
  white-space: pre-wrap;
  word-break: break-all;
  font-weight: var(--p-font-weight-bold);
`;

export default BasicProjectInfo;
