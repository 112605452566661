import React from "react";
import FlipMove from "react-flip-move";
import { Card, Text, VerticalStack } from "@shopify/polaris";

import { DEFAULT_LANGUAGE } from "../../../constants/languages";
import { duplicateQuestion } from "../../../helpers/questions.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { Question } from "../../../types/Questions";
import { insertAt, removeItem, swapElements, updateItem } from "../../../utils/collectionUtils";
import { isEmptyString } from "../../../utils/stringUtils";
import AddButton from "../../AddButton/AddButton";
import { Direction } from "../../MoveButtons/MoveButtons";

import QuestionEditor from "./QuestionEditor/QuestionEditor";
import { isNewQuestion } from "./QuestionEditor/QuestionEditor.helpers";
import QuestionsTableRow from "./QuestionsTableRow";

export type QuestionsTableProps = {
  questions: Question[];
  readonly?: boolean;
  onNewQuestion(): Question;
  onChange(questions: Question[]): void;
};

const QuestionsTable = (props: QuestionsTableProps) => {
  const { questions, readonly, onNewQuestion, onChange } = props;

  const f = useFormatMessage();

  const isEmpty = questions.length === 0;

  const addNewQuestion = () => onChange([...questions, onNewQuestion()]);

  const handleQuestionChange = (question: Question) => onChange(updateItem(questions, question));

  const handleQuestionDelete = (question: Question) => onChange(removeItem(questions, question));

  const handleQuestionOrderChange = (question: Question, direction: Direction) => {
    const index = questions.indexOf(question);
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    onChange(swapElements(questions, index, targetIndex));
  };

  const handleQuestionDuplicate = (question: Question) =>
    onChange(insertAt(questions, duplicateQuestion(question), questions.indexOf(question)));

  const isValidQuestion = (question: Question) => {
    if (isEmptyString(question.text[DEFAULT_LANGUAGE])) {
      return f("question.editor.errors.empty-question");
    }

    const hasDuplicateName = questions.some(
      (q) => q.id !== question.id && q.text[DEFAULT_LANGUAGE].trim() === question.text[DEFAULT_LANGUAGE].trim()
    );

    if (hasDuplicateName) {
      return f("question.editor.errors.duplicate-question");
    }
  };

  return (
    <>
      {isEmpty && (
        <Card>
          <Card.Section>
            <VerticalStack gap="4" inlineAlign="center">
              <Text variant="bodyMd" as="span" color="subdued">
                {f("question.editor.questions-table.empty.label")}
              </Text>
              <AddButton onClick={addNewQuestion} disabled={readonly}>
                {f("questions.editor.buttons.add-question.label")}
              </AddButton>
            </VerticalStack>
          </Card.Section>
        </Card>
      )}
      <FlipMove>
        {questions.map((question, index) => (
          <QuestionsTableRow
            isFirst={index === 0}
            isLast={index === questions.length - 1}
            readonly={readonly}
            onOrderChange={(direction) => handleQuestionOrderChange(question, direction)}
            onDuplicate={() => handleQuestionDuplicate(question)}
            onDelete={() => handleQuestionDelete(question)}
            key={question.id}
          >
            <QuestionEditor
              question={question}
              autofocus={index === questions.length - 1 && isNewQuestion(question)}
              readonly={readonly}
              isValidQuestion={isValidQuestion}
              onChange={handleQuestionChange}
            />
          </QuestionsTableRow>
        ))}
      </FlipMove>
    </>
  );
};

export default QuestionsTable;
