import React from "react";
import { Banner } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../../hooks/useFormatMessage";
import useRichFormatMessage from "../../../hooks/useRichFormatMessage";

interface OutstandingSharesBannerProps {
  outstandingShares?: number;
  totalShares?: number;
}

const OutstandingSharesBanner = (props: OutstandingSharesBannerProps) => {
  const { outstandingShares = 0, totalShares = 0 } = props;

  const f = useFormatMessage();
  const richFormat = useRichFormatMessage();

  return (
    <Banner status="warning" title={f("outstanding-shares.banner.title")}>
      <StyledContent>
        {richFormat("outstanding-shares.banner.description", { outstandingShares, totalShares })}
      </StyledContent>
    </Banner>
  );
};

const StyledContent = styled.p`
  white-space: pre-wrap;
`;

export default OutstandingSharesBanner;
