import getUnicodeFlagIcon from "country-flag-icons/unicode";

import api from "../api";

export const DEFAULT_LANGUAGE = "nb";

/**
 * List of supported languages, sorted by importance
 */
export const SUPPORTED_LANGUAGES: api.LanguageEnum[] = ["nb", "en"];

export const LANGUAGES_TO_COUNTRY_CODES: Record<api.LanguageEnum, api.CountryEnum> = {
  nb: "NO",
  en: "US"
};

export const LANGUAGES_FLAGS: Record<api.LanguageEnum, string> = {
  nb: getUnicodeFlagIcon("NO"),
  en: getUnicodeFlagIcon("US")
};

export const LANGUAGE_KEY = "kdk-language";
