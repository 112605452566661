import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import ProjectIcon from "../../../assets/icons/project.svg";
import useGetAssignmentIndustriesAndPurposes from "../../hooks/useGetAssignmentIndustriesAndPurposes";
import ArchivedBadge from "../ArchivedBadge/ArchivedBadge";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";

type AssignmentInfoProps = {
  assignmentName?: string;
  assignmentPurpose?: string;
  isArchived?: boolean;
};

const AssignmentInfo = (props: AssignmentInfoProps) => {
  const { assignmentName, assignmentPurpose, isArchived } = props;

  const { getPurposeLabel } = useGetAssignmentIndustriesAndPurposes();

  if (!assignmentName && !assignmentPurpose) {
    return null;
  }

  const purpose = getPurposeLabel(assignmentPurpose);

  return (
    <StyledWrapper title={assignmentName}>
      <HorizontalStack gap="1" blockAlign="baseline" wrap={false}>
        <Icon source={ProjectIcon} width="16px" height="12px" />
        <StyledWrapper>
          <Text as="span" fontWeight="bold" truncate>
            {assignmentName}
          </Text>
          {purpose && <StyledInfoText>{purpose}</StyledInfoText>}
        </StyledWrapper>
        {isArchived && <ArchivedBadge />}
      </HorizontalStack>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  line-height: var(--p-font-line-height-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledInfoText = styled(CondensedText)`
  font-weight: var(--p-font-weight-bold);
  display: inline-block;
`;

export default AssignmentInfo;
