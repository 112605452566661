import React from "react";
import { generatePath } from "react-router-dom";
import { Button } from "@shopify/polaris";
import styled from "styled-components";

import FreshCustomerImage from "../../../assets/images/fresh-customer.svg";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import KDKEmptyState from "../KDKEmptyState/KDKEmptyState";

const CustomersListFreshCustomerEmptyState = () => {
  const f = useFormatMessage();

  const lookupPath = generatePath(ROUTES.COMPANY_LOOKUP);

  return (
    <StyledEmptyState
      header={f("customers.list.fresh-customer.title")}
      description={f("customers.list.fresh-customer.content")}
      image={FreshCustomerImage}
      altImage={""}
    >
      <Button primary url={lookupPath}>
        {f("customers.list.fresh-customer.label")}
      </Button>
    </StyledEmptyState>
  );
};

const StyledEmptyState = styled(KDKEmptyState)`
  margin-top: 0;
`;

export default CustomersListFreshCustomerEmptyState;
