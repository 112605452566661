import React, { PropsWithChildren, useEffect, useState } from "react";
import { Card, Stack, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import {
  MAX_BIRTH_DATE,
  MAX_LENGTH_OF_TEXT_INPUT_FIELD,
  MIN_BIRTH_DATE,
  REQUIRED_FIELDS_FOR_NEW_PERSON
} from "../../constants/person";
import { getBirthDate } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { PersonInformation, PersonInformationKeys, ValueOf } from "../../types/utilities";
import { isValidDate, isValidYear } from "../../utils/dateUtils";
import { isEmptyString } from "../../utils/stringUtils";
import AddressForm from "../AddressForm/AddressForm";
import KDKTextField from "../KDKTextField/KDKTextField";
import PartialDateInput from "../PartialDateInput/PartialDateInput";

import PersonContactDetails from "./PersonContactDetails";
import PersonCountries from "./PersonCountries";

export type PersonFormProps<T extends PersonInformation> = {
  value: T;
  disabled?: boolean;
  // key of fieldsErrorMap is set as string (and not as keyof PersonalInformation) in order to enable general utilities
  // to provide errors for this component
  fieldsErrorMap?: Map<string, string>;
  isCreating?: boolean;
  birthDateIsRequired?: boolean;
  allowBirthYear?: boolean;
  requiredFields?: (keyof api.Address)[];
  renderAsCards?: boolean;
  onChange(value: T): void;
  onValidityChange(isFormValid: boolean): void;
};

function PersonForm<T extends PersonInformation>(props: PropsWithChildren<PersonFormProps<T>>) {
  const {
    value,
    disabled,
    fieldsErrorMap = new Map(),
    birthDateIsRequired,
    isCreating,
    allowBirthYear,
    requiredFields = [],
    renderAsCards,
    onChange,
    onValidityChange,
    children
  } = props;

  const f = useFormatMessage();

  const [isNationalIdValid, setIsNationalIdValid] = useState(true);

  const firstNameError = isEmptyString(value.first_name)
    ? f("common.errors.required.first.name")
    : fieldsErrorMap.get("first_name");

  const lastNameError = isEmptyString(value.last_name)
    ? f("common.errors.required.last.name")
    : fieldsErrorMap.get("last_name");

  // when creating a new person the full birthday should be set
  const isInvalidBirthDate =
    (!allowBirthYear || !isEmptyString(value.birth_date)) &&
    !isValidDate(value.birth_date, MIN_BIRTH_DATE, MAX_BIRTH_DATE);

  const birthDateError =
    isInvalidBirthDate || !isValidYear(value.birth_year, MIN_BIRTH_DATE, MAX_BIRTH_DATE)
      ? f("common.errors.invalid.birth.date")
      : fieldsErrorMap.get("birth_date");

  const requiredCountries: PersonInformationKeys = isCreating ? REQUIRED_FIELDS_FOR_NEW_PERSON : [];

  const isCountryOfCitizenshipValid = !isCreating || !isEmptyString(value.country_of_citizenship);
  const isCountryOfResidenceValid = !isCreating || !isEmptyString(value.country_of_residence);

  const isFormValid =
    !isEmptyString(value.first_name) &&
    !isEmptyString(value.last_name) &&
    // if date of birth is required, check if there's an error from PartialDateInput (birthDateError), otherwise
    // do not perform any validation on the date of birth input field
    (birthDateIsRequired ? isEmptyString(birthDateError) : true) &&
    isCountryOfCitizenshipValid &&
    isCountryOfResidenceValid &&
    isNationalIdValid;

  useEffect(() => onValidityChange(isFormValid), [isFormValid]);

  const handleChange = (field: keyof PersonInformation, newValue?: ValueOf<PersonInformation>) => {
    const valueResult = { ...value, [field]: newValue };

    if (field === "country_of_residence" && isEmptyString(value.national_id)) {
      valueResult.national_id_country = newValue as api.CountryEnum;
    }

    onChange(valueResult);
  };

  const handleContactDetailsChange = (updateValue: T, isValid?: boolean) => {
    if (isValid !== undefined) {
      setIsNationalIdValid(isValid);
    }
    onChange(updateValue);
  };

  const handleBirthDateChange = (birthDate: string, year: string) => {
    if (allowBirthYear && isEmptyString(birthDate)) {
      onChange({ ...value, birth_date: undefined, birth_year: parseInt(year, 10) });
    } else {
      onChange({ ...value, birth_date: birthDate, birth_year: parseInt(year, 10) });
    }
  };

  // render form components as function in order to be able to render them
  // straight or inside Card components
  const personDetailsMarkup = () => (
    <>
      <Stack distribution={"fillEvenly"}>
        <KDKTextField
          value={value.first_name || ""}
          label={f("common.labels.first_name")}
          onChange={(newValue) => handleChange("first_name", newValue)}
          autoFocus
          placeholder={f("common.labels.first_name")}
          disabled={disabled}
          error={firstNameError}
          maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
          autoComplete="off"
          requiredIndicator
        />
        <KDKTextField
          value={value.last_name || ""}
          label={f("common.labels.last_name")}
          onChange={(newValue) => handleChange("last_name", newValue)}
          placeholder={f("common.labels.last_name")}
          disabled={disabled}
          error={lastNameError}
          maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
          autoComplete="off"
          requiredIndicator
        />
        <PartialDateInput
          dateValue={getBirthDate(value)}
          label={f("common.labels.birth_date")}
          required={birthDateIsRequired}
          disabled={disabled}
          error={birthDateError}
          onChange={handleBirthDateChange}
        />
      </Stack>
      <AddressForm
        value={value.address}
        disabled={disabled}
        onChange={(address) => handleChange("address", address)}
        requiredFields={requiredFields}
      />
    </>
  );

  const personCountriesMarkup = () => (
    <VerticalStack gap="4">
      <PersonCountries
        value={value}
        disabled={disabled}
        requiredFields={requiredCountries}
        fieldsErrorMap={fieldsErrorMap}
        onChange={handleChange}
      />
      <PersonContactDetails
        value={value}
        disabled={disabled}
        onChange={handleContactDetailsChange}
        fieldsErrorMap={fieldsErrorMap}
      />
      {children}
    </VerticalStack>
  );

  if (renderAsCards) {
    return (
      <>
        <Card>
          <Card.Section>{personDetailsMarkup()}</Card.Section>
        </Card>
        <Card>
          <Card.Section>{personCountriesMarkup()}</Card.Section>
        </Card>
      </>
    );
  }

  return (
    <VerticalStack gap="4">
      {personDetailsMarkup()}
      {personCountriesMarkup()}
    </VerticalStack>
  );
}

export default PersonForm;
