import { Card, HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import { RiskIndicatorsType } from "../../types/RiskIndicators";
import Badge from "../extensions/Badge";
import RiskIndicators from "../RiskIndicators/RiskIndicators";
import useRiskIndicators from "../RiskIndicators/useRiskIndicators";

type CustomerReviewRiskSectionProps = {
  customerId: string;
  riskIndicators: RiskIndicatorsType;
};

const CustomerReviewRiskSection = (props: CustomerReviewRiskSectionProps) => {
  const { customerId, riskIndicators } = props;

  const f = useFormatMessage();
  const { showRiskIndicators, riskIndicatorsCount } = useRiskIndicators(riskIndicators);

  return (
    <Card>
      {showRiskIndicators && (
        <Card.Section
          title={
            <HorizontalStack gap="1" blockAlign={"baseline"}>
              <Text variant="headingSm" as="h2">
                {f("reviews.labels.risk-indicators", { count: 0 })}:
              </Text>
              <StyledCounter>{riskIndicatorsCount}</StyledCounter>
            </HorizontalStack>
          }
        >
          <RiskIndicators customerId={customerId} riskIndicators={riskIndicators} />
        </Card.Section>
      )}
    </Card>
  );
};

const StyledCounter = styled(Badge)`
  & .Polaris-Badge {
    border: solid var(--p-border-width-1) var(--p-color-border-caution-subdued);
    background-color: var(--p-color-bg-caution-subdued);
  }
`;

export default CustomerReviewRiskSection;
