import { useEffect } from "react";

import usePageNavigation from "./usePageNavigation";
import useSettingsItems from "./useSettingsItems";

const useSettingsPage = () => {
  const sections = useSettingsItems();
  const { setNavigationSections, clearNavigationSections } = usePageNavigation();

  useEffect(() => {
    setNavigationSections(sections);

    return () => clearNavigationSections();
  }, []);
};

export default useSettingsPage;
