import React from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

import DashboardCustomerTypeTemplateWidget from "./DashboardCustomerTypeTemplateWidget";
import useDashboardNavigation from "./useDashboardNavigation";

interface CustomersMarkedAsPepWidgetProps {
  values: api.CustomerTypeMetric;
  total: number;
  customersCount: number;
  loading?: boolean;
}

const CustomersMarkedAsPepWidget = (props: CustomersMarkedAsPepWidgetProps) => {
  const { values, total, customersCount, loading } = props;

  const f = useFormatMessage();

  const { navigateToPepCustomers } = useDashboardNavigation();

  return (
    <DashboardCustomerTypeTemplateWidget
      title={f("dashboard.widgets.marked-as-pep.title")}
      values={values}
      totalCount={total}
      allCustomersCount={customersCount}
      loading={loading}
      onNavigation={navigateToPepCustomers}
    />
  );
};

export default CustomersMarkedAsPepWidget;
