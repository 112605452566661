import React, { useCallback, useEffect, useState } from "react";
import { Combobox, Listbox, TextFieldProps } from "@shopify/polaris";

import useGetAssignmentIndustriesAndPurposes from "../../hooks/useGetAssignmentIndustriesAndPurposes";
import useLanguage from "../../hooks/useLanguage";
import { filterRegEx } from "../../utils/stringUtils";

type IndustryChoicesSelectProps = Omit<TextFieldProps, "onChange" | "onClearButtonClick" | "clearButton"> & {
  selectedIndustry?: string;
  onChange(selectedIndustry?: string): void;
};

const IndustryChoicesSelect = (props: IndustryChoicesSelectProps) => {
  const { selectedIndustry, onChange, ...textFieldProps } = props;

  const { industries } = useGetAssignmentIndustriesAndPurposes();
  const language = useLanguage();

  const getIndustryLabel = (value?: string) => industries.find((industry) => industry.value === value)?.label[language];

  const deselectedOptions = industries.map((industry) => ({
    label: getIndustryLabel(industry.value) || industry.value,
    value: industry.value
  }));

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);

  // update options and input value after industries were loaded
  useEffect(() => {
    if (industries.length > 0) {
      setOptions(deselectedOptions);
      setInputValue(getIndustryLabel(selectedIndustry) || "");
    }
  }, [industries.length]);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegEx(value)));
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected?: string) => {
      const matchedOptionLabel = options.find((option) => option.value === selected)?.label;

      onChange(selected);
      setInputValue(matchedOptionLabel || "");
    },
    [options]
  );

  return (
    <Combobox
      activator={
        <Combobox.TextField
          onChange={updateText}
          clearButton
          onClearButtonClick={() => updateSelection(undefined)}
          value={inputValue}
          {...textFieldProps}
        />
      }
    >
      {options.length > 0 ? (
        <Listbox onSelect={updateSelection}>
          {options?.map((option) => (
            <Listbox.Option value={option.value} selected={selectedIndustry === option.value} key={option.value}>
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox>
      ) : null}
    </Combobox>
  );
};

export default IndustryChoicesSelect;
