import { CustomerDetails, isPersonCustomerDetails } from "../../../types/utilities";
import { isValidDate } from "../../../utils/dateUtils";

import useCompanyHasCriticalUpdates from "./useCompanyHasCriticalUpdates";

const useCustomerDetailsBanners = (customer: CustomerDetails) => {
  const { is_follow_up_expired, follow_up_date } = customer;

  const hasCriticalUpdates = useCompanyHasCriticalUpdates(isPersonCustomerDetails(customer) ? undefined : customer);

  const hasFollowupDateExpired = is_follow_up_expired && isValidDate(follow_up_date);

  const hasOwnershipUpdates =
    !isPersonCustomerDetails(customer) && Boolean(customer.updates.ownership && customer.updates.ownership.length > 0);

  const showBanners = hasCriticalUpdates || hasFollowupDateExpired || hasOwnershipUpdates;

  return {
    showBanners,
    hasCriticalUpdates,
    hasFollowupDateExpired,
    hasOwnershipUpdates
  };
};

export default useCustomerDetailsBanners;
