import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

import api from "../../../api";
import TableColumnHeader from "../../../components/TableColumnHeader/TableColumnHeader";
import VitecProjectProxies from "../../../components/VitecProjectProxies/VitecProjectProxies";
import { isVitecProject } from "../../../helpers/integration.helpers";
import useCustomersTableColumns from "../../../hooks/useCustomersTableColumns";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { Customer } from "../../../types/utilities";

import VitecReportUploadStatus from "./VitecReportUploadStatus";

const useProjectCustomersTableColumns = (project: api.Project) => {
  const f = useFormatMessage();
  const isVitec = isVitecProject(project);

  const renderProxies = (customer: Customer) => {
    const vitecCustomers = project.integrations?.vitec_data?.customers;
    if (!vitecCustomers) {
      return null;
    }

    const vitecCustomer = vitecCustomers.find((vc) => vc.id === customer.id);
    if (!vitecCustomer || !vitecCustomer.proxy_id) {
      return null;
    }

    const vitecProxyCustomer = vitecCustomers.find((vc) => vc.id === vitecCustomer.proxy_id);
    const projectProxyCustomer = project.customers.find((pc) => pc.id === vitecProxyCustomer?.id);

    if (!projectProxyCustomer) {
      return null;
    }

    return <VitecProjectProxies customer={projectProxyCustomer} />;
  };

  const projectCustomersColumns = useCustomersTableColumns(undefined, undefined, isVitec ? renderProxies : undefined);

  const columnHelper = createColumnHelper<Customer>();

  const vitecProjectCustomersTableColumns = [...projectCustomersColumns];

  // replace last column with report column
  vitecProjectCustomersTableColumns[projectCustomersColumns.length - 1] = columnHelper.display({
    header: () => <TableColumnHeader centered>{f("customers.list.column.report")}</TableColumnHeader>,
    id: "report",
    cell: (props) => (
      <VitecReportUploadStatus
        reportStatus={
          project.integrations?.vitec_data?.customers.find(
            (vitecCustomer) => vitecCustomer.id === props.row.original.id
          )?.report?.status
        }
      />
    )
  });

  return isVitec ? vitecProjectCustomersTableColumns : projectCustomersColumns;
};

export default useProjectCustomersTableColumns;
