import { Button } from "@shopify/polaris";

import TimeoutImage from "../../../assets/images/timeout.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import KDKEmptyState from "../KDKEmptyState/KDKEmptyState";

const ScreeningEmptyState = () => {
  const f = useFormatMessage();

  return (
    <KDKEmptyState
      header={f("screening.errors.heading")}
      description={f("screening.errors.description")}
      image={TimeoutImage}
      altImage={"Action timeout"}
    >
      <Button primary onClick={() => window.location.reload()}>
        {f("screening.errors.content")}
      </Button>
    </KDKEmptyState>
  );
};

export default ScreeningEmptyState;
