import { isValidSignatureConfiguration } from "../helpers/disclosures.helpers";
import { SignatureConfiguration } from "../types/Disclosure";
import { isEmail } from "../utils/stringUtils";

type DisclosureFormValidationResult = (isValid: boolean, hasDuplicates: boolean) => void;

const useValidateDisclosureForm = () => {
  const validateForm = (signatures: SignatureConfiguration[], onValidation?: DisclosureFormValidationResult) => {
    const hasValidSelection = signatures.every(isValidSignatureConfiguration);

    let hasValidEmail = true;
    let hasDuplicates = false;

    const emailDuplicates = new Set<string | undefined>();

    if (signatures.length > 0) {
      signatures.forEach((signature) => {
        const email = signature.email;

        if (signature.send_email) {
          if (!isEmail(email)) {
            hasValidEmail = false;
          }

          if (emailDuplicates.has(email)) {
            hasValidEmail = false;
            hasDuplicates = true;
          } else {
            if (email) {
              emailDuplicates.add(email);
            }
          }
        }
      });
    }

    const isValid = signatures.length > 0 && hasValidEmail && hasValidSelection;

    onValidation && onValidation(isValid, hasDuplicates);

    return isValid;
  };

  return validateForm;
};

export default useValidateDisclosureForm;
