import styled from "styled-components";

import Badge from "../extensions/Badge";

const MatchDetailsBadge = styled(Badge)`
  & > .Polaris-Badge {
    border-radius: var(--p-border-radius-1);
  }
`;

export default MatchDetailsBadge;
