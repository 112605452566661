import * as Sentry from "@sentry/react";

import api from "../api";
import {
  getErrorTitles,
  is4xxError,
  isBadRequestError,
  isConflictError,
  isValidationError
} from "../helpers/errors.helpers";
import { ErrorType } from "../types/utilities";
import { last } from "../utils/collectionUtils";
import { isEmptyString } from "../utils/stringUtils";
import { isNil } from "../utils/util";

import useFormatMessage from "./useFormatMessage";

type ParsedError = {
  error: ErrorType;
  generalError?: string;
  fieldsErrorsMap: Map<string, string>;
};

const useErrorParser = (customerType?: api.CustomerType) => {
  const f = useFormatMessage();

  const parseError = (error: ErrorType): ParsedError => {
    let generalError: string | undefined;
    const fieldsErrorsMap = new Map<string, string>();

    // don't try to parse the error if it doesn't exist
    if (isNil(error)) {
      return { error, generalError, fieldsErrorsMap };
    }

    if (typeof error === "string") {
      return {
        error,
        generalError: error,
        fieldsErrorsMap
      };
    }

    // Handle 422 validation errors (beaufort-api),
    if (isValidationError(error)) {
      error.data?.detail?.forEach((errorDetail: ErrorType) => {
        const field = last(errorDetail?.loc);
        const unhandledFields: string[] = [];

        switch (field) {
          case "first_name": {
            fieldsErrorsMap.set("first_name", f("common.errors.invalid.first.name"));
            break;
          }
          case "last_name": {
            fieldsErrorsMap.set("last_name", f("common.errors.invalid.last.name"));
            break;
          }
          case "birth_date": {
            fieldsErrorsMap.set("birth_date", f("common.errors.invalid.birth.date"));
            break;
          }
          case "national_id": {
            fieldsErrorsMap.set("national_id", f("common.errors.invalid.person.national-id"));
            break;
          }
          case "country_of_citizenship": {
            fieldsErrorsMap.set("country_of_citizenship", f("common.errors.invalid.citizenship"));
            break;
          }
          case "email": {
            fieldsErrorsMap.set("email", f("common.errors.invalid.email"));
            break;
          }
          case "address": {
            generalError = f("common.errors.invalid.address");
            break;
          }
          case "comment": {
            generalError = f("common.errors.invalid.comment");
            break;
          }
          default: {
            if (typeof field === "string" && !isEmptyString(errorDetail?.msg)) {
              unhandledFields.push(errorDetail?.msg);
            }
            break;
          }
        }
        if (unhandledFields.length > 0) {
          generalError = unhandledFields.join(", ");
        }
      });
      // Handle 409 errors (conflict)
    } else if (isConflictError(error)) {
      const errorDetails = error?.data?.detail;

      if (customerType === "person") {
        if (errorDetails?.includes("national_id")) {
          fieldsErrorsMap.set("national_id", f("common.errors.person-national-id.exists"));
        } else {
          generalError = f("common.errors.person.exists");
        }
      } else if (customerType === "company") {
        if (errorDetails?.includes("national_id")) {
          fieldsErrorsMap.set("national_id", f("common.errors.company-national-id.exists"));
        } else if (errorDetails?.includes("external_id")) {
          fieldsErrorsMap.set("external_id", f("common.errors.company-external-id.exists"));
        } else {
          generalError = f("common.errors.company.exists");
        }
      }
      //  Handle 400 errors (from data service)
    } else if (isBadRequestError(error)) {
      if (customerType === "company") {
        const companyOrgNumberError = error?.data?.subject?.legal_entity?.national_id;
        if (companyOrgNumberError) {
          // company org number validation in server failed
          fieldsErrorsMap.set("national_id", f("common.errors.invalid.company.national-id"));
        } else {
          generalError = f("common.errors.company.exists");
        }
      }

      if (customerType === "person") {
        const personNationalIdError = error?.data?.subject?.person?.national_id || error?.data?.person?.national_id;
        if (personNationalIdError) {
          fieldsErrorsMap.set("national_id", f("common.errors.invalid.person.national-id"));
        } else {
          generalError = f("common.errors.company.exists");
        }
      }

      if (error.data?.title && !isNil(error.data?.title)) {
        const titles = getErrorTitles(error.data.title);
        generalError = `${error.data?.detail}${titles ? `: ${titles}` : ""}`;
      }
    } else if (error instanceof Error) {
      generalError = error.message || f("common.errors.generic");
    }

    Sentry.addBreadcrumb({
      category: "front-end-error-parser",
      level: is4xxError(error?.status) ? "warning" : "error",
      message: is4xxError(error?.status) ? "Validation error" : "Server error",
      data: { error, generalError, fieldsErrorsMap }
    });

    return { error, generalError, fieldsErrorsMap };
  };

  return parseError;
};

export default useErrorParser;
