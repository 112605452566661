import React from "react";
import { useParams } from "react-router";
import { Card, SkeletonBodyText, SkeletonDisplayText, Text, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import CompanyIcon from "../../../assets/icons/company.svg";
import DocTextIcon from "../../../assets/icons/doc-text.svg";
import ProjectIcon from "../../../assets/icons/folder.svg";
import GlobeIcon from "../../../assets/icons/globe.svg";
import TagIcon from "../../../assets/icons/tag.svg";
import api from "../../api";
import AddressInfo from "../../components/AddressInfo/AddressInfo";
import CondensedText from "../../components/CondensedText/CondensedText";
import Country from "../../components/Country/Country";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import Icon from "../../components/extensions/Icon";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import useProjectPermissions from "../../hooks/useProjectPermissions";
import { IDParams } from "../../types/params";

const CompanyCardSkeleton = () => (
  <Card>
    <Card.Section>
      <VerticalStack gap="4">
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </VerticalStack>
    </Card.Section>
  </Card>
);

const CompanyDetailsCard = () => {
  const { id } = useParams<IDParams>();
  const query = useQuery([QUERIES_KEYS.COMPANY_DETAILS, id], () => api.getCompanyCustomerDetails(id));
  const f = useFormatMessage();
  const { isProjectsEnabled } = useProjectPermissions();

  if (query.error) {
    return <ErrorPanel message={query.error} actionTitle={f("company-card.errors.fetch-failed-action")} />;
  }

  if (!query.data || query.isLoading) {
    return <CompanyCardSkeleton />;
  }

  const companyCustomer = query.data;

  const companyType =
    companyCustomer.company_type && (companyCustomer.company_type.description_nb || companyCustomer.company_type.code);

  const hasProject =
    isProjectsEnabled && companyCustomer.projects?.length === 1 && companyCustomer.projects[0] !== undefined;

  const projectName = hasProject ? companyCustomer.projects![0].name : "";

  return (
    <Card>
      <Card.Section>
        <StyledGridContainer>
          <Icon source={CompanyIcon} />
          <CondensedText>{f("common.labels.name").toUpperCase()}</CondensedText>
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            {companyCustomer.name}
          </Text>

          <Icon source={GlobeIcon} />
          <CondensedText>{f("common.labels.country").toUpperCase()}</CondensedText>
          <Country country={companyCustomer.country} />

          <Icon source={DocTextIcon} />
          <CondensedText>{f("company-form.labels.national-id").toUpperCase()}</CondensedText>
          <Text variant="bodyMd" as="span">
            {companyCustomer.national_id} {companyType && ` - ${companyType}`}
          </Text>

          <Icon source={TagIcon} />
          <CondensedText>{f("common.labels.address").toUpperCase()}</CondensedText>
          <AddressInfo address={companyCustomer.business_address} />

          {hasProject && (
            <>
              <Icon source={ProjectIcon} />
              <CondensedText>{f("summary.project.title").toUpperCase()}</CondensedText>
              <Text variant="bodyMd" as="span">
                {projectName}
              </Text>
            </>
          )}
        </StyledGridContainer>
      </Card.Section>
    </Card>
  );
};

const StyledGridContainer = styled.div`
  display: grid;
  gap: var(--p-space-4);
  grid-template-columns: min-content min-content auto;
  align-items: center;
`;

export default CompanyDetailsCard;
