import React from "react";
import { useIntl } from "react-intl";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";

import DashboardContentCircle from "./DashboardContentCircle";
import DashboardNavigation from "./DashboardNavigation";
import DashboardWidget from "./DashboardWidget";
import useDashboardNavigation from "./useDashboardNavigation";

interface CustomersWithPendingTasksWidgetProps {
  customersWithPendingTasksCount: number;
  loading?: boolean;
}

const CustomersWithPendingTasksWidget = (props: CustomersWithPendingTasksWidgetProps) => {
  const { customersWithPendingTasksCount, loading } = props;

  const f = useFormatMessage();
  const { formatNumber } = useIntl();

  const { navigateToPendingTasks } = useDashboardNavigation();

  return (
    <DashboardWidget title={f("dashboard.widgets.pending-tasks.title")} loading={loading}>
      <DashboardNavigation onClick={navigateToPendingTasks} highlight>
        <StyledGradientCircle
          title={
            <Text variant="heading4xl" as="p">
              {formatNumber(customersWithPendingTasksCount)}
            </Text>
          }
        />
      </DashboardNavigation>
    </DashboardWidget>
  );
};

const StyledGradientCircle = styled(DashboardContentCircle)`
  box-shadow: var(--kdk-dashboard-widget-shadow);
  border: solid var(--p-border-width-1) var(--kdk-dashboard-dark-border);
  background: linear-gradient(var(--kdk-dashboard-dark-gradient-start), var(--kdk-dashboard-dark-gradient-end));
  color: white;
`;

export default CustomersWithPendingTasksWidget;
