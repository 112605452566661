import React, { useState } from "react";
import { ActionListItemDescriptor, ActionListSection, Spinner } from "@shopify/polaris";

import DownloadIcon from "../../../../assets/icons/arrow-down-rectangle.svg";
import EditESignaturesIcon from "../../../../assets/icons/pen-line.svg";
import DeleteIcon from "../../../../assets/icons/trash.svg";
import api from "../../../api";
import useAttachmentDownload from "../../../hooks/useAttachmentDownload";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { Entity } from "../../../types/utilities";
import { isPDFAttachment } from "../../../utils/filenameUtils";
import ActionsDropdown from "../../ActionsDropdown/ActionsDropdown";
import { hasSignedAttachment, isAttachmentSignable, isAttachmentSigned } from "../AttachmentsSection.helpers";

import DeleteAttachmentConfirmation from "./DeleteAttachmentConfirmation";

type AttachmentActionsProps = {
  entity: Entity;
  attachment: api.AttachmentDetails;
  readonly?: boolean;
  signaturesEnabled?: boolean;
  onSelectAttachment(attachment: api.AttachmentDetails): void;
};

const AttachmentActions = (props: AttachmentActionsProps) => {
  const { entity, attachment, readonly, signaturesEnabled, onSelectAttachment } = props;

  const [deleteConfirmationModalState, setDeleteConfirmationModalState] = useState({
    open: false,
    deleteSignedAttachment: false
  });

  const f = useFormatMessage();

  const closeDeleteModal = () => setDeleteConfirmationModalState({ ...deleteConfirmationModalState, open: false });

  const downloadAttachmentMutation = useAttachmentDownload(entity, attachment);

  const actions: ActionListItemDescriptor[] = [];

  const isSigned = isAttachmentSigned(attachment);

  const isSignable = isAttachmentSignable(attachment);

  const hasSignedAttachmentFile = hasSignedAttachment(attachment);

  if (signaturesEnabled && isSignable) {
    const isPDF = isPDFAttachment(attachment);

    actions.push({
      content: readonly || isSigned ? f("attachments.actions.view-signature") : f("attachments.actions.edit-signature"),
      image: EditESignaturesIcon,
      onAction: () => onSelectAttachment(attachment),
      disabled: !isPDF || downloadAttachmentMutation.isLoading,
      helpText: !isPDF ? f("attachments.actions.signature.help-text") : undefined
    });
  }

  if (isSignable) {
    actions.push({
      content: f("attachments.actions.download-attachment"),
      image: DownloadIcon,
      disabled: downloadAttachmentMutation.isLoading,
      prefix:
        downloadAttachmentMutation.isLoading && !downloadAttachmentMutation.variables?.downloadSignedAttachment ? (
          <Spinner size="small" />
        ) : null,
      onAction: () => downloadAttachmentMutation.mutate({ downloadSignedAttachment: false })
    });
  }

  actions.push({
    content: f("attachments.actions.delete-attachment"),
    image: DeleteIcon,
    onAction: () => setDeleteConfirmationModalState({ open: true, deleteSignedAttachment: false }),
    disabled: readonly || isSigned || downloadAttachmentMutation.isLoading
  });

  const signedAttachmentSection: ActionListSection = {
    title: f("attachments.actions.section.signed.document.title"),
    items: [
      {
        content: f("attachments.actions.download-signed-attachment"),
        image: DownloadIcon,
        disabled: downloadAttachmentMutation.isLoading,
        prefix:
          downloadAttachmentMutation.isLoading && downloadAttachmentMutation.variables?.downloadSignedAttachment ? (
            <Spinner size="small" />
          ) : null,
        onAction: () => downloadAttachmentMutation.mutate({ downloadSignedAttachment: true })
      },
      {
        content: f("attachments.actions.delete-signed-attachment"),
        image: DeleteIcon,
        onAction: () => setDeleteConfirmationModalState({ open: true, deleteSignedAttachment: true }),
        disabled: readonly || downloadAttachmentMutation.isLoading
      }
    ]
  };

  return (
    <>
      <ActionsDropdown
        hideTitle
        distribution="trailing"
        items={actions}
        forceOpen={downloadAttachmentMutation.isLoading}
        sections={hasSignedAttachmentFile ? [signedAttachmentSection] : undefined}
      />
      {deleteConfirmationModalState.open && (
        <DeleteAttachmentConfirmation
          entity={entity}
          attachment={attachment}
          deleteSignedAttachment={deleteConfirmationModalState.deleteSignedAttachment}
          onModalClose={closeDeleteModal}
        />
      )}
    </>
  );
};

export default AttachmentActions;
