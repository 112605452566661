import { useHistory } from "react-router-dom";

import { PAGE_ANCHORS } from "../constants/page-anchors";
import { getPendingDisclosuresCount } from "../helpers/reviews.helpers";
import { CustomerDetails } from "../types/utilities";

import useAuth from "./useAuth";
import useCustomerPath from "./useCustomerPath";
import useFeatures from "./useFeatures";
import usePageNavigation from "./usePageNavigation";

const useCustomerReviewBanner = (customer: CustomerDetails, navigateFromExternalPage?: boolean) => {
  const { navigateInPage } = usePageNavigation();
  const { getCustomerPath } = useCustomerPath();
  const { user } = useAuth();
  const history = useHistory();
  const features = useFeatures();

  const unresolvedCaseCount = customer.unresolved_case_count || 0;
  const hasUnresolvedMatchCases = unresolvedCaseCount > 0;

  const pendingDisclosuresCount = getPendingDisclosuresCount(customer, user?.id);
  const hasPendingDisclosures = pendingDisclosuresCount > 0;

  // disable new customer reviews if there are pending reviews, unresolved match cases or pending disclosures
  const isNewCustomerReviewDisabled = Boolean(
    hasUnresolvedMatchCases || (features.REVIEW__DISABLE_FOR_UNANSWERED_DISCLOSURES && hasPendingDisclosures)
  );

  const navigateToMatchesSection = () =>
    navigateFromExternalPage
      ? history.push(`${getCustomerPath(customer.id, customer.type!)}#${PAGE_ANCHORS.CUSTOMER_MATCH_CASES}`)
      : navigateInPage(PAGE_ANCHORS.CUSTOMER_MATCH_CASES);

  const navigateToDisclosuresSection = () =>
    navigateFromExternalPage
      ? history.push(`${getCustomerPath(customer.id, customer.type!)}#${PAGE_ANCHORS.DISCLOSURES_SECTION}`)
      : navigateInPage(PAGE_ANCHORS.DISCLOSURES_SECTION);

  return {
    hasUnresolvedMatchCases,
    unresolvedCaseCount,
    hasPendingDisclosures,
    pendingDisclosuresCount,
    isNewCustomerReviewDisabled,
    navigateToMatchesSection,
    navigateToDisclosuresSection
  };
};

export default useCustomerReviewBanner;
