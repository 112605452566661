import React from "react";
import { HorizontalStack, Stack, Text, VerticalStack } from "@shopify/polaris";

import { getCustomerName } from "../../helpers/display.helpers";
import { isOnboardingFinished } from "../../helpers/onboarding.helpers";
import { hasPendingTasks } from "../../helpers/pending-tasks.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import { CustomerDetails } from "../../types/utilities";
import ArchivedBadge from "../ArchivedBadge/ArchivedBadge";
import BackButton from "../BackButton/BackButton";
import CondensedText from "../CondensedText/CondensedText";
import DisplayTagsWrapper from "../DisplayTagsWrapper/DisplayTagsWrapper";
import PendingTasksBanner from "../PendingTasksBanner/PendingTasksBanner";

import BasicCustomerInfo from "./BasicCustomerInfo/BasicCustomerInfo";
import CustomerActionsMenu from "./CustomerActionsMenu/CustomerActionsMenu";
import CustomerDetailsBanners from "./CustomerDetailsBanner/CustomerDetailsBanners";
import useCustomerDetailsBanners from "./CustomerDetailsBanner/useCustomerDetailsBanners";
import CustomerTagPopover from "./CustomerTagPopover";
import useCustomerBackButton from "./useCustomerBackButton";

interface CustomerDetailsHeader {
  customer: CustomerDetails;
}

const CustomerDetailsHeader = (props: CustomerDetailsHeader) => {
  const { customer } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const { backButtonDisplayLabel, backButtonURL } = useCustomerBackButton();
  // since Polaris Stack renders gap even for empty components, we need to decide if there are any banners
  // to show and only then render CustomerDetailsBanners
  const { showBanners } = useCustomerDetailsBanners(customer);

  const hasFinishedOnboarding = isOnboardingFinished(customer);

  const readonly = customer.is_archived || !hasFinishedOnboarding;

  const isMonitoring =
    customer.screening_status.screening_status === "MONITORING" &&
    customer.screening_status.screening_stopped !== undefined;

  return (
    <VerticalStack gap="8">
      <HorizontalStack>
        <BackButton showAsLink displayLabel={backButtonDisplayLabel} url={backButtonURL} />
      </HorizontalStack>
      <VerticalStack>
        <Stack alignment="center" spacing="tight">
          <Text variant="heading2xl" as="p">
            {getCustomerName(customer)}
          </Text>
          {customer.is_archived && <ArchivedBadge customer={customer} />}
          <DisplayTagsWrapper tags={customer.labels} />
          {!readonly && <CustomerTagPopover customer={customer} />}
          <Stack.Item fill />
          {hasFinishedOnboarding && <CustomerActionsMenu customer={customer} isMonitoring={isMonitoring} />}
        </Stack>
        {customer.is_archived && customer.archived_by && customer.archiving_dt && (
          <CondensedText italic>
            {f("customer.details.archived-date", {
              name: getUserDisplayName(customer.archived_by),
              timestamp: new Date(customer.archiving_dt)
            })}
          </CondensedText>
        )}
      </VerticalStack>
      <VerticalStack gap="4">
        <BasicCustomerInfo customer={customer} screening={customer.screening_status} readonly={readonly} />
        {hasPendingTasks(customer) && <PendingTasksBanner customer={customer} />}
      </VerticalStack>
      {showBanners && <CustomerDetailsBanners customer={customer} />}
    </VerticalStack>
  );
};

export default CustomerDetailsHeader;
