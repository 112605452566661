import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import CheckMarkGreenIcon from "../../../assets/icons/checkmark-green.svg";
import CrossMarkRedIcon from "../../../assets/icons/circle-cross.svg";
import HourglassIcon from "../../../assets/icons/status-waiting.svg";
import { isPendingReview } from "../../helpers/reviews.helpers";
import useCustomerReview from "../../hooks/useCustomerReview";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Review } from "../../types/CustomerReview";
import { isNil } from "../../utils/util";
import Icon from "../extensions/Icon";

type CustomerReviewStatusProps = {
  review: Review;
};

const CustomerReviewStatus = (props: CustomerReviewStatusProps) => {
  const { review } = props;

  const f = useFormatMessage();
  const { hasUncompletedPendingMeasures } = useCustomerReview(review);

  const isPending = isPendingReview(review);
  const isReviewAcceptedOrRejected = !isNil(review.accepted);

  const statusIcon = isPending ? HourglassIcon : review.accepted ? CheckMarkGreenIcon : CrossMarkRedIcon;

  let statusLabel: string | undefined = undefined;

  if (review.accepted === true) {
    statusLabel = review.assignment
      ? f("details.reviews.assignment-review.accepted")
      : f("details.reviews.customer-review.accepted");
  } else if (review.accepted === false) {
    statusLabel = review.assignment
      ? f("details.reviews.assignment-review.rejected")
      : f("details.reviews.customer-review.rejected");
  }

  const subtitle = hasUncompletedPendingMeasures ? f("details.reviews.pending-measures") : undefined;

  return (
    <HorizontalStack gap="1" blockAlign={"baseline"}>
      {isReviewAcceptedOrRejected && (
        <Icon source={statusIcon} width={isPending ? "14px" : undefined} height={isPending ? "14px" : undefined} />
      )}
      <VerticalStack>
        <Text as="span" fontWeight={"semibold"}>
          {statusLabel}
        </Text>
        {subtitle && (
          <StyledItalicWrapper>
            <Text as="span" color="subdued">
              {subtitle}
            </Text>
          </StyledItalicWrapper>
        )}
      </VerticalStack>
    </HorizontalStack>
  );
};

const StyledItalicWrapper = styled.span`
  font-style: italic;
`;

export default CustomerReviewStatus;
