import React, { PropsWithChildren } from "react";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";

import WarningIcon from "../../../assets/icons/triangle-filled-exclamation.svg";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import Warning from "../Warning/Warning";

interface WarningWrapperProps {
  showWarning?: boolean;
  description?: string;
}

const WarningWrapperTooltip = (props: PropsWithChildren<WarningWrapperProps>) => {
  const { showWarning, description, children } = props;

  if (!showWarning) {
    return children;
  }

  return (
    <HorizontalStack gap="1" blockAlign="center">
      <TooltipWrapper content={<Warning>{description}</Warning>}>
        <Icon source={WarningIcon} />
      </TooltipWrapper>
      <VerticalStack gap="2">{children}</VerticalStack>
    </HorizontalStack>
  );
};

export default WarningWrapperTooltip;
