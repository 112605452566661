import React from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

import DashboardCustomerTypeTemplateWidget from "./DashboardCustomerTypeTemplateWidget";
import useDashboardNavigation from "./useDashboardNavigation";

interface CustomersMarkedAsSanctionedWidgetProps {
  values: api.CustomerTypeMetric;
  total: number;
  customersCount: number;
  loading?: boolean;
}

const CustomersMarkedAsSanctionedWidget = (props: CustomersMarkedAsSanctionedWidgetProps) => {
  const { values, total, customersCount, loading } = props;

  const f = useFormatMessage();

  const { navigateToSanctionedCustomers } = useDashboardNavigation();

  return (
    <DashboardCustomerTypeTemplateWidget
      title={f("dashboard.widgets.marked-as-sanctioned.title")}
      totalCount={total}
      allCustomersCount={customersCount}
      values={values}
      loading={loading}
      onNavigation={navigateToSanctionedCustomers}
    />
  );
};

export default CustomersMarkedAsSanctionedWidget;
