import React, { useMemo, useState } from "react";
import { ActionListItemDescriptor, Card, DataTable, EmptyState, HorizontalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import EditIcon from "../../../assets/icons/pen-line.svg";
import TagIcon from "../../../assets/icons/tag.svg";
import DeleteIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import ActionsDropdown from "../../components/ActionsDropdown/ActionsDropdown";
import AddButton from "../../components/AddButton/AddButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import ConfirmAction from "../../components/ConfirmAction/ConfirmAction";
import DataTableCellChild from "../../components/DataTableCellChild/DataTableCellChild";
import Icon from "../../components/extensions/Icon";
import RowHighlighter from "../../components/extensions/RowHighlighter";
import FilterContainer from "../../components/FilterContainer/FilterContainer";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import Tag from "../../components/Tag/Tag";
import TagEditModal from "../../components/TagEditModal/TagEditModal";
import TextHighlighter from "../../components/TextHighlighter/TextHighlighter";
import { SETTINGS_SEARCH_DEBOUNCE_DELAY } from "../../constants/durations";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getTagTitle } from "../../helpers/tag.helpers";
import useDebouncedState from "../../hooks/useDebouncedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useQueryData from "../../hooks/useQueryData";
import { isEmptyString } from "../../utils/stringUtils";
import { noop } from "../../utils/util";

import SettingsTagsSkeleton from "./SettingsTagsSkeleton";

const EMPTY_TAG: api.Label = {
  id: "",
  title: { nb: "", en: "" },
  is_assigned: false
};

interface SettingsTagsProps {
  tagsList: api.LabelList;
  readonly?: boolean;
}

const SettingsTags = (props: SettingsTagsProps) => {
  const { tagsList, readonly } = props;

  const [isModalOpen, toggleModalOpen] = useOpenClose();
  const [isDeleteConfirmOpen, toggleDeleteConfirm, closeDeleteConfirm] = useOpenClose();
  const [currentTag, setCurrentTag] = useState<api.Label>();
  const [queryText, setQueryText, debouncedQueryValue] = useDebouncedState("", SETTINGS_SEARCH_DEBOUNCE_DELAY);

  const { updateQueryData } = useQueryData<api.LabelList>([QUERIES_KEYS.ALL_TAGS]);
  const f = useFormatMessage();

  const tags = useMemo(() => {
    return tagsList.labels.filter(
      (tag) =>
        isEmptyString(debouncedQueryValue) ||
        getTagTitle(tag).toLocaleLowerCase().includes(debouncedQueryValue.toLocaleLowerCase())
    );
  }, [debouncedQueryValue, tagsList.labels]);

  const deleteTagMutation = useMutation(api.deleteLabel, {
    onSuccess: (response, deletedTagId) => {
      updateQueryData((tagsData) => {
        tagsData.count -= 1;
        tagsData.labels = tagsData.labels.filter((tag) => tag.id !== deletedTagId);
      });
    }
  });

  const newTag = () => {
    setCurrentTag(EMPTY_TAG);
    toggleModalOpen();
  };

  const editTag = (tag: api.Label) => {
    setCurrentTag(tag);
    toggleModalOpen();
  };

  const closeAndClearModal = () => {
    setCurrentTag(undefined);
    toggleModalOpen();
  };

  const getTagActions = (tag: api.Label): ActionListItemDescriptor[] => [
    {
      content: f("default.edit"),
      prefix: <Icon source={EditIcon} useMask />,
      disabled: readonly,
      onAction: () => (readonly ? noop : editTag(tag))
    },
    {
      content: f("default.delete"),
      prefix: <Icon source={DeleteIcon} useMask />,
      disabled: readonly || tag.is_assigned || tag.read_only,
      helpText: tag.is_assigned ? f("tags.page.assigned-tag.delete.description") : undefined,
      onAction: () => {
        if (!readonly) {
          setCurrentTag(tag);
          toggleDeleteConfirm();
        }
      }
    }
  ];

  return (
    <SettingsPage
      readonly={readonly}
      title={f("tags.page.title")}
      subtitle={f("tags.page.subtitle")}
      permissions={false}
      primaryAction={
        <AddButton onClick={newTag} disabled={readonly}>
          {f("tags.modal.title.add-tag")}
        </AddButton>
      }
    >
      <FilterContainer>
        <SearchTextField
          placeholder={f("tags.page.filter.placeholder")}
          value={queryText}
          clearButton
          labelHidden
          onChange={setQueryText}
          onClearButtonClick={() => setQueryText("")}
        />
      </FilterContainer>
      <Card>
        {tags.length === 0 && <EmptyState image="" heading={f("tags.modal.empty-state")} />}
        {tags.length > 0 && (
          <DataTable
            increasedTableDensity
            columnContentTypes={["text", "text"]}
            headings={[f("table.column.name"), f("common.buttons.actions.button").toUpperCase()]}
            rows={tags.map((tag) => [
              <RowHighlighter clickable={!readonly}>
                <StyleTagCell onClick={() => (readonly ? noop : editTag(tag))}>
                  <HorizontalStack gap="2" blockAlign="center" wrap={false}>
                    <Icon source={TagIcon} />
                    <Tag color={tag.color}>
                      <TextHighlighter searchWords={[queryText]} textToHighlight={getTagTitle(tag)} />
                    </Tag>
                  </HorizontalStack>
                </StyleTagCell>
              </RowHighlighter>,
              <DataTableCellChild width="10%">
                <ActionsDropdown items={getTagActions(tag)} hideTitle />
              </DataTableCellChild>
            ])}
          />
        )}
      </Card>
      {isModalOpen && <TagEditModal tag={currentTag!} onClose={closeAndClearModal} />}
      {isDeleteConfirmOpen && (
        <ConfirmAction
          apiCall={() => deleteTagMutation.mutateAsync(currentTag!.id)}
          title={f("tags.modal.tag.delete-tag")}
          description={f("tags.modal.tag.delete-tag.description", { name: getTagTitle(currentTag!) })}
          onNo={() => {
            closeDeleteConfirm();
            setCurrentTag(EMPTY_TAG);
          }}
          actionTitle={f("tags.modal.tag.delete-tag")}
        />
      )}
    </SettingsPage>
  );
};

const StyleTagCell = styled.div`
  line-height: 1.75rem;
`;

const SettingsTagsPage = ({ data }: AsyncPageChild<api.LabelList>) => {
  return <SettingsTags tagsList={data} />;
};

export default withAsyncPage<api.LabelList>(SettingsTagsPage, {
  queryKey: QUERIES_KEYS.ALL_TAGS,
  apiFunction: api.getAllLabels,
  paramNames: [],
  skeleton: <SettingsTagsSkeleton />
});
