import React from "react";
import { Image, Text, VerticalStack } from "@shopify/polaris";

import NoMatchImage from "../../../assets/images/no-match.svg";
import useFormatMessage from "../../hooks/useFormatMessage";

const CustomersListEmptyState = () => {
  const f = useFormatMessage();

  return (
    <VerticalStack gap="4" inlineAlign={"center"}>
      <Image height={75} alt={"No results found"} source={NoMatchImage} />
      <Text as={"h2"} variant={"headingLg"}>
        {f("customers.list.empty.title")}
      </Text>
      <Text as={"p"} alignment={"center"}>
        {f("customers.list.empty.content")}
      </Text>
    </VerticalStack>
  );
};

export default CustomersListEmptyState;
