import { Row } from "read-excel-file";

import { stripOrgId } from "../../helpers/onboarding.helpers";
import { ImportRowData } from "../../types/ImportRow";
import { uniqueId } from "../../utils/util";

export const convertRowToImportRowData = (row: Row): ImportRowData => ({
  id: uniqueId("import-row-data"),
  record: row.map((cell) => String(cell)).filter(Boolean),
  status: "INITIAL"
});

export const getImportRowValue = (row: ImportRowData, dataIndex: number) =>
  dataIndex > -1 && dataIndex < row.record.length ? row.record[dataIndex] : undefined;

export const getImportRowOrgId = (row: ImportRowData, orgIdRecordIndex: number) =>
  stripOrgId(getImportRowValue(row, orgIdRecordIndex) || "");

// required columns must be selected in the columns names and they should appear only once
export const isValidColumnsNames = (columnsNames: string[], requiredColumns: string[]) =>
  requiredColumns.every(
    (columnName) =>
      columnsNames.includes(columnName) && columnsNames.indexOf(columnName) === columnsNames.lastIndexOf(columnName)
  );
