import { memo } from "react";
import { TagProps } from "@shopify/polaris";

import api from "../../api";
import { getTagTitle } from "../../helpers/tag.helpers";
import { isEmptyString } from "../../utils/stringUtils";
import Tag from "../Tag/Tag";
import TextHighlighter from "../TextHighlighter/TextHighlighter";

type KDKTagProps = TagProps & {
  tag: api.Label;
  highlightText?: string;
  language?: api.LanguageEnum;
};

const KDKTag = (props: KDKTagProps) => {
  const { tag, highlightText, language, ...tagProps } = props;

  const { color } = tag;

  const tagTitle = getTagTitle(tag, language);

  const showTextHighlights = !isEmptyString(highlightText);

  return (
    <Tag color={color} {...tagProps}>
      {!showTextHighlights && tagTitle}
      {showTextHighlights && <TextHighlighter searchWords={[highlightText]} textToHighlight={tagTitle} />}
    </Tag>
  );
};

export default memo(KDKTag);
