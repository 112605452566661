import React, { ReactNode, useEffect, useState } from "react";
import { Filters, ResourceItem, ResourceList, ResourceListProps, Stack } from "@shopify/polaris";
import { TickMinor } from "@shopify/polaris-icons";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import { QUERY_SEARCH_DEBOUNCE_DELAY } from "../../constants/durations";
import { CUSTOMERS_PER_PAGE } from "../../constants/pagination";
import useDebounce from "../../hooks/useDebounce";
import { EntityWithId } from "../../types/utilities";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Icon from "../extensions/Icon";

type SearchParams = {
  offset: number;
  limit: number;
  q: string;
};

export interface EntitiesResourceListProps<T extends EntityWithId> extends Omit<ResourceListProps<T>, "items"> {
  getEntities(searchParams: SearchParams): Promise<T[]>;
  queryKey: string;
  allowMultiple?: boolean;
  queryPlaceholder: string;
  renderItem: (entity: T) => ReactNode;
  selectedIds: string[];
  existingEntities?: T[];
  page: number;
  resetPage(): void;
  onSelectionChange(selectedIds: string[]): void;
}

/**
 * This list should be used in conjunction with a Modal where the Modal keeps a current page state
 * and increases it when onScrolledToBottom is triggered.
 */
function EntitiesResourceList<T extends EntityWithId>(props: EntitiesResourceListProps<T>) {
  const {
    getEntities,
    queryKey,
    allowMultiple,
    queryPlaceholder,
    renderItem,
    selectedIds,
    existingEntities = [],
    page,
    resetPage,
    onSelectionChange,
    ...rest
  } = props;

  const [q, setQ] = useState("");

  const [entities, setEntities] = useState(existingEntities);

  const [queryText, setQueryText] = useState(q);

  useDebounce(() => setQ(queryText), QUERY_SEARCH_DEBOUNCE_DELAY, [queryText]);

  const searchParams = { offset: CUSTOMERS_PER_PAGE * page, q, limit: CUSTOMERS_PER_PAGE };
  const { isLoading, isError, error, data } = useQuery([queryKey, ...Object.values(searchParams)], () => {
    return getEntities(searchParams);
  });

  const existingEntitiesIds = existingEntities.map((entity) => entity.id);

  useEffect(() => {
    const hasExistingEntities = existingEntities?.length > 0;
    const entities = data || [];

    const filteredEntities = hasExistingEntities
      ? entities.filter((entity) => !existingEntitiesIds.includes(entity.id))
      : entities;

    setEntities((currentEntities) => [...currentEntities, ...filteredEntities]);
  }, [data]);

  // "All" is a value that is passed by ResourceList when selecting all values - it's disabled in this component
  // but the type for it still remains.
  const handleSelectionChange = (newSelectedIds: string[] | "All") => {
    if (newSelectedIds === "All") {
      return;
    }

    if (allowMultiple || selectedIds.length === 0) {
      onSelectionChange(newSelectedIds);
    } else {
      onSelectionChange(newSelectedIds.filter((id) => !selectedIds.includes(id)));
    }
  };

  const handleFilterQueryChange = (filterInput: string) => {
    setQueryText(filterInput);
    setEntities(existingEntities);
    resetPage();
  };

  const toggleResourceItemSelection = (entityId: string) => {
    if (selectedIds.includes(entityId)) {
      handleSelectionChange(selectedIds.filter((id) => id !== entityId));
    } else {
      handleSelectionChange([...selectedIds, entityId]);
    }
  };

  return (
    <StyledWrapper>
      <ResourceList<T>
        items={entities}
        idForItem={(entity) => entity.id}
        renderItem={(entity) => (
          <ResourceItem id={entity.id} url="" onClick={() => toggleResourceItemSelection(entity.id)}>
            <Stack alignment="center" spacing="none">
              <Stack.Item fill>{renderItem(entity)}</Stack.Item>
              {!allowMultiple && selectedIds.includes(entity.id) && <Icon source={TickMinor} color="highlight" />}
            </Stack>
          </ResourceItem>
        )}
        loading={isLoading}
        selectable={allowMultiple}
        selectedItems={selectedIds}
        onSelectionChange={handleSelectionChange}
        showHeader={false}
        isFiltered={q !== ""}
        filterControl={
          <Filters
            queryPlaceholder={queryPlaceholder}
            focused
            queryValue={queryText}
            onQueryChange={handleFilterQueryChange}
            onQueryClear={() => handleFilterQueryChange("")}
            onClearAll={() => handleFilterQueryChange("")}
            filters={[]}
          />
        }
        {...rest}
      />
      {isError && <ErrorPanel message={error} />}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;

  & .Polaris-ResourceList__FiltersWrapper {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--p-surface);
  }
`;

export default EntitiesResourceList;
