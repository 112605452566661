import React from "react";

import PencilIcon from "../../../assets/icons/pencil.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { CustomerDetails, isPersonCustomerDetails } from "../../types/utilities";
import EditCompanyCustomerInfoModal from "../EditCompanyCustomerInfoModal/EditCompanyCustomerInfoModal";
import EditPersonCustomerDetailsModal from "../EditPersonCustomerDetailsModal/EditPersonCustomerDetailsModal";
import Button, { ButtonProps } from "../extensions/Button";

interface EditCustomerDetailsButtonProps extends ButtonProps {
  customer: CustomerDetails;
  readonly?: boolean;
}
const EditCustomerDetailsButton = (props: EditCustomerDetailsButtonProps) => {
  const { customer, readonly, ...buttonProps } = props;

  const f = useFormatMessage();

  const [showEditModal, toggleEditModal, closeEditModal] = useOpenClose();

  const buttonLabel =
    customer.type === "person"
      ? f("common.buttons.actions.edit-person-info")
      : f("common.buttons.actions.edit-company-info");

  return (
    <>
      {customer.is_manual && (
        <Button
          icon={PencilIcon}
          onClick={toggleEditModal}
          disabled={readonly}
          size={"slim"}
          fullWidth={false}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
      {showEditModal && !isPersonCustomerDetails(customer) && customer.is_manual && (
        <EditCompanyCustomerInfoModal companyCustomer={customer} onClose={closeEditModal} />
      )}
      {showEditModal && isPersonCustomerDetails(customer) && (
        <EditPersonCustomerDetailsModal person={customer} onClose={closeEditModal} />
      )}
    </>
  );
};

export default EditCustomerDetailsButton;
