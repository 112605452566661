import React, { useState } from "react";
import { HorizontalStack, Spinner, Text } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { convertNotationToNote, sortByNoteDate } from "../../helpers/notes.helpers";
import useCustomerCreateNoteMutation from "../../hooks/useCustomerCreateNoteMutation";
import useCustomerDeleteNoteMutation from "../../hooks/useCustomerDeleteNoteMutation";
import useCustomerUpdateNoteMutation from "../../hooks/useCustomerUpdateNoteMutation";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Note } from "../../types/Notes";
import { CustomerDetails } from "../../types/utilities";
import ConfirmAction from "../ConfirmAction/ConfirmAction";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import NotesSection from "../NotesSection/NotesSection";

type CustomerNotesSectionProps = {
  customer: CustomerDetails;
  readonly?: boolean;
};

const CustomerNotesSection = (props: CustomerNotesSectionProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();
  const { data, isLoading, isError, error } = useQuery([QUERIES_KEYS.CUSTOMER_NOTES, customer.id], () =>
    api.listCustomerNotations(customer.id)
  );
  const createNoteMutation = useCustomerCreateNoteMutation(customer);
  const updateNoteMutation = useCustomerUpdateNoteMutation(customer);
  const deleteNoteMutation = useCustomerDeleteNoteMutation(customer);

  const [noteToDelete, setNoteToDelete] = useState<Note>();
  const clearNoteToDelete = () => setNoteToDelete(undefined);

  if (isLoading) {
    return (
      <HorizontalStack gap="2" blockAlign={"center"}>
        <Spinner size={"small"} />
        <Text as="p">{f("default.loading")}</Text>
      </HorizontalStack>
    );
  }

  if (isError) {
    return <ErrorPanel message={error} />;
  }

  const notes = data?.map(convertNotationToNote).sort(sortByNoteDate);

  const deleteNote = () => deleteNoteMutation.mutateAsync(noteToDelete!.id, { onSuccess: clearNoteToDelete });

  return (
    <>
      <NotesSection
        notes={notes}
        readonly={readonly}
        placeholder={f("customer.notes.placeholder")}
        createNoteMutation={createNoteMutation}
        onDelete={setNoteToDelete}
        onSave={(note) =>
          updateNoteMutation.mutateAsync({ noteId: note.id, comment: note.text }).then(convertNotationToNote)
        }
      />
      {noteToDelete && (
        <ConfirmAction
          apiCall={deleteNote}
          title={f("component.notes.delete.confirmation.title")}
          description={f("component.notes.delete.confirmation.description")}
          actionTitle={f("default.yes")}
          onNo={clearNoteToDelete}
          onSuccess={clearNoteToDelete}
        />
      )}
    </>
  );
};

export default CustomerNotesSection;
