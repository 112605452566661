import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Button, HorizontalStack, Text, TextField, VerticalStack } from "@shopify/polaris";

import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import { NORWEGIAN_COMPANY_NATIONAL_ID_REGEX } from "../../constants/company-details";
import { ROUTES } from "../../constants/routes";
import { stripOrgId } from "../../helpers/onboarding.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";

interface CompanyLookupFormProps {
  projectId?: string;
  projectName?: string;
}

const CompanyLookupForm = (props: CompanyLookupFormProps) => {
  const { projectId, projectName } = props;

  const history = useHistory();
  const f = useFormatMessage();

  const [value, setValue] = useState("");
  const [error, setError] = useState<string>();

  const createdFromProject = projectId && projectName ? { id: projectId, name: projectName } : undefined;

  const handleChange = (newValue: string) => {
    if (!NORWEGIAN_COMPANY_NATIONAL_ID_REGEX.test(newValue)) {
      setError(f("lookup.error.wrong.orgid"));
    } else {
      setError(undefined);
    }
    setValue(newValue);
  };

  const handleChoiceKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && value && !error) {
      event.preventDefault();
      submit();
    }
  };

  const submit = () => {
    const lookupPath = generatePath(ROUTES.COMPANY_LOOKUP_RESULT, { orgId: stripOrgId(value) });
    history.push(lookupPath, { createdFromProject });
  };

  return (
    <VerticalStack gap="4">
      <Text variant="bodyMd" as="span">
        {f("lookup.search.title")}
      </Text>
      <div onKeyDown={handleChoiceKeyDown}>
        <TextField
          id="lookup-input"
          autoFocus
          onChange={handleChange}
          value={value}
          placeholder={f("lookup.input.placeholder")}
          label={f("lookup.search.title")}
          labelHidden
          autoComplete="off"
        />
      </div>

      {error && <ErrorPanel message={error} fieldID="lookup-input" />}

      <HorizontalStack gap="4" align="end">
        <Button primary disabled={!value || Boolean(error)} onClick={submit}>
          {f("lookup.button.search")}
        </Button>
      </HorizontalStack>
    </VerticalStack>
  );
};

export default CompanyLookupForm;
