import React, { PropsWithChildren } from "react";

import PlusIcon from "../../../assets/icons/plus.svg";
import Button, { ButtonProps } from "../extensions/Button";
import Icon from "../extensions/Icon";

const AddItemButton = (props: PropsWithChildren<ButtonProps>) => {
  return <Button {...props} plain icon={<Icon source={PlusIcon} useMask />} textAlign={"left"} />;
};

export default AddItemButton;
