import api from "../../api";
import RoleTypes from "../../constants/role-types";
import { getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import CondensedText from "../CondensedText/CondensedText";

type SignedByLabelProps = {
  signature?: api.Signature;
};

const SignedByLabel = (props: SignedByLabelProps) => {
  const { signature } = props;

  const f = useFormatMessage();

  if (!signature || !signature.signed_dt) {
    return null;
  }

  const roleType = signature.role?.type ? f(RoleTypes[signature.role.type]) : undefined;

  return (
    <CondensedText italic>
      {f("common.answered.label", {
        name:
          signature.signing_user_info?.name || `${getFullName(signature.person)}${roleType ? ` (${roleType})` : ""}`,
        timestamp: new Date(signature.signed_dt)
      })}
    </CondensedText>
  );
};

export default SignedByLabel;
