import React from "react";
import { Card, DataTable, Filters, HorizontalStack, Page, SkeletonBodyText, VerticalStack } from "@shopify/polaris";

import AddButton from "../../components/AddButton/AddButton";
import Button from "../../components/extensions/Button";
import useFormatMessage from "../../hooks/useFormatMessage";
import useSettingsPage from "../../hooks/useSettingsPage";
import { noop } from "../../utils/util";

const SettingsUsersSkeleton = () => {
  const f = useFormatMessage();

  useSettingsPage();

  return (
    <Page title={f("users.page.title")} primaryAction={<AddButton disabled>{f("default.add")}</AddButton>}>
      <VerticalStack gap="4">
        <HorizontalStack>
          <Filters
            filters={[]}
            queryValue={""}
            onQueryChange={noop}
            onQueryClear={noop}
            onClearAll={noop}
            queryPlaceholder={f("users.page.filter.placeholder")}
            disabled
          />
        </HorizontalStack>
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              f("table.column.name"),
              f("table.column.email"),
              f("table.column.groups"),
              f("table.column.active")
            ]}
            rows={Array(4).fill([
              <SkeletonBodyText key={1} lines={1} />,
              <SkeletonBodyText key={2} lines={1} />,
              <SkeletonBodyText key={3} lines={1} />,
              <SkeletonBodyText key={4} lines={1} />
            ])}
          />
        </Card>
        <Button disabled>{f("users.page.filter.show-inactive-users")}</Button>
      </VerticalStack>
    </Page>
  );
};

export default SettingsUsersSkeleton;
