import { useCallback, useState } from "react";

const useOpenClose = (initialValue = false): [boolean, () => void, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((preValue) => !preValue), []);

  return [isOpen, toggle, close, open];
};

export default useOpenClose;
