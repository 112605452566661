import React, { CSSProperties } from "react";
import { VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

import { ChartBar } from "./DashboardBarChart";
import DashboardNavigation from "./DashboardNavigation";
import DashboardNumbersItem from "./DashboardNumbersItem";
import DashboardVerticalStack from "./DashboardVerticalStack";
import DashboardWidget from "./DashboardWidget";
import useDashboardNavigation from "./useDashboardNavigation";

const ReviewStatuses: api.ReviewStatus[] = ["APPROVED", "REJECTED", "REVIEW_REQUIRED", "NOT_READY"];

const STATUS_COLORS: Record<api.ReviewStatus, { start: CSSProperties["color"]; end: CSSProperties["color"] }> = {
  APPROVED: {
    start: "var(--kdk-dashboard-risk-low-gradient-start)",
    end: "var(--kdk-dashboard-risk-low-gradient-end)"
  },
  REJECTED: {
    start: "var(--kdk-dashboard-risk-high-gradient-start)",
    end: "var(--kdk-dashboard-risk-high-gradient-end)"
  },
  REVIEW_REQUIRED: {
    start: "var(--kdk-dashboard-risk-medium-gradient-start)",
    end: "var(--kdk-dashboard-risk-medium-gradient-end)"
  },
  NOT_READY: { start: "darkgray", end: "var(--kdk-dark-grey)" }
};

interface CustomersByReviewStatusWidgetProps {
  values: api.ReviewStatusMetric;
  total: number;
  loading?: boolean;
}

const CustomersByReviewStatusWidget = (props: CustomersByReviewStatusWidgetProps) => {
  const { values, total, loading } = props;

  const f = useFormatMessage();

  const { navigateToCustomersByStatus } = useDashboardNavigation();

  const ReviewStatusTitles: Record<api.ReviewStatus, string> = {
    APPROVED: f("common.labels.approved"),
    REJECTED: f("common.labels.rejected"),
    NOT_READY: f("risklabel.NOT_SET"),
    REVIEW_REQUIRED: f("onboarding.status.ready-for-review")
  };

  return (
    <DashboardWidget title={f("dashboard.widgets.status.title")} loading={loading}>
      <DashboardVerticalStack align="space-evenly">
        {ReviewStatuses.map((status) => (
          <StyledDashboardNavigation
            key={status}
            spacing={"medium"}
            onClick={() => navigateToCustomersByStatus(status)}
          >
            <VerticalStack gap="1">
              <DashboardNumbersItem
                value={values[status]}
                percent={!total ? 0 : values[status] / total}
                label={ReviewStatusTitles[status]}
              />
              <StyledHorizontalBar
                value={!total ? 0 : Math.round((values[status] / total) * 100)}
                startColor={STATUS_COLORS[status].start}
                endColor={STATUS_COLORS[status].end}
              />
            </VerticalStack>
          </StyledDashboardNavigation>
        ))}
      </DashboardVerticalStack>
    </DashboardWidget>
  );
};

const StyledDashboardNavigation = styled(DashboardNavigation)`
  padding-left: var(--p-space-4);
  padding-right: var(--p-space-2);
`;

const StyledHorizontalBar = styled.div<ChartBar>`
  width: ${({ value }) => value}%;
  border-radius: 0 6rem 6rem 0;
  box-shadow: var(--kdk-dashboard-widget-shadow);
  background: linear-gradient(90deg, ${({ startColor }) => startColor}, ${({ endColor }) => endColor});
  padding: var(--p-space-2) 0;
  transition: width var(--p-ease) var(--p-duration-500);
`;

export default CustomersByReviewStatusWidget;
