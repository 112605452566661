import { PropsWithChildren } from "react";
import { Card } from "@shopify/polaris";

import NavigationSection, { NavigationSectionProps } from "../NavigationSection/NavigationSection";

const CardNavigationSection = (props: PropsWithChildren<NavigationSectionProps>) => {
  const { children, ...navigationSectionProps } = props;

  return (
    <NavigationSection {...navigationSectionProps}>
      <Card>
        <Card.Section>{children}</Card.Section>
      </Card>
    </NavigationSection>
  );
};

export default CardNavigationSection;
