import api from "../../api";
import { DEFAULT_LANGUAGE } from "../../constants/languages";
import { NEW_QUESTIONNAIRE_PREFIX } from "../../constants/questionnaires";
import { isInvalidQuestion } from "../../helpers/questions.helpers";
import { isEmptyString } from "../../utils/stringUtils";
import { uniqueId } from "../../utils/util";

export const createNewQuestionnaire = (): api.Questionnaire => ({
  id: uniqueId(NEW_QUESTIONNAIRE_PREFIX),
  name: "",
  customer_type: "both",
  questions: [],
  is_archived: false,
  created_dt: new Date().toString(),
  version: 0
});

// this is a hack to let the QuestionEditor determine if a question is new or existing
export const isNewQuestionnaire = (questionnaire: api.Questionnaire) =>
  questionnaire.id.startsWith(NEW_QUESTIONNAIRE_PREFIX);

export const hasDuplicateQuestions = (questionnaire: api.Questionnaire) =>
  // create a set of all the questions for the current language - is the set size is different from the questions
  // size it means that there are more questions than in the Set --> there is at least one duplicate question
  new Set(questionnaire.questions.map((question) => question.text[DEFAULT_LANGUAGE])).size !==
  questionnaire.questions.length;

export const isInvalidQuestionnaire = (questionnaire: api.Questionnaire) =>
  isEmptyString(questionnaire.name) ||
  isEmptyString(questionnaire.title?.nb) ||
  questionnaire.questions.some(isInvalidQuestion) ||
  hasDuplicateQuestions(questionnaire);
