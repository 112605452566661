import React from "react";
import { Banner } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

const PossibleMatchBanner = () => {
  const f = useFormatMessage();
  return <Banner title={f("common.banner.possible.match.description")} status="warning" />;
};

export default PossibleMatchBanner;
