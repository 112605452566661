import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../../api";
import { CustomerListResponse } from "../../../client.generated";
import { QUERIES_KEYS } from "../../../constants/queries-keys";
import useGetUsers from "../../../hooks/useGetUsers";
import { Customer } from "../../../types/utilities";
import { toggleElement } from "../../../utils/collectionUtils";
import { getIndeterminateSelection } from "../../../utils/selectionUtils";

const useSetResponsibleUser = (customers: Customer[]) => {
  const customerIds = customers.map((customer) => customer.id);

  const queryClient = useQueryClient();
  const { isLoading: isLoadingUsers, users, activeUsers, error: loadingUsersError } = useGetUsers();

  const { selectedItems: selectedResponsibleUsers, partiallySelectedItems: partiallySelectedResponsibleUsers } =
    getIndeterminateSelection<Customer, api.User>(customers, (entity) => entity.responsible_users || []);

  const [responsibleUsersIds, setResponsibleUsersIds] = useState(() => selectedResponsibleUsers.map((user) => user.id));
  const [partiallySelectedResponsibleUsersIds, setPartiallySelectedResponsibleUsersIds] = useState(() =>
    partiallySelectedResponsibleUsers.map((user) => user.id)
  );

  const setResponsibleUserMutation = useMutation(api.batchSetResponsibleUsersOnCustomers, {
    onSuccess: () => {
      // TODO better update the query cache instead of invalidating it completely
      queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMERS_LIST]);
    }
  });

  /**
   * This method will either call setResponsibleUserMutation once to set all the selected users as responsible users
   * for all the given customer OR it will call setResponsibleUserMutation multiple times if there are partially selected users
   */
  const setResponsibleUsers = async () => {
    // if there are no partially selected responsible users
    // set all the selected responsible users to all the customers in a single mutation
    if (partiallySelectedResponsibleUsersIds.length === 0) {
      setResponsibleUserMutation.mutate({ customer_ids: customerIds, user_ids: responsibleUsersIds });
    } else {
      // there is at least one customer with responsible users that were partially selected
      // (which means they will not be included in the responsibleUsersIds)
      // in order to avoid removing these responsible users from the customers,
      // set customers with responsible users that are partially selected
      // in a different mutation that also includes their responsible users
      const mutations: Array<Promise<CustomerListResponse>> = [];
      const customersWithoutPartialResponsibleUsersIds: string[] = [];

      customers.forEach((customer) => {
        // if the customer has a responsible user that's in partiallySelectedResponsibleUsersIds
        // it means that responsible user should be included when setting responsible users for that customer
        const existingResponsibleUsers = customer.responsible_users || [];
        if (
          existingResponsibleUsers.find((responsible) => partiallySelectedResponsibleUsersIds.includes(responsible.id))
        ) {
          // responsible users for customer with partially selected responsible users should include
          // 1. all selected responsible users
          // 2. partially selected responsible users that were already assigned to the customer
          const combinedResponsibleUserIds = [
            ...responsibleUsersIds,
            ...existingResponsibleUsers
              .filter((user) => partiallySelectedResponsibleUsersIds.includes(user.id))
              .map((user) => user.id)
          ];

          mutations.push(
            setResponsibleUserMutation.mutateAsync({
              customer_ids: [customer.id],
              user_ids: combinedResponsibleUserIds
            })
          );
        } else {
          // the customer does not have a responsible user that's in partiallySelectedResponsibleUserIds
          // which means it could be set all the selected responsible users (without adding any previous responsible users)
          customersWithoutPartialResponsibleUsersIds.push(customer.id);
        }
      });

      // if there are customers without partial selected responsible users,
      // add another mutation to set the selected responsible users for all of them
      if (customersWithoutPartialResponsibleUsersIds.length > 0) {
        mutations.push(
          setResponsibleUserMutation.mutateAsync({
            customer_ids: customersWithoutPartialResponsibleUsersIds,
            user_ids: responsibleUsersIds
          })
        );
      }

      await Promise.all(mutations);
    }
  };

  const hasError = loadingUsersError || setResponsibleUserMutation.isError;
  const error = loadingUsersError || setResponsibleUserMutation.error;

  const areAllChecked = responsibleUsersIds.length === activeUsers.length;

  const isValid = !isLoadingUsers && responsibleUsersIds.length > 0;

  const isUpdating = setResponsibleUserMutation.isLoading;

  const handleSelectAll = () => {
    if (areAllChecked) {
      setResponsibleUsersIds([]);
      setPartiallySelectedResponsibleUsersIds([]);
    } else {
      setResponsibleUsersIds(activeUsers.map((user) => user.id));
    }
  };

  const isUserChecked = (user: api.User) =>
    partiallySelectedResponsibleUsersIds.includes(user.id) ? "indeterminate" : responsibleUsersIds.includes(user.id);

  const handleUserSelection = (user: api.User) => {
    // always remove the responsible user id from the partially selected list if any changes are made to its selection
    setPartiallySelectedResponsibleUsersIds((prevState) => prevState.filter((id) => id !== user.id));
    // if the user is active allow to toggle it on and off. if the user is not active only allow to toggle it off
    setResponsibleUsersIds((prevState) =>
      user.is_active ? toggleElement(prevState, user.id) : prevState.filter((userId) => userId !== user.id)
    );
  };

  return {
    isLoadingUsers,
    hasError,
    error,
    users,
    isValid,
    isUpdating,
    areAllChecked,
    handleSelectAll,
    isUserChecked,
    handleUserSelection,
    setResponsibleUsers
  };
};

export default useSetResponsibleUser;
