import React, { useState } from "react";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";
import OwnersCard from "../OwnersCard/OwnersCard";

import CompanyOwnersHeader from "./CompanyOwnersHeader/CompanyOwnersHeader";
import useCompanyOwners from "./useCompanyOwners";

interface CompanyOwnersProps {
  customer: api.CompanyCustomerDetailResponse;
  screeningOwners?: api.Owner[];
  readonly?: boolean;
  isScreeningActive: boolean;
  hideAddButton?: boolean;
}

const CompanyOwners = (props: CompanyOwnersProps) => {
  const { customer, screeningOwners = [], readonly, isScreeningActive, hideAddButton } = props;

  const {
    owners,
    hasArchivedOwners,
    showArchivedOwners,
    toggleArchivedOwners,
    getOwnershipUpdate,
    handleOwnerCreate,
    handleOwnerUpdate,
    handleOwnerArchive,
    handleOwnershipUpdateReject,
    handleOwnershipUpdateConfirm
  } = useCompanyOwners(customer);

  const f = useFormatMessage();
  const [isConfirmAllLoading, setIsConfirmAllLoading] = useState(false);
  const [ownershipViewType, setOwnershipViewType] = useState<"tree" | "table">("table");

  const isArchiveDisabled = (ownerId?: string) =>
    isScreeningActive && Boolean(screeningOwners.find((screeningOwner) => screeningOwner.id === ownerId));

  const screenedOwnersIds = isScreeningActive ? screeningOwners.map((owner) => owner.id!) : undefined;

  const handleConfirmAllOwnershipUpdates = (confirmingAllOwnershipUpdates: boolean) => {
    setIsConfirmAllLoading(confirmingAllOwnershipUpdates);
  };

  const showArchivedOwnersToggle = hasArchivedOwners && ownershipViewType === "table";

  return (
    <VerticalStack gap="4">
      <CompanyOwnersHeader customer={customer} onConfirmAllLoading={handleConfirmAllOwnershipUpdates} />
      <OwnersCard
        customer={customer}
        owners={owners}
        allowOwnershipTree
        readonly={readonly || isConfirmAllLoading}
        hideAddButton={hideAddButton}
        isArchiveDisabled={isArchiveDisabled}
        screenedOwnersIds={screenedOwnersIds}
        getOwnershipUpdate={getOwnershipUpdate}
        onArchive={handleOwnerArchive}
        onUpdate={handleOwnerUpdate}
        onCreate={handleOwnerCreate}
        onOwnershipUpdateReject={handleOwnershipUpdateReject}
        onOwnershipUpdateConfirm={handleOwnershipUpdateConfirm}
        onViewTypeChange={setOwnershipViewType}
      />
      {showArchivedOwnersToggle && (
        <HorizontalStack>
          <Button disclosure={showArchivedOwners ? "up" : "down"} onClick={toggleArchivedOwners}>
            {showArchivedOwners
              ? f("company.owners.hide-historical-owners")
              : f("company.owners.view-historical-owners")}
          </Button>
        </HorizontalStack>
      )}
    </VerticalStack>
  );
};

export default CompanyOwners;
