import React, { useState } from "react";
import { Card } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { getCompanyType } from "../../helpers/customer.helpers";
import { wasFoundedAfterOwnershipRegistryWasPublished } from "../../helpers/owners.helpers";
import { createNewOwner } from "../../helpers/person.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import AddItemButton from "../AddItemButton/AddItemButton";
import OwnerModal, { OwnerModalProps } from "../OwnerModal/OwnerModal";
import OwnershipView, { OwnershipViewProps } from "../OwnershipView/OwnershipView";

type OwnersCardsProps = Omit<OwnershipViewProps, "onEdit" | "onArchive"> &
  Omit<OwnerModalProps, "onClose" | "ownerData" | "isArchiveDisabled" | "customerId"> & {
    customer: api.CompanyCustomerDetailResponse;
    readonly?: boolean;
    hideAddButton?: boolean;
    isArchiveDisabled(ownerId: string): boolean;
  };

const OwnersCard = (props: OwnersCardsProps) => {
  const {
    customer,
    owners,
    readonly,
    hideAddButton,
    isArchiveDisabled,
    onArchive,
    onUpdate,
    onCreate,
    ...ownersTableProps
  } = props;

  const { can_have_shareholders } = customer;

  const f = useFormatMessage();
  const language = useLanguage();

  const [ownerData, setOwnerData] = useState<api.Owner>();

  const closeModal = () => setOwnerData(undefined);

  const companyType = getCompanyType(language, customer.company_type);

  const showOwnersTable = can_have_shareholders !== false;

  const editOwner = (owner: api.Owner) => setOwnerData(owner);

  const emptyTableLabel = customer.is_manual
    ? f("owners.card.manual.no-owners")
    : wasFoundedAfterOwnershipRegistryWasPublished(customer.founded_date)
      ? f("owners.card.newly-founded.no-owners")
      : f("owners.card.no-owners");

  const handleCreateOwner = (owner: api.Owner) => {
    onCreate(owner);
    closeModal();
  };

  const handleUpdateOwner = (owner: api.Owner) => {
    onUpdate(owner);
    closeModal();
  };

  const handleArchiveOwner = (ownerId: string) => {
    onArchive(ownerId);
    closeModal();
  };

  return (
    <>
      {!showOwnersTable && <Card.Section>{f("owners.card.cannot-have-shareholders", { companyType })}</Card.Section>}
      {showOwnersTable && (
        <>
          {owners.length === 0 && (
            <Card.Section>
              <StyledEmptyLabel>{emptyTableLabel}</StyledEmptyLabel>
            </Card.Section>
          )}
          {owners.length > 0 && (
            <OwnershipView
              customer={customer}
              owners={owners}
              readonly={readonly}
              onEdit={editOwner}
              onUpdate={handleUpdateOwner}
              onArchive={onArchive}
              {...ownersTableProps}
            />
          )}
          {!hideAddButton && (
            <Card.Section>
              <AddItemButton onClick={() => setOwnerData(createNewOwner(customer.country))} disabled={readonly}>
                {f("owners.card.buttons.add-owner")}
              </AddItemButton>
            </Card.Section>
          )}
        </>
      )}
      {ownerData && (
        <OwnerModal
          customerId={customer.id}
          ownerData={ownerData}
          isArchiveDisabled={ownerData && isArchiveDisabled(ownerData.id!)}
          onArchive={handleArchiveOwner}
          onUpdate={handleUpdateOwner}
          onCreate={handleCreateOwner}
          onClose={closeModal}
        />
      )}
    </>
  );
};

const StyledEmptyLabel = styled.p`
  white-space: pre-line;
`;

export default OwnersCard;
