import { useIntl } from "react-intl";
import { PrimitiveType } from "intl-messageformat";

import { MessageKey } from "../providers/LanguageProvider";

export default function useFormatMessage(): (
  id: MessageKey, // only accepts valid keys, not any string
  values?: Record<string, PrimitiveType>
) => string {
  const intl = useIntl();

  return (id, values) => intl.formatMessage({ id }, values);
}
