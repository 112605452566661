import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";

import useQueryData from "./useQueryData";

const useProjectCreateNoteMutation = (projectId: string) => {
  const { updateQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, projectId]);

  const createNoteMutation = useMutation<api.ProjectReview, unknown, string>(
    (comment) => api.createProjectReview(projectId, { comment }),
    {
      onSuccess: (newNote: api.ProjectReview) => {
        updateQueryData((prevData) => (prevData.reviews = [newNote, ...prevData.reviews]));
      }
    }
  );

  return createNoteMutation;
};

export default useProjectCreateNoteMutation;
