import React, { CSSProperties, useState } from "react";
import { ButtonGroup, ButtonProps, Popover, Text } from "@shopify/polaris";
import styled from "styled-components";

import CheckIcon from "../../../assets/icons/checkmark-white.svg";
import CopyIcon from "../../../assets/icons/copy.svg";
import { DURATION_SHORT_IN_MS } from "../../constants/durations";
import {
  COPY_LABEL_MAX_WIDTH_IN_PX,
  COPY_LABEL_MOBILE_MAX_WIDTH_IN_PX,
  MOBILE_BREAKPOINT
} from "../../constants/styles";
import useFormatMessage from "../../hooks/useFormatMessage";
import useTimeout from "../../hooks/useTimeout";
import { copyToClipboard } from "../../utils/browserUtils";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface CopyLabelProps extends ButtonProps {
  maxWidth?: CSSProperties["width"];
}

const CopyLabel = (props: CopyLabelProps) => {
  const { maxWidth, children, ...buttonProps } = props;

  const [isCopied, setCopied] = useState(false);
  const f = useFormatMessage();

  const content = children as string;

  const closeTooltip = () => setCopied(false);

  useTimeout(() => setCopied(false), DURATION_SHORT_IN_MS, isCopied);

  const copyLink = () => {
    copyToClipboard(content);
    setCopied(true);
  };

  return (
    <Popover
      preferredPosition="above"
      fluidContent
      active={isCopied}
      onClose={closeTooltip}
      activator={
        <StyledTrimmedButtonsWrapper maxWidth={maxWidth}>
          <ButtonGroup segmented>
            <TooltipWrapper content={f("copy-label.buttons.copy.tooltip")}>
              <Button icon={<img src={CopyIcon} />} {...buttonProps} onClick={copyLink} />
            </TooltipWrapper>
            <TooltipWrapper content={content} preferredPosition="above">
              <Button {...buttonProps} onClick={copyLink}>
                {content}
              </Button>
            </TooltipWrapper>
          </ButtonGroup>
        </StyledTrimmedButtonsWrapper>
      }
    >
      <StyledBanner>
        <Icon source={CheckIcon} />
        <Text as="span">{f("modals.copy.link.button.copied")}</Text>
      </StyledBanner>
    </Popover>
  );
};

const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  gap: var(--p-space-1);
  padding: var(--p-space-2) var(--p-space-3);
  color: var(--p-text-on-dark);
  background: var(--kdk-dark-success);
  stroke: var(--p-text-on-dark);
  fill: var(--p-text-on-dark);
`;

const StyledTrimmedButtonsWrapper = styled.div<{ maxWidth?: CSSProperties["width"] }>`
  & .Polaris-Button__Text {
    max-width: ${({ maxWidth }) => maxWidth || `${COPY_LABEL_MAX_WIDTH_IN_PX}px`};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // For small viewports - limit maximum width to 220px
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      max-width: ${COPY_LABEL_MOBILE_MAX_WIDTH_IN_PX}px;
    }
  }
`;

export default CopyLabel;
