import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";

import useQueryData from "./useQueryData";

const useQuestionnaireArchiveMutation = () => {
  const { updateQueryData } = useQueryData<api.QuestionnaireListResponse>([QUERIES_KEYS.QUESTIONNAIRES]);

  const archiveQuestionnaireMutation = useMutation<unknown, unknown, api.Questionnaire>(
    (questionnaire) => api.archiveQuestionnaire(questionnaire.id),
    {
      onSuccess: (_, questionnaire) => {
        updateQueryData((queryData) => {
          queryData.questionnaires = queryData.questionnaires.map((entry) =>
            entry.id === questionnaire.id ? { ...questionnaire, is_archived: true } : entry
          );
        });
      }
    }
  );

  return archiveQuestionnaireMutation;
};

export default useQuestionnaireArchiveMutation;
