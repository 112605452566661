import React from "react";
import { Banner, Link, Text } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../api";
import { SHARES_CLASSES_SUPPORT_PAGE } from "../../../constants/external-urls";
import { getCustomerName } from "../../../helpers/display.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";

interface SharesClassesBannerProps {
  customer: api.CompanyCustomerDetailResponse;
}

const SharesClassesBanner = (props: SharesClassesBannerProps) => {
  const { customer } = props;

  const { share_classes = [] } = customer;

  const f = useFormatMessage();

  const sharesDisplayName =
    share_classes
      .map((shareClass) => f(`common.share-class.${shareClass}`))
      .filter(Boolean)
      .join(" + ") || "-";

  return (
    <Banner status="warning" title={f("shares-classes.banner.title", { shares: sharesDisplayName })}>
      <StyledContent>{f("shares-classes.banner.description", { name: getCustomerName(customer) })}</StyledContent>
      <Link url={SHARES_CLASSES_SUPPORT_PAGE} external>
        <StyledLink>
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            {f("shares-classes.banner.url-label")}
          </Text>
        </StyledLink>
      </Link>
    </Banner>
  );
};

const StyledContent = styled.p`
  white-space: pre-wrap;
`;

const StyledLink = styled.span`
  color: var(--p-interactive);
`;

export default SharesClassesBanner;
