import useFormatMessage from "../../hooks/useFormatMessage";
import WarningBadge from "../WarningBadge/WarningBadge";

const IndustryTypeWarningBadge = () => {
  const f = useFormatMessage();

  return <WarningBadge>{f("industry-info.labels.possible-risk")}</WarningBadge>;
};

export default IndustryTypeWarningBadge;
