import React from "react";

import api from "../../../api";
import { getOwnerCountries } from "../../../helpers/owners.helpers";
import CountriesWithWarnings from "../../CountriesWithWarnings/CountriesWithWarnings";

interface OwnerCountriesProps {
  owner: api.Owner;
}

const OwnerCountries = (props: OwnerCountriesProps) => {
  const { owner } = props;

  return <CountriesWithWarnings fieldCountryMap={getOwnerCountries(owner)} />;
};

export default OwnerCountries;
