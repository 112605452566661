import React, { CSSProperties, ReactNode } from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";

import CustomDate from "../components/CustomDate/CustomDate";
import CustomerName from "../components/CustomerName/CustomerName";
import CustomerApprovalStatus from "../components/CustomerStatus/CustomerApprovalStatus";
import CustomerPendingTaskStatus from "../components/CustomerStatus/CustomerPendingTaskStatus";
import MultipleUsersInitials from "../components/MultipleUsersInitials/MultipleUsersInitials";
import PendingTasksButton from "../components/PendingTasksButton/PendingTasksButton";
import Risk from "../components/Risk/Risk";
import TableColumnHeader from "../components/TableColumnHeader/TableColumnHeader";
import TooltipWrapper from "../components/TooltipWrapper/TooltipWrapper";
import { SORT_BY_ADDED_DATE_PARAMETER, SORT_BY_NAME_PARAMETER } from "../constants/pagination";
import { MOBILE_BREAKPOINT } from "../constants/styles";
import { getCustomerName } from "../helpers/display.helpers";
import { Customer } from "../types/utilities";

import useFeatures from "./useFeatures";
import useFormatMessage from "./useFormatMessage";

const useCustomersTableColumns = (
  searchTerm?: string,
  haveOwnershipUpdates?: boolean,
  nameColumnChildren?: (customer: Customer | Customer) => ReactNode
) => {
  const f = useFormatMessage();
  const features = useFeatures();

  const columnHelper = createColumnHelper<Customer | Customer>();

  const tableColumns = [
    columnHelper.accessor((row) => getCustomerName(row).toLowerCase(), {
      header: f("customers.list.column.customer"),
      id: SORT_BY_NAME_PARAMETER,
      enableSorting: true,
      cell: (props) => {
        return (
          <HorizontalStack blockAlign={"baseline"} wrap={false}>
            <StyledCustomerApprovalStatus customer={props.row.original} />
            <StyledTruncatedWrapper maxWidth="var(--kdk-column-max-width)">
              <CustomerName customer={props.row.original} searchTerm={searchTerm}>
                {nameColumnChildren && nameColumnChildren(props.row.original)}
              </CustomerName>
            </StyledTruncatedWrapper>
          </HorizontalStack>
        );
      }
    }),
    columnHelper.accessor("review_status", {
      header: f("customers.list.column.tasks"),
      enableSorting: false,
      cell: (props) => (
        <VerticalStack gap={"2"} inlineAlign="start">
          <StyledTruncatedWrapper maxWidth="var(--kdk-column-max-width)">
            <CustomerPendingTaskStatus customer={props.row.original} haveOwnershipUpdates={haveOwnershipUpdates} />
          </StyledTruncatedWrapper>
          <PendingTasksButton customer={props.row.original} haveOwnershipUpdates={haveOwnershipUpdates} />
        </VerticalStack>
      )
    }),
    features.RESPONSIBLE_USER
      ? columnHelper.accessor("responsible_users", {
          header: () => <TableColumnHeader>{f("customers.list.column.responsible.users")}</TableColumnHeader>,
          meta: {
            label: f("customers.list.column.responsible.users")
          },
          enableSorting: false,
          cell: (props) => <MultipleUsersInitials users={props.getValue() || []} responsive />
        })
      : undefined,
    features.EXTERNAL_ID
      ? columnHelper.accessor("external_id", {
          header: f("customers.list.column.external-id"),
          enableSorting: false,
          cell: (props) => (
            <TooltipWrapper
              content={f("customers.list.external-id.tooltip", { id: props.getValue() })}
              preferredPosition="above"
            >
              <StyledTruncatedWrapper title={props.getValue()} maxWidth="7rem">
                <Text as="span" truncate>
                  {props.getValue()}
                </Text>
              </StyledTruncatedWrapper>
            </TooltipWrapper>
          )
        })
      : undefined,
    columnHelper.accessor("risk_level", {
      header: () => <StyledCenteredCell>{f("customers.list.column.risk")}</StyledCenteredCell>,
      meta: {
        label: f("customers.list.column.risk"),
        centered: true
      },
      enableSorting: false,
      cell: (props) => <Risk risk={props.row.original.risk_level} />
    }),
    columnHelper.accessor((row) => new Date(row.created!), {
      header: () => <TableColumnHeader centered>{f("customers.list.column.added")}</TableColumnHeader>,
      meta: {
        label: f("customers.list.column.added")
      },
      id: SORT_BY_ADDED_DATE_PARAMETER,
      enableSorting: true,
      sortingFn: "datetime",
      cell: (props) => (
        <HorizontalStack>
          <CustomDate date={props.row.original.created!} />
        </HorizontalStack>
      )
    })
  ].filter(Boolean) as ColumnDef<Customer | Customer>[];

  return tableColumns;
};

const StyledTruncatedWrapper = styled.div<{ maxWidth: CSSProperties["maxWidth"] }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

const StyledCenteredCell = styled.div`
  text-align: center;
`;

const StyledCustomerApprovalStatus = styled(CustomerApprovalStatus)`
  margin-left: calc(-1 * var(--p-space-4));
  padding-bottom: var(--p-space-05);

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-left: 0;
  }
`;

export default useCustomersTableColumns;
