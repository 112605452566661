import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import api, { GroupRoleKey } from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";
import { intersects } from "../utils/collectionUtils";
import { isEmptyString } from "../utils/stringUtils";

import useTranslateGroup from "./useTranslateGroup";

// set the stale time to 1 hour to avoid multiple calls to getAllUsersAndGroups when navigating
// between customers list and customer details pages
const useGetUsers = (fetchOnInit = true) => {
  const { isLoading, error, data } = useQuery([QUERIES_KEYS.USERS], () => api.getAllUsersAndGroups(), {
    enabled: fetchOnInit,
    staleTime: STALE_TIME
  });
  const getGroupName = useTranslateGroup();

  const groups = data?.groups || [];
  const groupsMap = Object.fromEntries(groups.map((group) => [group.id, getGroupName(group)]));
  const activeUsers = useMemo(() => (data?.users || []).filter((user) => user.is_active), [data?.users]);

  const getUserGroupsDisplayName = (user?: api.User) =>
    (user?.groups || [])
      .map((groupId) => groupsMap[groupId])
      .filter((groupName) => !isEmptyString(groupName))
      .join(", ");

  const isSingleUser = activeUsers.length === 1;

  const getFilteredUsers = useCallback(
    (groupsKeys?: GroupRoleKey[]) => {
      if (!groupsKeys || groupsKeys.length === 0) {
        return data?.users;
      }

      const groupsIds = groups
        .filter((group) => group.role_key && groupsKeys.includes(group.role_key))
        .map((group) => group.id);

      return data?.users.filter((user) => intersects(groupsIds, user.groups || []));
    },
    [data?.users, groups]
  );

  return {
    isLoading,
    error,
    users: data?.users || [],
    groups,
    activeUsers,
    isSingleUser,
    getUserGroupsDisplayName,
    getFilteredUsers
  };
};

export default useGetUsers;
