import React from "react";
import { HorizontalStack, List, Text, VerticalStack } from "@shopify/polaris";

import QuestionMarkIcon from "../../../assets/icons/question-mark.svg";
import TextIcon from "../../../assets/icons/text-bubble.svg";
import useTranslatedText from "../../hooks/useTranslatedText";
import { DisclosureRiskInformation } from "../../types/RiskIndicators";
import { first, hasContent } from "../../utils/collectionUtils";
import DisclosureAnswer from "../DisclosureAnswers/DisclosureAnswer";
import Icon from "../extensions/Icon";
import KDKBanner from "../KDKBanner/KDKBanner";
import SignedByLabel from "../SignedByLabel/SignedByLabel";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

type DisclosureRiskIndicatorProps = {
  disclosureRiskInformation: DisclosureRiskInformation;
};

const DisclosureRiskIndicator = (props: DisclosureRiskIndicatorProps) => {
  const { disclosureRiskInformation } = props;

  const t = useTranslatedText();

  const { signature, answer, riskIndicator } = disclosureRiskInformation;

  if (!signature || !riskIndicator) {
    return null;
  }

  const tooltip = !hasContent(riskIndicator.additional_description) ? undefined : riskIndicator.additional_description
      ?.length === 1 ? (
    t(first(riskIndicator.additional_description)!)
  ) : (
    <List spacing="extraTight">
      {riskIndicator.additional_description?.map((description, index) => (
        <List.Item key={index}>{t(description)}</List.Item>
      ))}
    </List>
  );

  return (
    <KDKBanner status="warning" hideIcon>
      <VerticalStack gap="2">
        <TooltipWrapper content={tooltip} width="wide" active={tooltip === undefined ? false : undefined}>
          <Text as="span" fontWeight="semibold">
            {t(riskIndicator.description)}
          </Text>
        </TooltipWrapper>
        <HorizontalStack gap="1" blockAlign="start" wrap={false}>
          <Icon source={QuestionMarkIcon} />
          <Text as={"span"}>{answer ? t(answer.question.text) : ""}</Text>
        </HorizontalStack>
        <HorizontalStack gap="1" blockAlign="start" wrap={false}>
          <Icon source={TextIcon} />
          <DisclosureAnswer answer={answer} />
        </HorizontalStack>
        <SignedByLabel signature={signature} />
      </VerticalStack>
    </KDKBanner>
  );
};

export default DisclosureRiskIndicator;
