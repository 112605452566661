import React from "react";
import { ActionListItemDescriptor, Spinner, Text, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import FileIcon from "../../../../assets/icons/file.svg";
import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import download from "../../../utils/downloadUtils";
import ActionsDropdown from "../../ActionsDropdown/ActionsDropdown";
import CondensedText from "../../CondensedText/CondensedText";
import Button from "../../extensions/Button";
import Icon from "../../extensions/Icon";
import TooltipWrapper from "../../TooltipWrapper/TooltipWrapper";

import { flatAllFiles, getFileName, getFilesetType } from "./FilesetActions.helpers";

interface FilesetActionsProps {
  filesets: (api.SignatureFileSet | api.UploadedDocument)[];
}

const FilesetActions = (props: FilesetActionsProps) => {
  const { filesets } = props;

  const f = useFormatMessage();

  const downloadFileMutation = useMutation<unknown, unknown, api.CustomerFile | api.File>(async (customerFile) => {
    const downloadInfo = await api.getFileDownloadUrl(customerFile.id);
    return download(downloadInfo.download_url, getFileName(customerFile));
  });

  const isLoading = (fileId: string) => downloadFileMutation.isLoading && downloadFileMutation.variables?.id == fileId;

  const allFiles = flatAllFiles(filesets);

  const items: ActionListItemDescriptor[] = allFiles.map(({ file, fileset }) => ({
    content: getFileName(file),
    helpText: f(`disclosure.fileset.document-type.${getFilesetType(fileset)}`),
    prefix: isLoading(file.id) ? <Spinner size={"small"} /> : <Icon source={FileIcon} />,
    disabled: downloadFileMutation.isLoading,
    onAction: () => downloadFileMutation.mutate(file)
  }));

  if (allFiles.length === 1) {
    const { file, fileset } = allFiles[0];

    return (
      <TooltipWrapper
        content={
          <VerticalStack gap="1">
            <Text as="span">{getFileName(file)}</Text>
            <CondensedText>{f(`disclosure.fileset.document-type.${getFilesetType(fileset)}`)}</CondensedText>
          </VerticalStack>
        }
      >
        <Button
          icon={FileIcon}
          plain
          textAlign="right"
          onClick={() => downloadFileMutation.mutate(file)}
          loading={isLoading(file.id)}
        >
          <Text as={"span"} truncate>
            {getFileName(file)}
          </Text>
        </Button>
      </TooltipWrapper>
    );
  }

  return (
    <ActionsDropdown
      items={items}
      title={f("attachments.actions.download-attachment")}
      icon={FileIcon}
      iconWidth="20px"
      iconHeight="20px"
      plain
      monochrome
      disclosure
      forceOpen={downloadFileMutation.isLoading}
    />
  );
};

export default FilesetActions;
