import React, { PropsWithChildren } from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import Badge, { BadgeProps } from "../extensions/Badge";

export const DISPLAY_BADGE_PROPS: BadgeProps = { size: "xsmall", status: "default-dark" };

type BadgesProps = {
  isPep?: boolean;
  isSanctioned?: boolean;
  hasNegativeNews?: boolean;
  isOther?: boolean;
  showHyphenIfNotSet?: boolean;
};

const Badges = (props: PropsWithChildren<BadgesProps>) => {
  const { isPep, isSanctioned, hasNegativeNews, isOther, showHyphenIfNotSet, children } = props;
  const f = useFormatMessage();

  const hasBadges = Boolean(children || isPep || isSanctioned || hasNegativeNews || isOther);

  if (!hasBadges) {
    return showHyphenIfNotSet ? <Text as="span">-</Text> : null;
  }

  return (
    <HorizontalStack gap="1">
      {children}
      {isPep && <Badge {...DISPLAY_BADGE_PROPS}>{f("badge.pep")}</Badge>}
      {isSanctioned && <Badge {...DISPLAY_BADGE_PROPS}>{f("badge.sanctioned")}</Badge>}
      {hasNegativeNews && <Badge {...DISPLAY_BADGE_PROPS}>{f("badge.negative-news")}</Badge>}
      {isOther && <Badge {...DISPLAY_BADGE_PROPS}>{f("badge.other")}</Badge>}
    </HorizontalStack>
  );
};

export default Badges;
