import React from "react";
import { ColumnContentType, DataTable } from "@shopify/polaris";

import api from "../../api";
import { isKYCSelected } from "../../helpers/disclosures.helpers";
import { getBirthDate, getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import { lowercaseFirstLetter } from "../../utils/stringUtils";
import { isEmailEnabled } from "../../utils/util";
import DisplayName from "../DisplayName/DisplayName";
import Language from "../Language/Language";

interface OnboardingSummarySignatureSectionProps {
  signatures: api.Signature[];
}

const renderSignatures = (signatures: api.Signature[]) => {
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const getEmailLabel = (signature: api.Signature) =>
    isEmailEnabled(signature) && signature.email ? signature.email : f("signature.email.not.provided");

  const getScopeLabel = (signature: api.Signature) => {
    const selectionLabels: string[] = [];

    if (signature.use_standard_disclosure) {
      selectionLabels.push(f("disclosure-request.field.use_standard_disclosure"));
    }

    if (signature.use_pep_disclosure) {
      selectionLabels.push(f("disclosure-request.field.use_pep_disclosure"));
    }

    if (signature.use_verification) {
      const verificationLabel = isKYCSelected(signature)
        ? lowercaseFirstLetter(f("disclosure-request.field.use_verification"))
        : f("disclosure-request.field.use_verification");
      selectionLabels.push(verificationLabel);
    }

    return formatMultipleNames(selectionLabels);
  };

  return signatures.map((signature) => [
    <DisplayName type="person" name={getFullName(signature.person)} birthDate={getBirthDate(signature.person)} />,
    getScopeLabel(signature),
    <Language language={signature.language} />,
    getEmailLabel(signature)
  ]);
};

const OnboardingSummarySignatureSection = (props: OnboardingSummarySignatureSectionProps) => {
  const { signatures } = props;

  const f = useFormatMessage();

  const columnContentTypes: ColumnContentType[] = ["text", "text", "text", "text"];

  const headings = [
    f("onboarding-summary.signature-table-column.name"),
    f("onboarding-summary.signature-table-column.scope"),
    f("onboarding-summary.signature-table-column.content-language"),
    f("onboarding-summary.signature-table-column.email-or-url")
  ];

  return (
    <DataTable
      columnContentTypes={columnContentTypes}
      headings={headings}
      rows={renderSignatures(signatures)}
      hoverable={false}
    />
  );
};

export default OnboardingSummarySignatureSection;
