import React from "react";

import api from "../../../api";
import { getCustomerName } from "../../../helpers/display.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { useCustomerDetailsQueryData } from "../../../hooks/useQueryData";
import { Customer } from "../../../types/utilities";
import ConfirmAction from "../../ConfirmAction/ConfirmAction";

interface CustomerArchiveConfirmationProps {
  customer: Customer;
  isMonitoring: boolean;
  onClose(): void;
}

const CustomerArchiveConfirmation = (props: CustomerArchiveConfirmationProps) => {
  const { customer, isMonitoring, onClose } = props;

  const updateQueryData = useCustomerDetailsQueryData(customer);

  const f = useFormatMessage();
  const name = getCustomerName(customer);

  const content = isMonitoring
    ? f("archive-customer.confirmation-modal.description.monitoring", { name })
    : f("archive-customer.confirmation-modal.description", { name });

  return (
    <ConfirmAction
      title={f("archive-customer.confirmation-modal.title")}
      description={content}
      apiCall={() => api.archiveCustomer(customer.id)}
      onSuccess={() => updateQueryData((queryData) => (queryData.is_archived = true))}
      onNo={onClose}
      actionTitle={f("archive-customer.confirmation-modal.labels.button.archive", { name })}
      isDestructive
    />
  );
};

export default CustomerArchiveConfirmation;
