import React, { PropsWithChildren, ReactNode } from "react";
import { Card, Text, VerticalStack } from "@shopify/polaris";

type MatchCategorySectionProps = {
  title: string;
  description?: ReactNode;
};

const MatchCategorySection = (props: PropsWithChildren<MatchCategorySectionProps>) => {
  const { title, description, children } = props;

  const isEmpty = React.Children.count(children) === 0 || React.Children.toArray(children).filter(Boolean).length === 0;

  return (
    <Card
      title={
        <VerticalStack gap="2">
          <Text as={"h3"} variant={"headingMd"} color={isEmpty ? "subdued" : undefined}>
            {title}
          </Text>
          {description}
        </VerticalStack>
      }
    >
      {children}
      {isEmpty && (
        <Card.Section>
          <Text as="span" color="subdued">
            ---
          </Text>
        </Card.Section>
      )}
    </Card>
  );
};

export default MatchCategorySection;
