import React from "react";
import { VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { convertMeasureToUpdateCustomMeasureRequest } from "../../helpers/measures.helpers";
import CompleteMeasureForm from "../CompleteMeasureForm/CompleteMeasureForm";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

import { CustomerReviewMeasureProps } from "./types";

export type CustomerReviewMeasureFormProps = CustomerReviewMeasureProps & {
  onChange(measure: api.Measure): void;
  onCancel(measure: api.Measure): void;
};

const CustomerReviewMeasureForm = (props: CustomerReviewMeasureFormProps) => {
  const { customerId, reviewId, measure, readonly, onChange, onCancel } = props;

  const saveMeasureMutation = useMutation<api.Measure, unknown, api.Measure>(
    (measure) =>
      api.updateCustomerReviewMeasure(
        customerId,
        reviewId,
        measure.id,
        convertMeasureToUpdateCustomMeasureRequest(measure)
      ),
    { onSuccess: onChange }
  );

  const save = (measure: api.Measure) => saveMeasureMutation.mutate(measure);

  return (
    <VerticalStack gap="4">
      <CompleteMeasureForm
        measure={measure}
        readonly={readonly}
        isLoading={saveMeasureMutation.isLoading}
        autoFocus
        onSave={save}
        onCancel={onCancel}
      />
      {saveMeasureMutation.isError && <ErrorPanel message={saveMeasureMutation.error} />}
    </VerticalStack>
  );
};

export default CustomerReviewMeasureForm;
