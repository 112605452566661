import { CSSProperties } from "react";
import styled from "styled-components";

export const StyledResponsiveCircle = styled.div<{ $width?: CSSProperties["width"] }>`
  border-radius: var(--p-border-radius-half);
  width: ${({ $width }) => $width || "65%"};
  position: relative;
  margin: 0 auto;

  // weird hack to create a square (with a circle in it)
  // see https://spin.atomicobject.com/2015/07/14/css-responsive-square/
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  & > :first-child {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
  }
`;
