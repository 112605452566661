import React, { useState } from "react";
import { ActionListItemDescriptor } from "@shopify/polaris";

import CopyLinkIcon from "../../../../../assets/icons/link.svg";
import EditIcon from "../../../../../assets/icons/pencil.svg";
import RemindIcon from "../../../../../assets/icons/remind.svg";
import DeleteIcon from "../../../../../assets/icons/trash.svg";
import api from "../../../../api";
import { getBirthDate, getFullName } from "../../../../helpers/display.helpers";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useSnackbar from "../../../../hooks/useSnackbar";
import { isEmailEnabled } from "../../../../utils/util";
import ActionsDropdown, { ActionsDropdownProps } from "../../../ActionsDropdown/ActionsDropdown";
import CopyLinkModal from "../../../CopyLinkModal/CopyLinkModal";
import RemindSignerModal from "../../../RemindSignerModal/RemindSignerModal";

import DeleteSignatureModal from "./DeleteSignatureModal";
import UpdateSignatureModal, { UpdateSignatureModalProps } from "./UpdateSignatureModal";

type SignatureActionsProps = Omit<ActionsDropdownProps, "items"> &
  Omit<UpdateSignatureModalProps, "onClose"> & {
    readonly?: boolean;
    onSignatureDeleted(newSignature: api.Signature): void;
  };

const SignatureActions = (props: SignatureActionsProps) => {
  const {
    customerId,
    customerType,
    signature: initialSignature,
    existingEmails,
    readonly,
    assignments,
    onSignatureUpdated,
    onSignatureDeleted,
    ...actionDropdownProps
  } = props;

  const [showEditSignatureModal, setShowEditSignatureModal] = useState(false);
  const [showRemindSignerModal, setShowRemindSignerModal] = useState(false);
  const [showCopySignatureLinkModal, setShowCopySignatureLinkModal] = useState(false);
  const [showDeleteSignatureModal, setShowDeleteSignatureModal] = useState(false);
  const [signature, setSignature] = useState<api.Signature>(initialSignature);

  const f = useFormatMessage();
  const { setSnackbar } = useSnackbar();

  const actions: ActionListItemDescriptor[] = [];

  actions.push({
    content: f("signature.actions.edit.signature"),
    image: EditIcon,
    onAction: () => setShowEditSignatureModal(true),
    disabled: readonly
  });

  if (isEmailEnabled(signature) && signature.email) {
    actions.push({
      content: f("signature.actions.remind.signer"),
      image: RemindIcon,
      onAction: () => setShowRemindSignerModal(true),
      disabled: readonly
    });
  }

  actions.push(
    {
      content: f("signature.actions.copy.link"),
      image: CopyLinkIcon,
      onAction: () => setShowCopySignatureLinkModal(true)
    },
    {
      content: f("signature.actions.delete.signature"),
      image: DeleteIcon,
      onAction: () => setShowDeleteSignatureModal(true),
      disabled: readonly
    }
  );

  return (
    <>
      <ActionsDropdown hideTitle items={actions} {...actionDropdownProps} />
      {showEditSignatureModal && (
        <UpdateSignatureModal
          customerId={customerId}
          customerType={customerType}
          signature={signature}
          existingEmails={existingEmails}
          assignments={assignments}
          onSignatureUpdated={(signature) => {
            setSignature(signature);
            onSignatureUpdated(signature);
          }}
          onClose={() => setShowEditSignatureModal(false)}
        />
      )}
      <RemindSignerModal
        customerId={customerId}
        signatureId={signature.id}
        email={signature.email!}
        name={getFullName(signature.person)}
        birthdate={getBirthDate(signature.person)}
        open={showRemindSignerModal}
        onModalClose={() => setShowRemindSignerModal(false)}
      />
      <CopyLinkModal
        title={f("signature.modal.and.tooltips.copy.link")}
        url={signature.signing_page_url}
        open={showCopySignatureLinkModal}
        onModalClose={() => setShowCopySignatureLinkModal(false)}
      />
      <DeleteSignatureModal
        customerId={customerId}
        signatureId={signature.id}
        signatureName={getFullName(signature.person)}
        open={showDeleteSignatureModal}
        onSignatureDeleted={() => {
          setSnackbar(f("signature.snackbar.deleted"));
          onSignatureDeleted(signature);
        }}
        onModalClose={() => setShowDeleteSignatureModal(false)}
      />
    </>
  );
};

export default SignatureActions;
