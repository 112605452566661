import React from "react";
import { Text, TextProps } from "@shopify/polaris";

import api from "../../api";
import useGetAssignmentIndustriesAndPurposes from "../../hooks/useGetAssignmentIndustriesAndPurposes";

type AssignmentPurposeProps = Omit<TextProps, "children" | "as"> & {
  assignment: api.Assignment | api.CustomerAssignmentDetails;
};

const AssignmentPurpose = (props: AssignmentPurposeProps) => {
  const { assignment, ...textProps } = props;

  const { getPurposeLabel } = useGetAssignmentIndustriesAndPurposes();

  return (
    <Text as="span" {...textProps}>
      {assignment.purpose ? getPurposeLabel(assignment.purpose) : assignment.purpose_desc}
    </Text>
  );
};

export default AssignmentPurpose;
