import { useEffect } from "react";

import api from "../api";
import {
  CUSTOMER_TYPE_NAME_PARAMETER,
  RESPONSIBLE_USERS_NAME_PARAMETER,
  TAGS_NAME_PARAMETER
} from "../constants/customers-list-search-params";
import {
  DASHBOARD_END_NAME_PARAMETER,
  DASHBOARD_INTERVAL_NAME_PARAMETER,
  DASHBOARD_START_NAME_PARAMETER
} from "../constants/pagination";
import { Concrete } from "../types/utilities";
import { isEmptyString } from "../utils/stringUtils";
import { removeEmptyValues } from "../utils/util";

import useAuth from "./useAuth";
import useFeatures from "./useFeatures";
import { useURLSearchParam, useURLSearchParams } from "./useURLSearchParam";

type DashboardParams = Concrete<Parameters<typeof api.getDashboard>[0]>;

const useDashboardParams = () => {
  const { user } = useAuth();
  const features = useFeatures();

  const [responsibleUserIds, setResponsibleUserIds] = useURLSearchParams(RESPONSIBLE_USERS_NAME_PARAMETER);
  const [tagIds, setTagIds] = useURLSearchParams(TAGS_NAME_PARAMETER);
  const [customerType, setCustomerType] = useURLSearchParam<api.DashboardCustomerType>(CUSTOMER_TYPE_NAME_PARAMETER);
  const [dateInterval, setDateInterval] = useURLSearchParam<api.DashboardDateInterval>(
    DASHBOARD_INTERVAL_NAME_PARAMETER
  );
  const [startDate, setStartDate] = useURLSearchParam(DASHBOARD_START_NAME_PARAMETER);
  const [endDate, setEndDate] = useURLSearchParam(DASHBOARD_END_NAME_PARAMETER);

  useEffect(() => {
    if (!user || !features.RESPONSIBLE_USER) {
      return;
    }

    // if the users is a "plain" user (without groups) and there are no responsible users ids as parameters
    // set the default responsible user id to be the current user
    const useCurrentUserId = user.groups.length === 0 && responsibleUserIds.length === 0;

    if (useCurrentUserId) {
      setResponsibleUserIds([user.id]);
    }
  }, [user, features.RESPONSIBLE_USER]);

  const dashboardParams = removeEmptyValues<DashboardParams>({
    responsibleUserIds,
    labelIds: tagIds,
    customerType: customerType,
    interval: dateInterval,
    startDate: startDate,
    endDate: endDate
  });

  // do not send "CUSTOM_DATE_RANGE" if there are no start date or end date set
  if (isEmptyString(startDate) && isEmptyString(endDate) && dateInterval === "CUSTOM_DATE_RANGE") {
    delete dashboardParams["interval"];
  }

  const isEmptySearch =
    tagIds.length === 0 &&
    responsibleUserIds.length === 0 &&
    isEmptyString(customerType) &&
    isEmptyString(dateInterval) &&
    isEmptyString(startDate) &&
    isEmptyString(endDate);

  const resetAllFilters = () => {
    setResponsibleUserIds([]);
    setTagIds([]);
    setCustomerType(undefined);
    setDateInterval(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  return {
    responsibleUserIds,
    setResponsibleUserIds,
    tagIds,
    setTagIds,
    customerType,
    setCustomerType,
    dateInterval,
    setDateInterval,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dashboardParams,
    isEmptySearch,
    resetAllFilters
  };
};

export default useDashboardParams;
