import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Country from "../Country/Country";

type AddressInfoProps = {
  address?: api.Address;
  shortCountryName?: boolean;
};

const AddressInfo = (props: AddressInfoProps) => {
  const { address, shortCountryName } = props;

  const f = useFormatMessage();

  if (!address) {
    return <>{f("not.specified")}</>;
  }

  const addressLabel = [address.address_line_1, address.address_line_2, address.postal_code, address.city]
    .filter(Boolean)
    .join(", ");

  return (
    <HorizontalStack gap="1" wrap={false}>
      {addressLabel && <Text as="span">{addressLabel}</Text>}
      <Country country={address.country} shortName={shortCountryName} />
    </HorizontalStack>
  );
};

export default AddressInfo;
