import { generatePath, useLocation } from "react-router-dom";

import { ROUTES } from "../../constants/routes";
import HistoryLocationState from "../../types/HistoryLocationState";

const useProjectDetailsBackButton = () => {
  const location = useLocation<HistoryLocationState>();

  const { fromProjectsList } = location.state || {};

  // returning undefined as a URL will cause the BackButton component to call history.goBack() instead of a regular url
  const backButtonURL = fromProjectsList ? undefined : generatePath(ROUTES.PROJECTS_LIST);

  return { backButtonURL };
};

export default useProjectDetailsBackButton;
