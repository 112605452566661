import { Dispatch, SetStateAction, useState } from "react";

import { QUERY_SEARCH_DEBOUNCE_DELAY } from "../constants/durations";

import useDebounce from "./useDebounce";

function useDebouncedState<S>(
  initialValue: S | (() => S),
  delay = QUERY_SEARCH_DEBOUNCE_DELAY
): [S, Dispatch<SetStateAction<S>>, S] {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useDebounce(() => setDebouncedValue(value), delay, [value]);

  return [value, setValue, debouncedValue];
}

export default useDebouncedState;
