import React, { useState } from "react";

import useFormatMessage from "../../hooks/useFormatMessage";
import useTags from "../../hooks/useTags";
import SearchTextField from "../SearchTextField/SearchTextField";
import { StickyWrapper } from "../StickyWrapper/StickyWrapper";
import TagsChoiceList from "../TagsChoiceList/TagsChoiceList";

interface TabsFilterProp {
  tagIds: string[];
  setTagIds(tagIds: string[]): void;
}

const TagsFilter = (props: TabsFilterProp) => {
  const { tagIds, setTagIds } = props;

  const f = useFormatMessage();

  const [filterValue, setFilterValue] = useState("");
  const { allTags, findTag } = useTags();

  // default tag contains "..." as title and tagId - if a tag is found then that tags will be displayed
  const getTagWithDefaults = (tagId: string) => Object.assign({ id: tagId, title: "..." }, findTag(tagId));

  return (
    <StickyWrapper>
      <SearchTextField label={f("tags.editor.filter-tags")} value={filterValue} onChange={setFilterValue} />
      <TagsChoiceList
        tags={allTags}
        selectedTags={tagIds.map(getTagWithDefaults)}
        titleHidden
        filterValue={filterValue}
        enableReadonlySelection
        onSelectionChange={(selectedTags) => setTagIds(selectedTags.map((tag) => tag.id))}
      />
    </StickyWrapper>
  );
};

export default TagsFilter;
