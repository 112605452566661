import React, { useCallback, useMemo, useState } from "react";
import { Autocomplete, Icon } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import { filterRegEx, isEmptyString } from "../../utils/stringUtils";
import TextHighlighter from "../TextHighlighter/TextHighlighter";

type RoleTypeSelectProps = {
  roleType: api.RoleType;
  disabled?: boolean;
  onChange(roleType: api.RoleType): void;
};

const RoleTypeSelect = (props: RoleTypeSelectProps) => {
  const { roleType, disabled, onChange } = props;

  const f = useFormatMessage();
  const language = useLanguage();

  const [inputValue, setInputValue] = useState(f(RoleTypes[roleType]));
  const [selectedOptions, setSelectedOptions] = useState<string[]>([roleType]);

  const deselectedOptions = useMemo(
    () =>
      Object.keys(RoleTypes)
        .filter((roleType) => {
          return (
            (roleType !== "null" && roleType !== "DEPUTY_BOARD_MEMBER") ||
            (roleType === "DEPUTY_BOARD_MEMBER" && roleType === "DEPUTY_BOARD_MEMBER")
          );
        })
        .map((roleType) => ({
          value: roleType,
          label: (
            <TextHighlighter
              searchWords={[inputValue]}
              textToHighlight={
                roleType === "DEPUTY_BOARD_MEMBER"
                  ? `${f(RoleTypes[roleType])} (${f(`common.roles.not.in.use`)})`
                  : f(RoleTypes[roleType as api.RoleType])
              }
            />
          )
        })),
    [language, inputValue]
  );

  const [options, setOptions] = useState(deselectedOptions);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (isEmptyString(value)) {
        setOptions(deselectedOptions);
      } else {
        const resultOptions = deselectedOptions.filter((option) =>
          f(RoleTypes[option.value as api.RoleType]).match(filterRegEx(value))
        );
        setOptions(resultOptions);
      }
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected: string[]) => {
      setSelectedOptions(selected);

      const selectedLabels = selected.map((selectedItem) => f(RoleTypes[selectedItem as api.RoleType]));
      setInputValue(selectedLabels[0] || "");

      onChange(selected[0] as api.RoleType);
    },
    [options]
  );

  return (
    <Autocomplete
      options={options}
      selected={selectedOptions}
      onSelect={updateSelection}
      allowMultiple={false}
      textField={
        <Autocomplete.TextField
          onChange={updateText}
          onBlur={() => setInputValue(f(RoleTypes[roleType]))}
          label={f("modals.role.labels.role_type")}
          value={inputValue}
          prefix={<Icon source={SearchMinor} color="base" />}
          placeholder={f("modals.role.labels.select.placeholder")}
          inputMode="search"
          autoComplete="off"
          disabled={disabled}
          selectTextOnFocus
          requiredIndicator
        />
      }
    />
  );
};

export default RoleTypeSelect;
