import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";

// arranges pairs of 2 elements (like label and value) in 2 columns
// the number of rows to display should be known in advanced
const DetailsGrid = styled.div<{ rows: number }>`
  display: grid;
  gap: var(--p-space-2) var(--p-space-4);

  grid-template-columns: repeat(2, max-content auto);
  grid-auto-flow: row dense;

  // select all the first X odd elements and move them to the first grid column
  & > *:nth-child(-2n + ${({ rows }) => rows * 2 - 1}) {
    grid-column: 1;
  }

  // select all the first X event elements and move them to the second grid column
  & > *:nth-child(-2n + ${({ rows }) => rows * 2}) {
    grid-column: 2;
  }

  // For small viewports layout in a single column
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: auto;
    gap: 0;

    & > * {
      grid-column: unset !important;
    }

    & > *:nth-child(even) {
      margin-bottom: var(--p-space-4);
    }
  }
`;

export default DetailsGrid;
