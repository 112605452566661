import React, { useState } from "react";
import { Card, RadioButton, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import useRichFormatMessage from "../../hooks/useRichFormatMessage";
import { EMPTY_CUSTOMER_NOTIFICATIONS_SETTINGS } from "../SettingsAutoRemindersPage/SettingsAutoRemindersPage";

type SettingsCustomerIntoNotificationsProps = {
  notificationsSettings: api.CustomerNotificationSettings;
  readonly?: boolean;
};

const SettingsCustomerIntoNotifications = (props: SettingsCustomerIntoNotificationsProps) => {
  const { notificationsSettings, readonly } = props;

  const f = useFormatMessage();
  const richFormat = useRichFormatMessage();
  const { isPermittedTo } = usePermissions();

  const [settings, setSettings] = useState(notificationsSettings);

  const { setQueryData } = useQueryData<api.CustomerNotificationSettings>([
    QUERIES_KEYS.CUSTOMER_NOTIFICATIONS_SETTINGS
  ]);

  const FREQUENCIES_OPTIONS = new Map<api.BasicInfoUpdateNotificationFrequency, string>([
    ["IMMEDIATELY", f("customer-notifications-settings.frequency.options.immediately")],
    ["DAILY", f("customer-notifications-settings.frequency.options.daily")],
    ["WEEKLY", f("customer-notifications-settings.frequency.options.weekly")]
  ]);

  const disabled = readonly || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const setNotificationsFrequency = (frequency: api.BasicInfoUpdateNotificationFrequency) => {
    setSettings({ ...settings, basic_info_update_notification_frequency: frequency });
  };

  return (
    <SettingsPage<api.CustomerNotificationSettings>
      readonly={readonly}
      apiCall={() => api.updateCustomerNotificationSettings(settings)}
      onSaveAfter={setQueryData}
      title={f("customer-notifications-settings.page.title")}
      subtitle={richFormat("customer-notifications-settings.description")}
    >
      <Card>
        <Card.Section>
          <VerticalStack gap="8">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {f("customer-notifications-settings.frequency.label")}
            </Text>
            <VerticalStack gap="2">
              {[...FREQUENCIES_OPTIONS].map(([frequency, label]) => (
                <RadioButton
                  label={label}
                  checked={settings.basic_info_update_notification_frequency === frequency}
                  onChange={() => setNotificationsFrequency(frequency)}
                  disabled={disabled}
                  key={frequency}
                />
              ))}
            </VerticalStack>
          </VerticalStack>
        </Card.Section>
      </Card>
    </SettingsPage>
  );
};

const SettingsCustomerIntoNotificationsPage = ({ data }: AsyncPageChild<api.CustomerNotificationSettings>) => {
  return <SettingsCustomerIntoNotifications notificationsSettings={data} />;
};

export default withAsyncPage<api.CustomerNotificationSettings>(SettingsCustomerIntoNotificationsPage, {
  queryKey: QUERIES_KEYS.CUSTOMER_NOTIFICATIONS_SETTINGS,
  apiFunction: api.getCustomerNotificationSettings,
  paramNames: [],
  skeleton: <SettingsCustomerIntoNotifications readonly notificationsSettings={EMPTY_CUSTOMER_NOTIFICATIONS_SETTINGS} />
});
