import { generatePath, matchPath, useHistory } from "react-router-dom";

import {
  ONBOARDING_COMPANY_WIZARD_ROUTES,
  ONBOARDING_MANUAL_COMPANY_WIZARD_ROUTES,
  ONBOARDING_PERSON_ROUTES
} from "../constants/routes";
import { IDParams } from "../types/params";

export type OnboardingType = "company-wizard" | "manual-company-wizard" | "person";

const useNavigationToolbar = () => {
  const history = useHistory();
  const path = history.location.pathname;

  const matchOnboardingCompanyWizard = matchPath<IDParams>(path, { path: ONBOARDING_COMPANY_WIZARD_ROUTES });
  const matchOnboardingManualCompanyWizard = matchPath<IDParams>(path, {
    path: ONBOARDING_MANUAL_COMPANY_WIZARD_ROUTES
  });
  const matchOnboardingPerson = matchPath<IDParams>(path, { path: ONBOARDING_PERSON_ROUTES });

  let onboardingType: OnboardingType | undefined;
  let onboardingStepIndex = -1;

  if (matchOnboardingPerson) {
    onboardingType = "person";
    onboardingStepIndex = 0;
  } else if (matchOnboardingManualCompanyWizard) {
    onboardingType = "manual-company-wizard";
    onboardingStepIndex = ONBOARDING_MANUAL_COMPANY_WIZARD_ROUTES.findIndex((route) => matchPath(path, route));
  } else if (matchOnboardingCompanyWizard) {
    onboardingType = "company-wizard";
    onboardingStepIndex = ONBOARDING_COMPANY_WIZARD_ROUTES.findIndex((route) => matchPath(path, route));
  }

  const goToStep = (stepIndex: number) => {
    const steps =
      onboardingType === "company-wizard"
        ? ONBOARDING_COMPANY_WIZARD_ROUTES
        : onboardingType === "manual-company-wizard"
          ? ONBOARDING_MANUAL_COMPANY_WIZARD_ROUTES
          : [];

    if (stepIndex < 0 || stepIndex >= steps.length) {
      throw new Error(`stepIndex must be between 0 and ${steps.length - 1}`);
    }

    const route = steps[stepIndex];
    const pathname = generatePath(route, { id });
    history.push(pathname);
  };

  const id =
    matchOnboardingPerson?.params?.id ||
    matchOnboardingCompanyWizard?.params.id ||
    matchOnboardingManualCompanyWizard?.params.id;

  const isManualCompany = onboardingType === "manual-company-wizard";

  return {
    id,
    onboardingType,
    isManualCompany,
    onboardingStepIndex,
    goToStep
  };
};

export default useNavigationToolbar;
