import React, { PropsWithChildren } from "react";
import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { BadgeStatus } from "../extensions/Badge";
import StatusBadge from "../StatusBadge/StatusBadge";

type MeasuresHeaderProps = {
  measures: api.Measure[];
};

const MeasuresHeader = (props: PropsWithChildren<MeasuresHeaderProps>) => {
  const { measures, children } = props;

  const f = useFormatMessage();

  const badgeStatus: BadgeStatus = measures.some((measure) => !measure.is_completed) ? "warning" : "success";

  return (
    <HorizontalStack blockAlign="center" align="space-between">
      <Text as="span" fontWeight="bold">
        <HorizontalStack gap="2" blockAlign="center">
          {f("details.reviews.measures.header")}
          {measures.length > 0 && <StyledStatusBadge status={badgeStatus}>{measures.length}</StyledStatusBadge>}
        </HorizontalStack>
      </Text>
      {children}
    </HorizontalStack>
  );
};

const StyledStatusBadge = styled(StatusBadge)`
  & .Polaris-Badge {
    border-width: var(--p-border-width-1);
  }
`;

export default MeasuresHeader;
