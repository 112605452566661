import React from "react";
import { useIntl } from "react-intl";
import { Stack, Text } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

import DashboardColorBlock from "./DashboardColorBlock";
import DashboardNavigation from "./DashboardNavigation";
import DashboardNumbersItem from "./DashboardNumbersItem";
import DashboardStack from "./DashboardStack";
import DashboardVerticalStack from "./DashboardVerticalStack";
import DashboardWidget from "./DashboardWidget";
import { StyledResponsiveCircle } from "./StyledResponsiveCircle";
import useDashboardNavigation from "./useDashboardNavigation";

interface CustomersByTypeWidgetProps {
  values: api.CustomerTypeMetric;
  customersCount: number;
  loading?: boolean;
}

const CustomersByTypeWidget = (props: CustomersByTypeWidgetProps) => {
  const { values, customersCount, loading } = props;

  const f = useFormatMessage();
  const { formatNumber } = useIntl();

  const { navigateToCustomersByType } = useDashboardNavigation();

  return (
    <DashboardWidget title={f("dashboard.widgets.active-customers.title")} loading={loading}>
      <DashboardVerticalStack>
        <StyledCenterWrapper>
          <Text variant="heading4xl" as="p">
            {formatNumber(customersCount)}
          </Text>
        </StyledCenterWrapper>
        <div style={{ flex: 1 }}>
          <DashboardStack>
            <Stack.Item fill>
              <DashboardVerticalStack align="space-evenly">
                <DashboardNavigation onClick={() => navigateToCustomersByType("companies")}>
                  <Stack distribution={"leading"} wrap={false}>
                    <DashboardColorBlock color={"var(--kdk-dashboard-customer-type-company)"} />
                    <DashboardNumbersItem
                      value={values.COMPANY}
                      percent={!customersCount ? 0 : values.COMPANY / customersCount}
                      label={f("component.filter.customer.option.company.title")}
                    />
                  </Stack>
                </DashboardNavigation>
                <DashboardNavigation onClick={() => navigateToCustomersByType("persons")}>
                  <Stack distribution={"leading"} wrap={false}>
                    <DashboardColorBlock color={"var(--kdk-dashboard-customer-type-person)"} />
                    <DashboardNumbersItem
                      value={values.PERSON}
                      percent={!customersCount ? 0 : values.PERSON / customersCount}
                      label={f("component.filter.customer.option.person.title")}
                    />
                  </Stack>
                </DashboardNavigation>
              </DashboardVerticalStack>
            </Stack.Item>
            <StyledPieChartContainer>
              <StyledPieChart $width={"6rem"} $value={!customersCount ? 0 : values.PERSON / customersCount} />
            </StyledPieChartContainer>
          </DashboardStack>
        </div>
      </DashboardVerticalStack>
    </DashboardWidget>
  );
};

const StyledPieChartContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  padding: var(--p-space-4);
`;

const StyledPieChart = styled(StyledResponsiveCircle)<{ $value: number }>`
  background: conic-gradient(
    from 0deg,
    var(--kdk-dashboard-dark-gradient-end) 0,
    var(--kdk-dashboard-customer-type-person) ${({ $value }) => Math.round($value * 100)}%,
    var(--kdk-dashboard-dark-gradient-end) 0,
    var(--kdk-dashboard-dark-gradient-start) 100%
  );
  box-shadow:
    25px -15px 20px 10px rgba(0, 0, 0, 0.75) inset,
    0px 20px 30px 20px rgba(0, 0, 0, 0.25) inset,
    var(--kdk-dashboard-widget-shadow);
`;

const StyledCenterWrapper = styled.div`
  display: grid;
  place-items: center;
  padding: var(--p-space-2);
`;

export default CustomersByTypeWidget;
