import React, { useEffect, useState } from "react";
import { ComplexAction, Modal, Stack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import useFetch from "../../hooks/useFetch";
import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

type PreviewDisclosureModalProps = {
  settings: api.DisclosureSettings;
  customerType: api.CustomerType;
  onClose(): void;
};

const PreviewDisclosureModal = (props: PreviewDisclosureModalProps) => {
  const { customerType, settings, onClose } = props;

  const language = useLanguage();
  const f = useFormatMessage();
  const fetch = useFetch();
  const [iframeSource, setIframeSource] = useState("");
  const previewDisclosureSettings = useMutation(api.previewDisclosureSettings);

  const closeAction: ComplexAction = {
    content: f("default.close"),
    onAction: onClose
  };

  useEffect(
    () =>
      previewDisclosureSettings.mutate(settings, {
        onSuccess: (data) => {
          const subjectType = customerType === "person" ? "person" : "legal_entity";
          const previewUrl = `${data.preview_url}?language=${language}&subject_type=${subjectType}`;
          fetch(previewUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data.payload)
          })
            .then((res) => res.blob())
            .then((blob) => {
              const urlObject = URL.createObjectURL(blob);
              setIframeSource(urlObject);
            });
        }
      }),
    []
  );

  return (
    <Modal
      open
      large
      onClose={onClose}
      title={f("default.preview")}
      primaryAction={closeAction}
      loading={previewDisclosureSettings.isLoading}
      iFrameName="kyc-settings-preview"
    >
      <Modal.Section flush>
        <Stack vertical alignment="fill">
          <StyledPreviewContainer>
            <iframe src={iframeSource} name="kyc-settings-preview" />
          </StyledPreviewContainer>
          {previewDisclosureSettings.isError && <ErrorPanel message={previewDisclosureSettings.error} />}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

const StyledPreviewContainer = styled.div`
  background: var(--p-surface-dark);
  display: flex;
  justify-content: center;
  padding: var(--p-space-4);

  & > iframe {
    background: var(--p-surface);
    width: var(--p-breakpoints-sm);
    min-height: 50vh;
  }
`;

export default PreviewDisclosureModal;
