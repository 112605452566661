import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import { CustomerDetails, getCustomerQueryKey } from "../types/utilities";
import { updateItem } from "../utils/collectionUtils";

import useCustomerReviewCreateNoteMutation from "./useCustomerReviewCreateNoteMutation";
import { useCustomerDetailsQueryData } from "./useQueryData";

const useCustomerReviewApproveReject = (customer: CustomerDetails, reviewId: string) => {
  const queryClient = useQueryClient();
  const updateQueryData = useCustomerDetailsQueryData(customer);
  const createNoteMutation = useCustomerReviewCreateNoteMutation(customer.id, reviewId);

  const handlePendingReviewApproval = (customerReviewApproval: api.CustomerReviewApproval, reviewId: string) => {
    updateQueryData((queryData) => {
      const review = queryData.reviews.find((review) => review.id === reviewId)!;
      queryData.reviews = updateItem(queryData.reviews, { ...review, approval: customerReviewApproval });

      if (customerReviewApproval.is_approved) {
        queryData.risk_level = review.risk_level || "NOT_SET";
        queryData.is_pep = Boolean(review.is_pep);
        queryData.is_sanctioned = Boolean(review.is_sanctioned);
      }
    });

    // invalidate the entire query in order to get the customer review status from the server
    // it might not change even though there's a customer review (for example, if there are unresolved match cases)
    queryClient.invalidateQueries(getCustomerQueryKey(customer));
  };

  const approveReviewMutation = useMutation<api.CustomerReviewApproval, unknown, string>(
    () => api.approveCustomerReview(customer.id, reviewId),
    {
      onSuccess: async (response, note) => {
        await createNoteMutation.mutateAsync(note);
        handlePendingReviewApproval(response, reviewId);
      }
    }
  );

  const rejectReviewMutation = useMutation<api.CustomerReviewApproval, unknown, string>(
    () => api.rejectCustomerReview(customer.id, reviewId),
    {
      onSuccess: async (response, note) => {
        await createNoteMutation.mutateAsync(note);
        handlePendingReviewApproval(response, reviewId);
      }
    }
  );

  return { approveReviewMutation, rejectReviewMutation };
};

export default useCustomerReviewApproveReject;
