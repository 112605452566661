import React from "react";
import { generatePath } from "react-router-dom";
import {
  ActionListItemDescriptor,
  DataTable,
  HorizontalStack,
  Link,
  Spinner,
  Text,
  VerticalStack
} from "@shopify/polaris";
import styled from "styled-components";

import ArchiveIcon from "../../../assets/icons/archivebox.svg";
import ExportIcon from "../../../assets/icons/arrow-right-offset-circle.svg";
import ChecklistIcon from "../../../assets/icons/Checklist.svg";
import CopyIcon from "../../../assets/icons/copy.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EditIcon from "../../../assets/icons/pen-line.svg";
import RestoreIcon from "../../../assets/icons/restore.svg";
import api from "../../api";
import { ROUTES } from "../../constants/routes";
import { MAIN_COLUMN_WIDTH } from "../../constants/tables";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useYesNo from "../../hooks/useYesNo";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import CondensedText from "../CondensedText/CondensedText";
import DataTableCellChild from "../DataTableCellChild/DataTableCellChild";
import Badge from "../extensions/Badge";
import Icon from "../extensions/Icon";
import RowHighlighter from "../extensions/RowHighlighter";
import ReviewChecklistConditionsLabel from "../ReviewChecklistConditionsLabel/ReviewChecklistConditionsLabel";
import TextHighlighter from "../TextHighlighter/TextHighlighter";

type ReviewChecklistsTableProps = {
  checklists: api.ReviewChecklist[];
  isRestoringChecklist?: boolean;
  queryText?: string;
  selectedChecklistId?: string;
  readonly?: boolean;
  onPreview(checklist: api.ReviewChecklist): void;
  onDuplicate(checklist: api.ReviewChecklist): void;
  onArchive(checklist: api.ReviewChecklist): void;
  onRestore(checklist: api.ReviewChecklist): void;
  onExport(checklist: api.ReviewChecklist): void;
};

const ReviewChecklistsTable = (props: ReviewChecklistsTableProps) => {
  const {
    checklists,
    isRestoringChecklist,
    queryText,
    selectedChecklistId,
    readonly,
    onPreview,
    onDuplicate,
    onArchive,
    onRestore,
    onExport
  } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const yesNo = useYesNo();

  const getCustomerTypeLabel = (checklist: api.ReviewChecklist) => {
    switch (checklist.customer_type) {
      case "both":
        return f("common.labels.both-customer-types");
      case "company":
        return f("common.labels.customer-type.company");
      case "person":
        return f("common.labels.customer-type.person");
      default:
        return "-";
    }
  };

  const getChecklistActions = (checklist: api.ReviewChecklist): ActionListItemDescriptor[] => {
    const actions = [
      {
        content: f("default.edit"),
        image: EditIcon,
        disabled: readonly,
        url: generatePath(ROUTES.SETTINGS_REVIEW_CHECKLIST_DETAILS, { id: checklist.id })
      },
      {
        content: f("default.preview"),
        image: EyeIcon,
        onAction: () => onPreview(checklist)
      },
      checklist.is_archived
        ? {
            content: f("default.restore"),
            image: RestoreIcon,
            prefix: isRestoringChecklist ? <Spinner size="small" /> : null,
            disabled: readonly,
            onAction: () => onRestore(checklist)
          }
        : {
            content: f("default.archive"),
            image: ArchiveIcon,
            disabled: readonly,
            onAction: () => onArchive(checklist)
          },
      {
        content: f("default.copy"),
        image: CopyIcon,
        onAction: () => onDuplicate(checklist)
      }
    ];

    if (features.SETTINGS__REVIEW_CHECKLISTS_IMPORT_EXPORT) {
      actions.push({
        content: "Export", // no need for translation since this action is used to move checklists between tenants
        image: ExportIcon,
        onAction: () => onExport(checklist)
      });
    }

    return actions;
  };

  return (
    <DataTable
      columnContentTypes={["text", "text", "text", "text", "text", "text"]}
      headings={[
        f("table.column.name"),
        f("table.column.customerTypes"),
        f("table.column.used"),
        f("table.column.required"),
        f("table.column.questions"),
        f("common.buttons.actions.button").toUpperCase()
      ]}
      rows={checklists.map((checklist) => [
        <DataTableCellChild width={MAIN_COLUMN_WIDTH}>
          <RowHighlighter subdued={checklist.is_archived}>
            <VerticalStack>
              <Link url={generatePath(ROUTES.SETTINGS_REVIEW_CHECKLIST_DETAILS, { id: checklist.id })} removeUnderline>
                <HorizontalStack gap="2" blockAlign="center" wrap={false}>
                  <Icon source={ChecklistIcon} />
                  <Text as="span" color={checklist.is_archived ? "subdued" : undefined}>
                    <TextHighlighter searchWords={[queryText]} textToHighlight={checklist.name} />
                  </Text>
                  {checklist.is_archived && (
                    <Badge size="xsmall" status={"new"}>
                      {f("badge.archived")}
                    </Badge>
                  )}
                </HorizontalStack>
              </Link>
              <StyledDescriptionText>{checklist.description}</StyledDescriptionText>
            </VerticalStack>
          </RowHighlighter>
        </DataTableCellChild>,
        <Text as="span" color={checklist.is_archived ? "subdued" : undefined}>
          {getCustomerTypeLabel(checklist)}
        </Text>,
        <DataTableCellChild width="25%" wrap={"nowrap"}>
          <Text as="span" color={checklist.is_archived ? "subdued" : undefined}>
            <ReviewChecklistConditionsLabel checklist={checklist} />
          </Text>
        </DataTableCellChild>,
        <Text as="span" color={checklist.is_archived ? "subdued" : undefined}>
          {yesNo(checklist.is_required)}
        </Text>,
        <StyledCenterCell width="10%">
          <Text as="span" color={checklist.is_archived ? "subdued" : undefined}>
            {checklist.questions.length}
          </Text>
        </StyledCenterCell>,
        <DataTableCellChild width="10%">
          <ActionsDropdown
            items={getChecklistActions(checklist)}
            hideTitle
            forceOpen={isRestoringChecklist && checklist.id === selectedChecklistId}
          />
        </DataTableCellChild>
      ])}
    />
  );
};

const StyledCenterCell = styled(DataTableCellChild)`
  text-align: center;
`;

const StyledDescriptionText = styled(CondensedText)`
  line-height: var(--p-font-line-height-1);
`;

export default ReviewChecklistsTable;
