import React from "react";
import { VerticalStack } from "@shopify/polaris";

import useCountries from "../../hooks/useCountries";
import { FieldCountryMap } from "../../types/RiskIndicators";
import Warning from "../Warning/Warning";

import MultipleCountries from "./MultipleCountries";

interface CountriesWithWarningsProps {
  fieldCountryMap: FieldCountryMap;
}

const CountriesWithWarnings = (props: CountriesWithWarningsProps) => {
  const { fieldCountryMap } = props;
  const { getCountryRiskDescription, isHighRiskCountry } = useCountries();

  const highRiskCountriesSet = new Set([...fieldCountryMap.values()].flat().filter(isHighRiskCountry));

  return (
    <VerticalStack gap={highRiskCountriesSet ? "2" : "0"}>
      <MultipleCountries countries={fieldCountryMap} />
      {[...highRiskCountriesSet].map((country) => (
        <Warning key={country}>{getCountryRiskDescription(country)}</Warning>
      ))}
    </VerticalStack>
  );
};

export default CountriesWithWarnings;
