import { matchPath } from "react-router-dom";

import { ROUTES } from "../../constants/routes";
import history from "../../utils/history";

export const isInExpiredPage = () => {
  return matchPath(window.location.pathname, { path: ROUTES.EXPIRED }) !== null;
};

/**
 * Redirect to /expired and set the `sessionExpiryURL` location state
 * to the current URL so that the user will be redirected back to the
 * place he/she was when the session/token expired when logging in again
 */
export const redirectToExpiredPage = () => {
  // No need to redirect to the expired page if we're already there
  if (isInExpiredPage()) {
    return;
  }

  // Redirect to the expired page
  history.push(ROUTES.EXPIRED, { sessionExpiryURL: window.location.href });
};
