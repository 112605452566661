import React, { useMemo, useState } from "react";
import { TwitterPicker } from "react-color";
import { HorizontalStack, Modal, Stack, Text, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import { DEFAULT_LANGUAGE, LANGUAGES_FLAGS } from "../../constants/languages";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { DEFAULT_TAG_COLOR, PRESET_COLORS_FOR_TAG_PICKER } from "../../constants/styles";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData from "../../hooks/useQueryData";
import { ValueOf } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import KDKTag from "../KDKTag/KDKTag";
import TranslationTextField from "../TranslationTextField/TranslationTextField";

type TagEditModalProps = {
  tag: api.Label;
  onClose(): void;
};

const TagEditModal = (props: TagEditModalProps) => {
  const { tag: initialTag, onClose } = props;

  const isNewTag = !initialTag.id;

  const f = useFormatMessage();
  const [tag, setTag] = useState({ ...initialTag });
  const { getQueryData, updateQueryData } = useQueryData<api.LabelList>([QUERIES_KEYS.ALL_TAGS]);

  const tags = getQueryData()?.labels || [];

  const tagsNames = useMemo(
    () => new Set(tags.map((tag) => (tag.id !== initialTag.id ? tag.title[DEFAULT_LANGUAGE].toLocaleLowerCase() : ""))),
    [initialTag.title[DEFAULT_LANGUAGE], tags]
  );

  const tagDescriptionLabels: Record<api.LanguageEnum, string> = {
    en: f("tags.modal.tag.name", {
      language: `${f("common.language.english")} ${LANGUAGES_FLAGS["en"]}`
    }),
    nb: f("tags.modal.tag.name", {
      language: `${f("common.language.norwegian")} ${LANGUAGES_FLAGS["nb"]}`
    })
  };

  const modalTitle = isNewTag ? f("tags.modal.title.add-tag") : f("tags.modal.title.update-tag");

  // a tag title is invalid if it is empty or already exists in the unique Set of all other tags
  const isInvalidTag =
    isEmptyString(tag.title[DEFAULT_LANGUAGE].trim()) ||
    tagsNames.has(tag.title[DEFAULT_LANGUAGE].trim().toLocaleLowerCase());

  const saveTagMutation = useMutation<api.Label, unknown, api.Label>(
    (params) => (tag.id ? api.updateLabel(tag.id, params) : api.createLabel(params)),
    {
      onSuccess: (tag) => {
        updateQueryData((tagsList) => {
          if (isNewTag) {
            tagsList.labels = [tag, ...tagsList.labels];
            tagsList.count += 1;
          } else {
            tagsList.labels = tagsList.labels.map((currentTag) => (currentTag.id === tag.id ? tag : currentTag));
          }
        });
        onClose();
      }
    }
  );

  const saveTag = () =>
    saveTagMutation.mutate({ ...tag, title: { nb: tag.title.nb.trim(), en: tag.title.en?.trim() } });

  const handleUpdate = (value: ValueOf<api.Label> | undefined, property: keyof api.Label) =>
    setTag({ ...tag, [property]: value });

  const onColorChange = (color: string) => {
    if (tag.read_only) {
      return;
    }

    if (color === DEFAULT_TAG_COLOR) {
      handleUpdate(undefined, "color");
    } else {
      handleUpdate(color, "color");
    }
  };

  const saveAction = {
    content: isNewTag ? f("default.add") : f("default.save"),
    onAction: saveTag,
    disabled: isInvalidTag || tag.read_only,
    loading: saveTagMutation.isLoading
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: onClose,
    disabled: saveTagMutation.isLoading
  };

  return (
    <Modal open large title={modalTitle} onClose={onClose} primaryAction={saveAction} secondaryActions={[cancelAction]}>
      <Modal.Section>
        <VerticalStack gap="4">
          <Stack distribution={"fillEvenly"} wrap={false}>
            <VerticalStack gap="8">
              <TranslationTextField
                getLabel={(language) => tagDescriptionLabels[language]}
                placeholder={f("tags.modal.tag.placeholder")}
                text={tag.title}
                onChange={(text) => handleUpdate(text, "title")}
                autoComplete="off"
                requiredIndicator
                autoFocus
                disabled={saveTagMutation.isLoading || tag.read_only}
                error={isInvalidTag ? f("tags.modal.tag.validation-error") : undefined}
              />
              <VerticalStack gap="1">
                <Text as="span" fontWeight="semibold">
                  {f("default.preview")}
                </Text>
                <HorizontalStack gap="4">
                  <KDKTag tag={tag} language={DEFAULT_LANGUAGE} />
                  <KDKTag tag={tag} language={"en"} />
                </HorizontalStack>
              </VerticalStack>
            </VerticalStack>
            <VerticalStack gap="1">
              <Text as="span">{f("tags.modal.tag.color")}</Text>
              <StyleColorPicker
                colors={PRESET_COLORS_FOR_TAG_PICKER}
                color={tag.color || DEFAULT_TAG_COLOR}
                triangle="hide"
                disabled={tag.read_only}
                onChange={(value) => onColorChange(value.hex)}
              />
            </VerticalStack>
          </Stack>
          {saveTagMutation.isError && <ErrorPanel message={saveTagMutation.error} />}
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};

const StyleColorPicker = styled(TwitterPicker)<{ disabled?: boolean }>`
  box-shadow: none !important;
  border: var(--p-border-width-1) solid var(--p-border-subdued);
  border-top-color: var(--p-border-shadow);
  border-radius: var(--p-border-radius-1);

  & > div {
    padding: 0 !important;
    pointer-events: ${({ disabled }) => (disabled ? "none" : undefined)};
  }
`;

export default TagEditModal;
