import React from "react";
import {
  Bleed,
  Box,
  Checkbox,
  ChoiceListProps,
  errorTextID,
  InlineError,
  RadioButton,
  VerticalStack
} from "@shopify/polaris";

import { noop, uniqueId } from "../../utils/util";

type Choice = ChoiceListProps["choices"][0];

type IndeterminateChoiceListProps = {
  // add an option to display indeterminate state in checkboxes if they are not selected
  indeterminate?: string[];
};

export default function ChoiceList({
  title,
  titleHidden,
  allowMultiple,
  choices,
  selected,
  onChange = noop,
  error,
  disabled = false,
  name: nameProp,
  indeterminate = []
}: IndeterminateChoiceListProps & ChoiceListProps) {
  const ControlComponent = allowMultiple ? Checkbox : RadioButton;

  const name = uniqueId("ChoiceList" + nameProp);
  const finalName = allowMultiple ? `${name}[]` : name;

  const titleMarkup = title ? (
    <Box as="legend" paddingBlockEnd={{ xs: "5", md: "1" }} visuallyHidden={titleHidden}>
      {title}
    </Box>
  ) : null;

  const choicesMarkup = choices.map((choice) => {
    const { value, id, label, helpText, disabled: choiceDisabled, describedByError } = choice;

    function handleChange(checked: boolean) {
      onChange(updateSelectedChoices(choice, checked, selected, allowMultiple), name);
    }

    const isSelected = choiceIsSelected(choice, selected);
    const checked = isSelected ? true : indeterminate.includes(choice.value) ? "indeterminate" : false;
    const renderedChildren = choice.renderChildren ? choice.renderChildren(isSelected) : null;
    const children = renderedChildren ? (
      <div>
        <Box paddingBlockStart={{ xs: "4", md: "0" }}>{renderedChildren}</Box>
      </div>
    ) : null;
    return (
      <li key={value}>
        <Bleed marginBlockEnd={helpText ? { xs: "1", md: "0" } : { xs: "0" }}>
          <ControlComponent
            name={finalName}
            value={value}
            id={id}
            label={label}
            disabled={choiceDisabled || disabled}
            // The union of RadioButton and Checkbox returns a checked property that is boolean only
            // But Checkbox check property can also accept "indeterminate" values
            // @ts-ignore
            checked={allowMultiple ? checked : Boolean(checked)}
            helpText={helpText}
            onChange={handleChange}
            ariaDescribedBy={error && describedByError ? errorTextID(finalName) : undefined}
          />
          {children}
        </Bleed>
      </li>
    );
  });

  const errorMarkup = error && (
    <Box paddingBlockStart={{ xs: "0", md: "1" }} paddingBlockEnd="2">
      <InlineError message={error} fieldID={finalName} />
    </Box>
  );

  return (
    <VerticalStack as="fieldset" gap={{ xs: "4", md: "0" }} aria-invalid={error != null} id={finalName}>
      {titleMarkup}
      <VerticalStack as="ul" gap={{ xs: "4", md: "0" }}>
        {choicesMarkup}
      </VerticalStack>
      {errorMarkup}
    </VerticalStack>
  );
}

function choiceIsSelected({ value }: Choice, selected: string[]) {
  return selected.includes(value);
}

function updateSelectedChoices({ value }: Choice, checked: boolean, selected: string[], allowMultiple = false) {
  if (checked) {
    return allowMultiple ? [...selected, value] : [value];
  }

  return selected.filter((selectedChoice) => selectedChoice !== value);
}
