/** this util file has a ".tsx" extension since it has functions that return object with React component in them **/

import React from "react";
import { AppliedFilterInterface, ChoiceList, FilterInterface } from "@shopify/polaris";

import { ALL_FILTER_ITEM_VALUE } from "../components/CustomersListFilters/useFiltersOptions";
import { isEmptyString } from "../utils/stringUtils";

export interface AppliedFilter extends AppliedFilterInterface {
  isTag?: boolean;
  tagColor?: string;
}

export function createFilter<T extends string>(
  key: string,
  label: string,
  value: T | undefined,
  disabled: boolean,
  choices: Array<{ label: string; value: string }>,
  onChange: (value: T | undefined) => void
): [filter: FilterInterface, appliedFilter: AppliedFilter | undefined] {
  const valueLabel = choices.find((choice) => choice.value === value)?.label || value;
  const displayLabel = `${label}${isEmptyString(value) ? "" : `: ${valueLabel}`}`;

  const filter: FilterInterface = {
    key,
    label,
    shortcut: true,
    disabled,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={label}
        titleHidden
        choices={choices}
        selected={value ? [value] : [ALL_FILTER_ITEM_VALUE]}
        onChange={(selected: T[]) => onChange(selected[0] === ALL_FILTER_ITEM_VALUE ? undefined : selected[0])}
      />
    )
  };

  const appliedFilter: AppliedFilter | undefined = isEmptyString(value)
    ? undefined
    : {
        key,
        label: displayLabel,
        onRemove: () => onChange(undefined)
      };

  return [filter, appliedFilter];
}
