import React from "react";
import { HorizontalStack, Spinner, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { getChecklistAnchorId, isChecklistEmpty } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { OptionalChecklistAnswersRequest } from "../../types/ReviewChecklist";
import KDKBanner from "../KDKBanner/KDKBanner";
import ReviewChecklistForm from "../ReviewChecklistForm/ReviewChecklistForm";
import ReviewChecklistSelect from "../ReviewChecklistSelect/ReviewChecklistSelect";

import useCustomerReviewChecklistForms from "./useCustomerReviewChecklistForms";

export type CustomerReviewChecklistFormsProps = {
  customerType: api.CustomerType;
  readonly?: boolean;
  // answers that come from a saved review
  savedChecklistsAnswers?: api.ChecklistAnswers[];
  // answers that are used for the form
  checklistsAnswers?: OptionalChecklistAnswersRequest[];
  // a list of checklists ids that were added due to changes in the customer review
  addedChecklistsIds: string[];
  // a list of checklists that were manually removed by the user
  manuallyRemovedChecklistIds: string[];
  setManuallyRemovedChecklistsIds: (manuallyRemovedChecklistIds: string[]) => void;
  onChecklistAnswersChange(checklistsAnswers: OptionalChecklistAnswersRequest[]): void;
};

const CustomerReviewChecklistForms = (props: CustomerReviewChecklistFormsProps) => {
  const {
    customerType,
    readonly,
    savedChecklistsAnswers,
    checklistsAnswers,
    addedChecklistsIds,
    manuallyRemovedChecklistIds,
    setManuallyRemovedChecklistsIds,
    onChecklistAnswersChange
  } = props;

  const f = useFormatMessage();

  const {
    loading,
    selectedChecklistsIds,
    checklistsAnswersMaps,
    getReviewChecklist,
    onSelectionChange,
    onChecklistRemove,
    onAnswerChange
  } = useCustomerReviewChecklistForms(
    savedChecklistsAnswers || [],
    checklistsAnswers || [],
    manuallyRemovedChecklistIds,
    setManuallyRemovedChecklistsIds,
    onChecklistAnswersChange
  );

  return (
    <VerticalStack gap="4">
      <HorizontalStack align={"space-between"} blockAlign={"center"}>
        <HorizontalStack gap="4" blockAlign={"center"}>
          <Text as={"h2"} variant={"headingSm"}>
            {f("customer.checklist.review.form.header")}
          </Text>
          {loading && <Spinner size="small" />}
        </HorizontalStack>
        <ReviewChecklistSelect
          checklistsIds={selectedChecklistsIds}
          customerType={customerType}
          onChange={onSelectionChange}
          shouldDisplayConfirmation={(checklist) => !isChecklistEmpty(checklistsAnswersMaps.get(checklist.id)!)}
          readonly={readonly || loading}
        />
      </HorizontalStack>
      {!loading && (
        <VerticalStack gap={"4"}>
          {checklistsAnswers?.map((checklistAnswers) => (
            <StyledKDKBanner
              hideIcon
              hideBorder
              key={checklistAnswers.checklist_id}
              id={getChecklistAnchorId(checklistAnswers.checklist_id)}
              status={addedChecklistsIds.includes(checklistAnswers.checklist_id) ? "info" : undefined}
            >
              <ReviewChecklistForm
                checklist={getReviewChecklist(checklistAnswers)!}
                answers={checklistsAnswersMaps.get(checklistAnswers.checklist_id)!}
                onChange={(questionId: string, answer: boolean, text?: string) =>
                  onAnswerChange(checklistAnswers, questionId, answer, text)
                }
                onRemove={() => onChecklistRemove(checklistAnswers.checklist_id)}
                readonly={readonly}
              />
            </StyledKDKBanner>
          ))}
        </VerticalStack>
      )}
    </VerticalStack>
  );
};

const StyledKDKBanner = styled(KDKBanner)`
  scroll-margin-top: calc(var(--kdk-toolbar-height) + var(--kdk-message-offset) + var(--p-space-4));
`;

export default CustomerReviewChecklistForms;
