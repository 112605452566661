import {
  ARCHIVED_NAME_PARAMETER,
  CUSTOMER_FILTER_STATUS_PARAMS,
  CUSTOMER_FILTER_TYPE_PARAMS,
  FOLLOW_UP_EXPIRED_NAME_PARAMETER,
  OWNERSHIP_UPDATES_NAME_PARAMETER,
  PENDING_MEASURES_NAME_PARAMETER,
  UNRESOLVED_NAME_PARAMETER
} from "../../constants/customers-list-search-params";
import { MARKED_AS_FILTERS, SCREENING_TYPE_FILTERS } from "../../constants/filters";
import useFormatMessage from "../../hooks/useFormatMessage";

// this value indicates that when selected, the filter value should be set to undefined
export const ALL_FILTER_ITEM_VALUE = "__clear__";

const useFiltersOptions = () => {
  const f = useFormatMessage();

  const ALL_ITEMS_FILTER_ITEM = {
    label: f("component.filters.all.title"),
    value: ALL_FILTER_ITEM_VALUE,
    disabled: false
  };

  const CUSTOMER_STATUS_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filters.status.started"), value: CUSTOMER_FILTER_STATUS_PARAMS.STARTED },
    { label: f("component.filters.status.not-ready"), value: CUSTOMER_FILTER_STATUS_PARAMS.NOT_READY },
    { label: f("component.filters.status.review-required"), value: CUSTOMER_FILTER_STATUS_PARAMS.REVIEW_REQUIRED },
    { label: f("component.filters.status.draft-review"), value: CUSTOMER_FILTER_STATUS_PARAMS.DRAFT_REVIEW },
    {
      label: f("component.filters.status.review-approval-required"),
      value: CUSTOMER_FILTER_STATUS_PARAMS.REVIEW_APPROVAL_REQUIRED
    },
    { label: f("component.filters.status.approved"), value: CUSTOMER_FILTER_STATUS_PARAMS.APPROVED },
    { label: f("component.filters.status.rejected"), value: CUSTOMER_FILTER_STATUS_PARAMS.REJECTED }
  ];

  const CUSTOMER_TYPE_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filter.customer.option.company.title"), value: CUSTOMER_FILTER_TYPE_PARAMS.COMPANY },
    { label: f("component.filter.customer.option.person.title"), value: CUSTOMER_FILTER_TYPE_PARAMS.PERSON }
  ];

  const RISK_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("risklabel.LOW"), value: "LOW" },
    { label: f("risklabel.MEDIUM"), value: "MEDIUM" },
    { label: f("risklabel.HIGH"), value: "HIGH" }
  ];

  const UNRESOLVED_MATCHES_OPTIONS = [
    { label: f("component.filters.match.option.open.matches"), value: UNRESOLVED_NAME_PARAMETER }
  ];

  const PEP_SANCTION_FILTERS = [
    { label: f("component.filters.marked-as.option.pep"), value: MARKED_AS_FILTERS.PEP },
    { label: f("component.filters.marked-as.option.sanction"), value: MARKED_AS_FILTERS.SANCTIONED }
  ];

  const ARCHIVE_OPTIONS = [
    { label: f("component.filters.archive.option.include-archived-customers"), value: ARCHIVED_NAME_PARAMETER }
  ];

  const FOLLOWUP_EXPIRED_OPTIONS = [
    { label: f("component.filters.followup.option.expired"), value: FOLLOW_UP_EXPIRED_NAME_PARAMETER }
  ];

  const PENDING_MEASURES_OPTIONS = [
    { label: f("component.filters.pending-measures.option"), value: PENDING_MEASURES_NAME_PARAMETER }
  ];

  const OWNERSHIP_UPDATES_OPTIONS = [
    { label: f("component.filters.ownership-updates.label"), value: OWNERSHIP_UPDATES_NAME_PARAMETER }
  ];

  const SCREENING_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filters.screening.option.monitoring"), value: SCREENING_TYPE_FILTERS.MONITORING },
    { label: f("component.filters.screening.option.one-time"), value: SCREENING_TYPE_FILTERS.ONE_TIME }
  ];

  const PROJECT_STATUS_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filters.project.option.active"), value: "ACTIVE" },
    { label: f("component.filters.project.option.inactive"), value: "INACTIVE" }
  ];

  return {
    customerStatus: CUSTOMER_STATUS_OPTIONS,
    customerType: CUSTOMER_TYPE_OPTIONS,
    risk: RISK_OPTIONS,
    unresolvedMatches: UNRESOLVED_MATCHES_OPTIONS,
    markedAs: PEP_SANCTION_FILTERS,
    archive: ARCHIVE_OPTIONS,
    followup: FOLLOWUP_EXPIRED_OPTIONS,
    screening: SCREENING_OPTIONS,
    projectStatus: PROJECT_STATUS_OPTIONS,
    ownershipUpdates: OWNERSHIP_UPDATES_OPTIONS,
    measures: PENDING_MEASURES_OPTIONS
  };
};

export default useFiltersOptions;
