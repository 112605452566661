import { Button, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import CopyIcon from "../../../assets/icons/copy.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";
import KDKBanner from "../KDKBanner/KDKBanner";

export type ReviewChecklistTemplateCardProps = {
  templateChecklist: api.ReviewChecklist;
  readonly?: boolean;
  onCreateInstance(checklist: api.ReviewChecklist): void;
};

const ReviewChecklistTemplateCard = (props: ReviewChecklistTemplateCardProps) => {
  const { templateChecklist, readonly, onCreateInstance } = props;

  const f = useFormatMessage();

  return (
    <StyledBanner status="info" hideIcon hideBorder>
      <VerticalStack gap="4" align={"space-between"}>
        <VerticalStack>
          <Text as={"span"} fontWeight={"semibold"}>
            {templateChecklist.name}
          </Text>
          <CondensedText>{templateChecklist.description}</CondensedText>
        </VerticalStack>
        <HorizontalStack align={"end"}>
          <Button
            onClick={() => onCreateInstance(templateChecklist)}
            icon={<Icon source={CopyIcon} />}
            size="slim"
            disabled={readonly}
          >
            {f("component.review.checklist.create-instance")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </StyledBanner>
  );
};

const StyledBanner = styled(KDKBanner)`
  width: 20rem;
  background-color: var(--p-color-bg);
  padding: var(--p-space-1);
  border-radius: var(--p-border-radius-2);

  & > .Polaris-Banner {
    background-color: var(--p-color-bg-info-subdued-hover);
    padding: var(--p-space-3);
    height: 100%;
  }
`;

export default ReviewChecklistTemplateCard;
