import React from "react";

import ArchiveIcon from "../../../../../assets/icons/archivebox.svg";
import api from "../../../../api";
import { getOwnerName } from "../../../../helpers/owners.helpers";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useOpenClose from "../../../../hooks/useOpenClose";
import useRichFormatMessage from "../../../../hooks/useRichFormatMessage";
import { isEmptyString } from "../../../../utils/stringUtils";
import { isNil, noop } from "../../../../utils/util";
import ConfirmAction from "../../../ConfirmAction/ConfirmAction";
import EditButton from "../../../EditButton/EditButton";
import Button from "../../../extensions/Button";
import Icon from "../../../extensions/Icon";
import TooltipWrapper from "../../../TooltipWrapper/TooltipWrapper";

import OwnershipUpdateActions, { OwnershipUpdateActionsProps } from "./OwnershipUpdateActions";

interface OwnerActionsProps extends Partial<OwnershipUpdateActionsProps> {
  owner: api.Owner;
  readonly?: boolean;
  onEdit?(owner: api.Owner): void;
  onAfterArchive?(ownerId: string): void;
}

const OwnerActions = (props: OwnerActionsProps) => {
  const {
    customer,
    owner,
    update,
    readonly,
    onEdit = noop,
    onOwnershipUpdateReject = noop,
    onOwnershipUpdateConfirm = noop,
    onAfterArchive = noop
  } = props;

  const f = useFormatMessage();
  const richFormatMessage = useRichFormatMessage();
  const [showArchiveConfirm, toggleArchiveConfirm, closeArchiveConfirm] = useOpenClose();

  const archiveOwner = (ownerId?: string) => api.deleteCompanyOwner(customer!.id, ownerId!);

  const ownerName = isEmptyString(getOwnerName(owner)) ? f("common.labels.unidentified-owner") : getOwnerName(owner);

  if (update && customer) {
    return (
      <OwnershipUpdateActions
        customer={customer}
        update={update}
        readonly={readonly}
        onOwnershipUpdateReject={onOwnershipUpdateReject}
        onOwnershipUpdateConfirm={onOwnershipUpdateConfirm}
      />
    );
  }

  // for Person owners (known or unknown) - enable editing the owner details
  if (!owner.company && !isNil(owner.person)) {
    return <EditButton onEdit={() => onEdit(owner)} readonly={readonly} />;
  } else if (owner.company || owner.is_unknown) {
    // for unknown owners which are not a person - either a company or not known
    return (
      <>
        <TooltipWrapper content={f("default.archive")}>
          <Button onClick={toggleArchiveConfirm} plain fullWidth monochrome disabled={readonly || owner.deleted}>
            <Icon source={ArchiveIcon} width="22px" height="22px" />
          </Button>
        </TooltipWrapper>
        {showArchiveConfirm && (
          <ConfirmAction
            apiCall={() => archiveOwner(owner.id)}
            title={f("default.archive")}
            actionTitle={f("default.archive")}
            onNo={closeArchiveConfirm}
            onSuccess={() => onAfterArchive(owner.id!)}
          >
            <p>{richFormatMessage("company.owners.archive.description", { name: ownerName })}</p>
          </ConfirmAction>
        )}
      </>
    );
  }

  return null;
};

export default OwnerActions;
