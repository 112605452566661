import React, { PropsWithChildren } from "react";
import { Button, Collapsible, Grid, HorizontalStack, VerticalStack } from "@shopify/polaris";

import api from "../../../api";
import { getFullName } from "../../../helpers/display.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import { hasContent } from "../../../utils/collectionUtils";
import AssignmentInfo from "../../AssignmentInfo/AssignmentInfo";
import DisclosureAnswers from "../../DisclosureAnswers/DisclosureAnswers";

import SigningUserInfo from "./SigningUserInfo";
import useGetSigningMethodDisplayLabel from "./useGetSigningMetodLabel";

type CompletedDisclosureDocumentSignatureProps = {
  signature: api.Signature;
  hideSignedUserInfo?: boolean;
  useSmallButton?: boolean;
  vertical?: boolean;
};

const CompletedDisclosureDocumentSignature = (props: PropsWithChildren<CompletedDisclosureDocumentSignatureProps>) => {
  const { signature, hideSignedUserInfo, useSmallButton, vertical, children } = props;

  const f = useFormatMessage();

  const [isDisclosureOpen, toggleDisclosureOpen] = useOpenClose();

  const hasQuestionnaireAnswer = hasContent(signature.questionnaire_ids);

  if (!signature.signing_user_info && !signature.disclosure && !hasQuestionnaireAnswer) {
    return null;
  }

  const showSignedUserInfo = !hideSignedUserInfo && signature.signing_user_info !== undefined;

  return (
    <VerticalStack gap="4">
      <Grid columns={{ xs: 1, md: 3, lg: 5 }}>
        {/* if there is not signing_user_info then expand the assignment info across all 3 columns */}
        <Grid.Cell columnSpan={showSignedUserInfo ? { xs: 1, md: 3 } : { xs: 5 }}>
          <VerticalStack align="space-between" gap="2">
            {signature.assignment_id && (
              <AssignmentInfo
                assignmentName={signature.assignment_name}
                assignmentPurpose={signature.assignment_purpose}
              />
            )}
            {(signature.disclosure || hasQuestionnaireAnswer) && (
              <HorizontalStack gap="1" align={"space-between"} wrap={false}>
                <Button onClick={toggleDisclosureOpen} removeUnderline size={useSmallButton ? "slim" : undefined}>
                  {isDisclosureOpen ? f("disclosure-collapsible.hide") : f("disclosure-collapsible.show")}
                </Button>
                {children}
              </HorizontalStack>
            )}
          </VerticalStack>
        </Grid.Cell>
        {signature.signing_user_info && (
          <Grid.Cell columnSpan={{ xs: 1, md: 2, lg: 4 }}>
            <SigningUserInfo
              identificationMethodLabel={f("disclosure-card.identification.label", {
                method: useGetSigningMethodDisplayLabel(signature)
              })}
              customerName={getFullName(signature.person)}
              signingUserInfo={signature.signing_user_info}
            />
          </Grid.Cell>
        )}
      </Grid>
      <Collapsible id={signature.id + "-disclosure-collapsible"} open={isDisclosureOpen}>
        {signature.disclosure_answers && (
          <DisclosureAnswers
            disclosureSections={signature.disclosure_answers.sections}
            vertical={vertical}
            hoverable={false}
          />
        )}
      </Collapsible>
    </VerticalStack>
  );
};

export default CompletedDisclosureDocumentSignature;
