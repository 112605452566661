import { memo } from "react";

import KDKBanner from "../../components/KDKBanner/KDKBanner";
import useFormatMessage from "../../hooks/useFormatMessage";

interface ImportCustomersValidationBannerProps {
  columnsNames: string[];
  validationColumns: string[];
}
const ImportCustomersValidationBanner = (props: ImportCustomersValidationBannerProps) => {
  const { columnsNames, validationColumns } = props;

  const f = useFormatMessage();

  const missingRequiredColumns = validationColumns.filter((columnName) => !columnsNames.includes(columnName));
  const multipleRequiredColumns = validationColumns.filter(
    (columnName) =>
      columnsNames.includes(columnName) && columnsNames.indexOf(columnName) !== columnsNames.lastIndexOf(columnName)
  );

  const hasErrors = missingRequiredColumns.length > 0 || multipleRequiredColumns.length > 0;

  if (!hasErrors) {
    return null;
  }

  return (
    <KDKBanner status={"warning"} hideIcon>
      {missingRequiredColumns.length > 0 && (
        <p>
          {f("import-customers.wizard.warning.missing-columns", {
            count: missingRequiredColumns.length,
            names: missingRequiredColumns.join(", ")
          })}
        </p>
      )}
      {multipleRequiredColumns.length > 0 && (
        <p>
          {f("import-customers.wizard.warning.multiple-columns", {
            count: multipleRequiredColumns.length,
            names: multipleRequiredColumns.join(", ")
          })}
        </p>
      )}
    </KDKBanner>
  );
};

export default memo(ImportCustomersValidationBanner);
