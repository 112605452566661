import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import Badge from "../extensions/Badge";

type ReviewChecklistPageHeaderProps = {
  title?: string;
  isArchived?: boolean;
};

const ReviewChecklistPageHeader = (props: ReviewChecklistPageHeaderProps) => {
  const { title = "", isArchived } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="8">
      <HorizontalStack gap="2" blockAlign="baseline">
        <Text variant="headingLg" as="h2">
          {title}
        </Text>
        {isArchived && <Badge size="xsmall">{f("badge.archived")}</Badge>}
      </HorizontalStack>
      <Text as="span" color="subdued">
        {f("review.checklists.page.header.description")}
      </Text>
    </VerticalStack>
  );
};

export default ReviewChecklistPageHeader;
