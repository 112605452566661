import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { TextField } from "@shopify/polaris";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { ROUTES } from "../../constants/routes";
import { convertChecklistToCreateChecklistRequest, isChecklistActive } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetReviewChecklists from "../../hooks/useGetReviewChecklists";
import useQueryData from "../../hooks/useQueryData";
import { sortByName } from "../../utils/collectionUtils";
import { isEmptyString } from "../../utils/stringUtils";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

type ReviewChecklistDuplicateModalProps = {
  checklist: api.ReviewChecklist;
  navigateToChecklistListPageAfterCopy?: boolean;
  isTemplate?: boolean;
  onClose(): void;
};

const ReviewChecklistDuplicateModal = (props: ReviewChecklistDuplicateModalProps) => {
  const { checklist, isTemplate, navigateToChecklistListPageAfterCopy, onClose } = props;

  const f = useFormatMessage();
  const history = useHistory();
  const { data } = useGetReviewChecklists();

  const checklists = data?.checklists || [];

  const checklistNames = new Set(checklists.filter(isChecklistActive).map((checklist) => checklist.name));

  const [newName, setNewName] = useState(isTemplate ? checklist.name : checklist.name + " copy");

  const { updateQueryData } = useQueryData<api.ReviewChecklistListResponse>([QUERIES_KEYS.REVIEW_CHECKLISTS]);

  const errorMessage = isEmptyString(newName)
    ? f("common.errors.invalid.empty.name")
    : checklistNames.has(newName)
      ? f("common.errors.invalid.name.exists")
      : undefined;

  const title = isTemplate
    ? f("review.checklist.template.instance.modal.title", { name: checklist.name })
    : f("review.checklist.duplicate.modal.title", { name: checklist.name });

  return (
    <ConfirmAction<api.ReviewChecklist>
      isOpen
      apiCall={() =>
        api.createReviewChecklist({ ...convertChecklistToCreateChecklistRequest(checklist), name: newName })
      }
      title={title}
      actionTitle={f("default.save")}
      isActionDisabled={!isEmptyString(errorMessage)}
      onNo={onClose}
      isDestructive={false}
      useSuccessToast={!navigateToChecklistListPageAfterCopy}
      successTitle={f("review.checklist.duplicate.modal.success.message", { name: newName })}
      onSuccess={(duplicateChecklist) => {
        updateQueryData((queryData) => {
          queryData.checklists.push(duplicateChecklist);
          queryData.checklists.sort(sortByName);
        });

        if (navigateToChecklistListPageAfterCopy) {
          history.push(generatePath(ROUTES.SETTINGS_REVIEW_CHECKLISTS_LIST));
        }
      }}
    >
      <TextField
        label={f("common.labels.name")}
        autoComplete="off"
        requiredIndicator
        value={newName}
        onChange={setNewName}
        error={errorMessage}
      />
    </ConfirmAction>
  );
};

export default ReviewChecklistDuplicateModal;
