import React, { memo } from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled, { keyframes } from "styled-components";

import { isLoading } from "../../helpers/import-customers.helpers";
import useGetImportCustomerStatusLabelAndIcon from "../../hooks/useGetImportCustomerStatusLabelAndIcon";
import { ImportRowData } from "../../types/ImportRow";
import Icon from "../extensions/Icon";

interface ImportCustomerStatusProps {
  importRowData: ImportRowData;
}

const ImportCustomerStatus = (props: ImportCustomerStatusProps) => {
  const { importRowData } = props;

  const getStatusLabelAndIcon = useGetImportCustomerStatusLabelAndIcon();

  const { icon, label } = getStatusLabelAndIcon(importRowData.status);

  const animate = isLoading(importRowData);

  return (
    <HorizontalStack gap="4" blockAlign={"center"} wrap={false}>
      <SpinningIcon source={icon} animate={animate} height={"20px"} width={"20px"} />
      <VerticalStack>
        {label && <Text as="span">{label}</Text>}
        {importRowData.errorMessage && (
          <Text as="span" color="critical">
            {importRowData.errorMessage}
          </Text>
        )}
      </VerticalStack>
    </HorizontalStack>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled(Icon)<{ animate?: boolean }>`
  &:before {
    animation: ${rotate} var(--p-linear) 1.5s none;
    animation-iteration-count: ${({ animate }) => (animate ? "infinite" : 0)};
  }
`;

export default memo(ImportCustomerStatus);
