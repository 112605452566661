import React, { PropsWithChildren } from "react";
import { ComplexAction, Modal, ModalProps, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import { ErrorType } from "../../types/utilities";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

export type PreviewModalProps = Omit<ModalProps, "open"> & {
  previewURL?: string;
  error?: ErrorType;
  large?: boolean;
};

const PreviewModal = (props: PropsWithChildren<PreviewModalProps>) => {
  const { previewURL, error, large, onClose, children, ...modalProps } = props;

  const f = useFormatMessage();

  const closeAction: ComplexAction = {
    content: f("default.close"),
    onAction: onClose
  };

  return (
    <Modal open large={large} onClose={onClose} primaryAction={closeAction} iFrameName="preview-modal" {...modalProps}>
      <Modal.Section flush>
        <VerticalStack inlineAlign="stretch">
          <StyledPreviewContainer large={large}>
            {previewURL && <iframe src={previewURL} name="preview-modal" />}
            {children}
          </StyledPreviewContainer>
          {error && <ErrorPanel message={error} />}
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};

const StyledPreviewContainer = styled.div<{ large?: boolean }>`
  margin: 0 ${({ large }) => (large ? "0" : "auto")};
  padding: 0 var(--p-space-4);
  overscroll-behavior: none;
  min-width: var(--p-breakpoints-sm);

  & > iframe {
    border: none;
    width: var(--p-breakpoints-sm);
    overscroll-behavior: none;
    min-height: 50vh;
  }
`;

export default PreviewModal;
