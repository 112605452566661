import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../../api";
import { QUERIES_KEYS } from "../../../constants/queries-keys";
import useAttachmentsAPI from "../../../hooks/useAttachmentsAPI";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { Entity } from "../../../types/utilities";
import { cleanupFilename } from "../../../utils/filenameUtils";
import Confirm from "../../Confirm/Confirm";

type DeleteAttachmentConfirmationProps = {
  entity: Entity;
  attachment: api.AttachmentDetails;
  deleteSignedAttachment?: boolean;
  onModalClose(): void;
};

const DeleteAttachmentConfirmation = (props: DeleteAttachmentConfirmationProps) => {
  const { entity, attachment, deleteSignedAttachment, onModalClose } = props;

  const queryClient = useQueryClient();
  const f = useFormatMessage();

  const {
    entityId,
    deleteAttachment,
    deleteSignedAttachment: deleteSignedAttachmentCall
  } = useAttachmentsAPI(entity, attachment);

  const mutation = useMutation(
    () =>
      deleteSignedAttachment
        ? deleteSignedAttachmentCall(entityId, attachment.id)
        : deleteAttachment(entityId, attachment.id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERIES_KEYS.ATTACHMENTS, entityId]);
        // if the attachment was from an assignment it means that entity is a customer
        // and it's cache should also be invalidated
        if (attachment.assignment_id) {
          await queryClient.invalidateQueries([QUERIES_KEYS.ATTACHMENTS, entity.id]);
        }
        onModalClose();
      }
    }
  );

  const filename = cleanupFilename(attachment.filename);

  const title = deleteSignedAttachment
    ? f("attachments.delete.confirmation.signed.title")
    : f("attachments.delete.confirmation.title");

  const description = deleteSignedAttachment
    ? f("attachments.delete.confirmation.signed.description", { name: filename })
    : f("attachments.delete.confirmation.description", { name: filename });

  const deleteActionTitle = deleteSignedAttachment
    ? f("attachments.delete.confirmation.signed.yes")
    : f("attachments.delete.confirmation.yes");

  return (
    <Confirm
      title={title}
      description={description}
      onYes={() => mutation.mutate()}
      onNo={onModalClose}
      actionTitle={deleteActionTitle}
      isActionInProgress={mutation.isLoading}
      error={mutation.error}
    />
  );
};

export default DeleteAttachmentConfirmation;
