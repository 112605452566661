import React from "react";
import { VerticalStack } from "@shopify/polaris";

import useRDCMatch from "../../hooks/useRDCMatch";
import { MatchDetailsProps } from "../../types/MatchDetails";
import MatchDetailsComparisonSection from "../MatchDetailsComparisonSection/MatchDetailsComparisonSection";
import MatchDetailsNegativeNewsSection from "../MatchDetailsNegativeNewsSection/MatchDetailsNegativeNewsSection";
import MatchDetailsOtherSection from "../MatchDetailsOtherSection/MatchDetailsOtherSection";
import MatchDetailsPEPSection from "../MatchDetailsPEPSection/MatchDetailsPEPSection";
import MatchDetailsRelationsSection from "../MatchDetailsRelationsSection/MatchDetailsRelationsSection";
import MatchDetailsSanctionsSection from "../MatchDetailsSanctionsSection/MatchDetailsSanctionsSection";
import MatchKeyInformationSection from "../MatchKeyInformationSection/MatchKeyInformationSection";
import RDCMatchDetailsGeographicComparisonSection from "../RDCMatchDetailsGeographicComparisonSection/RDCMatchDetailsGeographicComparisonSection";

const RDCMatch = (props: MatchDetailsProps) => {
  const { matchDetails } = props;

  const { sanctionEvents, pepEvents, positions, hasNewInfo } = useRDCMatch(matchDetails);
  return (
    <>
      <VerticalStack>
        <MatchDetailsComparisonSection matchDetails={matchDetails} />
        <RDCMatchDetailsGeographicComparisonSection matchDetails={matchDetails} />
      </VerticalStack>
      <MatchKeyInformationSection matchDetails={matchDetails} />
      <VerticalStack>
        <MatchDetailsRelationsSection matchDetails={matchDetails} />
        <MatchDetailsPEPSection pepEvents={pepEvents} positions={positions} hasNewInfo={hasNewInfo} />
        <MatchDetailsSanctionsSection sanctionEvents={sanctionEvents} hasNewInfo={hasNewInfo} />
        <MatchDetailsNegativeNewsSection matchDetails={matchDetails} />
        <MatchDetailsOtherSection matchDetails={matchDetails} />
      </VerticalStack>
    </>
  );
};

export default RDCMatch;
