import React, { useEffect } from "react";
import { Filters, Stack, VerticalStack } from "@shopify/polaris";

import { QUERY_SEARCH_DEBOUNCE_DELAY } from "../../constants/durations";
import useChangedState from "../../hooks/useChangedState";
import useDebounce from "../../hooks/useDebounce";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import AppliedFilters from "../AppliedFilters/AppliedFilters";

import SavedFilters from "./SavedFilters/SavedFilters";
import useCustomersListFilters from "./useCustomersListFilters";

interface CustomersListFiltersProps {
  isLoading: boolean;
}

const CustomersListFilters = (props: CustomersListFiltersProps) => {
  const { isLoading } = props;

  const f = useFormatMessage();

  const { q, setQ, filters, appliedFilters, resetAllFilters } = useCustomersListFilters(isLoading);
  const [queryText, setQueryText, queryTextChanged] = useChangedState(q ?? "");

  // When navigating back to the customers list page,
  // the previous URL search string is re-loaded from session storage in order to restore the previous applied filters and search query.
  // Since restoring the URL search params happens AFTER initial rendering of this component,
  // the initial value of queryText will be an empty string - which means that if q value changes due to the
  // re-loaded from session storage, Filters component will remain empty - even though the URL search param
  // may contain a query string
  // in order to fix this issue, call setQueryText with q value (when changes) but only if q is not empty and
  // queryText has not queryTextChanged (so it would not try to update it when typing)
  useEffect(() => {
    if (!queryTextChanged && !isEmptyString(q)) {
      setQueryText(q ?? "");
    }
  }, [q, queryTextChanged]);

  useDebounce(() => setQ(queryText), QUERY_SEARCH_DEBOUNCE_DELAY, [queryText]);

  return (
    <VerticalStack gap="2">
      <Stack spacing="tight">
        <SavedFilters />
        <Stack.Item fill>
          <Filters
            queryValue={queryText}
            queryPlaceholder={f("component.search.customers.hint")}
            filters={filters}
            appliedFilters={appliedFilters}
            hideTags
            onQueryChange={setQueryText}
            onQueryClear={() => setQueryText("")}
            onClearAll={() => resetAllFilters()}
          />
        </Stack.Item>
      </Stack>
      <AppliedFilters appliedFilters={appliedFilters} />
    </VerticalStack>
  );
};

export default CustomersListFilters;
