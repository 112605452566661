import React from "react";
import { Avatar } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

import { getUserInitials } from "./UserInitials.helpers";

interface UserInitialsProps {
  user?: api.User;
  tooltip?: string;
  hideOnNoUser?: boolean;
}

const UserInitials = (props: UserInitialsProps) => {
  const { user, tooltip, hideOnNoUser } = props;

  if (hideOnNoUser && !user) {
    return null;
  }

  const avatar = (
    <StyledAvatar showInitials={user !== undefined}>
      <Avatar size="small" customer={false} initials={user && getUserInitials(user)} />
    </StyledAvatar>
  );

  return (
    <TooltipWrapper content={tooltip} preferredPosition="above" dismissOnMouseOut>
      {avatar}
    </TooltipWrapper>
  );
};

const StyledAvatar = styled.div<{ showInitials: boolean }>`
  & .Polaris-Avatar {
    min-width: var(--p-space-6);
    width: var(--p-space-6);
    border: solid var(--p-border-width-2) transparent;
    background: ${({ showInitials }) => (showInitials ? "var(--p-decorative-one-surface)" : "initial")};
  }

  & .Polaris-Avatar .Polaris-Avatar__Text {
    font-size: var(--p-font-size-200);
    font-weight: var(--p-font-weight-bold);
  }
,
`;

export default UserInitials;
