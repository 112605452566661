export const SORT_BY_NAME_PARAMETER = "name";
export const SORT_BY_ADDED_DATE_PARAMETER = "created";

export const CUSTOMERS_PER_PAGE = 20;

export const ORDER_NAME_PARAMETER = "order";
export const PAGE_NAME_PARAMETER = "page";
export const LIMIT_NAME_PARAMETER = "limit";
export const QUERY_NAME_PARAMETER = "q";

export const CREATED_RANGE_NAME_PARAMETER = "created";
export const CREATED_AFTER_NAME_PARAMETER = "createdAfter";
export const CREATED_BEFORE_NAME_PARAMETER = "createdBefore";

export const FOLLOWUP_RANGE_NAME_PARAMETER = "followup";
export const FOLLOWUP_AFTER_NAME_PARAMETER = "followupAfter";
export const FOLLOWUP_BEFORE_NAME_PARAMETER = "followupBefore";

export const DASHBOARD_INTERVAL_NAME_PARAMETER = "interval";
export const DASHBOARD_START_NAME_PARAMETER = "from";
export const DASHBOARD_END_NAME_PARAMETER = "until";

export enum DateRange {
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
  CUSTOM = "CUSTOM"
}
