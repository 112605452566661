import { getAlpha2Code, registerLocale } from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import nbCountries from "i18n-iso-countries/langs/nb.json";

import api from "../api";
import { DEFAULT_LANGUAGE } from "../constants/languages";
import { sortByName } from "../utils/collectionUtils";
import { isEmptyString } from "../utils/stringUtils";

const convertLocaleCountries = (countries: Record<string, string[] | string>) =>
  Object.keys(countries).map((key) => ({
    name: Array.isArray(countries[key]) ? (countries[key] as string[]).join(",") : (countries[key] as string),
    code: key
  }));

const COUNTRIES: Record<string, ReturnType<typeof convertLocaleCountries>> = {
  // this has to be repeated for all locales we want to be supported
  nb: convertLocaleCountries(nbCountries.countries).sort(sortByName),
  en: convertLocaleCountries(enCountries.countries).sort(sortByName)
};

export const searchForCountryCode = (countryName?: string, language?: string) => {
  if (!countryName || isEmptyString(countryName)) {
    return undefined;
  }

  const lang = language || DEFAULT_LANGUAGE;

  if (lang === DEFAULT_LANGUAGE) {
    registerLocale(nbCountries);
  } else if (lang === "en") {
    registerLocale(enCountries);
  }

  return getAlpha2Code(countryName, lang) as api.CountryEnum;
};

export default COUNTRIES;
