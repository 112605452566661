import React from "react";
import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import isPropValid from "@emotion/is-prop-valid";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StyleSheetManager } from "styled-components";

import "core-js/es";
import "core-js/es/string/replace-all.js";

import App from "./components/App/App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { AuthProvider } from "./providers/AuthProvider/AuthProvider";
import { authSessionService } from "./providers/AuthProvider/AuthProvider.authSessionService";
import { LanguageContextProvider } from "./providers/LanguageProvider";
import { PageNavigationContextProvider } from "./providers/PageNavigation";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import history from "./utils/history";
import api from "./api";

// Set API defaults
api.defaults.fetch = authSessionService.apiFetchInterceptor;
api.defaults.baseUrl = `${process.env.API_URL?.replace("/v2", "")}`;

// Init Sentry
// Only track and send events/reports to Sentry when running in (pre)prod, ie:
// * NODE_ENV is "production", and
// * REACT_APP_SENTRY_ENVIRONMENT is either "production" or "preprod"
const isSentryEnabled =
  process.env.NODE_ENV === "production" &&
  ["production", "preprod"].includes(process.env.REACT_APP_SENTRY_ENVIRONMENT as string);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tunnel: `${api.defaults.baseUrl}/sentry`,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ],
  // Transaction sample rate (ie. recording rate of page loads, API requests etc.)
  // Docs recommend starting with a low sampling rate, then gradually increase it,
  // or configure it with `tracesSampler`.
  // For more, see https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
  tracesSampleRate: 0.2,
  beforeSend(event) {
    if (isSentryEnabled) {
      // Send event to Sentry if running in (pre)prod
      return event;
    } else {
      // Do not send events to Sentry for other envs. Log instead
      console.error("Captured Sentry event: ", event);
      return null;
    }
  },
  // see https://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
  ignoreErrors: [
    "__gCrWeb" // "Chrome in iOS __gCrWeb error",
  ],
  sendClientReports: isSentryEnabled,
  autoSessionTracking: isSentryEnabled
});

const isDevelopment = process.env.NODE_ENV === "development";

if (isDevelopment) {
  import("mimic");
}

// Setup Sentry as logger for react-query
// if sentry is not enabled, messages will be printed in the console
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !isDevelopment
    }
  }
});

const OuterErrorBoundaryFallback = () => (
  // At this point none of the providers has been loaded
  // -> Cannot use Polaris components, translation hooks etc.
  // -> Render basic error message
  // --> Hopefully we won't get here :)
  <div style={{ padding: "2em" }}>
    <h2 style={{ fontSize: "1.5em" }}>En feil oppstod.</h2>
    <br />
    <h4 style={{ fontSize: "1em" }}>Vi er på saken!</h4>
  </div>
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ErrorBoundary fallback={OuterErrorBoundaryFallback}>
    <Router history={history}>
      <AuthProvider>
        <LanguageContextProvider>
          <QueryClientProvider client={queryClient}>
            {/* Wrap the app with StyleSheetManager in order to revert the default behavior changed in styled-components v6
                see https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default for details
            */}
            <StyleSheetManager
              shouldForwardProp={(propName, target) =>
                // For HTML elements, forward the prop if it is a valid HTML attribute
                // For other elements, forward all props
                typeof target !== "string" || isPropValid(propName)
              }
            >
              <PageNavigationContextProvider>
                <SnackbarProvider>
                  <App />
                </SnackbarProvider>
              </PageNavigationContextProvider>
            </StyleSheetManager>
          </QueryClientProvider>
        </LanguageContextProvider>
      </AuthProvider>
    </Router>
  </ErrorBoundary>
);
