import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import DataTableCellChild from "../DataTableCellChild/DataTableCellChild";
import Icon from "../extensions/Icon";

type ComparisonTableHeaderProps = {
  icon: string;
  label: string;
};

const ComparisonTableHeader = (props: ComparisonTableHeaderProps) => {
  const { icon, label } = props;

  return (
    <StyledDataTableCellChild width={"50%"}>
      <HorizontalStack gap="1" blockAlign="center" wrap={false}>
        <Icon source={icon} />
        <Text as="span" variant="headingMd" fontWeight="semibold">
          {label}
        </Text>
      </HorizontalStack>
    </StyledDataTableCellChild>
  );
};

// used to override (revert) the data table header colors
const StyledDataTableCellChild = styled(DataTableCellChild)`
  color: var(--p-color-text) !important;
`;

export default ComparisonTableHeader;
