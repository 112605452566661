import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { CustomerDetails } from "../types/utilities";

import { useCustomerDetailsQueryData } from "./useQueryData";

const useCustomerReviewDelete = (customer: CustomerDetails, reviewId: string) => {
  const updateQueryData = useCustomerDetailsQueryData(customer);

  const deleteCustomerReviewMutation = useMutation(() => api.deleteCustomerReview(customer.id, reviewId), {
    onSuccess: () => {
      updateQueryData((queryData) => {
        queryData.reviews = queryData.reviews.filter((review) => review.id !== reviewId);
      });
    }
  });

  return deleteCustomerReviewMutation;
};

export default useCustomerReviewDelete;
