import styled from "styled-components";

export const StickyWrapper = styled.div`
  position: relative;

  & > div:first-of-type {
    position: sticky;
    top: 0;
    background-color: var(--p-surface);
    z-index: var(--p-z-1);
    // use negative margins to hide the box shadow of the popover when scrolling
    padding: var(--p-space-2) var(--p-space-4);
    margin: 0 calc(-1 * var(--p-space-4));
  }
`;
