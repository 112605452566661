import React, { useState } from "react";
import { FormLayout, HorizontalStack, TextField } from "@shopify/polaris";

import api from "../../../api";
import { isInvalidMeasure } from "../../../helpers/measures.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { isEmptyString } from "../../../utils/stringUtils";
import Button from "../../extensions/Button";
import KDKTextField from "../../KDKTextField/KDKTextField";

type MeasureFormProps = {
  measure: api.Measure;
  existingMeasures: api.Measure[];
  readonly?: boolean;
  onSave(measure: api.Measure): void;
  onCancel(measure: api.Measure): void;
};

const MeasureForm = (props: MeasureFormProps) => {
  const { measure, existingMeasures, readonly, onSave, onCancel } = props;

  const [editableMeasure, setEditableMeasure] = useState({ ...measure });
  const f = useFormatMessage();

  const isDuplicateTitle = new Set(
    existingMeasures.map((existingMeasure) =>
      existingMeasure.id !== editableMeasure.id ? existingMeasure.title.toLowerCase() : ""
    )
  ).has(editableMeasure.title.trim().toLowerCase());

  const invalid = isInvalidMeasure(editableMeasure) || isDuplicateTitle;

  const measureTitleError = isEmptyString(editableMeasure.title)
    ? f("measures.modal.errors.title-validation-error")
    : isDuplicateTitle
      ? f("measures.modal.errors.title-not-unique-validation-error")
      : undefined;

  const handleFieldChange = (field: keyof api.Measure, value: string) =>
    setEditableMeasure({ ...editableMeasure, [field]: value });

  const cancel = () => {
    setEditableMeasure({ ...measure });
    onCancel(editableMeasure);
  };

  const save = () => onSave(editableMeasure);

  return (
    <FormLayout>
      <KDKTextField
        value={editableMeasure.title}
        label={f("common.labels.title")}
        autoComplete="off"
        autoFocus
        onChange={(value) => handleFieldChange("title", value)}
        error={measureTitleError}
        disabled={readonly}
      />
      <TextField
        value={editableMeasure.description}
        label={f("common.labels.description")}
        autoComplete="off"
        multiline={4}
        onChange={(value) => handleFieldChange("description", value)}
        disabled={readonly}
      />
      <HorizontalStack gap="4" align="end">
        <Button onClick={cancel} size="slim">
          {f("default.cancel")}
        </Button>
        <Button disabled={readonly || invalid} size="slim" onClick={save} primary>
          {f("default.save")}
        </Button>
      </HorizontalStack>
    </FormLayout>
  );
};

export default MeasureForm;
