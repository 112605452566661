import React from "react";
import { Banner, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../../hooks/useFormatMessage";

const CriticalUpdatesBanner = () => {
  const f = useFormatMessage();

  return (
    <Banner status="critical" title={f("details.banners.critical-update.title")}>
      <VerticalStack gap="4">
        <p>{f("details.banners.critical-update.content")}</p>
      </VerticalStack>
    </Banner>
  );
};

export default CriticalUpdatesBanner;
