import api from "../api";

import useFormatMessage from "./useFormatMessage";

const useGetLanguageName = () => {
  const f = useFormatMessage();

  const getLanguageName = (language?: api.LanguageEnum) => {
    switch (language) {
      case "en":
        return f("common.language.english");
      case "nb":
        return f("common.language.norwegian");
      default: {
        return "-";
      }
    }
  };

  return getLanguageName;
};

export default useGetLanguageName;
