import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import BackButton from "../../components/BackButton/BackButton";
import DisclosureSignatureForm from "../../components/DisclosureSignatureForm/DisclosureSignatureForm";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import PersonDisclosureSkeleton from "../../components/PersonDisclosureSkeleton/PersonDisclosureSkeleton";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isActiveAssignment } from "../../helpers/assignments.helpers";
import { getPersonDisplayName } from "../../helpers/display.helpers";
import useCreatePersonDisclosure from "../../hooks/useCreatePersonDisclosure";
import useCustomerDetailsBackButton from "../../hooks/useCustomerDetailsBackButton";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useValidateDisclosureForm from "../../hooks/useValidateDisclosureForm";
import { IdentifiersParams } from "../../types/params";

const PersonRequestDisclosurePage = ({ data: person }: AsyncPageChild<api.PersonCustomerDetailResponse>) => {
  const { id, assignmentId } = useParams<IdentifiersParams>();

  const history = useHistory();
  const f = useFormatMessage();
  const features = useFeatures();
  const validateForm = useValidateDisclosureForm();

  const { createDisclosureMutation, signature, setSignature, selectedAssignment, setSelectedAssignment } =
    useCreatePersonDisclosure(id, person.email, person.assignments, assignmentId);

  // if features.ASSIGNMENTS__HIDE_DETAILS is enabled, do not use the assignment id to return to the
  // assignment details page since it is going to be hidden
  const { backButtonURL } = useCustomerDetailsBackButton(
    id,
    "person",
    features.ASSIGNMENTS__HIDE_DETAILS ? undefined : selectedAssignment?.id
  );
  const backPageLabel = `${getPersonDisplayName(person)}${
    !features.ASSIGNMENTS__HIDE_DETAILS && selectedAssignment ? ` / ${selectedAssignment.name}` : ""
  }`;

  // after a new disclosure is created return back to the assignment details page or person details page
  const navigateToDetailsPage = () => {
    if (backButtonURL) {
      history.push(backButtonURL);
    } else {
      history.goBack();
    }
  };

  if (!signature) {
    return <PersonDisclosureSkeleton />;
  }

  const isFormValid = validateForm([signature]);

  const assignments = person.assignments?.filter(isActiveAssignment);

  return (
    <Page>
      <VerticalStack gap="8">
        <VerticalStack gap="4">
          <BackButton url={backButtonURL} displayLabel={backPageLabel} showAsLink />
          <Text variant="heading2xl" as="p">
            {f("disclosure-request.title")}
          </Text>
        </VerticalStack>
        <Card>
          <Card.Section>
            <DisclosureSignatureForm
              signature={signature}
              customerId={person.id}
              customerType="person"
              signatureName={getPersonDisplayName(person)}
              readonly={createDisclosureMutation.isLoading}
              assignments={assignments}
              disableAssignmentSelection={assignmentId !== undefined}
              selectedAssignment={selectedAssignment}
              onChange={setSignature}
              onAssignmentChange={setSelectedAssignment}
            />
          </Card.Section>
        </Card>
        {createDisclosureMutation.isError && <ErrorPanel message={createDisclosureMutation.error} />}
        <HorizontalStack gap="4" align="end">
          <BackButton url={backButtonURL} />
          <Button
            disabled={!isFormValid}
            loading={createDisclosureMutation.isLoading}
            primary
            onClick={() => createDisclosureMutation.mutate(undefined, { onSuccess: navigateToDetailsPage })}
          >
            {f("disclosure-request.button.send")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Page>
  );
};

export default withAsyncPage<api.PersonCustomerDetailResponse>(PersonRequestDisclosurePage, {
  queryKey: QUERIES_KEYS.PERSON_DETAILS,
  apiFunction: (id, _, options) => api.getPersonCustomerDetails(id, options),
  paramNames: ["id", "assignmentId"],
  skeleton: <PersonDisclosureSkeleton />
});
