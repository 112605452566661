import React from "react";
import { generatePath } from "react-router-dom";
import { HorizontalStack, Link, Stack, VerticalStack } from "@shopify/polaris";

import CommentIcon from "../../../assets/icons/text-bubble.svg";
import api from "../../api";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import Badges from "../Badges/Badges";
import CollapsibleText from "../CollapsibleText/CollapsibleText";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";
import KDKBanner from "../KDKBanner/KDKBanner";

interface MatchCaseRiskIndicatorPropsProps {
  customerId: string;
  matchCase: api.MatchCaseSummary;
}

const MatchCaseRiskIndicator = (props: MatchCaseRiskIndicatorPropsProps) => {
  const { customerId, matchCase } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  return (
    <KDKBanner status="warning" hideIcon>
      <VerticalStack gap="2">
        <HorizontalStack gap="4" blockAlign="center">
          <Link url={generatePath(ROUTES.MATCH_CASE_DETAILS, { customerId, id: matchCase.id })}>
            {matchCase.match_entity_name}
          </Link>
          <Badges
            isPep={matchCase.review?.is_pep}
            isSanctioned={matchCase.review?.is_sanctioned}
            hasNegativeNews={matchCase.review?.negative_news}
            isOther={matchCase.review?.other}
          />
        </HorizontalStack>

        <Stack spacing="none" wrap={false}>
          <Icon source={CommentIcon} />
          <Stack.Item fill>
            <CollapsibleText text={matchCase.review?.description} />
          </Stack.Item>
        </Stack>
        {matchCase.review?.reviewed_by && matchCase.review?.created_dt && (
          <CondensedText italic>
            {f("common.reviewed.label", {
              name: getUserDisplayName(matchCase.review.reviewed_by),
              timestamp: new Date(matchCase.review.created_dt)
            })}
          </CondensedText>
        )}
      </VerticalStack>
    </KDKBanner>
  );
};

export default MatchCaseRiskIndicator;
