import React from "react";
import { HorizontalStack, RadioButton, VerticalStack } from "@shopify/polaris";

import { MAX_FOLLOWUP_INTERVAL_IN_MONTHS } from "../../constants/followup";
import { DateRange } from "../../constants/pagination";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import DateInput from "../DateInput/DateInput";

const MIN_DATE = new Date("2020-01-01");

interface FollowupDateFilterProps {
  isLoading: boolean;
  followupRange?: DateRange;
  followupAfter?: string;
  followupBefore?: string;
  setFollowupRange(followupRange?: DateRange): void;
  setFollowupAfter(followupAfter: string): void;
  setFollowupBefore(followupBefore: string): void;
}

const FollowupDateFilter = (props: FollowupDateFilterProps) => {
  const {
    isLoading,
    followupRange,
    followupAfter,
    followupBefore,
    setFollowupRange,
    setFollowupAfter,
    setFollowupBefore
  } = props;

  const f = useFormatMessage();

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + MAX_FOLLOWUP_INTERVAL_IN_MONTHS / 12);

  return (
    <VerticalStack gap="2">
      <RadioButton
        name="followup"
        label={f(`component.filters.followup.range.ALL`)}
        disabled={isLoading}
        checked={isEmptyString(followupRange)}
        onChange={() => setFollowupRange(undefined)}
      />
      {Object.values(DateRange).map((rangeOption) => (
        <RadioButton
          key={rangeOption}
          name="followup"
          label={f(`component.filters.followup.range.${rangeOption}`)}
          disabled={isLoading}
          checked={followupRange === rangeOption}
          onChange={() => setFollowupRange(rangeOption)}
        />
      ))}
      <HorizontalStack gap="4">
        <DateInput
          label={f("component.filters.followup-after.title")}
          value={followupAfter || ""}
          onChange={(date) => setFollowupAfter(date)}
          minDate={MIN_DATE}
          maxDate={maxDate}
          disabled={isLoading || followupRange !== DateRange.CUSTOM}
          autoComplete="off"
        />
        <DateInput
          label={f("component.filters.followup-before.title")}
          value={followupBefore || ""}
          onChange={(date) => setFollowupBefore(date)}
          minDate={MIN_DATE}
          maxDate={maxDate}
          disabled={isLoading || followupRange !== DateRange.CUSTOM}
          autoComplete="off"
        />
      </HorizontalStack>
    </VerticalStack>
  );
};

export default FollowupDateFilter;
