import React from "react";
import { generatePath } from "react-router-dom";
import { ActionListItemDescriptor, Card, HorizontalStack, Stack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import CompanyIcon from "../../../../assets/icons/company.svg";
import ExistingCustomersIcon from "../../../../assets/icons/lines-horizontal-3.svg";
import PersonIcon from "../../../../assets/icons/person.svg";
import PlusMajorIcon from "../../../../assets/icons/plus-major-white.svg";
import api from "../../../api";
import ActionsDropdown from "../../../components/ActionsDropdown/ActionsDropdown";
import { ROUTES } from "../../../constants/routes";
import { isVitecProject } from "../../../helpers/integration.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import useSelectionMap from "../../../hooks/useSelectionMap";
import useSetLocationState from "../../../hooks/useSetLocationState";
import { Customer } from "../../../types/utilities";

import AddCustomersToProjectModal from "./AddCustomersToProjectModal/AddCustomersToProjectModal";
import ProjectCustomersBulkActions from "./ProjectCustomersBulkActions/ProjectCustomersBulkActions";
import ProjectCustomerEmptyState from "./ProjectCustomerEmptyState";
import ProjectCustomersTable from "./ProjectCustomersTable";

interface ProjectCustomersSectionProps {
  project: api.Project;
  readonly?: boolean;
}

const ProjectCustomersSection = (props: ProjectCustomersSectionProps) => {
  const { project, readonly } = props;

  const [showCustomersSelectModal, toggleCustomersSelectModal, closeCustomersSelectModal] = useOpenClose();
  const [selectedCustomersIds, selectedCustomers, handleSelectionChange, clearSelection] = useSelectionMap<Customer>(
    project.customers
  );
  const f = useFormatMessage();
  const isVitec = isVitecProject(project);

  const disabled = readonly || project.status !== "ACTIVE";
  const count = project.customers.length;

  /*
  onboarding links from the project details page should redirect the user
  to the onboarding pages for person or company and associate the new customers-section with this project.
  they should also display the project name.

  in order to associate the new customers-section with this project, the project id should be added to the
  api.createPersonCustomer or api.updateCompanyScreeningConfiguration.

  the mechanism to pass data between links is by using react router's link state object.
  when setting data in a link state, after navigating to that link (from within the application) the component
  rendered from that link can access the link's state and retrieve the information stored in it.

  the component that renders links is AdapterLink which is set as the Link component renderer in App.tsx:
  <AppProvider linkComponent={AdapterLink} ... />

  inside AdapterLink, the project id and name are retrieved from the current location state (the project details URL)
  and added to the created links state - the created links are for the onboarding of new customers-section.
*/
  useSetLocationState("createdFromProject", { id: project.id, name: project.name });

  const companyOnboardingPath = generatePath(ROUTES.COMPANY_LOOKUP);
  const personOnboardingPath = generatePath(ROUTES.ONBOARDING_NEW_PERSON);

  const items: ActionListItemDescriptor[] = [
    {
      content: f("project.customers.buttons.add-existing-customers"),
      onAction: () => toggleCustomersSelectModal(),
      disabled: readonly,
      image: ExistingCustomersIcon
    },
    {
      content: f("project.customers.buttons.add-new-person-customer"),
      url: personOnboardingPath,
      disabled: readonly,
      image: PersonIcon
    },
    {
      content: f("project.customers.buttons.add-new-company-customer"),
      url: companyOnboardingPath,
      disabled: readonly,
      image: CompanyIcon
    }
  ];

  const isAllSelected = count === selectedCustomersIds.length;
  const selectedLabel = isAllSelected
    ? f("customers.list.header.customers.count.all", { count })
    : f("customers.list.header.customers.count", { count, selected: selectedCustomersIds.length });

  return (
    <VerticalStack gap="4">
      <Stack alignment="trailing">
        <VerticalStack gap={count === 0 ? "0" : "6"}>
          <Text variant="headingMd" as="h2">
            {f("project.customers.title")}
          </Text>
          <HorizontalStack gap="4" blockAlign="center">
            {count > 0 && <StyledTableSelection>{selectedLabel}</StyledTableSelection>}
            {selectedCustomersIds.length > 0 && (
              <ProjectCustomersBulkActions
                project={project}
                customers={selectedCustomers}
                readonly={readonly}
                onClearSelection={clearSelection}
              />
            )}
          </HorizontalStack>
        </VerticalStack>
        <Stack.Item fill />
        {!isVitec && (
          <ActionsDropdown
            items={items}
            icon={PlusMajorIcon}
            title={f("project.customers.buttons.add-customers")}
            disabled={disabled}
            size="medium"
            primary
          />
        )}
      </Stack>
      {count > 0 ? (
        <ProjectCustomersTable
          project={project}
          selectedCustomersIds={selectedCustomersIds}
          onSelectionChange={handleSelectionChange}
        />
      ) : (
        <Card.Section>
          <ProjectCustomerEmptyState project={project} />
        </Card.Section>
      )}
      {showCustomersSelectModal && (
        <AddCustomersToProjectModal project={project} readonly={readonly} onClose={closeCustomersSelectModal} />
      )}
    </VerticalStack>
  );
};

const StyledTableSelection = styled.p`
  min-height: var(--p-space-8);
  line-height: var(--p-space-8);
`;

export default ProjectCustomersSection;
