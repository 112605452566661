import { isNumber } from "../utils/numbersUtils";
import { isEmptyString } from "../utils/stringUtils";

import useFeatures from "./useFeatures";

const formatPercent = (str = "", digits = 2) => {
  if (isEmptyString(str)) {
    return "-";
  }

  if (str.endsWith("%")) {
    return str;
  }

  const value = parseFloat(str);
  if (isNumber(value)) {
    return value.toFixed(digits) + "%";
  }

  return str.concat("%");
};

const useFormatOwnershipPercentage = () => {
  const features = useFeatures();
  const accuracy = features.HIGHER_ACCURACY_PERCENTAGES ? 3 : 2;

  return (value?: string, digits: number = accuracy) => formatPercent(value, digits);
};

export default useFormatOwnershipPercentage;
