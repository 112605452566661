import React, { useState } from "react";
import { generatePath } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  DataTable,
  HorizontalStack,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  VerticalStack
} from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import DisplayName from "../../components/DisplayName/DisplayName";
import Button from "../../components/extensions/Button";
import OnboardingCompanyAttachmentsInfo from "../../components/OnboardingCompanyAttachmentsInfo/OnboardingCompanyAttachmentsInfo";
import OnboardingSummaryAssignmentInfo from "../../components/OnboardingSummaryAssignmentInfo/OnboardingSummaryAssignmentInfo";
import OnboardingSummarySignatureSection from "../../components/OnboardingSummarySignatureSection/OnboardingSummarySignatureSection";
import RoleType from "../../components/RoleType/RoleType";
import SectionDivider from "../../components/SectionDivider/SectionDivider";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import UsersSelect from "../../components/UsersSelect/UsersSelect";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { ROUTES } from "../../constants/routes";
import { getBirthDate, getFullName } from "../../helpers/display.helpers";
import useAttachmentsAPI from "../../hooks/useAttachmentsAPI";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatOwnershipPercentage from "../../hooks/useFormatOwnershipPercentage";
import useNavigationToolbar from "../../hooks/useNavigationToolbar";
import useProjectPermissions from "../../hooks/useProjectPermissions";
import useQueryData from "../../hooks/useQueryData";
import { IDParams } from "../../types/params";
import { first, hasContent } from "../../utils/collectionUtils";

const OnboardingCompanySummaryPage = ({ data: customer }: AsyncPageChild<api.CompanyCustomerDetailResponse>) => {
  const { id } = useParams<IDParams>();

  const f = useFormatMessage();
  const features = useFeatures();
  const formatPercent = useFormatOwnershipPercentage();
  const history = useHistory();

  const { updateQueryData } = useQueryData<api.CompanyCustomerDetailResponse>([
    QUERIES_KEYS.COMPANY_ONBOARDING_SUMMARY,
    id
  ]);
  const { isProjectsEnabled } = useProjectPermissions();
  const { onboardingStepIndex, goToStep } = useNavigationToolbar();

  const { entityId, getAttachments } = useAttachmentsAPI(customer);
  const attachmentsQueryKey = [QUERIES_KEYS.ATTACHMENTS, entityId];
  const { isLoading, data } = useQuery(attachmentsQueryKey, () => getAttachments(entityId));

  const [isPosting, setPosting] = useState(false);

  const roles = customer.screening?.roles || [];
  const owners = customer.screening?.beneficial_owners || [];

  const onboardingSignatures = first(customer.disclosure_documents)?.signatures || [];

  const showAttachments = !isLoading && hasContent(data?.attachment_list);

  const renderRoles = (roles: api.RoleScreeningConfiguration[]) => {
    return roles.map(({ role }) => [
      <DisplayName
        key={role.id}
        type="person"
        name={getFullName(role.person)}
        birthDate={getBirthDate(role.person)}
        comment={role.comment}
      />,
      <RoleType role={role} />
    ]);
  };

  const renderOwners = (owners: api.BeneficialOwnerScreeningConfiguration[]) => {
    return owners.map(({ owner }) => {
      return [
        <DisplayName
          type="person"
          name={getFullName(owner.person)}
          birthDate={getBirthDate(owner.person)}
          comment={owner.comment}
        />,
        formatPercent(owner.percent_share || "0")
      ];
    });
  };

  const associatedProject = customer.projects && customer.projects.length === 1 ? customer.projects[0] : undefined;
  const isCreatedViaProject = associatedProject !== undefined;

  const redirectURLAfterFinish = isCreatedViaProject
    ? generatePath(ROUTES.PROJECT_DETAILS, { id: associatedProject.id })
    : generatePath(ROUTES.COMPANY_DETAILS, { id });

  const updateResponsibleUsers = (responsibleUserIds: string[]) =>
    api
      .updateCustomerDetails(customer.id, { responsible_users: responsibleUserIds })
      .then((response) => response.responsible_users || []);

  const updateQueryCache = (responsibleUsers: api.User[]) =>
    updateQueryData((queryData) => (queryData.responsible_users = responsibleUsers));

  const finish = () => {
    setPosting(true);
    api
      .finishOnboarding(id)
      .then(() => {
        setPosting(false);
        history.push(generatePath(redirectURLAfterFinish));
      })
      .catch((error) => {
        setPosting(false);
        console.log(error);
      });
  };

  const showProjectName = associatedProject && (!isProjectsEnabled || !isCreatedViaProject);

  const showAssignment =
    hasContent(customer.assignments) && onboardingSignatures.some((signature) => signature.assignment_id !== undefined);

  return (
    <Page title={f("onboarding-summary.title")}>
      <VerticalStack gap="4">
        <Text as={"p"}>{f("summary.description")}</Text>

        {features.RESPONSIBLE_USER && (
          <Card>
            <Card.Section title={f("summary.responsible-user")}>
              <UsersSelect
                selectedUsers={customer.responsible_users || []}
                emptySelectionLabel={f("responsible.empty.users")}
                updateErrorMessage={f("responsible.error")}
                onUserSelection={updateResponsibleUsers}
                onAfterUserSelection={updateQueryCache}
              />
            </Card.Section>
          </Card>
        )}

        <Card>
          <Card.Section title={f("summary.screening-type")}>
            <Text as="span">{f(`screening-type.${customer.screening?.screening_type || "NOT_SET"}`)}</Text>
          </Card.Section>
        </Card>

        <Card>
          <Card.Section title={f("summary.text.screening.company")}>
            <DisplayName name={customer.name} type={customer.type || "company"}>
              {customer.national_id && f("display-name.suffixes.labels.org-id", { orgId: customer.national_id })}
            </DisplayName>
          </Card.Section>
        </Card>

        <Card>
          <Card.Section title={f("summary.text.screening.owners")}>
            {owners.length === 0 && <Text as={"p"}>{f("summary.text.no-owners")}</Text>}
            {owners.length > 0 && (
              <DataTable
                columnContentTypes={["text", "numeric"]}
                headings={[f("table.column.name"), f("table.column.shares")]}
                rows={renderOwners(owners)}
                hoverable={false}
              />
            )}
          </Card.Section>
        </Card>

        <Card>
          <Card.Section title={f("summary.text.screening.roles")}>
            {roles.length === 0 && <Text as={"p"}>{f("summary.text.no-roles")}</Text>}
            {roles.length > 0 && (
              <DataTable
                columnContentTypes={["text", "text"]}
                headings={[f("table.column.name"), f("table.column.role")]}
                rows={renderRoles(roles)}
                hoverable={false}
              />
            )}
          </Card.Section>
        </Card>

        <Card>
          <Card.Section title={f("summary.signers.title")}>
            {onboardingSignatures.length === 0 && <Text as={"p"}>{f("summary.signers.text.skipped")}</Text>}
            {onboardingSignatures.length > 0 && <OnboardingSummarySignatureSection signatures={onboardingSignatures} />}
          </Card.Section>
        </Card>

        {showAssignment && (
          <Card>
            <Card.Section>
              <OnboardingSummaryAssignmentInfo assignment={first(customer.assignments)} />
            </Card.Section>
          </Card>
        )}

        {showProjectName && (
          <Card>
            <Card.Section title={f("summary.project.title")}>
              <Text as="p">{associatedProject.name}</Text>
            </Card.Section>
          </Card>
        )}

        {showAttachments && (
          <Card>
            <Card.Section title={f("attachments.section.title")}>
              <OnboardingCompanyAttachmentsInfo customer={customer} />
            </Card.Section>
          </Card>
        )}

        <SectionMargin size="medium" />
        <HorizontalStack gap="4" align="end">
          <Button disabled={isPosting} onClick={() => goToStep(onboardingStepIndex - 1)}>
            {f("button.go.back")}
          </Button>
          <Button primary loading={isPosting} disabled={isPosting} onClick={finish}>
            {f("summary.signers.start.screening")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Page>
  );
};

const Skeleton = () => (
  <Page>
    <VerticalStack gap="4">
      <SkeletonDisplayText size="large" />
      <SkeletonBodyText lines={3} />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={1} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={1} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <HorizontalStack gap="4" align="end">
        <Button primary disabled={true}>
          {/* @ts-ignore */}
          <SkeletonBodyText lines={1} />
        </Button>
      </HorizontalStack>
    </VerticalStack>
  </Page>
);

export default withAsyncPage<api.CompanyCustomerDetailResponse>(OnboardingCompanySummaryPage, {
  queryKey: QUERIES_KEYS.COMPANY_ONBOARDING_SUMMARY,
  apiFunction: api.getCompanyCustomerDetails,
  paramNames: ["id"],
  skeleton: <Skeleton />
});
