import React from "react";
import { HorizontalStack, RadioButton, VerticalStack } from "@shopify/polaris";

import { DateRange } from "../../constants/pagination";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import DateInput from "../DateInput/DateInput";

const MIN_DATE = new Date("2020-01-01");
const MAX_DATE = new Date();

interface CreatedDateFilterProps {
  isLoading: boolean;
  createdRange?: string;
  createdAfter?: string;
  createdBefore?: string;
  setCreatedRange(createdRange?: DateRange): void;
  setCreatedAfter(createdAfter: string): void;
  setCreatedBefore(createdBefore: string): void;
}

const CreatedDateFilter = (props: CreatedDateFilterProps) => {
  const { isLoading, createdRange, createdAfter, createdBefore, setCreatedRange, setCreatedAfter, setCreatedBefore } =
    props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="2">
      <RadioButton
        name="created"
        label={f(`component.filters.created.range.ALL`)}
        disabled={isLoading}
        checked={isEmptyString(createdRange)}
        onChange={() => setCreatedRange(undefined)}
      />
      {Object.values(DateRange).map((rangeOption) => (
        <RadioButton
          key={rangeOption}
          name="created"
          label={f(`component.filters.created.range.${rangeOption}`)}
          disabled={isLoading}
          checked={createdRange === rangeOption}
          onChange={() => setCreatedRange(rangeOption)}
        />
      ))}
      <HorizontalStack gap="4">
        <DateInput
          label={f("component.filters.created-after.title")}
          value={createdAfter || ""}
          onChange={(date) => setCreatedAfter(date)}
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
          disabled={isLoading || createdRange !== DateRange.CUSTOM}
          autoComplete="off"
        />
        <DateInput
          label={f("component.filters.created-before.title")}
          value={createdBefore || ""}
          onChange={(date) => setCreatedBefore(date)}
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
          disabled={isLoading || createdRange !== DateRange.CUSTOM}
          autoComplete="off"
        />
      </HorizontalStack>
    </VerticalStack>
  );
};

export default CreatedDateFilter;
