import { HorizontalStack, Text, TextProps } from "@shopify/polaris";

import api from "../../api";
import useGetUserName from "../../hooks/useGetUserName";

type UserDisplayNameProps = Omit<TextProps, "as"> & {
  user?: api.User;
};

const UserDisplayName = (props: UserDisplayNameProps) => {
  const { user, children, ...textProps } = props;

  const { getUserDisplayName } = useGetUserName();

  if (!user) {
    return null;
  }

  return (
    <HorizontalStack gap="1" blockAlign="center" wrap={false}>
      {children}
      <Text as="span" fontWeight="semibold" {...textProps}>
        {getUserDisplayName(user)}
      </Text>
    </HorizontalStack>
  );
};

export default UserDisplayName;
