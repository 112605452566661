import React from "react";

import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { CustomerDetails } from "../../../types/utilities";
import ConfirmAction from "../../ConfirmAction/ConfirmAction";

import OwnershipUpdateRejectDescription from "./OwnershipUpdateRejectDescription";

interface OwnershipUpdateRejectConfirmationProps {
  customer: CustomerDetails;
  update: api.OwnershipUpdate;
  onClose(): void;
  onSuccess(update: api.OwnershipUpdate): void;
}

const OwnershipUpdateRejectConfirmation = (props: OwnershipUpdateRejectConfirmationProps) => {
  const { customer, update, onClose, onSuccess } = props;

  const f = useFormatMessage();

  return (
    <ConfirmAction
      isDestructive
      apiCall={() => api.rejectOwnershipUpdate(customer.id, update.id)}
      title={f("ownership-update.reject.confirmation.modal.title")}
      cancelTitle={f("default.cancel")}
      actionTitle={f("ownership-update.reject.confirmation.modal.action.label")}
      onNo={onClose}
      onSuccess={() => onSuccess(update)}
    >
      <OwnershipUpdateRejectDescription update={update} />
    </ConfirmAction>
  );
};

export default OwnershipUpdateRejectConfirmation;
