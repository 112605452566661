import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import api from "../../../api";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { CustomerDetails } from "../../../types/utilities";
import Badges from "../../Badges/Badges";
import CustomerResponsibleUsersSelect from "../../CustomerResponsibleUsersSelect/CustomerResponsibleUsersSelect";
import CustomerApprovalStatus from "../../CustomerStatus/CustomerApprovalStatus";
import DetailsGrid from "../../DetailsGrid/DetailsGrid";
import KDKBanner from "../../KDKBanner/KDKBanner";
import Risk from "../../Risk/Risk";
import ScreeningStatusBadge from "../../ScreeningStatusBadge/ScreeningStatusBadge";

import FollowupSelect from "./FollowupSelect";

type BasicCustomerInfoProp = {
  customer: CustomerDetails;
  screening: api.CustomerScreeningStatus;
  readonly?: boolean;
};

const BasicCustomerInfo = (props: BasicCustomerInfoProp) => {
  const { customer, screening, readonly } = props;

  const features = useFeatures();
  const f = useFormatMessage();

  return (
    <KDKBanner hideIcon hideBorder>
      <DetailsGrid rows={3}>
        <Text as="span">{f("details.basic-info.status")}:</Text>
        <Text as="span" fontWeight="semibold">
          <CustomerApprovalStatus customer={customer} detailed />
        </Text>

        <Text as="span">{f("details.basic-info.customer-risk")}:</Text>
        <Text as="span" fontWeight="semibold">
          <Risk risk={customer.risk_level} />
        </Text>

        <Text as="span">{f("details.basic-info.marked-as")}:</Text>
        <Badges isPep={customer.is_pep} isSanctioned={customer.is_sanctioned} showHyphenIfNotSet />

        <Text as="span">{f("details.basic-info.screening-type")}:</Text>
        <Text as="span" fontWeight="semibold">
          <HorizontalStack gap="1" blockAlign="center">
            <Text as="span">{f(`screening-type.${screening.screening_type || "NOT_SET"}`)}</Text>
            <ScreeningStatusBadge screening={screening} />
          </HorizontalStack>
        </Text>

        <Text as="span">{f("details.basic-info.follow-up")}:</Text>
        <FollowupSelect customer={customer} readonly={readonly} />

        {features.RESPONSIBLE_USER && (
          <>
            <Text as="span">{`${f("responsible-users.label")}:`}</Text>
            <CustomerResponsibleUsersSelect customer={customer} readonly={readonly} />
          </>
        )}
      </DetailsGrid>
    </KDKBanner>
  );
};

export default BasicCustomerInfo;
