import React, { useState } from "react";
import { HorizontalStack, Popover, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import useGetUserName from "../../hooks/useGetUserName";
import useOpenClose from "../../hooks/useOpenClose";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Button from "../extensions/Button";
import MultipleUsersInitials from "../MultipleUsersInitials/MultipleUsersInitials";
import UsersList from "../UsersList/UsersList";

interface UsersSelectProps {
  selectedUsers: api.User[];
  emptySelectionLabel: string;
  updateErrorMessage: string;
  readonly?: boolean;
  excludeCurrentUser?: boolean;
  strong?: boolean;
  onUserSelection: (selectedUserIds: string[]) => Promise<api.User[]>;
  onAfterUserSelection: (selectedUsers: api.User[]) => void;
}

const UsersSelect = (props: UsersSelectProps) => {
  const {
    selectedUsers,
    emptySelectionLabel,
    updateErrorMessage,
    readonly,
    excludeCurrentUser,
    strong,
    onUserSelection,
    onAfterUserSelection
  } = props;

  const formatMultipleNames = useFormatMultipleNames();
  const { getUserDisplayName } = useGetUserName();

  const [active, toggleActive, close] = useOpenClose();
  const [selectionChanged, setSelectionChanged] = useState(false);

  const selectedUsersIds = selectedUsers.map((user) => user.id);

  const userSelectionMutation = useMutation<api.User[], unknown, string[]>(
    (selectedUserIds) => onUserSelection(selectedUserIds),
    {
      onSuccess: onAfterUserSelection
    }
  );

  const usersDisplayName =
    selectedUsers.length === 0 ? emptySelectionLabel : formatMultipleNames(selectedUsers.map(getUserDisplayName), 2);

  const usersTooltip = selectedUsers.length === 0 ? "" : formatMultipleNames(selectedUsers.map(getUserDisplayName), 0);

  const activator = (
    <StyledButton
      id="user-select"
      onClick={toggleActive}
      size="slim"
      plain
      textAlign={"left"}
      // this is to fix a bug with @polaris that hides the loading indicator if button is monochrome
      monochrome={!userSelectionMutation.isLoading}
      loading={userSelectionMutation.isLoading}
      disabled={readonly}
      disclosure={active ? "up" : "down"}
      removeUnderline
    >
      <HorizontalStack gap="2" blockAlign="center" wrap={false}>
        <MultipleUsersInitials users={selectedUsers} />
        <StyledDisplayLabel title={usersTooltip} strong={strong}>
          {usersDisplayName}
        </StyledDisplayLabel>
      </HorizontalStack>
    </StyledButton>
  );

  function handleSelectionChange(selectedIds: string[]) {
    if (selectedIds.length > selectedUsersIds.length) {
      setSelectionChanged(true);
    }
    userSelectionMutation.mutate(selectedIds);
  }

  return (
    <VerticalStack gap="1">
      <Popover active={active} activator={activator} onClose={close} autofocusTarget="first-node">
        <Popover.Section>
          <UsersList
            selectedUserIds={selectedUsersIds}
            onChange={handleSelectionChange}
            allowMultiple
            selectedFirst={!selectionChanged}
            excludeCurrentUser={excludeCurrentUser}
            readonly={userSelectionMutation.isLoading}
          />
        </Popover.Section>
      </Popover>
      {userSelectionMutation.isError && <ErrorPanel message={updateErrorMessage} fieldID="user-select" />}
    </VerticalStack>
  );
};

const StyledButton = styled(Button)<{ plain?: boolean }>`
  & .Polaris-Button {
    padding: ${({ plain }) => (plain ? "0" : undefined)};
  }
`;

const StyledDisplayLabel = styled.div<{ strong?: boolean }>`
  font-weight: ${({ strong }) => (strong ? "var(--p-font-weight-bold)" : undefined)};
  max-width: 100%;
`;

export default UsersSelect;
