import React, { useMemo } from "react";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import WarningIcon from "../../../../assets/icons/triangle-filled-exclamation.svg";
import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { areNamesSimilar } from "../../../utils/stringUtils";
import AddressInfo from "../../AddressInfo/AddressInfo";
import CondensedText from "../../CondensedText/CondensedText";
import DisplayName from "../../DisplayName/DisplayName";
import Icon from "../../extensions/Icon";
import TooltipWrapper from "../../TooltipWrapper/TooltipWrapper";

type SigningUserInfoProps = {
  identificationMethodLabel: string;
  signingUserInfo: api.SigningUserInfo;
  customerName: string;
};

const SigningUserInfo = (props: SigningUserInfoProps) => {
  const { identificationMethodLabel, signingUserInfo, customerName } = props;

  const f = useFormatMessage();

  const showNameMatchWarning = useMemo(
    () => !areNamesSimilar(customerName, signingUserInfo.name),
    [customerName, signingUserInfo.name]
  );

  return (
    <VerticalStack gap="1">
      <HorizontalStack gap="2" blockAlign="center" wrap={false}>
        <DisplayName strong type={"person"} name={signingUserInfo.name} />
        {showNameMatchWarning && (
          <TooltipWrapper content={f("signing-user-info.name.mismatch.warning.tooltip")}>
            <Icon source={WarningIcon} />
          </TooltipWrapper>
        )}
      </HorizontalStack>
      <StyledInfoText>{identificationMethodLabel}</StyledInfoText>
      <VerticalStack gap="0">
        <HorizontalStack gap="1" blockAlign="center">
          <StyledInfoEntry>
            <CondensedText>{f("signing-user-info.address")}: </CondensedText>
          </StyledInfoEntry>
          <StyledInfoEntry>
            <CondensedText>
              <AddressInfo address={signingUserInfo.address} />
            </CondensedText>
          </StyledInfoEntry>
        </HorizontalStack>
        <StyledInfoEntry>
          <CondensedText>{f("common.labels.national-id")}: </CondensedText>
          <CondensedText>{signingUserInfo.social_no || f("not.specified")}</CondensedText>
        </StyledInfoEntry>
        <StyledInfoEntry>
          <CondensedText>{f("signing-user-info.birth-date")}: </CondensedText>
          <CondensedText>{signingUserInfo.birth_date || f("not.specified")}</CondensedText>
        </StyledInfoEntry>
      </VerticalStack>
    </VerticalStack>
  );
};

const StyledInfoEntry = styled.div`
  line-height: var(--p-font-line-height-1);
`;

const StyledInfoText = styled(CondensedText)`
  font-weight: var(--p-font-weight-bold);
`;

export default SigningUserInfo;
