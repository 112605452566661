import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { convertQuestionnaireToUpdateQuestionnaireRequest } from "../helpers/questionnaire.helpers";

import useQueryData from "./useQueryData";

const useQuestionnaireRestoreMutation = () => {
  const queryClient = useQueryClient();

  const { updateQueryData } = useQueryData<api.QuestionnaireListResponse>([QUERIES_KEYS.QUESTIONNAIRES]);

  const restoreQuestionnaireMutation = useMutation<api.Questionnaire, unknown, api.Questionnaire>(
    (questionnaire) =>
      api.updateQuestionnaire(questionnaire.id, {
        ...convertQuestionnaireToUpdateQuestionnaireRequest(questionnaire),
        is_archived: false
      }),
    {
      onSuccess: (response) => {
        updateQueryData((queryData) => {
          queryData.questionnaires = queryData.questionnaires.map((entry) =>
            entry.id === response.id ? response : entry
          );
        });
        queryClient.invalidateQueries([QUERIES_KEYS.QUESTIONNAIRES_SUMMARY]);
      }
    }
  );

  return restoreQuestionnaireMutation;
};

export default useQuestionnaireRestoreMutation;
