// search for a person customer within customers list roles
import { getCustomerName } from "../../../../../helpers/display.helpers";
import { CustomerDetails, isPersonCustomerDetails } from "../../../../../types/utilities";

export const findPersonCompanyName = (personId?: string, customers?: CustomerDetails[]) => {
  if (!personId || !customers) {
    return undefined;
  }

  const companyCustomer = customers.find((customer) => {
    if (isPersonCustomerDetails(customer)) {
      return undefined;
    } else {
      // if the customer contains a role with the same person.id then return the customer
      return customer.roles.find((role) => role.person.id === personId) ? customer : undefined;
    }
  });

  return companyCustomer ? getCustomerName(companyCustomer) : undefined;
};
