import { isEmptyString } from "./stringUtils";

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isWindows10() {
  return navigator.userAgent.toLowerCase().includes("Windows NT 10".toLowerCase());
}

export const copyToClipboard = (text?: string) => {
  if (!text) return;

  navigator.clipboard.writeText(text);
};

export const scrollInPage = (id: string, options?: ScrollIntoViewOptions) => {
  const element = document.getElementById(id);

  if (!element) {
    return;
  }

  element.scrollIntoView(options);
};

export const removeAnchor = (value?: string) => {
  if (isEmptyString(value)) {
    return value;
  }

  const anchorIndex = value!.indexOf("#");

  return anchorIndex > -1 ? value!.substring(0, anchorIndex) : value;
};

export function isValidUrl(string: string) {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (e) {
    return false;
  }
}

/**
 * convert file to base64 string
 */
export const fileToBase64 = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader(); // Read file content on file loaded event
    reader.onload = function (event) {
      const result = event.target?.result;
      if (!result) {
        resolve("");
      } else if (typeof result === "string") {
        // Convert data to base64
        resolve(btoa(result));
      } else {
        resolve(String.fromCharCode(...new Uint16Array(result)));
      }
    };

    // start reading the content of the file
    reader.readAsDataURL(file);
  });
};

/**
  convert base64 string to File
 */
export async function base64ToFile(content: string, filename: string, fileType: string) {
  try {
    const decodedContent = atob(content);
    const base64 = await fetch(decodedContent);
    const blob = await base64.blob();
    return new File([blob], filename, { type: fileType });
  } catch (error) {
    return Promise.reject(`"${content}" is not a valid base64 string`);
  }
}

/**
 * Creates a typed fetch request
 * see: https://www.youtube.com/watch?v=deenC-vH14E
 */
const get = async (url: string, input: Record<string, string>, requestInit?: RequestInit) => {
  return fetch(`${url}?${new URLSearchParams(input).toString()}`, requestInit);
};

const post = async (url: string, input: Record<string, string>, requestInit: RequestInit = {}) => {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(input),
    ...requestInit
  });
};

type CreateAPIMethod = <TInput extends Record<string, string>, TOutput>(opts: {
  url: string;
  method: "GET" | "POST";
  requestInit?: RequestInit;
}) => (input: TInput) => Promise<TOutput>;

// @ts-ignore
export const createAPIMethod: CreateAPIMethod = (opts) => (input) => {
  const method = opts.method === "GET" ? get : post;

  return (
    method(opts.url, input, opts.requestInit)
      // Imagine error handling here...
      .then((res) => res.json())
  );
};
