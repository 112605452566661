import React from "react";
import { useIntl } from "react-intl";
import { Checkbox, HorizontalStack, Stack, Text, VerticalStack } from "@shopify/polaris";

import GreenCheckMarkIcon from "../../../assets/icons/checkmark-green.svg";
import Icon from "../../components/extensions/Icon";
import useFormatMessage from "../../hooks/useFormatMessage";

import ImportCustomersFilters, { ImportCustomersFiltersProps } from "./ImportCustomersFilters";

interface ImportCustomersHeaderProps extends ImportCustomersFiltersProps {
  useFirstRowAsHeaders: boolean;
  setUseFirstRowAsHeaders(useFirstRowAsHeaders: boolean): void;
  totalCount: number;
  visibleRowsCount: number;
  selectedRowsCount: number;
  errorsCount: number;
  importedCount: number;
}

const ImportCustomersHeader = (props: ImportCustomersHeaderProps) => {
  const {
    useFirstRowAsHeaders,
    setUseFirstRowAsHeaders,
    totalCount,
    visibleRowsCount,
    selectedRowsCount,
    errorsCount,
    importedCount,
    ...filtersProps
  } = props;

  const f = useFormatMessage();
  const { formatNumber } = useIntl();

  const headerLabel = `${f("default.preview")}${totalCount > 0 ? ` - ${formatNumber(totalCount)} rows` : ""}`;

  const isAllSelected = visibleRowsCount === selectedRowsCount;
  const selectedLabel = isAllSelected
    ? f("customers.list.header.customers.count.all", { count: visibleRowsCount })
    : f("customers.list.header.customers.count", { count: visibleRowsCount, selected: selectedRowsCount });

  return (
    <VerticalStack gap="4">
      <Text variant="headingLg" as="p">
        {headerLabel}
      </Text>
      <Stack spacing={"tight"}>
        {totalCount > 0 && <Text as="span">{selectedLabel}</Text>}
        {errorsCount > 0 && (
          <Text as="span" color={"critical"}>
            {formatNumber(errorsCount)} errors
          </Text>
        )}
        {importedCount > 0 && (
          <HorizontalStack>
            <Icon source={GreenCheckMarkIcon} />
            <Text as="span">{formatNumber(importedCount)} imported successfully</Text>
          </HorizontalStack>
        )}
      </Stack>
      {totalCount > 0 && <ImportCustomersFilters {...filtersProps} />}
      {totalCount > 0 && (
        <Checkbox
          label={f("import-customers.wizard.labels.first-row-contains-headers")}
          checked={useFirstRowAsHeaders}
          onChange={setUseFirstRowAsHeaders}
        />
      )}
    </VerticalStack>
  );
};

export default ImportCustomersHeader;
