import React, { useMemo } from "react";
import { Select } from "@shopify/polaris";

import api from "../../api";
import { IGNORE_COLUMN } from "../../constants/import-customers";

import useGetColumnLabel from "./useGetColumnLabel";
import useGetDefaultImportColumnsNames from "./useGetDefaultImportColumnsNames";

interface ColumnNameSelectProps {
  columnName: string;
  customerType: api.CustomerType;
  onChange(columnName: string): void;
}

const ColumnNameSelect = (props: ColumnNameSelectProps) => {
  const { columnName, customerType, onChange } = props;

  const { defaultColumns } = useGetDefaultImportColumnsNames(customerType);
  const getColumnLabel = useGetColumnLabel();

  const OPTIONS = useMemo(
    () => [...defaultColumns, IGNORE_COLUMN].map((name) => ({ value: name, label: getColumnLabel(name) })),
    [defaultColumns]
  );

  return <Select label={columnName} labelHidden value={columnName} options={OPTIONS} onChange={onChange} />;
};

export default ColumnNameSelect;
