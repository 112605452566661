import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import CustomDate from "../CustomDate/CustomDate";
import UserDisplayName from "../UserDisplayName/UserDisplayName";

type CustomerReviewApprovalLabelProps = {
  reviewApproval: api.CustomerReviewApproval;
};

const CustomerReviewApprovalLabel = (props: CustomerReviewApprovalLabelProps) => {
  const { reviewApproval } = props;

  const f = useFormatMessage();

  return (
    <HorizontalStack gap="1" blockAlign={"center"}>
      <UserDisplayName user={reviewApproval.approved_by}>
        <Text variant="bodyMd" as="span">
          {f("details.reviews.approved-by")}
        </Text>
      </UserDisplayName>
      <Text as={"span"} fontWeight={"bold"}>
        {" - "}
        <CustomDate date={reviewApproval.timestamp} />
      </Text>
    </HorizontalStack>
  );
};

export default CustomerReviewApprovalLabel;
