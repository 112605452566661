import RoleTypes from "../../constants/role-types";
import { getBirthDate, getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { CountryRiskInformation, PersonRiskInformation } from "../../types/RiskIndicators";
import DisplayName from "../DisplayName/DisplayName";
import Position from "../Position/Position";

type RiskIndicatorPersonNameProps = {
  riskInformation: CountryRiskInformation | PersonRiskInformation;
};

const RiskIndicatorPersonName = (props: RiskIndicatorPersonNameProps) => {
  const { riskInformation } = props;

  const f = useFormatMessage();

  const displayLabel = riskInformation.name || getFullName(riskInformation.person);
  const showPosition = Boolean(riskInformation.role || riskInformation.owner);

  const positionLabel = riskInformation.types ? riskInformation.types.map((roleType) => f(RoleTypes[roleType])) : [];

  if (riskInformation.owner) {
    positionLabel.push(f("table.row.owner"));
  }

  return (
    <DisplayName
      type={riskInformation.customerType}
      strong
      name={displayLabel}
      birthDate={getBirthDate(riskInformation.person)}
      subtitle={showPosition ? <Position>{positionLabel.join(", ")}</Position> : undefined}
    />
  );
};

export default RiskIndicatorPersonName;
