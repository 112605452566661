import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";

import PencilIcon from "../../../assets/icons/plus-major.svg";
import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import useTags from "../../hooks/useTags";
import { Customer, CustomerDetails } from "../../types/utilities";
import Icon from "../extensions/Icon";
import TagsEditorPopover from "../TagsEditorPopover/TagsEditorPopover";

interface CustomerTagPopoverProps {
  customer: CustomerDetails;
  readonly?: boolean;
}

const CustomerTagPopover = (props: CustomerTagPopoverProps) => {
  const { customer, readonly } = props;

  const { allTags } = useTags();
  const updateQueryData = useCustomerDetailsQueryData(customer);
  const queryClient = useQueryClient();
  const f = useFormatMessage();

  const updateCustomerTags = (customers: Customer[]) =>
    updateQueryData((queryData) => (queryData.labels = customers[0].labels));

  const assignTagMutation = useMutation<api.CustomerListResponse, unknown, string[]>(
    (labelsIds) =>
      api.batchSetLabelsOnCustomers({
        target_ids: [customer.id],
        label_ids: labelsIds
      }),
    {
      onSuccess: ({ customers }) => updateCustomerTags(customers)
    }
  );

  const createAndAssignTagMutation = useMutation<api.CustomerListResponse, unknown, api.TranslationText>(
    (title) =>
      api.batchCreateAndAssignLabelOnCustomers({
        target_ids: [customer.id],
        title
      }),
    {
      onSuccess: ({ customers }) => {
        queryClient.invalidateQueries([QUERIES_KEYS.ALL_TAGS]);
        updateCustomerTags(customers);
      }
    }
  );

  const isLoading = assignTagMutation.isLoading || createAndAssignTagMutation.isLoading;

  const handleSelectionChange = async (selectedTags: api.Label[]) =>
    await assignTagMutation.mutate(selectedTags.map((tag) => tag.id));

  const createTagAndAssign = async (text: api.TranslationText) => await createAndAssignTagMutation.mutate(text);

  const hasTags = Boolean(customer.labels && customer.labels.length > 0);

  return (
    <StyledCustomerTagPopover
      tags={allTags}
      selectedTags={customer.labels}
      onSelectionChange={handleSelectionChange}
      onNewTag={createTagAndAssign}
      size="slim"
      disabled={readonly || isLoading}
      // change button appearance based on if there are any assigned labels or not
      monochrome
      removeUnderline
      tooltip={hasTags ? f("tags.modal.title.edit-tags") : undefined}
      label={hasTags ? undefined : f("common.labels.create-tag")}
      icon={<Icon source={PencilIcon} width="10px" height="10px" useMask />}
    />
  );
};

const StyledCustomerTagPopover = styled(TagsEditorPopover)`
  & .Polaris-Button {
    border: var(--p-border-divider);
    border-radius: var(--p-border-radius-1);
    min-height: 1.325rem;
    box-shadow: none;
    min-width: 1.6rem;

    & *::before {
      margin: 0;
    }
  }

  & .Polaris-Button__Text {
    font-size: var(--p-font-size-75);
  }
`;

export default CustomerTagPopover;
