import React, { PropsWithChildren, ReactNode, useMemo, useState } from "react";
import { ActionList, ActionListItemDescriptor, ActionListProps, Checkbox, Text, VerticalStack } from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";

import { EntityWithId } from "../../types/utilities";
import { filterRegEx } from "../../utils/stringUtils";
import Icon from "../extensions/Icon";
import SearchTextField from "../SearchTextField/SearchTextField";
import { StickyWrapper } from "../StickyWrapper/StickyWrapper";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export interface EntitiesListProps<T extends EntityWithId> extends Omit<ActionListProps, "items"> {
  entities: T[];
  selectedIds: string[];
  readonly?: boolean;
  filterLabel: string;
  hideFilter?: boolean;
  allowMultiple?: boolean;
  selectedFirst?: boolean;
  prefixActionListItemDescriptor?: ActionListItemDescriptor;
  getEntityLabel(entity: T): string;
  getEntityHelpText?(entity: T): string | undefined;
  getEntityPrefix?(entity: T): ReactNode;
  isEntityDisabled(entity: T): boolean;
  onSelect(entity?: T): void;
}

function EntitiesList<T extends EntityWithId>(props: PropsWithChildren<EntitiesListProps<T>>) {
  const {
    entities,
    selectedIds,
    readonly,
    filterLabel,
    hideFilter,
    allowMultiple,
    selectedFirst,
    prefixActionListItemDescriptor,
    getEntityLabel,
    getEntityHelpText = () => null,
    getEntityPrefix = () => null,
    isEntityDisabled,
    onSelect,
    children,
    ...actionListProps
  } = props;

  const [inputValue, setInputValue] = useState("");

  const mapEntityToActionItem = (entity: T): ActionListItemDescriptor =>
    allowMultiple
      ? {
          content: getEntityLabel(entity),
          helpText: (
            <Text as={"span"} truncate>
              {getEntityHelpText(entity)}
            </Text>
          ),
          onAction: () => onSelect(entity),
          prefix: (
            <Checkbox
              label=""
              labelHidden
              checked={selectedIds.includes(entity.id)}
              disabled={readonly || isEntityDisabled(entity)}
            />
          ),
          disabled: readonly || isEntityDisabled(entity),
          truncate: true
        }
      : {
          content: getEntityLabel(entity),
          helpText: (
            <TooltipWrapper content={getEntityHelpText(entity)}>
              <Text as={"span"} truncate>
                {getEntityHelpText(entity)}
              </Text>
            </TooltipWrapper>
          ),
          onAction: () => onSelect(entity),
          suffix: selectedIds.includes(entity.id) ? <Icon source={TickSmallMinor} /> : null,
          prefix: getEntityPrefix(entity),
          active: selectedIds.includes(entity.id),
          disabled: readonly || isEntityDisabled(entity),
          truncate: true
        };

  const filterEntity = (entity: T) =>
    (!isEntityDisabled(entity) || selectedIds.includes(entity.id)) &&
    getEntityLabel(entity).match(filterRegEx(inputValue));

  const sortEntities = (entityA: T, entityB: T) => {
    return selectedIds.includes(entityA.id) === selectedIds.includes(entityB.id)
      ? // both entities are either selected or not selected - sort them by name
        getEntityLabel(entityA).localeCompare(getEntityLabel(entityB))
      : // only one of the entities is selected so if entityA is selected, return -1, otherwise, entityB is selected and return 1
        selectedIds.includes(entityA.id)
        ? -1
        : 1;
  };

  const actionItems = useMemo(() => {
    const sortedEntities = selectedFirst ? entities.sort(sortEntities) : entities;

    const items = sortedEntities.filter(filterEntity).map(mapEntityToActionItem);

    if (prefixActionListItemDescriptor) {
      items.unshift(prefixActionListItemDescriptor);
    }

    return items;
  }, [selectedFirst, entities, selectedIds, inputValue, prefixActionListItemDescriptor]);

  return (
    <StickyWrapper>
      {!hideFilter && (
        <VerticalStack>
          <SearchTextField label={filterLabel} value={inputValue} disabled={readonly} onChange={setInputValue} />
          {children}
        </VerticalStack>
      )}
      <ActionList items={actionItems} {...actionListProps} />
    </StickyWrapper>
  );
}

export default EntitiesList;
