import React, { CSSProperties } from "react";
import { Icon as PolarisIcon, IconProps as PolarisIconProps } from "@shopify/polaris";
import styled from "styled-components";

interface IconProps extends PolarisIconProps {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  useMask?: boolean;
}

const Icon = (props: IconProps) => {
  const { source } = props;

  if (typeof source === "string") {
    // Custom SVG icon
    return <StyledIcon {...props} />;
  } else {
    // Polaris icon
    return <PolarisIcon {...props} />;
  }
};

const StyledIcon = styled.div<IconProps>`
  &:before {
    display: block;
    content: " ";
    width: ${({ width = "20px" }) => width};
    height: ${({ height = "20px" }) => height};
    max-height: ${({ height = "20px" }) => height};
    margin-right: 4px;
    background-repeat: no-repeat;
    background-size: ${({ width = "20px", height = "20px" }) => `${width} ${height}`};
    background-image: ${({ useMask, source }) => (useMask ? "none" : `url(${source})`)};
    mask: ${({ useMask, source }) => (useMask ? `url(${source})` : "initial")};
    mask-size: ${({ width = "20px", height = "20px" }) => `${width} ${height}`};
    background-color: ${({ useMask }) => (useMask ? "currentColor" : "initial")};
  },
`;

export default Icon;
