import React from "react";
import { generatePath, useHistory } from "react-router-dom";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import HistoryLocationState from "../../types/HistoryLocationState";
import { CustomerDetails } from "../../types/utilities";
import AssignmentModal from "../AssignmentModal/AssignmentModal";
import FloatPrimaryButton from "../FloatPrimaryButton/FloatPrimaryButton";

interface AddAssignmentButtonProps {
  customer: CustomerDetails;
  readonly?: boolean;
}

const AddAssignmentButton = (props: AddAssignmentButtonProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();

  const [isAssignmentModalOpen, toggleAssignmentModal, closeAssignmentModal] = useOpenClose();
  const updateQueryData = useCustomerDetailsQueryData(customer);

  const handleSaveAssignment = (assignment: api.Assignment, isNew: boolean) => {
    updateQueryData((queryData) => (queryData.assignments = [assignment, ...(queryData.assignments || [])]));

    closeAssignmentModal();

    if (isNew) {
      history.push(generatePath(ROUTES.ASSIGNMENT_DETAILS, { customerId: customer.id, id: assignment.id }));
    }
  };

  return (
    <>
      <FloatPrimaryButton onClick={toggleAssignmentModal} disabled={readonly}>
        {f("common.buttons.actions.new-assignment")}
      </FloatPrimaryButton>
      {isAssignmentModalOpen && (
        <AssignmentModal customerId={customer.id} onCancel={closeAssignmentModal} onSave={handleSaveAssignment} />
      )}
    </>
  );
};

export default AddAssignmentButton;
