import React, { useState } from "react";
import { Grid, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import CalendarIcon from "../../../../assets/icons/calendar.svg";
import DocumentIcon from "../../../../assets/icons/doc-text.svg";
import IdentityIcon from "../../../../assets/icons/identity.svg";
import api from "../../../api";
import { isKYCSelected } from "../../../helpers/disclosures.helpers";
import { getBirthDate, getFullName } from "../../../helpers/display.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useGetDisclosureCountryRiskInformation from "../../../hooks/useGetDisclosureCountryRiskInformation";
import { useCustomerDetailsQueryData } from "../../../hooks/useQueryData";
import CustomDate from "../../CustomDate/CustomDate";
import DisplayName from "../../DisplayName/DisplayName";
import Icon from "../../extensions/Icon";
import FlatCard, { StyledFlatCardFooter } from "../../FlatCard/FlatCard";
import Language from "../../Language/Language";
import Position from "../../Position/Position";
import QuestionnaireDisplayName from "../../QuestionnaireDisplayName/QuestionnaireDisplayName";
import RoleType from "../../RoleType/RoleType";
import SectionTile from "../../SectionTile/SectionTile";
import WarningBadge from "../../WarningBadge/WarningBadge";

import SignatureActions from "./SignatureActions/SignatureActions";
import CompletedDisclosureDocumentSignature from "./CompletedDisclosureDocumentSignature";
import DisclosureStatus from "./DisclosureStatus";
import ExpiredDisclosureDocumentSignatureBanner from "./ExpiredDisclosureDocumentSignatureBanner";
import PendingDisclosureDocumentSignature from "./PendingDisclosureDocumentSignature";

type DisclosureDocumentSignature = {
  customerId: string;
  customerType: api.CustomerType;
  createdTimestamp: string;
  signature: api.Signature;
  existingEmails: string[]; // all emails used in disclosure document
  readonly?: boolean;
  assignments?: api.Assignment[];
};

const DisclosureDocumentSignature = (props: DisclosureDocumentSignature) => {
  const {
    customerId,
    customerType,
    signature: initialSignature,
    createdTimestamp,
    existingEmails,
    readonly,
    assignments
  } = props;

  const f = useFormatMessage();
  const { getSignatureRiskAnswersCount } = useGetDisclosureCountryRiskInformation();

  const [signature, setSignature] = useState<api.Signature>(initialSignature);

  const updateQueryData = useCustomerDetailsQueryData({ id: customerId, type: customerType });

  const onSignatureDeleted = (deletedSignature: api.Signature) => {
    updateQueryData((queryData) => {
      queryData.disclosure_documents = queryData.disclosure_documents.map((disclosureDocument) => ({
        ...disclosureDocument,
        signatures: disclosureDocument.signatures.filter((signature) => signature.id !== deletedSignature.id)
      }));
    });
  };

  const isSignatureComplete = signature.status === "SIGNED";
  const isSignatureExpired = signature.status === "EXPIRED";

  const signatureRiskAnswersCount = getSignatureRiskAnswersCount(signature);

  const haveHighRiskAnswers = isSignatureComplete && signatureRiskAnswersCount > 0;

  return (
    <StyledFlatCard
      footer={
        isSignatureComplete ? (
          <CompletedDisclosureDocumentSignature signature={signature} />
        ) : (
          <PendingDisclosureDocumentSignature signature={signature} />
        )
      }
      footerBorder
      success={isSignatureComplete}
    >
      <VerticalStack gap="4">
        <Grid columns={{ xs: 1, md: 3, lg: 5 }}>
          <SectionTile title={f("disclosure-card.status-tile")} gap="1" mobileOnly>
            <DisclosureStatus signature={signature} />
          </SectionTile>
          <SectionTile title={f("disclosure-card.recipient-tile")} gap="1" mobileOnly>
            <DisplayName
              type="person"
              name={getFullName(signature.person)}
              birthDate={getBirthDate(signature.person)}
              wrap
              strong
              subtitle={
                initialSignature.role?.type ? (
                  <Position>
                    <RoleType role={initialSignature.role} />
                  </Position>
                ) : undefined
              }
            />
          </SectionTile>
          <VerticalStack gap="6">
            <SectionTile title={f("disclosure-card.scope-tile")} gap="1" mobileOnly>
              {signature.use_verification && (
                <HorizontalStack gap="1" wrap={false}>
                  <Icon source={IdentityIcon} width="25px" height="18px" />
                  <Text as="span" truncate>
                    {f("disclosure-request.field.use_verification")}
                  </Text>
                </HorizontalStack>
              )}
              {isKYCSelected(signature) && (
                <HorizontalStack gap="1" wrap={false}>
                  <Icon source={DocumentIcon} />
                  {signature.use_standard_disclosure && (
                    <Text as="span" truncate>
                      {f("disclosure-request.field.use_standard_disclosure")}
                    </Text>
                  )}
                  {signature.use_pep_disclosure && (
                    <Text as="span" truncate>
                      {f("disclosure-request.field.use_pep_disclosure")}
                    </Text>
                  )}
                </HorizontalStack>
              )}
              {signature.questionnaire_ids?.map((questionnaireId) => (
                <QuestionnaireDisplayName questionnaireId={questionnaireId} key={questionnaireId} />
              ))}
            </SectionTile>
          </VerticalStack>
          <SectionTile title={f("disclosure-card.content-language")} gap="1" mobileOnly>
            <HorizontalStack gap="1" blockAlign="center">
              <Language language={signature.language} />
            </HorizontalStack>
          </SectionTile>
          <SectionTile title={f("disclosure-card.date-tile")} gap="1" mobileOnly>
            <HorizontalStack align={"space-between"}>
              <HorizontalStack gap="1" blockAlign="center" wrap={false}>
                <Icon source={CalendarIcon} />
                <CustomDate date={createdTimestamp} />
              </HorizontalStack>
              {!isSignatureComplete && (
                <SignatureActions
                  customerId={customerId}
                  customerType={customerType}
                  signature={signature}
                  existingEmails={existingEmails}
                  readonly={readonly}
                  assignments={assignments}
                  onSignatureUpdated={setSignature}
                  onSignatureDeleted={onSignatureDeleted}
                />
              )}
            </HorizontalStack>
          </SectionTile>
        </Grid>
        {haveHighRiskAnswers && (
          <WarningBadge>{f("disclosure-status.warning", { count: signatureRiskAnswersCount })}</WarningBadge>
        )}
        {isSignatureExpired && <ExpiredDisclosureDocumentSignatureBanner />}
      </VerticalStack>
    </StyledFlatCard>
  );
};

const StyledFlatCard = styled(FlatCard)<{ success?: boolean }>`
  border-color: ${({ success }) => (success ? "var(--p-border-success-subdued)" : undefined)};

  ${StyledFlatCardFooter} {
    border-color: ${({ success }) => (success ? "var(--p-border-success-subdued)" : undefined)};
    background-color: ${({ success }) => (success ? "var(--p-surface-success-subdued)" : undefined)};
  }
`;

export default DisclosureDocumentSignature;
