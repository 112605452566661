import api from "../api";
import { DEFAULT_COUNTRY } from "../constants/countries";
import { ComplyMatchEvent } from "../types/MatchDetails";
import { first, groupBy, hasContent } from "../utils/collectionUtils";
import { isEmptyString } from "../utils/stringUtils";
import { isNil } from "../utils/util";

import { searchForCountryCode } from "./countries.helpers";

export const isRDCMatch = (match: api.Match) => match.source.source_name === "RiskConnect";

export const isNotComplyAdvantageSource = ({ source_key }: { source_key?: string }) => source_key !== "complyadvantage";

export function groupMatchAttributesByCode(match: api.Match) {
  const nationalities: api.MatchAttribute[] = [];
  const citizenships: api.MatchAttribute[] = [];
  const countries_of_incorporations: api.MatchAttribute[] = [];
  const riskography: api.MatchAttribute[] = [];
  const occupations: api.MatchAttribute[] = [];
  const links: api.MatchAttribute[] = [];
  const remarks: api.MatchAttribute[] = [];
  const avatarImages: api.MatchAttribute[] = [];
  const yearOfIncorporations: api.MatchAttribute[] = [];

  match.attributes.forEach((a) => {
    switch (a.code) {
      case "NAT": // nationality
        nationalities.push(a);
        break;
      case "CTZ": // citizenship
        citizenships.push(a);
        break;
      case "COI": // country of incorporation
        countries_of_incorporations.push(a);
        break;
      case "SEX":
        // gender
        break;
      case "RGP": // riskography
      case "SUM": // summary
      case "BIO": // bio
        riskography.push(a);
        break;
      case "IMG":
        avatarImages.push(a);
        break;
      case "OCU":
        occupations.push(a);
        break;
      case "URL":
        links.push(a);
        break;
      case "RMK":
        remarks.push(a);
        break;
      case "YOI":
        yearOfIncorporations.push(a);
        break;
      default:
        break;
    }
  });

  return {
    nationalities,
    citizenships,
    countries_of_incorporations,
    riskography,
    occupations,
    links,
    remarks,
    avatarImages,
    yearOfIncorporations
  };
}

const groupCountryAttributes = (attributes: api.MatchAttribute[], newMatch: api.NewMatch) => {
  // group attributes by value
  const attributesMap = groupBy(attributes, (attribute) => attribute.value);

  const result = [...attributesMap.keys()].map((attributeValue) => {
    // comply advantage countries are names in english
    const value = searchForCountryCode(attributeValue, "en") || attributeValue;

    const sourcesKeys = first(attributesMap.get(attributeValue))?.source_key?.split(",");
    const sourcesNames = sourcesKeys
      ?.map((sourceKey) => (sourceKey ? newMatch.sources[sourceKey]?.source_name : undefined))
      .filter(Boolean);

    const is_reviewed = Boolean(attributesMap.get(attributeValue)?.every((attribute) => attribute.is_reviewed));

    return { value, sourcesNames, is_reviewed };
  });

  // sort countries by name/country code but make Norway the first one if it exists
  result.sort((attrA, attrB) =>
    attrA.value === DEFAULT_COUNTRY
      ? -10
      : attrB.value === DEFAULT_COUNTRY
        ? 10
        : attrA.value.localeCompare(attrB.value)
  );

  return result;
};

export const groupComplyMatchFieldsByDescription = (newMatch: api.NewMatch) => {
  // do not use complyadvantage sources
  const externalSources = [...newMatch.sanction_sources, ...newMatch.pep_sources];
  const sources = externalSources.map((sourceName) => newMatch.sources[sourceName]);

  const countriesAttributes: api.MatchAttribute[] = [];
  const nationalitiesAttributes: api.MatchAttribute[] = [];

  sources.forEach((source) => {
    source.attributes?.forEach((attribute) => {
      switch (attribute.description) {
        case "Country": {
          countriesAttributes.push(attribute);
          break;
        }
        case "Nationality": {
          nationalitiesAttributes.push(attribute);
          break;
        }
      }
    });
  });

  const countries = groupCountryAttributes(countriesAttributes, newMatch);
  const nationalities = groupCountryAttributes(nationalitiesAttributes, newMatch);

  return { countries, nationalities };
};

export const isConfirmedMatch = (matchCaseDetails: api.MatchCaseDetail) =>
  matchCaseDetails.status === "RESOLVED" &&
  !isNil(matchCaseDetails.resolved_by) &&
  matchCaseDetails.reviews.length > 0 &&
  matchCaseDetails.reviews[0].is_match; // first review is the most recent review

export const isRejectedMatch = (matchCaseDetails: api.MatchCaseDetail) =>
  matchCaseDetails.status === "RESOLVED" &&
  !isNil(matchCaseDetails.resolved_by) &&
  matchCaseDetails.reviews.length > 0 &&
  !matchCaseDetails.reviews[0].is_match; // first review is the most recent review

export const isConfirmedMatchCase = (matchCase: api.MatchCaseSummary) =>
  matchCase.status === "RESOLVED" && matchCase.review?.is_match === true;

export const isRejectedMatchCase = (matchCase: api.MatchCaseSummary) =>
  matchCase.status === "RESOLVED" && matchCase.review?.is_match === false;

export const isUnresolvedMatchCase = (match: api.MatchCaseSummary) =>
  match.status !== "RESOLVED" && isNil(match.review);

export const findNextUnresolvedMatchCase = (matchCases: api.MatchCaseSummary[] = [], currentMatchCaseId: string) =>
  matchCases.find((matchCase) => matchCase.id !== currentMatchCaseId && isUnresolvedMatchCase(matchCase));

export const isEmptyMatchSource = ({ headline, publisher, publication_source, source_url }: api.MatchSource) =>
  ![headline, publisher, publication_source, source_url].every(isEmptyString);

export const sortBirthDatesByRelevance = (sourceDate?: string) => {
  if (isNil(sourceDate)) {
    return undefined;
  }

  return (matchBirthDate1: api.MatchBirthDate, matchBirthDate2: api.MatchBirthDate): number => {
    const date1 = matchBirthDate1.value;
    const date2 = matchBirthDate2.value;

    if (date1 === sourceDate) return -10;
    if (date2 === sourceDate) return 10;

    // check if the birthdate starts with a year
    if (sourceDate.startsWith(date1)) return -1;
    if (sourceDate.startsWith(date2)) return 1;

    return date1.localeCompare(date2);
  };
};

const calcAddressProximity = (source: api.Address, match: api.MatchAddress) => {
  let proximity = 0;

  if (source.country === match.country_code) proximity += 100;
  if (source.city?.toLocaleLowerCase() === match.city?.toLocaleLowerCase()) proximity += 10;

  // TODO check additional address fields?

  return proximity;
};

export const parseMatchAddress = (value: string): api.MatchAddress | undefined => {
  const addressParts: string[] = [];
  const addressCountries: api.CountryEnum[] = [];

  value.split(", ").flatMap((part) => {
    const countryCode = searchForCountryCode(part, "en");
    if (countryCode) {
      addressCountries.push(countryCode);
    } else {
      addressParts.push(part);
    }
  });

  // addresses contains only countries - do not return them
  if (addressParts.length === 0) {
    return undefined;
  }

  return {
    addr1: addressParts.join(", "),
    country_code: first(addressCountries),
    is_reviewed: true
  };
};

export const sortAddressesByRelevance = (sourceAddresses: api.Address[]) => {
  if (!hasContent(sourceAddresses)) {
    return undefined;
  }

  return (matchAddress1: api.MatchAddress, matchAddress2: api.MatchAddress): number => {
    const address1Proximity = sourceAddresses.reduce(
      (sum, address) => sum + calcAddressProximity(address, matchAddress1),
      0
    );

    const address2Proximity = sourceAddresses.reduce(
      (sum, address) => sum + calcAddressProximity(address, matchAddress2),
      0
    );

    return address2Proximity - address1Proximity;
  };
};

const sortMatchEventByDate = (date1Str?: string, date2Str?: string): number => {
  if (!date1Str) return 100;
  if (!date2Str) return -100;

  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);

  return date2.getTime() - date1.getTime();
};

// sort match events by relevance first (if they have new info) and then by date
export const getSortMatchEventByRelevance = (hasNewInfo: (matchEvent: api.MatchEvent) => boolean) => {
  const sortByRelevance = (match1: ComplyMatchEvent, match2: ComplyMatchEvent): number => {
    if (hasNewInfo(match1)) return -1;
    if (hasNewInfo(match2)) return 1;

    return sortMatchEventByDate(match1.amendedDate || match1.date, match2.amendedDate || match2.date);
  };

  return sortByRelevance;
};

// sort match events by relevance first (if they have new info) and then by date
export const getSortMatchSourceByRelevance = (hasNewInfo: (matchEvent: api.MatchSource) => boolean) => {
  const sortByRelevance = (source1: api.MatchSource, source2: api.MatchSource): number => {
    if (hasNewInfo(source1)) return -1;
    if (hasNewInfo(source2)) return 1;

    return sortMatchEventByDate(source1.entity_dt, source2.entity_dt);
  };

  return sortByRelevance;
};

export const isPartialMatchAddress = (address: api.MatchAddress) =>
  [address.addr1, address.addr2, address.city, address.state_prov].some((part) => !isEmptyString(part));
