import React from "react";
import { Banner } from "@shopify/polaris";

import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useGetOnboardingWarnings from "../../../hooks/useGetOnboardingWarnings";

interface DisabledOnboardingWarningProps {
  lookupInfo: api.CompanyInformationLookupInfo;
}

const DisabledOnboardingWarning = (props: DisabledOnboardingWarningProps) => {
  const { lookupInfo } = props;

  const getOnboardingWarnings = useGetOnboardingWarnings();

  const warnings = getOnboardingWarnings(lookupInfo);

  const f = useFormatMessage();

  return (
    <Banner status="critical" title={f("lookup.error.title")}>
      {warnings.map((warning) => (
        <p key={warning}>{warning}</p>
      ))}
    </Banner>
  );
};

export default DisabledOnboardingWarning;
