import React from "react";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import CustomerReviewsList, { CustomerReviewsListProps } from "../CustomerReviewsList/CustomerReviewsList";
import Button from "../extensions/Button";

const CustomerReviewsListWithFilter = (props: CustomerReviewsListProps) => {
  const { reviews, ...customerReviewsListProps } = props;

  const f = useFormatMessage();
  // toggle between showing all reviews or only the first review
  const [showAllReviews, toggleShowAllReviews] = useOpenClose();

  const showToggleButton = reviews.length > 1;

  const reviewsToDisplay = showAllReviews || reviews.length === 0 ? reviews : [reviews[0]];

  const toggleReviewLabel = showAllReviews
    ? f("reviews.labels.toggle.hide-previous", { count: reviews.length - 1 })
    : f("reviews.labels.toggle.show-previous", { count: reviews.length - 1 });

  return (
    <VerticalStack gap="4">
      <CustomerReviewsList reviews={reviewsToDisplay} {...customerReviewsListProps} />
      {showToggleButton && (
        <HorizontalStack>
          <Button onClick={toggleShowAllReviews} disclosure={showAllReviews ? "up" : "down"}>
            {toggleReviewLabel}
          </Button>
        </HorizontalStack>
      )}
    </VerticalStack>
  );
};

export default CustomerReviewsListWithFilter;
