import React, { useMemo } from "react";
import { generatePath } from "react-router-dom";
import { Link, VerticalStack } from "@shopify/polaris";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import api from "../../api";
import { MATCH_CASES_BULK_ACTIONS_CUTOFF } from "../../constants/matches";
import { ROUTES } from "../../constants/routes";
import { isConfirmedMatchCase, isUnresolvedMatchCase } from "../../helpers/match.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import CondensedText from "../CondensedText/CondensedText";
import CustomDate from "../CustomDate/CustomDate";
import MatchCaseStatus from "../MatchCaseStatus/MatchCaseStatus";
import ReactTable from "../ReactTable/ReactTable";

type MatchCasesTableProps = {
  matchCases: api.MatchCaseSummary[];
  customerId: string;
  unresolvedMatchCasesCount: number;
  selectedIds: string[];
  onSelectionChange(selectedIds: string[]): void;
};

const MatchCasesTable = (props: MatchCasesTableProps) => {
  const { matchCases, customerId, unresolvedMatchCasesCount, selectedIds, onSelectionChange } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<api.MatchCaseSummary>();

    const columns = [
      columnHelper.accessor("subject_name", {
        header: f("table.column.name")
      }),
      columnHelper.accessor("match_entity_name", {
        header: f("table.column.name-on-match"),
        enableSorting: true
      }),
      columnHelper.accessor("summary_text", {
        header: f("table.column.details"),
        id: "details",
        cell: ({ row, getValue }) => {
          const matchCase = row.original;

          const isResolved = matchCase.status === "RESOLVED";
          const isUpdatedSinceLastReview = matchCase.review && !isResolved ? true : false;

          return (
            <VerticalStack>
              <Link url={generatePath(ROUTES.MATCH_CASE_DETAILS, { customerId, id: matchCase.id })}>
                <MatchCaseStatus
                  isResolved={isResolved}
                  isConfirmed={isConfirmedMatchCase(matchCase)}
                  isUpdated={isUpdatedSinceLastReview}
                >
                  {getValue()}
                </MatchCaseStatus>
              </Link>
              {matchCase.review && (
                <CondensedText>
                  {matchCase.review.is_match
                    ? f("common.labels.confirmed-by", {
                        name: getUserDisplayName(matchCase.review.reviewed_by),
                        timestamp: new Date(matchCase.review.created_dt)
                      })
                    : f("matches.labels.rejected-by", {
                        name: getUserDisplayName(matchCase.review.reviewed_by),
                        timestamp: new Date(matchCase.review.created_dt)
                      })}
                </CondensedText>
              )}
            </VerticalStack>
          );
        }
      }),
      columnHelper.accessor((row) => row.updated_timestamp || row.timestamp, {
        header: f("table.column.last.updated"),
        id: "last-updated",
        cell: ({ getValue }) => <CustomDate date={getValue()} />
      }),
      columnHelper.accessor("id", {
        header: f("table.column.id"),
        enableSorting: false
      })
    ] as ColumnDef<api.MatchCaseSummary>[];

    return columns;
  }, []);

  return (
    <ReactTable<api.MatchCaseSummary>
      columns={columns}
      data={matchCases}
      selectable={unresolvedMatchCasesCount > MATCH_CASES_BULK_ACTIONS_CUTOFF}
      selectedIds={selectedIds}
      sortLocally
      onSelectionChange={onSelectionChange}
      isRowSelectable={isUnresolvedMatchCase}
      getRowId={(row) => row.id}
      getRowProps={(row) =>
        isConfirmedMatchCase(row) ? { status: "warning" } : row.status !== "RESOLVED" ? { status: "info" } : undefined
      }
    />
  );
};

export default MatchCasesTable;
