import api from "../api";
import { MAX_BIRTH_DATE, MIN_BIRTH_DATE, REQUIRED_FIELDS_FOR_NEW_PERSON } from "../constants/person";
import { FieldCountryMap } from "../types/RiskIndicators";
import { PersonInformation } from "../types/utilities";
import { isValidDate, isValidYear } from "../utils/dateUtils";
import { isEmptyString } from "../utils/stringUtils";
import { isNil } from "../utils/util";

import { getBirthDate, getFullName } from "./display.helpers";

export const isManuallyCreated = (entity: { created_by?: api.User }) => !isNil(entity.created_by);

/**
 * Extract update fields from PersonInformation and return as a person update request
 */
export const convertPersonToUpdateRequest = ({
  first_name,
  last_name,
  birth_date,
  birth_year,
  country_of_origin,
  country_of_citizenship,
  country_of_residence,
  address,
  national_id_country,
  national_id,
  phone,
  email,
  is_pep,
  is_sanctioned
}: PersonInformation): api.UpdatePersonRequest => ({
  first_name,
  last_name,
  birth_date,
  birth_year,
  country_of_origin,
  country_of_citizenship,
  country_of_residence,
  address,
  national_id_country,
  national_id,
  phone,
  email,
  is_pep,
  is_sanctioned
});

export const getPersonCountries = (person?: PersonInformation) => {
  const countriesMap: FieldCountryMap = new Map();

  if (!person) {
    return countriesMap;
  }

  if (person.country_of_origin) {
    countriesMap.set("COUNTRY_OF_ORIGIN", person.country_of_origin!);
  }
  if (person.country_of_citizenship) {
    countriesMap.set("COUNTRY_OF_CITIZENSHIP", person.country_of_citizenship!);
  }
  if (person.country_of_residence) {
    countriesMap.set("COUNTRY_OF_RESIDENCE", person.country_of_residence!);
  }
  if (person.national_id_country) {
    countriesMap.set("NATIONAL_ID_COUNTRY", person.national_id_country!);
  }
  if (person.address?.country) {
    countriesMap.set("ADDRESS", person.address!.country);
  }

  return countriesMap;
};

export const getPersonUniqueCountries = (person?: PersonInformation) => {
  const countriesMap = getPersonCountries(person);

  const countriesSet = new Set(countriesMap.values() as IterableIterator<api.CountryEnum>);

  return [...countriesSet.values()];
};

// a type that has first_name, last_name, birth_date and birth_year
type PersonKeyType = Parameters<typeof getFullName>[0] & Parameters<typeof getBirthDate>[0];

export const getPersonKey = (entity: PersonKeyType) =>
  `${getFullName(entity)}${new Date(getBirthDate(entity)!).getFullYear()}`;

// since owner.person.id and role.person.id do not match even for the same person
// calculate if two persons are the same by using their names and birth year
export const getRoleOrOwnerKey = (entity: api.Role | api.Owner) => getPersonKey(entity.person);

export const isUserCreatedPerson = (personModalData: api.Role | api.Owner) =>
  !personModalData.id || isManuallyCreated(personModalData);

export const isValidPerson = (personModalData: api.Role | api.Owner) => {
  if (isEmptyString(personModalData.person?.first_name)) return false;
  if (isEmptyString(personModalData.person?.last_name)) return false;
  if (isEmptyString(personModalData.person?.address?.country)) return false;
  if (
    isUserCreatedPerson(personModalData) &&
    REQUIRED_FIELDS_FOR_NEW_PERSON.some((field) => !personModalData.person || !personModalData.person[field])
  )
    return false;

  return true;
};

export const isValidPersonDateOfBirth = (personModalData: api.Role | api.Owner) => {
  if (!isValidYear(personModalData.person?.birth_year, MIN_BIRTH_DATE, MAX_BIRTH_DATE)) return false;

  // if creating a new person OR if the birthdate field is not empty then
  // validate the birthdate
  // (which means that if editing an existing persons it is allowed to have an empty birthdate)
  if (isUserCreatedPerson(personModalData) || !isEmptyString(personModalData.person?.birth_date)) {
    if (!isValidDate(personModalData.person?.birth_date, MIN_BIRTH_DATE, MAX_BIRTH_DATE)) return false;
  }

  return true;
};

const trimAddress = (a?: api.Address): api.Address | undefined => {
  if (!a) {
    return undefined;
  }

  return {
    address_line_1: a.address_line_1?.trim() || "",
    address_line_2: a.address_line_2?.trim() || "",
    postal_code: a.postal_code?.trim() || "",
    city: a.city?.trim() || "",
    county: a.county?.trim() || "",
    country: a.country
  };
};

export const convertPersonToCreateRequest = ({
  first_name,
  last_name,
  birth_date,
  country_of_origin,
  country_of_citizenship,
  country_of_residence,
  address,
  national_id_country,
  national_id,
  phone,
  email
}: PersonInformation): api.CreatePersonRequest => ({
  first_name: first_name ? first_name.trim() : "",
  last_name: last_name ? last_name.trim() : "",
  birth_date: birth_date!,
  country_of_origin,
  country_of_citizenship: country_of_citizenship!,
  country_of_residence: country_of_residence!,
  address: trimAddress(address),
  national_id_country,
  national_id,
  phone,
  email
});

const createPersonWithDefaultCountry = (defaultCountry: api.CountryEnum): PersonInformation => {
  return {
    first_name: "",
    last_name: "",
    birth_date: "",
    country_of_origin: defaultCountry,
    country_of_citizenship: defaultCountry,
    country_of_residence: defaultCountry,
    national_id_country: defaultCountry,
    address: { country: defaultCountry }
  };
};

export const createNewRole = (defaultCountry: api.CountryEnum): api.Role => ({
  person: { ...createPersonWithDefaultCountry(defaultCountry), id: "" },
  is_new: false,
  is_ubo: false,
  status: "ACTIVE",
  type: "UNKNOWN_ROLE"
});

export const createNewOwner = (defaultCountry: api.CountryEnum): api.Owner => ({
  person: { ...createPersonWithDefaultCountry(defaultCountry), id: "" },
  is_ubo: false
});

export const createNewPerson = (defaultCountry: api.CountryEnum): api.CreatePersonCustomerRequest => ({
  ...(createPersonWithDefaultCountry(defaultCountry) as Required<PersonInformation>)
});

/**
 * Checks if a person is a UBO and returns true of either the person was
 * manually set/unset as a UBO (custom_is_ubo === true / false)
 * or if the data-service calculated it to be a UBO (is_ubo === true).
 *
 * is_ubo is used only if custom_is_ubo === undefined
 * (hence the use of nullish coalescing - `??`)
 */
export const isUBO = (person: { custom_is_ubo?: boolean; is_ubo: boolean }) => person.custom_is_ubo ?? person.is_ubo;
