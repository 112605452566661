import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import {
  ActionListItemDescriptor,
  DataTable,
  HorizontalStack,
  Link,
  Spinner,
  Text,
  VerticalStack
} from "@shopify/polaris";
import styled from "styled-components";

import ArchiveIcon from "../../../assets/icons/archivebox.svg";
import ExportIcon from "../../../assets/icons/arrow-right-offset-circle.svg";
import DocumentIcon from "../../../assets/icons/doc-text.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EditIcon from "../../../assets/icons/pen-line.svg";
import RestoreIcon from "../../../assets/icons/restore.svg";
import api from "../../api";
import ActionsDropdown from "../../components/ActionsDropdown/ActionsDropdown";
import CondensedText from "../../components/CondensedText/CondensedText";
import DataTableCellChild from "../../components/DataTableCellChild/DataTableCellChild";
import Badge from "../../components/extensions/Badge";
import Icon from "../../components/extensions/Icon";
import RowHighlighter from "../../components/extensions/RowHighlighter";
import TextHighlighter from "../../components/TextHighlighter/TextHighlighter";
import TooltipWrapper from "../../components/TooltipWrapper/TooltipWrapper";
import { ROUTES } from "../../constants/routes";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";

type QuestionnairesTableProps = {
  questionnaires: api.Questionnaire[];
  isRestoringQuestionnaire?: boolean;
  queryText?: string;
  selectedQuestionnaireId?: string;
  readonly?: boolean;
  onPreview(questionnaire: api.Questionnaire): void;
  onArchive(questionnaire: api.Questionnaire): void;
  onRestore(questionnaire: api.Questionnaire): void;
  onExport(questionnaire: api.Questionnaire): void;
};

const QuestionnairesTable = (props: QuestionnairesTableProps) => {
  const {
    questionnaires,
    isRestoringQuestionnaire,
    queryText,
    selectedQuestionnaireId,
    readonly,
    onPreview,
    onArchive,
    onRestore,
    onExport
  } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const history = useHistory();

  const getQuestionnaireActions = (questionnaire: api.Questionnaire): ActionListItemDescriptor[] => {
    const actions = [
      {
        content: f("default.edit"),
        image: EditIcon,
        disabled: readonly,
        onAction: () => history.push(generatePath(ROUTES.SETTINGS_QUESTIONNAIRE_DETAILS, { id: questionnaire.id }))
      },
      {
        content: f("default.preview"),
        image: EyeIcon,
        onAction: () => onPreview(questionnaire)
      },
      questionnaire.is_archived
        ? {
            content: f("default.restore"),
            image: RestoreIcon,
            prefix: isRestoringQuestionnaire ? <Spinner size="small" /> : null,
            disabled: readonly,
            onAction: () => onRestore(questionnaire)
          }
        : {
            content: f("default.archive"),
            image: ArchiveIcon,
            disabled: readonly,
            onAction: () => onArchive(questionnaire)
          }
    ];

    if (features.SETTINGS__REVIEW_CHECKLISTS_IMPORT_EXPORT) {
      actions.push({
        content: "Export", // no need for translation since this action is used to move checklists between tenants
        image: ExportIcon,
        onAction: () => onExport(questionnaire)
      });
    }

    return actions;
  };

  const getCustomerTypeLabel = (questionnaire: api.Questionnaire) => {
    switch (questionnaire.customer_type) {
      case "both":
        return f("common.labels.both-customer-types");
      case "company":
        return f("common.labels.customer-type.company");
      case "person":
        return f("common.labels.customer-type.person");
      default:
        return "-";
    }
  };

  return (
    <DataTable
      columnContentTypes={["text", "text", "text", "text"]}
      headings={[
        f("table.column.name"),
        f("table.column.customerTypes"),
        f("table.column.questions"),
        f("common.buttons.actions.button").toUpperCase()
      ]}
      rows={questionnaires.map((questionnaire) => [
        <RowHighlighter>
          <VerticalStack>
            <Link url={generatePath(ROUTES.SETTINGS_QUESTIONNAIRE_DETAILS, { id: questionnaire.id })} removeUnderline>
              <HorizontalStack gap="2" blockAlign="center" wrap={false}>
                <Icon source={DocumentIcon} />
                <Text as="span" color={questionnaire.is_archived ? "subdued" : undefined}>
                  <TextHighlighter searchWords={[queryText]} textToHighlight={questionnaire.name} />
                </Text>
                {questionnaire.is_archived && (
                  <Badge size="xsmall" status={"new"}>
                    {f("badge.archived")}
                  </Badge>
                )}
              </HorizontalStack>
            </Link>
            {questionnaire.description && (
              <TooltipWrapper content={questionnaire.description}>
                <CondensedText>
                  <Text as={"span"} truncate color={"subdued"}>
                    {questionnaire.description}
                  </Text>
                </CondensedText>
              </TooltipWrapper>
            )}
          </VerticalStack>
        </RowHighlighter>,
        <DataTableCellChild width="15%">
          <Text as="span" color={questionnaire.is_archived ? "subdued" : undefined}>
            {getCustomerTypeLabel(questionnaire)}
          </Text>
        </DataTableCellChild>,
        <StyledCenterCell width="10%">
          <Text as="span" color={questionnaire.is_archived ? "subdued" : undefined}>
            {questionnaire.questions.length}
          </Text>
        </StyledCenterCell>,
        <DataTableCellChild width="10%">
          <ActionsDropdown
            items={getQuestionnaireActions(questionnaire)}
            hideTitle
            forceOpen={isRestoringQuestionnaire && questionnaire.id === selectedQuestionnaireId}
          />
        </DataTableCellChild>
      ])}
    />
  );
};

const StyledCenterCell = styled(DataTableCellChild)`
  text-align: center;
`;

export default QuestionnairesTable;
