import React, { ElementRef, PropsWithChildren, useEffect, useRef } from "react";

// Since @shopify/Polaris data tables don't allow setting or changing the colspan property
// of a table cell, use this class to modify the cell directly
// It's a hack that can be removed once we use a different component to render tables
const DataTableCellChild = (props: PropsWithChildren<React.HTMLProps<HTMLTableHeaderCellElement>>) => {
  const { children, className, onClick, ...tableCellProps } = props;

  const cellRef = useRef<ElementRef<"div">>(null);

  useEffect(() => {
    if (cellRef.current) {
      const tableCell = cellRef.current.parentElement;

      if (tableCell) {
        // @ts-ignore
        Object.keys(tableCellProps).forEach((name) => tableCell?.setAttribute(name, String(tableCellProps[name])));
      }

      return () => {
        for (const name in tableCellProps) {
          tableCell?.setAttribute(name, "");
        }
      };
    }
  }, [cellRef.current, props]);

  return (
    <div ref={cellRef} className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default DataTableCellChild;
