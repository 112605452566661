import api from "../api";

import { isEmptyString } from "./stringUtils";

export const isPDFAttachment = (attachment: api.AttachmentDetails) =>
  attachment.filename.toLowerCase().endsWith(".pdf");

export const addSuffixToFilename = (filename = "", suffix: string) => {
  if (isEmptyString(suffix) || isEmptyString(filename)) {
    return filename;
  }

  const extensionIndex = filename.lastIndexOf(".");

  return `${filename.substring(0, extensionIndex)}${suffix}${filename.substring(extensionIndex)}`;
};

export const cleanupFilename = (filename: string) => {
  if (filename) {
    return filename.split("/").pop();
  }
  return filename;
};

export function convertToValidFilename(name: string) {
  return name.replace(/[\\/|\\:*?"<>]/g, "-");
}
