import api from "../api";
import { traverseSignedSignatures } from "../helpers/disclosures.helpers";
import { getOwnerCountries } from "../helpers/owners.helpers";
import { getPersonCountries } from "../helpers/person.helpers";
import { isGeographicalRiskIndicator } from "../helpers/risk.helpers";
import { CountryRiskInformation, FieldCountryMap } from "../types/RiskIndicators";
import { CustomerDetails, isPersonCustomerDetails } from "../types/utilities";
import { hasContent } from "../utils/collectionUtils";

import useCountries from "./useCountries";
import useTranslatedText from "./useTranslatedText";

const useGetDisclosureCountryRiskInformation = () => {
  const { isHighRiskCountry } = useCountries();
  const t = useTranslatedText();

  const getDisclosuresCountryRiskInformation = (customer: CustomerDetails): CountryRiskInformation[] => {
    const countriesRiskInformation: CountryRiskInformation[] = [];

    traverseSignedSignatures(customer, (signature) => {
      signature.disclosure_answers?.sections
        .flatMap((section) => section.answers)
        .forEach((answer) => {
          answer.risk_indicators?.forEach((riskIndicator) => {
            if (isGeographicalRiskIndicator(riskIndicator.type) && hasContent(riskIndicator.country_codes)) {
              countriesRiskInformation.push({
                signature,
                fields: new Map([[t(answer.question.text), riskIndicator.country_codes!]])
              });
            }
          });
        });
    });

    return countriesRiskInformation;
  };

  const getPersonCountryRiskInformation = (countriesMap: FieldCountryMap): CountryRiskInformation => {
    const highRiskCountryFieldMap = new Map(
      [...countriesMap.entries()].filter(([, countries]) => [countries].flat().filter(isHighRiskCountry).length > 0)
    );

    return { fields: highRiskCountryFieldMap, customerType: "person" };
  };

  const getPersonCustomerCountriesRiskInformation = (person: api.PersonCustomerDetailResponse) => {
    const disclosuresRiskInformation = getDisclosuresCountryRiskInformation(person);

    const personRiskInformation = getPersonCountryRiskInformation(getPersonCountries(person));
    personRiskInformation.person = person;

    if (personRiskInformation.fields.size > 0) {
      return [...disclosuresRiskInformation, personRiskInformation];
    } else {
      return disclosuresRiskInformation;
    }
  };

  const getCompanyCountryRiskInformation = (company: api.CompanyCustomerDetailResponse): CountryRiskInformation[] => {
    const disclosuresRiskInformation = getDisclosuresCountryRiskInformation(company);
    const countriesRiskInformation: CountryRiskInformation[] = [];

    const companyCountryRiskInformation: CountryRiskInformation = {
      fields: new Map(),
      name: company.name,
      customerType: "company"
    };

    if (isHighRiskCountry(company.country)) {
      companyCountryRiskInformation.fields.set("COUNTRY", company.country);
    }
    if (isHighRiskCountry(company.address?.country)) {
      companyCountryRiskInformation.fields.set("ADDRESS", company.address!.country);
    }
    if (isHighRiskCountry(company.business_address?.country)) {
      companyCountryRiskInformation.fields.set("BUSINESS_ADDRESS", company.business_address!.country);
    }
    if (isHighRiskCountry(company.office_address?.country)) {
      companyCountryRiskInformation.fields.set("OFFICE_ADDRESS", company.office_address!.country);
    }

    if (companyCountryRiskInformation.fields.size > 0) {
      countriesRiskInformation.push(companyCountryRiskInformation);
    }

    company.roles.forEach((role) => {
      const roleCountryRiskInformation = getPersonCountryRiskInformation(getPersonCountries(role.person));
      if (roleCountryRiskInformation.fields.size > 0) {
        countriesRiskInformation.push({ ...roleCountryRiskInformation, person: role.person, role });
      }
    });

    company.beneficial_owners.forEach((owner) => {
      const ownerCountryRiskInformation = getPersonCountryRiskInformation(getOwnerCountries(owner));
      if (ownerCountryRiskInformation.fields.size > 0) {
        countriesRiskInformation.push({ ...ownerCountryRiskInformation, person: owner.person, owner });
      }
    });

    return [...disclosuresRiskInformation, ...countriesRiskInformation];
  };

  const getCustomerCountryRiskInformation = (customer: CustomerDetails) => {
    return isPersonCustomerDetails(customer)
      ? getPersonCustomerCountriesRiskInformation(customer)
      : getCompanyCountryRiskInformation(customer);
  };

  const getSignatureRiskAnswersCount = (signature: api.Signature) => {
    if (!signature.disclosure_answers) {
      return 0;
    }

    return (
      signature.disclosure_answers.sections
        .flatMap((section) => section.answers)
        // count the number answers with one or more risk indicators
        .reduce((result, answer) => result + (hasContent(answer.risk_indicators) ? 1 : 0), 0)
    );
  };

  return { getCustomerCountryRiskInformation, getSignatureRiskAnswersCount };
};

export default useGetDisclosureCountryRiskInformation;
