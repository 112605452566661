import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DropZone, HorizontalStack, Text, Thumbnail, VerticalStack } from "@shopify/polaris";

import CloseButton from "../../../assets/icons/xmark.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ErrorType } from "../../types/utilities";
import { base64ToFile, fileToBase64 } from "../../utils/browserUtils";
import { isNil } from "../../utils/util";
import AddButton from "../AddButton/AddButton";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Button from "../extensions/Button";

type ImageDropZoneProps = {
  readonly?: boolean;
  image?: string;
  filename?: string;
  filesizeLimit?: number; // limit the file size in bytes
  onChange(image: string, filename: string): void;
};

const ImageDropZone = (props: ImageDropZoneProps) => {
  const { readonly, image, filename, filesizeLimit, onChange } = props;

  const f = useFormatMessage();
  const { formatNumber } = useIntl();

  const [file, setFile] = useState<File>();
  const [error, setError] = useState<ErrorType>();

  const clearFile = useCallback(() => handleFileChange(undefined), []);

  const isFileSet = Boolean(file);

  const convertImage = async (file: File) => {
    setError(undefined);
    setFile(file);
    const base64File = await fileToBase64(file);
    onChange(base64File, file.name);
  };

  const acceptedFileTypes = "image/png,image/jpg,image/jpeg";

  useEffect(() => {
    if (image && !isFileSet) {
      base64ToFile(image, filename || "logo.png", acceptedFileTypes)
        .then(setFile)
        .catch(setError);
    }
  }, [image, filename, isFileSet]);

  const handleFileChange = async (updatedFile?: File) => {
    if (updatedFile) {
      convertImage(updatedFile);
    } else {
      setFile(undefined);
      setError(undefined);
      onChange("", "");
    }
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[]) => handleFileChange(acceptedFiles[0]),
    []
  );

  return (
    <VerticalStack gap="4">
      <DropZone
        accept={acceptedFileTypes}
        type="image"
        onDrop={handleDropZoneDrop}
        disabled={readonly || !isNil(file)}
        allowMultiple={false}
        outline={false}
        variableHeight
        customValidator={(file) => (filesizeLimit ? file.size <= filesizeLimit : true)}
      >
        <VerticalStack gap="4" inlineAlign={file ? "start" : "center"}>
          {!file && <AddButton>{f("common.labels.file.upload")}</AddButton>}
          {file && (
            <HorizontalStack gap="4" blockAlign="center">
              <Thumbnail alt={file.name} source={window.URL.createObjectURL(file)} />
              <Button icon={CloseButton} plain onClick={clearFile} disabled={readonly} />
              <div>
                {file.name}{" "}
                <Text variant="bodySm" as="p">
                  {formatNumber(file.size / 1024, { maximumFractionDigits: 0 })} KB
                </Text>
              </div>
            </HorizontalStack>
          )}
        </VerticalStack>
      </DropZone>
      {error && <ErrorPanel message={error} />}
    </VerticalStack>
  );
};

export default ImageDropZone;
