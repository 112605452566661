import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import TableRowModifier from "./TableRowModifier";

interface RowHighlighterProps {
  highlight?: boolean;
  warning?: boolean;
  subdued?: boolean;
  clickable?: boolean;
}

const RowHighlighter = (props: PropsWithChildren<RowHighlighterProps>) => {
  const { children, ...rest } = props;

  return <StyledRowHighlighter {...rest}>{children}</StyledRowHighlighter>;
};

const StyledRowHighlighter = styled(TableRowModifier)<RowHighlighterProps>`
  & .Polaris-DataTable__Cell {
    ${(props) =>
      props.highlight &&
      `background: var(--p-surface-highlight-subdued);
      --p-surface-hovered: var(--p-surface-highlight-subdued-hovered);
    `}
    ${(props) =>
      props.warning &&
      `background: var(--p-surface-warning-subdued);
      --p-surface-hovered: var(--p-surface-warning-subdued-hovered);
  `}
  }

  ${(props) => props.subdued && `opacity: 0.9; filter: grayscale(1);`}
  ${(props) => props.clickable && `cursor: pointer;`}
`;

export default RowHighlighter;
