import React, { ElementRef, ForwardedRef, useMemo } from "react";
import { useIntl } from "react-intl";
import { ActionListItemDescriptor, HorizontalStack, Text } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import CalendarIcon from "../../../assets/icons/calendar.svg";
import PencilIcon from "../../../assets/icons/pencil.svg";
import TextIcon from "../../../assets/icons/text-bubble.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { getFullName } from "../../helpers/display.helpers";
import useAuth from "../../hooks/useAuth";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import { Note } from "../../types/Notes";
import { fixedForwardRef } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import Icon from "../extensions/Icon";
import FlatCard from "../FlatCard/FlatCard";
import MarkdownContent from "../MarkdownContent/MarkdownContent";
import UserInitials from "../UserInitials/UserInitials";

import NoteForm from "./NoteForm";

type NoteCardProps<T extends Note> = {
  note: T;
  queryText?: string;
  placeholder?: string;
  onDelete?(): void;
  onSave?(note: T): Promise<T>;
};

const NoteCard = <T extends Note>(props: NoteCardProps<T>, ref: ForwardedRef<ElementRef<"div">>) => {
  const { note, queryText = "", placeholder = "", onDelete, onSave, ...rest } = props;

  const f = useFormatMessage();
  const { formatDate } = useIntl();
  const { user } = useAuth();
  const { isPermittedTo } = usePermissions();
  const [isEditing, toggleEditing] = useOpenClose();

  // only the user who created the note OR KDK Admin can edit/delete the note
  const isEditable = user?.id === note.user?.id || isPermittedTo(PERMISSIONS_TYPES.ADMIN_USER);

  const allowDelete = isEditable && onDelete !== undefined;
  const allowUpdate = isEditable && onSave !== undefined;

  const showEditor = isEditing && onSave !== undefined;

  const highlightedNote = useMemo(
    () =>
      // highlight queryText in note.text by marking it as bold (**) using a regex to replace all occurrences of
      // queryText (case-insensitive) with **$1** were $1 is the matching text within note.text
      isEmptyString(queryText) ? note.text : note.text.replace(new RegExp(`(${queryText.trim()})`, "ig"), `**$1**`),
    [note.text, queryText]
  );

  const saveMutation = useMutation<unknown, unknown, string>(
    (text) => (onSave ? onSave({ ...note, text }) : Promise.resolve(note)),
    {
      onSuccess: toggleEditing
    }
  );

  const actions: ActionListItemDescriptor[] = [];
  if (allowUpdate) {
    actions.push({
      content: f("default.edit"),
      onAction: toggleEditing,
      image: PencilIcon
    });
  }
  if (allowDelete) {
    actions.push({
      content: f("default.delete"),
      onAction: onDelete,
      image: TrashIcon
    });
  }

  return (
    <FlatCard
      ref={ref}
      footer={
        showEditor ? (
          <NoteForm
            value={note.text}
            placeholder={placeholder}
            createNoteMutation={saveMutation}
            onCancel={toggleEditing}
          />
        ) : (
          <HorizontalStack gap="1" blockAlign="start" wrap={false}>
            <StyledIcon source={TextIcon} />
            <MarkdownContent content={highlightedNote} />
          </HorizontalStack>
        )
      }
      {...rest}
    >
      <HorizontalStack align="space-between" blockAlign="baseline">
        <HorizontalStack gap="8" blockAlign="center">
          <HorizontalStack gap="1" blockAlign="center">
            <Icon source={CalendarIcon} />
            <Text variant="bodyMd" as="span">
              {formatDate(note.date)}
            </Text>
          </HorizontalStack>
          {note.user && (
            <HorizontalStack gap="1" blockAlign="center">
              <UserInitials user={note.user} />
              <Text variant="bodyMd" as="span">
                {getFullName(note.user)}
              </Text>
            </HorizontalStack>
          )}
        </HorizontalStack>
        {actions.length > 0 && <ActionsDropdown items={actions} hideTitle plain />}
      </HorizontalStack>
    </FlatCard>
  );
};

const StyledIcon = styled(Icon)`
  // adjust icon position to better align with first line of text in the note
  margin-top: var(--p-space-05);
`;

export default fixedForwardRef(NoteCard);
