import api from "../api";

export const ONBOARDING_STATUSES: api.OnboardingStatus[] = [
  "FETCHING_DATA",
  "SCREENING_CONFIG",
  // "ASSIGNMENT_CONFIG",
  "SIGNATURE_CONFIG",
  "READY"
];

export const ONBOARDING_SCREENING_TYPES: api.ScreeningType[] = ["MONITORING", "ONE_TIME"];

export const DEFAULT_ONBOARDING_CONFIGURATION: api.PersonOnboardingSettings = {
  screening_type: "MONITORING",
  signature_settings: {
    use_verification: true,
    use_standard_disclosure: true,
    use_pep_disclosure: false,
    language: "nb",
    send_email: true,
    questionnaire_ids: []
  }
};
