import { PropsWithChildren } from "react";
import { Grid, Text } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";

const DisclosureDocumentSignatureHeaders = (props: PropsWithChildren) => {
  const f = useFormatMessage();

  return (
    <StyledHeaders {...props}>
      <Grid columns={{ xs: 1, md: 3, lg: 5 }}>
        <Text as="span" variant="bodySm" fontWeight="semibold">
          {f("disclosure-card.status-tile")}
        </Text>
        <Text as="span" variant="bodySm" fontWeight="semibold">
          {f("disclosure-card.recipient-tile")}
        </Text>
        <Text as="span" variant="bodySm" fontWeight="semibold">
          {f("disclosure-card.scope-tile")}
        </Text>
        <Text as="span" variant="bodySm" fontWeight="semibold">
          {f("disclosure-card.content-language")}
        </Text>
        <Text as="span" variant="bodySm" fontWeight="semibold">
          {f("disclosure-card.date-tile")}
        </Text>
      </Grid>
    </StyledHeaders>
  );
};

const StyledHeaders = styled.div`
  padding: 0 var(--p-space-4);
`;

export default DisclosureDocumentSignatureHeaders;
