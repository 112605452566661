import { Card, Scrollable, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

import CompletedSignature from "./CompletedSignature";

type CustomerReviewDisclosuresSectionProps = {
  signatures: api.Signature[];
};

const CustomerReviewDisclosuresSection = (props: CustomerReviewDisclosuresSectionProps) => {
  const { signatures } = props;

  const f = useFormatMessage();

  return (
    <Card>
      <Card.Section title={f("common.labels.kyc-responses")}>
        <Scrollable vertical horizontal={false}>
          <VerticalStack gap="2">
            {signatures.map((signature) => (
              <CompletedSignature signature={signature} key={signature.id} />
            ))}
          </VerticalStack>
        </Scrollable>
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewDisclosuresSection;
