import React, { PropsWithChildren } from "react";
import ReactCountryFlag from "react-country-flag";
import { HorizontalStack, Text, TextProps } from "@shopify/polaris";

import api from "../../api";
import { searchForCountryCode } from "../../helpers/countries.helpers";
import useCountries from "../../hooks/useCountries";
import { isWindows10 } from "../../utils/browserUtils";
import { isNil } from "../../utils/util";

const useSVG = isWindows10();

type CountryProps = Omit<TextProps, "as" | "children"> & {
  country?: api.CountryEnum | string;
  language?: api.LanguageEnum;
  hideFlag?: boolean;
  hideLabel?: boolean;
  shortName?: boolean;
};

const Country = (props: PropsWithChildren<CountryProps>) => {
  const { country, language, hideFlag, hideLabel, shortName, children, ...textTypeProps } = props;

  const { getCountryName } = useCountries();

  if (!country && isNil(country)) {
    return null;
  }

  const countryCode = country.length === 2 ? country : searchForCountryCode(country, language);

  if (!countryCode) {
    return null;
  }

  const shouldRenderText = !hideLabel || children;

  const displayLabel = shortName
    ? getCountryName(countryCode).split(",")[0]
    : getCountryName(countryCode).replaceAll(",", ", ");

  if (!shouldRenderText && !hideFlag) {
    return <ReactCountryFlag countryCode={countryCode} svg={useSVG} />;
  }

  return (
    <HorizontalStack gap="1" blockAlign="center" wrap={false}>
      {!hideFlag && <ReactCountryFlag countryCode={countryCode} svg={useSVG} />}
      {shouldRenderText && (
        <Text as="span" {...textTypeProps}>
          {!hideLabel && displayLabel}
          {children}
        </Text>
      )}
    </HorizontalStack>
  );
};

export default Country;
