import React from "react";

import api from "../../api";
import { LANGUAGES_TO_COUNTRY_CODES } from "../../constants/languages";
import useGetLanguageName from "../../hooks/useGetLanguageName";
import Country from "../Country/Country";

interface LanguageProps {
  language?: api.LanguageEnum;
}

const Language = (props: LanguageProps) => {
  const { language } = props;

  const getLanguageName = useGetLanguageName();

  return (
    <Country country={language ? LANGUAGES_TO_COUNTRY_CODES[language] : undefined} hideLabel>
      {getLanguageName(language)}
    </Country>
  );
};

export default Language;
