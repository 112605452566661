import { useMemo } from "react";

import { MLRO_GROUP_KEY } from "../constants/permissions";

import useGetUsers from "./useGetUsers";

const useGetMLROUsers = () => {
  const { activeUsers, groups } = useGetUsers();

  const MLRO_GROUP_ID = groups.find((group) => group.role_key === MLRO_GROUP_KEY)?.id;

  return useMemo(
    () => activeUsers.filter((user) => MLRO_GROUP_ID && user.groups?.includes(MLRO_GROUP_ID)),
    [activeUsers]
  );
};

export default useGetMLROUsers;
