import { Card, HorizontalStack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import { OptionalCustomerReviewRequest } from "../../types/CustomerReview";
import CustomerReviewAcceptForm from "../CustomerReviewAcceptForm/CustomerReviewAcceptForm";
import CustomerReviewApprovalForm from "../CustomerReviewApprovalForm/CustomerReviewApprovalForm";

type CustomerReviewApprovalSectionProps = {
  review: OptionalCustomerReviewRequest;
  readonly?: boolean;
  onAcceptedChange(accepted: boolean): void;
  onChange(review: OptionalCustomerReviewRequest): void;
};

const CustomerReviewApprovalSection = (props: CustomerReviewApprovalSectionProps) => {
  const { review, readonly, onAcceptedChange, onChange } = props;

  return (
    <Card>
      <Card.Section>
        <VerticalStack gap="4" inlineAlign={"stretch"}>
          <CustomerReviewAcceptForm
            accepted={review.accepted}
            onAcceptedChange={onAcceptedChange}
            readonly={readonly}
          />
          <HorizontalStack>
            <StyledBorderedWrapper>
              <CustomerReviewApprovalForm review={review} onChange={onChange} readonly={readonly} />
            </StyledBorderedWrapper>
          </HorizontalStack>
        </VerticalStack>
      </Card.Section>
    </Card>
  );
};

const StyledBorderedWrapper = styled.div`
  padding: var(--p-space-1) var(--p-space-4);
  border: solid var(--p-border-width-1) var(--p-border-subdued);
  border-radius: var(--p-border-radius-1);

  &:hover {
    border-color: var(--p-border-hovered);
  }
`;

export default CustomerReviewApprovalSection;
