import React, { PropsWithChildren } from "react";
import { HorizontalStack, Link, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import WarningIcon from "../../../../assets/icons/triangle-filled-exclamation.svg";
import useFormatMessage from "../../../hooks/useFormatMessage";
import Icon from "../../extensions/Icon";
import Warning from "../../Warning/Warning";

interface WarningWrapperProps {
  showWarning?: boolean;
  description?: string;
  url?: string;
}

const WarningWrapper = (props: PropsWithChildren<WarningWrapperProps>) => {
  const { showWarning, description, url, children } = props;

  const f = useFormatMessage();

  return (
    <HorizontalStack gap="2" wrap={false} blockAlign="baseline">
      {showWarning && <Icon source={WarningIcon} />}
      <VerticalStack gap="2">
        {children}
        {description && <Warning>{description}</Warning>}
        {url && (
          <HorizontalStack gap="1" wrap={false}>
            <StyledLinkLabel>
              <Text as="span">{f("common.labels.more-information-at")}</Text>
            </StyledLinkLabel>
            <StyledLink>
              <Text as="span">
                <Link url={url} external monochrome={false}>
                  {f("default.support-portal")}
                </Link>
              </Text>
            </StyledLink>
          </HorizontalStack>
        )}
      </VerticalStack>
    </HorizontalStack>
  );
};

const StyledLinkLabel = styled.span`
  font-size: var(--p-font-size-75);
  line-height: var(--p-font-line-height-1);
`;

const StyledLink = styled(StyledLinkLabel)`
  color: var(--p-interactive);
`;

export default WarningWrapper;
