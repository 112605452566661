import { Features } from "../constants/features";

import useFeatures from "./useFeatures";

/**
 * Projects should be enabled/accessible for users/orgs with either
 * of the PROJECTS or VITEC_PROJECTS feature
 */
const isProjectsEnabled = (features: Partial<Features>) => {
  return features.PROJECTS || features.VITEC_PROJECTS ? true : false;
};

/**
 * In order to _create_ a project, or add customers to a project,
 * the user/org. must have the PROJECTS feature
 */
const isCreateOrAddToProjectEnabled = (features: Partial<Features>) => {
  return features.PROJECTS ? true : false;
};

const useProjectPermissions = () => {
  const features = useFeatures();

  return {
    isProjectsEnabled: isProjectsEnabled(features),
    isCreateOrAddToProjectEnabled: isCreateOrAddToProjectEnabled(features)
  };
};

export default useProjectPermissions;
