import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { ButtonProps, DropZone, Toast, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { ACCEPTED_FILE_TYPES, MAXIMUM_FILE_SIZE_IN_BYTES_FOR_UPLOAD } from "../../constants/files";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isValidFile } from "../../helpers/attachments";
import useAttachmentsAPI from "../../hooks/useAttachmentsAPI";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData from "../../hooks/useQueryData";
import { Entity, isAssignment } from "../../types/utilities";
import { cleanupFilename } from "../../utils/filenameUtils";
import { bytesToMegaBytes } from "../../utils/stringUtils";
import AcceptedFileTypesLabel from "../AcceptedFileTypesLabel/AcceptedFileTypesLabel";
import AddButton from "../AddButton/AddButton";

const DEFAULT_DOCUMENT_FILENAME = "default-document";

export type AddFileButtonProps<T extends Entity> = ButtonProps & {
  entity: T;
  readonly?: boolean;
  hideAcceptedFileTypesLabel?: boolean;
  onFileUploadingStart(filesCount: number): void;
  onFileUploadingFinish(addedAttachments: api.AttachmentDetails[]): void;
};

const AddFileButton = <T extends Entity>(props: AddFileButtonProps<T>) => {
  const { entity, readonly, hideAcceptedFileTypesLabel, onFileUploadingStart, onFileUploadingFinish, ...buttonProps } =
    props;

  const f = useFormatMessage();
  const features = useFeatures();

  const [uploadError, setUploadedError] = useState<string | undefined>();
  const clearError = () => setUploadedError(undefined);

  const { entityId, addAttachment } = useAttachmentsAPI(entity);

  const showPlanConversion = !features.ATTACHMENTS;

  const attachmentsQueryKey = [QUERIES_KEYS.ATTACHMENTS, entityId];

  const { updateQueryData } = useQueryData<api.AttachmentDetailsList>(attachmentsQueryKey);

  const uploadFile = async (file: File) => {
    const filename = cleanupFilename(file.name) || DEFAULT_DOCUMENT_FILENAME;

    try {
      // get upload_url
      const { upload_url, upload_id } = await api.generateAttachmentUploadIdAndUrl({
        filename,
        content_type: file.type,
        content_length: file.size
      });

      // upload the file to the cloud
      await fetch(upload_url, {
        method: "PUT",
        headers: {
          // Set special headers that *must* be passed with requests to
          // the signed upload URL
          "X-Upload-Content-Length": `${file.size}`,
          "X-Goog-Content-Length-Range": `0,${MAXIMUM_FILE_SIZE_IN_BYTES_FOR_UPLOAD}`
        },
        body: file
      });

      // save the result
      const request = { filename, upload_id };
      const attachmentDetails = await addAttachment(entityId, request);
      // TODO remove this code once the API is fixed
      // hack to fix API no returning assignment details in attachment that were added to an assignment
      if (isAssignment(entity)) {
        attachmentDetails.assignment_id = entity.id;
        attachmentDetails.assignment_name = entity.name;
        attachmentDetails.assignment_purpose = entity.purpose || entity.purpose_desc;
        attachmentDetails.assignment_is_archived = false;
      }

      updateQueryData((queryData) => (queryData.attachment_list = [attachmentDetails, ...queryData.attachment_list]));

      return attachmentDetails;
    } catch (error: unknown) {
      console.log(error);
      setUploadedError(f("error.message.unknown"));
    }
  };

  const reportFileSizeExceeded = (size: number) => {
    Sentry.captureMessage(
      f("common.errors.file-size-exceeded", { max: bytesToMegaBytes(MAXIMUM_FILE_SIZE_IN_BYTES_FOR_UPLOAD) }) +
        ` filesize: ${size} bytes`,
      {
        level: "debug",
        tags: {
          location: "AddFileButton:handleFilesUpload[MAX_FILE_SIZE]"
        }
      }
    );
  };

  const handleFilesUpload = async (files: File[]) => {
    const attachments: api.AttachmentDetails[] = [];
    const validFilesCount = files.filter(isValidFile).length;

    onFileUploadingStart(validFilesCount);

    for (const file of files) {
      if (isValidFile(file)) {
        const attachment = await uploadFile(file);
        if (attachment) {
          attachments.push(attachment);
        }
      } else {
        setUploadedError(
          f("common.errors.file-size-exceeded", { max: bytesToMegaBytes(MAXIMUM_FILE_SIZE_IN_BYTES_FOR_UPLOAD) })
        );
        reportFileSizeExceeded(file.size);
      }
    }

    onFileUploadingFinish(attachments);
  };

  const disabled = readonly || showPlanConversion;

  return (
    <VerticalStack gap={"1"}>
      <DropZone
        outline={false}
        variableHeight
        disabled={readonly}
        accept={ACCEPTED_FILE_TYPES.join(",")}
        onDropAccepted={handleFilesUpload}
      >
        <AddButton disabled={disabled} {...buttonProps}>
          {f("attachments.upload.button")}
        </AddButton>
      </DropZone>
      {!hideAcceptedFileTypesLabel && <AcceptedFileTypesLabel />}
      {uploadError && <Toast content={uploadError} error onDismiss={clearError} />}
    </VerticalStack>
  );
};

export default AddFileButton;
