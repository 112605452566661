import { InfoUpdateFieldChange } from "../types/CustomerUpdates";
import { isEmpty } from "../utils/util";

const isEmptyChange = (change: InfoUpdateFieldChange) => isEmpty(change.old) && isEmpty(change.new);

export function hasUnreviewedChanges(changes?: InfoUpdateFieldChange[]) {
  if (!changes) {
    return false;
  }

  return changes.some((change) => !change.isReviewed && !isEmptyChange(change));
}
