import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import useMatchRelationLabel from "../../hooks/useMatchRelationLabel";
import useRDCMatch from "../../hooks/useRDCMatch";
import { MatchDetailsProps } from "../../types/MatchDetails";
import { groupBy } from "../../utils/collectionUtils";
import CondensedText from "../CondensedText/CondensedText";
import MatchCategorySection from "../MatchCategorySection/MatchCategorySection";
import MatchValuesCollapsible from "../MatchKeyInformationSection/MatchValuesCollapsible";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";

const MatchDetailsRelationsSection = (props: MatchDetailsProps) => {
  const { matchDetails } = props;

  const f = useFormatMessage();

  const { match, hasNewInfo } = useRDCMatch(matchDetails);
  const getMatchRelationLabel = useMatchRelationLabel();

  const relationsByType = groupBy(match.relations, (relation) => relation.type);
  const relations = [...relationsByType.entries()].map(([type, entries]) => ({
    type,
    newInfo: entries.some(hasNewInfo),
    names: entries.map((entity) => entity.entity_name).join(", ")
  }));

  return (
    <MatchCategorySection title={f("matches.labels.relations")}>
      {relations.length > 0 && (
        <MatchValuesCollapsible
          label={f("matches.titles.relations")}
          labelHidden
          values={relations}
          renderValue={(relation, index) => (
            <StyledContainer key={index}>
              <VerticalStack>
                <HorizontalStack gap="2" blockAlign={"start"}>
                  <CondensedText>{getMatchRelationLabel(relation.type)}</CondensedText>
                  {relation.newInfo && <NewInfoBadge />}
                </HorizontalStack>
                <Text as="span" variant="bodySm" fontWeight={"semibold"}>
                  {relation.names}
                </Text>
              </VerticalStack>
            </StyledContainer>
          )}
        />
      )}
    </MatchCategorySection>
  );
};

const StyledContainer = styled.div`
  margin-bottom: var(--p-space-2);
`;

export default MatchDetailsRelationsSection;
