import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import AddButton from "../AddButton/AddButton";
import DisclosureDocumentSignatureHeaders from "../DisclosureDocumentSignatureHeaders/DisclosureDocumentSignatureHeaders";
import HideOnMobile from "../HideOnMobile/HideOnMobile";

import DisclosureDocumentSignature from "./DisclosureDocumentSignature/DisclosureDocumentSignature";
import { createDisclosurePagePath } from "./DisclosureDocumentSection.helpers";

type DisclosureDocumentSectionProps = {
  customerId: string;
  assignmentId?: string;
  customerType: api.CustomerType;
  disclosureDocuments: api.CustomerDisclosureDocument[];
  readonly?: boolean;
  addButtonLabel: string;
  emptyLabel: string;
  assignments?: api.Assignment[];
};

const DisclosureDocumentSection = (props: DisclosureDocumentSectionProps) => {
  const {
    customerId,
    assignmentId,
    customerType,
    disclosureDocuments,
    readonly,
    addButtonLabel,
    emptyLabel,
    assignments
  } = props;

  const f = useFormatMessage();

  const disclosureDocumentSignatures = disclosureDocuments
    .map((disclosureDocument) => {
      // Get all emails used in document
      // -> emails should be unique for each document
      const existingEmails = disclosureDocument.signatures
        .map((s) => s.email)
        .filter((email): email is string => email !== undefined);

      return disclosureDocument.signatures.map((disclosureDocumentSignature) => (
        <DisclosureDocumentSignature
          key={disclosureDocumentSignature.id}
          customerId={customerId}
          customerType={customerType}
          signature={disclosureDocumentSignature}
          existingEmails={existingEmails}
          createdTimestamp={disclosureDocument.created_dt}
          readonly={readonly}
          assignments={assignments}
        />
      ));
    })
    .flat();

  return (
    <VerticalStack gap="4">
      <HorizontalStack align={"space-between"} blockAlign={"center"}>
        <Text variant="headingMd" as="h2">
          {f("disclosure-documents.title")}
        </Text>
        <AddButton url={createDisclosurePagePath(customerId, customerType, assignmentId)} disabled={readonly}>
          {addButtonLabel}
        </AddButton>
      </HorizontalStack>
      {disclosureDocumentSignatures.length > 0 && (
        <HideOnMobile>
          <DisclosureDocumentSignatureHeaders />
        </HideOnMobile>
      )}
      {disclosureDocumentSignatures}
      {disclosureDocumentSignatures.length === 0 && <Text as="span">{emptyLabel}</Text>}
    </VerticalStack>
  );
};

export default DisclosureDocumentSection;
