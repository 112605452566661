import React, { useCallback, useMemo, useState } from "react";
import { Autocomplete, Card, OptionList, TextField, TextFieldProps } from "@shopify/polaris";
import styled from "styled-components";

import GlobeIcon from "../../../assets/icons/globe.svg";
import api from "../../api";
import useCountries from "../../hooks/useCountries";
import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import { filterRegEx, isEmptyString } from "../../utils/stringUtils";
import Country from "../Country/Country";
import Icon from "../extensions/Icon";

type CountrySelectProps = Omit<TextFieldProps, "autoComplete" | "value"> & {
  value?: api.CountryEnum;
  autoComplete?: "on" | "off";
  renderAsOptionsList?: boolean;
  onSelect(country?: api.CountryEnum): void;
  excludedCountries?: api.CountryEnum[];
};

const CountrySelect = (props: CountrySelectProps) => {
  const {
    value,
    label,
    autoComplete,
    renderAsOptionsList,
    placeholder,
    onClearButtonClick,
    excludedCountries,
    onSelect,
    ...textFieldProps
  } = props;

  const f = useFormatMessage();

  const { getCountriesOptions, getCountryName } = useCountries();
  const language = useLanguage();
  const deselectedOptions = useMemo(
    () =>
      getCountriesOptions(excludedCountries).map((option) => ({
        ...option,
        media: <Country country={option.value} hideLabel />
      })),
    [excludedCountries, language]
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>(value ? [value] : []);
  const [inputValue, setInputValue] = useState<string>(value ? getCountryName(value) : "");
  const [options, setOptions] = useState(deselectedOptions);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegEx(value)));
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = (selected: api.CountryEnum[]) => {
    setSelectedOptions(selected);
    onSelect(selected[0]);
    setInputValue(getCountryName(selected[0] || ""));
  };

  const clearInput = () => {
    updateText("");
    onSelect();
  };

  const TextFieldComponent = renderAsOptionsList ? TextField : Autocomplete.TextField;

  const textField = (
    <TextFieldComponent
      onChange={updateText}
      {...textFieldProps}
      label={label || f("common.labels.country")}
      value={inputValue}
      prefix={
        selectedOptions.length === 0 || isEmptyString(inputValue) ? (
          <Icon source={GlobeIcon} />
        ) : (
          <Country country={selectedOptions[0]} hideLabel />
        )
      }
      autoComplete={autoComplete || "off"}
      placeholder={placeholder || f("common.country-select.choose-country-option")}
      clearButton={renderAsOptionsList ? false : !textFieldProps.disabled && textFieldProps.clearButton !== false}
      onClearButtonClick={onClearButtonClick || clearInput}
      onBlur={() => setInputValue(value ? getCountryName(value) : "")}
    />
  );

  return renderAsOptionsList ? (
    <Card>
      <Card.Section subdued>
        <StyledWrapper>
          {textField}
          <OptionList options={options} selected={selectedOptions} onChange={updateSelection} />
        </StyledWrapper>
      </Card.Section>
    </Card>
  ) : (
    <Autocomplete options={options} selected={selectedOptions} onSelect={updateSelection} textField={textField} />
  );
};

const StyledWrapper = styled.div`
  position: relative;
  min-width: 30ch;
  max-width: 30ch;

  & > .Polaris-Labelled--hidden {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--p-surface-subdued);
  }
`;

export default CountrySelect;
