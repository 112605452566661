import React, { useState } from "react";
import { HorizontalStack, Text } from "@shopify/polaris";
import { TabDescriptor } from "@shopify/polaris/build/ts/latest/src/components/Tabs/types";

import api from "../../api";
import { hasOwnershipUpdates } from "../../helpers/ownership-updates.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { appendCounter } from "../../utils/stringUtils";
import AddOwnerButton from "../AddOwnerButton/AddOwnerButton";
import AddRoleButton from "../AddRoleButton/AddRoleButton";
import CompanyOwners from "../CompanyOwners/CompanyOwners";
import { hasMultipleShareClasses } from "../CompanyOwners/CompanyOwnersHeader/CompanyOwnersHeader.helpers";
import CompanyRoles from "../CompanyRoles/CompanyRoles";
import KDKTabs from "../KDKTabs/KDKTabs";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";

const OWNERS_TAB_INDEX = 0;
const ROLES_TAB_INDEX = 1;

type RolesAndOwnersSectionProps = {
  customer: api.CompanyCustomerDetailResponse;
  readonly?: boolean;
};

const RolesAndOwnersSection = (props: RolesAndOwnersSectionProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();

  const [selectedTabIndex, setSelectedTabIndex] = useState(OWNERS_TAB_INDEX);

  const hasNewRoles = customer.roles.some((role) => role.is_new);

  const showOwnersIndicator =
    hasOwnershipUpdates(customer) || customer.has_outstanding_shares || hasMultipleShareClasses(customer.share_classes);

  const ownersTabTitle = appendCounter(
    f("company.owners.title"),
    customer.beneficial_owners.filter((owner) => !owner.deleted).length
  );
  const rolesTabTitle = appendCounter(f("company.roles.title"), customer.roles.length);

  const tabs: TabDescriptor[] = [
    {
      id: "owners",
      content: (
        <HorizontalStack gap="1">
          <Text as="span" fontWeight="bold">
            {ownersTabTitle}
          </Text>
          {showOwnersIndicator && <NewInfoBadge />}
        </HorizontalStack>
      )
    },
    {
      id: "roles",
      content: (
        <HorizontalStack gap="1">
          <Text as="span" fontWeight="bold">
            {rolesTabTitle}
          </Text>
          {hasNewRoles && <NewInfoBadge />}
        </HorizontalStack>
      )
    }
  ];

  const screeningRoles = (customer.screening?.roles || []).map((role) => role.role);
  const screeningOwners = (customer.screening?.beneficial_owners || []).map((beneficialOwner) => beneficialOwner.owner);

  const isScreeningActive =
    customer.screening_status.screening_type === "MONITORING" && customer.screening_status.screening_status !== "DONE";

  return (
    <div>
      {selectedTabIndex === OWNERS_TAB_INDEX && <AddOwnerButton customer={customer} readonly={readonly} />}
      {selectedTabIndex === ROLES_TAB_INDEX && <AddRoleButton customer={customer} readonly={readonly} />}
      <KDKTabs tabs={tabs} selected={selectedTabIndex} onSelect={setSelectedTabIndex}>
        {selectedTabIndex === OWNERS_TAB_INDEX && (
          <CompanyOwners
            customer={customer}
            readonly={readonly}
            screeningOwners={screeningOwners}
            isScreeningActive={isScreeningActive}
            hideAddButton
          />
        )}
        {selectedTabIndex === ROLES_TAB_INDEX && (
          <CompanyRoles
            customer={customer}
            readonly={readonly}
            screeningRoles={screeningRoles}
            isScreeningActive={isScreeningActive}
            hideTitle
            hideAddButton
          />
        )}
      </KDKTabs>
    </div>
  );
};

export default RolesAndOwnersSection;
