import React from "react";
import { Redirect } from "react-router-dom";

import { ROUTES } from "../../constants/routes";
import { CUSTOMERS_LIST_SEARCH } from "../../constants/session-state";
import useClearLastWindowLocationSearch from "../../hooks/useClearLastWindowLocationSearch";

const RedirectToCustomersList = () => {
  const clearLastWindowLocationSearch = useClearLastWindowLocationSearch(CUSTOMERS_LIST_SEARCH);

  clearLastWindowLocationSearch();

  return <Redirect to={ROUTES.CUSTOMERS} />;
};

export default RedirectToCustomersList;
