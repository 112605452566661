import { useCallback } from "react";

import { CountryFieldType } from "../types/RiskIndicators";

import useFormatMessage from "./useFormatMessage";

const useGetCountryFieldTranslation = () => {
  const f = useFormatMessage();

  const getCountryFieldTranslation = useCallback((countryField: CountryFieldType) => {
    switch (countryField) {
      case "COUNTRY_OF_ORIGIN":
        return f("common.labels.country-of-origin");
      case "COUNTRY_OF_CITIZENSHIP":
        return f("common.labels.citizenship");
      case "COUNTRY_OF_RESIDENCE":
        return f("common.labels.country-of-residence");
      case "NATIONAL_ID_COUNTRY":
        return f("common.labels.national-id-country");
      case "COUNTRY":
        return f("common.labels.country");
      case "ADDRESS":
        return f("common.labels.address");
      case "OFFICE_ADDRESS":
        return f("common.labels.office-address");
      case "BUSINESS_ADDRESS":
        return f("common.labels.business-address");
      case "PEP_POSITION_COUNTRY":
        return f("common.labels.pep-position-country");
      case "KYC_INVOLVED_WITH_FOREIGN_COUNTRY":
        return f("disclosure.foreign-assignment-description");
      default:
        return countryField;
    }
  }, []);

  return getCountryFieldTranslation;
};

export default useGetCountryFieldTranslation;
