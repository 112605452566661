import { ReactNode } from "react";
import { Card, Collapsible, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import { MATCH_POSITIONS_AND_RELATIONS_CUTOFF } from "../../constants/matches";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import CondensedText from "../CondensedText/CondensedText";
import Button from "../extensions/Button";

type MatchValuesCollapsibleProps<T> = {
  label: string;
  labelHidden?: boolean;
  values?: T[];
  renderValue(value: T, index: number): ReactNode;
};

function MatchValuesCollapsible<T>(props: MatchValuesCollapsibleProps<T>) {
  const { values = [], label, labelHidden, renderValue } = props;

  const f = useFormatMessage();
  const [isOpen, toggleOpen] = useOpenClose();

  const buttonLabel = isOpen
    ? f("common.labels.show-less")
    : f("common.labels.show-more-with-count", { count: values.length - MATCH_POSITIONS_AND_RELATIONS_CUTOFF });

  return (
    <Card.Section
      title={
        <HorizontalStack align="space-between" blockAlign="center">
          {!labelHidden && <CondensedText>{label}</CondensedText>}
          {values.length > MATCH_POSITIONS_AND_RELATIONS_CUTOFF && (
            <Button
              plain
              size="micro"
              monochrome
              removeUnderline
              textAlign="right"
              disclosure={isOpen ? "up" : "down"}
              onClick={toggleOpen}
            >
              <Text as="span" color="subdued">
                {buttonLabel}
              </Text>
            </Button>
          )}
        </HorizontalStack>
      }
    >
      {values.length === 0 && (
        <Text as="span" color="subdued">
          ---
        </Text>
      )}
      <VerticalStack gap="1">
        {values.slice(0, MATCH_POSITIONS_AND_RELATIONS_CUTOFF).map((value, index) => renderValue(value, index))}
        <Collapsible id={label} open={isOpen}>
          <VerticalStack gap="1">
            {values
              .slice(MATCH_POSITIONS_AND_RELATIONS_CUTOFF)
              .map((value, index) => renderValue(value, MATCH_POSITIONS_AND_RELATIONS_CUTOFF + index))}
          </VerticalStack>
        </Collapsible>
      </VerticalStack>
    </Card.Section>
  );
}

export default MatchValuesCollapsible;
