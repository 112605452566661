import React, { forwardRef, PropsWithChildren } from "react";
import { Stack, StackProps } from "@shopify/polaris";
import styled from "styled-components";

const DashboardStack = forwardRef<HTMLDivElement, PropsWithChildren<Omit<StackProps, "vertical">>>((props, ref) => {
  const { spacing = "none", wrap = false, ...stackProps } = props;

  return (
    <StyledStackWrapper ref={ref}>
      <Stack spacing={spacing} wrap={wrap} {...stackProps} />
    </StyledStackWrapper>
  );
});

const StyledStackWrapper = styled.div`
  height: 100%;

  & > .Polaris-Stack {
    height: 100%;
  }

  & > .Polaris-Stack > .Polaris-Stack__Item + .Polaris-Stack__Item {
    border-left: var(--p-border-divider);
  }
`;

export default DashboardStack;
