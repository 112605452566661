import api from "../../api";
import { RESPONSIBLE_USERS_NAME_PARAMETER, STATUS_NAME_PARAMETER } from "../../constants/customers-list-search-params";
import useListParams from "../../hooks/useListParams";
import useNonInitialEffect from "../../hooks/useNonInitialEffect";
import { useURLSearchParam, useURLSearchParams } from "../../hooks/useURLSearchParam";
import { Concrete } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import { removeEmptyValues } from "../../utils/util";

type ProjectsListParams = Concrete<Parameters<typeof api.getProjects>[0]>;

const useProjectListParams = () => {
  const {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams: baseSearchParams,
    isEmptySearch: baseIsEmptySearch,
    resetAllFilters: baseResetAllFilters
  } = useListParams();

  const [status, setStatus] = useURLSearchParam(STATUS_NAME_PARAMETER);
  const [responsibleUserIds, setResponsibleUserIds] = useURLSearchParams(RESPONSIBLE_USERS_NAME_PARAMETER);

  useNonInitialEffect(() => {
    setPage(0);
  }, [status, responsibleUserIds.toString()]);

  const searchParams = removeEmptyValues<ProjectsListParams>({
    ...baseSearchParams,
    status: status as api.ProjectStatus,
    responsibleIds: responsibleUserIds.length === 0 ? undefined : responsibleUserIds.join(",")
  });

  const isEmptySearch = baseIsEmptySearch && isEmptyString(status) && responsibleUserIds.length === 0;

  const resetAllFilters = () => {
    baseResetAllFilters();
    setStatus(undefined);
    setResponsibleUserIds([]);
  };

  return {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    status,
    setStatus,
    responsibleUserIds,
    setResponsibleUserIds,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams,
    isEmptySearch,
    resetAllFilters
  };
};

export default useProjectListParams;
