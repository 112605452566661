import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { CustomerDetails } from "../types/utilities";

import useQueryData from "./useQueryData";

const useCustomerDeleteNoteMutation = (customer: CustomerDetails) => {
  const { updateQueryData } = useQueryData<api.CustomerNotation[]>([QUERIES_KEYS.CUSTOMER_NOTES, customer.id]);

  const deleteNoteMutation = useMutation<unknown, unknown, string>(
    (noteId) => api.deleteCustomerNotation(customer.id, noteId),
    {
      onSuccess: (_, noteId) => {
        updateQueryData((prevNotes) => {
          const deletedNoteIndex = prevNotes.findIndex((note) => note.id === noteId);
          if (deletedNoteIndex > -1) {
            prevNotes.splice(deletedNoteIndex, 1);
          }
        });
      }
    }
  );

  return deleteNoteMutation;
};

export default useCustomerDeleteNoteMutation;
