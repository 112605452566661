import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { isScreeningStarting } from "../helpers/screening";
import { CustomerDetails, getCustomerQueryKey } from "../types/utilities";

const CUSTOMER_DETAILS_REFRESH_RETRIES_LIMIT = 5;
const CUSTOMER_DETAILS_REFRESH_RETRIES_DELAY = 2000;

// Refresh the customer details query cache in case the customer screening has not been finished and the customer
// is not archived in order to get the customer details after screening is finished without the user needing to
// manually refresh the page.
// This is done by calling invalidateCustomerDetails() up to 5 times max (and with a delay of 2 seconds between each retry)
const useCustomerDetailsRefresh = (customerDetails: CustomerDetails) => {
  const queryClient = useQueryClient();
  const [retriesCount, setRetriesCount] = useState(0);

  const queryKey = getCustomerQueryKey(customerDetails);
  const isScreeningStarted = isScreeningStarting(customerDetails.screening);

  const invalidateCustomerDetails = async () => await queryClient.invalidateQueries(queryKey);

  useEffect(() => {
    if (customerDetails.is_archived || !isScreeningStarted || retriesCount > CUSTOMER_DETAILS_REFRESH_RETRIES_LIMIT) {
      return;
    }

    const timeoutHandle = setTimeout(() => {
      invalidateCustomerDetails().then(() => setRetriesCount((prevCount) => prevCount + 1));
    }, CUSTOMER_DETAILS_REFRESH_RETRIES_DELAY);

    return () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [queryKey, customerDetails.is_archived, isScreeningStarted, retriesCount]);
};

export default useCustomerDetailsRefresh;
