import React, { forwardRef, PropsWithChildren, ReactNode } from "react";
import { BannerStatus, Card, HorizontalStack, Stack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import CalendarIcon from "../../../assets/icons/calendar.svg";
import RejectedIcon from "../../../assets/icons/status-rejected.svg";
import TextIcon from "../../../assets/icons/text-bubble.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import CustomDate from "../CustomDate/CustomDate";
import Icon from "../extensions/Icon";
import UserDisplayName from "../UserDisplayName/UserDisplayName";

type ReviewCardProps = {
  reviewStatus?: BannerStatus;
  reviewDate: string;
  reviewedBy?: api.User;
  approvedBy?: api.User;
  isRejected?: boolean;
  additionalTitleContent?: ReactNode;
  reviewHeader?: ReactNode;
  reviewContent: ReactNode;
};

const ReviewCard = forwardRef<HTMLDivElement, PropsWithChildren<ReviewCardProps>>((props, ref) => {
  const {
    reviewStatus,
    reviewDate,
    reviewedBy,
    approvedBy,
    isRejected,
    additionalTitleContent,
    reviewHeader,
    reviewContent,
    children
  } = props;

  const f = useFormatMessage();

  return (
    <StyledCard status={reviewStatus} ref={ref}>
      <Card>
        <Card.Section>
          <Stack alignment="center">
            <HorizontalStack gap="1" blockAlign="center" wrap={false}>
              <Icon source={CalendarIcon} />
              <CustomDate date={reviewDate} />
            </HorizontalStack>

            {reviewedBy && (
              <UserDisplayName user={reviewedBy} fontWeight={"regular"}>
                <Text as="span">{f("details.reviews.reviewed-by")}</Text>
              </UserDisplayName>
            )}

            {approvedBy && (
              <HorizontalStack blockAlign="center" wrap={false}>
                {isRejected && <Icon source={RejectedIcon} width="16px" height="16px" />}
                <UserDisplayName user={approvedBy} fontWeight={isRejected ? "bold" : "regular"}>
                  <Text as="span" fontWeight={isRejected ? "bold" : undefined}>
                    {reviewStatus === "success" ? f("details.reviews.confirmed-by") : f("details.reviews.rejected-by")}
                  </Text>
                </UserDisplayName>
              </HorizontalStack>
            )}

            <Stack.Item fill>{additionalTitleContent}</Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section>
          {reviewHeader && <StyledReviewHeader disabled={isRejected}>{reviewHeader}</StyledReviewHeader>}
        </Card.Section>
        <Card.Section>
          <VerticalStack gap="2">
            <HorizontalStack gap="1" blockAlign="center" wrap={false}>
              <Icon source={TextIcon} />
              <Text as="span" fontWeight={"semibold"}>
                {f("reviews.labels.review-label")}
              </Text>
            </HorizontalStack>
            <StyledItalicWrapper>{reviewContent}</StyledItalicWrapper>
          </VerticalStack>
        </Card.Section>
        {React.Children.toArray(children)
          .filter(React.isValidElement)
          .map((child, index) => (
            <Card.Section key={index}>{child}</Card.Section>
          ))}
      </Card>
    </StyledCard>
  );
});

// add separator lines between all stack items that are not the first or the last
const StyledReviewHeader = styled.div<{ disabled?: boolean }>`
  & > .Polaris-HorizontalStack > * + * {
    padding-left: var(--p-space-6);
    border-left: thin solid var(--p-border-subdued);
  }
  filter: grayscale(${({ disabled }) => (disabled ? 1 : 0)});
`;

// apply Polaris status colors based on status value
const StyledCard = styled.div<{ status?: BannerStatus }>`
  & > .Polaris-Card {
    box-shadow: none;
    border: solid var(--p-border-width-1) var(--p-border-subdued);
    border-radius: var(--p-border-radius-1);

    ${({ status }) =>
      status &&
      `
      background-color: var(--p-surface-${status}-subdued);
      border: solid var(--p-border-width-1) var(--p-border-${status}-subdued);
      --p-divider: var(--p-surface-${status});

    .Polaris-Card__Section + .Polaris-Card__Section {
      border-top-color: var(--p-border-${status}-subdued);
    }
    `}
  }

  .Polaris-Card__Section:empty {
    display: none;
  }
`;

const StyledItalicWrapper = styled.span`
  font-style: italic;
`;

export default ReviewCard;
