import React from "react";
import { generatePath, useHistory } from "react-router-dom";

import api from "../../../api";
import { ROUTES } from "../../../constants/routes";
import { getCustomerName } from "../../../helpers/display.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useSnackbar from "../../../hooks/useSnackbar";
import { Customer } from "../../../types/utilities";
import ConfirmAction from "../../ConfirmAction/ConfirmAction";

interface CustomerDeleteConfirmationProps {
  customer: Customer;
  onClose(): void;
}

const CustomerDeleteConfirmation = (props: CustomerDeleteConfirmationProps) => {
  const { customer, onClose } = props;

  const history = useHistory();
  const f = useFormatMessage();
  const name = getCustomerName(customer);
  const { setSnackbar } = useSnackbar();

  const handleDeleteSuccess = () => {
    setSnackbar(f("delete-customer.confirmation-modal.success.message", { name }));
    history.push(generatePath(ROUTES.HOME));
  };

  return (
    <ConfirmAction
      title={f("delete-customer.confirmation-modal.title")}
      description={f("delete-customer.confirmation-modal.description", { name })}
      apiCall={() => api.deleteCustomers({ customer_ids: [customer.id] })}
      onSuccess={handleDeleteSuccess}
      onNo={onClose}
      actionTitle={f("delete-customer.confirmation-modal.labels.button.delete", { name })}
      isDestructive
    />
  );
};

export default CustomerDeleteConfirmation;
