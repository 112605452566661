import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { convertAssignmentToUpdateRequest, sortAssignmentsByDate } from "../helpers/assignments.helpers";
import { first, hasContent } from "../utils/collectionUtils";

import useChangedState from "./useChangedState";
import useFeatures from "./useFeatures";
import useQueryData from "./useQueryData";

const useDisclosureAssignment = (
  customerId?: string,
  assignmentId?: string,
  assignments?: api.Assignment[],
  onAssignmentUpdate?: (assignment: api.Assignment) => void
) => {
  const features = useFeatures();

  // if assignmentId is set as a URL params - select the assignment that matches with its id
  // otherwise, if SHOW_ASSIGNMENTS_IN_DISCLOSURE_FORM is enabled, select the first assignment found
  const getInitialAssignment = () =>
    assignments?.find((assignment) => assignment.id === assignmentId) ||
    (features.ASSIGNMENTS__HIDE_DETAILS ? first(assignments?.sort(sortAssignmentsByDate)) : undefined);

  const [selectedAssignment, setSelectedAssignment, isAssignmentChanged] = useChangedState(() =>
    getInitialAssignment()
  );

  const hasAssignments = hasContent(assignments);
  useEffect(() => {
    if (!selectedAssignment && hasAssignments) {
      setSelectedAssignment(getInitialAssignment());
    }
  }, [hasAssignments]);

  const { updateQueryData } = useQueryData<api.CustomerAssignmentDetails>([
    QUERIES_KEYS.ASSIGNMENT_DETAILS,
    customerId,
    selectedAssignment?.id
  ]);

  const shouldUpdateAssignment = customerId && isAssignmentChanged && selectedAssignment;

  const updateAssignmentMutation = useMutation(
    () =>
      // if SHOW_ASSIGNMENTS_IN_DISCLOSURE_FORM is enabled and was changed - update the assignment be
      api.updateCustomerAssignment(
        customerId!,
        selectedAssignment!.id,
        convertAssignmentToUpdateRequest(selectedAssignment!)
      ),
    {
      onSuccess: (response) => {
        // update assignment details with purpose changes
        updateQueryData((queryData) => Object.assign(queryData, response));

        if (onAssignmentUpdate) {
          onAssignmentUpdate(response);
        }
      }
    }
  );

  return {
    selectedAssignment,
    setSelectedAssignment,
    shouldUpdateAssignment,
    updateAssignmentMutation
  };
};

export default useDisclosureAssignment;
