import React from "react";
import { useIntl } from "react-intl";
import { VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import AddressInfo from "../../components/AddressInfo/AddressInfo";
import Country from "../../components/Country/Country";
import EditCustomerDetailsButton from "../../components/EditCustomerDetailsButton/EditCustomerDetailsButton";
import InfoTable, { InfoTableRow } from "../../components/InfoTable/InfoTable";
import TooltipWrapper from "../../components/TooltipWrapper/TooltipWrapper";
import useCountries from "../../hooks/useCountries";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isValidDate } from "../../utils/dateUtils";

type PersonInfoSectionProps = {
  person: api.PersonCustomerDetailResponse;
  readonly?: boolean;
};

const PersonInfoSection = (props: PersonInfoSectionProps) => {
  const { person, readonly } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const { formatDate } = useIntl();
  const { getCountryName, getCountryRiskDescription, isHighRiskCountry } = useCountries();

  const nationalIdTooltip = `${person.national_id_country ? getCountryName(person.national_id_country, true) : ""} ${
    person.national_id
  }`;

  const rows: InfoTableRow[] = [
    features.EXTERNAL_ID
      ? {
          label: f("person.details.caption.externalId"),
          value: person.external_id || "-"
        }
      : undefined,
    {
      label: f("person.details.caption.first.name"),
      value: person.first_name
    },
    {
      label: f("person.details.caption.last.name"),
      value: person.last_name
    },
    {
      label: f("person.details.caption.birth.date"),
      value: isValidDate(person.birth_date) ? formatDate(person.birth_date) : person.birth_year?.toFixed(0) || "-"
    },
    {
      label: f("person.details.caption.person.number"),
      value: person.national_id ? (
        <TooltipWrapper content={nationalIdTooltip}>
          <span>*****</span>
        </TooltipWrapper>
      ) : (
        f("not.specified")
      )
    },
    person.country_of_origin
      ? {
          label: f("person.details.caption.origin"),
          value: person.country_of_origin ? <Country country={person.country_of_origin} /> : "-",
          isWarning: isHighRiskCountry(person.country_of_origin),
          warningDescription: getCountryRiskDescription(person.country_of_origin)
        }
      : undefined,
    {
      label: f("person.details.caption.citizenship"),
      value: person.country_of_citizenship ? <Country country={person.country_of_citizenship} /> : "-",
      isWarning: isHighRiskCountry(person.country_of_citizenship),
      warningDescription: getCountryRiskDescription(person.country_of_citizenship)
    },
    {
      label: f("person.details.caption.residence"),
      value: person.country_of_residence ? <Country country={person.country_of_residence} /> : "-",
      isWarning: isHighRiskCountry(person.country_of_residence),
      warningDescription: getCountryRiskDescription(person.country_of_residence)
    },
    {
      label: f("person.details.caption.address"),
      value: <AddressInfo address={person.address} />
    }
  ];

  return (
    <VerticalStack gap="4">
      <StyledButton customer={person} readonly={readonly} />
      <InfoTable rows={rows} useCondensedLabels />
    </VerticalStack>
  );
};

const StyledButton = styled(EditCustomerDetailsButton)`
  margin: var(--p-space-4);
`;

export default PersonInfoSection;
