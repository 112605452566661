import React from "react";

import api from "../../api";
import useAuth from "../../hooks/useAuth";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePreviewDisclosureMutation from "../../hooks/usePreviewDisclosureMutation";
import PreviewModalAsync from "../PreviewModalAsync/PreviewModalAsync";

type PreviewQuestionnaireModalProps = {
  signature: api.SignaturePreviewRequest;
  onClose(): void;
};

const DisclosurePreviewModal = (props: PreviewQuestionnaireModalProps) => {
  const { signature, onClose } = props;

  const { user } = useAuth();
  const f = useFormatMessage();
  const previewDisclosureMutation = usePreviewDisclosureMutation();

  return (
    <PreviewModalAsync
      getPreviewURLPromise={() =>
        previewDisclosureMutation.mutateAsync({ ...signature, organization_id: user?.organization.id })
      }
      title={f("default.preview")}
      loading={previewDisclosureMutation.isLoading}
      onClose={onClose}
    />
  );
};

export default DisclosurePreviewModal;
