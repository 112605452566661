import api from "../api";

const isHeadersAMap = (headers: string[][] | Record<string, string> | Headers): headers is Headers => {
  return (headers as Headers).get !== undefined;
};

const useFetch = () => {
  const augmentedFetch: typeof fetch = (input, init) => {
    const fetchInit = init || {};

    if (fetchInit.headers) {
      if (isHeadersAMap(fetchInit.headers)) {
        for (const header in api.defaults.headers) {
          fetchInit.headers.set(header, api.defaults.headers[header] as string);
        }
      } else {
        for (const header in api.defaults.headers) {
          (fetchInit.headers as Record<string, string>)[header] = api.defaults.headers[header] as string;
        }
      }
    } else {
      fetchInit.headers = api.defaults.headers as Record<string, string>;
    }

    return api.defaults.fetch!(input, fetchInit);
  };

  return augmentedFetch;
};

export default useFetch;
