import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import ConfirmAction, { ConfirmActionProps } from "../ConfirmAction/ConfirmAction";

import { CustomerReviewMeasureProps } from "./types";

type RemoveMeasureConfirmProps = CustomerReviewMeasureProps &
  Omit<ConfirmActionProps<api.Measure>, "title" | "actionTitle" | "apiCall">;

const RemoveMeasureConfirm = (props: RemoveMeasureConfirmProps) => {
  const { customerId, reviewId, measure, ...confirmProps } = props;
  const f = useFormatMessage();

  return (
    <ConfirmAction<api.Measure>
      title={f("details.reviews.measures.remove-confirmation.title")}
      description={f("details.reviews.measures.remove-confirmation.description", { name: measure.title })}
      actionTitle={f("default.delete")}
      apiCall={() => api.removeCustomerReviewMeasure(customerId, reviewId, measure.id)}
      {...confirmProps}
    />
  );
};

export default RemoveMeasureConfirm;
