import React, { useEffect, useState } from "react";
import { Banner, Card, List, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetOnboardingConfiguration from "../../hooks/useGetOnboardingConfiguration";
import useValidateDisclosureForm from "../../hooks/useValidateDisclosureForm";
import { hasContent } from "../../utils/collectionUtils";
import { isEmptyString } from "../../utils/stringUtils";
import CompanyDisclosureAssignmentSelector, {
  CompanyDisclosureAssignmentSelectorProps
} from "../CompanyDisclosureAssignmentSelector/CompanyDisclosureAssignmentSelector";
import DisclosureFormRow from "../DisclosureFormRow/DisclosureFormRow";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

// key is the role id from the signature
export type SignaturesMap = Map<string, api.CompanyRoleSignatureConfiguration>;

type CompanyDisclosureSignatureFormProps = CompanyDisclosureAssignmentSelectorProps & {
  companyDetails?: api.CompanyCustomerDetailResponse;
  companySignatureConfiguration: api.CompanySignatureConfiguration;
  signaturesByRoleId: SignaturesMap;
  attachments?: api.AttachmentDetails[];
  footer?: React.ReactNode;
  readonly?: boolean;
  onChange(signatures: SignaturesMap, isValid: boolean): void;
};

const CompanyDisclosureSignatureForm = (props: CompanyDisclosureSignatureFormProps) => {
  const {
    companyDetails,
    companySignatureConfiguration,
    signaturesByRoleId: initialSignaturesByRoleId,
    // attachments,
    readonly,
    footer,
    onChange,
    ...assignmentSelectorProps
  } = props;

  const f = useFormatMessage();
  const validateForm = useValidateDisclosureForm();
  const defaultSignatureConfiguration = useGetOnboardingConfiguration("company");

  const [signaturesByRoleId, setSignaturesByRoleId] = useState<SignaturesMap>(new Map());
  const [validationError, setValidationError] = useState<string>();

  const companyName = companyDetails?.name || "";
  const possibleSignatureRoles = companySignatureConfiguration.possible_signers || [];
  const signatureDescription = companySignatureConfiguration.signature_description;
  const signatureDescriptions = companySignatureConfiguration.signature_descriptions || [];

  const showAssignmentSelection = hasContent(assignmentSelectorProps.assignments);

  const selectedAssignment = assignmentSelectorProps.selectedAssignment;

  const onFormUpdate = (signatures: SignaturesMap) => {
    validateForm(Array.from(signatures.values()), (isValid, hasDuplicates) => {
      if (hasDuplicates) {
        setValidationError(f("onboarding.disclosures.duplicate.emails.error"));
      } else {
        setValidationError(undefined);
      }

      onChange(signatures, isValid);
    });
  };

  // Used to add existing data to the map (if for example a user navigates
  // from summary back to the KYC step during onboarding, we want to show
  // the existing signature configurations for roles)
  useEffect(() => {
    if (initialSignaturesByRoleId.size > 0) {
      setSignaturesByRoleId(initialSignaturesByRoleId);
      // Perform validation of the initial signature configurations
      onFormUpdate(initialSignaturesByRoleId);
    }
  }, [initialSignaturesByRoleId]);

  const onSignerSelectionChange = (id: string, selected: boolean) => {
    if (selected) {
      const email = companyDetails?.roles.find((role) => role.id === id)?.person.email;

      signaturesByRoleId.set(id, { id, ...defaultSignatureConfiguration!.signature_settings, email });
    } else {
      signaturesByRoleId.delete(id);
    }

    const updatedSignatures = new Map(signaturesByRoleId);

    setSignaturesByRoleId(updatedSignatures);
    onFormUpdate(updatedSignatures);
  };

  // Update a field when the signature configuration changes
  const onSignatureChange = (id: string, changedSignature: api.CompanyRoleSignatureConfiguration) => {
    if (signaturesByRoleId.has(id) && signaturesByRoleId.get(id) !== changedSignature) {
      signaturesByRoleId.set(id, changedSignature);

      const updatedSignatures = new Map(signaturesByRoleId);

      setSignaturesByRoleId(updatedSignatures);
      onFormUpdate(updatedSignatures);
    }
  };

  return (
    <VerticalStack gap="4">
      <Banner status="info">
        <VerticalStack gap="4">
          <Text as="span" fontWeight="semibold">
            {f("disclosure-request.banner.signatory-rights", { company: companyName })}
          </Text>
          {signatureDescriptions.length === 0 && isEmptyString(signatureDescription) && (
            <Text as="span">{f("disclosure-request.banner.no.signing-basis")}</Text>
          )}
          <List>
            {!isEmptyString(signatureDescription) && <List.Item>{signatureDescription}</List.Item>}
            {signatureDescriptions.length > 0 &&
              signatureDescriptions.map((description, index) => <List.Item key={index}>{description}</List.Item>)}
          </List>
        </VerticalStack>
      </Banner>

      {showAssignmentSelection && (
        <Card>
          <Card.Section>
            <CompanyDisclosureAssignmentSelector {...assignmentSelectorProps} readonly={readonly} />
          </Card.Section>
        </Card>
      )}

      <Card>
        {possibleSignatureRoles.length === 0 && <Card.Section>{f("roles.messages.no-roles")}</Card.Section>}
        {possibleSignatureRoles.map((signatureRole: api.SignatureRole) => {
          const roleId = signatureRole.id!;
          const signature = signaturesByRoleId.get(roleId);

          return (
            <Card.Section key={roleId}>
              <DisclosureFormRow
                roleType={signatureRole.type}
                person={signatureRole.person}
                selected={signaturesByRoleId.has(roleId)}
                signature={signature}
                selectedAssignment={selectedAssignment}
                // attachments={attachments}
                // selectedAttachmentsIds={signature?.attachments_ids}
                customerId={companyDetails?.id}
                customerType="company"
                signatureName={getFullName(signatureRole.person)}
                readonly={readonly}
                onChange={(signature) => onSignatureChange(roleId, signature as api.CompanyRoleSignatureConfiguration)}
                // onAttachmentsSelectionChange={(attachmentsIds) =>
                //   onSignatureChange(roleId, { ...signature!, attachments_ids: attachmentsIds })
                // }
                onSelectionChanged={(value) => onSignerSelectionChange(roleId, value)}
              />
            </Card.Section>
          );
        })}
        {footer && <Card.Section>{footer}</Card.Section>}
      </Card>

      {validationError && <ErrorPanel message={validationError} />}
    </VerticalStack>
  );
};

export default CompanyDisclosureSignatureForm;
