import React from "react";

import api from "../../../api";
import CustomersListTable from "../../../components/CustomersListTable/CustomersListTable";
import { isVitecProject } from "../../../helpers/integration.helpers";

import useProjectCustomersTableColumns from "./useProjectCustomersTableColumns";

interface ProjectCustomersTableProps {
  project: api.Project;
  selectedCustomersIds: string[];
  onSelectionChange(selectedCustomersIds: string[]): void;
}

const ProjectCustomersTable = (props: ProjectCustomersTableProps) => {
  const { project, selectedCustomersIds, onSelectionChange } = props;

  const isVitec = isVitecProject(project);

  const columns = useProjectCustomersTableColumns(project);

  const count = project.customers.length;

  return (
    <CustomersListTable
      columns={columns}
      customers={project.customers}
      count={count}
      hidePagination
      sortLocally
      selectable={!isVitec}
      selectedIds={selectedCustomersIds}
      onSelectionChange={onSelectionChange}
    />
  );
};

export default ProjectCustomersTable;
