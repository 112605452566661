import React, { useState } from "react";
import { Toast } from "@shopify/polaris";
import { TabDescriptor } from "@shopify/polaris/build/ts/latest/src/components/Tabs/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import ConfirmAction from "../../components/ConfirmAction/ConfirmAction";
import CustomerReviewGuidelineEditor from "../../components/CustomerReviewGuidelineEditor/CustomerReviewGuidelineEditor";
import DisplayName from "../../components/DisplayName/DisplayName";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import Button from "../../components/extensions/Button";
import KDKTabs from "../../components/KDKTabs/KDKTabs";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useChangedState from "../../hooks/useChangedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";

const EMPTY_GUIDELINE: api.ReviewGuideline = {
  text_md: { nb: "", en: "" },
  title: { nb: "", en: "" }
};

type SettingsGuidelinesProps = {
  guidelines: api.ReviewGuideline[]; // an array of two guidelines - the first is for person and the second for company
  readonly?: boolean;
};

const SettingsGuidelines = (props: SettingsGuidelinesProps) => {
  const { guidelines, readonly } = props;

  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const { isPermittedTo } = usePermissions();

  const [customerType, setCustomerType] = useState<api.CustomerType>("person");
  const [personGuideline, setPersonGuideline, isPersonGuidelineChanged] = useChangedState(guidelines[0]);
  const [companyGuideline, setCompanyGuideline, isCompanyGuidelineChanged] = useChangedState(guidelines[1]);

  const [showResetWarning, toggleResetWarning, closeResetWarning] = useOpenClose();
  const [showSuccessMessageToast, toggleShowSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();

  const { setQueryData } = useQueryData<api.ReviewGuideline[]>([QUERIES_KEYS.CUSTOMER_REVIEW_GUIDELINES_SETTINGS]);

  const updateSettings = () =>
    Promise.all([
      api.updateReviewGuideline("person", personGuideline),
      api.updateReviewGuideline("company", companyGuideline)
    ]);

  const onSuccess = (responses: api.ReviewGuideline[]) => {
    setQueryData(responses);
    queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMER_REVIEW_GUIDELINE]);
  };

  const resetSettingsMutation = useMutation(
    () => Promise.all([api.updateReviewSettings({ use_default_guideline: true })]),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMER_REVIEW_GUIDELINE]);
        await queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMER_REVIEW_GUIDELINES_SETTINGS]);

        const data = queryClient.getQueryData<api.ReviewGuideline[]>([
          QUERIES_KEYS.CUSTOMER_REVIEW_GUIDELINES_SETTINGS
        ]);
        if (data) {
          const [updatedPersonGuideline, updatedCompanyGuideline] = data;
          setPersonGuideline(updatedPersonGuideline);
          setCompanyGuideline(updatedCompanyGuideline);
        }

        toggleShowSuccessMessageToast();
        closeResetWarning();
      }
    }
  );

  const isChanged = isPersonGuidelineChanged || isCompanyGuidelineChanged;

  const disabled = readonly || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS) || resetSettingsMutation.isLoading;

  const tabs: TabDescriptor[] = [
    {
      id: "person",
      content: <DisplayName name={f("common.labels.customer-type.person")} type={"person"} />
    },
    {
      id: "company",
      content: <DisplayName name={f("common.labels.customer-type.company")} type={"company"} />
    }
  ];

  const handleSelectionChange = (selectedTabIndex: number) =>
    setCustomerType(selectedTabIndex === 0 ? "person" : "company");

  return (
    <SettingsPage
      apiCall={updateSettings}
      onSaveAfter={onSuccess}
      title={f("customer-review-guideline-settings.page.title")}
      subtitle={f("customer-review-guideline-settings.page.description")}
      readonly={readonly}
      footer={
        <Button disabled={disabled || !isChanged} onClick={toggleResetWarning}>
          {f("default.reset")}
        </Button>
      }
    >
      <KDKTabs selected={customerType === "person" ? 0 : 1} tabs={tabs} onSelect={handleSelectionChange} />
      {customerType === "person" && (
        <CustomerReviewGuidelineEditor guideline={personGuideline} readonly={disabled} onChange={setPersonGuideline} />
      )}
      {customerType === "company" && (
        <CustomerReviewGuidelineEditor
          guideline={companyGuideline}
          readonly={disabled}
          onChange={setCompanyGuideline}
        />
      )}
      <SectionMargin divider size={"medium"} />
      {resetSettingsMutation.isError && <ErrorPanel message={resetSettingsMutation.error} />}
      {showSuccessMessageToast && (
        <Toast content={f("common.labels.saved-successfully")} onDismiss={closeSuccessMessageToast} />
      )}
      <ConfirmAction
        apiCall={() => resetSettingsMutation.mutateAsync()}
        isOpen={showResetWarning}
        title={f("customer-review-guideline-settings.page.reset.confirmation.title")}
        description={f("customer-review-guideline-settings.page.reset.confirmation.description")}
        isDestructive
        actionTitle={f("default.reset")}
        onNo={closeResetWarning}
      />
    </SettingsPage>
  );
};

const SettingsGuidelinesPage = ({ data }: AsyncPageChild<api.ReviewGuideline[]>) => {
  return <SettingsGuidelines guidelines={data} />;
};

export default withAsyncPage<api.ReviewGuideline[]>(SettingsGuidelinesPage, {
  queryKey: QUERIES_KEYS.CUSTOMER_REVIEW_GUIDELINES_SETTINGS,
  apiFunction: (options) =>
    Promise.all([api.getReviewGuideline("person", options), api.getReviewGuideline("company", options)]),
  paramNames: [],
  skeleton: <SettingsGuidelines readonly guidelines={[EMPTY_GUIDELINE, EMPTY_GUIDELINE]} />
});
