import { useHistory, useLocation } from "react-router-dom";

import api from "../api";
import { getDetailsPageURL } from "../helpers/navigation.helpers";
import HistoryLocationState from "../types/HistoryLocationState";

import useSnackbar from "./useSnackbar";

const useCustomerDetailsBackButton = (customerId: string, customerType: api.CustomerType, assignmentId?: string) => {
  const location = useLocation<HistoryLocationState>();
  const history = useHistory<HistoryLocationState>();
  const { setSnackbar } = useSnackbar();

  const state = location.state || {};

  const { fromCustomerDetails, fromCustomerAssignmentDetails } = state;

  // returning undefined as a URL will cause the BackButton component to call history.goBack() instead of a regular url
  const backButtonURL =
    fromCustomerDetails || fromCustomerAssignmentDetails
      ? undefined
      : getDetailsPageURL(customerId, customerType, assignmentId);

  const goToCustomerDetails = (snackbarMessage?: string) => {
    if (snackbarMessage) {
      setSnackbar(snackbarMessage);
    }

    if (backButtonURL) {
      history.push(backButtonURL, state);
    } else {
      history.goBack();
    }
  };

  return { backButtonURL, goToCustomerDetails };
};

export default useCustomerDetailsBackButton;
