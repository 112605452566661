import { useMemo } from "react";

import api from "../../api";
import { NORWEGIAN_COMPANY_NATIONAL_ID_REGEX } from "../../constants/company-details";
import { DEFAULT_COUNTRY } from "../../constants/countries";
import {
  EXTERNAL_ID_COLUMN,
  NAME_COLUMN,
  ORG_ID_COLUMN,
  RESPONSIBLE_USER_EMAIL_COLUMN
} from "../../constants/import-customers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetOnboardingWarnings from "../../hooks/useGetOnboardingWarnings";
import useGetUsers from "../../hooks/useGetUsers";
import { ImportRowData } from "../../types/ImportRow";

import { getImportRowOrgId, getImportRowValue } from "./ImportCustomersPage.helpers";

const getRecordIndices = (columnsNames: string[]) => ({
  orgIdIndex: columnsNames.indexOf(ORG_ID_COLUMN),
  nameIndex: columnsNames.indexOf(NAME_COLUMN),
  externalIdIndex: columnsNames.indexOf(EXTERNAL_ID_COLUMN),
  responsibleUserEmailIndex: columnsNames.indexOf(RESPONSIBLE_USER_EMAIL_COLUMN)
});

const useImportCompanies = (columnNames: string[]) => {
  const f = useFormatMessage();
  const { activeUsers } = useGetUsers();

  const usersEmails = useMemo(() => new Set(activeUsers.map((user) => user.email.toLowerCase())), [activeUsers.length]);

  // the indices to read properties from a row record
  const recordIndices = useMemo(() => getRecordIndices(columnNames), [columnNames.join("-")]);

  const getOnboardingWarnings = useGetOnboardingWarnings();

  const getDataRowIdentifier = (row: ImportRowData) => getImportRowOrgId(row, recordIndices.orgIdIndex);

  const validateRow = (
    row: ImportRowData,
    existingIds: Set<ReturnType<typeof getDataRowIdentifier>>,
    updatedRecordIndices: ReturnType<typeof getRecordIndices>
  ) => {
    const orgId = getImportRowOrgId(row, updatedRecordIndices.orgIdIndex);
    if (!NORWEGIAN_COMPANY_NATIONAL_ID_REGEX.test(orgId + "")) {
      return f("import-customers.wizard.validation-error.invalid-org-id");
    }

    if (existingIds.has(orgId)) {
      return f("import-customers.wizard.validation-error.duplicate-org-id");
    }

    if (updatedRecordIndices.responsibleUserEmailIndex > -1) {
      const email = getImportRowValue(row, updatedRecordIndices.responsibleUserEmailIndex);
      if (!email || !usersEmails.has(email.toLowerCase())) {
        return f("import-customers.wizard.validation-error.responsible-user-not-found");
      }
    }

    return undefined;
  };

  const validateRows = (
    rows: ImportRowData[],
    updatedColumnNames: string[] = columnNames,
    callback: (row: ImportRowData, hasError: boolean) => void
  ) => {
    const rowsUniqueIdSet = new Set<ReturnType<typeof getDataRowIdentifier>>();
    const updatedRecordIndices = getRecordIndices(updatedColumnNames);

    rows.forEach((row) => {
      const validationMessage = validateRow(row, rowsUniqueIdSet, updatedRecordIndices);

      if (validationMessage) {
        callback({ ...row, status: "VALIDATION_ERROR", errorMessage: validationMessage }, true);
      } else {
        callback({ ...row, status: "READY_FOR_IMPORT", errorMessage: undefined }, false);
      }

      rowsUniqueIdSet.add(getDataRowIdentifier(row));
    });
  };

  const importRow = async (row: ImportRowData) => {
    const lookupInfo = await api.getCompanyInfo("NO", getImportRowOrgId(row, recordIndices.orgIdIndex));

    const warnings = getOnboardingWarnings(lookupInfo.basic_legal_entity_info);
    if (warnings.length > 0) {
      return Promise.reject(warnings[0]);
    } else {
      return api.createCompanyCustomer({
        country: DEFAULT_COUNTRY,
        name: getImportRowValue(row, recordIndices.nameIndex),
        national_id: getImportRowOrgId(row, recordIndices.orgIdIndex) + "",
        external_id: getImportRowValue(row, recordIndices.externalIdIndex),
        responsible_user_email: getImportRowValue(row, recordIndices.responsibleUserEmailIndex)
      });
    }
  };

  return { validateRows, importRow };
};

export default useImportCompanies;
