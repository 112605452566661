import React, { PropsWithChildren } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { HorizontalStack, Text } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import { isVitecProject } from "../../helpers/integration.helpers";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useNavigationToolbar, { OnboardingType } from "../../hooks/useNavigationToolbar";
import HistoryLocationState from "../../types/HistoryLocationState";
import { getCustomerQueryKey } from "../../types/utilities";
import HideOnMobile from "../HideOnMobile/HideOnMobile";
import ShowOnMobile from "../ShowOnMobile/ShowOnMobile";

import CancelOnboardingMenu from "./CancelOnboardingMenu";
import OnboardingWizardMenu from "./OnboardingWizardMenu";

type ToolbarItemProps = {
  disabled?: boolean;
  onClick?(): void;
  isPast?: boolean;
  isActive?: boolean;
};

const ToolbarItem = ({ disabled, onClick, isPast, isActive, children }: PropsWithChildren<ToolbarItemProps>) => {
  return (
    <StyledToolbarItem onClick={onClick} isActive={isActive} disabled={disabled}>
      <Text
        as="span"
        fontWeight={isActive || isPast ? "semibold" : undefined}
        color={!isActive && !isPast ? "subdued" : undefined}
      >
        {children}
      </Text>
    </StyledToolbarItem>
  );
};

const OnboardingWizardToolbar = () => {
  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();
  const features = useFeatures();

  const createdFromProjectId = history.location.state?.createdFromProject?.id;

  const redirectURLAfterClose = createdFromProjectId
    ? generatePath(ROUTES.PROJECT_DETAILS, { id: createdFromProjectId })
    : generatePath(ROUTES.CUSTOMERS);

  const { id: customerId, onboardingType, onboardingStepIndex, goToStep } = useNavigationToolbar();

  // fetch customer details to check if they belong to a vitec project
  const { isLoading, data: customer } = useQuery<api.CompanyCustomerDetailResponse>(
    getCustomerQueryKey({ id: customerId!, type: "company" }),
    () => api.getCompanyCustomerDetails(customerId!),
    { enabled: customerId !== undefined }
  );

  // do not allow to cancel onboarding if the customer belongs to a vitec project
  const allowCancelOnboarding = !isLoading && customer?.projects?.find(isVitecProject) === undefined;

  // add tab title for assignment wizard step after the screening step
  const assignmentStepLabel = features.ATTACHMENTS
    ? f("onboarding.bar.company.assignments-and-attachments.title")
    : f("onboarding.bar.company.assignments.title");

  const menuItems: Record<OnboardingType, string[]> = {
    person: [],
    "company-wizard": [
      f("onboarding.bar.company.screening.title"),
      assignmentStepLabel,
      f("onboarding.bar.company.disclosures.title"),
      f("onboarding.bar.company.summary.title")
    ].filter(Boolean),
    "manual-company-wizard": [
      f("onboarding.bar.manual-company.information.title"),
      f("onboarding.bar.company.screening.title"),
      assignmentStepLabel,
      f("onboarding.bar.company.disclosures.title"),
      f("onboarding.bar.company.summary.title")
    ].filter(Boolean)
  };

  if (!onboardingType) {
    return null;
  }

  return (
    <StyledToolbarWrapper>
      <HideOnMobile>
        <HorizontalStack blockAlign="center" align="center" wrap={false}>
          {menuItems[onboardingType].map((item, index) => (
            <ToolbarItem
              disabled={index >= onboardingStepIndex}
              onClick={() => goToStep(index)}
              key={item}
              isPast={index < onboardingStepIndex}
              isActive={onboardingStepIndex === index}
            >
              {`${index + 1}. ${item}`}
            </ToolbarItem>
          ))}
        </HorizontalStack>
      </HideOnMobile>
      <ShowOnMobile>
        <OnboardingWizardMenu
          menuItems={menuItems}
          onboardingType={onboardingType}
          onboardingStepIndex={onboardingStepIndex}
          goToStep={goToStep}
        />
      </ShowOnMobile>
      <CancelOnboardingMenu
        customer={customer}
        allowCancelOnboarding={allowCancelOnboarding}
        onClose={() => history.push(redirectURLAfterClose)}
      />
    </StyledToolbarWrapper>
  );
};

const StyledToolbarWrapper = styled.div`
  height: 100%;
  margin: 0 var(--p-space-4);

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledToolbarItem = styled.div<ToolbarItemProps>`
  userselect: none;
  cursor: pointer;
  padding: 0 var(--p-space-4);
  height: var(--kdk-toolbar-height);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  min-width: max-content;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: var(--p-border-width-4);
    width: 100%;
    background-color: ${(props) => (props.isActive ? "var(--kdk-blue-600)" : "transparent")};
  }

  &:hover {
    background-color: ${({ onClick }) => (onClick ? "var(--p-surface-hovered)" : undefined)};
  }

  &:active {
    background-color: ${({ onClick }) => (onClick ? "var(--p-surface-pressed)" : undefined)};
  }
  ${(props) =>
    props.disabled &&
    `
      cursor: default;
      pointer-events: none;

      &:hover {
        background-color: inherit;
      }
  `}
`;

export default OnboardingWizardToolbar;
