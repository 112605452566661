import api from "../../../../api";
import { secondsBetweenDates } from "../../../../utils/dateUtils";
import { isNil } from "../../../../utils/util";

/**
 * This is a workaround for the fact that currently, ownership updates DO NOT contain the name or the year
 * of the ownership registry.
 * We assume that if an owner has an update date but not update user then this update was from the
 * ownership registry.
 */
export const isUpdatedByOwnershipRegistry = (owner: api.Owner) => {
  // Owner was manually updated by a user
  if (!isNil(owner.updated_by)) {
    return false;
  }

  // updated_by is nil, which we assume that means the update comes from a registry
  // Now we need to check if the owner actually has been updated:
  // Because Django automatically adds an updated timestamp on creation,
  // we need to compare the created and updated timestamps to check if the
  // owner actually has been updated. If there is less than two seconds between
  // the owner's created and updated timestamp, we assume that the owner has
  // _not_ been updated
  return secondsBetweenDates(owner.created_dt, owner.updated_dt) > 2;
};
