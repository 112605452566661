import React, { PropsWithChildren } from "react";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

import { AttachmentSignatureRow } from "../useAttachmentSignaturesModal";

interface AttachmentTableCellProps {
  row: AttachmentSignatureRow;
  centered?: boolean;
}

const AttachmentTableCell = (props: PropsWithChildren<AttachmentTableCellProps>) => {
  const { row, centered, children } = props;

  return (
    <StyledItalic italic={row.isModified} centered={centered}>
      <Text as="span" color={row.isModified ? "subdued" : undefined}>
        {children}
      </Text>
    </StyledItalic>
  );
};

const StyledItalic = styled.span<{ centered?: boolean; italic?: boolean }>`
  text-align: ${({ centered }) => (centered ? "center" : undefined)};
  font-style: ${({ italic }) => (italic ? "italic" : undefined)};
`;

export default AttachmentTableCell;
