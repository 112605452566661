import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { EntityWithId } from "../types/utilities";

type useSelectionMapType<T> = [string[], T[], (selectedIds: string[]) => void, () => void];

/**
 * Save selected entities in addition to selected ids
 *
 * This hook is used to save the selected entities when navigating between pages in a paginated table
 *
 * @param entities a list of all the entities (not just selected) that are currently available
 */
const useSelectionMap = <T extends EntityWithId>(entities: T[]): useSelectionMapType<T> => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  // entities will change when moving to another page so save all previous entities in a ref
  const allEntities = useRef<Record<string, T>>({});

  useEffect(() => {
    allEntities.current = {
      ...allEntities.current,
      ...Object.fromEntries(entities.map((entity) => [entity.id, entity]))
    };
  }, [entities.map((entity) => entity.id)]);

  const clearSelection = useCallback(() => setSelectedIds([]), []);

  const selectedEntities = useMemo(() => selectedIds.map((entityId) => allEntities.current[entityId]), [selectedIds]);

  return [selectedIds, selectedEntities, setSelectedIds, clearSelection];
};

export default useSelectionMap;
