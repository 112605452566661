import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import { LinkLikeComponentProps } from "@shopify/polaris/build/ts/latest/src/utilities/link";

import { ROUTES } from "../../constants/routes";
import HistoryLocationState from "../../types/HistoryLocationState";

const AdapterLink = (props: LinkLikeComponentProps) => {
  const { url, children, external, download, target, ...rest } = props;

  const location = useLocation<HistoryLocationState>();

  const htmlAnchorParams: React.RefAttributes<HTMLAnchorElement> = rest as React.RefAttributes<HTMLAnchorElement>;

  const fromMatchPage = matchPath(location.pathname, { path: ROUTES.MATCH_CASE_DETAILS }) !== null;
  const fromCustomerAssignmentDetails = matchPath(location.pathname, { path: ROUTES.ASSIGNMENT_DETAILS }) !== null;
  const fromCustomerReviewPage = matchPath(location.pathname, { path: ROUTES.CUSTOMER_REVIEW }) !== null;
  const fromCustomerReviewNewPage = matchPath(location.pathname, { path: ROUTES.CUSTOMER_REVIEW_NEW }) !== null;

  const fromCustomersList =
    !fromMatchPage &&
    !fromCustomerAssignmentDetails &&
    !fromCustomerReviewPage &&
    !fromCustomerReviewNewPage &&
    matchPath(location.pathname, { path: ROUTES.CUSTOMERS }) !== null;

  const fromCustomerDetails =
    !fromMatchPage && matchPath(location.pathname, { path: [ROUTES.COMPANY_DETAILS, ROUTES.PERSON_DETAILS] }) !== null;

  const fromProjectsList = matchPath(location.pathname, { path: ROUTES.PROJECTS_LIST, exact: true }) !== null;
  const fromDashboard = matchPath(location.pathname, { path: ROUTES.DASHBOARD }) !== null;
  const isProjectPage = matchPath(location.pathname, { path: ROUTES.PROJECT_DETAILS }) !== null;

  // if link is generated from within the project details page
  // get the project id and project name from the current link state
  const createdFromProject = location.state?.createdFromProject;

  const fromProject = isProjectPage ? location.state?.fromProject : undefined;

  return (
    <Link<HistoryLocationState>
      to={{
        pathname: url,
        state: {
          fromCustomerDetails,
          fromCustomersList,
          fromProjectsList,
          fromDashboard,
          createdFromProject,
          fromProject,
          fromCustomerAssignmentDetails
        }
      }}
      download={download || rest.download}
      target={external ? "_blank" : target}
      {...htmlAnchorParams}
    >
      {children}
    </Link>
  );
};

export default AdapterLink;
