import React, { useState } from "react";
import { HorizontalStack, TextField, VerticalStack } from "@shopify/polaris";
import { UseMutationResult } from "@tanstack/react-query";

import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Button from "../extensions/Button";

export type NoteFormProps = {
  value?: string;
  readonly?: boolean;
  placeholder?: string;
  createNoteMutation: UseMutationResult<unknown, unknown, string>;
  onCancel?(): void;
};

const NoteForm = (props: NoteFormProps) => {
  const { value, readonly, placeholder, createNoteMutation, onCancel } = props;

  const f = useFormatMessage();

  const [comment, setComment] = useState(value || "");

  const handleCancel = () => {
    createNoteMutation.reset();
    setComment("");
    onCancel && onCancel();
  };

  const handleSubmit = () => {
    createNoteMutation.mutate(comment, {
      onSuccess: () => {
        setComment("");
      }
    });
  };

  const disabled = readonly || createNoteMutation.isLoading;
  const isSubmitDisabled = disabled || isEmptyString(comment);

  return (
    <VerticalStack gap="4">
      <TextField
        label={placeholder}
        labelHidden
        value={comment}
        placeholder={placeholder}
        onChange={setComment}
        multiline={4}
        id="text"
        autoComplete="off"
        disabled={readonly}
      />

      <HorizontalStack gap="4" blockAlign="center" align="end">
        <Button onClick={handleCancel} disabled={disabled}>
          {f("default.cancel")}
        </Button>
        <Button primary disabled={isSubmitDisabled} loading={createNoteMutation.isLoading} onClick={handleSubmit}>
          {f("component.notes.labels.save-note")}
        </Button>
      </HorizontalStack>
      {createNoteMutation.isError && <ErrorPanel message={createNoteMutation.error} />}
    </VerticalStack>
  );
};

export default NoteForm;
