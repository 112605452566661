import React, { forwardRef, PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

interface FlatCardProps {
  footer?: ReactNode;
  footerBorder?: boolean;
}

const FlatCard = forwardRef<HTMLDivElement, PropsWithChildren<FlatCardProps>>((props, ref) => {
  const { footer, footerBorder, children, ...rest } = props;

  return (
    <StyledFlatCard ref={ref} {...rest}>
      {children}
      {footer && <StyledFlatCardFooter footerBorder={footerBorder}>{footer}</StyledFlatCardFooter>}
    </StyledFlatCard>
  );
});

const StyledFlatCard = styled.div`
  --kdk-flat-card-spacing-vertical: var(--p-space-2);
  --kdk-flat-card-spacing-horizontal: var(--p-space-4);

  border-radius: var(--p-border-radius-1);
  border: var(--p-border-width-1) solid var(--p-border-subdued);
  padding: var(--kdk-flat-card-spacing-vertical) var(--kdk-flat-card-spacing-horizontal);
  background: var(--p-surface);
`;

export const StyledFlatCardFooter = styled.div<{ footerBorder?: boolean }>`
  padding: var(--kdk-flat-card-spacing-vertical) var(--kdk-flat-card-spacing-horizontal);
  margin: var(--kdk-flat-card-spacing-vertical) calc(-1 * var(--kdk-flat-card-spacing-horizontal))
    calc(-1 * var(--kdk-flat-card-spacing-vertical));

  background-color: var(--p-surface-neutral-subdued);

  border-bottom-left-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);

  border-top: var(--p-border-width-1) solid
    ${({ footerBorder }) => (footerBorder ? "var(--p-border-subdued)" : "transparent")};
`;

export const StyledFlatCardDivider = styled.div`
  border-top: var(--p-border-width-1) solid var(--p-border-subdued);
  margin: var(--kdk-flat-card-spacing-vertical) calc(-1 * var(--kdk-flat-card-spacing-horizontal))
    calc(-1 * var(--kdk-flat-card-spacing-vertical));
`;

export default FlatCard;
