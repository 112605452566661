import { useHistory } from "react-router-dom";

function useBase<T>(
  searchParameterName: string,
  convertToType: (value: string | null) => T
): [T | undefined, (value: T | undefined) => void] {
  const history = useHistory();

  // get value from url search params (either a string or null is not found)
  const searchParams = new URLSearchParams(history.location.search);
  const searchParamValue = searchParams.get(searchParameterName);

  // initial value is the converted url search param value
  const initialValue = convertToType(searchParamValue);

  const setValue = (value: T | undefined) => {
    // get the current url search params
    const currentSearchParams = new URLSearchParams(history.location.search);

    // Save to URLSearchParams or remove from url search params if value is the same as the default one
    if (value) {
      currentSearchParams.set(searchParameterName, String(value));
    } else {
      currentSearchParams.delete(searchParameterName);
    }

    const search = "?" + currentSearchParams.toString();

    // update the URL if there are changes
    if (history.location.search !== search) {
      history.replace({ ...history.location, search });
    }
  };

  return [initialValue, setValue];
}

export const useURLSearchParams = <T = string>(searchParameterName: string): [T[], (values: T[]) => void] => {
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const searchParamValues = searchParams.getAll(searchParameterName) as T[];

  const setValues = (values: T[]) => {
    const currentSearchParams = new URLSearchParams(history.location.search);

    currentSearchParams.delete(searchParameterName);
    values.forEach((value) => currentSearchParams.append(searchParameterName, String(value)));

    const search = "?" + currentSearchParams.toString();

    // update the URL if there are changes
    if (history.location.search !== search) {
      history.replace({ ...history.location, search });
    }
  };

  return [searchParamValues, setValues];
};

export function useURLSearchParam<T extends string>(searchParameterName: string) {
  return useBase<T>(searchParameterName, (value) => value as T);
}

export function useBooleanURLSearchParam(searchParameterName: string) {
  return useBase<boolean>(searchParameterName, (value) => !!value);
}

const convertParameterValueToNumber = (searchParameterName: string, defaultValue: number) => {
  return (value: string | null) => {
    if (!value) {
      return defaultValue;
    }

    try {
      return parseInt(value, 10);
    } catch (error: unknown) {
      console.error(`Failed to parse search parameter ${searchParameterName} to a number. ${error}`);
      return defaultValue;
    }
  };
};

export function useNumericURLSearchParam(searchParameterName: string, defaultValue = 0) {
  return useBase<number>(searchParameterName, convertParameterValueToNumber(searchParameterName, defaultValue));
}
