import React from "react";
import { HorizontalStack } from "@shopify/polaris";

import useCustomersListBulkActions from "../../hooks/useCustomersListBulkActions";
import { Customer } from "../../types/utilities";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import ProjectInfoModal from "../ProjectInfoModal/ProjectInfoModal";

import AddToProjectModal from "./AddToProjectModal/AddToProjectModal";
import ResponsibleUsersModal from "./ResponsibleUsersModal/ResponsibleUsersModal";
import ArchiveCustomers from "./ArchiveCustomers";
import ChangeScreeningTypeModal from "./ChangeScreeningTypeModal";
import CustomersTagsPopover from "./CustomersTagsPopover";
import DeleteCustomers from "./DeleteCustomers";
import FollowupDateModal from "./FollowupDateModal";
import RestoreCustomers from "./RestoreCustomers";

interface CustomersListBulkActionsProps {
  customers: Customer[];
  isArchived?: boolean;
  onClearSelection(): void;
}

const CustomersListBulkActions = (props: CustomersListBulkActionsProps) => {
  const { customers, isArchived, onClearSelection } = props;

  const {
    bulkActions,
    showResponsibleUserModal,
    showArchiveConfirm,
    showDeleteConfirm,
    showRestoreConfirm,
    showAddToProjectModal,
    showAddToNewProjectModal,
    showFollowupDateModal,
    showChangeScreeningTypeModal,
    closeResponsibleUserModal,
    closeArchiveConfirm,
    closeDeleteConfirm,
    closeRestoreConfirm,
    closeAddToProjectModal,
    closeAddToNewProjectModal,
    closeFollowupDateModal,
    closesChangeScreeningTypeModal
  } = useCustomersListBulkActions(customers.length, isArchived);

  return (
    <HorizontalStack gap="2">
      <ActionsDropdown items={bulkActions} cutoff={bulkActions.length === 1 ? 1 : 0} />
      {!isArchived && <CustomersTagsPopover customers={customers} />}
      {showResponsibleUserModal && <ResponsibleUsersModal customers={customers} onClose={closeResponsibleUserModal} />}
      {showArchiveConfirm && (
        <ArchiveCustomers customers={customers} onClose={closeArchiveConfirm} onSuccess={onClearSelection} />
      )}
      {showDeleteConfirm && (
        <DeleteCustomers customers={customers} onClose={closeDeleteConfirm} onSuccess={onClearSelection} />
      )}
      {showRestoreConfirm && (
        <RestoreCustomers customers={customers} onClose={closeRestoreConfirm} onSuccess={onClearSelection} />
      )}
      {showAddToProjectModal && <AddToProjectModal customers={customers} onClose={closeAddToProjectModal} />}
      {showAddToNewProjectModal && <ProjectInfoModal customers={customers} onClose={closeAddToNewProjectModal} />}
      {showFollowupDateModal && <FollowupDateModal customers={customers} onClose={closeFollowupDateModal} />}
      {showChangeScreeningTypeModal && (
        <ChangeScreeningTypeModal customers={customers} onClose={closesChangeScreeningTypeModal} />
      )}
    </HorizontalStack>
  );
};

export default CustomersListBulkActions;
