import api from "../api";
import { Entity, isAssignment, isProject } from "../types/utilities";

// the customer attachments list contains attachments that are either directly from the customer
// and also all the attachments from all the assignments of that customer
// if an attachment has an assignment_id set, then the API that should be returned
// if the assignments API
const useAttachmentsAPI = (entity: Entity, attachment?: api.AttachmentDetails) => {
  const entityId = entity.id;

  if (isProject(entity)) {
    return {
      entityId,
      getAttachments: api.getProjectAttachments,
      addAttachment: api.addProjectAttachment,
      downloadAttachment: api.downloadProjectAttachment,
      deleteAttachment: api.deleteProjectAttachment,
      getAttachmentSignatures: api.getProjectAttachmentSignatures,
      updateAttachmentSignatures: api.updateProjectAttachmentSignatures,
      downloadSignedDocumentAttachment: api.downloadSignedProjectAttachment,
      deleteSignedAttachment: api.deleteSignedProjectAttachment
    };
  } else if (isAssignment(entity) || attachment?.assignment_id) {
    // if attachment.assignment_id is defined, then the attachment belongs to an assignment
    // and the attachments APIs for assignments should be used instead of the customer attachments APIs
    // in this scenario, the customerId is the entity id, since we only show attachments from assignments in the customer
    // details page
    const customerId = isAssignment(entity) ? entity.customer_id : entityId;

    return {
      // if the attachment has an assignment, then the entityId should be the assignment id (as if the entity was an assignment)
      entityId: attachment?.assignment_id || entityId,
      getAttachments: (assignmentId: string) => api.getCustomerAssignmentAttachments(customerId, assignmentId),
      addAttachment: (assignmentId: string, createAttachmentMetadataRequest: api.CreateAttachmentMetadataRequest) =>
        api.addCustomerAssignmentAttachment(customerId, assignmentId, createAttachmentMetadataRequest),
      downloadAttachment: (assignmentId: string, attachmentId: string) =>
        api.downloadCustomerAssignmentAttachment(customerId, assignmentId, attachmentId),
      deleteAttachment: (assignmentId: string, attachmentId: string) =>
        api.deleteCustomerAssignmentAttachment(customerId, assignmentId, attachmentId),
      getAttachmentSignatures: (assignmentId: string, attachmentId: string) =>
        api.getCustomerAssignmentAttachmentSignatures(customerId, assignmentId, attachmentId),
      updateAttachmentSignatures: (
        assignmentId: string,
        attachmentId: string,
        updateAttachmentSignaturesRequest: api.UpdateAttachmentSignaturesRequest
      ) =>
        api.updateCustomerAssignmentAttachmentSignatures(
          customerId,
          assignmentId,
          attachmentId,
          updateAttachmentSignaturesRequest
        ),
      downloadSignedDocumentAttachment: (assignmentId: string, attachmentId: string) =>
        api.downloadSignedCustomerAssignmentAttachment(customerId, assignmentId, attachmentId),
      deleteSignedAttachment: (assignmentId: string, attachmentId: string) =>
        api.deleteSignedCustomerAssignmentAttachment(customerId, assignmentId, attachmentId)
    };
  } else {
    return {
      entityId,
      getAttachments: api.getCustomerAttachments,
      addAttachment: api.addCustomerAttachment,
      downloadAttachment: api.downloadCustomerAttachment,
      deleteAttachment: api.deleteCustomerAttachment,
      getAttachmentSignatures: api.getAttachmentSignatures,
      updateAttachmentSignatures: api.updateAttachmentSignatures,
      downloadSignedDocumentAttachment: api.downloadSignedCustomerAttachment,
      deleteSignedAttachment: api.deleteSignedCustomerAttachment
    };
  }
};

export default useAttachmentsAPI;
