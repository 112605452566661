import React from "react";
import { HorizontalStack, Stack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../../api";
import { groupByCountry } from "../../../helpers/risk.helpers";
import useCountries from "../../../hooks/useCountries";
import useGetCountryFieldTranslation from "../../../hooks/useGetCountryFieldTranslation";
import { FieldCountryMap } from "../../../types/RiskIndicators";
import Country from "../../Country/Country";
import TooltipWrapper from "../../TooltipWrapper/TooltipWrapper";

interface CountryRiskProps {
  countryFields: FieldCountryMap;
}

const CountryRisk = (props: CountryRiskProps) => {
  const { countryFields } = props;

  const { getCountryRiskDescription } = useCountries();

  const getCountryFieldTranslation = useGetCountryFieldTranslation();

  // group all fields by country
  const fieldsByCountryMap = groupByCountry(countryFields);

  return (
    <VerticalStack gap="2">
      {Object.entries(fieldsByCountryMap).map(([country, fields]) => (
        <TooltipWrapper
          dismissOnMouseOut
          preferredPosition="above"
          key={country}
          content={getCountryRiskDescription(country as api.CountryEnum)}
        >
          <HorizontalStack blockAlign="center">
            <Stack spacing="tight" alignment="center">
              <Country country={country} fontWeight="semibold">
                :
              </Country>
              {/* used to add space when stack is horizontal but not when stack is vertical (when fields string is too long)*/}
              <Stack.Item />
            </Stack>
            <Text as="span">{fields.map(getCountryFieldTranslation).join(", ")}</Text>
          </HorizontalStack>
        </TooltipWrapper>
      ))}
    </VerticalStack>
  );
};

export default CountryRisk;
