import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { findNextUnresolvedMatchCase, groupMatchAttributesByCode } from "../helpers/match.helpers";
import { HasNewInfoHandler } from "../types/MatchDetails";

const useMatchPage = (data: api.MatchCaseDetailResponse) => {
  const match = data.case.match;
  const reviews = data.case.reviews;
  const status = data.case.status;
  const isPerson = match.subject_type !== "company";

  // isUpdated is true if the match has been reviewed, but has been
  // updated since the last review, ie. it needs a new review
  const isUpdated = reviews.length > 0 && status !== "RESOLVED";

  // used to hint TypeScript which type to check
  const isSubjectPerson = (subject: api.PersonDetail | api.CompanyDetail): subject is api.PersonDetail => isPerson;

  const hasNewInfo: HasNewInfoHandler = (entity: { is_reviewed?: boolean }) => isUpdated && !entity.is_reviewed;

  const { avatarImages } = useMemo(() => groupMatchAttributesByCode(match), [match]);

  // get all match cases of the current customer
  const { data: matches } = useQuery([QUERIES_KEYS.CUSTOMER_MATCH_CASES, data.customer_id], () =>
    api.getCustomerMatchCases(data.customer_id)
  );

  const matchCases = matches?.match_cases || [];

  const nextUnresolvedMatchCase = findNextUnresolvedMatchCase(matches?.match_cases, data.case.id);

  return {
    match,
    reviews,
    avatarImages,
    matchCases,
    nextUnresolvedMatchCase,
    isSubjectPerson,
    hasNewInfo
  };
};

export default useMatchPage;
