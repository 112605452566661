import React from "react";
import { Stack } from "@shopify/polaris";

import api from "../../../api";
import SeparatorStackWrapper from "../../../components/SeparatorStackWrapper/SeparatorStackWrapper";
import { RiskTypes } from "../../../constants/risk-types";
import { IntervalType } from "../SettingsFollowupPage";

import RiskFollowupInterval from "./RiskFollowupInterval";

interface RiskIntervalsEditorProps {
  intervals: Map<api.RiskLevel, IntervalType>;
  readonly?: boolean;
  onIntervalChange(risk: api.RiskLevel, interval: number | undefined, isCustom: boolean): void;
}

const RiskIntervalsEditor = (props: RiskIntervalsEditorProps) => {
  const { intervals, readonly, onIntervalChange } = props;

  return (
    <SeparatorStackWrapper>
      <Stack distribution={"fillEvenly"} wrap={false}>
        {RiskTypes.map((risk) => (
          <RiskFollowupInterval
            risk={risk}
            isCustom={Boolean(intervals.get(risk)?.custom)}
            readonly={readonly}
            intervalInMonths={intervals.get(risk)?.months}
            onIntervalChange={(intervalInMonths, isCustom) => onIntervalChange(risk, intervalInMonths, isCustom)}
            key={risk}
          />
        ))}
      </Stack>
    </SeparatorStackWrapper>
  );
};

export default RiskIntervalsEditor;
