import React from "react";
import { Layout, TextField, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { createNewReviewChecklistQuestion } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Question } from "../../types/Questions";
import { ValueOf } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import { isNil } from "../../utils/util";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import KDKTextField from "../KDKTextField/KDKTextField";
import QuestionsTable from "../QuestionsEditor/QuestionsTable/QuestionsTable";
import ReviewChecklistConditionsEditor from "../ReviewChecklistConditionsEditor/ReviewChecklistConditionsEditor";
import ReviewChecklistEditorTipsBanner from "../ReviewChecklistEditorTipsBanner/ReviewChecklistEditorTipsBanner";
import SectionDivider from "../SectionDivider/SectionDivider";

type ReviewChecklistEditorProps = {
  checklist: api.ReviewChecklist;
  readonly?: boolean;
  isNew?: boolean;
  error?: unknown;
  onChange(checklist: api.ReviewChecklist): void;
};

const ReviewChecklistEditor = (props: ReviewChecklistEditorProps) => {
  const { checklist, readonly, isNew, error, onChange } = props;

  const f = useFormatMessage();

  const handleFieldChange = (key: keyof api.ReviewChecklist, value: ValueOf<api.ReviewChecklist>) =>
    onChange({ ...checklist, [key]: value });

  const handleQuestionsChange = (questions: Question[]) => onChange({ ...checklist, questions });

  // cast checklist.questions to Question[] in order to force existence of id field
  const questions = checklist.questions as Question[];

  return (
    <VerticalStack gap="5">
      <Layout>
        <Layout.Section>
          <VerticalStack gap="4">
            <KDKTextField
              value={checklist.name || ""}
              label={f("common.labels.name")}
              requiredIndicator
              autoFocus
              autoComplete="on"
              disabled={readonly}
              onChange={(value) => handleFieldChange("name", value)}
              error={isEmptyString(checklist.name) ? f("review.checklist.editor.empty.name") : undefined}
            />
            <TextField
              value={checklist.description || ""}
              label={f("common.labels.description")}
              autoComplete="on"
              multiline={4}
              disabled={readonly}
              onChange={(value) => handleFieldChange("description", value)}
            />
          </VerticalStack>
        </Layout.Section>
        <Layout.Section oneHalf>
          <ReviewChecklistEditorTipsBanner />
        </Layout.Section>
      </Layout>
      <ReviewChecklistConditionsEditor checklist={checklist} readonly={readonly} isNew={isNew} onChange={onChange} />
      {!isNil(error) && <ErrorPanel message={error} />}
      <SectionDivider />
      <QuestionsTable
        questions={questions}
        readonly={readonly}
        onNewQuestion={createNewReviewChecklistQuestion}
        onChange={handleQuestionsChange}
      />
    </VerticalStack>
  );
};

export default ReviewChecklistEditor;
