import React from "react";
import { Button, Icon, Spinner, Text, VerticalStack } from "@shopify/polaris";
import { CancelMinor } from "@shopify/polaris-icons";
import DocumentIcon from "jsx:../../../../assets/icons/doc-text.svg";
import InfoIcon from "jsx:../../../../assets/icons/info-blue.svg";
import styled from "styled-components";

import useGetQuestionnairesSummary from "../../../hooks/useGetQuestionnairesSummary";
import { toggleElement } from "../../../utils/collectionUtils";
import ErrorPanel from "../../ErrorPanel/ErrorPanel";
import TooltipWrapper from "../../TooltipWrapper/TooltipWrapper";

import QuestionnairesSelect, { QuestionnairesSelectProps } from "./QuestionnairesSelect/QuestionnairesSelect";

const DisclosureQuestionnaireSelect = (props: QuestionnairesSelectProps) => {
  const { questionnairesIds, customerType, readonly, onChange } = props;

  const { questionnairesSummaryByIdMap, isLoading, isError, error } = useGetQuestionnairesSummary(customerType);

  if (isLoading) {
    return <Spinner size="small" />;
  }

  if (isError) {
    return <ErrorPanel message={error} />;
  }

  return (
    <VerticalStack gap="4">
      <QuestionnairesSelect {...props} />
      <VerticalStack gap="2">
        {questionnairesIds.map((questionnaireId) => (
          <StyledQuestionnaireNameWrapper
            key={questionnaireId}
            title={questionnairesSummaryByIdMap[questionnaireId].name}
          >
            <Icon source={DocumentIcon} />
            <StyledQuestionnaireName>
              <Text as="span" color={readonly ? "subdued" : undefined} truncate>
                {questionnairesSummaryByIdMap[questionnaireId].name}
              </Text>
              {questionnairesSummaryByIdMap[questionnaireId].description && (
                <TooltipWrapper content={questionnairesSummaryByIdMap[questionnaireId].description}>
                  <Icon source={InfoIcon} />
                </TooltipWrapper>
              )}
            </StyledQuestionnaireName>
            <Button
              disabled={readonly}
              size={"slim"}
              icon={CancelMinor}
              onClick={() => onChange(toggleElement(questionnairesIds, questionnaireId))}
            />
          </StyledQuestionnaireNameWrapper>
        ))}
      </VerticalStack>
    </VerticalStack>
  );
};

const StyledQuestionnaireNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 2rem auto 2rem;

  background: var(--p-surface-subdued);

  border: var(--p-border-width-1) solid var(--p-border-subdued);
  border-radius: var(--p-border-radius-1);

  // reset Polaris button to have square size
  & > .Polaris-Button {
    border: none;
    box-shadow: none;
    padding: var(--p-space-1);
    min-width: auto;

    & > .Polaris-Button__Content {
      padding: 0;
    }
  }

  // reset Polaris icon width
  & > .Polaris-Icon {
    max-width: fit-content;
  }

  // draw lines between components
  & > *:not(:first-child):not(:last-child) {
    line-height: var(--p-font-line-height-4);
    padding: 0 var(--p-space-2);
    border-left: var(--p-border-width-1) solid var(--p-border-subdued);
    border-right: var(--p-border-width-1) solid var(--p-border-subdued);
  }
`;

const StyledQuestionnaireName = styled.div`
  display: grid;
  grid-template-columns: fit-content(100%) 1rem;
  align-items: center;
  gap: var(--p-space-1);
`;

export default DisclosureQuestionnaireSelect;
