import { OptionalCustomerReviewRequest } from "../types/CustomerReview";

export const NEW_REVIEW_CHECKLIST_PREFIX = "review-checklist";

// a list of fields that a change in them will trigger a re-calculation of the review checklists
export const REVIEW_FIELDS_INCLUDED_IN_CHECKLISTS: (keyof OptionalCustomerReviewRequest)[] = [
  "assignment_id",
  "is_pep",
  "is_sanctioned",
  "risk_level",
  "labels"
];
