import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { InView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { PAGE_ANCHORS } from "../../constants/page-anchors";
import usePageNavigation from "../../hooks/usePageNavigation";

// top 10% of the viewport
const TOP_ROOT_MARGIN = "0% 0% -90% 0%";
// bottom 10% of the viewport
const BOTTOM_ROOT_MARGIN = "90% 0% 0% 0%";

export interface NavigationSectionProps {
  anchor?: string;
  bottom?: boolean;
}

const NavigationSection = (props: PropsWithChildren<NavigationSectionProps>) => {
  const { anchor, bottom, children } = props;

  const location = useLocation();
  const history = useHistory();
  const { isNavigationScrolling } = usePageNavigation();

  const rootMargin = useMemo(() => (bottom ? BOTTOM_ROOT_MARGIN : TOP_ROOT_MARGIN), [bottom]);

  const handleChange = useCallback(
    (inView: boolean) => {
      if (inView && anchor) {
        location.hash = anchor === PAGE_ANCHORS.TOP_SECTION ? "" : `#${anchor}`;
        history.replace(location);
      }
    },
    [anchor, location.pathname]
  );

  return (
    <StyledInView id={anchor} onChange={handleChange} rootMargin={rootMargin} skip={isNavigationScrolling}>
      {children}
    </StyledInView>
  );
};

const StyledInView = styled(InView)`
  scroll-margin-top: calc(var(--kdk-toolbar-height) + var(--kdk-message-offset) + var(--p-space-4));
`;

export default NavigationSection;
