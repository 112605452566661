import styled from "styled-components";

export const MarkdownTheme = {
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
    h6: "editor-heading-h6"
  },
  link: "editor-link",
  list: {
    nested: {
      listitem: "editor-nested-listitem"
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listItem"
  },
  text: {
    bold: "editor-textBold",
    italic: "editor-textItalic",
    strikethrough: "editor-textStrikethrough"
  }
};

export const MarkdownStylesWrapper = styled.div`
  & .editor-textBold {
    font-weight: var(--p-font-weight-bold);
  }

  & .editor-textItalic {
    font-style: italic;
  }

  & .editor-textStrikethrough {
    text-decoration: line-through;
  }

  & .editor-heading-h1 {
    font-size: var(--p-font-size-300);
    margin-bottom: var(--p-space-2);
  }

  & .editor-heading-h2 {
    font-size: var(--p-font-size-200);
    margin-bottom: var(--p-space-2);
  }

  & .editor-heading-h3 {
    font-size: var(--p-font-size-100);
    margin-bottom: var(--p-space-2);
  }

  & .editor-heading-h4 {
    font-size: var(--p-font-size-75);
    margin-bottom: var(--p-space-2);
  }

  & .editor-heading-h5 {
    font-size: var(--p-font-size-75);
    margin-bottom: var(--p-space-2);
  }

  & .editor-heading-h6 {
    font-size: var(--p-font-size-75);
    margin-bottom: var(--p-space-2);
  }

  & .editor-quote {
    margin: 0;
    margin-left: var(--p-space-4);
    margin-bottom: var(--p-space-2);
    color: var(--p-text-subdued);
    border-left: solid 4px var(--p-text-disabled);
    padding-left: var(--p-space-2);
  }

  & .editor-paragraph {
    position: relative;
    margin-bottom: var(--p-space-2);
  }

  & ul,
  ol {
    margin: var(--p-space-1) 0;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  & .editor-nested-listitem {
    list-style-type: none;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb {
    background: #999;
  }
`;
