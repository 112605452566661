import React from "react";
import { useParams } from "react-router-dom";
import { Layout, Page, Text, Toast, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import BackButton from "../../components/BackButton/BackButton";
import CustomerReviewAddedChecklistsToast from "../../components/CustomerReviewAddedChecklistsToast/CustomerReviewAddedChecklistsToast";
import CustomerReviewApprovalLabel from "../../components/CustomerReviewApprovalLabel/CustomerReviewApprovalLabel";
import CustomerReviewApprovalSection from "../../components/CustomerReviewApprovalSection/CustomerReviewApprovalSection";
import CustomerReviewAssignmentSection from "../../components/CustomerReviewAssignmentSection/CustomerReviewAssignmentSection";
import CustomerReviewChecklistSection from "../../components/CustomerReviewChecklistSection/CustomerReviewChecklistSection";
import CustomerReviewControlsSection from "../../components/CustomerReviewControlsSection/CustomerReviewControlsSection";
import CustomerReviewDisclosuresSection from "../../components/CustomerReviewDisclosuresSection/CustomerReviewDisclosuresSection";
import CustomerReviewFormSection from "../../components/CustomerReviewFormSection/CustomerReviewFormSection";
import CustomerReviewGuidelineSection from "../../components/CustomerReviewGuidelineSection/CustomerReviewGuidelineSection";
import CustomerReviewMeasuresSection from "../../components/CustomerReviewMeasuresSection/CustomerReviewMeasuresSection";
import CustomerReviewPageBanner from "../../components/CustomerReviewPageBanner/CustomerReviewPageBanner";
import CustomerReviewRiskLevelForm from "../../components/CustomerReviewRiskLevelForm/CustomerReviewRiskLevelForm";
import CustomerReviewRiskSection from "../../components/CustomerReviewRiskSection/CustomerReviewRiskSection";
import UserDisplayName from "../../components/UserDisplayName/UserDisplayName";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getPersonnelRiskInformation, getUnifiedCustomerDisclosureRiskInformation } from "../../helpers/risk.helpers";
import useCustomerReviewMutations from "../../hooks/useCustomerReviewMutations";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetDisclosureCountryRiskInformation from "../../hooks/useGetDisclosureCountryRiskInformation";
import useOpenClose from "../../hooks/useOpenClose";
import { IdentifiersParams } from "../../types/params";
import { CustomerDetails, isPersonCustomerDetails } from "../../types/utilities";
import { hasContent } from "../../utils/collectionUtils";

import CustomerReviewSkeleton from "./CustomerReviewSkeleton";
import useCustomerReviewPage from "./useCustomerReviewPage";

const CustomerReviewPage = ({ data: customer }: AsyncPageChild<CustomerDetails>) => {
  const { id, assignmentId } = useParams<IdentifiersParams>();

  const f = useFormatMessage();
  const features = useFeatures();
  const { getCustomerCountryRiskInformation } = useGetDisclosureCountryRiskInformation();

  const industryRisk = isPersonCustomerDetails(customer) ? undefined : customer.risk_information;
  const matchCases = customer.match_cases;
  const countriesRisk = getCustomerCountryRiskInformation(customer);
  const disclosuresRisk = getUnifiedCustomerDisclosureRiskInformation(customer);
  const personnelRisk = getPersonnelRiskInformation(customer);

  const riskIndicators = { industryRisk, matchCases, countriesRisk, disclosuresRisk, personnelRisk };

  const {
    previousPageLabel,
    readonly,
    review,
    reviewedBy,
    reviewDate,
    reviewApproval,
    isInvalidReview,
    canBeDeleted,
    isPossibleToApprove,
    isReviewChanged,
    signatures,
    assignments,
    savedChecklistAnswers,
    manuallyRemovedChecklistIds,
    addedChecklistsIds,
    resetAddedChecklists,
    setManuallyRemovedChecklistsIds,
    handleReviewChange,
    resetReviewIsChanged,
    setReview,
    onBackNavigation
  } = useCustomerReviewPage(customer, riskIndicators, id, assignmentId);

  const { saveMutation, completeMutation, isLoading } = useCustomerReviewMutations(customer, review, id);

  //////// save review toast
  const [showSavedToast, toggleSavedToast, closeSavedToast] = useOpenClose();

  // add some post-mutations changes after running save and complete review mutations
  const saveReview = useMutation(saveMutation.mutateAsync, {
    onSuccess: () => {
      resetReviewIsChanged();
      toggleSavedToast();
    }
  });

  const completeReview = useMutation(completeMutation.mutateAsync, { onSuccess: onBackNavigation });

  return (
    <Page>
      <VerticalStack gap="8">
        <BackButton showAsLink displayLabel={previousPageLabel} onClick={onBackNavigation} />
        <VerticalStack gap="4">
          <Text variant="heading2xl" as="p">
            {f("reviews.titles.risk-review")}
          </Text>
          {reviewedBy && (
            <VerticalStack gap="2">
              <UserDisplayName user={reviewedBy}>
                <Text variant="bodyMd" as="span">
                  {f("details.reviews.reviewed-by")}
                </Text>
              </UserDisplayName>
              {reviewApproval && <CustomerReviewApprovalLabel reviewApproval={reviewApproval} />}
            </VerticalStack>
          )}
        </VerticalStack>
        <CustomerReviewPageBanner customer={customer} />
        <Layout>
          <Layout.Section>
            <CustomerReviewGuidelineSection customerType={customer.type!} timestamp={reviewDate} />

            {hasContent(assignments) && (
              <CustomerReviewAssignmentSection
                customer={customer}
                reviewId={review.id}
                assignments={assignments}
                selectedAssignmentId={review.assignment_id}
                onAssignmentsChange={(assignmentId) => handleReviewChange("assignment_id", assignmentId)}
                readonly={isLoading || readonly}
              />
            )}

            <CustomerReviewRiskSection customerId={customer.id} riskIndicators={riskIndicators} />

            {features.REVIEW_CHECKLIST && (
              <CustomerReviewChecklistSection
                customerType={customer.type!}
                savedChecklistsAnswers={savedChecklistAnswers}
                checklistsAnswers={review.checklist_answers}
                addedChecklistsIds={addedChecklistsIds}
                manuallyRemovedChecklistIds={manuallyRemovedChecklistIds}
                setManuallyRemovedChecklistsIds={setManuallyRemovedChecklistsIds}
                onChecklistAnswersChange={(answers) => handleReviewChange("checklist_answers", answers)}
                readonly={isLoading || readonly}
              />
            )}

            <CustomerReviewRiskLevelForm
              riskLevel={review.risk_level}
              onRiskLevelChange={(riskLevel) => handleReviewChange("risk_level", riskLevel)}
              readonly={isLoading || readonly}
            />

            {features.MEASURES && (
              <CustomerReviewMeasuresSection
                measures={review.measures || []}
                onChange={(measures) => handleReviewChange("measures", measures)}
                readonly={isLoading || readonly}
              />
            )}

            <CustomerReviewFormSection
              review={review}
              readonly={isLoading || readonly}
              onFieldChange={handleReviewChange}
            />

            <CustomerReviewApprovalSection
              review={review}
              onAcceptedChange={(accepted) => handleReviewChange("accepted", accepted)}
              onChange={setReview}
              readonly={isLoading || readonly}
            />

            <CustomerReviewControlsSection
              customer={customer}
              reviewId={id}
              isPossibleToApprove={isPossibleToApprove}
              isInvalidReview={isInvalidReview}
              canBeDeleted={canBeDeleted}
              isDirty={isReviewChanged}
              readonly={readonly}
              saveReviewMutation={saveReview}
              completeReviewMutation={completeReview}
              onBackNavigation={() => {
                resetReviewIsChanged();
                onBackNavigation();
              }}
            />
          </Layout.Section>

          {signatures.length > 0 && (
            <Layout.Section oneThird>
              <CustomerReviewDisclosuresSection signatures={signatures} />
            </Layout.Section>
          )}
        </Layout>
      </VerticalStack>
      <CustomerReviewAddedChecklistsToast addedChecklistsIds={addedChecklistsIds} onDismiss={resetAddedChecklists} />
      {showSavedToast && <Toast content={f("details.reviews.messages.saved")} onDismiss={closeSavedToast} />}
    </Page>
  );
};

export default withAsyncPage<CustomerDetails>(CustomerReviewPage, {
  queryKey: QUERIES_KEYS.CUSTOMER_REVIEW,
  apiFunction: (customerId, _, options) => api.getCustomerDetails(customerId, options),
  paramNames: ["customerId", "id"],
  skeleton: <CustomerReviewSkeleton />
});
