import styled from "styled-components";

import CondensedText from "../CondensedText/CondensedText";

const Position = styled(CondensedText)`
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Position;
