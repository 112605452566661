import { useEffect, useState } from "react";
import { HorizontalStack, Modal, RadioButton, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { MOBILE_BREAKPOINT } from "../../constants/styles";
import { getCustomerName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useQueryData from "../../hooks/useQueryData";
import { CustomerDetails } from "../../types/utilities";
import { hasContent } from "../../utils/collectionUtils";
import AcceptedFileTypesLabel from "../AcceptedFileTypesLabel/AcceptedFileTypesLabel";
import AddButton from "../AddButton/AddButton";
import AddFileButton, { AddFileButtonProps } from "../AddFileButton/AddFileButton";
import AssignmentInfo from "../AssignmentInfo/AssignmentInfo";
import DisplayName from "../DisplayName/DisplayName";
import Button from "../extensions/Button";

type CustomerUploadDocumentButtonProps = AddFileButtonProps<CustomerDetails> & {
  assignments?: api.Assignment[];
  selectedAssignmentId?: string;
  className?: string;
};

const CustomerUploadDocumentButton = (props: CustomerUploadDocumentButtonProps) => {
  const { assignments, selectedAssignmentId, className, ...addFileButtonProps } = props;

  const { readonly, entity, onFileUploadingStart, onFileUploadingFinish } = addFileButtonProps;

  const f = useFormatMessage();
  const [selectedAssignment, setSelectedAssignment] = useState<api.Assignment | undefined>(() =>
    assignments?.find((assignment) => assignment.id === selectedAssignmentId)
  );

  const [showAssignmentSelectionModal, toggleAssignmentSelectionModal, closeAssignmentSelectionModal] = useOpenClose();
  const [isUploading, setIsUploading] = useState(false);

  const customerAttachmentsQueryKey = [QUERIES_KEYS.ATTACHMENTS, entity.id];
  const updateCustomerQueryData = useQueryData<api.AttachmentDetailsList>(customerAttachmentsQueryKey).updateQueryData;

  useEffect(() => {
    setSelectedAssignment(assignments?.find((assignment) => assignment.id === selectedAssignmentId));
  }, [selectedAssignmentId]);

  const handleFileUploadingFinish = (addedAttachments: api.AttachmentDetails[]) => {
    onFileUploadingFinish(addedAttachments);
    setIsUploading(false);
    // if the attachment was uploaded to an assignment, also update the list of attachment on the customer
    if (selectedAssignment) {
      updateCustomerQueryData(
        (prevData) => (prevData.attachment_list = [...addedAttachments, ...prevData.attachment_list])
      );
    }
  };

  const disabled = readonly || isUploading;

  if (!hasContent(assignments)) {
    return (
      <StyledFloatButtonWrapper className={className}>
        <AddFileButton {...addFileButtonProps} />
      </StyledFloatButtonWrapper>
    );
  }

  return (
    <StyledFloatButtonWrapper className={className}>
      <AddButton disabled={disabled} onClick={toggleAssignmentSelectionModal}>
        {f("attachments.upload.button")}
      </AddButton>
      <AcceptedFileTypesLabel />
      {showAssignmentSelectionModal && (
        <Modal open title={""} titleHidden onClose={closeAssignmentSelectionModal}>
          <Modal.Section>
            <VerticalStack gap="8">
              <Text as={"h3"} variant={"headingMd"}>
                {f("attachments.customer.upload.assignment.select")}
              </Text>
              <VerticalStack gap="2">
                <RadioButton
                  label={
                    <DisplayName name={getCustomerName(entity)} strong>
                      <Text as={"span"} fontWeight={"semibold"}>
                        ({f("common.labels.customer-relationship").toLocaleLowerCase()})
                      </Text>
                    </DisplayName>
                  }
                  checked={!selectedAssignment}
                  onChange={() => setSelectedAssignment(undefined)}
                  disabled={disabled}
                />
                {assignments?.map((assignment) => (
                  <RadioButton
                    label={<AssignmentInfo assignmentName={assignment.name} assignmentPurpose={assignment.purpose} />}
                    checked={selectedAssignment?.id === assignment.id}
                    onChange={(newValue) => setSelectedAssignment(newValue ? assignment : undefined)}
                    disabled={disabled}
                    key={assignment.id}
                  />
                ))}
              </VerticalStack>
              <HorizontalStack gap="2" align={"end"}>
                <Button onClick={closeAssignmentSelectionModal}>{f("default.cancel")}</Button>
                <AddFileButton
                  entity={selectedAssignment ? { ...selectedAssignment, customer_id: entity.id } : entity}
                  readonly={disabled}
                  hideAcceptedFileTypesLabel
                  loading={isUploading}
                  onFileUploadingStart={(filesCount) => {
                    onFileUploadingStart(filesCount);
                    setIsUploading(true);
                  }}
                  onFileUploadingFinish={(addedAttachments) => {
                    handleFileUploadingFinish(addedAttachments);
                    closeAssignmentSelectionModal();
                  }}
                />
              </HorizontalStack>
            </VerticalStack>
          </Modal.Section>
        </Modal>
      )}
    </StyledFloatButtonWrapper>
  );
};

const StyledFloatButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: calc(-1 * var(--p-space-1));

  // For small viewports - render button as a regular block element
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    position: revert;
  }
`;

export default CustomerUploadDocumentButton;
