import React from "react";

import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

type OwnerArchiveConfirmProps = {
  customerId: string;
  owner: api.Owner;
  onClose(): void;
  onAfterArchive(ownerId: string): void;
};

const OwnerArchiveConfirm = (props: OwnerArchiveConfirmProps) => {
  const { customerId, owner, onClose, onAfterArchive } = props;

  const f = useFormatMessage();

  return (
    <ConfirmAction
      apiCall={() => api.deleteCompanyOwner(customerId, owner.id!)}
      title={f("modals.owner.confirm.archive.title")}
      description={f("modals.owner.confirm.archive.description", { owner: getFullName(owner.person) })}
      onNo={onClose}
      actionTitle={f("default.archive")}
      onSuccess={() => onAfterArchive(owner.id!)}
    />
  );
};

export default OwnerArchiveConfirm;
