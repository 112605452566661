import React from "react";
import { EmptyState, Text, VerticalStack } from "@shopify/polaris";

import api from "../../../api";
import ExternalNavigationLink from "../../../components/ExternalNavigationLink/ExternalNavigationLink";
import { isVitecProject } from "../../../helpers/integration.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";

interface ProjectCustomerEmptyStateProps {
  project: api.Project;
}

const ProjectCustomerEmptyState = (props: ProjectCustomerEmptyStateProps) => {
  const { project } = props;

  const f = useFormatMessage();
  const isVitec = isVitecProject(project);

  return (
    <EmptyState heading={f("project.customers.empty.header")} image="">
      {isVitec && (
        <VerticalStack inlineAlign="center">
          <Text variant="bodyMd" as="span">
            {f("project.vitec.customers.empty.message")}
          </Text>
          <ExternalNavigationLink url={project.integrations?.vitec_data?.vitec_url}>
            {f("project.button.imported-from-vitec")}
          </ExternalNavigationLink>
        </VerticalStack>
      )}
      {!isVitec && <p>{f("project.customers.empty.message")}</p>}
    </EmptyState>
  );
};

export default ProjectCustomerEmptyState;
