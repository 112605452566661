import { generatePath, useHistory, useLocation } from "react-router-dom";

import api from "../../api";
import {
  CUSTOMER_INDUSTRY_CODES_NAME_PARAMETER,
  CUSTOMER_TYPE_NAME_PARAMETER,
  CUSTOMER_TYPE_PARAMS,
  MARKED_AS_PEP_NAME_PARAMETER,
  MARKED_AS_SANCTIONED_NAME_PARAMETER,
  PENDING_TASKS_TAB_NAME_PARAMETER,
  RISK_NAME_PARAMETER,
  STATUS_NAME_PARAMETER
} from "../../constants/customers-list-search-params";
import { ROUTES } from "../../constants/routes";
import { CUSTOMERS_LIST_SEARCH } from "../../constants/session-state";
import useClearLastWindowLocationSearch from "../../hooks/useClearLastWindowLocationSearch";
import HistoryLocationState from "../../types/HistoryLocationState";

const useDashboardNavigation = () => {
  const history = useHistory();
  const location = useLocation<HistoryLocationState>();

  const clearLastWindowLocationSearch = useClearLastWindowLocationSearch(CUSTOMERS_LIST_SEARCH);

  const navigateToCustomersList = (params: Record<string, string>) => {
    clearLastWindowLocationSearch();

    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([name, value]) => searchParams.set(name, value));

    const url = `${generatePath(ROUTES.CUSTOMERS)}?${searchParams.toString()}`;

    history.push(url, { ...location.state, fromDashboard: true });
  };

  const navigateToPendingTasks = () => navigateToCustomersList({ [PENDING_TASKS_TAB_NAME_PARAMETER]: "true" });

  const navigateToCustomersByRisk = (risk: api.RiskLevel) => navigateToCustomersList({ [RISK_NAME_PARAMETER]: risk });

  const navigateToCustomersByStatus = (statusParam: api.ReviewStatus) =>
    navigateToCustomersList({ [STATUS_NAME_PARAMETER]: statusParam });

  const navigateToPepCustomers = (customerType?: CUSTOMER_TYPE_PARAMS) => {
    const params: Record<string, string> = {
      [MARKED_AS_PEP_NAME_PARAMETER]: "true"
    };

    if (customerType) {
      params[CUSTOMER_TYPE_NAME_PARAMETER] = customerType;
    }

    navigateToCustomersList(params);
  };

  const navigateToSanctionedCustomers = (customerType?: CUSTOMER_TYPE_PARAMS) => {
    const params: Record<string, string> = {
      [MARKED_AS_SANCTIONED_NAME_PARAMETER]: "true"
    };

    if (customerType) {
      params[CUSTOMER_TYPE_NAME_PARAMETER] = customerType;
    }

    navigateToCustomersList(params);
  };

  const navigateToCustomersByType = (customerType: CUSTOMER_TYPE_PARAMS) =>
    navigateToCustomersList({ [CUSTOMER_TYPE_NAME_PARAMETER]: customerType });

  const navigateToCustomersByIndustryType = (industryCode: api.IndustryCodeEnum) =>
    navigateToCustomersList({ [CUSTOMER_INDUSTRY_CODES_NAME_PARAMETER]: industryCode });

  return {
    navigateToPendingTasks,
    navigateToCustomersByRisk,
    navigateToCustomersByStatus,
    navigateToPepCustomers,
    navigateToSanctionedCustomers,
    navigateToCustomersByType,
    navigateToCustomersByIndustryType
  };
};

export default useDashboardNavigation;
