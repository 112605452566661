import { useState } from "react";
import { HorizontalStack, Modal, Scrollable, Thumbnail, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { first } from "../../utils/collectionUtils";
import Button from "../extensions/Button";

type MatchImagesProps = {
  entityName: string;
  avatarAttributes?: api.MatchAttribute[];
};

const MatchImages = (props: MatchImagesProps) => {
  const { entityName, avatarAttributes } = props;

  const [selectedThumbnail, setSelectedThumbnail] = useState<string>();

  if (!avatarAttributes || avatarAttributes.length === 0) {
    return null;
  }

  const avatarAttribute = first(avatarAttributes)!;

  return (
    <>
      <HorizontalStack gap="2">
        <Button plain onClick={() => setSelectedThumbnail(avatarAttribute.value)}>
          <Thumbnail source={avatarAttribute.value} alt={avatarAttribute.description} size="large" transparent />
        </Button>
      </HorizontalStack>
      {selectedThumbnail !== undefined && (
        <Modal open title={entityName} onClose={() => setSelectedThumbnail(undefined)}>
          <Modal.Section>
            <VerticalStack gap="4">
              <Scrollable horizontal vertical={false}>
                <HorizontalStack gap="2" wrap={false}>
                  {avatarAttributes.map((avatarImageAttribute, index) => (
                    <StyledImageButton
                      plain
                      onClick={() => setSelectedThumbnail(avatarImageAttribute.value)}
                      key={index}
                    >
                      <Thumbnail
                        key={index}
                        source={avatarImageAttribute.value}
                        alt={avatarImageAttribute.description}
                        size="large"
                      />
                    </StyledImageButton>
                  ))}
                </HorizontalStack>
              </Scrollable>
              <StyledImage src={selectedThumbnail} />
            </VerticalStack>
          </Modal.Section>
        </Modal>
      )}
    </>
  );
};

const StyledImageButton = styled(Button)`
  flex: 0 0 auto;
`;

const StyledImage = styled.img`
  width: 100%;
`;

export default MatchImages;
