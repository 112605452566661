import api from "../api";
import { PAGE_ANCHORS } from "../constants/page-anchors";
import { NEW_QUESTION_PREFIX } from "../constants/questions";
import {
  OptionalChecklistAnswersRequest,
  OptionalChecklistQuestionAnswerRequest,
  ReviewChecklistConditionType,
  ReviewChecklistSelectionType
} from "../types/ReviewChecklist";
import { hasContent } from "../utils/collectionUtils";
import { isEmptyString } from "../utils/stringUtils";
import { isEmpty, removeProperty, uniqueId } from "../utils/util";

export const EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS: api.ReviewChecklistDisplaySettings = {
  risk_categories: [],
  risk_levels: [],
  assignment_purposes: [],
  labels: [],
  is_always_select: false
};

export const createNewReviewChecklistQuestion = (): api.ReviewChecklistQuestion & { id: string } => ({
  id: uniqueId(NEW_QUESTION_PREFIX),
  text: {
    en: "",
    nb: ""
  }
});

export const convertChecklistToCreateChecklistRequest = (
  checklist: api.ReviewChecklist
): api.CreateReviewChecklistRequest => ({
  name: checklist.name,
  description: checklist.description,
  customer_type: checklist.customer_type,
  is_required: checklist.is_required,
  display_settings: checklist.display_settings
    ? {
        ...checklist.display_settings,
        label_ids: checklist.display_settings?.labels?.map((label) => label.id),
        assignment_purposes: checklist.display_settings?.assignment_purposes.filter(Boolean)
      }
    : undefined,
  // strip id from questions
  questions: checklist.questions.map((question) => removeProperty(question, "id"))
});

export const isChecklistEmpty = (checklist: Map<string, { answer?: boolean; text?: string }>) =>
  [...checklist.values()].every(({ answer, text }) => answer !== true && isEmpty(text));

// checks if a list of answers contains one or more filled answers (answer is true/false (and not undefined)
export const isChecklistPartiallyFilled = (answers?: OptionalChecklistQuestionAnswerRequest[]) =>
  answers?.some((answer) => answer.answer !== undefined || !isEmptyString(answer.additional_info));

export const isChecklistActive = (checklist: api.ReviewChecklist) => !checklist.is_archived;

export const createChecklistAnswers = (
  checklistReview: api.ReviewChecklist,
  defaultAnswer?: boolean
): OptionalChecklistAnswersRequest => ({
  checklist_id: checklistReview.id,
  version: checklistReview.version,
  answers: checklistReview.questions.map((question) => ({ question_id: question.id!, answer: defaultAnswer }))
});

const convertChecklistQuestionAnswerToChecklistQuestionAnswerRequest = (
  checklistAnswer: api.ChecklistQuestionAnswer
): api.ChecklistQuestionAnswerRequest => ({
  question_id: checklistAnswer.question_id,
  additional_info: checklistAnswer.additional_info,
  answer: checklistAnswer.answer
});

export const convertChecklistAnswerToChecklistAnswerRequest = (
  checklistAnswer: api.ChecklistAnswers
): api.ChecklistAnswersRequest => ({
  checklist_id: checklistAnswer.checklist.id,
  version: checklistAnswer.checklist.version,
  answers: checklistAnswer.answers.map(convertChecklistQuestionAnswerToChecklistQuestionAnswerRequest)
});

const convertOptionalChecklistQuestionAnswerRequestToChecklistQuestionAnswerRequest = (
  checklistAnswer: OptionalChecklistQuestionAnswerRequest
): api.ChecklistQuestionAnswerRequest => ({
  ...checklistAnswer,
  answer: Boolean(checklistAnswer.answer)
});

export const convertOptionalChecklistAnswersRequestToChecklistAnswersRequest = (
  checklistAnswers: OptionalChecklistAnswersRequest
): api.ChecklistAnswersRequest => ({
  ...checklistAnswers,
  answers: checklistAnswers.answers
    .filter((checklistAnswer) => checklistAnswer.answer !== undefined)
    .map(convertOptionalChecklistQuestionAnswerRequestToChecklistQuestionAnswerRequest)
});

export const getChecklistConditionType = (
  condition?: api.ReviewChecklistDisplaySettings
): ReviewChecklistConditionType | undefined => {
  if (hasContent(condition?.risk_categories)) return "risk";
  if (hasContent(condition?.labels)) return "tag";
  if (hasContent(condition?.assignment_purposes)) return "purpose";
};

export const getChecklistSelectionType = (
  condition?: api.ReviewChecklistDisplaySettings
): ReviewChecklistSelectionType => {
  if (condition?.is_always_select) return "always";
  if (getChecklistConditionType(condition)) return "automatic";
  return "manual";
};

export const getChecklistAnchorId = (checklistId: string) => `${PAGE_ANCHORS.REVIEW_CHECKLIST_FORM}-${checklistId}`;
