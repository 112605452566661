import React from "react";
import { HorizontalStack, List, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { groupByCountry } from "../../helpers/risk.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetCountryFieldTranslation from "../../hooks/useGetCountryFieldTranslation";
import { CountryFieldType, FieldCountryMap } from "../../types/RiskIndicators";
import Country from "../Country/Country";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface MultipleCountriesProps {
  countries: FieldCountryMap;
}

const MultipleCountries = (props: MultipleCountriesProps) => {
  const { countries } = props;
  const f = useFormatMessage();
  const getCountryFieldTranslation = useGetCountryFieldTranslation();

  // group all fields by country
  const fieldsByCountryMap = groupByCountry(countries);

  const showMultipleCountries = Object.keys(fieldsByCountryMap).length > 1;

  const getTooltipContent = (country: api.CountryEnum, fieldTypes: CountryFieldType[]) => (
    <StyledTooltipContentWrapper>
      <VerticalStack gap="2">
        <Country country={country} fontWeight="semibold" />
        <List>
          {fieldTypes.map((fieldType) => (
            <List.Item key={fieldType}>{getCountryFieldTranslation(fieldType)}</List.Item>
          ))}
        </List>
      </VerticalStack>
    </StyledTooltipContentWrapper>
  );

  if (countries.size === 0) {
    return (
      <HorizontalStack>
        <Text as="span">{f("default.unknown")}</Text>
      </HorizontalStack>
    );
  }

  return (
    <HorizontalStack gap="1">
      {[...Object.entries(fieldsByCountryMap)].map(([country, fieldTypes]) => (
        <TooltipWrapper
          content={getTooltipContent(country as api.CountryEnum, fieldTypes)}
          preferredPosition="above"
          dismissOnMouseOut
          key={country}
        >
          <StyledCountry
            key={country}
            country={country}
            hideLabel={showMultipleCountries}
            showMultipleCountries={showMultipleCountries}
          />
        </TooltipWrapper>
      ))}
    </HorizontalStack>
  );
};

const StyledCountry = styled(Country)<{ showMultipleCountries?: boolean }>`
  // increase font size if only showing country flags without labels
  font-size: ${({ showMultipleCountries }) => (showMultipleCountries ? "var(--p-font-size-100)" : "inherit")};
  white-space: break-spaces;
`;

const StyledTooltipContentWrapper = styled.div`
  padding: var(--p-space-4);
`;

export default MultipleCountries;
