import React from "react";
import { Stack, StackProps } from "@shopify/polaris";

import ArrowDownIcon from "../../../assets/icons/arrow-down.svg";
import ArrowUpIcon from "../../../assets/icons/arrow-up.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export type Direction = "up" | "down";

interface MoveButtonsProps extends StackProps {
  disableMoveUp?: boolean;
  disableMoveDown?: boolean;
  readonly?: boolean;
  onOrderChange(direction: Direction): void;
}

const MoveButtons = (props: MoveButtonsProps) => {
  const { disableMoveUp, disableMoveDown, readonly, onOrderChange, spacing = "tight", ...stackProps } = props;

  const f = useFormatMessage();

  const moveUp = () => onOrderChange("up");
  const moveDown = () => onOrderChange("down");

  return (
    <Stack spacing={spacing} wrap={false} {...stackProps}>
      <TooltipWrapper content={f("common.buttons.move-up")} dismissOnMouseOut>
        <Button onClick={moveUp} disabled={disableMoveUp || readonly} icon={ArrowUpIcon} plain hoverable />
      </TooltipWrapper>
      <TooltipWrapper content={f("common.buttons.move-down")} dismissOnMouseOut>
        <Button onClick={moveDown} disabled={disableMoveDown || readonly} icon={ArrowDownIcon} plain hoverable />
      </TooltipWrapper>
    </Stack>
  );
};

export default MoveButtons;
