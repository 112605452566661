import React, { memo } from "react";
import classNames from "classnames";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";
import { BACKGROUND_STATUS, STATUS_TO_CUSTOM_PROPERTY_NAME } from "../extensions/types";

import { ROW_OPTIONS } from "./types";

const formatCustomPropertyName = (status?: BACKGROUND_STATUS, suffix = "") =>
  status ? `var(${STATUS_TO_CUSTOM_PROPERTY_NAME[status]}${suffix})` : `var(--p-surface${suffix})`;

type TableRowProps = React.HTMLAttributes<HTMLTableRowElement> & ROW_OPTIONS & { depth?: number; expanded?: boolean };

const TableRow = (props: TableRowProps) => {
  const { className, expanded, ...rest } = props;

  const classes = classNames("Polaris-DataTable__TableRow Polaris-DataTable--hoverable", className);

  const showSeparator = Boolean(!expanded && rest.depth === 0);

  // when rendering a row as a banner, add rows before and after the given row
  // in order to add space (margin) between the banner row and the rows adjacent to it.
  // this is done because margins don't work for table rows
  if (rest.warningBanner) {
    return (
      <>
        <StyledSpacerRow />
        <StyledBannerRow {...rest} className={classes} />
        {showSeparator && <StyledSpacerRow />}
      </>
    );
  }
  return <StyledTableRow {...rest} className={classes} />;
};

const StyledTableRow = styled.tr<TableRowProps>`
  // create css custom variables for regular status that do not come pre-defined in Polaris for use in formatCustomPropertyName
  --p-surface-regular-subdued: hsl(180, 11%, 96%);
  --p-surface-regular-subdued-hovered: hsl(180, 14%, 94%);

  & .Polaris-DataTable__Cell {
    background-color: var(--p-surface ${({ status }) => (status ? `-${status}-subdued` : "")});
    background-color: ${({ status }) => (status ? formatCustomPropertyName(status) : undefined)};
  }

  &:hover .Polaris-DataTable__Cell {
    background: ${({ status }) => formatCustomPropertyName(status, "-hovered")};
  }

  & > td {
    // hide the rows separator if this is a regular (non warning) sub row
    border-top: ${({ depth, warningBanner }) => (!warningBanner && depth && depth > 0 ? "none !important" : undefined)};
  }

  // For small viewports - render cells in a column (as cards) and remove the borders between cells
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: grid;
    margin: var(--p-space-8) 0;
    border-radius: var(--p-border-radius-2);
    box-shadow: var(--p-shadow-card);
    border: var(--p-border-divider);

    & + & .Polaris-DataTable__Cell {
      border-top: none;
    }
  }
`;

const StyledBannerRow = styled(StyledTableRow)`
  --border-style: solid var(--p-border-width-1) var(--p-border-subdued);
  --warning-border-radius: var(--p-border-radius-2);

  // For small viewports - hide cells border
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    --border-style: none;
    --warning-border-radius: 0;
  }

  & .Polaris-DataTable__Cell {
    border-top: var(--border-style);
    border-bottom: var(--border-style);
    background-color: var(--p-background);

    &:first-child {
      border-left: var(--border-style);
      border-top-left-radius: var(--warning-border-radius);
      border-bottom-left-radius: var(--warning-border-radius);
    }

    &:last-child {
      border-right: var(--border-style);
      border-top-right-radius: var(--warning-border-radius);
      border-bottom-right-radius: var(--warning-border-radius);
      margin-right: var(--p-space-4);
    }
  }

  &:hover .Polaris-DataTable__Cell {
    background-color: var(--p-background-hovered);
    --border-style: solid var(--p-border-width-1) var(--p-border-hovered);
  }
`;

const StyledSpacerRow = styled.tr`
  height: var(--p-space-4);
  position: relative;

  // For small viewports - hide the spacer row since the gap between rows is set by ReactTable
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    height: 0;
  }

  & + &::before {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    border-top: var(--p-border-divider);
  }
`;

export default memo(TableRow);
