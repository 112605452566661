import React from "react";
import { Banner } from "@shopify/polaris";

import useFormatMessage from "../../../hooks/useFormatMessage";
import { CustomerDetails } from "../../../types/utilities";
import { isValidDate } from "../../../utils/dateUtils";

interface FollowupExpiredBannerProps {
  customer: CustomerDetails;
}

const FollowupExpiredBanner = (props: FollowupExpiredBannerProps) => {
  const { customer } = props;

  const f = useFormatMessage();

  const { follow_up_date } = customer;

  if (!isValidDate(follow_up_date)) {
    return null;
  }

  return (
    <Banner title={f("common.banner.followup-expired.title")} status="warning">
      <p>{f("common.banner.followup-expired.description", { followup: new Date(follow_up_date!) })}</p>
    </Banner>
  );
};

export default FollowupExpiredBanner;
