import api from "../api";
import { ErrorType } from "../types/utilities";

// Error status 400
type BadRequestError = {
  data?: {
    detail: string;
    title?: string[] | string;
    person?: Record<keyof api.DataServicePersonDetail, string[]>;
    subject?: {
      person?: Record<keyof api.DataServicePersonDetail, string[]>;
      legal_entity?: Record<keyof api.DataServiceLegalEntityDetailResponse, string[]>;
    };
  };
};

// Error status 422
type ValidatorError = {
  data?: api.HttpValidationError;
};

// Error status 409
type ConflictError = {
  data?: {
    detail?: string;
    id?: string;
    onboarding_status?: api.OnboardingStatus;
  };
};

export const isBadRequestError = (error?: ErrorType): error is BadRequestError => error?.status === 400;

export const isValidationError = (error?: ErrorType): error is ValidatorError => error?.status === 422;

export const isConflictError = (error?: ErrorType): error is ConflictError => error?.status === 409;

export const getErrorTitles = (title: string[] | string) => (Array.isArray(title) ? title?.join(", ") : title);

export const is4xxError = (status: ErrorType) =>
  typeof status === "number" && Number.isInteger(status) && status >= 400 && status < 500;
