import React from "react";
import { generatePath, useHistory } from "react-router-dom";

import { ROUTES } from "../../../constants/routes";
import useFormatMessage from "../../../hooks/useFormatMessage";
import Confirm from "../../Confirm/Confirm";

interface OwnershipUpdatesCompleteModalProps {
  customerId: string;
  onClose(): void;
}

const OwnershipUpdatesCompleteModal = (props: OwnershipUpdatesCompleteModalProps) => {
  const { customerId, onClose } = props;

  const f = useFormatMessage();
  const history = useHistory();

  const navigateToCustomerScreening = () =>
    history.push(generatePath(ROUTES.COMPANY_CHANGE_SCREENING, { id: customerId }));

  return (
    <Confirm
      title={f("ownership-update.confirm-all.modal.title")}
      description={f("ownership-update.confirm-all.modal.content")}
      onYes={navigateToCustomerScreening}
      onNo={onClose}
      isDestructive={false}
      cancelTitle={f("default.close")}
      actionTitle={f("ownership-update.confirm-all.modal.action.label")}
    />
  );
};

export default OwnershipUpdatesCompleteModal;
