import React, { useMemo, useState } from "react";

import TagIcon from "../../../assets/icons/tag.svg";
import api from "../../api";
import { getTagTitle } from "../../helpers/tag.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";
import PopoverButton, { PopoverButtonProps } from "../PopoverButton/PopoverButton";
import PopoverStickyWrapper from "../PopoverStickyWrapper/PopoverStickyWrapper";
import TagsChoiceList, { TagsChoiceListProps } from "../TagsChoiceList/TagsChoiceList";

import NewTagForm from "./NewTagForm";

type TagsEditorPopoverProps = Omit<PopoverButtonProps, "render"> &
  TagsChoiceListProps & {
    onNewTag?(text: api.TranslationText): void;
  };

const TagsEditorPopover = (props: TagsEditorPopoverProps) => {
  const {
    tags,
    selectedTags,
    partiallySelectedTags,
    onSelectionChange,
    onNewTag,
    enableReadonlySelection,
    ...popoverButtonProps
  } = props;

  const f = useFormatMessage();

  const [filterValue, setFilterValue] = useState("");

  const handleNewTag = (onClose: () => void) => async (tagTitle: string) => {
    // use the given value from NewTagForm to create a tag were both languages (Norwegian and English)
    // have the same value
    onNewTag && (await onNewTag({ nb: tagTitle, en: tagTitle }));
    onClose();
  };

  const filterTagExists = useMemo(
    () => tags?.some((tag) => getTagTitle(tag).toLocaleLowerCase() === filterValue.toLocaleLowerCase()),
    [tags, filterValue],
  );

  const disableAddButton = filterTagExists || popoverButtonProps.disabled;

  return (
    <PopoverButton
      label={f("tags.button.label")}
      icon={<Icon source={TagIcon} useMask />}
      size="slim"
      {...popoverButtonProps}
      render={(onClose) => (
        <PopoverStickyWrapper
          filter={
            <NewTagForm
              disabled={disableAddButton}
              onNewTag={onNewTag ? handleNewTag(onClose) : undefined}
              onTagValueChange={setFilterValue}
            />
          }
        >
          <TagsChoiceList
            tags={tags}
            selectedTags={selectedTags}
            partiallySelectedTags={partiallySelectedTags}
            disabled={popoverButtonProps.disabled}
            enableReadonlySelection={enableReadonlySelection}
            filterValue={filterValue}
            onSelectionChange={onSelectionChange}
          />
        </PopoverStickyWrapper>
      )}
    />
  );
};

export default TagsEditorPopover;
