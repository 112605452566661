import React, { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { Card, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import AddButton from "../../components/AddButton/AddButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import Button from "../../components/extensions/Button";
import FilterContainer from "../../components/FilterContainer/FilterContainer";
import ImportChecklistsButton from "../../components/ImportChecklistsButton/ImportChecklistsButton";
import ReviewChecklistArchiveConfirm from "../../components/ReviewChecklistArchiveConfirm/ReviewChecklistArchiveConfirm";
import ReviewChecklistCreateInstanceModal from "../../components/ReviewChecklistCreateInstanceModal/ReviewChecklistCreateInstanceModal";
import ReviewChecklistDuplicateModal from "../../components/ReviewChecklistDuplicateModal/ReviewChecklistDuplicateModal";
import ReviewChecklistPageSkeleton from "../../components/ReviewChecklistPageSkeleton/ReviewChecklistPageSkeleton";
import ReviewChecklistPreviewModal from "../../components/ReviewChecklistPreviewModal/ReviewChecklistPreviewModal";
import ReviewChecklistsTable from "../../components/ReviewChecklistsTable/ReviewChecklistsTable";
import ReviewChecklistTemplatesSection from "../../components/ReviewChecklistTemplatesSection/ReviewChecklistTemplatesSection";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { ROUTES } from "../../constants/routes";
import useChecklistRestoreMutation from "../../hooks/useChecklistRestoreMutation";
import useExportReviewChecklistMutation from "../../hooks/useExportReviewChecklistMutation";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useSnackbar from "../../hooks/useSnackbar";
import { ActionType } from "../../types/Settings";
import { sortByName } from "../../utils/collectionUtils";
import { isEmptyString } from "../../utils/stringUtils";

type Action = { checklist: api.ReviewChecklist; actionType: ActionType };

const SettingsReviewChecklistsListPage = ({ data }: AsyncPageChild<api.ReviewChecklistListResponse>) => {
  const f = useFormatMessage();
  const features = useFeatures();
  const { isPermittedTo } = usePermissions();

  const { setSnackbar } = useSnackbar();

  const [activeAction, setActiveAction] = useState<Action>();
  const resetActiveAction = () => setActiveAction(undefined);

  const [isShowingArchivedChecklist, toggleShowArchivedChecklist] = useOpenClose();

  const restoreChecklistMutation = useChecklistRestoreMutation();
  const exportReviewChecklistMutation = useExportReviewChecklistMutation();

  const [queryText, setQueryText] = useState("");

  const hasArchivedChecklists = data.checklists.some((checklist) => checklist.is_archived);

  const checklistsToDisplay = useMemo(() => {
    let checklists = isShowingArchivedChecklist
      ? data.checklists
      : data.checklists.filter((checklist) => !checklist.is_archived);

    if (!isEmptyString(queryText)) {
      checklists = checklists.filter((checklist) =>
        checklist.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
      );
    }

    checklists.sort(sortByName);

    return checklists;
  }, [isShowingArchivedChecklist, data.checklists, queryText]);

  const handlePreview = (checklist: api.ReviewChecklist) =>
    setActiveAction({ checklist: checklist, actionType: "Preview" });

  const handleDuplicate = (checklist: api.ReviewChecklist) =>
    setActiveAction({ checklist: checklist, actionType: "Duplicate" });

  const handleArchive = (checklist: api.ReviewChecklist) =>
    setActiveAction({ checklist: checklist, actionType: "Archive" });

  const handleCreateInstance = (checklist: api.ReviewChecklist) =>
    setActiveAction({ checklist: checklist, actionType: "Instance" });

  const handleRestore = (checklist: api.ReviewChecklist) => restoreChecklistMutation.mutate(checklist.id);

  const handleExport = (checklist: api.ReviewChecklist) =>
    exportReviewChecklistMutation.mutate(checklist, {
      //  No need for translation since this action is used to move checklists between tenants
      onSuccess: () => setSnackbar("checklist exported successfully!")
    });

  const readonly = !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const filterChecklistsLabel = isShowingArchivedChecklist
    ? f("review.checklists.page.filter.hide-archived-checklists")
    : f("review.checklists.page.filter.show-archived-checklists");

  return (
    <SettingsPage
      readonly={readonly}
      title={f("review.checklists.page.title")}
      subtitle={f("review.checklists.page.header.description")}
      primaryAction={
        <AddButton url={generatePath(ROUTES.SETTINGS_REVIEW_CHECKLIST_DETAILS_NEW)} disabled={readonly}>
          {f("review.checklists.page.add.button.label")}
        </AddButton>
      }
      secondaryActions={features.SETTINGS__REVIEW_CHECKLISTS_IMPORT_EXPORT ? <ImportChecklistsButton /> : undefined}
    >
      <VerticalStack gap="10">
        <ReviewChecklistTemplatesSection onCreateInstance={handleCreateInstance} readonly={readonly} />
        <VerticalStack gap="4">
          <VerticalStack gap="2">
            <Text as={"h2"} variant={"headingSm"}>
              {f("review.checklists.page.table.header")}
            </Text>
            <FilterContainer>
              <SearchTextField
                placeholder={f("review.checklists.page.filter.placeholder")}
                value={queryText}
                clearButton
                onChange={setQueryText}
                onClearButtonClick={() => setQueryText("")}
              />
            </FilterContainer>
          </VerticalStack>
          <Card>
            <ReviewChecklistsTable
              checklists={checklistsToDisplay}
              readonly={readonly}
              queryText={queryText}
              isRestoringChecklist={restoreChecklistMutation.isLoading}
              selectedChecklistId={activeAction?.checklist.id}
              onPreview={handlePreview}
              onDuplicate={handleDuplicate}
              onArchive={handleArchive}
              onRestore={handleRestore}
              onExport={handleExport}
            />
          </Card>
        </VerticalStack>
      </VerticalStack>
      {hasArchivedChecklists && (
        <HorizontalStack>
          <Button onClick={toggleShowArchivedChecklist} disclosure={isShowingArchivedChecklist ? "up" : "down"}>
            {filterChecklistsLabel}
          </Button>
        </HorizontalStack>
      )}
      {activeAction?.actionType === "Preview" && (
        <ReviewChecklistPreviewModal checklist={activeAction?.checklist} onClose={resetActiveAction} />
      )}
      {activeAction?.actionType === "Duplicate" && (
        <ReviewChecklistDuplicateModal checklist={activeAction?.checklist} onClose={resetActiveAction} />
      )}
      {activeAction?.actionType === "Archive" && (
        <ReviewChecklistArchiveConfirm checklist={activeAction.checklist} onClose={resetActiveAction} />
      )}
      {activeAction?.actionType === "Instance" && (
        <ReviewChecklistCreateInstanceModal checklistTemplate={activeAction?.checklist} onClose={resetActiveAction} />
      )}
    </SettingsPage>
  );
};

export default withAsyncPage<api.ReviewChecklistListResponse>(SettingsReviewChecklistsListPage, {
  queryKey: QUERIES_KEYS.REVIEW_CHECKLISTS,
  apiFunction: api.getReviewChecklists,
  paramNames: [],
  skeleton: <ReviewChecklistPageSkeleton />
});
