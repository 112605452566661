import React, { useCallback, useEffect } from "react";
import { Checkbox, Stack, VerticalStack } from "@shopify/polaris";

import SearchTextField from "../../components/SearchTextField/SearchTextField";
import { QUERY_SEARCH_DEBOUNCE_DELAY } from "../../constants/durations";
import { ERROR_STATUSES } from "../../constants/import-customers";
import useDebouncedState from "../../hooks/useDebouncedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IMPORT_STATUS } from "../../types/ImportRow";

export interface ImportCustomersFiltersProps {
  statuses: IMPORT_STATUS[];
  onStatusesChanges(statuses: IMPORT_STATUS[]): void;
  onFilterQueryChange(searchText: string): void;
}

const ImportCustomersFilters = (props: ImportCustomersFiltersProps) => {
  const { statuses, onStatusesChanges, onFilterQueryChange } = props;

  const f = useFormatMessage();

  const [queryText, setQueryText] = useDebouncedState("", QUERY_SEARCH_DEBOUNCE_DELAY);
  useEffect(() => onFilterQueryChange(queryText), [queryText]);

  const clearQueryText = useCallback(() => setQueryText(""), []);

  const toggleErrors = () => onStatusesChanges(statuses.length === 0 ? ERROR_STATUSES : []);

  return (
    <VerticalStack gap={statuses.length === 0 ? "0" : "2"}>
      <Stack distribution={"leading"} alignment={"center"}>
        <SearchTextField
          value={queryText}
          onChange={setQueryText}
          labelHidden
          placeholder={f("default.search")}
          clearButton
          onClearButtonClick={clearQueryText}
        />

        <Checkbox label={"Show errors"} checked={statuses.length > 0} onChange={toggleErrors} />
      </Stack>
    </VerticalStack>
  );
};

export default ImportCustomersFilters;
