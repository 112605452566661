import useFetch from "../hooks/useFetch";

import { cleanupFilename } from "./filenameUtils";

const DEFAULT_FILE_NAME = "default_file.pdf";

const download = (url: string, filename?: string) => {
  const fetch = useFetch();
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        response
          .arrayBuffer()
          .then((data) => {
            if (response.status !== 200) {
              reject(response.statusText);
            } else {
              const headers = response.headers;
              const link = document.createElement("a");
              const type = headers.get("content-type")!;
              link.href = window.URL.createObjectURL(new Blob([data], { type }));
              link.style.display = "none";
              link.setAttribute("download", filename || extractFilenameFromHeaders(headers));
              document.body.appendChild(link);
              link.click();
              resolve(true);
            }
          })
          .catch((e) => reject(e));
      })
      .catch((e) => reject(e));
  });
};

const extractFilenameFromHeaders = (headers: Headers) => {
  if (headers.has("content-disposition")) {
    const headerFilename = headers.get("content-disposition") || "";
    return cleanupFilename(headerFilename.split('"')[1]) || "default_file.pdf";
  } else {
    return DEFAULT_FILE_NAME;
  }
};

export default download;
