import React, { useState } from "react";
import { Modal, Text, Toast, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { MAX_FOLLOWUP_INTERVAL_IN_MONTHS } from "../../constants/followup";
import { getCustomerName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import useOpenClose from "../../hooks/useOpenClose";
import { Customer } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import DateInput from "../DateInput/DateInput";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

interface FollowupDateModalModalProps {
  customers: Customer[];
  onClose(): void;
}

const FollowupDateModal = (props: FollowupDateModalModalProps) => {
  const { customers, onClose } = props;

  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();
  const [followupDate, setFollowupDate] = useState<string>();

  const customersIds = customers.map((customer) => customer.id);

  const saveFollowupDateMutation = useMutation(
    () => api.batchSetFollowUpDateOnCustomers({ customer_ids: customersIds, follow_up_date: followupDate }),
    {
      onSuccess: () => toggleSuccessMessageToast()
    }
  );

  const saveAction = {
    content: f("default.save"),
    onAction: () => saveFollowupDateMutation.mutate(),
    loading: saveFollowupDateMutation.isLoading,
    disabled: isEmptyString(followupDate)
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: () => onClose(),
    disabled: saveFollowupDateMutation.isLoading
  };

  const customersNamesLabel = formatMultipleNames(customers.map(getCustomerName));

  const handleToastDismiss = () => {
    closeSuccessMessageToast();
    onClose();
  };

  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + MAX_FOLLOWUP_INTERVAL_IN_MONTHS / 12);

  return (
    <>
      <Modal
        open={!showSuccessMessageToast}
        title={f("followup.modal.title")}
        onClose={onClose}
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
      >
        <Modal.Section>
          <VerticalStack gap="4">
            <Text variant="bodyMd" as="span">
              {f("followup.modal.message", { names: customersNamesLabel })}
            </Text>
            <DateInput
              label={f("followup.modal.labels.date")}
              value={followupDate}
              onChange={(newValue, isValid) => setFollowupDate(isValid ? newValue : undefined)}
              minDate={minDate}
              maxDate={maxDate}
              disabled={saveFollowupDateMutation.isLoading}
              requiredIndicator
              autoComplete="off"
            />
          </VerticalStack>
        </Modal.Section>
        {saveFollowupDateMutation.isError && <ErrorPanel message={saveFollowupDateMutation.error} />}
      </Modal>
      {showSuccessMessageToast && (
        <Toast onDismiss={handleToastDismiss} content={f("bulk.actions.followup.success.message")} />
      )}
    </>
  );
};

export default FollowupDateModal;
