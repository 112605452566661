import React, { PropsWithChildren } from "react";
import { Badge as PolarisBadge, BadgeProps as PolarisBadgeProps } from "@shopify/polaris";
import styled from "styled-components";

// get Polaris badge size and status type definitions
type PolarisBadgeSize = PolarisBadgeProps["size"];
type PolarisBadgeStatus = PolarisBadgeProps["status"];

// extend Polaris badge size type definition with "xsmall" value and status with "default-dark" status
type BadgeSize = PolarisBadgeSize | "xsmall";
export type BadgeStatus = PolarisBadgeStatus | "default-dark" | "severe" | "success-dark";

// extend Polaris badge properties without original "size" and "status" property
export interface BadgeProps extends Omit<PolarisBadgeProps, "size" | "status" | "children"> {
  // replace the omitted size property with the extended size type
  size?: BadgeSize;
  status?: BadgeStatus;
  className?: string;
}

const Badge = ({ size, status, className, children, ...props }: PropsWithChildren<BadgeProps>) => {
  const isExtraSmall = size === "xsmall";
  const isDark = status === "default-dark";
  const isSevere = status === "severe";
  const isSuccessDark = status === "success-dark";

  // for "xsmall" size = pass "small" size value to Polaris Badge to avoid typescript conflicts
  // but style the badge to look extra small
  const badgeSize = isExtraSmall ? "small" : size;

  // for "default-dark" status = pass "undefined" status value to Polaris Badge to avoid typescript conflicts
  // but style the badge to look dark
  const badgeStatus = isDark || isSuccessDark ? undefined : status;

  return (
    <StyledBadge
      isExtraSmall={isExtraSmall}
      isDark={isDark}
      isSevere={isSevere}
      isSuccessDark={isSuccessDark}
      className={className}
    >
      {/* @ts-ignore */}
      <PolarisBadge size={badgeSize} status={badgeStatus} {...props}>
        {children as string}
      </PolarisBadge>
    </StyledBadge>
  );
};

const StyledBadge = styled.span<{ isExtraSmall: boolean; isDark: boolean; isSevere: boolean; isSuccessDark: boolean }>`
  ${(props) =>
    props.isExtraSmall &&
    `& .Polaris-Badge > .Polaris-Text--root {
      font-size: var(--kdk-font-size-50);
      font-weight: var(--p-font-weight-semibold);
      line-height: 1;
      padding: var(--p-space-025);
  }`}

  ${(props) =>
    props.isDark &&
    `& .Polaris-Badge {
      color: var(--p-text-on-dark);
      background-color: var(--kdk-dark-grey);
  }`}

  ${(props) =>
    props.isSevere &&
    `& .Polaris-Badge {
      background-color: var(--kdk-red);
      color: var(--p-text-on-primary);
  }`}
  
  ${(props) =>
    props.isSuccessDark &&
    `& .Polaris-Badge {
      color: var(--p-text-on-dark);
      background-color: var(--kdk-dark-success);
  }`}
`;

export default Badge;
