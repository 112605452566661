import { ReactNode } from "react";
import { HorizontalStack } from "@shopify/polaris";

import InfoIcon from "../../../assets/icons/info-blue.svg";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

type InfoTooltipLabelProps = {
  label: string;
  content: ReactNode;
};

const InfoTooltipLabel = (props: InfoTooltipLabelProps) => {
  const { label, content } = props;

  return (
    <HorizontalStack gap="1" blockAlign={"center"} wrap={false}>
      <CondensedText italic>{label}</CondensedText>
      <TooltipWrapper content={content}>
        <Icon source={InfoIcon} width="14px" height="14px" />
      </TooltipWrapper>
    </HorizontalStack>
  );
};

export default InfoTooltipLabel;
