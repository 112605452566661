import Highlighter from "react-highlight-words";
import styled from "styled-components";

const TextHighlighter = styled(Highlighter).attrs({ autoEscape: true })`
  & > mark {
    background: inherit;
    color: inherit;
    font-weight: var(--p-font-weight-bold);
  }
`;

export default TextHighlighter;
