import { DateRange } from "../constants/pagination";

import { isNumber } from "./numbersUtils";

export const isValidDate = (dateValue?: string, minDate?: Date, maxDate?: Date) => {
  if (!dateValue) return false;

  // Check if valid date
  // see https://medium.com/@esganzerla/simple-date-validation-with-javascript-caea0f71883c
  const date = new Date(dateValue);
  const day = parseInt(dateValue.substr(8, 2));
  // if +date === 0 it means the date is 01-01-1970
  const isValidDate = +date === 0 || (Boolean(+date) && date.getDate() == day);
  if (!isValidDate) return false;

  // Check if date is within min/max limits
  if (minDate && date < minDate) {
    return false;
  }
  if (maxDate && date > maxDate) {
    return false;
  }
  return true;
};

export const isValidYear = (yearValue?: string | number, minDate?: Date, maxDate?: Date) => {
  if (!yearValue) return false;

  const year = isNumber(yearValue) ? yearValue : parseYear(yearValue);

  if (isNaN(year)) return false;

  if (minDate && year < minDate.getFullYear()) return false;

  if (maxDate && year > maxDate.getFullYear()) return false;

  return true;
};

export const formatDate = (year: string, month: string, day: string) =>
  `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

// format a Date to the format of yyyy-mm-dd that is the input for <input type="date">
// see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
export const formatDateInput = (date: Date) =>
  formatDate(String(date.getFullYear()), String(date.getMonth() + 1), String(date.getDate()));

// parses a date string in the format of yyyy-mm-dd and returns the year as a number
export const parseYear = (dateValue: string) => parseInt(dateValue.split("-")[0], 10);

export const secondsBetweenDates = (dateValueA?: string, dateValueB?: string) => {
  if (!dateValueA || !dateValueB || !isValidDate(dateValueA) || !isValidDate(dateValueB)) {
    return NaN;
  }

  const dateA = new Date(dateValueA);
  const dateB = new Date(dateValueB);

  return Math.ceil(Math.abs((dateB.getTime() - dateA.getTime()) / 1000));
};

export const daysBetweenDates = (dateValueA?: string, dateValueB?: string) => {
  const seconds = secondsBetweenDates(dateValueA, dateValueB);
  if (isNaN(seconds)) {
    return seconds;
  }

  return Math.ceil(seconds / (3600 * 24));
};

export const getRangeDate = (range?: DateRange, customDate?: string, inThePast?: boolean) => {
  const now = new Date();

  // reset time properties
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  const action = inThePast ? -1 : 1;

  switch (range) {
    case DateRange.WEEK:
      now.setDate(now.getDate() + action * 7);
      return now.toISOString();
    case DateRange.MONTH:
      now.setMonth(now.getMonth() + action * 1);
      return now.toISOString();
    case DateRange.YEAR:
      now.setFullYear(now.getFullYear() + action * 1);
      return now.toISOString();
    case DateRange.CUSTOM:
    default:
      return customDate;
  }
};

export const transformDate = (d?: string | null, year?: string | null) =>
  year ? year : d ? d.split("-")[0] : undefined;
