import React from "react";
import { ComplexAction, HorizontalStack, Modal, TextField, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { isInvalidAssignment } from "../../helpers/assignments.helpers";
import useAuth from "../../hooks/useAuth";
import useChangedState from "../../hooks/useChangedState";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ValueOf } from "../../types/utilities";
import DateInput from "../DateInput/DateInput";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import PurposeChoicesSelect from "../PurposeChoicesSelect/PurposeChoicesSelect";

import IndustryChoicesSelect from "./IndustryChoicesSelect";

export type AssignmentModalProps = {
  customerId: string;
  assignment?: api.Assignment;
  onCancel(): void;
  onSave(assignment: api.Assignment, isNew: boolean): void;
};

const AssignmentModal = (props: AssignmentModalProps) => {
  const { customerId, assignment, onCancel, onSave } = props;

  const isNew = assignment === undefined;

  const f = useFormatMessage();
  const features = useFeatures();
  const { user: currentUser } = useAuth();

  const responsibleUsersIds =
    isNew && currentUser ? [currentUser.id] : (assignment?.responsible_users || []).map((user) => user.id);

  const [assignmentDetails, setAssignmentDetails, changed] = useChangedState<api.CreateOrUpdateAssignmentRequest>({
    industry_type: assignment?.industry_type,
    name: assignment?.name || "",
    description: assignment?.description || "",
    purpose: assignment?.purpose,
    purpose_desc: assignment?.purpose_desc,
    responsible_users: responsibleUsersIds,
    start_date: assignment?.start_date,
    end_date: assignment?.end_date
  });

  const saveAssignmentMutation = useMutation(
    () =>
      isNew
        ? api.createCustomerAssignment(customerId, assignmentDetails)
        : api.updateCustomerAssignment(customerId, assignment.id, assignmentDetails),
    {
      onSuccess: (response) => onSave(response, isNew)
    }
  );

  const saveAssignment = () => saveAssignmentMutation.mutate();

  const isInvalid = isInvalidAssignment(assignmentDetails);

  const handleChange = (
    name: keyof api.CreateOrUpdateAssignmentRequest,
    value: ValueOf<api.CreateOrUpdateAssignmentRequest>
  ) => {
    setAssignmentDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePurposeChange = (purpose?: string, purposeDescription?: string) => {
    setAssignmentDetails((prevState) => ({ ...prevState, purpose, purpose_desc: purposeDescription }));
  };

  const title = isNew ? f("common.buttons.actions.new-assignment") : f("common.buttons.actions.edit-assignment");

  const disabled = assignment?.is_archived || saveAssignmentMutation.isLoading;

  const cancelAction: ComplexAction = {
    content: assignment?.is_archived ? f("default.close") : f("default.cancel"),
    onAction: onCancel,
    disabled: saveAssignmentMutation.isLoading
  };

  const saveAction: ComplexAction = {
    content: isNew ? f("default.create") : f("default.save"),
    onAction: saveAssignment,
    disabled: disabled || isInvalid || !changed,
    loading: saveAssignmentMutation.isLoading
  };

  return (
    <Modal
      open
      onClose={onCancel}
      title={title}
      primaryAction={saveAction}
      secondaryActions={[cancelAction]}
      footer={saveAssignmentMutation.isError && <ErrorPanel message={saveAssignmentMutation.error} />}
    >
      <Modal.Section>
        <VerticalStack gap="4">
          <TextField
            label={f("customer-assignments.modal.assignment-name")}
            placeholder={f("customer-assignments.modal.assignment-name")}
            value={assignmentDetails.name}
            onChange={(value) => handleChange("name", value)}
            disabled={disabled}
            autoFocus
            requiredIndicator
            autoComplete="off"
          />
          {features.ASSIGNMENT_INDUSTRIES && (
            <IndustryChoicesSelect
              label={f("customer-assignments.modal.assignment-industry")}
              placeholder={f("customer-assignments.modal.assignment-industry.placeholder")}
              selectedIndustry={assignmentDetails.industry_type}
              autoComplete="off"
              onChange={(industryType) => handleChange("industry_type", industryType)}
              disabled={disabled || assignment?.industry_type !== undefined}
            />
          )}
          <PurposeChoicesSelect
            industryType={assignmentDetails.industry_type}
            hasPurposeDesc={assignmentDetails.purpose_desc !== undefined}
            value={assignmentDetails.purpose || assignmentDetails.purpose_desc}
            showDeprecatedPurposes={!isNew}
            onChange={handlePurposeChange}
            disabled={disabled}
          />
          <HorizontalStack gap="8" blockAlign={"end"}>
            <DateInput
              label={f("common.labels.start-date")}
              value={assignmentDetails.start_date || ""}
              onChange={(value) => handleChange("start_date", value)}
              clearButton
              onClearButtonClick={() => handleChange("start_date", undefined)}
              disabled={disabled}
              autoComplete="off"
            />
            <DateInput
              label={f("common.labels.end-date")}
              value={assignmentDetails.end_date || ""}
              onChange={(value) => handleChange("end_date", value)}
              clearButton
              onClearButtonClick={() => handleChange("end_date", undefined)}
              disabled={disabled}
              autoComplete="off"
            />
          </HorizontalStack>
          <TextField
            label={f("customer-assignments.modal.assignment-description")}
            placeholder={f("customer-assignments.modal.assignment-description")}
            value={assignmentDetails.description}
            multiline={3}
            onChange={(value) => handleChange("description", value)}
            disabled={disabled}
            requiredIndicator
            autoComplete="off"
          />
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};

export default AssignmentModal;
