import React from "react";
import { Card } from "@shopify/polaris";

import CustomerReviewApproveRejectToolbar, {
  CustomerReviewApproveRejectToolbarProps
} from "../CustomerReviewApproveRejectToolbar/CustomerReviewApproveRejectToolbar";
import CustomerReviewEditToolbar, {
  CustomerReviewEditToolbarProps
} from "../CustomerReviewEditToolbar/CustomerReviewEditToolbar";

type CustomerReviewControlsSectionProps = CustomerReviewApproveRejectToolbarProps &
  CustomerReviewEditToolbarProps & {
    isPossibleToApprove?: boolean;
  };

const CustomerReviewControlsSection = (props: CustomerReviewControlsSectionProps) => {
  const {
    isPossibleToApprove,
    customer,
    reviewId,
    isInvalidReview,
    readonly,
    canBeDeleted,
    isDirty,
    saveReviewMutation,
    completeReviewMutation,
    onBackNavigation
  } = props;

  return (
    <Card>
      <Card.Section>
        {isPossibleToApprove && (
          <CustomerReviewApproveRejectToolbar
            customer={customer}
            reviewId={reviewId}
            onBackNavigation={onBackNavigation}
          />
        )}
        {!isPossibleToApprove && (
          <CustomerReviewEditToolbar
            customer={customer}
            reviewId={reviewId}
            isInvalidReview={isInvalidReview}
            canBeDeleted={canBeDeleted}
            isDirty={isDirty}
            readonly={readonly}
            onBackNavigation={onBackNavigation}
            saveReviewMutation={saveReviewMutation}
            completeReviewMutation={completeReviewMutation}
          />
        )}
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewControlsSection;
