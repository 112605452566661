import api from "../api";
import {
  CountryFieldType,
  CountryRiskInformation,
  DisclosureRiskInformation,
  FieldCountryMap,
  PersonRiskInformation
} from "../types/RiskIndicators";
import { CustomerDetails, isPersonCustomerDetails } from "../types/utilities";
import { groupBy } from "../utils/collectionUtils";

import { traverseSignedSignatures } from "./disclosures.helpers";
import { getPersonKey } from "./person.helpers";

export const getIndustryRiskCount = (industryRisk?: api.IndustryRiskInformation) =>
  (industryRisk?.industry_types.industry_code_1?.has_risk ? 1 : 0) +
  (industryRisk?.industry_types.industry_code_2?.has_risk ? 1 : 0) +
  (industryRisk?.industry_types.industry_code_3?.has_risk ? 1 : 0);

export const groupByCountry = (countryFields: FieldCountryMap) => {
  return [...countryFields.entries()].reduce(
    (map, [fieldType, countries]) => {
      [countries].flat().forEach((country) => (map[country] = [...(map[country] || []), fieldType]));
      return map;
    },
    {} as Record<api.CountryEnum, CountryFieldType[]>
  );
};

// convert answers that are not of country type to risk information list
export const getUnifiedCustomerDisclosureRiskInformation = (customer: CustomerDetails): DisclosureRiskInformation[] => {
  const disclosuresRiskInformation: DisclosureRiskInformation[] = [];

  traverseSignedSignatures(customer, (signature) => {
    signature.disclosure_answers?.sections
      .flatMap((section) => section.answers)
      .forEach((answer) => {
        answer.risk_indicators?.forEach((riskIndicator) => {
          if (!isGeographicalRiskIndicator(riskIndicator.type)) {
            disclosuresRiskInformation.push({
              signature,
              riskIndicator,
              answer
            });
          }
        });
      });
  });

  return disclosuresRiskInformation;
};

export const isGeographicalRiskIndicator = (riskIndicatorType: api.DisclosureRiskIndicatorType) =>
  riskIndicatorType === "HIGH_RISK_COUNTRY";

export const groupRiskInformationByPersonName = <T extends CountryRiskInformation | PersonRiskInformation>(
  countriesRisk: T[]
): T[] => {
  // group risk information people to avoid showing the same person with different roles
  // only a single person will be shown with multiple roles (if exists)
  const peopleByNameMap = groupBy(countriesRisk, (risk) => (risk.person ? getPersonKey(risk.person) : risk.name));

  const groupedRiskInformation: T[] = [...peopleByNameMap.values()].map((people: T[]) => ({
    // people is a list of all the roles/owners with the same name + birth year
    // deconstruct the entire list into a single object and add all the different role types / ownership
    // to types field
    ...Object.assign({}, ...people),
    types: people.map((risk) => risk.role?.type).filter(Boolean)
  }));

  return groupedRiskInformation;
};

const getRolesRiskInformation = (customer: api.CompanyCustomerDetailResponse): PersonRiskInformation[] => {
  return customer.roles
    .filter((role) => role.person?.is_pep || role.person?.is_sanctioned)
    .map((role) => ({
      isPep: Boolean(role.person?.is_pep),
      isSanctioned: Boolean(role.person?.is_sanctioned),
      person: role.person,
      customerType: "person",
      role: role
    }));
};

const getOwnersRiskInformation = (customer: api.CompanyCustomerDetailResponse): PersonRiskInformation[] => {
  return customer.beneficial_owners
    .filter((owner) => owner.person?.is_pep || owner.person?.is_sanctioned)
    .map((owner) => ({
      isPep: Boolean(owner.person?.is_pep),
      isSanctioned: Boolean(owner.person?.is_sanctioned),
      person: owner.person,
      customerType: owner.company ? "company" : "person",
      owner: owner
    }));
};

export const getPersonnelRiskInformation = (customer: CustomerDetails): PersonRiskInformation[] => {
  if (isPersonCustomerDetails(customer)) {
    return [];
  }

  const rolesRiskInformation = getRolesRiskInformation(customer);
  const ownersRiskInformation = getOwnersRiskInformation(customer);

  return [...rolesRiskInformation, ...ownersRiskInformation];
};
