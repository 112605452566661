import React from "react";
import { Query, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { Customer } from "../../types/utilities";
import EntitiesTagPopover, { AssignedTags } from "../EntitiesTagPopover/EntitiesTagPopover";

type CustomersTagsPopoverProps = {
  customers: Customer[];
  readonly?: boolean;
};

const CustomersTagsPopover = (props: CustomersTagsPopoverProps) => {
  const { customers, readonly } = props;

  const queryClient = useQueryClient();

  const customersIds = customers.map((customer) => customer.id);

  const updateCustomers = (updatedCustomers: Customer[]) => {
    const queryCache = queryClient.getQueryCache();

    // find all search queries that begins with QUERIES_KEYS.CUSTOMERS_LIST
    const customersSearchQueries: Query[] = queryCache.findAll([QUERIES_KEYS.CUSTOMERS_LIST]);

    customersSearchQueries.forEach(({ queryKey }) => {
      const cacheData = queryClient.getQueryData<api.CustomerListResponse>(queryKey);
      if (cacheData) {
        // merge the updatedCustomers with existing query cached customers
        const customers = cacheData.customers.map((customer) =>
          customersIds.includes(customer.id)
            ? { ...customer, ...updatedCustomers.find((updatedCustomer) => updatedCustomer.id === customer.id) }
            : customer
        );
        queryClient.setQueryData(queryKey, { ...cacheData, customers });
      }
    });
  };

  const onAssignTags = ({ entitiesIds, tagIds }: AssignedTags) => {
    return api
      .batchSetLabelsOnCustomers({
        target_ids: entitiesIds,
        label_ids: tagIds
      })
      .then(({ customers }) => customers);
  };

  const onCreatedAndAssignTag = (customersIds: string[], title: api.TranslationText) =>
    api
      .batchCreateAndAssignLabelOnCustomers({
        target_ids: customersIds,
        title
      })
      .then(({ customers }) => customers);

  return (
    <EntitiesTagPopover<Customer>
      entities={customers}
      readonly={readonly}
      onUpdateEntities={updateCustomers}
      onAssignTags={onAssignTags}
      onCreatedAndAssignTag={onCreatedAndAssignTag}
    />
  );
};

export default CustomersTagsPopover;
