import React from "react";
import { HorizontalStack } from "@shopify/polaris";
import styled from "styled-components";

import RedFlagIcon from "../../../assets/icons/red-flag.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { HasNewInfoHandler } from "../../types/MatchDetails";
import Icon from "../extensions/Icon";
import MatchAttributesCollapsibleCardSections from "../MatchAttributesCollapsibleCardSections/MatchAttributesCollapsibleCardSections";
import MatchCategorySection from "../MatchCategorySection/MatchCategorySection";
import MatchEvent from "../MatchEvent/MatchEvent";
import Tag from "../Tag/Tag";

type MatchDetailsSanctionsSectionProps = {
  sanctionEvents: api.MatchEvent[];
  hasNewInfo: HasNewInfoHandler;
};

const MatchDetailsSanctionsSection = (props: MatchDetailsSanctionsSectionProps) => {
  const { sanctionEvents, hasNewInfo } = props;

  const f = useFormatMessage();

  const showDescription = sanctionEvents.length > 0;

  return (
    <MatchCategorySection
      title={f("matches.labels.sanctions")}
      description={
        showDescription ? (
          <StyledSanctionTag>
            <HorizontalStack blockAlign="center" wrap={false}>
              <Icon source={RedFlagIcon} width="16px" height="16px" />
              {f("matches.labels.sanction")}
            </HorizontalStack>
          </StyledSanctionTag>
        ) : undefined
      }
    >
      {showDescription && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.listings")}
          elements={sanctionEvents}
          renderElement={(event) => <MatchEvent event={event} showNewInfo={hasNewInfo(event)} />}
        />
      )}
    </MatchCategorySection>
  );
};

const StyledSanctionTag = styled(Tag)`
  & .Polaris-Tag {
    background-color: var(--kdk-red-500);
    border: solid var(--p-border-width-1) var(--kdk-red);
  }
`;

export default MatchDetailsSanctionsSection;
