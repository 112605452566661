import React from "react";

import ActiveScreeningIcon from "../../../assets/icons/screening_active.svg";
import InactiveScreeningIcon from "../../../assets/icons/screening_inactive.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface ScreeningTypeProps {
  screeningType?: api.ScreeningType;
}

const ScreeningType = (props: ScreeningTypeProps) => {
  const { screeningType } = props;

  const f = useFormatMessage();

  if (screeningType !== "NONE" && screeningType !== "MONITORING") {
    return null;
  }

  const tooltipContent =
    screeningType === "MONITORING"
      ? f("screening-type.tooltip.person.screening.monitoring")
      : f("screening-type.tooltip.person.screening.none");

  return (
    <TooltipWrapper content={tooltipContent} dismissOnMouseOut preferredPosition="above">
      <Icon source={screeningType === "MONITORING" ? ActiveScreeningIcon : InactiveScreeningIcon} />
    </TooltipWrapper>
  );
};

export default ScreeningType;
