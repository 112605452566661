import React, { useState } from "react";
import { useIntl } from "react-intl";
import { HorizontalStack, Spinner, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import DownloadIcon from "../../../../../assets/icons/arrow-down-rectangle.svg";
import FileIcon from "../../../../../assets/icons/file.svg";
import DeleteIcon from "../../../../../assets/icons/trash.svg";
import api from "../../../../api";
import { getFullName } from "../../../../helpers/display.helpers";
import useAttachmentDownload from "../../../../hooks/useAttachmentDownload";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import { Entity } from "../../../../types/utilities";
import { cleanupFilename } from "../../../../utils/filenameUtils";
import ActionsDropdown from "../../../ActionsDropdown/ActionsDropdown";
import Button from "../../../extensions/Button";
import { hasSignedAttachment, wasSignedAttachmentDeleted } from "../../AttachmentsSection.helpers";
import DeleteAttachmentConfirmation from "../DeleteAttachmentConfirmation";

interface AttachmentSignedDocumentSectionProps {
  entity: Entity;
  attachment: api.AttachmentDetails;
  readonly?: boolean;
}

const AttachmentSignedDocumentSection = (props: AttachmentSignedDocumentSectionProps) => {
  const { entity, attachment, readonly } = props;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const f = useFormatMessage();
  const intl = useIntl();
  const downloadAttachmentMutation = useAttachmentDownload(entity, attachment);

  const hasSignedAttachmentFile = hasSignedAttachment(attachment);
  const hasSignedAttachmentDeleted = wasSignedAttachmentDeleted(attachment);
  const filename = cleanupFilename(attachment.filename);

  const name = getFullName(attachment.signed_document_deleted_by);
  const date = intl.formatDate(attachment.signed_document_deleted_timestamp);

  const actions = [
    {
      content: f("attachments.actions.download-signed-attachment"),
      image: DownloadIcon,
      disabled: downloadAttachmentMutation.isLoading,
      prefix: downloadAttachmentMutation.isLoading ? <Spinner size="small" /> : null,
      onAction: () => downloadAttachmentMutation.mutate({ downloadSignedAttachment: true })
    },
    {
      content: f("attachments.actions.delete-signed-attachment"),
      image: DeleteIcon,
      onAction: () => setShowDeleteConfirmation(true),
      disabled: readonly || downloadAttachmentMutation.isLoading
    }
  ];

  return (
    <VerticalStack gap="4">
      <Text variant="headingMd" as="h2">
        {f("attachments.actions.section.signed.document.title")}
      </Text>
      {hasSignedAttachmentFile && (
        <HorizontalStack gap="4" blockAlign="center">
          <StyledDownloadSignedDocumentButton
            plain
            icon={<img src={FileIcon} />}
            truncate
            textAlign="left"
            loading={downloadAttachmentMutation.isLoading}
            onClick={() => downloadAttachmentMutation.mutate({ downloadSignedAttachment: true })}
          >
            {filename}
          </StyledDownloadSignedDocumentButton>
          <ActionsDropdown items={actions} hideTitle />
        </HorizontalStack>
      )}
      {hasSignedAttachmentDeleted && (
        <StyledDeleteMessage>
          <Text as="span" color="subdued">
            {f("attachments.signatures.modal.labels.deleted-signed-document", { name, date })}
          </Text>
        </StyledDeleteMessage>
      )}
      {showDeleteConfirmation && (
        <DeleteAttachmentConfirmation
          entity={entity}
          attachment={attachment}
          deleteSignedAttachment
          onModalClose={() => setShowDeleteConfirmation(false)}
        />
      )}
    </VerticalStack>
  );
};

const StyledDownloadSignedDocumentButton = styled(Button)`
  margin-left: -0.625rem;
  display: inline-block;
`;

const StyledDeleteMessage = styled.div`
  font-style: italic;
`;

export default AttachmentSignedDocumentSection;
