import { HorizontalStack } from "@shopify/polaris";

import SuccessIcon from "../../../assets/icons/checkmark-green.svg";
import ErrorIcon from "../../../assets/icons/exclamationmark-triangle-filled-red.svg";
import api from "../../api";
import { formatKARAccountNumber } from "../../helpers/kar.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

type KarLookupResultStatusProps = {
  result: api.KarLookupResult;
};

const KarLookupResultStatus = (props: KarLookupResultStatusProps) => {
  const { result } = props;

  const f = useFormatMessage();

  const icon = result.result ? SuccessIcon : ErrorIcon;
  const tooltip = !result.result ? f("kar.table.status.failure.tooltip") : undefined;

  return (
    <HorizontalStack gap="1" blockAlign={"center"} wrap={false}>
      <TooltipWrapper content={tooltip}>
        <Icon source={icon} />
      </TooltipWrapper>
      {formatKARAccountNumber(result.account_number)}
    </HorizontalStack>
  );
};

export default KarLookupResultStatus;
