import { Auth0ContextInterface, GetTokenSilentlyOptions } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

import { isNumber } from "../../utils/numbersUtils";

import { isInExpiredPage, redirectToExpiredPage } from "./AuthProvider.helpers";

/**
 * Interval for checking the user session and refreshing the API access token
 * if the user is active.
 *
 * Setting the interval to 45 seconds is reasonable as the access token will be
 * taken from the cache unless it's within 60 seconds of expiring (see
 * https://github.com/auth0/auth0-spa-js/blob/4c0c755c51781ed8da7cd43b0f33da1c9166b963/src/Auth0Client.ts#L1165),
 * in which case we will get a new token by calling Auth0's API.
 */
const CHECK_SESSION_INTERVAL_IN_MS = 45_000;

interface GetAccessTokenOrRedirectToExpiredOptions extends GetTokenSilentlyOptions {
  /**
   * Whether or not the user should be redirected to the /expired page
   * if getting the access token fails.
   * Defaults to `true`
   */
  redirectToExpired?: boolean;
}

/**
 * This service is initialized (and terminated) from `BeaufortAuthProvider`.
 *
 * This service is responsible for:
 * - Monitoring user activity using a `visibilityState`-event listener and an interval
 *    for regularly checking the session, refreshing the access token and redirecting
 *    to `/expired` if the user has been inactive for too long
 * - API fetch interceptor which gets API access token (usually from cache, or from Auth0
 *    if near expiry) before each request to the API
 */
class AuthSessionService {
  #getAccessTokenSilently?: Auth0ContextInterface["getAccessTokenSilently"] = undefined;

  lastActive?: Date;
  checkSessionInterval?: NodeJS.Timeout;

  constructor() {
    this.getAccessTokenOrRedirectToExpired = this.getAccessTokenOrRedirectToExpired.bind(this);
    this.apiFetchInterceptor = this.apiFetchInterceptor.bind(this);
  }

  /**
   * Inits the auth session service. Called from `BeaufortAuthProvider`.
   * Sets `this.#getAccessTokenSilently` and `this.lastActive` and creates/starts
   * the visibilityState-event listener and `this.checkSessionInterval`
   */
  init = (getAccessTokenSilently?: Auth0ContextInterface["getAccessTokenSilently"]) => {
    this.#getAccessTokenSilently = getAccessTokenSilently;

    // Set this.lastActive to now before creating the interval and event listener
    this.lastActive = new Date();

    document.addEventListener("visibilitychange", this.checkSessionOnVisibilityStateChange);

    if (!this.checkSessionInterval) {
      this.checkSessionInterval = setInterval(() => this.checkSession(), CHECK_SESSION_INTERVAL_IN_MS);
    }
  };

  /**
   * "Terminate" the service by clearing its properties, event listener and interval.
   * Will be called when `BeaufortAuthProvider` unmounts
   */
  terminate = () => {
    this.#getAccessTokenSilently = undefined;
    this.lastActive = undefined;
    document.removeEventListener("visibilitychange", this.checkSessionOnVisibilityStateChange);
    this.checkSessionInterval && clearInterval(this.checkSessionInterval);
  };

  /**
   * Checks if the user (tab) has been inactive for longer than the session lifetime
   * set in Auth0. Note that the session may still be active as the user may be
   * active in another tab, so the user should NOT be automatically logged out or redirected
   * to /expired. Instead, `this.getAccessTokenOrRedirectToExpired` should be called.
   *
   * (Auth0 recommends setting up a rolling timer that aligns with the maximum inactivity
   * lifetime of the Auth0 session. This should be the same as the value set in the Auth0 Dashboard:
   *  Settings -> Advanced -> Login Session Management -> Inactivity timeout
   * See https://auth0.com/docs/manage-users/sessions/session-lifetime-limits)
   */
  hasExceededInactivityLifetime = () => {
    if (!this.lastActive) {
      return false;
    }

    if (!isNumber(process.env.REACT_APP_AUTH0_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES)) {
      return false;
    }

    const now = new Date();
    const userInactivityInMs = now.getTime() - this.lastActive.getTime();
    return userInactivityInMs >= process.env.REACT_APP_AUTH0_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES * 60 * 1000;
  };

  /**
   * This function is called every 45 seconds to check the auth/user session:
   * - If user is active, set lastActive to now and try to refresh the Auth0
   *    session + API access token
   * - If user is inactive, check if user has been inactive for longer than the
   *    Auth0's inactivity lifetime. If so, try to refresh the access token. This
   *    may still succeed, as the user may have been active in another tab. If not,
   *    the user will be redirected to /expired
   */
  checkSession = () => {
    // Do nothing if already in /expired page
    if (isInExpiredPage()) {
      return;
    }

    if (document.visibilityState === "visible") {
      this.lastActive = new Date();
      this.getAccessTokenOrRedirectToExpired();
    } else {
      if (this.hasExceededInactivityLifetime()) {
        this.getAccessTokenOrRedirectToExpired();
      }
    }
  };

  /**
   * This function checks the user session every time the visibilityState, i.e.
   * the user activity, changes:
   * - If the user just became active, try to refresh access token. The user will
   *    be redirected to /expired if he/she has been inactive for too long
   * - If the user just became inactive, force fetch an access token from
   *    Auth0 (by setting cacheMode to "off" to synchronize this and Auth0's
   *    "session inactivity timers"
   * Sets `lastActive` to now
   */
  checkSessionOnVisibilityStateChange = () => {
    // Do nothing if already in /expired page
    if (isInExpiredPage()) {
      return;
    }

    if (document.visibilityState === "visible") {
      this.getAccessTokenOrRedirectToExpired();
    } else {
      this.getAccessTokenOrRedirectToExpired({ cacheMode: "off" });
    }

    this.lastActive = new Date();
  };

  /**
   * Tries to get an access token silently (without prompting the user)
   * to be used to call the Beaufort API.
   *
   * If the user's Auth0 session has expired, redirect the user
   * to the /expired page.
   */
  async getAccessTokenOrRedirectToExpired(
    options: GetAccessTokenOrRedirectToExpiredOptions = { redirectToExpired: true }
  ) {
    if (!this.#getAccessTokenSilently) {
      return;
    }

    // Do nothing if already in /expired page
    if (isInExpiredPage()) {
      return;
    }

    // Try to get/refresh the access token silently for the Beaufort API
    try {
      const token = await this.#getAccessTokenSilently(options);
      return token;
    } catch (e) {
      // do not send the exception to Sentry as this is a normal Auth0 flow
      // Sentry.captureException(e, {
      //   level: "debug",
      //   tags: {
      //     location: "getAccessTokenOrRedirectToExpired"
      //   }
      // });
      // The user session has expired, meaning the user must log in
      // again to be able to get a new access token
      // --> Redirect the user to the /expired page (unless disabled)
      if (options.redirectToExpired) {
        redirectToExpiredPage();
      }
      return;
    }
  }

  /**
   * A fetch interceptor to be used to intercept any request to the (Beaufort) API
   *
   * This fetch interceptor ensures the access token is refreshed and gets set
   * as a bearer token in the auth header before any request to the API
   *
   * If, for some reason, the request still fails with 401, the user is redirected
   * to the /expired page. Exception: request to the /auth/ endpoint - this is handled
   * in `BeaufortAuthProvider` (see `authenticateBeaufortUser`)
   */
  apiFetchInterceptor(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      const isAuthUserRequest = input.toString().includes("/auth/user");

      // Get/refresh access token
      // Note: if the request is authenticating the user, this is the initial
      // request to the API. If so, we should NOT redirect to /expired if getting
      // the access token fails or the request fails, as it will be handled in
      // `BeaufortAuthProvider.authenticateBeaufortUser` (the user will be logged out)
      this.getAccessTokenOrRedirectToExpired({ redirectToExpired: !isAuthUserRequest })
        .then((token) => {
          // Set auth header using token as bearer token
          const requestInit: RequestInit = init || {};
          if (token) {
            const requestHeaders: HeadersInit = new Headers(requestInit.headers);
            requestHeaders.set("Authorization", `Bearer ${token}`);
            requestInit.headers = requestHeaders;
          }

          // Then fetch
          // Failsafe: if 401, assume that the auth token has expired
          // and redirect to the /expired page (exception: auth request)
          fetch(input, requestInit)
            .then((response) => {
              // Do not intercept auth requests (handled by `BeaufortAuthProvider`)
              if (isAuthUserRequest) {
                return resolve(response);
              }

              if (response.status === 401) {
                // report on errors to the API
                Sentry.captureMessage(`Got 401 error for ${input}`, {
                  level: "debug",
                  tags: {
                    location: "apiFetchInterceptor:fetch[401]"
                  }
                });
                !isAuthUserRequest && redirectToExpiredPage();
                reject();
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              const status = error?.response?.status || error?.status;
              const name = error?.name;

              if (status === 401) {
                !isAuthUserRequest && redirectToExpiredPage();
              } else if (name !== "AbortError" && name === "TypeError") {
                // do not send AbortError or TypeError to sentry
                // report on error from the API if it does not have expired status
                Sentry.captureException(error, {
                  level: "debug",
                  tags: {
                    location: "apiFetchInterceptor:catch[inner]"
                  }
                });
              }

              reject(error);
            });
        })
        .catch((reason) => {
          // report on errors to the API
          Sentry.captureException(reason, {
            level: "debug",
            tags: {
              location: "apiFetchInterceptor:catch[outer]"
            }
          });
          reject();
        });
    });
  }
}

export const authSessionService = new AuthSessionService();
