import React, { useState } from "react";
import { HorizontalStack, Scrollable, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import { Note } from "../../types/Notes";
import { isEmptyString } from "../../utils/stringUtils";
import SearchTextField from "../SearchTextField/SearchTextField";

import NoteCard from "./NoteCard";

export type NotesListProps<T extends Note> = {
  notes: T[];
  placeholder?: string;
  hideSearch?: boolean;
  onDelete?(note: T): void;
  onSave?(note: T): Promise<T>;
};

const NotesList = <T extends Note>(props: NotesListProps<T>) => {
  const { notes, placeholder, hideSearch, onDelete, onSave } = props;

  const f = useFormatMessage();
  const [queryText, setQueryText] = useState("");

  const clearQueryText = () => setQueryText("");

  const notesToDisplay = isEmptyString(queryText)
    ? notes
    : notes.filter((note) => note.text.toLocaleUpperCase().includes(queryText.toLocaleUpperCase()));

  return (
    <VerticalStack gap="4">
      {!hideSearch && (
        <HorizontalStack>
          <SearchTextField
            labelHidden
            placeholder={f("default.search")}
            value={queryText}
            clearButton
            onChange={setQueryText}
            onClearButtonClick={clearQueryText}
          />
        </HorizontalStack>
      )}
      <StyledScrollable horizontal={false} shadow>
        <VerticalStack gap="2">
          {notesToDisplay.map((note, index) => (
            <NoteCard
              note={note}
              queryText={queryText}
              placeholder={placeholder}
              onDelete={onDelete ? () => onDelete(note) : undefined}
              onSave={onSave}
              key={index}
            />
          ))}
        </VerticalStack>
      </StyledScrollable>
    </VerticalStack>
  );
};

const StyledScrollable = styled(Scrollable)`
  //  limit the height of the notes
  max-height: 20rem;
`;

export default NotesList;
