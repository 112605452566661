import { isConfirmedMatchCase } from "../../helpers/match.helpers";
import { getIndustryRiskCount, groupRiskInformationByPersonName } from "../../helpers/risk.helpers";
import { RiskIndicatorsType } from "../../types/RiskIndicators";

const useRiskIndicators = (props: RiskIndicatorsType) => {
  const { industryRisk, matchCases = [], countriesRisk = [], disclosuresRisk = [], personnelRisk = [] } = props;

  const confirmedMatchCases = matchCases.filter(isConfirmedMatchCase);
  const showConfirmedMatches = confirmedMatchCases.length > 0;

  /*
    risk information should be shown if there's at least some data available
    and if the set risk level justifies showing it.
  */
  const industryRiskCount = getIndustryRiskCount(industryRisk);
  const showIndustryRisk = industryRiskCount > 0;

  const groupedCountriesRisk = groupRiskInformationByPersonName(countriesRisk);
  const showGeographicRisk = groupedCountriesRisk.length > 0;

  const showDisclosuresRisk = disclosuresRisk.length > 0;

  const groupedPersonnelRisk = groupRiskInformationByPersonName(personnelRisk);
  const showPersonnelRisk = groupedPersonnelRisk.length > 0;

  const riskIndicatorsCount =
    confirmedMatchCases.length +
    industryRiskCount +
    groupedCountriesRisk.length +
    disclosuresRisk.length +
    groupedPersonnelRisk.length;

  const showRiskIndicators =
    showConfirmedMatches || showIndustryRisk || showGeographicRisk || showDisclosuresRisk || showPersonnelRisk;

  return {
    showConfirmedMatches,
    confirmedMatchCases,
    industryRiskCount,
    showIndustryRisk,
    showRiskIndicators,
    riskIndicatorsCount,
    showGeographicRisk,
    countriesRisk: groupedCountriesRisk,
    showDisclosuresRisk,
    disclosuresRisk,
    showPersonnelRisk,
    personnelRisk: groupedPersonnelRisk
  };
};

export default useRiskIndicators;
