import * as Sentry from "@sentry/react";
import { QueryKey, useQueryClient } from "@tanstack/react-query";

import { Customer, CustomerDetails, CustomerKeyType, getCustomerQueryKey } from "../types/utilities";
import { clone } from "../utils/util";

function useQueryData<T = never>(queryKey: QueryKey) {
  const queryClient = useQueryClient();

  const getQueryData = () => queryClient.getQueryData<T>(queryKey);

  const setQueryData = (queryData: T) => queryClient.setQueryData(queryKey, queryData);

  /**
   * This method expects to receive a function that updates the queryData (which is mutable).
   * It will only be called if existing queryData exists
   */
  const updateQueryData = (updater: (queryData: T) => void) => {
    const queryData = getQueryData();

    if (queryData) {
      try {
        // TODO fix refresh not working after calling setQueryData with the same queryData object
        // Temporary fix following upgrading react-query
        const newData = clone(queryData);
        updater(newData);
        setQueryData(Array.isArray(newData) ? newData : { ...newData });
      } catch (e) {
        Sentry.captureException(e, { level: "warning" });
        console.error(`Failed to update query cache data.`, { queryKey, queryData });
      }
    }
  };

  return { getQueryData, setQueryData, updateQueryData };
}

/**
 * This utility is meant for updating customer details which is the most common use case for updating query cache
 */
export const useCustomerDetailsQueryData = <T extends CustomerDetails>(
  customer: Customer | CustomerDetails | CustomerKeyType
) => useQueryData<T>(getCustomerQueryKey(customer)).updateQueryData;

export default useQueryData;
