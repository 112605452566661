import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import TextIcon from "../../../assets/icons/text-bubble.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import { PersonRiskInformation } from "../../types/RiskIndicators";
import Badges from "../Badges/Badges";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";
import KDKBanner from "../KDKBanner/KDKBanner";
import { isManuallyUpdated } from "../PersonName/PersonName.helpers";
import RiskIndicatorPersonName from "../RiskIndicatorPersonName/RiskIndicatorPersonName";

type PersonRiskIndicatorProps = {
  personRiskInformation: PersonRiskInformation;
};

const PersonRiskIndicator = (props: PersonRiskIndicatorProps) => {
  const { personRiskInformation } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const entity = (personRiskInformation.role as api.Role | undefined) || personRiskInformation.owner;
  const showManuallyUpdatedInfo = Boolean(entity && isManuallyUpdated(entity) && entity.updated_dt);

  return (
    <KDKBanner status="warning" hideIcon>
      <VerticalStack gap="2">
        <RiskIndicatorPersonName riskInformation={personRiskInformation} />
        <HorizontalStack gap="1" blockAlign="center">
          <Icon source={TextIcon} />
          <Text as={"span"}>{f("details.basic-info.marked-as")} </Text>
          <Badges isPep={personRiskInformation.isPep} isSanctioned={personRiskInformation.isSanctioned} />
        </HorizontalStack>
        {showManuallyUpdatedInfo && (
          <CondensedText>
            {f("common.labels.modified-by", {
              name: getUserDisplayName(entity!.updated_by),
              timestamp: new Date(entity!.updated_dt!)
            })}
          </CondensedText>
        )}
      </VerticalStack>
    </KDKBanner>
  );
};

export default PersonRiskIndicator;
