import React from "react";

import api from "../../../api";
import { getPersonCountries } from "../../../helpers/person.helpers";
import CountriesWithWarnings from "../../CountriesWithWarnings/CountriesWithWarnings";

interface RoleCountriesProps {
  role: api.Role;
}

const RoleCountries = (props: RoleCountriesProps) => {
  const { role } = props;

  return <CountriesWithWarnings fieldCountryMap={getPersonCountries(role.person)} />;
};

export default RoleCountries;
