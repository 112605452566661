import React, { forwardRef } from "react";
import { Card as PolarisCard, CardSectionProps as PolarisCardSectionProps } from "@shopify/polaris";
import styled from "styled-components";

import { BACKGROUND_STATUS, STATUS_TO_CUSTOM_PROPERTY_NAME } from "./types";

type CardSectionProps = PolarisCardSectionProps & {
  status?: BACKGROUND_STATUS;
  className?: string;
};

const CardSection = forwardRef<HTMLDivElement, CardSectionProps>((props, ref) => {
  const { status, className, ...cardSectionProps } = props;

  return (
    <StyleCardSectionWrapper status={status} className={className} ref={ref}>
      <PolarisCard.Section {...cardSectionProps} />
    </StyleCardSectionWrapper>
  );
});

const StyleCardSectionWrapper = styled.div<{ status?: BACKGROUND_STATUS }>`
  & .Polaris-Card__Section {
    ${({ status }) => status && `background-color: var(${STATUS_TO_CUSTOM_PROPERTY_NAME[status]});`}
  }
`;

export default CardSection;
