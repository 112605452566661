import { $convertFromMarkdownString } from "@lexical/markdown";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { BASE_MARKDOWN_CONFIG, TRANSFORMERS } from "../../constants/markdown";
import AutoLinkPlugin from "../MarkdownEditor/AutoLinkPlugin";
import { MarkdownStylesWrapper } from "../MarkdownEditor/MarkdownStylesWrapper";
import MarkdownUpdatePlugin from "../MarkdownUpdatePlugin/MarkdownUpdatePlugin";

type MarkdownContentProps = {
  content?: string;
};

const MarkdownContent = (props: MarkdownContentProps) => {
  const { content } = props;

  const initialConfig: InitialConfigType = {
    ...BASE_MARKDOWN_CONFIG,
    namespace: "markdown-reader",
    editable: false,
    editorState: () => $convertFromMarkdownString(content || "", TRANSFORMERS),
    onError: console.error
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <MarkdownStylesWrapper>
        <RichTextPlugin contentEditable={<ContentEditable />} placeholder={null} ErrorBoundary={LexicalErrorBoundary} />
        <MarkdownUpdatePlugin value={content} />
        <AutoLinkPlugin />
      </MarkdownStylesWrapper>
    </LexicalComposer>
  );
};

export default MarkdownContent;
