export const PENDING_TASKS_TAB_NAME_PARAMETER = "pendingTasks";
export const RISK_NAME_PARAMETER = "risk";
export const STATUS_NAME_PARAMETER = "status";
export const MARKED_AS_PEP_NAME_PARAMETER = "isPep";
export const MARKED_AS_SANCTIONED_NAME_PARAMETER = "isSanctioned";
export const SCREENING_TYPE_NAME_PARAMETER = "screeningType";
export const CUSTOMER_TYPE_NAME_PARAMETER = "customerType";
export const CUSTOMER_INDUSTRY_CODES_NAME_PARAMETER = "industryType";
export const COUNTRY_CODE_NAME_PARAMETER = "countryCode";
export const TAGS_NAME_PARAMETER = "labels";
export const PENDING_MEASURES_NAME_PARAMETER = "pendingMeasures";
export const ARCHIVED_NAME_PARAMETER = "isArchived";
export const UNRESOLVED_NAME_PARAMETER = "unresolved";
export const FOLLOW_UP_EXPIRED_NAME_PARAMETER = "followupExpired";
export const OWNERSHIP_UPDATES_NAME_PARAMETER = "ownershipUpdates";
export const RESPONSIBLE_USERS_NAME_PARAMETER = "responsibleUsers";

export enum CUSTOMER_FILTER_TYPE_PARAMS {
  PERSON = "persons",
  COMPANY = "companies"
}

export type CUSTOMER_TYPE_PARAMS = `${CUSTOMER_FILTER_TYPE_PARAMS}`;

export enum CUSTOMER_FILTER_STATUS_PARAMS {
  STARTED = "STARTED",
  REVIEW_REQUIRED = "REVIEW_REQUIRED",
  DRAFT_REVIEW = "DRAFT_REVIEW",
  REVIEW_APPROVAL_REQUIRED = "REVIEW_APPROVAL_REQUIRED",
  NOT_READY = "NOT_READY",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export type CUSTOMER_FILTER_STATUS_PARAMS_TYPE = `${CUSTOMER_FILTER_STATUS_PARAMS}`;
