import React, { useEffect, useState } from "react";
import { Card, Stack, Text, TextField, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import AddressForm from "../AddressForm/AddressForm";
import CountrySelect from "../CountrySelect/CountrySelect";

export interface CompanyFormValues {
  name?: string;
  nationalId?: string;
  country?: api.CountryEnum;
  address: Partial<api.Address>;
}

interface CompanyFormProps {
  company?: Partial<api.CompanyDetail>;
  onChange(company: CompanyFormValues): void;
  excludedCountries?: api.CountryEnum[];
  disabled?: boolean;
  nationalIdError?: string;
}

/**
 * A company form section that should be part of another form
 * Renders company field inputs and calls the onChange-callback
 * whenever a field has been changed with all the field values of
 * the company form
 */
const CompanyForm = (props: CompanyFormProps) => {
  const { company, onChange, disabled = false, excludedCountries, nationalIdError } = props;

  const f = useFormatMessage();

  const [name, setName] = useState(company?.name || "");
  const [nationalId, setNationalId] = useState(company?.national_id || "");
  const [country, setCountry] = useState<api.CountryEnum | undefined>(company?.country);
  const [address, setAddress] = useState<Partial<api.Address>>(company?.business_address || {});

  /**
   * Whenever any of the fields has changed, call the onChange-callback
   */
  useEffect(() => {
    onChange({
      name: name,
      nationalId: nationalId,
      country: country,
      address: address
    });
  }, [name, nationalId, country, address]);

  return (
    <>
      <Card.Section>
        <VerticalStack gap="4">
          <Text as="h1" variant="headingMd">
            {f("company-form.headings.details")}
          </Text>
          <Stack distribution="fillEvenly">
            <TextField
              autoFocus
              value={name}
              label={f("company-form.labels.name")}
              placeholder={f("company-form.labels.name")}
              onChange={(v) => setName(v)}
              autoComplete="off"
              requiredIndicator
            />
            <TextField
              value={nationalId}
              label={f("company-form.labels.national-id")}
              placeholder={f("company-form.labels.national-id")}
              onChange={(v) => setNationalId(v)}
              error={nationalIdError}
              autoComplete="off"
              requiredIndicator
            />
            <CountrySelect
              value={country}
              disabled={disabled}
              label={f("company-form.labels.country")}
              excludedCountries={excludedCountries}
              onSelect={(country) => setCountry(country)}
              requiredIndicator
            />
          </Stack>
        </VerticalStack>
      </Card.Section>
      <Card.Section>
        <VerticalStack gap="4">
          <Text variant="headingMd" as="h2">
            {f("common.labels.business-address")}
          </Text>
          <AddressForm
            value={address}
            onChange={(v) => setAddress(v)}
            disabled={disabled}
            requiredFields={["country"]}
          />
        </VerticalStack>
      </Card.Section>
    </>
  );
};

export default CompanyForm;
