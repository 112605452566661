import React from "react";
import { HorizontalStack } from "@shopify/polaris";
import styled from "styled-components";

import { createNewQuestion } from "../../helpers/questions.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import AddButton from "../AddButton/AddButton";

import QuestionsTable, { QuestionsTableProps } from "./QuestionsTable/QuestionsTable";

const QuestionsEditor = (props: QuestionsTableProps) => {
  const { questions, readonly, onChange } = props;

  const f = useFormatMessage();

  const addNewQuestion = () => onChange([...questions, createNewQuestion()]);

  // div wrapper is needed to sticky toolbar to work
  return (
    <div>
      <StyledToolbar>
        <HorizontalStack gap="4" align="end">
          {questions.length > 0 && (
            <AddButton onClick={addNewQuestion} disabled={readonly}>
              {f("questions.editor.buttons.add-question.label")}
            </AddButton>
          )}
        </HorizontalStack>
      </StyledToolbar>
      <QuestionsTable {...props} />
    </div>
  );
};

const StyledToolbar = styled.div`
  background-color: var(--p-color-bg-app);
  position: sticky;
  top: var(--kdk-toolbar-height);
  z-index: var(--p-z-1);
  padding-bottom: var(--p-space-4);
  margin-left: -5px;
  margin-right: -2px;
`;

export default QuestionsEditor;
