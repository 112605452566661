import React from "react";
import { Link, Text, VerticalStack } from "@shopify/polaris";

import StarIcon from "../../../assets/icons/star-filled.svg";
import { PLAN_CONVERSION_PAGE } from "../../constants/external-urls";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";

export interface PlanConversionBannerProps {
  content: string;
}

const PlanConversionBanner = (props: PlanConversionBannerProps) => {
  const { content } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack align="center">
      <Icon source={StarIcon} width="30px" height="30px" />
      <Text as="span">{content}</Text>
      <Link url={PLAN_CONVERSION_PAGE} external>
        {f("plan-conversion.banner.labels.link")}
      </Link>
    </VerticalStack>
  );
};

export default PlanConversionBanner;
