import React from "react";
import { Banner, Button, HorizontalStack } from "@shopify/polaris";

import ChevronIcon from "../../../../assets/icons/chevron-right.svg";
import { PAGE_ANCHORS } from "../../../constants/page-anchors";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { scrollInPage } from "../../../utils/browserUtils";
import Icon from "../../extensions/Icon";

const OwnershipUpdatesBanner = () => {
  const f = useFormatMessage();

  const navigateToCompanyOwnersSection = () =>
    scrollInPage(PAGE_ANCHORS.COMPANY_ROLES_AND_OWNERS_SECTION, { behavior: "smooth" });

  return (
    <Banner status="info">
      <p>{f("details.banners.ownership-updates.content")}</p>
      <HorizontalStack gap="1" blockAlign="center">
        <Button plain onClick={navigateToCompanyOwnersSection}>
          {f("details.banners.ownership-updates.link-label")}
        </Button>
        <Icon source={ChevronIcon} width="8px" height="14px" />
      </HorizontalStack>
    </Banner>
  );
};

export default OwnershipUpdatesBanner;
