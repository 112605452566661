import React from "react";
import { Text } from "@shopify/polaris";

import api from "../../../api";
import useDisplayName from "../../../hooks/useDisplayName";
import useFormatOwnershipPercentage from "../../../hooks/useFormatOwnershipPercentage";
import useRichFormatMessage from "../../../hooks/useRichFormatMessage";

interface OwnershipUpdateRejectDescriptionProps {
  update: api.OwnershipUpdate;
}

const OwnershipUpdateRejectDescription = (props: OwnershipUpdateRejectDescriptionProps) => {
  const { update } = props;

  const richFormat = useRichFormatMessage();
  const formatDisplayName = useDisplayName();
  const formatPercent = useFormatOwnershipPercentage();

  const getActionDescription = () => {
    const value = update.field_changes.find((field) => field.field_name === "percent_share")?.old;
    const percentShare = formatPercent(value);
    const displayName = formatDisplayName(update);

    switch (update.update_type) {
      case "ADDED":
        return richFormat("ownership-update.reject.confirmation.modal.content.add", { name: displayName });
      case "DELETED":
        return richFormat("ownership-update.reject.confirmation.modal.content.delete", { name: displayName });
      case "UPDATED":
        return richFormat("ownership-update.reject.confirmation.modal.content.update", {
          name: displayName,
          value: percentShare
        });
    }
  };

  return <Text as="span">{getActionDescription()}</Text>;
};

export default OwnershipUpdateRejectDescription;
