import { useState } from "react";
import { Button, FormLayout, HorizontalStack, Text, TextField, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import validateAccountNumber from "norwegian-utils/validateAccountNumber";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { CustomerDetails } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import KDKTextField from "../KDKTextField/KDKTextField";

import KarLookupFailureBanner from "./KarLookupFailureBanner";
import KarLookupSuccessBanner from "./KarLookupSuccessBanner";

type KarLookupFormProps = {
  customer: CustomerDetails;
};

const KarLookupForm = (props: KarLookupFormProps) => {
  const { customer } = props;

  const f = useFormatMessage();

  const [accountNumber, setAccountNumber] = useState("");
  const [nationalId, setNationalId] = useState(customer.national_id || "");
  const [lookupResult, setLookupResult] = useState<api.KarLookupResult>();
  const clearResult = () => setLookupResult(undefined);

  const checkAccountMutation = useMutation(
    () => api.createKarLookup(customer.id, { account_number: accountNumber, national_id: nationalId }),
    { onMutate: clearResult, onSuccess: setLookupResult }
  );

  const isNationalIdFilled = !isEmptyString(customer.national_id);
  const isValidAccountNumber = validateAccountNumber(accountNumber);

  const isDisabled = !isValidAccountNumber || isEmptyString(nationalId);

  return (
    <HorizontalStack align={"space-between"} blockAlign={"start"}>
      <VerticalStack gap="4">
        <Text as={"h4"} variant={"bodyMd"} fontWeight={"semibold"}>
          {f("kar.form.title")}
        </Text>
        <FormLayout>
          <FormLayout.Group>
            <KDKTextField
              label={f("kar.form.labels.account-number")}
              value={accountNumber}
              onChange={setAccountNumber}
              autoComplete="on"
              debounceError
              minLength={11}
              disabled={checkAccountMutation.isLoading}
              error={!isValidAccountNumber ? f("common.errors.invalid.account-number") : undefined}
            />
            <TextField
              label={f("kar.form.labels.customer-number")}
              value={nationalId}
              onChange={setNationalId}
              autoComplete="on"
              readOnly={isNationalIdFilled}
              disabled={checkAccountMutation.isLoading}
            />
          </FormLayout.Group>
        </FormLayout>
        {lookupResult?.result && <KarLookupSuccessBanner customer={customer} lookupResult={lookupResult} />}
        {lookupResult?.result === false && <KarLookupFailureBanner customer={customer} lookupResult={lookupResult} />}
        {checkAccountMutation.isError && <ErrorPanel message={checkAccountMutation.error} />}
      </VerticalStack>
      <Button
        primary
        onClick={() => checkAccountMutation.mutate()}
        disabled={isDisabled}
        loading={checkAccountMutation.isLoading}
      >
        {f("kar.form.labels.check-account-number")}
      </Button>
    </HorizontalStack>
  );
};

export default KarLookupForm;
