import { $convertFromMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { TRANSFORMERS } from "../../constants/markdown";
import useNonInitialEffect from "../../hooks/useNonInitialEffect";

type MarkdownUpdatePluginProps = {
  value?: string;
};

const MarkdownUpdatePlugin = (props: MarkdownUpdatePluginProps) => {
  const { value } = props;

  const [editor] = useLexicalComposerContext();

  useNonInitialEffect(() => {
    editor.update(() => {
      $convertFromMarkdownString(value || "", TRANSFORMERS);
    });
  }, [value, editor]);

  return null;
};

export default MarkdownUpdatePlugin;
