import { EntityWithId } from "../types/utilities";

// K - an Element that contains a list of items of type T
// example: K = Customer, T = User
// getItemsForElement is responsible to return the array of items (T[]) for a given element of type K
// example: getItemsForElement = (customer: Customer) => customer.responsibleUsers
export function getIndeterminateSelection<K, T extends EntityWithId>(
  elements: K[],
  getItemsForElement: (element: K) => T[] | undefined
) {
  // count the number of elements that contains the current item
  const elementsCountByItem = elements.reduce<Record<EntityWithId["id"], { count: number; item: T }>>(
    (elementsCounts, element) => {
      getItemsForElement(element)?.forEach((item) => {
        elementsCounts[item.id] = elementsCounts[item.id]
          ? // if an entry with a count already exists, increase the count by 1
            { ...elementsCounts[item.id], count: elementsCounts[item.id].count + 1 }
          : // if an entry with a count does not exist, create a new one with a count of 1
            { count: 1, item };
      });
      return elementsCounts;
    },
    {}
  );

  const selectedItems: T[] = [];
  const partiallySelectedItems: T[] = [];

  Object.values(elementsCountByItem).forEach((itemElementsCount) => {
    // if the number of elements for the item is equal to the total number of elements
    // then the item is selected by all elements
    if (itemElementsCount.count === elements.length) {
      selectedItems.push(itemElementsCount.item);
    } else {
      // otherwise, the item is selected by some (but not all) elements
      partiallySelectedItems.push(itemElementsCount.item);
    }
  });

  return { selectedItems, partiallySelectedItems };
}
