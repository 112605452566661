import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";

import useFeatures from "./useFeatures";

const useGetKYCPreviewerURL = () => {
  const features = useFeatures();

  const { data } = useQuery([QUERIES_KEYS.KYC_PREVIEW], () => api.getInternalKycPreviewLink(), {
    enabled: features.KYC_PREVIEWER,
    staleTime: STALE_TIME
  });

  return data;
};

export default useGetKYCPreviewerURL;
