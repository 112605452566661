import React, { useState } from "react";
import { Button, Checkbox, HorizontalStack, Modal, Stack, Text, TextField, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import {
  convertPersonToCreateRequest,
  convertPersonToUpdateRequest,
  isUBO,
  isUserCreatedPerson
} from "../../helpers/person.helpers";
import { isDateOfBirthRequired, isValidRole } from "../../helpers/roles.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { CustomerDetails, ValueOf } from "../../types/utilities";
import CondensedText from "../CondensedText/CondensedText";
import ConfirmAction from "../ConfirmAction/ConfirmAction";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import PersonForm from "../PersonForm/PersonForm";
import RoleTypeSelect from "../RoleTypeSelect/RoleTypeSelect";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import UBOManualOverride from "../UBOManualOverride/UBOManualOverride";

export interface RoleModalProps {
  customer: CustomerDetails;
  dataRole: api.Role;
  isDeleteDisabled: boolean;
  onDelete(ownerId: string): void;
  onUpdate(role: api.Role): void;
  onCreate(role: api.Role): void;
  onClose(): void;
}

const RoleModal = (props: RoleModalProps) => {
  const { dataRole, customer, isDeleteDisabled, onDelete, onUpdate, onCreate, onClose } = props;

  const [role, setRole] = useState({ ...dataRole });

  const isUpdatingRole = Boolean(role.id);

  const [showDeleteConfirm, toggleDeleteConfirm, closeDeleteConfirm] = useOpenClose(false);
  const [isFormValid, setIsFormValid] = useState(role.id !== undefined);

  const f = useFormatMessage();

  const saveRoleMutation = useMutation(
    () => {
      const { type = "UNKNOWN_ROLE", comment } = role;

      return isUpdatingRole && role.id
        ? api.updateCompanyRole(customer.id, role.id, {
            type,
            comment,
            custom_is_ubo: role.custom_is_ubo,
            person: convertPersonToUpdateRequest(role.person)
          })
        : api.createCompanyRole(customer.id, {
            type,
            comment,
            custom_is_ubo: role.custom_is_ubo,
            person: convertPersonToCreateRequest(role.person)
          });
    },
    {
      onSuccess: (updatedRole) => {
        isUpdatingRole ? onUpdate(updatedRole) : onCreate({ ...updatedRole, is_new: true });
      }
    }
  );

  const handleChange = (key: keyof api.Role, value?: ValueOf<api.Role>) =>
    setRole((prevRole) => ({ ...prevRole, [key]: value }));

  const saveNewRole = () => saveRoleMutation.mutate();

  const isSaveButtonDisabled = saveRoleMutation.isLoading || !isFormValid || !isValidRole(role);

  const isBirthDateRequired = isUserCreatedPerson(role) && isDateOfBirthRequired(role.type);

  return (
    <Modal
      open
      large
      onClose={onClose}
      title={isUpdatingRole ? f("modals.role.title.update") : f("modals.role.title.add")}
      primaryAction={{
        content: isUpdatingRole ? f("default.save") : f("default.add"),
        onAction: saveNewRole,
        loading: saveRoleMutation.isLoading,
        disabled: isSaveButtonDisabled
      }}
      secondaryActions={[
        {
          content: f("default.cancel"),
          onAction: onClose
        }
      ]}
      footer={
        <HorizontalStack gap="4" blockAlign="center">
          {isUpdatingRole && (
            <TooltipWrapper
              content={isDeleteDisabled ? f("modals.role.tooltips.delete-disabled") : ""}
              preferredPosition="above"
            >
              <Button
                destructive
                disabled={isDeleteDisabled || saveRoleMutation.isLoading}
                onClick={toggleDeleteConfirm}
              >
                {f("default.delete")}
              </Button>
            </TooltipWrapper>
          )}
          {saveRoleMutation.isError && <ErrorPanel message={saveRoleMutation.error} />}
        </HorizontalStack>
      }
    >
      <Modal.Section>
        <VerticalStack gap="4">
          <Text variant="headingMd" as="h2">
            {f("modals.headings.personal-information")}
          </Text>
          <PersonForm<api.RolePerson>
            value={role.person}
            disabled={saveRoleMutation.isLoading}
            isCreating={isUserCreatedPerson(role)}
            birthDateIsRequired={isBirthDateRequired}
            allowBirthYear={!isUserCreatedPerson(role)}
            onChange={(person) => setRole((prevRole) => ({ ...prevRole, person }))}
            onValidityChange={setIsFormValid}
          />
        </VerticalStack>
      </Modal.Section>

      <Modal.Section>
        <VerticalStack gap="4">
          <VerticalStack>
            <Text variant="headingMd" as="h2">
              {f("modals.role.headings.role-in-company")}
            </Text>
            {role.is_ubo && <CondensedText italic>{f("common.labels.calculated-ubo")}</CondensedText>}
          </VerticalStack>
          <Stack distribution={"fillEvenly"}>
            <RoleTypeSelect
              roleType={role.type}
              disabled={saveRoleMutation.isLoading}
              onChange={(newValue) => handleChange("type", newValue)}
            />
            {/* use 2 empty stack items to create a 3 columns layout */}
            <Stack.Item />
            <Stack.Item />
          </Stack>
          <VerticalStack gap="6">
            <UBOManualOverride
              checked={isUBO(role)}
              onChange={(newValue) => handleChange("custom_is_ubo", newValue)}
              disabled={saveRoleMutation.isLoading}
            />
            <VerticalStack inlineAlign="start">
              <Checkbox
                label={f("common.labels.mark-person-as-pep")}
                checked={role.person.is_pep}
                onChange={(newValue) =>
                  setRole((prevRole) => ({ ...prevRole, person: { ...prevRole.person, is_pep: newValue } }))
                }
                disabled={saveRoleMutation.isLoading}
              />
              <Checkbox
                label={f("common.labels.mark-person-as-sanctioned")}
                checked={role.person.is_sanctioned}
                onChange={(newValue) =>
                  setRole((prevRole) => ({ ...prevRole, person: { ...prevRole.person, is_sanctioned: newValue } }))
                }
                disabled={saveRoleMutation.isLoading}
              />
            </VerticalStack>
          </VerticalStack>
        </VerticalStack>
      </Modal.Section>
      <Modal.Section>
        <TextField
          placeholder={f("common.labels.comment")}
          label={f("common.labels.comment")}
          multiline={3}
          value={role.comment || ""}
          onChange={(newValue) => handleChange("comment", newValue)}
          disabled={saveRoleMutation.isLoading}
          autoComplete="off"
        />
      </Modal.Section>

      {showDeleteConfirm && (
        <ConfirmAction
          apiCall={() => api.deleteCompanyRole(customer.id, role.id!)}
          title={f("modals.role.confirm.delete.title")}
          description={f("modals.role.confirm.delete.description", { role: getFullName(role.person) })}
          onNo={closeDeleteConfirm}
          actionTitle={f("default.delete")}
          onSuccess={() => onDelete(role.id!)}
        />
      )}
    </Modal>
  );
};

export default RoleModal;
