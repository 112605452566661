import React from "react";
import { List } from "@shopify/polaris";

import api from "../../api";
import { OptionalChecklistAnswersRequest } from "../../types/ReviewChecklist";

type ReviewChecklistAnswers = {
  checklistAnswers?: (api.ChecklistAnswers | api.ChecklistAnswersRequest | OptionalChecklistAnswersRequest)[];
};
const ReviewChecklistAnswers = (props: ReviewChecklistAnswers) => {
  const { checklistAnswers } = props;

  if (!checklistAnswers) {
    return null;
  }

  const answers = checklistAnswers
    ?.flatMap((checklistAnswer) => checklistAnswer.answers.map((answer) => answer.additional_info))
    .filter(Boolean);

  return (
    <List type="bullet" spacing={"extraTight"}>
      {answers.map((answer, index) => (
        <List.Item key={index}>{answer}</List.Item>
      ))}
    </List>
  );
};

export default ReviewChecklistAnswers;
