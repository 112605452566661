import React, { useState } from "react";
import { ComplexAction, Modal, Stack, TextField } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useChangedState from "../../hooks/useChangedState";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useErrorParser from "../../hooks/useErrorParser";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData from "../../hooks/useQueryData";
import { ValueOf } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import PersonForm from "../PersonForm/PersonForm";

interface EditPersonCustomerDetailsModalProps {
  person: api.PersonCustomerDetailResponse;
  onClose(): void;
}

const EditPersonCustomerDetailsModal = (props: EditPersonCustomerDetailsModalProps) => {
  const { person: originalPerson, onClose } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const { getQueryData, setQueryData } = useQueryData<api.PersonCustomerDetailResponse>([
    QUERIES_KEYS.PERSON_DETAILS,
    originalPerson.id
  ]);
  const defaultCountry = useDefaultCountry();
  const parseError = useErrorParser("person");

  const [person, setPerson, changed] = useChangedState({ ...originalPerson });
  const [isFormValid, setIsFormValid] = useState(true);

  const updatePersonMutation = useMutation(
    () =>
      api.updatePerson(person.id, {
        first_name: person.first_name,
        last_name: person.last_name,
        birth_date: person.birth_date || "",
        country_of_origin: person.country_of_origin,
        country_of_citizenship: person.country_of_citizenship || defaultCountry,
        country_of_residence: person.country_of_residence || defaultCountry,
        address: person.address,
        phone: person.phone,
        email: person.email,
        national_id_country: person.national_id_country,
        national_id: person.national_id,
        external_id: person.external_id
      }),
    {
      onSuccess: (updatedPerson) => {
        const personData = getQueryData();
        if (personData) {
          const updatedPersonData = { ...personData, ...updatedPerson };
          setQueryData(updatedPersonData);
        }
        onClose();
      }
    }
  );

  const handleChange = (
    key: keyof api.PersonCustomerDetailResponse,
    value?: ValueOf<api.PersonCustomerDetailResponse>
  ) => setPerson((prevPerson) => ({ ...prevPerson, [key]: value }));

  const saveAction: ComplexAction = {
    content: f("default.save"),
    onAction: () => updatePersonMutation.mutate(),
    loading: updatePersonMutation.isLoading,
    disabled: !changed || updatePersonMutation.isLoading || !isFormValid || isEmptyString(person.address?.country)
  };

  const cancelAction: ComplexAction = {
    content: f("default.cancel"),
    onAction: onClose,
    disabled: updatePersonMutation.isLoading
  };

  const { generalError, fieldsErrorsMap } = parseError(updatePersonMutation.error);

  return (
    <Modal
      open
      large
      onClose={onClose}
      title={f("modals.person.edit.title")}
      primaryAction={saveAction}
      secondaryActions={[cancelAction]}
    >
      <Modal.Section>
        <PersonForm<api.PersonCustomerDetailResponse>
          value={person}
          isCreating={false}
          birthDateIsRequired
          disabled={updatePersonMutation.isLoading}
          onChange={setPerson}
          onValidityChange={setIsFormValid}
          fieldsErrorMap={fieldsErrorsMap}
        >
          {features?.EXTERNAL_ID && (
            <Stack distribution={"fillEvenly"}>
              <TextField
                value={person.external_id || ""}
                onChange={(newValue) => handleChange("external_id", newValue)}
                placeholder={f("company-form.labels.external-id")}
                label={f("company-form.labels.external-id")}
                disabled={updatePersonMutation.isLoading}
                maxLength={50}
                error={fieldsErrorsMap.get("external_id")}
                autoComplete="off"
              />
              {/* use 2 empty stack items to make each element a third of the total width */}
              <Stack.Item />
              <Stack.Item />
            </Stack>
          )}
        </PersonForm>
      </Modal.Section>
      {generalError && (
        <Modal.Section>
          <ErrorPanel message={generalError} />
        </Modal.Section>
      )}
    </Modal>
  );
};

export default EditPersonCustomerDetailsModal;
