import React, { ElementRef, useEffect, useRef } from "react";
import { Banner, BannerStatus } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../../api";
import MarkdownContent from "../../../MarkdownContent/MarkdownContent";

import useStatusMessage from "./useStatusMessage";

const SERVER_SEVERITY_TO_BANNER_SEVERITY_MAP: Record<api.StatusMessageSeverity, BannerStatus> = {
  INFO: "info",
  WARNING: "warning",
  CRITICAL: "critical"
};

const StatusMessage = () => {
  const wrapperRef = useRef<ElementRef<"div">>(null);
  const { showBanner, statusMessage } = useStatusMessage();

  useEffect(() => {
    const messageOffset =
      showBanner && wrapperRef.current ? `${wrapperRef.current.getBoundingClientRect().height}px` : "0px";

    // if showBanner is true, set Polaris top padding to match the new toolbar height --kdk-message-offset
    document.documentElement.style.setProperty("--kdk-message-offset", messageOffset);
  }, [showBanner, wrapperRef.current]);

  if (!showBanner || !statusMessage) {
    return null;
  }

  const { message, severity } = statusMessage;

  return (
    <StyledBannerWrapper ref={wrapperRef}>
      <Banner status={SERVER_SEVERITY_TO_BANNER_SEVERITY_MAP[severity]}>
        <MarkdownContent content={message} />
      </Banner>
    </StyledBannerWrapper>
  );
};

const StyledBannerWrapper = styled.div`
  & .Polaris-Banner {
    padding: 0.875rem;
    justify-content: center;
    border-radius: 0;
    border-right: none;
    border-left: none;
  }

  & .Polaris-Banner__ContentWrapper {
    flex: 0 1 auto;
  }

  & .Polaris-Text--headingMd {
    font-weight: var(--p-font-weight-regular);
  }
`;

export default StatusMessage;
