import React, { useState } from "react";
import { Card, Collapsible, HorizontalStack, RadioButton, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS, getChecklistSelectionType } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useYesNo from "../../hooks/useYesNo";
import { ReviewChecklistSelectionType } from "../../types/ReviewChecklist";
import { ValueOf } from "../../types/utilities";
import CustomerTypeForm from "../CustomerTypeForm/CustomerTypeForm";

import ReviewChecklistRiskConditionsEditor from "./ReviewChecklistRiskConditionsEditor";

type ReviewChecklistConditionsEditorProps = {
  checklist: api.ReviewChecklist;
  readonly?: boolean;
  isNew?: boolean;
  onChange(checklist: api.ReviewChecklist): void;
};

const ReviewChecklistConditionsEditor = (props: ReviewChecklistConditionsEditorProps) => {
  const { checklist, readonly, isNew, onChange } = props;

  const f = useFormatMessage();
  const yesNo = useYesNo();

  const [selectionType, setSelectionType] = useState<ReviewChecklistSelectionType>(
    getChecklistSelectionType(checklist.display_settings)
  );

  const handleChange = (field: keyof api.ReviewChecklist, value: ValueOf<api.ReviewChecklist>) =>
    onChange({ ...checklist, [field]: value });

  const handleSelectionTypeChange = (selectionType: ReviewChecklistSelectionType) => {
    setSelectionType(selectionType);
    if (selectionType === "always") {
      onChange({
        ...checklist,
        display_settings: { ...EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS, is_always_select: true }
      });
    } else {
      onChange({ ...checklist, display_settings: EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS });
    }
  };

  return (
    <VerticalStack>
      <Card>
        <Card.Section title={f("review.checklist.editor.labels.customer-type")}>
          <CustomerTypeForm
            customerType={checklist.customer_type}
            readonly={readonly}
            onChange={(customerType) => handleChange("customer_type", customerType)}
          />
        </Card.Section>
      </Card>
      <Card>
        <Card.Section title={f("review.checklist.editor.labels.selection-type")}>
          <HorizontalStack gap="4">
            <RadioButton
              label={f("default.no")}
              checked={selectionType === "manual"}
              disabled={readonly}
              name={"selection-type"}
              onChange={() => handleSelectionTypeChange("manual")}
            />
            <RadioButton
              label={f("review.checklist.editor.labels.yes-always")}
              checked={selectionType === "always"}
              disabled={readonly}
              name={"selection-type"}
              onChange={() => handleSelectionTypeChange("always")}
            />
            <RadioButton
              label={f("review.checklist.editor.labels.yes-choose")}
              checked={selectionType === "automatic"}
              disabled={readonly}
              name={"selection-type"}
              onChange={() => handleSelectionTypeChange("automatic")}
            />
          </HorizontalStack>
          <Collapsible id={"review-checklist-detailed-conditions"} open={selectionType === "automatic"}>
            <ReviewChecklistRiskConditionsEditor
              checklist={checklist}
              isNew={isNew}
              readonly={readonly || selectionType !== "automatic"}
              onChange={onChange}
              key={selectionType}
            />
          </Collapsible>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section title={f("review.checklist.editor.labels.required")}>
          <HorizontalStack gap="4">
            <RadioButton
              label={yesNo(true)}
              checked={checklist.is_required}
              disabled={readonly}
              name={"checklist-required"}
              onChange={(required) => handleChange("is_required", required)}
            />
            <RadioButton
              label={yesNo(false)}
              checked={!checklist.is_required}
              disabled={readonly}
              name={"checklist-required"}
              onChange={(required) => handleChange("is_required", !required)}
            />
          </HorizontalStack>
        </Card.Section>
      </Card>
    </VerticalStack>
  );
};

export default ReviewChecklistConditionsEditor;
