import React, { forwardRef, PropsWithChildren } from "react";
import { Button as PolarisButton, ButtonProps as PolarisButtonProps } from "@shopify/polaris";
import styled from "styled-components";

export interface ButtonProps extends Omit<PolarisButtonProps, "children"> {
  truncate?: boolean;
  round?: boolean;
  warning?: boolean;
  className?: string;
  hoverable?: boolean;
  id?: string;
}

const Button = forwardRef<HTMLSpanElement, PropsWithChildren<ButtonProps>>((props, ref) => {
  const { truncate, round, warning, className = "", icon, hoverable, id, children, ...buttonProps } = props;

  const iconNode =
    typeof icon !== "string" ? (
      icon
    ) : (
      <StyledIconWrapper hoverable={hoverable}>
        <img src={icon} />
      </StyledIconWrapper>
    );

  if (truncate || round || className || id) {
    return (
      <StyledTruncateWrapper
        className={className}
        truncate={truncate}
        round={round}
        warning={warning}
        id={id}
        ref={ref}
      >
        <PolarisButton fullWidth icon={iconNode} {...buttonProps}>
          {/* @ts-ignore */}
          {children}
        </PolarisButton>
      </StyledTruncateWrapper>
    );
  }

  return (
    <PolarisButton icon={iconNode} {...buttonProps}>
      {/* @ts-ignore */}
      {children}
    </PolarisButton>
  );
});

const StyledIconWrapper = styled.div<{ hoverable?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ hoverable }) =>
    hoverable &&
    `
    padding: var(--p-space-1);
    
    &:hover {
      border-radius: var(--p-border-radius-1);
      background-color: var(--p-surface-hovered);
    }
  `}
`;

const StyledTruncateWrapper = styled.span<{ truncate?: boolean; round?: boolean; warning?: boolean }>`
  & .Polaris-Button {
    ${({ round }) => round && `border-radius: 50%;`}

    &.Polaris-Button--pressed {
      ${({ warning }) =>
        warning &&
        `background-color: var(--p-surface-warning-subdued-pressed);
       `}
    }
    &:hover {
      ${({ warning }) =>
        warning &&
        `background-color: var(--p-surface-warning-subdued-pressed);
         box-shadow: var(--p-banner-border-warning);
         border: none;
       `}
    }
  }

  & .Polaris-Button__Text {
    ${({ truncate }) =>
      truncate &&
      `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
  }
`;

export default Button;
