import api from "../api";
import { removeProperty } from "../utils/util";

// remove id from question choice when converting to create request
const convertQuestionChoiceToCreateQuestionChoiceRequest = (
  choice: api.QuestionChoice
): api.CreateQuestionChoiceRequest => removeProperty(choice, "id");

// remove id from question when converting to create request
const convertQuestionToCreateQuestionRequest = (question: api.Question): api.CreateQuestionRequest => ({
  ...removeProperty(question, "id"),
  choices: question.choices?.map(convertQuestionChoiceToCreateQuestionChoiceRequest)
});

export const convertQuestionnaireToCreateQuestionnaireRequest = (
  questionnaire: api.Questionnaire
): api.CreateQuestionnaireRequest => ({
  name: questionnaire.name,
  description: questionnaire.description,
  title: questionnaire.title,
  customer_type: questionnaire.customer_type,
  questions: questionnaire.questions.map(convertQuestionToCreateQuestionRequest)
});

export const convertQuestionnaireToUpdateQuestionnaireRequest = (
  questionnaire: api.Questionnaire
): api.UpdateQuestionnaireRequest => ({
  name: questionnaire.name,
  description: questionnaire.description,
  title: questionnaire.title,
  customer_type: questionnaire.customer_type,
  questions: questionnaire.questions.map(convertQuestionToCreateQuestionRequest),
  is_archived: questionnaire.is_archived
});

export const isQuestionnaireActive = (questionnaire: api.Questionnaire | api.QuestionnaireSummary) =>
  !questionnaire.is_archived;
