import { $convertFromMarkdownString, $convertToMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { TRANSFORMERS } from "../../constants/markdown";
import useNonInitialEffect from "../../hooks/useNonInitialEffect";

type UpdateStatePluginProps = {
  value: string;
};
const UpdateStatePlugin = (props: UpdateStatePluginProps) => {
  const { value } = props;
  const [editor] = useLexicalComposerContext();

  useNonInitialEffect(() => {
    if (!editor) {
      return;
    }

    editor.update(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      if (markdown !== value) {
        $convertFromMarkdownString(value, TRANSFORMERS);
      }
    });
  }, [value, editor]);
  return null;
};

export default UpdateStatePlugin;
