import React, { useMemo, useState } from "react";
import {
  Button,
  Card,
  ComplexAction,
  DataTable,
  EmptyState,
  FormLayout,
  HorizontalStack,
  Modal,
  OptionList,
  Text,
  TextField,
  VerticalStack
} from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import AddButton from "../../components/AddButton/AddButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import CondensedText from "../../components/CondensedText/CondensedText";
import DataTableCellChild from "../../components/DataTableCellChild/DataTableCellChild";
import EmailView from "../../components/EmailView/EmailView";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import RowHighlighter from "../../components/extensions/RowHighlighter";
import FilterContainer from "../../components/FilterContainer/FilterContainer";
import PopoverButton from "../../components/PopoverButton/PopoverButton";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import TextHighlighter from "../../components/TextHighlighter/TextHighlighter";
import { SETTINGS_SEARCH_DEBOUNCE_DELAY } from "../../constants/durations";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { USER_NAME_COLUMN_WIDTH } from "../../constants/tables";
import { getFullName } from "../../helpers/display.helpers";
import useDebouncedState from "../../hooks/useDebouncedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUsers from "../../hooks/useGetUsers";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import useTranslateGroup from "../../hooks/useTranslateGroup";
import useYesNo from "../../hooks/useYesNo";
import { ValueOf } from "../../types/utilities";
import { isEmail, isEmptyString } from "../../utils/stringUtils";
import { noop } from "../../utils/util";

import SettingsUsersSkeleton from "./SettingsUsersSkeleton";

type ServerError = api.HttpValidationError & {
  email?: string[];
};

const EMPTY_USER: api.User = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  is_active: true
};

const SettingsUsersPage = ({ data }: AsyncPageChild<api.UserListResponse>) => {
  const [isModalOpen, toggleModalOpen, closeModal] = useOpenClose();
  const [isShowingInactiveUsers, toggleShowInactiveUsers] = useOpenClose();
  const [queryText, setQueryText, debouncedQueryValue] = useDebouncedState("", SETTINGS_SEARCH_DEBOUNCE_DELAY);
  const [currentUser, setCurrentUser] = useState<api.User>(EMPTY_USER);
  const [isReactivating, setReactivating] = useState(false);

  const { updateQueryData } = useQueryData<api.UserListResponse>([QUERIES_KEYS.USERS]);
  const yesNo = useYesNo();
  const f = useFormatMessage();
  const { isPermittedTo } = usePermissions();
  const { getUserGroupsDisplayName, activeUsers } = useGetUsers();
  const getGroupName = useTranslateGroup();

  const readonly = !isPermittedTo(PERMISSIONS_TYPES.ADMIN_USER);

  const saveUserMutation = useMutation<api.User, { data: ServerError }, api.UpdateUserRequest>(
    ({ first_name, last_name, email, groups, is_active }) => {
      return currentUser.id
        ? api.updateUser(currentUser.id, { first_name, last_name, email, groups, is_active })
        : api.createUser({ first_name, last_name, email, groups });
    },
    {
      onSuccess: (updatedUser) => {
        updateQueryData((queryData) => {
          if (currentUser.id) {
            // update existing user
            queryData.users = queryData.users.map((currentUser) =>
              currentUser.id === updatedUser.id ? updatedUser : currentUser
            );
          } else {
            // add new user
            queryData.users = [updatedUser, ...queryData.users];
          }
        });
        closeAndClearModal();
      }
    }
  );

  const groups = data.groups || [];

  const users = useMemo(() => {
    return (isShowingInactiveUsers ? data.users : activeUsers).filter(
      (user) =>
        isEmptyString(debouncedQueryValue) ||
        getFullName(user).toLocaleLowerCase().includes(debouncedQueryValue.toLocaleLowerCase())
    );
  }, [debouncedQueryValue, isShowingInactiveUsers, data.users]);

  const inactiveUsersCount = useMemo(
    () =>
      data.users.filter(
        (user) =>
          !user.is_active && getFullName(user).toLocaleLowerCase().includes(debouncedQueryValue.toLocaleLowerCase())
      ).length,
    [data.users, debouncedQueryValue]
  );

  const isSaving = saveUserMutation.isLoading;

  const adminGroup = groups.find((group) => group.role_key === "KDK_ADMIN");
  const rolesNames = adminGroup?.name ? [adminGroup?.name] : [];

  const adminUsers = useMemo(
    () => (adminGroup?.id ? activeUsers.filter((user) => user.groups?.includes(adminGroup.id)) : []),
    [data.users]
  );

  const handleUpdate = (value: ValueOf<api.User>, property: keyof api.User) => {
    if (property === "email") {
      saveUserMutation.reset();
    }
    setCurrentUser({ ...currentUser, [property]: value });
  };

  const activateUser = (isActive: boolean) => {
    setReactivating(true);
    currentUser.is_active = isActive;
    saveUserMutation.mutate(currentUser, { onSuccess: () => setReactivating(false) });
  };

  const saveUser = () => saveUserMutation.mutate(currentUser);

  const closeAndClearModal = () => {
    closeModal();
    setCurrentUser(EMPTY_USER);
    saveUserMutation.reset();
  };

  const editUser = readonly
    ? noop
    : (user: api.User) => {
        setCurrentUser(user);
        toggleModalOpen();
      };

  const isNewUser = !currentUser.id;
  const modalTitle = isNewUser ? f("users.modal.title.add-user") : f("users.modal.title.update-user");

  const emailServerError =
    saveUserMutation.error?.data?.email && saveUserMutation.error.data.email.length > 0
      ? saveUserMutation.error.data.email[0]
      : undefined;

  const filterUsersLabel = isShowingInactiveUsers
    ? f("users.page.filter.hide-inactive-users", { count: inactiveUsersCount })
    : f("users.page.filter.show-inactive-users", { count: inactiveUsersCount });

  const cancelAction: ComplexAction = {
    content: f("default.cancel"),
    onAction: closeAndClearModal,
    disabled: isSaving
  };

  const saveAction: ComplexAction = {
    content: f("default.save"),
    onAction: saveUser,
    loading: isSaving && !isReactivating,
    disabled: isReactivating || !isEmail(currentUser.email) || !currentUser.first_name || !currentUser.last_name
  };

  return (
    <SettingsPage
      permissions={PERMISSIONS_TYPES.ADMIN_USER}
      overrideRoleNames={rolesNames}
      title={f("users.page.title")}
      primaryAction={
        <AddButton onClick={toggleModalOpen} disabled={readonly}>
          {f("users.page.add-user.button-label")}
        </AddButton>
      }
    >
      <FilterContainer>
        <SearchTextField
          placeholder={f("users.page.filter.placeholder")}
          value={queryText}
          clearButton
          labelHidden
          onChange={setQueryText}
          onClearButtonClick={() => setQueryText("")}
        />
      </FilterContainer>
      <Card>
        {users.length === 0 && <EmptyState heading={f("users.page.table.empty")} image="" />}
        {users.length > 0 && (
          <DataTable
            increasedTableDensity
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <CondensedText>{f("table.column.name")}</CondensedText>,
              <CondensedText> {f("table.column.email")}</CondensedText>,
              <CondensedText> {f("table.column.groups")}</CondensedText>,
              <CondensedText> {f("table.column.active")}</CondensedText>
            ]}
            rows={users.map((user) => [
              <DataTableCellChild width={USER_NAME_COLUMN_WIDTH}>
                <RowHighlighter subdued={!user.is_active} clickable={!readonly}>
                  <TextHighlighter
                    searchWords={[queryText]}
                    textToHighlight={getFullName(user)}
                    onClick={() => editUser(user)}
                  />
                </RowHighlighter>
              </DataTableCellChild>,
              <div onClick={() => editUser(user)}>
                <EmailView>{user.email || ""}</EmailView>
              </div>,
              <StyledGroupsCell onClick={() => editUser(user)}>{getUserGroupsDisplayName(user)}</StyledGroupsCell>,
              <StyledActiveTableCell onClick={() => editUser(user)}>{yesNo(user.is_active)}</StyledActiveTableCell>
            ])}
          />
        )}
      </Card>
      {inactiveUsersCount > 0 && (
        <HorizontalStack>
          <Button onClick={toggleShowInactiveUsers} disclosure={isShowingInactiveUsers ? "up" : "down"}>
            {filterUsersLabel}
          </Button>
        </HorizontalStack>
      )}
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onClose={closeAndClearModal}
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
        footer={
          <Button
            disabled={!currentUser.id || isSaving}
            destructive
            loading={isSaving && isReactivating}
            onClick={() => activateUser(!currentUser.is_active)}
          >
            {currentUser.is_active ? f("users.modal.label.deactivate") : f("users.modal.label.activate")}
          </Button>
        }
      >
        <Modal.Section>
          <VerticalStack gap="4">
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  autoFocus
                  label={f("common.labels.first_name")}
                  value={currentUser.first_name}
                  placeholder={f("common.labels.first_name")}
                  disabled={isSaving}
                  onChange={(value) => handleUpdate(value, "first_name")}
                  autoComplete="off"
                />
                <TextField
                  label={f("common.labels.last_name")}
                  value={currentUser.last_name}
                  placeholder={f("common.labels.last_name")}
                  disabled={isSaving}
                  onChange={(value) => handleUpdate(value, "last_name")}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
            <TextField
              label={f("common.labels.email")}
              value={currentUser.email}
              type="email"
              placeholder={f("email.placeholder.text")}
              disabled={isSaving}
              error={
                currentUser.email && !isEmail(currentUser.email) ? f("email.invalid.address.error") : emailServerError
              }
              onChange={(value) => handleUpdate(value, "email")}
              autoComplete="off"
            />
            <VerticalStack gap="1">
              <Text variant="bodyMd" as="span">
                {f("common.labels.groups")}
              </Text>
              <PopoverButton
                disabled={isSaving}
                textAlign="left"
                useCaret
                fullWidth={false}
                label={
                  !currentUser.groups || currentUser.groups.length === 0
                    ? f("users.modal.empty-group")
                    : getUserGroupsDisplayName(currentUser)
                }
                render={() => (
                  <OptionList
                    allowMultiple
                    title={f("common.labels.groups")}
                    selected={(currentUser.groups || []).map((id) => `${id}`)}
                    options={groups.map((group) => ({
                      label: getGroupName(group),
                      value: `${group.id}`,
                      disabled: group.id === adminGroup?.id && adminUsers.length === 1
                    }))}
                    onChange={(selected) => {
                      handleUpdate(
                        selected.map((idString) => Number(idString)),
                        "groups"
                      );
                    }}
                  />
                )}
              />
            </VerticalStack>
            {saveUserMutation.isError && !emailServerError && <ErrorPanel message={saveUserMutation.error} />}
          </VerticalStack>
        </Modal.Section>
      </Modal>
    </SettingsPage>
  );
};

const StyledActiveTableCell = styled(DataTableCellChild)`
  // set a minimum width to the Active column cell in order to have a padding on the column header
  min-width: 4ch;
`;

const StyledGroupsCell = styled.div`
  white-space: normal;
`;

export default withAsyncPage<api.UserListResponse>(SettingsUsersPage, {
  queryKey: QUERIES_KEYS.USERS,
  apiFunction: api.getAllUsersAndGroups,
  paramNames: [],
  skeleton: <SettingsUsersSkeleton />
});
