import React from "react";
import styled from "styled-components";

import SettingsIcon from "../../../assets/icons/gear.svg";
import useSettingsItems from "../../hooks/useSettingsItems";
import { isEmptyString } from "../../utils/stringUtils";
import Button from "../extensions/Button";

const SettingsButton = () => {
  const settingsSections = useSettingsItems();

  const firstSettingsPage =
    settingsSections.find((section) => section.items.length > 0)?.items.find((item) => !isEmptyString(item.url))?.url ||
    "";

  return <StyledButton icon={SettingsIcon} url={firstSettingsPage} round />;
};

const StyledButton = styled(Button)`
  & > .Polaris-Button {
    padding: 0;
    box-shadow: none;

    & img {
      width: 26px;
      height: 26px;
    }
  }

  height: 100%;

  display: grid;
  place-items: center;

  padding: 0 var(--p-space-3);
`;

export default SettingsButton;
