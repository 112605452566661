import { useMemo } from "react";
import SettingsIcon from "jsx:../../assets/icons/gear.svg";

import { ROUTES } from "../constants/routes";
import { NavigationSection } from "../providers/PageNavigation";

import useFeatures from "./useFeatures";
import useFormatMessage from "./useFormatMessage";
import useGetKYCPreviewerURL from "./useGetKYCPreviewerURL";
import useLanguage from "./useLanguage";

const useSettingsItems = () => {
  const f = useFormatMessage();
  const features = useFeatures();
  const language = useLanguage();
  const kycPreviewerURL = useGetKYCPreviewerURL();

  const sections: NavigationSection[] = useMemo(() => {
    const userManagement: NavigationSection = {
      title: f("navigation.settings.users.section.title"),
      items: [
        {
          url: ROUTES.SETTINGS_USERS,
          label: f("navigation.settings.users.title")
        }
      ]
    };

    const onboardingManagement: NavigationSection = {
      title: f("navigation.settings.onboarding.section.title"),
      items: [
        {
          url: ROUTES.SETTINGS_ONBOARDING,
          label: f("navigation.settings.onboarding.person.title")
        }
      ]
    };

    const kycManagement: NavigationSection = {
      title: f("common.labels.kyc"),
      items: []
    };

    if (features.DISCLOSURE_SETTINGS) {
      kycManagement.items.push({
        url: ROUTES.SETTINGS_DISCLOSURE,
        label: f("disclosure.settings.page.title")
      });
    }

    kycManagement.items.push({
      url: ROUTES.SETTINGS_QUESTIONNAIRES_LIST,
      label: f("navigation.settings.kyc.custom-questionnaires.label")
    });

    const riskManagement: NavigationSection = {
      title: f("navigation.settings.risk-management.section.title"),
      items: [
        {
          url: ROUTES.SETTINGS_FOLLOWUP,
          label: f("followup-settings.page.title")
        }
      ]
    };

    if (features.MEASURES) {
      riskManagement.items.push({
        url: ROUTES.SETTINGS_MEASURES,
        label: f("measures-settings.page.title")
      });
    }

    riskManagement.items.push(
      {
        url: ROUTES.SETTINGS_GUIDELINE,
        label: f("customer-review-guideline-settings.page.title")
      },
      {
        url: ROUTES.SETTINGS_TAGS,
        label: f("tags.page.title")
      }
    );

    if (features.REVIEW_CHECKLIST) {
      riskManagement.items.push({
        url: ROUTES.SETTINGS_REVIEW_CHECKLISTS_LIST,
        label: f("navigation.settings.checklists.label")
      });
    }

    const notificationsManagement: NavigationSection = {
      title: f("navigation.settings.notifications.section.title"),
      items: [
        {
          url: ROUTES.SETTINGS_AUTO_REMINDERS,
          label: f("navigation.settings.kyc.auto-reminder.label")
        },
        {
          url: ROUTES.SETTINGS_FOLLOWUP_NOTIFICATIONS,
          label: f("followup-notifications.settings.page.title")
        }
      ]
    };

    if (features.SETTINGS__CUSTOMER_INFO_NOTIFICATIONS) {
      notificationsManagement.items.push({
        url: ROUTES.SETTINGS_CUSTOMER_NOTIFICATIONS,
        label: f("navigation.settings.notifications.customer-info.label")
      });
    }

    const internalTools: NavigationSection = {
      title: f("navigation.settings.internal-tools.section.title"),
      items: []
    };

    if (features.KYC_PREVIEWER) {
      internalTools.items.push({
        url: kycPreviewerURL,
        label: f("navigation.settings.internal-tools.kyc-previewer.label"),
        options: { external: true }
      });
    }

    const result = [
      { title: f("menu.settings"), icon: SettingsIcon, items: [] },
      userManagement,
      onboardingManagement,
      kycManagement,
      riskManagement,
      notificationsManagement
    ];

    if (internalTools.items.length > 0) {
      result.push(internalTools);
    }

    return result;
  }, [features, language]);

  return sections;
};

export default useSettingsItems;
