import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Pagination, PaginationProps, Stack } from "@shopify/polaris";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import { getDetailsPageURL } from "../../helpers/navigation.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";
import BackButton from "../BackButton/BackButton";

interface MatchPageNavigationProps {
  matchData: api.MatchCaseDetailResponse;
  matchCases: api.MatchCaseSummary[];
}

const MatchPageNavigation = (props: MatchPageNavigationProps) => {
  const { matchData, matchCases } = props;
  const { customer_id: customerId, customer_type, customer_name } = matchData;

  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();

  const matchPosition = matchCases.findIndex((matchCase) => matchCase.id === matchData.case.id);

  const nextMatchCase =
    matchPosition > -1 && matchPosition < matchCases.length - 1 ? matchCases[matchPosition + 1] : undefined;
  const prevMatchCase = matchPosition > 0 ? matchCases[matchPosition - 1] : undefined;

  const hasNext = nextMatchCase !== undefined;
  const hasPrevious = prevMatchCase !== undefined;

  const onNext = () =>
    history.replace(
      generatePath(ROUTES.MATCH_CASE_DETAILS, { customerId, id: nextMatchCase!.id }),
      history.location.state
    );

  const onPrevious = () =>
    history.replace(
      generatePath(ROUTES.MATCH_CASE_DETAILS, { customerId, id: prevMatchCase!.id }),
      history.location.state
    );

  const nextTooltip = nextMatchCase
    ? f("matches.navigation.tooltip", { summary: nextMatchCase.summary_text, name: nextMatchCase.subject_name })
    : undefined;

  const previousTooltip = prevMatchCase
    ? f("matches.navigation.tooltip", { summary: prevMatchCase.summary_text, name: prevMatchCase.subject_name })
    : undefined;

  const paginationProps: PaginationProps = { hasNext, hasPrevious, onNext, onPrevious, nextTooltip, previousTooltip };

  return (
    <Stack>
      <BackButton url={getDetailsPageURL(customerId, customer_type)} displayLabel={customer_name} showAsLink />
      <Stack.Item fill />
      {matchCases.length > 1 && <Pagination {...paginationProps} />}
    </Stack>
  );
};

export default MatchPageNavigation;
