import { Tooltip, TooltipProps } from "@shopify/polaris";

import { isEmptyString } from "../../utils/stringUtils";
import { isNil } from "../../utils/util";

const TooltipWrapper = (props: TooltipProps) => {
  const { content, children } = props;

  if (isNil(content) || (typeof content === "string" && isEmptyString(content))) {
    return children;
  }

  return <Tooltip {...props} />;
};

export default TooltipWrapper;
