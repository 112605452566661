import React from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isPDFAttachment } from "../../utils/filenameUtils";
import Button from "../extensions/Button";

import { AttachmentSignatureStatusIconsMap } from "./AttachmentsSection.constants";

interface AttachmentSignaturesStatusProps {
  attachment: api.AttachmentDetails;
  onClick(): void;
}

const AttachmentSignaturesStatus = (props: AttachmentSignaturesStatusProps) => {
  const { attachment, onClick } = props;

  const f = useFormatMessage();
  const isPDF = isPDFAttachment(attachment);

  const signed = attachment.completed_signature_count;
  const total = attachment.signature_count;

  const status = isPDF && attachment.signature_required && attachment.signature_status;

  const isActive = total > 0;

  const icon = status ? AttachmentSignatureStatusIconsMap[status] : undefined;
  let label = f("attachments.labels.signature-status.not-activated");

  switch (status) {
    case "EXPIRED": {
      label = f("attachments.labels.signature-status.expired");
      break;
    }
    case "SIGNED": {
      label = f("attachments.labels.signature-status.signed", { signed, total });
      break;
    }
    case "PENDING": {
      if (isActive) {
        label = f("attachments.labels.signature-status.not-signed", { signed, total });
      } else {
        label = f("attachments.labels.signature-status.not-activated");
      }
      break;
    }
    case "INVALID_FOR_SIGNING": {
      label = f("attachments.labels.signature-status.unsignable");
      break;
    }
    default: {
      label = isPDF ? f("attachments.labels.signature-status.not-activated") : "-";
    }
  }

  return (
    <Button onClick={onClick} icon={icon} disabled={!isPDF} plain monochrome removeUnderline hoverable>
      {label}
    </Button>
  );
};

export default AttachmentSignaturesStatus;
