import React from "react";

import api from "../../../../api";
import { isOwnerPerson } from "../../../../helpers/owners.helpers";
import { convertPersonToUpdateRequest } from "../../../../helpers/person.helpers";
import VitecPersonSync from "../../../VitecPersonSync/VitecPersonSync";

interface VitecOwnerSyncProps {
  customerId: string;
  owner: api.Owner;
  readonly?: boolean;
  onUpdate(owner: api.Owner): void;
}

const VitecOwnerSync = (props: VitecOwnerSyncProps) => {
  const { customerId, owner, readonly, onUpdate } = props;

  if (!isOwnerPerson(owner)) {
    return null;
  }

  const syncToVitec = () => api.syncCompanyOwner(customerId, { owner_id: owner.id! });

  const updateOwner = (phone: string) =>
    api.updateCompanyOwner(customerId, owner.id!, {
      person: { ...convertPersonToUpdateRequest(owner.person!), phone },
      percent_share: parseFloat(owner.percent_share || "0"),
      comment: owner.comment
    });

  return (
    <VitecPersonSync<api.Owner>
      entity={owner}
      isSyncedToVitec={owner.synced_with_vitec}
      readonly={readonly}
      syncToVitecAPICall={syncToVitec}
      updatePersonAPICall={updateOwner}
      onAfterUpdate={onUpdate}
    />
  );
};

export default VitecOwnerSync;
