import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Button, Page, ProgressBar, Text, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import { stripOrgId } from "../../helpers/onboarding.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";

import CompanyLookupResultConfirm from "./CompanyLookupResultConfirm/CompanyLookupResultConfirm";

interface NotFoundProps {
  id: string;
}

const NotFound = ({ id }: NotFoundProps) => {
  const f = useFormatMessage();
  const history = useHistory();

  const lookupPath = generatePath(ROUTES.COMPANY_LOOKUP);

  return (
    <Page>
      <VerticalStack gap="4">
        <Text variant="headingXl" as="p">
          {f("lookup.error.company.not.found", { orgId: id })}
        </Text>
        <Button primary onClick={() => history.push(lookupPath)}>
          {f("lookup.again")}
        </Button>
      </VerticalStack>
    </Page>
  );
};

const OnboardingCompanyLookupResultPage = () => {
  const { orgId } = useParams<{ orgId: string }>();

  const f = useFormatMessage();

  const { data, isLoading, isError } = useQuery(["search", orgId], () => api.getCompanyInfo("NO", stripOrgId(orgId)));

  if (isLoading) {
    return (
      <Page>
        <VerticalStack gap="4">
          <Text variant="headingLg" as="p">
            {f("in.progress")}
          </Text>
          <ProgressBar progress={70} size="small" />
        </VerticalStack>
      </Page>
    );
  }

  if (isError || !data) {
    return <NotFound id={orgId} />;
  }

  return <CompanyLookupResultConfirm lookupInfo={data.basic_legal_entity_info} />;
};

export default OnboardingCompanyLookupResultPage;
