import React from "react";
import { Layout, Link, Page, Text, VerticalStack } from "@shopify/polaris";

import PlanConversionProjectsImage from "../../../assets/images/plan-update-projects.png";
import { PLAN_CONVERSION_PAGE } from "../../constants/external-urls";
import useFormatMessage from "../../hooks/useFormatMessage";

const PlanConversionProjectPage = () => {
  const f = useFormatMessage();

  return (
    <Page>
      <Layout>
        <Layout.Section secondary>
          <img
            alt={f("plan-conversion.projects.image.alt-text")}
            width="100%"
            height="100%"
            loading="lazy"
            src={PlanConversionProjectsImage}
          />
        </Layout.Section>
        <Layout.Section>
          <VerticalStack gap="8">
            <Text variant="heading2xl" as="p">
              {f("projects.page.title")}
            </Text>
            <Text variant="bodyMd" as="span">
              {f("plan-conversion.projects.description")}
            </Text>
            <Text variant="bodyMd" as="span">
              {f("plan-conversion.messages.access-feature")}
            </Text>
            <Link url={PLAN_CONVERSION_PAGE} external>
              {f("plan-conversion.banner.labels.link")}
            </Link>
          </VerticalStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default PlanConversionProjectPage;
