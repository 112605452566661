import React from "react";
import { Card, DataTable, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { MAIN_COLUMN_WIDTH } from "../../constants/tables";
import { getBirthDate, getFullName } from "../../helpers/display.helpers";
import { isOnboardingFinished } from "../../helpers/onboarding.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import DataTableCellChild from "../DataTableCellChild/DataTableCellChild";
import DisplayName from "../DisplayName/DisplayName";

import useCompanyScreeningSection from "./useCompanyScreeningSection";

interface CompanyScreeningSectionProps {
  customer: api.CompanyCustomerDetailResponse;
}

const CompanyScreeningSection = (props: CompanyScreeningSectionProps) => {
  const { customer } = props;

  const f = useFormatMessage();

  const hasFinishedOnboarding = isOnboardingFinished(customer);
  const { entitiesToDisplay } = useCompanyScreeningSection(customer);

  const rows = [
    // show company name first
    [
      <DataTableCellChild width={MAIN_COLUMN_WIDTH}>
        <DisplayName strong type="company" name={customer.name}>
          {customer.national_id && f("display-name.suffixes.labels.org-id", { orgId: customer.national_id })}
        </DisplayName>
      </DataTableCellChild>,
      f("screening.titles.customer")
    ],
    // show roles and owners
    ...entitiesToDisplay.map((entity) => [
      <DataTableCellChild width={MAIN_COLUMN_WIDTH}>
        <DisplayName
          type={entity.person ? "person" : "company"}
          name={getFullName(entity.person)}
          birthDate={getBirthDate(entity.person)}
          comment={entity.comment}
        />
      </DataTableCellChild>,
      <Text variant="bodyMd" as="span">
        {entity.types.join(", ")}
      </Text>
    ])
  ];

  return (
    <VerticalStack gap="4">
      {hasFinishedOnboarding && (
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={[f("table.column.name"), f("table.column.title")]}
          rows={rows}
        />
      )}
      {!hasFinishedOnboarding && <Card.Section>{f("company.details.onboarding.no-screening-performed")}</Card.Section>}
    </VerticalStack>
  );
};

export default CompanyScreeningSection;
