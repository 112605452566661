import React from "react";
import { Page, ProgressBar, Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

interface ScreeningSkeletonProps {
  message?: string;
  progress?: number;
}

const ScreeningSkeleton = (props: ScreeningSkeletonProps) => {
  const f = useFormatMessage();

  const { progress = 15, message = f("common.loading.data") } = props;

  return (
    <Page>
      <VerticalStack gap="8">
        <Text variant="headingLg" as="p">
          {message}
        </Text>
        <ProgressBar progress={progress} size="small" />
      </VerticalStack>
    </Page>
  );
};

export default ScreeningSkeleton;
