import { hasPendingTasks } from "../helpers/pending-tasks.helpers";
import { Customer } from "../types/utilities";

import usePendingTask from "./usePendingTask";

const useCustomerPendingTasks = (customer: Customer, haveOwnershipUpdates?: boolean) => {
  const getPendingTaskStatuses = usePendingTask(customer);

  if (!hasPendingTasks(customer)) {
    return [];
  }

  const pendingTasks = customer.pending_tasks;

  if (!pendingTasks || pendingTasks.length === 0 || pendingTasks[0] === undefined) {
    return [];
  }

  // if haveOwnershipUpdates is true then try to return the "REVIEW_OWNERSHIP_UPDATES" task first
  const topPendingTask =
    haveOwnershipUpdates && pendingTasks.length > 1
      ? pendingTasks.find((task) => task.type === "REVIEW_OWNERSHIP_UPDATES") || pendingTasks[0]
      : pendingTasks[0];

  return getPendingTaskStatuses(topPendingTask);
};

export default useCustomerPendingTasks;
