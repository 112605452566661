import React from "react";
import { InlineError, InlineErrorProps } from "@shopify/polaris";

import useErrorParser from "../../hooks/useErrorParser";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ErrorType } from "../../types/utilities";

interface ErrorPanelProps extends Omit<InlineErrorProps, "fieldID" | "message"> {
  message: string | ErrorType;
  fieldID?: string;
  actionTitle?: string;
}

const ErrorPanel = (props: ErrorPanelProps) => {
  const { fieldID = "", message, actionTitle, ...inlineErrorProps } = props;

  const f = useFormatMessage();
  const parseError = useErrorParser();

  const { generalError = f("common.errors.generic") } = parseError(message);

  const actionLabel = (actionTitle || f("common.labels.save-changes")).toLocaleLowerCase();
  const displayMessage = f("common.errors.generic.failed-action", { actionLabel, message: generalError });

  return <InlineError fieldID={fieldID} message={displayMessage} {...inlineErrorProps} />;
};

export default ErrorPanel;
