import React from "react";
import { Banner, BannerProps } from "@shopify/polaris";
import styled from "styled-components";

type KDKBannerProps = {
  hideBorder?: boolean;
  className?: string;
  id?: string;
};

const KDKBanner = (props: KDKBannerProps & BannerProps) => {
  const { hideBorder, className, id, ...bannerProps } = props;

  return (
    <StyledBanner hideBorder={hideBorder} className={className} id={id}>
      <Banner {...bannerProps} />
    </StyledBanner>
  );
};

const StyledBanner = styled.div<KDKBannerProps>`
  & > .Polaris-Banner {
    border: ${({ hideBorder }) => (hideBorder ? "none" : undefined)};
  }
`;

export default KDKBanner;
