import React, { useEffect, useState } from "react";
import { Toast } from "@shopify/polaris";
import { MutationFunction, useMutation } from "@tanstack/react-query";

import useOpenClose from "../../hooks/useOpenClose";
import { noop } from "../../utils/util";
import Confirm, { ConfirmProps } from "../Confirm/Confirm";

export type ConfirmActionProps<T> = Omit<ConfirmProps, "onYes" | "isActionInProgress" | "error"> & {
  apiCall: MutationFunction<T>;
  onSuccess?(response: T): void;
  useSuccessToast?: boolean;
  successTitle?: string;
};

function ConfirmAction<T = never>(props: ConfirmActionProps<T>) {
  const { apiCall, onSuccess = noop, useSuccessToast, successTitle, onNo, isOpen, ...confirmProps } = props;

  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();
  const [toastMessage, setToastMessage] = useState(successTitle || "");

  // only update the toast message if it's not showing, or after it was closed
  useEffect(() => {
    if (!showSuccessMessageToast) {
      setToastMessage(successTitle || "");
    }
  }, [showSuccessMessageToast, successTitle]);

  const mutation = useMutation(apiCall, {
    onSuccess: (response: T) => {
      if (useSuccessToast) {
        toggleSuccessMessageToast();
      } else {
        onNo();
      }
      onSuccess(response);
    }
  });

  const handleToastDismiss = () => {
    closeSuccessMessageToast();
    setToastMessage("");
    onNo();
  };

  const showConfirm = isOpen && (useSuccessToast ? !showSuccessMessageToast : true);

  return (
    <>
      <Confirm
        isOpen={showConfirm}
        isActionInProgress={mutation.isLoading}
        onYes={() => mutation.mutate({})}
        onNo={onNo}
        error={mutation.error}
        {...confirmProps}
      />
      {showSuccessMessageToast && <Toast onDismiss={handleToastDismiss} content={toastMessage} />}
    </>
  );
}

export default ConfirmAction;
