import React from "react";

import api from "../../api";
import useChecklistArchiveMutation from "../../hooks/useChecklistArchiveMutation";
import useFormatMessage from "../../hooks/useFormatMessage";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

type ReviewChecklistArchiveConfirmProps = {
  checklist: api.ReviewChecklist;
  onClose(): void;
};

const ReviewChecklistArchiveConfirm = (props: ReviewChecklistArchiveConfirmProps) => {
  const { checklist, onClose } = props;

  const f = useFormatMessage();

  const archiveQuestionnaireMutation = useChecklistArchiveMutation();

  return (
    <ConfirmAction
      apiCall={() => archiveQuestionnaireMutation.mutateAsync(checklist)}
      title={f("review.checklists.archive.confirmation.title")}
      description={f("review.checklists.archive.confirmation.description", { name: checklist.name })}
      onNo={onClose}
      actionTitle={f("default.archive")}
    />
  );
};

export default ReviewChecklistArchiveConfirm;
