import React, { useEffect, useState } from "react";
import { Form, FormLayout } from "@shopify/polaris";

import PlusMajorIcon from "../../../assets/icons/plus-major.svg";
import { getTagTitle } from "../../helpers/tag.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useTags from "../../hooks/useTags";
import { isEmptyString } from "../../utils/stringUtils";
import { noop } from "../../utils/util";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import SearchTextField from "../SearchTextField/SearchTextField";

type NewTagFormProps = {
  disabled?: boolean;
  onTagValueChange?(value: string): void;
  onNewTag?(title: string): void;
};

const NewTagForm = (props: NewTagFormProps) => {
  const { disabled, onTagValueChange, onNewTag } = props;

  const f = useFormatMessage();
  const { allTags } = useTags();

  const [newTagValue, setNewTagValue] = useState("");

  useEffect(() => onTagValueChange && onTagValueChange(newTagValue), [newTagValue]);

  const handleNewTag = onNewTag ? async () => await onNewTag(newTagValue.trim()) : noop;

  const isDuplicateTag = new Set(allTags.map((tag) => getTagTitle(tag).toLocaleLowerCase())).has(
    newTagValue.trim().toLocaleLowerCase()
  );

  const showAddButton = onNewTag && (allTags.length === 0 || !isEmptyString(newTagValue));

  const isInvalidNewTag = isDuplicateTag || isEmptyString(newTagValue.trim());

  return (
    <Form onSubmit={handleNewTag}>
      <FormLayout>
        <SearchTextField
          label={onNewTag ? f("tags.editor.new-tag-input") : f("default.search")}
          value={newTagValue}
          onChange={setNewTagValue}
          placeholder={f("default.search")}
        />
        {showAddButton && (
          <Button
            plain
            removeUnderline
            submit
            icon={<Icon source={PlusMajorIcon} width="12px" height="12px" useMask />}
            disabled={isInvalidNewTag || disabled}
          >
            {f("tags.editor.add-and-apply-button", { tag: newTagValue })}
          </Button>
        )}
      </FormLayout>
    </Form>
  );
};

export default NewTagForm;
