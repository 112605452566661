import React from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

interface ScreeningStatusBadgeProps {
  screening: api.CustomerScreeningStatus;
}

const ScreeningStatusBadge = (props: ScreeningStatusBadgeProps) => {
  const f = useFormatMessage();

  const { screening } = props;
  const { screening_status, screening_type } = screening;

  const isDone = screening_status === "DONE" && (screening_type === "ONE_TIME" || screening_type === "MONITORING");

  if (!isDone) {
    return null;
  }

  const screeningStarted = screening.screening_started ? new Date(screening.screening_started) : undefined;
  const screeningStopped = screening.screening_stopped ? new Date(screening.screening_stopped) : undefined;

  if (!screeningStarted) {
    return null;
  }

  return (
    <>
      {screening_type === "ONE_TIME" && f("screening.status.finished", { finished: screeningStarted })}
      {screening_type === "MONITORING" &&
        screeningStopped &&
        f("screening.status.range", { started: screeningStarted, stopped: screeningStopped })}
    </>
  );
};

export default ScreeningStatusBadge;
