import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import KDKBanner from "../KDKBanner/KDKBanner";
import MarkdownContent from "../MarkdownContent/MarkdownContent";

const ReviewChecklistEditorTipsBanner = () => {
  const f = useFormatMessage();

  return (
    <StyledBanner status={"info"} title={f("review.checklist.editor.tips.banner.title")}>
      <MarkdownContent content={f("review.checklist.editor.tips.banner.description")} />
    </StyledBanner>
  );
};

const StyledBanner = styled(KDKBanner)`
  display: inline-block;
  margin-top: var(--p-space-6);
  width: 100%;
`;

export default ReviewChecklistEditorTipsBanner;
