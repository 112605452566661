import React, { useCallback, useState } from "react";
import { Card, HorizontalStack, Page, Stack, Text, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import AddButton from "../../components/AddButton/AddButton";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import ProjectInfoModal from "../../components/ProjectInfoModal/ProjectInfoModal";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useProjectPermissions from "../../hooks/useProjectPermissions";

import ProjectsListEmptyState from "./ProjectsListEmptyState";
import ProjectsListFilters from "./ProjectsListFilters";
import ProjectsListTable from "./ProjectsListTable";
import useProjectListParams from "./useProjectListParams";

const ProjectsListPage = () => {
  const f = useFormatMessage();
  const { isCreateOrAddToProjectEnabled } = useProjectPermissions();

  const { searchParams, isEmptySearch } = useProjectListParams();
  const [showModal, toggleShowModal, closeModal] = useOpenClose();
  const [selectedProjectsMap, setSelectedProjectsMap] = useState<Record<string, api.ProjectListItem>>({});

  const selectedProjectsIds = Object.keys(selectedProjectsMap);

  const { isLoading, isError, error, data } = useQuery([QUERIES_KEYS.PROJECTS, ...Object.values(searchParams)], () =>
    api.getProjects(searchParams)
  );

  const count = data?.count || 0;
  const projects = data?.projects || [];

  const handleSelectionChange = useCallback(
    (selectedIds: string[]) => {
      setSelectedProjectsMap((currentSelectedProjectsMap) => {
        const selectionMap: Record<string, api.ProjectListItem> = {};

        selectedIds.forEach((selectedProjectId) => {
          selectionMap[selectedProjectId] =
            currentSelectedProjectsMap[selectedProjectId] ||
            projects.find((project) => project.id === selectedProjectId);
        });

        return selectionMap;
      });
    },
    [projects]
  );

  const showEmptyState = isEmptySearch && count === 0 && !isLoading && !isError;

  return (
    <Page>
      <VerticalStack gap="8">
        <Stack>
          <Stack.Item fill>
            <Text variant="heading2xl" as="p">
              {f("projects.page.title")}
            </Text>
          </Stack.Item>
          {isCreateOrAddToProjectEnabled && (
            <AddButton onClick={toggleShowModal}>{f("projects.common.labels.create.project")}</AddButton>
          )}
        </Stack>
        <ProjectsListFilters isLoading={isLoading} />
        <StyledTableToolbar>
          <HorizontalStack gap="4" blockAlign="center">
            <Text variant="bodyMd" as="span">
              {count > 0
                ? f("projects.page.header.projects.count", { count, selected: selectedProjectsIds.length })
                : ""}
            </Text>
          </HorizontalStack>
        </StyledTableToolbar>
      </VerticalStack>
      {!showEmptyState && (
        <StyledTable
          isLoading={isLoading}
          projects={projects}
          count={count}
          selectedIds={selectedProjectsIds}
          onSelectionChange={handleSelectionChange}
        />
      )}
      {showEmptyState && <ProjectsListEmptyState />}
      {isError && (
        <Card>
          <ErrorPanel message={error} />
        </Card>
      )}
      {showModal && <ProjectInfoModal onClose={closeModal} />}
    </Page>
  );
};

const StyledTableToolbar = styled.div`
  & > div {
    min-height: 4.375rem;
  }
`;

const StyledTable = styled(ProjectsListTable)`
  & .Polaris-DataTable,
  & .Polaris-DataTable__ScrollContainer {
    overflow: unset;
  }

  & thead > tr > th {
    position: sticky;
    top: var(--kdk-toolbar-height);
    z-index: var(--p-z-1);
    background-color: var(--p-surface);
  }
`;

export default ProjectsListPage;
