import { useMemo } from "react";

import api from "../api";
import { isPartialAddress } from "../helpers/customer.helpers";
import {
  getSortMatchEventByRelevance,
  getSortMatchSourceByRelevance,
  groupComplyMatchFieldsByDescription,
  groupMatchAttributesByCode,
  isEmptyMatchSource,
  isNotComplyAdvantageSource,
  isPartialMatchAddress,
  sortAddressesByRelevance,
  sortBirthDatesByRelevance
} from "../helpers/match.helpers";
import { getPersonUniqueCountries } from "../helpers/person.helpers";
import { ComplyMatchEvent, ComplyMatchPosition } from "../types/MatchDetails";
import { difference, groupBy } from "../utils/collectionUtils";

import useMatchPage from "./useMatchPage";

const useComplyAdvantageMatch = (data: api.MatchCaseDetailResponse) => {
  const match = data.case.match;
  const newMatch = data.case.match_new;
  const subject = match.subject;

  const { isSubjectPerson, hasNewInfo } = useMatchPage(data);

  // RDC attributes
  const { riskography, links, remarks } = useMemo(() => groupMatchAttributesByCode(match), [match]);

  // ComplyAdvantage attributes
  const { countries, nationalities } = useMemo(() => groupComplyMatchFieldsByDescription(newMatch), [newMatch]);

  //// Countries
  const subjectCountries = isSubjectPerson(subject) ? getPersonUniqueCountries(subject) : [subject.country];
  const matchCountries = countries;

  //// Addresses
  const subjectAddresses: api.Address[] = (
    isSubjectPerson(subject)
      ? [subject.address]
      : [subject.business_address, subject.office_address, subject.mailing_address]
  )
    .filter(Boolean)
    .filter(isPartialAddress);

  // sort by relevance
  const matchAddresses = match.addresses.filter(
    (address) => isNotComplyAdvantageSource(address) && isPartialMatchAddress(address)
  );
  matchAddresses.sort(sortAddressesByRelevance(subjectAddresses));

  //// Nationalities
  const matchNationalities = nationalities;
  const subjectNationalities = isSubjectPerson(subject) ? [subject.country_of_citizenship].filter(Boolean) : [];

  const subjectPersonBirthDate = isSubjectPerson(subject) ? subject.birth_date || subject.birth_year?.toFixed(0) : "";

  // sort birth dates by relevance
  const birthdates = match.birth_dates.sort(sortBirthDatesByRelevance(subjectPersonBirthDate));

  // RDC Events
  const { positions, pepEvents, sanctionEvents, adverseMediaEvents, otherEvents } = useMemo(() => {
    // if event category is "WLT" (watch list) then use the sub_category code for grouping
    const groupedEvents = groupBy(match.events, (event) =>
      event.category.code === "WLT" ? event.sub_category.code : event.category.code
    );

    const externalSources = [...newMatch.sanction_sources, ...newMatch.pep_sources];
    const sources = externalSources.map((sourceName) => newMatch.sources[sourceName]);

    const positions: ComplyMatchPosition[] = sources
      .flatMap((source) => {
        const startDate = source.attributes?.find((attribute) => attribute.description === "active_start_date")?.value;
        return source.positions?.map((position) => ({ ...position, startDate }));
      })
      .filter(Boolean)
      .filter((position) => position.source_key !== "complyadvantage")
      .sort((posA, posB) => posA.value.localeCompare(posB.value));

    const barePepEvents = groupedEvents.get("PEP") || [];

    const bareSanctionEvents = groupedEvents.get("SAN") || [];

    const adverseMediaEvents = groupedEvents.get("ADV") || [];
    adverseMediaEvents.sort(getSortMatchEventByRelevance(hasNewInfo));

    const otherEvents = difference([...groupedEvents.keys()], ["PEP", "SAN", "ADV"]).flatMap(
      (key) => groupedEvents.get(key) || []
    );

    otherEvents.sort(getSortMatchEventByRelevance(hasNewInfo));

    // Enrich sanctions events with additional information from Comply Advantage sources
    const sanctionEvents: ComplyMatchEvent[] = bareSanctionEvents.map((event) => {
      const source = event.source_key ? newMatch.sources[event.source_key] : undefined;
      const attributes = source?.attributes;

      const program = attributes?.find((attribute) => attribute.description === "Program")?.value;
      const otherInformation = attributes?.find((attribute) => attribute.description === "Other Information")?.value;
      const amendedDate = attributes?.find((attribute) => attribute.description === "Amended On")?.value;

      return Object.assign({ program, otherInformation, amendedDate }, event);
    });

    barePepEvents.sort(getSortMatchEventByRelevance(hasNewInfo));

    // Enrich PEP events with additional information from Comply Advantage sources
    const pepEvents: ComplyMatchEvent[] = barePepEvents
      .filter((event) => event.source_key !== "complyadvantage") // filter comply advantage sources
      .map((event) => {
        const source = event.source_key ? newMatch.sources[event.source_key] : undefined;
        const attributes = source?.attributes;

        const startDate = attributes?.find((attribute) => attribute.description === "active_start_date")?.value;
        // TODO replace "active_start_date" description once it's available
        // const removedDate = attributes?.find((attribute) => attribute.description === "active_start_date")?.value;
        const chamber = attributes?.find((attribute) => attribute.description === "Chamber")?.value;
        const pepFunction = attributes?.find((attribute) => attribute.description === "Function")?.value;
        const institutionType = attributes?.find((attribute) => attribute.description === "Institution Type")?.value;
        const otherInformation = attributes?.find((attribute) => attribute.description === "Other Info")?.value;

        return Object.assign(
          { startDate, /*removedDate*/ chamber, pepFunction, institutionType, otherInformation },
          event
        );
      });

    return { positions, pepEvents, sanctionEvents, adverseMediaEvents, otherEvents };
  }, [match.id]);

  const adverseMediaCategories = [...new Set(adverseMediaEvents.map((event) => event.sub_category.description))];

  const sources = match.sources.filter(isEmptyMatchSource);
  sources.sort(getSortMatchSourceByRelevance(hasNewInfo));

  return {
    subjectCountries,
    matchCountries,
    subjectAddresses,
    matchAddresses,
    subjectNationalities,
    matchNationalities,
    subjectPersonBirthDate,
    birthdates,
    riskography,
    links,
    remarks,
    adverseMediaCategories,
    pepEvents,
    positions,
    sanctionEvents,
    adverseMediaEvents,
    otherEvents,
    sources,
    hasNewInfo
  };
};

export default useComplyAdvantageMatch;
