import { Route, Switch } from "react-router-dom";

import { FeaturesTypes, PROJECT_FEATURES } from "../../constants/features";
import { ROUTES } from "../../constants/routes";
import AccessDenied from "../../pages/AccessDenied";
import CompanyChangeScreeningPage from "../../pages/CompanyChangeScreeningPage/CompanyChangeScreeningPage";
import CompanyDetailsPage from "../../pages/CompanyDetailsPage/CompanyDetailsPage";
import CompanyEditDisclosuresPage from "../../pages/CompanyRequestDisclosuresPage/CompanyEditDisclosuresPage";
import CustomerAssignmentDetailsPage from "../../pages/CustomerAssignmentDetailsPage/CustomerAssignmentDetailsPage";
import CustomerReviewPage from "../../pages/CustomerReviewPage/CustomerReviewPage";
import CustomersListPage from "../../pages/CustomersListPage/CustomersListPage";
import DashboardPage from "../../pages/DashboardPage/DashboardPage";
import Expired from "../../pages/Expired";
import ImportCustomersPage from "../../pages/ImportCustomersPage/ImportCustomersPage";
import LogoutRedirect from "../../pages/LogoutRedirect/LogoutRedirect";
import MatchPage from "../../pages/MatchPage/MatchPage";
import NotFound from "../../pages/NotFound";
import OnboardingCompanyAssignmentsPage from "../../pages/OnboardingCompanyAssignmentsPage/OnboardingCompanyAssignmentsPage";
import OnboardingCompanyDisclosuresPage from "../../pages/OnboardingCompanyDisclosuresPage/OnboardingCompanyDisclosuresPage";
import OnboardingCompanyLookupResultPage from "../../pages/OnboardingCompanyLookupResultPage/OnboardingCompanyLookupResultPage";
import OnboardingCompanyPage from "../../pages/OnboardingCompanyPage/OnboardingCompanyPage";
import OnboardingCompanyScreeningPage from "../../pages/OnboardingCompanyScreeningPage/OnboardingCompanyScreeningPage";
import OnboardingCompanySummaryPage from "../../pages/OnboardingCompanySummaryPage/OnboardingCompanySummaryPage";
import OnboardingManualCompanyPage from "../../pages/OnboardingManualCompanyPage/OnboardingManualCompanyPage";
import OnboardingPersonEditPage from "../../pages/OnboardingPersonEditPage/OnboardingPersonEditPage";
import OnboardingPersonNewPage from "../../pages/OnboardingPersonNewPage/OnboardingPersonNewPage";
import PersonChangeScreeningPage from "../../pages/PersonChangeScreeningPage/PersonChangeScreeningPage";
import PersonDetailsPage from "../../pages/PersonDetailsPage/PersonDetailsPage";
import PersonRequestDisclosurePage from "../../pages/PersonRequestDisclosurePage/PersonRequestDisclosurePage";
import PlanConversionProjectPage from "../../pages/PlanConversionProjectPage/PlanConversionProjectPage";
import ProjectDetailsPage from "../../pages/ProjectDetailsPage/ProjectDetailsPage";
import ProjectsListPage from "../../pages/ProjectsListPage/ProjectsListPage";
import SettingsAutoRemindersPage from "../../pages/SettingsAutoRemindersPage/SettingsAutoRemindersPage";
import SettingsCustomerIntoNotificationsPage from "../../pages/SettingsCustomerIntoNotificationsPage/SettingsCustomerIntoNotificationsPage";
import SettingsDisclosurePage from "../../pages/SettingsDisclosurePage/SettingsDisclosurePage";
import SettingsFollowupNotificationsPage from "../../pages/SettingsFollowupNotificationsPage/SettingsFollowupNotificationsPage";
import SettingsFollowupPage from "../../pages/SettingsFollowupPage/SettingsFollowupPage";
import SettingsGuidelinesPage from "../../pages/SettingsGuidelinesPage/SettingsGuidelinesPage";
import SettingsMeasuresPage from "../../pages/SettingsMeasuresPage/SettingsMeasuresPage";
import SettingsOnboardingPage from "../../pages/SettingsOnboardingPage/SettingsOnboardingPage";
import SettingsQuestionnaireDetailsPage from "../../pages/SettingsQuestionnaireDetailsPage/SettingsQuestionnaireDetailsPage";
import SettingsQuestionnaireListPage from "../../pages/SettingsQuestionnairesListPage/SettingsQuestionnairesListPage";
import SettingsReviewChecklistDetailsPage from "../../pages/SettingsReviewChecklistDetailsPage/SettingsReviewChecklistDetailsPage";
import SettingsReviewChecklistsListPage from "../../pages/SettingsReviewChecklistsListPage/SettingsReviewChecklistsListPage";
import SettingsTagsPage from "../../pages/SettingsTagsPage/SettingsTagsPage";
import SettingsUsersPage from "../../pages/SettingsUsersPage/SettingsUsersPage";
import RequireAuth from "../RequireAuth/RequireAuth";

import RedirectToCustomersList from "./RedirectToCustomersList";

const Routes = () => {
  return (
    <Switch>
      <Route
        path={ROUTES.SETTINGS_USERS}
        exact
        render={() => (
          <RequireAuth>
            <SettingsUsersPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_TAGS}
        exact
        render={() => (
          <RequireAuth>
            <SettingsTagsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_QUESTIONNAIRE_DETAILS}
        exact
        render={() => (
          <RequireAuth>
            <SettingsQuestionnaireDetailsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_QUESTIONNAIRES_LIST}
        exact
        render={() => (
          <RequireAuth>
            <SettingsQuestionnaireListPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_REVIEW_CHECKLIST_DETAILS_NEW}
        exact
        render={() => (
          <RequireAuth>
            <SettingsReviewChecklistDetailsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_REVIEW_CHECKLIST_DETAILS}
        exact
        render={() => (
          <RequireAuth>
            <SettingsReviewChecklistDetailsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_REVIEW_CHECKLISTS_LIST}
        exact
        render={() => (
          <RequireAuth>
            <SettingsReviewChecklistsListPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_FOLLOWUP}
        exact
        render={() => (
          <RequireAuth>
            <SettingsFollowupPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_FOLLOWUP_NOTIFICATIONS}
        exact
        render={() => (
          <RequireAuth>
            <SettingsFollowupNotificationsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_GUIDELINE}
        exact
        render={() => (
          <RequireAuth>
            <SettingsGuidelinesPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_AUTO_REMINDERS}
        exact
        render={() => (
          <RequireAuth>
            <SettingsAutoRemindersPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_MEASURES}
        exact
        render={() => (
          <RequireAuth>
            <SettingsMeasuresPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_ONBOARDING}
        exact
        render={() => (
          <RequireAuth>
            <SettingsOnboardingPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_DISCLOSURE}
        exact
        render={() => (
          <RequireAuth>
            <SettingsDisclosurePage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.SETTINGS_CUSTOMER_NOTIFICATIONS}
        exact
        render={() => (
          <RequireAuth>
            <SettingsCustomerIntoNotificationsPage />
          </RequireAuth>
        )}
      />
      <Route path={ROUTES.EXPIRED} exact>
        <Expired />
      </Route>
      <Route path={ROUTES.ACCESS_DENIED} exact>
        <AccessDenied />
      </Route>
      {/* Dashboard*/}
      <Route
        path={ROUTES.DASHBOARD}
        exact
        render={() => (
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        )}
      />
      {/* Person customer routes */}
      <Route
        path={ROUTES.PERSON_DETAILS}
        exact
        render={() => (
          <RequireAuth>
            <PersonDetailsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.PERSON_REQUEST_DISCLOSURES}
        exact
        render={() => (
          <RequireAuth>
            <PersonRequestDisclosurePage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.PERSON_CHANGE_SCREENING}
        exact
        render={() => (
          <RequireAuth>
            <PersonChangeScreeningPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/persons/:customerId/match-cases/:id"
        exact
        render={() => (
          <RequireAuth>
            <MatchPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.PERSON_ASSIGNMENT_REQUEST_DISCLOSURES}
        exact
        render={() => (
          <RequireAuth>
            <PersonRequestDisclosurePage />
          </RequireAuth>
        )}
      />
      {/*  Person customer onboarding routes */}
      <Route
        path={ROUTES.ONBOARDING_NEW_PERSON}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingPersonNewPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.ONBOARDING_SAVED_PERSON}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingPersonEditPage />
          </RequireAuth>
        )}
      />
      {/* Company customer routes */}
      <Route
        path={ROUTES.COMPANY_DETAILS}
        exact
        render={() => (
          <RequireAuth>
            <CompanyDetailsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_REQUEST_DISCLOSURES}
        exact
        render={() => (
          <RequireAuth>
            <CompanyEditDisclosuresPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_CHANGE_SCREENING}
        exact
        render={() => (
          <RequireAuth>
            <CompanyChangeScreeningPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/companies/:customerId/match-cases/:id"
        exact
        render={() => (
          <RequireAuth>
            <MatchPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_ASSIGNMENT_REQUEST_DISCLOSURES}
        exact
        render={() => (
          <RequireAuth>
            <CompanyEditDisclosuresPage />
          </RequireAuth>
        )}
      />
      {/* Company customer onboarding routes */}
      <Route
        path={ROUTES.COMPANY_LOOKUP}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_LOOKUP_RESULT}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyLookupResultPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_ONBOARDING_SCREENING}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyScreeningPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_ONBOARDING_ASSIGNMENTS}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyAssignmentsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_ONBOARDING_DISCLOSURES}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyDisclosuresPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.COMPANY_ONBOARDING_SUMMARY}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanySummaryPage />
          </RequireAuth>
        )}
      />
      {/* Manual company customer onboarding routes */}
      <Route
        path={ROUTES.MANUAL_COMPANY_ONBOARDING_EDIT}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingManualCompanyPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyScreeningPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.MANUAL_COMPANY_ONBOARDING_ASSIGNMENTS}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyAssignmentsPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.MANUAL_COMPANY_ONBOARDING_DISCLOSURES}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanyDisclosuresPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.MANUAL_COMPANY_ONBOARDING_SUMMARY}
        exact
        render={() => (
          <RequireAuth>
            <OnboardingCompanySummaryPage />
          </RequireAuth>
        )}
      />
      {/* Match case route */}
      <Route
        path={ROUTES.MATCH_CASE_DETAILS}
        exact
        render={() => (
          <RequireAuth>
            <MatchPage />
          </RequireAuth>
        )}
      />
      {/* Assignment details route */}
      <Route
        path={ROUTES.ASSIGNMENT_DETAILS}
        exact
        render={() => (
          <RequireAuth>
            <CustomerAssignmentDetailsPage />
          </RequireAuth>
        )}
      />
      {/* Customer Review */}
      <Route
        path={ROUTES.CUSTOMER_REVIEW_NEW}
        exact
        render={() => (
          <RequireAuth>
            <CustomerReviewPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.CUSTOMER_REVIEW}
        exact
        render={() => (
          <RequireAuth>
            <CustomerReviewPage />
          </RequireAuth>
        )}
      />
      {/* Customer Assignment Review */}
      <Route
        path={ROUTES.CUSTOMER_ASSIGNMENT_REVIEW_NEW}
        exact
        render={() => (
          <RequireAuth>
            <CustomerReviewPage />
          </RequireAuth>
        )}
      />
      {/* Customer list routes */}
      <Route path={ROUTES.HOME} exact render={() => <RedirectToCustomersList />} />

      <Route path={ROUTES.CUSTOMERS}>
        <RequireAuth>
          <CustomersListPage />
        </RequireAuth>
      </Route>

      {/* Projects */}
      <Route
        path={ROUTES.PROJECTS_LIST}
        exact
        render={() => (
          <RequireAuth requiredFeatures={PROJECT_FEATURES}>
            <ProjectsListPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.PLAN_UPGRADE_PROJECTS_LIST}
        exact
        render={() => (
          <RequireAuth>
            <PlanConversionProjectPage />
          </RequireAuth>
        )}
      />
      <Route
        path={ROUTES.PROJECT_DETAILS}
        exact
        render={() => (
          <RequireAuth requiredFeatures={PROJECT_FEATURES}>
            <ProjectDetailsPage />
          </RequireAuth>
        )}
      />
      {/* Import */}
      <Route
        path={ROUTES.IMPORT_CUSTOMERS}
        exact
        render={() => (
          <RequireAuth requiredFeatures={[FeaturesTypes.IMPORT_WIZARD]}>
            <ImportCustomersPage />
          </RequireAuth>
        )}
      />
      <Route path={ROUTES.LOGOUT} render={() => <LogoutRedirect />} />

      {/* If we end up here it's a 404 */}
      <Route render={() => <NotFound />} />
    </Switch>
  );
};

export default Routes;
