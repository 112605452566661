import api from "../api";
import { MessageKey } from "../providers/LanguageProvider";

const RoleTypes: Record<api.RoleType | "null", MessageKey> = {
  null: "common.roles.null",
  UNKNOWN_ROLE: "common.roles.UNKNOWN_ROLE",
  CEO: "common.roles.CEO",
  PROPRIETOR: "common.roles.PROPRIETOR",
  CHAIRMAN: "common.roles.CHAIRMAN",
  DEPUTY_CHAIRMAN: "common.roles.DEPUTY_CHAIRMAN",
  BOARD_MEMBER: "common.roles.BOARD_MEMBER",
  ALTERNATE_BOARD_MEMBER: "common.roles.ALTERNATE_BOARD_MEMBER",
  CONTACT_PERSON: "common.roles.CONTACT_PERSON",
  MANAGER: "common.roles.MANAGER",
  MANAGING_DIRECTOR: "common.roles.MANAGING_DIRECTOR",
  ACCOUNTANT: "common.roles.ACCOUNTANT",
  AUDITOR: "common.roles.AUDITOR",
  TRUSTEE: "common.roles.TRUSTEE",
  NORWEGIAN_REPRESENTATIVE: "common.roles.NORWEGIAN_REPRESENTATIVE",
  // This role doesn't require entering date of birth when creating a new role
  REPRESENTATIVE: "common.roles.REPRESENTATIVE",
  OBSERVER: "common.roles.OBSERVER",
  PARTICIPANT_PART_RESPONSIBILITY: "common.roles.PARTICIPANT_PART_RESPONSIBILITY",
  PARTICIPANT_FULL_RESPONSIBILITY: "common.roles.PARTICIPANT_FULL_RESPONSIBILITY",
  COMPLEMENTARY: "common.roles.COMPLEMENTARY",
  SIGNATURE: "common.roles.SIGNATURE",
  SIGNATURE_JOINTLY: "common.roles.SIGNATURE_JOINTLY",
  SIGNATURE_SEPARATELY: "common.roles.SIGNATURE_SEPARATELY",
  PROCURA: "common.roles.PROCURA",
  PROCURA_JOINTLY: "common.roles.PROCURA_JOINTLY",
  PROCURA_SEPARATELY: "common.roles.PROCURA_SEPARATELY",
  // DEPRECATED
  DEPUTY_BOARD_MEMBER: "common.roles.DEPUTY_BOARD_MEMBER"
};

export default RoleTypes;
