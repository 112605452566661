import React, { useState } from "react";
import { Banner, HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import AddFileButton from "../../components/AddFileButton/AddFileButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import AttachmentsSection from "../../components/AttachmentsSection/AttachmentsSection";
import BackButton from "../../components/BackButton/BackButton";
import CardNavigationSection from "../../components/CardNavigationSection/CardNavigationSection";
import DetailsSkeleton from "../../components/DetailsSkeleton/DetailsSkeleton";
import ExternalNavigationLink from "../../components/ExternalNavigationLink/ExternalNavigationLink";
import ProjectNotesSection from "../../components/ProjectNotesSection/ProjectNotesSection";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isVitecProject } from "../../helpers/integration.helpers";
import useAppTitle from "../../hooks/useAppTitle";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useSetLocationState from "../../hooks/useSetLocationState";

import BasicProjectInfo from "./BasicProjectInfo/BasicProjectInfo";
import ProjectActionsMenu from "./ProjectActionsMenu/ProjectActionsMenu";
import ProjectCustomersSection from "./ProjectCustomersSection/ProjectCustomersSection";
import useProjectDetailsBackButton from "./useProjectDetailsBackButton";

const ProjectDetailsPage = ({ data: project }: AsyncPageChild<api.Project>) => {
  useAppTitle(project.name);
  const f = useFormatMessage();
  const features = useFeatures();
  const { backButtonURL } = useProjectDetailsBackButton();

  const isVitec = isVitecProject(project);

  useSetLocationState("fromProject", { id: project.id, name: project.name });

  const [uploadingFilesCount, setUploadingFilesCount] = useState(0);

  const showAttachments = !isVitec && features.ATTACHMENTS;
  const showNotes = !isVitec;

  const readonly = project.status !== "ACTIVE";

  return (
    <Page>
      <VerticalStack gap="8">
        <CardNavigationSection anchor={PAGE_ANCHORS.TOP_SECTION}>
          <VerticalStack gap="4">
            <BackButton url={backButtonURL} displayLabel={f("projects.page.title")} showAsLink />
            {isVitec && (
              <Banner title={f("project.banner.vitec.label")} status="info">
                <p>{f("project.banner.vitec.imported.text")}</p>
              </Banner>
            )}
            <HorizontalStack blockAlign={"baseline"} align={"space-between"}>
              <HorizontalStack gap="4" blockAlign="baseline">
                <Text variant="heading2xl" as="p">
                  {project.name}
                </Text>
                {isVitec && (
                  <ExternalNavigationLink url={project.integrations?.vitec_data?.vitec_url}>
                    {f("project.button.imported-from-vitec")}
                  </ExternalNavigationLink>
                )}
              </HorizontalStack>
              <ProjectActionsMenu project={project} readonly={readonly} />
            </HorizontalStack>
            <BasicProjectInfo project={project} readonly={readonly} />
          </VerticalStack>
        </CardNavigationSection>

        <CardNavigationSection>
          <ProjectCustomersSection project={project} readonly={readonly} />
        </CardNavigationSection>

        {showAttachments && (
          <CardNavigationSection>
            <AttachmentsSection
              entity={project}
              readonly={readonly}
              uploadingFilesCount={uploadingFilesCount}
              fetchSuggestedSigners={() =>
                api.getProjectCustomersDetails(project.id).then((response) => response.customers)
              }
            >
              <AddFileButton
                entity={project}
                onFileUploadingStart={(filesCount) => setUploadingFilesCount(filesCount)}
                onFileUploadingFinish={() => setUploadingFilesCount(0)}
              />
            </AttachmentsSection>
          </CardNavigationSection>
        )}

        {showNotes && (
          <CardNavigationSection>
            <ProjectNotesSection project={project} readonly={readonly} />
          </CardNavigationSection>
        )}
      </VerticalStack>
    </Page>
  );
};

export default withAsyncPage<api.Project>(ProjectDetailsPage, {
  queryKey: QUERIES_KEYS.PROJECT_DETAILS,
  apiFunction: api.getProject,
  paramNames: ["id"],
  skeleton: <DetailsSkeleton />
});
