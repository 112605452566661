import React, { useMemo } from "react";
import { Button, DropZone, VerticalStack } from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isQuestionnaireActive } from "../../helpers/questionnaire.helpers";
import useGetQuestionnairesSummary from "../../hooks/useGetQuestionnairesSummary";
import useSnackbar from "../../hooks/useSnackbar";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

const ImportQuestionnairesButton = () => {
  const { setSnackbar } = useSnackbar();
  const { data } = useGetQuestionnairesSummary();

  const queryClient = useQueryClient();

  const existingNames = useMemo(
    () => data?.questionnaire_summaries.filter(isQuestionnaireActive).map((questionnaire) => questionnaire.name) || [],
    [data]
  );

  const getValidQuestionnaireName = (name: string) => (existingNames.includes(name) ? `imported-${name}` : name);

  const createQuestionnaireMutation = useMutation<unknown, unknown, File[]>(
    (files) => Promise.all(files.map(handleFile)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES_KEYS.QUESTIONNAIRES_SUMMARY]);
        queryClient.invalidateQueries([QUERIES_KEYS.QUESTIONNAIRES]);
        //  No need for translation since this action is used to move questionnaires between tenants
        setSnackbar("Imported successfully.");
      }
    }
  );

  const handleFilesUpload = async (files: File[]) => {
    createQuestionnaireMutation.mutate(files);
  };

  const handleFile = async (file: File) => {
    return new Promise<api.Questionnaire>((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            const questionnaire = JSON.parse(reader.result as string) as api.CreateQuestionnaireRequest;

            const createdQuestionnaire = await api.createQuestionnaire({
              ...questionnaire,
              name: getValidQuestionnaireName(questionnaire.name)
            });

            resolve(createdQuestionnaire);
          },
          false
        );

        if (file) {
          reader.readAsText(file);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <VerticalStack gap="1">
      <DropZone
        outline={false}
        variableHeight
        type="file"
        accept={"application/json"}
        onDropAccepted={handleFilesUpload}
      >
        {/* No need for translation since this button is used to move questionnaires between tenants*/}
        <Button loading={createQuestionnaireMutation.isLoading}>Import</Button>
      </DropZone>
      {createQuestionnaireMutation.isError && <ErrorPanel message={createQuestionnaireMutation.error} />}
    </VerticalStack>
  );
};

export default ImportQuestionnairesButton;
