import { forwardRef } from "react";
import styled from "styled-components";

import ChevronDown from "../../../assets/icons/chevron-down.svg";
import Button, { ButtonProps } from "../extensions/Button";

interface ExpandButtonProps extends ButtonProps {
  expanded: boolean;
  onChange(expanded: boolean): void;
}
const ExpandButton = forwardRef<HTMLSpanElement, ExpandButtonProps>((props, ref) => {
  const { expanded, onChange, ...rest } = props;

  return (
    <StyledButton
      plain
      removeUnderline
      icon={ChevronDown}
      hoverable
      expanded={expanded}
      onClick={() => onChange(!expanded)}
      ref={ref}
      {...rest}
    />
  );
});

const StyledButton = styled(Button)<{ expanded?: boolean }>`
  --rotation: ${({ expanded }) => (expanded ? "0deg" : "-90deg")};

  & img {
    transform: rotate(var(--rotation));
    transition: transform var(--p-ease) var(--p-duration-150);
  }
`;

export default ExpandButton;
