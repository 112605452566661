import React from "react";
import { Stack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import { PersonInformation, PersonInformationKeys, ValueOf } from "../../types/utilities";
import CountrySelect from "../CountrySelect/CountrySelect";

interface PersonCountriesProps {
  value: PersonInformation;
  disabled?: boolean;
  requiredFields: PersonInformationKeys;
  fieldsErrorMap?: Map<keyof PersonInformation, string>;
  onChange(field: keyof PersonInformation, newValue?: ValueOf<PersonInformation>): void;
}

const PersonCountries = (props: PersonCountriesProps) => {
  const { value, disabled, requiredFields, fieldsErrorMap = new Map(), onChange } = props;

  const f = useFormatMessage();

  return (
    <Stack distribution={"fillEvenly"}>
      <CountrySelect
        value={value.country_of_residence}
        onSelect={(newValue) => onChange("country_of_residence", newValue)}
        disabled={disabled}
        error={fieldsErrorMap.get("country_of_residence")}
        label={f("common.labels.country-of-residence")}
        requiredIndicator={requiredFields.includes("country_of_residence")}
        clearButton={false}
      />
      <CountrySelect
        value={value.country_of_origin}
        onSelect={(newValue) => onChange("country_of_origin", newValue)}
        disabled={disabled}
        error={fieldsErrorMap.get("country_of_origin")}
        label={f("common.labels.country-of-origin")}
        requiredIndicator={requiredFields.includes("country_of_origin")}
        clearButton={false}
      />
      <CountrySelect
        value={value.country_of_citizenship}
        onSelect={(newValue) => onChange("country_of_citizenship", newValue)}
        disabled={disabled}
        error={fieldsErrorMap.get("country_of_citizenship")}
        label={f("common.labels.citizenship")}
        requiredIndicator={requiredFields.includes("country_of_citizenship")}
        clearButton={false}
      />
    </Stack>
  );
};

export default PersonCountries;
