import { matchPath, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import api from "../../../../api";
import { QUERIES_KEYS } from "../../../../constants/queries-keys";
import { ROUTES } from "../../../../constants/routes";
import useAuth from "../../../../hooks/useAuth";
import HistoryLocationState from "../../../../types/HistoryLocationState";

const POLLING_INTERVAL = 1_000 * 60 * 15; // poll every 15 minutes

const useStatusMessage = () => {
  const location = useLocation<HistoryLocationState>();
  const { isAuthenticated } = useAuth();

  const isInExpiredOrAccessDeniedPage =
    matchPath(location.pathname, { path: [ROUTES.EXPIRED, ROUTES.ACCESS_DENIED] }) !== null;

  const { data } = useQuery([QUERIES_KEYS.STATUS_MESSAGE], () => api.getStatusMessageList(), {
    enabled: isAuthenticated && !isInExpiredOrAccessDeniedPage,
    refetchInterval: POLLING_INTERVAL
  });

  // we currently only show the first message
  const statusMessage = data?.status_messages[0];

  const showBanner = statusMessage !== undefined;

  return { showBanner, statusMessage };
};

export default useStatusMessage;
