import { generatePath, useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { ROUTES } from "../constants/routes";
import { isReviewApprovalRequired } from "../helpers/reviews.helpers";
import useQueryData, { useCustomerDetailsQueryData } from "../hooks/useQueryData";
import {
  convertToCreateCustomerReviewRequest,
  convertToCreateDraftCustomerReviewRequest
} from "../pages/CustomerReviewPage/CustomerReviewPage.helpers";
import { OptionalCustomerReviewRequest, Review } from "../types/CustomerReview";
import { CustomerDetails, getCustomerQueryKey } from "../types/utilities";
import { updateItem } from "../utils/collectionUtils";

type ReviewAction = "save" | "complete";

const useCustomerReviewMutations = (
  customer: CustomerDetails,
  review: OptionalCustomerReviewRequest,
  reviewId?: string
) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const updateCustomerQueryData = useCustomerDetailsQueryData(customer);
  const { updateQueryData: updateReviewQueryData } = useQueryData<CustomerDetails>([
    QUERIES_KEYS.CUSTOMER_REVIEW,
    customer.id,
    reviewId
  ]);

  // search for an existing review in the pending reviews list first and then in the regular reviews list
  const isNew = !reviewId || !customer.reviews.some((review) => review.id === reviewId);

  const onAfterReviewMutation = (updatedReview: Review, actionType: ReviewAction) => {
    if (actionType === "complete") {
      // update customer details and review details
      // if the action type is complete and the review doesn't require an approval
      if (!isReviewApprovalRequired(updatedReview)) {
        updateCustomerQueryData((queryData) => {
          queryData.review_status = updatedReview.accepted ? "APPROVED" : "REJECTED";
          queryData.risk_level = updatedReview.risk_level || "NOT_SET";
          queryData.is_pep = Boolean(updatedReview.is_pep);
          queryData.is_sanctioned = Boolean(updatedReview.is_sanctioned);
        });

        updateReviewQueryData((queryData) => {
          queryData.review_status = updatedReview.accepted ? "APPROVED" : "REJECTED";
          queryData.risk_level = updatedReview.risk_level || "NOT_SET";
          queryData.is_pep = Boolean(updatedReview.is_pep);
          queryData.is_sanctioned = Boolean(updatedReview.is_sanctioned);
        });
      }

      // invalidate the entire customer details query in order to refresh the follow-up date
      queryClient.invalidateQueries(getCustomerQueryKey(customer));
      queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMER_REVIEW, customer.id, reviewId]);
    }
  };

  const createReviewMutation = (actionType: ReviewAction) =>
    useMutation(
      () =>
        actionType === "complete"
          ? api.createCustomerReview(customer.id, convertToCreateCustomerReviewRequest(review))
          : api.createDraftCustomerReview(customer.id, convertToCreateDraftCustomerReviewRequest(review)),
      {
        onSuccess: (newReview) => {
          updateCustomerQueryData((queryData) => (queryData.reviews = [newReview, ...queryData.reviews]));

          // this is the first time a new review is saved - so set a new customer review data
          queryClient.setQueryData([QUERIES_KEYS.CUSTOMER_REVIEW, customer.id, newReview.id], {
            ...customer,
            reviews: [newReview, ...customer.reviews]
          });

          onAfterReviewMutation(newReview, actionType);
          // replace the new customer review URL with the created review id
          if (actionType === "save") {
            history.replace(generatePath(ROUTES.CUSTOMER_REVIEW, { customerId: customer.id, id: newReview.id }));
          }
        }
      }
    );

  const updateReviewMutation = (actionType: ReviewAction) =>
    useMutation(
      () =>
        actionType === "complete"
          ? api.updateCustomerReview(customer.id, reviewId!, convertToCreateCustomerReviewRequest(review))
          : api.updateDraftCustomerReview(customer.id, reviewId!, convertToCreateDraftCustomerReviewRequest(review)),
      {
        onSuccess: (updatedReview) => {
          updateCustomerQueryData((queryData) => (queryData.reviews = updateItem(queryData.reviews, updatedReview)));
          updateReviewQueryData((queryData) => (queryData.reviews = updateItem(queryData.reviews, updatedReview)));
          onAfterReviewMutation(updatedReview, actionType);
        }
      }
    );

  const createMutation = (actionType: ReviewAction) => {
    return isNew ? createReviewMutation(actionType) : updateReviewMutation(actionType);
  };

  const saveMutation = createMutation("save");
  const completeMutation = createMutation("complete");

  const isLoading = saveMutation.isLoading || completeMutation.isLoading;

  return {
    isLoading,
    saveMutation,
    completeMutation
  };
};

export default useCustomerReviewMutations;
