import React, { useState } from "react";
import { HorizontalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import { CustomerDetails } from "../../types/utilities";
import CustomerReviewApproveConfirm from "../CustomerReviewApproveConfirm/CustomerReviewApproveConfirm";
import CustomerReviewRejectConfirm from "../CustomerReviewRejectConfirm/CustomerReviewRejectConfirm";
import Button from "../extensions/Button";

type ActionType = "approve" | "reject";

export type CustomerReviewApproveRejectToolbarProps = {
  customer: CustomerDetails;
  reviewId: string;
  onBackNavigation(): void;
};

const CustomerReviewApproveRejectToolbar = (props: CustomerReviewApproveRejectToolbarProps) => {
  const { customer, reviewId, onBackNavigation } = props;

  const [action, setAction] = useState<ActionType>();
  const clearAction = () => setAction(undefined);

  const f = useFormatMessage();

  const onSuccess = () => {
    clearAction();
    onBackNavigation();
  };

  return (
    <HorizontalStack align={"space-between"} blockAlign={"center"}>
      <Button onClick={onBackNavigation}>{f("default.cancel")}</Button>
      <HorizontalStack gap="4" blockAlign={"center"}>
        <Button destructive onClick={() => setAction("reject")}>
          {f("details.reviews.confirm.reject")}
        </Button>
        <StyledConfirmButton primary monochrome onClick={() => setAction("approve")}>
          {f("details.reviews.confirm.approve")}
        </StyledConfirmButton>
      </HorizontalStack>
      {action === "approve" && (
        <CustomerReviewApproveConfirm
          customer={customer}
          reviewId={reviewId}
          onSuccess={onSuccess}
          onCancel={clearAction}
        />
      )}
      {action === "reject" && (
        <CustomerReviewRejectConfirm
          customer={customer}
          reviewId={reviewId}
          onSuccess={onSuccess}
          onCancel={clearAction}
        />
      )}
    </HorizontalStack>
  );
};

const StyledConfirmButton = styled(Button)`
  & .Polaris-Button--primary {
    --pc-button-color: var(--kdk-dark-success);

    &:hover {
      background: var(--kdk-dark-success);
      filter: brightness(0.9);
    }
    &:active {
      background: var(--kdk-dark-success);
      filter: brightness(0.8);
    }
  }
`;

export default CustomerReviewApproveRejectToolbar;
