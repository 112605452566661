{
  "Polaris": {
    "ActionMenu": {
      "Actions": {
        "moreActions": "More actions"
      },
      "RollupActions": {
        "rollupButton": "View actions"
      }
    },
    "Avatar": {
      "label": "Avatar",
      "labelWithInitials": "Avatar with initials {initials}"
    },
    "Autocomplete": {
      "spinnerAccessibilityLabel": "Loading",
      "ellipsis": "{content}…"
    },
    "Badge": {
      "PROGRESS_LABELS": {
        "incomplete": "Incomplete",
        "partiallyComplete": "Partially complete",
        "complete": "Complete"
      },
      "STATUS_LABELS": {
        "info": "Info",
        "success": "Success",
        "warning": "Warning",
        "critical": "Critical",
        "attention": "Attention",
        "new": "New"
      },
      "progressAndStatus": "{statusLabel} {progressLabel}"
    },
    "Button": {
      "spinnerAccessibilityLabel": "Loading",
      "connectedDisclosureAccessibilityLabel": "Related actions"
    },
    "Common": {
      "checkbox": "checkbox",
      "undo": "Undo",
      "cancel": "Cancel",
      "clear": "Clear",
      "close": "Close",
      "submit": "Submit",
      "more": "More"
    },
    "ContextualSaveBar": {
      "save": "Save",
      "discard": "Discard"
    },
    "DataTable": {
      "sortAccessibilityLabel": "sort {direction} by",
      "navAccessibilityLabel": "Scroll table {direction} one column",
      "totalsRowHeading": "Totals",
      "totalRowHeading": "Total"
    },
    "DatePicker": {
      "previousMonth": "Show previous month, {previousMonthName} {showPreviousYear}",
      "nextMonth": "Show next month, {nextMonth} {nextYear}",
      "today": "Today ",
      "start": "Start of range",
      "end": "End of range",
      "months": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
      },
      "days": {
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday"
      },
      "daysAbbreviated": {
        "monday": "Mo",
        "tuesday": "Tu",
        "wednesday": "We",
        "thursday": "Th",
        "friday": "Fr",
        "saturday": "Sa",
        "sunday": "Su"
      }
    },
    "DiscardConfirmationModal": {
      "title": "Discard all unsaved changes",
      "message": "If you discard changes, you’ll delete any edits you made since you last saved.",
      "primaryAction": "Discard changes",
      "secondaryAction": "Continue editing"
    },
    "DropZone": {
      "single": {
        "overlayTextFile": "Drop file to upload",
        "overlayTextImage": "Drop image to upload",
        "overlayTextVideo": "Drop video to upload",
        "actionTitleFile": "Add file",
        "actionTitleImage": "Add image",
        "actionTitleVideo": "Add video",
        "actionHintFile": "or drop file to upload",
        "actionHintImage": "or drop image to upload",
        "actionHintVideo": "or drop video to upload",
        "labelFile": "Upload file",
        "labelImage": "Upload image",
        "labelVideo": "Upload video"
      },
      "allowMultiple": {
        "overlayTextFile": "Drop files to upload",
        "overlayTextImage": "Drop images to upload",
        "overlayTextVideo": "Drop videos to upload",
        "actionTitleFile": "Add files",
        "actionTitleImage": "Add images",
        "actionTitleVideo": "Add videos",
        "actionHintFile": "or drop files to upload",
        "actionHintImage": "or drop images to upload",
        "actionHintVideo": "or drop videos to upload",
        "labelFile": "Upload files",
        "labelImage": "Upload images",
        "labelVideo": "Upload videos"
      },
      "errorOverlayTextFile": "File type is not valid",
      "errorOverlayTextImage": "Image type is not valid",
      "errorOverlayTextVideo": "Video type is not valid"
    },
    "EmptySearchResult": {
      "altText": "Empty search results"
    },
    "Frame": {
      "skipToContent": "Skip to content",
      "navigationLabel": "Navigation",
      "Navigation": {
        "closeMobileNavigationLabel": "Close navigation"
      }
    },
    "FullscreenBar": {
      "back": "Back",
      "accessibilityLabel": "Exit fullscreen mode"
    },
    "Filters": {
      "moreFilters": "More filters",
      "moreFiltersWithCount": "More filters ({count})",
      "filter": "Filter {resourceName}",
      "noFiltersApplied": "No filters applied",
      "cancel": "Cancel",
      "done": "Done",
      "clearAllFilters": "Clear all filters",
      "clear": "Clear",
      "clearLabel": "Clear {filterName}",
      "addFilter": "Add filter",
      "clearFilters": "Clear all"
    },
    "FilterPill": {
      "clear": "Clear"
    },
    "IndexFilters": {
      "searchFilterTooltip": "Search and filter (F)",
      "searchFilterAccessibilityLabel": "Search and filter results",
      "sort": "Sort your results",
      "addView": "Add a new view",
      "newView": "Custom search",
      "SortButton": {
        "ariaLabel": "Sort the results",
        "tooltip": "Sort",
        "title": "Sort by",
        "sorting": {
          "asc": "Ascending",
          "desc": "Descending",
          "az": "A-Z",
          "za": "Z-A"
        }
      },
      "UpdateButtons": {
        "cancel": "Cancel",
        "update": "Update",
        "save": "Save",
        "saveAs": "Save as",
        "modal": {
          "title": "Save view as",
          "label": "Name",
          "sameName": "A view with this name already exists. Please choose a different name.",
          "save": "Save",
          "cancel": "Cancel"
        }
      }
    },
    "IndexProvider": {
      "defaultItemSingular": "Item",
      "defaultItemPlural": "Items",
      "allItemsSelected": "All {itemsLength}+ {resourceNamePlural} are selected",
      "selected": "{selectedItemsCount} selected",
      "a11yCheckboxDeselectAllSingle": "Deselect {resourceNameSingular}",
      "a11yCheckboxSelectAllSingle": "Select {resourceNameSingular}",
      "a11yCheckboxDeselectAllMultiple": "Deselect all {itemsLength} {resourceNamePlural}",
      "a11yCheckboxSelectAllMultiple": "Select all {itemsLength} {resourceNamePlural}"
    },
    "IndexTable": {
      "emptySearchTitle": "No {resourceNamePlural} found",
      "emptySearchDescription": "Try changing the filters or search term",
      "onboardingBadgeText": "New",
      "resourceLoadingAccessibilityLabel": "Loading {resourceNamePlural}…",
      "selectAllLabel": "Select all {resourceNamePlural}",
      "selected": "{selectedItemsCount} selected",
      "undo": "Undo",
      "selectAllItems": "Select all {itemsLength}+ {resourceNamePlural}",
      "selectItem": "Select {resourceName}",
      "selectButtonText": "Select",
      "sortAccessibilityLabel": "sort {direction} by"
    },
    "Loading": {
      "label": "Page loading bar"
    },
    "Modal": {
      "iFrameTitle": "body markup",
      "modalWarning": "These required properties are missing from Modal: {missingProps}"
    },
    "Page": {
      "Header": {
        "rollupActionsLabel": "View actions for {title}"
      }
    },
    "Pagination": {
      "previous": "Previous",
      "next": "Next",
      "pagination": "Pagination"
    },
    "ProgressBar": {
      "negativeWarningMessage": "Values passed to the progress prop shouldn’t be negative. Resetting {progress} to 0.",
      "exceedWarningMessage": "Values passed to the progress prop shouldn’t exceed 100. Setting {progress} to 100."
    },
    "ResourceList": {
      "sortingLabel": "Sort by",
      "defaultItemSingular": "item",
      "defaultItemPlural": "items",
      "showing": "Showing {itemsCount} {resource}",
      "showingTotalCount": "Showing {itemsCount} of {totalItemsCount} {resource}",
      "loading": "Loading {resource}",
      "selected": "{selectedItemsCount} selected",
      "allItemsSelected": "All {itemsLength}+ {resourceNamePlural} in your store are selected",
      "allFilteredItemsSelected": "All {itemsLength}+ {resourceNamePlural} in this filter are selected",
      "selectAllItems": "Select all {itemsLength}+ {resourceNamePlural} in your store",
      "selectAllFilteredItems": "Select all {itemsLength}+ {resourceNamePlural} in this filter",
      "emptySearchResultTitle": "No {resourceNamePlural} found",
      "emptySearchResultDescription": "Try changing the filters or search term",
      "selectButtonText": "Select",
      "a11yCheckboxDeselectAllSingle": "Deselect {resourceNameSingular}",
      "a11yCheckboxSelectAllSingle": "Select {resourceNameSingular}",
      "a11yCheckboxDeselectAllMultiple": "Deselect all {itemsLength} {resourceNamePlural}",
      "a11yCheckboxSelectAllMultiple": "Select all {itemsLength} {resourceNamePlural}",
      "Item": {
        "actionsDropdownLabel": "Actions for {accessibilityLabel}",
        "actionsDropdown": "Actions dropdown",
        "viewItem": "View details for {itemName}"
      },
      "BulkActions": {
        "actionsActivatorLabel": "Actions",
        "moreActionsActivatorLabel": "More actions",
        "warningMessage": "To provide a better user experience. There should only be a maximum of {maxPromotedActions} promoted actions."
      }
    },
    "SkeletonPage": {
      "loadingLabel": "Page loading"
    },
    "Tabs": {
      "newViewAccessibilityLabel": "Create new view",
      "newViewTooltip": "Create view",
      "toggleTabsLabel": "More views",
      "Tab": {
        "rename": "Rename view",
        "duplicate": "Duplicate view",
        "edit": "Edit view",
        "editColumns": "Edit columns",
        "delete": "Delete view",
        "copy": "Copy of {name}",
        "deleteModal": {
          "title": "Delete view?",
          "description": "This can’t be undone. {viewName} view will no longer be available in your admin.",
          "cancel": "Cancel",
          "delete": "Delete view"
        }
      },
      "RenameModal": {
        "title": "Rename view",
        "label": "Name",
        "cancel": "Cancel",
        "create": "Save",
        "errors": {
          "sameName": "A view with this name already exists. Please choose a different name."
        }
      },
      "DuplicateModal": {
        "title": "Duplicate view",
        "label": "Name",
        "cancel": "Cancel",
        "create": "Create view",
        "errors": {
          "sameName": "A view with this name already exists. Please choose a different name."
        }
      },
      "CreateViewModal": {
        "title": "Create new view",
        "label": "Name",
        "cancel": "Cancel",
        "create": "Create view",
        "errors": {
          "sameName": "A view with this name already exists. Please choose a different name."
        }
      }
    },
    "Tag": {
      "ariaLabel": "Remove {children}"
    },
    "TextField": {
      "characterCount": "{count} characters",
      "characterCountWithMaxLength": "{count} of {limit} characters used"
    },
    "TooltipOverlay": {
      "accessibilityLabel": "Tooltip: {label}"
    },
    "TopBar": {
      "toggleMenuLabel": "Toggle menu",
      "SearchField": {
        "clearButtonLabel": "Clear",
        "search": "Search"
      }
    },
    "MediaCard": {
      "dismissButton": "Dismiss",
      "popoverButton": "Actions"
    },
    "VideoThumbnail": {
      "playButtonA11yLabel": {
        "default": "Play video",
        "defaultWithDuration": "Play video of length {duration}",
        "duration": {
          "hours": {
            "other": {
              "only": "{hourCount} hours",
              "andMinutes": "{hourCount} hours and {minuteCount} minutes",
              "andMinute": "{hourCount} hours and {minuteCount} minute",
              "minutesAndSeconds": "{hourCount} hours, {minuteCount} minutes, and {secondCount} seconds",
              "minutesAndSecond": "{hourCount} hours, {minuteCount} minutes, and {secondCount} second",
              "minuteAndSeconds": "{hourCount} hours, {minuteCount} minute, and {secondCount} seconds",
              "minuteAndSecond": "{hourCount} hours, {minuteCount} minute, and {secondCount} second",
              "andSeconds": "{hourCount} hours and {secondCount} seconds",
              "andSecond": "{hourCount} hours and {secondCount} second"
            },
            "one": {
              "only": "{hourCount} hour",
              "andMinutes": "{hourCount} hour and {minuteCount} minutes",
              "andMinute": "{hourCount} hour and {minuteCount} minute",
              "minutesAndSeconds": "{hourCount} hour, {minuteCount} minutes, and {secondCount} seconds",
              "minutesAndSecond": "{hourCount} hour, {minuteCount} minutes, and {secondCount} second",
              "minuteAndSeconds": "{hourCount} hour, {minuteCount} minute, and {secondCount} seconds",
              "minuteAndSecond": "{hourCount} hour, {minuteCount} minute, and {secondCount} second",
              "andSeconds": "{hourCount} hour and {secondCount} seconds",
              "andSecond": "{hourCount} hour and {secondCount} second"
            }
          },
          "minutes": {
            "other": {
              "only": "{minuteCount} minutes",
              "andSeconds": "{minuteCount} minutes and {secondCount} seconds",
              "andSecond": "{minuteCount} minutes and {secondCount} second"
            },
            "one": {
              "only": "{minuteCount} minute",
              "andSeconds": "{minuteCount} minute and {secondCount} seconds",
              "andSecond": "{minuteCount} minute and {secondCount} second"
            }
          },
          "seconds": {
            "other": "{secondCount} seconds",
            "one": "{secondCount} second"
          }
        }
      }
    }
  }
}
