import React, { useState } from "react";
import { Card, Collapsible, HorizontalStack, RadioButton, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS, getChecklistConditionType } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useTags from "../../hooks/useTags";
import { ReviewChecklistConditionType } from "../../types/ReviewChecklist";
import { ValueOf } from "../../types/utilities";
import { first, removeItem } from "../../utils/collectionUtils";
import DisplayTagsWrapper from "../DisplayTagsWrapper/DisplayTagsWrapper";
import TagsEditorPopover from "../TagsEditorPopover/TagsEditorPopover";

import ReviewChecklistPurposesConditionsEditor from "./ReviewChecklistPurposesConditionsEditor";
import RiskCategorySelect from "./RiskCategorySelect";

type ReviewChecklistRiskConditionsEditorProps = {
  checklist: api.ReviewChecklist;
  readonly?: boolean;
  isNew?: boolean;
  onChange(checklist: api.ReviewChecklist): void;
};

const ReviewChecklistRiskConditionsEditor = (props: ReviewChecklistRiskConditionsEditorProps) => {
  const { checklist, readonly, isNew, onChange } = props;

  const f = useFormatMessage();
  const { allTags } = useTags();

  const [conditionType, setConditionType] = useState<ReviewChecklistConditionType | undefined>(
    getChecklistConditionType(checklist.display_settings)
  );

  const handleConditionTypeChange = (conditionType: ReviewChecklistConditionType) => {
    setConditionType(conditionType);
    onChange({ ...checklist, display_settings: EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS });
  };

  const handleConditionChange = (
    field: keyof api.ReviewChecklistDisplaySettings,
    value: ValueOf<api.ReviewChecklistDisplaySettings>
  ) => {
    onChange({ ...checklist, display_settings: { ...EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS, [field]: value } });
  };

  const removeTag = (tag: api.Label) =>
    handleConditionChange("labels", removeItem(checklist.display_settings?.labels || [], tag));

  return (
    <>
      <Card.Section fullWidth>
        <VerticalStack gap="2">
          <Text as={"h5"} variant={"headingSm"} fontWeight={"bold"}>
            {f("review.checklist.editor.labels.risk-type")}
          </Text>
          <RadioButton
            label={f("review.checklist.editor.condition-type.risk")}
            checked={conditionType === "risk"}
            onChange={(checked) => (checked ? handleConditionTypeChange("risk") : undefined)}
            disabled={readonly}
          />
          <Collapsible id={"risk-condition-type-collapsible"} open={conditionType === "risk"}>
            <HorizontalStack gap="4" blockAlign="center">
              <Spacer />
              <RiskCategorySelect
                riskCategory={first(checklist.display_settings?.risk_categories)}
                readonly={readonly || conditionType !== "risk"}
                onChange={(riskCategory) => handleConditionChange("risk_categories", [riskCategory])}
              />
            </HorizontalStack>
          </Collapsible>
        </VerticalStack>
      </Card.Section>
      <Card.Section fullWidth>
        <RadioButton
          label={f("review.checklist.editor.condition-type.purpose")}
          checked={conditionType === "purpose"}
          onChange={(checked) => (checked ? handleConditionTypeChange("purpose") : undefined)}
          disabled={readonly}
        />
        <Collapsible id={"purpose-condition-type-collapsible"} open={conditionType === "purpose"}>
          <ReviewChecklistPurposesConditionsEditor
            checklist={checklist}
            isNew={isNew}
            readonly={readonly}
            onChange={onChange}
          />
        </Collapsible>
      </Card.Section>
      <Card.Section fullWidth>
        <RadioButton
          label={f("review.checklist.editor.condition-type.tags")}
          checked={conditionType === "tag"}
          onChange={(checked) => (checked ? handleConditionTypeChange("tag") : undefined)}
          disabled={readonly}
        />
        <Collapsible id={"tag-condition-type-collapsible"} open={conditionType === "tag"}>
          <HorizontalStack gap="4" blockAlign="center">
            <Spacer />
            <TagsEditorPopover
              disabled={readonly || conditionType !== "tag"}
              tags={allTags}
              selectedTags={checklist.display_settings?.labels}
              enableReadonlySelection
              onSelectionChange={(tags) => handleConditionChange("labels", tags)}
            />
            <DisplayTagsWrapper
              tags={checklist.display_settings?.labels}
              onRemove={removeTag}
              readonly={readonly || conditionType !== "tag"}
            />
          </HorizontalStack>
        </Collapsible>
      </Card.Section>
    </>
  );
};

const Spacer = styled.div`
  margin-left: var(--p-space-3);
`;

export default ReviewChecklistRiskConditionsEditor;
