import React from "react";
import { Banner } from "@shopify/polaris";

import { COMPLIANCE_OFFICER_GROUP_KEY, MLRO_GROUP_KEY } from "../../constants/permissions";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import useGetUsers from "../../hooks/useGetUsers";

interface MissingRequiredPermissionsBannerProps {
  overrideRoleNames?: string[];
}

const MissingRequiredPermissionsBanner = (props: MissingRequiredPermissionsBannerProps) => {
  const { overrideRoleNames } = props;

  const { groups } = useGetUsers();
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const roleNames =
    overrideRoleNames ||
    groups
      .filter((group) => group.role_key === MLRO_GROUP_KEY || group.role_key === COMPLIANCE_OFFICER_GROUP_KEY)
      .map((group) => group.name);

  const roleNamesDisplayLabel = formatMultipleNames(roleNames, 2);

  return (
    <Banner status="info" title={""}>
      <p>{f("missing-required-permission.banner.description", { roles: roleNamesDisplayLabel })}</p>
    </Banner>
  );
};

export default MissingRequiredPermissionsBanner;
