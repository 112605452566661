import React from "react";
import { VerticalStack } from "@shopify/polaris";

import useComplyAdvantageMatch from "../../hooks/useComplyAdvantageMatch";
import { MatchDetailsProps } from "../../types/MatchDetails";
import ComplyAdvantageMatchDetailsGeographicComparisonSection from "../ComplyAdvantageMatchDetailsGeographicComparisonSection/ComplyAdvantageMatchDetailsGeographicComparisonSection";
import MatchDetailsComparisonSection from "../MatchDetailsComparisonSection/MatchDetailsComparisonSection";
import MatchDetailsNegativeNewsSection from "../MatchDetailsNegativeNewsSection/MatchDetailsNegativeNewsSection";
import MatchDetailsOtherSection from "../MatchDetailsOtherSection/MatchDetailsOtherSection";
import MatchDetailsPEPSection from "../MatchDetailsPEPSection/MatchDetailsPEPSection";
import MatchDetailsRelationsSection from "../MatchDetailsRelationsSection/MatchDetailsRelationsSection";
import MatchDetailsSanctionsSection from "../MatchDetailsSanctionsSection/MatchDetailsSanctionsSection";
import MatchKeyInformationSection from "../MatchKeyInformationSection/MatchKeyInformationSection";

const ComplyAdvantageMatch = (props: MatchDetailsProps) => {
  const { matchDetails } = props;

  const { sanctionEvents, pepEvents, positions, hasNewInfo } = useComplyAdvantageMatch(matchDetails);

  return (
    <>
      <VerticalStack>
        <MatchDetailsComparisonSection matchDetails={matchDetails} />
        <ComplyAdvantageMatchDetailsGeographicComparisonSection matchDetails={matchDetails} />
      </VerticalStack>
      <MatchKeyInformationSection matchDetails={matchDetails} />
      <VerticalStack>
        <MatchDetailsRelationsSection matchDetails={matchDetails} />
        <MatchDetailsPEPSection pepEvents={pepEvents} positions={positions} hasNewInfo={hasNewInfo} />
        <MatchDetailsSanctionsSection sanctionEvents={sanctionEvents} hasNewInfo={hasNewInfo} />
        <MatchDetailsNegativeNewsSection matchDetails={matchDetails} />
        <MatchDetailsOtherSection matchDetails={matchDetails} />
      </VerticalStack>
    </>
  );
};

export default ComplyAdvantageMatch;
