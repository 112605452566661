import { generatePath, useLocation } from "react-router-dom";

import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";

/**
 * A customer details pages could either be navigated to from the customers list, a project details page or directly
 * via a URL with customer ID
 */
const useCustomerBackButton = () => {
  const location = useLocation<HistoryLocationState>();
  const f = useFormatMessage();

  const fromCustomersList = location.state?.fromCustomersList === true;
  const fromProject = location.state?.fromProject;
  // if true - this mean to use history.back() instead of an HTML anchor
  const useNavigateBack = fromCustomersList || fromProject !== undefined;

  // undefined url will cause BackButton.tsx to use history.back() (which is preferred since it
  // stored all the URL search parameters, sorting and pagination state) instead of an HTML anchor
  const backButtonURL = useNavigateBack ? undefined : generatePath(ROUTES.CUSTOMERS);

  const backButtonDisplayLabel = fromProject ? fromProject.name : f("navigation.page.customers.title");

  return { backButtonDisplayLabel, backButtonURL };
};

export default useCustomerBackButton;
