import React, { useMemo } from "react";
import { ActionListItemDescriptor, Checkbox, HorizontalStack, Spinner, Text } from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";

import PersonIcon from "../../../assets/icons/person.svg";
import api from "../../api";
import useAuth from "../../hooks/useAuth";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import useGetUsers from "../../hooks/useGetUsers";
import { toggleElement } from "../../utils/collectionUtils";
import EntitiesList, { EntitiesListProps } from "../EntitiesList/EntitiesList";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Icon from "../extensions/Icon";

type UsersListProps = Omit<
  EntitiesListProps<api.User>,
  "entities" | "selectedIds" | "onSelect" | "getEntityLabel" | "getEntityPrefix" | "isEntityDisabled" | "filterLabel"
> & {
  selectedUserIds: string[];
  showAllUsersOption?: boolean;
  excludeCurrentUser?: boolean;
  filterGroups?: api.GroupRoleKey[];
  onChange(selectedUserIds: string[]): void;
};

const UsersList = (props: UsersListProps) => {
  const { selectedUserIds, showAllUsersOption, excludeCurrentUser, filterGroups, onChange, ...actionListProps } = props;

  const f = useFormatMessage();
  const { user: currentUser } = useAuth();
  const { getFilteredUsers, isLoading, error } = useGetUsers();
  const { getUserDisplayName } = useGetUserName();

  const users = getFilteredUsers(filterGroups);

  const displayUsers = useMemo(
    () => (excludeCurrentUser ? users?.filter((user) => user.id !== currentUser?.id) : users) || [],
    [excludeCurrentUser, users]
  );

  const clearSelection = () => onChange([]);

  const handleSelect = (user: api.User) => onChange(toggleElement(selectedUserIds, user.id));

  const allUsersActionItem: ActionListItemDescriptor = {
    content: f("component.users-list.all-users.title"),
    suffix: !actionListProps.allowMultiple && selectedUserIds.length === 0 ? <Icon source={TickSmallMinor} /> : null,
    prefix: actionListProps.allowMultiple ? (
      <Checkbox label="" labelHidden checked={selectedUserIds.length === 0} />
    ) : (
      <Icon source={PersonIcon} />
    ),
    onAction: clearSelection
  };

  if (isLoading) {
    return (
      <HorizontalStack gap="4" blockAlign="center">
        <Spinner size="small"></Spinner>
        <Text as="span">{f("component.users-list.loading")}</Text>;
      </HorizontalStack>
    );
  }

  if (error) {
    return <ErrorPanel message={error} actionTitle={f("component.users-list.loading.error-action")} />;
  }

  return (
    <EntitiesList<api.User>
      entities={displayUsers}
      selectedIds={selectedUserIds}
      getEntityLabel={(user) => getUserDisplayName(user)}
      getEntityPrefix={() => <Icon source={PersonIcon} />}
      isEntityDisabled={(user) => !user.is_active}
      prefixActionListItemDescriptor={showAllUsersOption ? allUsersActionItem : undefined}
      filterLabel={f("component.users-list.filter.label")}
      onSelect={handleSelect}
      {...actionListProps}
    />
  );
};

export default UsersList;
