import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Card, HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../../api";
import AddressInfo from "../../../components/AddressInfo/AddressInfo";
import DisplayName from "../../../components/DisplayName/DisplayName";
import ErrorPanel from "../../../components/ErrorPanel/ErrorPanel";
import Button from "../../../components/extensions/Button";
import IndustryRisk from "../../../components/IndustryRisk/IndustryRisk";
import { DEFAULT_COUNTRY } from "../../../constants/countries";
import { ROUTES } from "../../../constants/routes";
import { isConflictError } from "../../../helpers/errors.helpers";
import { getCompanyPath } from "../../../helpers/navigation.helpers";
import { isCompanyOnboardingDisabled } from "../../../helpers/onboarding.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import HistoryLocationState from "../../../types/HistoryLocationState";
import { ErrorType } from "../../../types/utilities";

import DisabledOnboardingWarning from "./DisabledOnboardingWarning";

type CompanyLookupResultConfirmProps = {
  lookupInfo: api.CompanyInformationLookupInfo;
};

const CompanyLookupResultConfirm = (props: CompanyLookupResultConfirmProps) => {
  const { lookupInfo } = props;

  const { national_id, name, business_address, mailing_address, legal_entity_type, risk_information } = lookupInfo;

  const history = useHistory<HistoryLocationState>();
  const f = useFormatMessage();

  const createCompanyMutation = useMutation<api.CompanyCustomerDetailResponse, ErrorType>(
    () =>
      api.createCompanyCustomer({
        national_id: `${national_id}`,
        country: DEFAULT_COUNTRY,
        name,
        project_id: createdFromProject?.id
      }),
    {
      onSuccess: (response) => {
        history.push(generatePath(ROUTES.COMPANY_ONBOARDING_SCREENING, { id: response.id }));
      }
    }
  );

  const createdFromProject = history.location.state?.createdFromProject;

  const isOnboardingDisabled = isCompanyOnboardingDisabled(lookupInfo);
  const description = legal_entity_type.description_nb || legal_entity_type.description_en;

  if (createCompanyMutation.isError && isConflictError(createCompanyMutation.error)) {
    // Conflict - company customer already exists. Redirect to company customer
    const existingCustomerId = createCompanyMutation.error.data?.id;
    const onboardingStatus = createCompanyMutation.error.data?.onboarding_status;

    if (existingCustomerId) {
      history.push(getCompanyPath(existingCustomerId, onboardingStatus));
    }
  }

  return (
    <Page title={f("lookup.title")}>
      <VerticalStack gap="4">
        <Card>
          <Card.Section>
            <VerticalStack gap="4">
              <DisplayName strong name={name} type="company" />
              <HorizontalStack gap="4">
                <Text variant="bodyMd" as="span" color="subdued">
                  {f("company.details.caption.national_id")}
                </Text>
                <span>{national_id}</span>
              </HorizontalStack>
              <HorizontalStack gap="4">
                <Text variant="bodyMd" as="span" color="subdued">
                  {f("company.details.caption.company.type")}
                </Text>
                <span>
                  {legal_entity_type.code} {description && `(${description})`}
                </span>
              </HorizontalStack>
              <HorizontalStack gap="4">
                <Text variant="bodyMd" as="span" color="subdued">
                  {f("company.details.caption.address")}
                </Text>
                <span>
                  <AddressInfo address={business_address} />
                </span>
              </HorizontalStack>
              <HorizontalStack gap="4">
                <Text variant="bodyMd" as="span" color="subdued">
                  {f("company.details.caption.address.postal")}
                </Text>
                <span>
                  <AddressInfo address={mailing_address} />
                </span>
              </HorizontalStack>
              <IndustryRisk
                industryRisk={risk_information}
                title={f("company.details.caption.industry-codes")}
                showAllIndustryTypes
              />
            </VerticalStack>
          </Card.Section>
        </Card>

        {isOnboardingDisabled && <DisabledOnboardingWarning lookupInfo={lookupInfo} />}

        {createCompanyMutation.isError && <ErrorPanel message={createCompanyMutation.error} />}

        <HorizontalStack gap="4" align="end">
          <Button
            onClick={() => {
              const redirectURLAfterClose = createdFromProject
                ? generatePath(ROUTES.PROJECT_DETAILS, { id: createdFromProject.id })
                : generatePath(ROUTES.CUSTOMERS);
              history.push(redirectURLAfterClose);
            }}
          >
            {f("default.cancel")}
          </Button>
          <Button onClick={() => history.push(generatePath(ROUTES.COMPANY_LOOKUP), { createdFromProject })}>
            {f("lookup.again")}
          </Button>
          <Button
            primary
            loading={createCompanyMutation.isLoading}
            disabled={createCompanyMutation.isLoading || isOnboardingDisabled}
            onClick={() => createCompanyMutation.mutate()}
          >
            {f("lookup.correct.customer")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Page>
  );
};

export default CompanyLookupResultConfirm;
