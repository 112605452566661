import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import { useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import {
  canCreateNewReview,
  getReviewStorageKey,
  isCompletedReview,
  isPendingReview,
  sortByReviewDate
} from "../../helpers/reviews.helpers";
import useCustomerReviewBanner from "../../hooks/useCustomerReviewBanner";
import useFormatMessage from "../../hooks/useFormatMessage";
import { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import { OptionalCustomerReviewRequest } from "../../types/CustomerReview";
import { CustomerDetails } from "../../types/utilities";
import { updateItem } from "../../utils/collectionUtils";
import CustomerReviewsList from "../CustomerReviewsList/CustomerReviewsList";
import CustomerReviewsListWithFilter from "../CustomerReviewsListWithFilter/CustomerReviewsListWithFilter";
import CustomerReviewsSectionBanner from "../CustomerReviewsSectionBanner/CustomerReviewsSectionBanner";
import NewCustomerReviewButton from "../NewCustomerReviewButton/NewCustomerReviewButton";

type CustomerReviewsSectionProps = {
  customer: CustomerDetails;
  readonly?: boolean;
};

const CustomerReviewsSection = (props: CustomerReviewsSectionProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const updateQueryData = useCustomerDetailsQueryData(customer);

  const { isNewCustomerReviewDisabled } = useCustomerReviewBanner(customer);

  const pendingReviews = customer.reviews.filter(isPendingReview).sort(sortByReviewDate);
  const reviews = customer.reviews.filter(isCompletedReview).sort(sortByReviewDate);

  const handleReviewChange = (updatedReview: api.CustomerReview) => {
    // update customer details cache
    updateQueryData((queryData) => (queryData.reviews = updateItem(queryData.reviews, updatedReview)));

    // update CUSTOMER REVIEW PAGE query cache in order to reflect changes in the measures
    queryClient.setQueryData<CustomerDetails>([[QUERIES_KEYS.CUSTOMER_REVIEW, customer.id, updatedReview.id]], {
      ...customer,
      reviews: updateItem(customer.reviews, updatedReview)
    });

    // try to update session storage of the review page with new data
    const itemKey = getReviewStorageKey(customer.id, updatedReview.id);
    const savedReviewContent = sessionStorage.getItem(itemKey);

    if (savedReviewContent) {
      try {
        const savedReview = JSON.parse(savedReviewContent) as OptionalCustomerReviewRequest;
        if (savedReview) {
          // only update the measures and not the whole review since handleReviewChange is currently only
          // used in measures outside the review page
          savedReview.measures = updatedReview.measures;
          sessionStorage.setItem(itemKey, JSON.stringify(savedReview));
        }
      } catch (e) {
        sessionStorage.removeItem(itemKey);
      }
    }
  };

  const showNewCustomerReviewButton = customer.reviews.length > 0 && canCreateNewReview(customer);

  return (
    <VerticalStack gap="4">
      <HorizontalStack align="space-between" blockAlign={"center"}>
        <Text variant="headingMd" as="h2">
          {f("reviews.header.title")}
        </Text>
        {showNewCustomerReviewButton && (
          <NewCustomerReviewButton customer={customer} disabled={readonly || isNewCustomerReviewDisabled}>
            {f("reviews.labels.new-review")}
          </NewCustomerReviewButton>
        )}
      </HorizontalStack>
      <CustomerReviewsSectionBanner customer={customer} readonly={readonly} />
      <div id={PAGE_ANCHORS.PENDING_REVIEWS_SECTION}>
        <CustomerReviewsList
          customer={customer}
          reviews={pendingReviews}
          readonly={readonly}
          onReviewChange={handleReviewChange}
        />
      </div>
      <CustomerReviewsListWithFilter
        customer={customer}
        reviews={reviews}
        readonly={readonly}
        onReviewChange={handleReviewChange}
      />
    </VerticalStack>
  );
};

export default CustomerReviewsSection;
