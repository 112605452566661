import React from "react";
import { Layout, Text, TextField, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { LANGUAGES_FLAGS } from "../../constants/languages";
import { createNewQuestion } from "../../helpers/questions.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ValueOf } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import { isNil } from "../../utils/util";
import CustomerTypeForm from "../CustomerTypeForm/CustomerTypeForm";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import KDKTextField from "../KDKTextField/KDKTextField";
import QuestionsEditor from "../QuestionsEditor/QuestionsEditor";
import SectionDivider from "../SectionDivider/SectionDivider";
import TranslationTextField from "../TranslationTextField/TranslationTextField";

type QuestionnaireEditorProps = {
  questionnaire: api.Questionnaire;
  readonly?: boolean;
  error?: unknown;
  onChange(questionnaire: api.Questionnaire): void;
};

const QuestionnaireEditor = (props: QuestionnaireEditorProps) => {
  const { questionnaire, readonly, error, onChange } = props;

  const f = useFormatMessage();

  const questionnaireTitleLabels: Record<api.LanguageEnum, string> = {
    en: f("questionnaire.title.label", {
      language: `${f("common.language.english")} ${LANGUAGES_FLAGS["en"]}`
    }),
    nb: f("questionnaire.title.label", {
      language: `${f("common.language.norwegian")} ${LANGUAGES_FLAGS["nb"]}`
    })
  };

  const handleFieldChange = (key: keyof api.Questionnaire, value: ValueOf<api.Questionnaire>) =>
    onChange({ ...questionnaire, [key]: value });

  const handleQuestionChange = (questions: api.Question[]) => onChange({ ...questionnaire, questions });

  return (
    <VerticalStack gap="5">
      <Layout>
        <Layout.Section>
          <VerticalStack gap="4">
            <KDKTextField
              value={questionnaire.name}
              label={f("common.labels.name")}
              requiredIndicator
              autoFocus
              autoComplete="on"
              disabled={readonly}
              onChange={(value) => handleFieldChange("name", value)}
              error={isEmptyString(questionnaire.name) ? f("common.errors.invalid.empty.name") : undefined}
            />
            <TextField
              value={questionnaire.description}
              label={f("common.labels.description")}
              autoComplete="on"
              multiline={4}
              disabled={readonly}
              onChange={(value) => handleFieldChange("description", value)}
            />
          </VerticalStack>
        </Layout.Section>
        <Layout.Section oneHalf />
      </Layout>
      <VerticalStack gap="2">
        <Text variant="bodyMd" as="span" color="subdued">
          {f("common.labels.customer-type")}
        </Text>
        <CustomerTypeForm
          customerType={questionnaire.customer_type}
          readonly={readonly}
          onChange={(customerType) => handleFieldChange("customer_type", customerType)}
        />
      </VerticalStack>
      {!isNil(error) && <ErrorPanel message={error} />}
      <SectionDivider />
      <Layout>
        <Layout.Section>
          <TranslationTextField
            text={questionnaire.title}
            vertical
            autoComplete="on"
            disabled={readonly}
            getLabel={(language) => questionnaireTitleLabels[language]}
            onChange={(text) => handleFieldChange("title", text)}
            error={isEmptyString(questionnaire.title?.nb) ? f("common.errors.invalid.empty.title") : undefined}
          />
        </Layout.Section>
        <Layout.Section oneHalf />
      </Layout>
      <QuestionsEditor
        questions={questionnaire.questions}
        readonly={readonly}
        onNewQuestion={createNewQuestion}
        onChange={handleQuestionChange}
      />
    </VerticalStack>
  );
};

export default QuestionnaireEditor;
