import React, { useState } from "react";
import { Button, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import { useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { getCompanyType } from "../../helpers/customer.helpers";
import useConvertToDeletedEntity from "../../hooks/useConvertToDeletedEntity";
import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import useQueryData from "../../hooks/useQueryData";
import { getCustomerQueryKey } from "../../types/utilities";
import { updateItem } from "../../utils/collectionUtils";
import RolesCard from "../RolesCard/RolesCard";

type CompanyRolesProps = {
  customer: api.CompanyCustomerDetailResponse;
  screeningRoles?: api.Role[];
  readonly?: boolean;
  isScreeningActive: boolean;
  hideTitle?: boolean;
  hideAddButton?: boolean;
};

const CompanyRoles = (props: CompanyRolesProps) => {
  const { customer, screeningRoles = [], readonly, isScreeningActive, hideTitle, hideAddButton } = props;

  const { roles, expired_roles, company_type } = customer;

  const [showExpiredRoles, setShowExpiredRoles] = useState(false);
  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const language = useLanguage();
  const convertToDeletedEntity = useConvertToDeletedEntity<api.Role>();
  const { updateQueryData } = useQueryData<api.CompanyCustomerDetailResponse>(getCustomerQueryKey(customer));
  const toggleShowExpiredRoles = () => setShowExpiredRoles(!showExpiredRoles);

  const getRoleScreeningType = (roleId?: string): api.ScreeningType | undefined => {
    if (!isScreeningActive) {
      return undefined;
    }

    if (screeningRoles.find((screeningRole) => screeningRole.id === roleId)) {
      return "MONITORING";
    } else {
      return "NONE";
    }
  };

  const rowsToDisplay = showExpiredRoles ? [...roles, ...expired_roles] : roles;

  const buttonLabel = showExpiredRoles
    ? f("roles.button.labels.hide-expired-roles")
    : f("roles.button.labels.show-expired-roles", { count: expired_roles.length });

  const handleCreateRole = (createdRole: api.Role) => {
    updateQueryData((queryData) => (queryData.roles = queryData.roles.concat(createdRole)));
  };

  const handleUpdatedRole = (updatedRole: api.Role) => {
    updateQueryData((queryData) => (queryData.roles = updateItem(queryData.roles, updatedRole)));
    // invalidate the entire customer query in case the role pep/sanctioned properties were changed
    // which could also change the roles if the role is also an owner of the company
    queryClient.invalidateQueries(getCustomerQueryKey(customer));
  };

  const handleDeleteRole = (deletedRoleId: string) =>
    updateQueryData((queryData) => {
      const deletedRole = queryData.roles.find((role) => role.id === deletedRoleId);
      if (deletedRole) {
        queryData.expired_roles = [convertToDeletedEntity(deletedRole), ...queryData.expired_roles];
      }

      queryData.roles = queryData.roles.filter((role) => role.id !== deletedRoleId);
    });

  return (
    <VerticalStack gap="4">
      {/* wrap  Heading in a vertical stack for spacing with tab in details page */}
      <VerticalStack gap="4">
        {!hideTitle && (
          <Text variant="headingMd" as="h2">
            {f("company.roles.title")}
          </Text>
        )}
      </VerticalStack>
      <RolesCard
        customer={customer}
        roles={rowsToDisplay}
        readonly={readonly}
        expiredRoles={expired_roles.map((role) => role.id!)}
        companyCountry={customer.country}
        hideAddButton={hideAddButton}
        showUpdates
        companyType={getCompanyType(language, company_type)}
        getRoleScreeningType={(role) => getRoleScreeningType(role.id)}
        getIsDeleteDisabled={(roleId) => getRoleScreeningType(roleId) === "MONITORING"}
        onCreate={handleCreateRole}
        onUpdate={handleUpdatedRole}
        onDelete={handleDeleteRole}
      />
      {expired_roles.length > 0 && (
        <HorizontalStack>
          <Button onClick={toggleShowExpiredRoles} textAlign="left" disclosure={showExpiredRoles ? "up" : "down"}>
            {buttonLabel}
          </Button>
        </HorizontalStack>
      )}
    </VerticalStack>
  );
};

export default CompanyRoles;
