import stringSimilarity from "string-similarity-js";

import { isNumber } from "./numbersUtils";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LETTERS_REGEX = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

// the threshold that below means 2 strings are not similar
// see: https://github.com/stephenjjbrown/string-similarity-js
const NAME_SIMILARITY_THRESHOLD = 0.6;

export const isEmail = (str?: string) => Boolean(str) && EMAIL_REGEX.test(String(str).toLowerCase());

export const firstLetter = (str?: string) => (str || "").substr(0, 1);

export const isEmptyString = (str?: string | null) =>
  typeof str !== "string" || str.length === 0 || str.trim().length === 0;

export const lowercaseFirstLetter = (str: string) => str.charAt(0).toLowerCase() + str.slice(1);

export const compareNumericStrings = (str1?: string, str2?: string) => Number(str1) > Number(str2);

export const parseWebsite = (www?: string): string | undefined => {
  if (!www) {
    return undefined;
  }

  if (www.startsWith("https") || www.startsWith("http")) {
    return www;
  } else {
    return `http://${www}`;
  }
};

export const appendCounter = (label: string, count?: number) =>
  !isNumber(count) || count === 0 ? label : `${label} (${count})`;

// returns a sorted words list without "-"
const getNameWords = (str: string) => str.replaceAll("-", " ").split(" ");

const isInitial = (word: string) => word.length === 1 || (word.endsWith(".") && word.length === 2);

export const areNamesSimilar = (name1: string, name2: string) => {
  const name1Words = getNameWords(name1);
  const name2Words = getNameWords(name2);

  if (name1Words.length !== name2Words.length) {
    return false;
  }

  for (let index = 0; index < name1Words.length; index++) {
    const word1 = name1Words[index];
    const word2 = name2Words[index];

    // first or last words of the name
    if (index === 0 || index === name1Words.length - 1) {
      if (stringSimilarity(word1, word2) < NAME_SIMILARITY_THRESHOLD) {
        return false;
      }
    } else {
      // middle words of the name

      // if one of the middle words in the name is an initial letter = compare only the first letters between the words
      if (isInitial(word1) || isInitial(word2)) {
        return word1[0].toLocaleLowerCase() === word2[0].toLocaleLowerCase();
      } else {
        // middle words are not initials - compare all the words based on their similarity
        if (stringSimilarity(word1, word2) < NAME_SIMILARITY_THRESHOLD) {
          return false;
        }
      }
    }
  }

  return true;
};

export const bytesToMegaBytes = (bytes: number) => (bytes / 1_000_000).toFixed(0);

/**
 * Convert any case to kebab case
 *
 * @see https://www.30secondsofcode.org/js/s/string-case-conversion/#convert-any-case-to-kebab-case
 */
export const toKebabCase = (str: string = "") =>
  str
    .match(LETTERS_REGEX)
    ?.map((x) => x.toLowerCase())
    .join("-");

export const filterRegEx = (term: string = "") => new RegExp(term.replace(/\(|\)/g, ""), "i");
