import React, { useState } from "react";
import { Card } from "@shopify/polaris";

import api from "../../api";
import { createNewRole } from "../../helpers/person.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import AddItemButton from "../AddItemButton/AddItemButton";
import RoleModal, { RoleModalProps } from "../RoleModal/RoleModal";

import RolesTable, { RolesTableProps } from "./RolesTable/RolesTable";

type RolesCardProps = Omit<RolesTableProps, "onEdit"> &
  Omit<RoleModalProps, "onClose" | "dataRole" | "isDeleteDisabled"> & {
    companyCountry: api.CountryEnum;
    hideAddButton?: boolean;
    getIsDeleteDisabled(roleId: string): boolean;
  };

const RolesCard = (props: RolesCardProps) => {
  const {
    roles,
    readonly,
    companyCountry,
    hideAddButton,
    customer,
    getIsDeleteDisabled,
    onCreate,
    onUpdate,
    onDelete,
    ...rolesTableProps
  } = props;

  const f = useFormatMessage();
  const [modalRole, setModalRole] = useState<api.Role>();

  const closeModal = () => setModalRole(undefined);

  const editRole = (role: api.Role) => setModalRole(role);

  const handleCreateRole = (role: api.Role) => {
    onCreate(role);
    closeModal();
  };

  const handleUpdateRole = (role: api.Role) => {
    onUpdate(role);
    closeModal();
  };

  const handleDeleteRole = (roleId: string) => {
    onDelete(roleId);
    closeModal();
  };

  return (
    <>
      {roles.length === 0 && <Card.Section>{f("roles.messages.no-roles")}</Card.Section>}
      {roles.length > 0 && (
        <RolesTable
          roles={roles}
          customer={customer}
          onEdit={editRole}
          onUpdate={onUpdate}
          readonly={readonly}
          {...rolesTableProps}
        />
      )}
      {!hideAddButton && (
        <Card.Section>
          <AddItemButton onClick={() => setModalRole(createNewRole(companyCountry))} disabled={readonly}>
            {f("screening.buttons.add-role")}
          </AddItemButton>
        </Card.Section>
      )}
      {modalRole && (
        <RoleModal
          customer={customer}
          dataRole={modalRole}
          onClose={closeModal}
          isDeleteDisabled={getIsDeleteDisabled(modalRole.id!)}
          onCreate={handleCreateRole}
          onUpdate={handleUpdateRole}
          onDelete={handleDeleteRole}
        />
      )}
    </>
  );
};

export default RolesCard;
