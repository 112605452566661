import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Button, Page } from "@shopify/polaris";

import NotFoundImage from "../../assets/images/not-found.svg";
import KDKEmptyState from "../components/KDKEmptyState/KDKEmptyState";
import { ROUTES } from "../constants/routes";
import useFormatMessage from "../hooks/useFormatMessage";

const NotFound = () => {
  const f = useFormatMessage();

  // Report 404 to Sentry
  useEffect(() => {
    try {
      // Throw error with the URL in order to capture 404s for unique URLs
      throw new Error(
        `A user was redirected to the NotFound page from ${window.location.href}. This may or may not be an error - open the Sentry issue to see why the user was redirected`
      );
    } catch (e) {
      Sentry.captureException(e, { level: "warning" });
    }
  }, []);

  return (
    <Page>
      <KDKEmptyState
        header={f("not-found.title")}
        description={f("not-found.description")}
        image={NotFoundImage}
        altImage={"404 - Page not found"}
      >
        <Button primary url={ROUTES.HOME}>
          {f("common.labels.take-me-back")}
        </Button>
      </KDKEmptyState>
    </Page>
  );
};

export default NotFound;
