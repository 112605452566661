import { useQuery, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import { MAX_RETRIES, QUERIES_KEYS, RETRY_TIME } from "../constants/queries-keys";

class RetryError extends Error {
  constructor(message: string) {
    super(message);
  }
}

/**
 * query to fetch screening info but extend the number of retries and the period between each try attempt
 * since data-service might take a while before returning roles and owners after creating a new company customer
 */
const useGetCompanyScreeningConfiguration = (customerId: string) => {
  const queryClient = useQueryClient();

  const { isLoading, isError, error, failureCount, ...rest } = useQuery(
    [QUERIES_KEYS.COMPANY_SCREENING, customerId],
    async () => {
      const response = await api.getCompanyScreeningConfiguration(customerId);

      if (!response?.beneficial_owners_fetched || !response?.roles_fetched) {
        throw new RetryError("Fetching roles and owners again.");
      }

      return Promise.resolve(response);
    },
    { retry: MAX_RETRIES, retryDelay: RETRY_TIME }
  );

  const loadingProgress = isLoading ? Math.round((failureCount / MAX_RETRIES) * 100) : 100;

  const invalidateQuery = () => queryClient.invalidateQueries([QUERIES_KEYS.COMPANY_SCREENING, customerId]);

  const isMaxRetriesReached = isError && error instanceof RetryError;

  return { isLoading, failureCount, isError, error, isMaxRetriesReached, loadingProgress, invalidateQuery, ...rest };
};

export default useGetCompanyScreeningConfiguration;
