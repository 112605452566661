import { useState } from "react";

import api from "../../api";
import { createChecklistAnswers } from "../../helpers/checklists.helpers";
import useFeatures from "../../hooks/useFeatures";
import useGetReviewChecklists from "../../hooks/useGetReviewChecklists";
import { OptionalChecklistAnswersRequest, OptionalChecklistQuestionAnswerRequest } from "../../types/ReviewChecklist";
import { delta } from "../../utils/collectionUtils";

// this hook handles handlers for managing checklist answers
const useCustomerReviewChecklistForms = (
  savedChecklistAnswers: api.ChecklistAnswers[],
  checklistAnswers: OptionalChecklistAnswersRequest[],
  manuallyRemovedChecklistIds: string[],
  setManuallyRemovedChecklistsIds: (manuallyRemovedChecklistIds: string[]) => void,
  onChecklistAnswersChange: (checklistAnswers: OptionalChecklistAnswersRequest[]) => void
) => {
  const features = useFeatures();
  const { data, isLoading } = useGetReviewChecklists();

  // a map of saved checklists from a saved/pending review
  const [savedChecklistAnswersMap, setSavedChecklistAnswersMap] = useState(
    new Map(
      savedChecklistAnswers.map((checklistAnswers) => [checklistAnswers.checklist.id, checklistAnswers.checklist])
    )
  );

  const selectedChecklistsIds = checklistAnswers.map((checklist) => checklist.checklist_id) || [];

  const loading = isLoading && selectedChecklistsIds.length > 0;

  // a map that contains ALL the answers for multiple checklists
  const checklistsAnswersMaps = new Map<string, Map<string, OptionalChecklistQuestionAnswerRequest>>(
    checklistAnswers.map((checklistAnswer) => [
      checklistAnswer.checklist_id,
      new Map(checklistAnswer.answers.map((answer) => [answer.question_id, answer]))
    ])
  );

  const getReviewChecklist = (checklistAnswers: OptionalChecklistAnswersRequest) => {
    const checklist =
      savedChecklistAnswersMap.get(checklistAnswers.checklist_id) ||
      data?.checklists.find((reviewChecklist) => reviewChecklist.id === checklistAnswers.checklist_id);

    return checklist;
  };

  const onSelectionChange = (checklistsIds: string[]) => {
    // remove the added checklistsIds from the manually removed list since they were just added manually by the user
    setManuallyRemovedChecklistsIds([
      ...new Set([...manuallyRemovedChecklistIds, ...delta(selectedChecklistsIds, checklistsIds)[0]])
    ]);

    const defaultAnswer = features.REVIEW__CHECKLIST_DEFAULT_TO_NO ? false : undefined;

    const updatedChecklistAnswers = checklistsIds.map(
      (checklistId) =>
        checklistAnswers.find((checklistAnswer) => checklistAnswer.checklist_id === checklistId) ||
        createChecklistAnswers(
          data!.checklists.find((reviewChecklist) => reviewChecklist.id === checklistId)!,
          defaultAnswer
        )
    );

    onChecklistAnswersChange(updatedChecklistAnswers);
  };

  const onChecklistRemove = (checklistId: string) => {
    // save the removed checklist id so it will not be automatically added again
    setManuallyRemovedChecklistsIds([...new Set([...manuallyRemovedChecklistIds, checklistId])]);

    setSavedChecklistAnswersMap((prevState) => {
      // remove the checklist from saved answers checklists map if it exists there
      const updatedMap = new Map(prevState);
      updatedMap.delete(checklistId);

      return updatedMap;
    });

    onChecklistAnswersChange(checklistAnswers.filter((answer) => checklistId !== answer.checklist_id) || []);
  };

  const onAnswerChange = (
    allAnswers: OptionalChecklistAnswersRequest,
    questionId: string,
    value: boolean,
    text?: string
  ) => {
    let answers: OptionalChecklistQuestionAnswerRequest[] = allAnswers.answers;
    if (allAnswers.answers.some((answer) => answer.question_id === questionId)) {
      // update the answer with a matching question_id
      answers = allAnswers.answers.map((answer) =>
        answer.question_id === questionId ? { ...answer, answer: value, additional_info: value ? text : "" } : answer
      );
    } else {
      // add as a new answer
      answers.push({ question_id: questionId, answer: value, additional_info: value ? text : "" });
    }

    // update the answer in the given checklistAnswers (allAnswers)
    const updatedChecklistAnswers: OptionalChecklistAnswersRequest = {
      ...allAnswers,
      answers
    };

    // update all the checklist answers
    const updatedValues = checklistAnswers.map((checklistAnswer) =>
      checklistAnswer.checklist_id === allAnswers.checklist_id ? updatedChecklistAnswers : checklistAnswer
    );

    onChecklistAnswersChange(updatedValues);
  };

  return {
    loading,
    selectedChecklistsIds,
    checklistsAnswersMaps,
    getReviewChecklist,
    onSelectionChange,
    onChecklistRemove,
    onAnswerChange
  };
};

export default useCustomerReviewChecklistForms;
