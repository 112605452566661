import React from "react";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import useFormatMessage from "../../hooks/useFormatMessage";

interface RoleTypeProps {
  role?: api.Role | api.SignerRole | api.RelatedRole;
}

const RoleType = (props: RoleTypeProps) => {
  const { role, ...rest } = props;

  const f = useFormatMessage();

  if (!role?.type) {
    return null;
  }

  const roleTypeDescription = f(RoleTypes[role.type]);

  return (
    <div title={roleTypeDescription} className="Polaris-DataTable__Cell--truncated" {...rest}>
      {roleTypeDescription}
    </div>
  );
};

export default RoleType;
