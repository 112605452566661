import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";
import { getTranslationText } from "../helpers/display.helpers";

import useLanguage from "./useLanguage";

// set the stale time to 1 hour to avoid multiple calls to getIndustryTypes
const useIndustryTypes = (enabled = false) => {
  const language = useLanguage();

  const getAllIndustryTypesQuery = useQuery([QUERIES_KEYS.ALL_INDUSTRY_TYPES], () => api.listAllIndustryCodes(), {
    staleTime: STALE_TIME,
    enabled
  });

  const allIndustries = getAllIndustryTypesQuery.data?.industry_codes || [];

  const industryTypesMap = useMemo(
    () => Object.fromEntries(allIndustries.map((industry) => [industry.code, industry])),
    [allIndustries, language]
  );

  const getIndustryLabel = useCallback(
    (industryCode: api.IndustryCodeEnum) =>
      getTranslationText(language, industryTypesMap[industryCode]?.name) || industryCode,
    [industryTypesMap]
  );

  // allIndustries contains both categories and codes
  // industry codes have 6 characters (formatted like this 00.000) while categories have less than 6 characters
  // (example: 01.10)
  const industriesWithoutCategories = useMemo(
    () => allIndustries.filter(({ code }) => code.length === 6),
    [allIndustries]
  );

  return {
    allIndustries,
    industriesWithoutCategories,
    getIndustryLabel,
    isLoading: getAllIndustryTypesQuery.isLoading
  };
};

export default useIndustryTypes;
