import { BannerStatus, Card, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import ConfirmedIcon from "../../../assets/icons/match_status_confirmed.svg";
import NewIcon from "../../../assets/icons/match_status_new.svg";
import RejectedIcon from "../../../assets/icons/match_status_rejected.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import CustomDate from "../CustomDate/CustomDate";
import Icon from "../extensions/Icon";
import KDKBanner from "../KDKBanner/KDKBanner";
import MatchImages from "../MatchImages/MatchImages";

type MatchHeaderProps = {
  id: string;
  status: BannerStatus;
  description: string;
  subjectName: string;
  matchEntityName: string;
  date: string;
  lastUpdateDate?: string;
  avatarImages?: api.MatchAttribute[];
  isRDC: boolean;
};

const MatchHeader = (props: MatchHeaderProps) => {
  const { id, status, description, subjectName, matchEntityName, date, lastUpdateDate, avatarImages, isRDC } = props;

  const f = useFormatMessage();

  const icon = status === "warning" ? ConfirmedIcon : status === "critical" ? RejectedIcon : NewIcon;

  return (
    <Card>
      <Card.Section>
        <KDKBanner status={status} hideIcon hideBorder>
          <HorizontalStack align="space-between">
            <VerticalStack gap="4">
              <HorizontalStack gap="4" align="start" wrap={false}>
                <Icon source={icon} width="50px" height="50px" />
                <VerticalStack>
                  <Text as="span" fontWeight="semibold">
                    {description}
                  </Text>
                  <Text variant="headingLg" as="h1">
                    {subjectName}
                  </Text>
                </VerticalStack>
              </HorizontalStack>
              <HorizontalStack gap="8" align="start">
                <HorizontalStack gap="1">
                  <Text as="span">{f("match.basic-info.id")}</Text>
                  <Text as="span" fontWeight="semibold">
                    {id}
                  </Text>
                </HorizontalStack>
                <HorizontalStack gap="1">
                  <Text as="span">{f("match.basic-info.match-date")}</Text>
                  <Text as="span" fontWeight="semibold">
                    <CustomDate date={date} />
                  </Text>
                </HorizontalStack>
                <HorizontalStack gap="1">
                  <Text as="span">{f("matches.labels.source")}</Text>
                  <Text as="span" fontWeight="semibold">
                    {isRDC ? "RDC" : "ComplyAdvantage"}
                  </Text>
                </HorizontalStack>
                {lastUpdateDate && (
                  <HorizontalStack gap="1">
                    <Text as="span">{f("match.basic-info.last-updated")}</Text>
                    <Text as="span" fontWeight="semibold">
                      <CustomDate date={lastUpdateDate} />
                    </Text>
                  </HorizontalStack>
                )}
              </HorizontalStack>
            </VerticalStack>
            <MatchImages entityName={matchEntityName} avatarAttributes={avatarImages} />
          </HorizontalStack>
        </KDKBanner>
      </Card.Section>
    </Card>
  );
};

export default MatchHeader;
