import React from "react";
import { FormLayout, Stack, TextField } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { DEFAULT_COUNTRY } from "../../constants/countries";
import { SUPPORTED_COUNTRIES } from "../../constants/integrations";
import { MAX_LENGTH_OF_TEXT_INPUT_FIELD } from "../../constants/person";
import { QUERIES_KEYS, STALE_TIME } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import AutoCompleteTextField from "../AutoCompleteTextField/AutoCompleteTextField";
import CountrySelect from "../CountrySelect/CountrySelect";

type AddressFormProps = {
  value?: Partial<api.Address>;
  disabled?: boolean;
  requiredFields?: (keyof api.Address)[];
  onChange(value: api.Address): void;
};

const AddressForm = (props: AddressFormProps) => {
  const { value: address, disabled = false, requiredFields = [], onChange } = props;

  const f = useFormatMessage();

  const isQueryEnabled = Boolean(
    address?.country && SUPPORTED_COUNTRIES.includes(address?.country) && !isEmptyString(address.postal_code)
  );

  const { data, isLoading } = useQuery(
    [QUERIES_KEYS.POSTAL_CODES_SUGGESTIONS, address?.country, address?.postal_code],
    () =>
      api.lookupPostalCode(
        (address?.country || DEFAULT_COUNTRY) as api.SupportedCountriesEnum,
        address?.postal_code || ""
      ),
    { keepPreviousData: true, enabled: isQueryEnabled, staleTime: STALE_TIME, retry: false }
  );
  const suggestions = data?.postal_codes?.map((code) => ({
    value: code.postal_code,
    label: code.postal_code
  }));

  const onFieldChanged = (field: keyof api.Address, value: string) => {
    // set new value as api.Address in order to avoid TypeScript error regarding undefined value in country field
    onChange({ ...address, [field]: value } as api.Address);
  };

  const autofillAddress = (postalCode?: string) => {
    const suggestedPostalCode = data?.postal_codes?.find((code) => code.postal_code === postalCode);
    if (suggestedPostalCode) {
      const city = suggestedPostalCode.city;
      const county = suggestedPostalCode.county;
      onChange({ ...address, city, county } as api.Address);
    }
  };

  return (
    <FormLayout>
      <Stack distribution={"fillEvenly"} alignment={"trailing"}>
        <TextField
          value={address?.address_line_1 || ""}
          onChange={(newValue) => onFieldChanged("address_line_1", newValue)}
          label={f("common.labels.address")}
          placeholder={f("common.address.addressline1")}
          disabled={disabled}
          autoComplete="off"
          maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
          requiredIndicator={requiredFields.includes("address_line_1")}
        />

        <TextField
          value={address?.address_line_2 || ""}
          onChange={(newValue) => onFieldChanged("address_line_2", newValue)}
          label={f("common.address.addressline2")}
          labelHidden
          placeholder={f("common.address.addressline2")}
          disabled={disabled}
          autoComplete="off"
          maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
          requiredIndicator={requiredFields?.includes("address_line_2")}
        />

        <AutoCompleteTextField
          value={address?.postal_code || ""}
          onChange={(newValue) => onFieldChanged("postal_code", newValue)}
          label={f("common.address.postal.code")}
          placeholder={f("common.address.postal.code")}
          disabled={disabled}
          autoComplete="off"
          maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
          requiredIndicator={requiredFields?.includes("postal_code")}
          isLoading={isQueryEnabled && isLoading}
          suggestions={suggestions}
          onBlur={() => autofillAddress(address?.postal_code)}
          onSelection={(value) => autofillAddress(value)}
        />
      </Stack>
      <FormLayout.Group>
        <TextField
          value={address?.city || ""}
          onChange={(newValue) => onFieldChanged("city", newValue)}
          label={f("common.address.city")}
          placeholder={f("common.address.city")}
          disabled={disabled}
          autoComplete="off"
          maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
          requiredIndicator={requiredFields?.includes("city")}
        />
        <TextField
          value={address?.county || ""}
          onChange={(newValue) => onFieldChanged("county", newValue)}
          label={f("common.address.county")}
          placeholder={f("common.address.county")}
          disabled={disabled}
          autoComplete="off"
          maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
          requiredIndicator={requiredFields?.includes("county")}
        />

        <CountrySelect
          value={address?.country}
          label={f("common.address.country")}
          onSelect={(newValue) => onFieldChanged("country", newValue || "")}
          disabled={disabled}
          requiredIndicator={requiredFields.includes("country")}
          clearButton={false}
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default AddressForm;
