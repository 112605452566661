import React from "react";
import { ProgressBar, Text, VerticalStack } from "@shopify/polaris";
import { useIsMutating } from "@tanstack/react-query";

import useFormatMessage from "../../hooks/useFormatMessage";

interface ImportCustomersProgressProps {
  actionItemsCount: number;
}

const ImportCustomersProgress = (props: ImportCustomersProgressProps) => {
  const { actionItemsCount } = props;

  const f = useFormatMessage();
  const mutationsCount = useIsMutating();

  const progress =
    actionItemsCount === 0 ? 0 : Math.ceil(((actionItemsCount - mutationsCount) / actionItemsCount) * 100);

  const label =
    progress === 100
      ? f("import-customers.wizard.labels.finished")
      : f("import-customers.wizard.labels.importing", {
          count: actionItemsCount - mutationsCount,
          total: actionItemsCount
        });

  if (progress === 0) {
    return null;
  }

  return (
    <VerticalStack>
      <Text variant="bodyMd" as="span">
        {label}
      </Text>
      <ProgressBar progress={progress} animated />
    </VerticalStack>
  );
};

export default ImportCustomersProgress;
