import api from "../api";

import { DEFAULT_COUNTRY } from "./countries";

/**
 * Countries we have integrated with company registers, ie.
 * countries we get roles and owners from, and receive company
 * info updates from automatically
 */
export const COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS: api.CountryEnum[] = [DEFAULT_COUNTRY];

// see https://developer.bring.com/api/postal-code/#supported-countries
export const SUPPORTED_COUNTRIES: api.CountryEnum[] = [
  "NO",
  "DK",
  "SE",
  "FI",
  "NL",
  "DE",
  "US",
  "BE",
  "FO",
  "GL",
  "IS",
  "SJ"
];
