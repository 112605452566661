// 2 seconds
export const DURATION_SHORT_IN_MS = 2000;

// 4 seconds
export const DURATION_MEDIUM_IN_MS = 4000;

// 10 minutes
export const DURATION_VERY_LONG_IN_MS = 600_000;

// 0.5 second
export const ERROR_DEBOUNCE_DELAY_IN_MILLISECONDS = 500;

// 0.25 second
export const QUERY_SEARCH_DEBOUNCE_DELAY = 250; // in milliseconds

export const SETTINGS_SEARCH_DEBOUNCE_DELAY = 125; // in milliseconds
