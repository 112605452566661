import React from "react";
import { RadioButton, RadioButtonProps } from "@shopify/polaris";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";

type FullWidthRadioButton = RadioButtonProps & {
  className?: string;
};

const FullWidthRadioButton = (props: FullWidthRadioButton) => {
  const { checked, disabled, className, ...buttonProps } = props;

  return (
    <StyledRadioButtonWrapper checked={checked} disabled={disabled} className={className}>
      <RadioButton checked={checked} disabled={disabled} {...buttonProps} />
    </StyledRadioButtonWrapper>
  );
};

const StyledRadioButtonWrapper = styled.span<{ checked?: boolean; disabled?: boolean }>`
  --background-color: var(--p-surface);
  --background-color-hovered: var(--p-surface-neutral-hovered);
  --background-color-active: var(--p-surface-neutral-pressed);

  --background-color-selected: var(--p-surface-selected);
  --background-color-selected-hovered: var(--p-surface-selected-hovered);
  --background-color-selected-active: var(--p-surface-selected-pressed);

  --border-color: var(--p-border-subdued);
  --border-color-hovered: var(--p-border-hovered);
  --border-color-active: var(--p-border-pressed);

  --border-color-selected: var(--p-interactive);
  --border-color-selected-hovered: var(--p-action-primary-hovered);
  --border-color-selected-active: var(--p-action-primary-pressed);

  & .Polaris-Choice {
    border-radius: var(--p-border-radius-1);
    padding: var(--p-space-4);
    background-color: ${({ checked }) => (checked ? "var(--background-color-selected)" : "var(--background-color)")};
    border: var(--p-border-width-1) solid
      ${({ checked }) => (checked ? "var(--border-color-selected)" : "var(--border-color)")};

    ${({ checked, disabled }) =>
      !disabled &&
      `
        &:hover {
          background-color: ${checked ? "var(--background-color-selected-hovered)" : "var(--background-color-hovered)"};
          border-color: ${checked ? "var(--border-color-selected-hovered)" : " var(--border-color-hovered)"};
        }
    
        &:active {
          border-color: ${checked ? "var(--border-color-selected-active)" : " var(--border-color-active)"};
          background-color: ${checked ? "var(--background-color-selected-active)" : "var(--background-color-active)"};
        }
    `}

    & .Polaris-Choice__Label {
      user-select: none;
      font-weight: ${({ checked }) => (checked ? "var(--p-font-weight-bold)" : undefined)};
    }

    width: 100%;

    // For small viewports - render button as a regular block element
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      padding: var(--p-space-2);
    }
  }
`;

export default FullWidthRadioButton;
