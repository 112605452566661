import React from "react";
import { Text, VerticalStack } from "@shopify/polaris";

import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useFormatOwnershipPercentage from "../../../hooks/useFormatOwnershipPercentage";
import { compareNumericStrings } from "../../../utils/stringUtils";
import CondensedText from "../../CondensedText/CondensedText";

interface OwnerPercentShareProps {
  owner: api.Owner;
  update?: api.OwnershipUpdate;
}

const OwnerPercentShare = (props: OwnerPercentShareProps) => {
  const { owner, update } = props;

  const f = useFormatMessage();
  const formatPercent = useFormatOwnershipPercentage();

  const hasUpdate = update?.update_type === "UPDATED";

  const fieldChange = update?.field_changes?.find((change) => change.field_name === "percent_share");

  const updateLabel = compareNumericStrings(fieldChange?.new, fieldChange?.old)
    ? f("default.up-from", { value: formatPercent(fieldChange?.old) })
    : f("default.down-from", { value: formatPercent(fieldChange?.old) });

  return (
    <VerticalStack gap="0" align="start">
      <Text as="span" color={owner.deleted ? "subdued" : undefined}>
        {formatPercent(owner.percent_share)}
      </Text>
      {hasUpdate && <CondensedText>{updateLabel}</CondensedText>}
    </VerticalStack>
  );
};

export default OwnerPercentShare;
