import React, { useState } from "react";
import { Card, TextField, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { DEFAULT_LANGUAGE } from "../../constants/languages";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import { ValueOf } from "../../types/utilities";
import CondensedText from "../CondensedText/CondensedText";
import TranslationTextMarkdownEditor from "../TranslationTextMarkdownEditor/TranslationTextMarkdownEditor";

type CustomerReviewGuidelineEditorProps = {
  guideline: api.ReviewGuideline;
  readonly?: boolean;
  onChange(guideline: api.ReviewGuideline): void;
};

const CustomerReviewGuidelineEditor = (props: CustomerReviewGuidelineEditorProps) => {
  const { guideline, readonly, onChange } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const [language, setLanguage] = useState<api.LanguageEnum>(DEFAULT_LANGUAGE);

  const handleChange = (field: keyof api.ReviewGuideline, value: ValueOf<api.ReviewGuideline>) =>
    onChange({ ...guideline, [field]: value });

  return (
    <VerticalStack gap="4">
      <Card>
        <TranslationTextMarkdownEditor
          translationText={guideline.text_md}
          onTextChange={(value) => handleChange("text_md", value)}
          language={language}
          onLanguageChange={setLanguage}
          readonly={readonly}
          key={language}
        >
          <TextField
            label={f("customer-review-guideline-settings.labels.guideline.header")}
            value={guideline.title[language] || ""}
            placeholder={f("reviews.titles.guideline-tips")}
            onChange={(value) => handleChange("title", { ...guideline.title, [language]: value })}
            labelHidden
            autoComplete="off"
          />
        </TranslationTextMarkdownEditor>
      </Card>
      {guideline.updated_by && guideline.updated_dt && (
        <CondensedText italic>
          {f("common.labels.modified-by", {
            name: getUserDisplayName(guideline.updated_by),
            timestamp: new Date(guideline.updated_dt)
          })}
        </CondensedText>
      )}
    </VerticalStack>
  );
};

export default CustomerReviewGuidelineEditor;
