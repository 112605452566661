import React from "react";
import { HorizontalStack, Link, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { parseWebsite } from "../../utils/stringUtils";
import CustomDate from "../CustomDate/CustomDate";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";

type MatchSourceProps = {
  source: api.MatchSource;
  showNewInfo?: boolean;
};

const MatchSource = (props: MatchSourceProps) => {
  const { source, showNewInfo } = props;
  const { entity_dt, headline, source_url, source_name, publisher, publication_source } = source;

  const f = useFormatMessage();

  return (
    <VerticalStack>
      <HorizontalStack gap="2" blockAlign={"baseline"}>
        {entity_dt && (
          <Text as={"span"} fontWeight="bold" variant="bodySm">
            <CustomDate date={entity_dt} />
          </Text>
        )}
        {showNewInfo && <NewInfoBadge />}
      </HorizontalStack>
      {headline && <Text as="p">{headline}</Text>}
      {/* don't show source_name twice if it is going to be shown as a URL */}
      {!source.source_url && source.source_name && (
        <HorizontalStack gap="1">
          <Text as="span" color="subdued">
            {f("matches.labels.source.name")}
          </Text>
          <Text as="span">{source_name}</Text>
        </HorizontalStack>
      )}
      {publisher && (
        <HorizontalStack gap="1">
          <Text as="span" color="subdued">
            {f("matches.labels.source.publisher")}
          </Text>
          <Text as="span">{publisher}</Text>
        </HorizontalStack>
      )}
      {publication_source && (
        <HorizontalStack gap="1">
          <Text as="span" color="subdued">
            {f("matches.labels.source.publication-source")}
          </Text>
          <Text as="span">{publication_source}</Text>
        </HorizontalStack>
      )}
      {source_url && (
        <HorizontalStack gap="1">
          <Text as="span">{f("matches.labels.source.url")}</Text>
          <Link url={parseWebsite(source_url)} external>
            {source_name || source_url}
          </Link>
        </HorizontalStack>
      )}
    </VerticalStack>
  );
};

export default MatchSource;
