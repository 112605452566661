import { useMutation } from "@tanstack/react-query";

import api from "../api";

import { useCustomerDetailsQueryData } from "./useQueryData";

const useAcknowledgeCompanyInfoChangeMutation = (customerId: string, infoUpdateIds: number[]) => {
  const updateCustomerDetails = useCustomerDetailsQueryData<api.CompanyCustomerDetailResponse>({
    id: customerId,
    type: "company"
  });

  // do not try to acknowledge the same infoUpdateId more than once
  const uniqueInfoUpdateIds = [...new Set(infoUpdateIds).values()];

  return useMutation(
    () =>
      Promise.all(
        uniqueInfoUpdateIds.map((infoUpdateId) => api.quickReviewCompanyInfoUpdate(customerId, infoUpdateId))
      ),
    {
      onSuccess: () => {
        updateCustomerDetails(
          (prevData) =>
            (prevData.info_updates = prevData.info_updates.filter(
              (infoUpdate) => !uniqueInfoUpdateIds.includes(infoUpdate.id)
            ))
        );
      }
    }
  );
};

export default useAcknowledgeCompanyInfoChangeMutation;
