import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { removeProperty } from "../utils/util";

import useFetch from "./useFetch";

const usePreviewDisclosureMutation = () => {
  const fetch = useFetch();

  const previewDisclosureMutation = useMutation<string, unknown, api.SignaturePreviewRequest>(async (signature) => {
    const request = { ...removeProperty(signature, "id"), send_email: false };
    const previewUrl = await api.getDisclosurePreviewLink(request);

    return await fetch(previewUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request)
    })
      // convert the response to an Object URL string that can be used inside an iFrame
      .then((res) => res.blob())
      .then((blob) => URL.createObjectURL(blob));
  });

  return previewDisclosureMutation;
};

export default usePreviewDisclosureMutation;
