import api from "../api";
import { hasContent } from "../utils/collectionUtils";
import { isEmptyString } from "../utils/stringUtils";

export const isOnboardingFinished = (customer: { onboarding_status: api.OnboardingStatus }) =>
  customer.onboarding_status === "DONE";

export const stripOrgId = (id: string) => parseInt(id ? id.replace(/\W/g, "") : id, 10);

export const isCompanyOnboardingDisabled = ({
  is_bankrupt,
  closure_date,
  under_forced_liquidation_or_dissolution,
  under_liquidation
}: api.CompanyInformationLookupInfo) =>
  under_forced_liquidation_or_dissolution || under_liquidation || is_bankrupt || !isEmptyString(closure_date);

export const convertSignatureToCompanyRoleSignatureConfiguration = (
  signature: api.Signature
): api.CompanyRoleSignatureConfiguration => ({
  id: signature.role_id!,
  email: signature.email,
  send_email: signature.send_email,
  use_standard_disclosure: signature.use_standard_disclosure,
  use_pep_disclosure: signature.use_pep_disclosure,
  use_verification: signature.use_verification,
  questionnaire_ids: hasContent(signature.questionnaire_ids) ? signature.questionnaire_ids : undefined,
  language: signature.language,
  assignment_id: signature.assignment_id
});
