import React, { useState } from "react";
import { ComplexAction, Modal, Text } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../../../api";
import ErrorPanel from "../../../../components/ErrorPanel/ErrorPanel";
import { QUERIES_KEYS } from "../../../../constants/queries-keys";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useQueryData from "../../../../hooks/useQueryData";

import CustomersResourceList from "./CustomersResourceList";

interface AddCustomersToProjectModalProps {
  project: api.Project;
  readonly?: boolean;
  onClose(): void;
}

const AddCustomersToProjectModal = (props: AddCustomersToProjectModalProps) => {
  const { project, readonly, onClose } = props;

  const f = useFormatMessage();
  const { setQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, project.id]);
  const [page, setPage] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>(project.customers.map((customer) => customer.id));

  const addCustomersToProjectMutation = useMutation(
    () => api.updateProject(project.id, { customer_ids: selectedIds }),
    {
      onSuccess: (updatedProject) => {
        setQueryData(updatedProject);
        onClose();
      }
    }
  );

  const saveAction: ComplexAction = {
    content: f("default.add"),
    onAction: () => addCustomersToProjectMutation.mutate(),
    disabled: readonly || addCustomersToProjectMutation.isLoading,
    loading: addCustomersToProjectMutation.isLoading
  };

  const cancelAction: ComplexAction = {
    content: f("default.close"),
    onAction: onClose,
    disabled: addCustomersToProjectMutation.isLoading
  };

  return (
    <Modal
      open
      title={f("project.customers.modal.title", { name: project.name })}
      onClose={onClose}
      primaryAction={saveAction}
      secondaryActions={[cancelAction]}
      onScrolledToBottom={() => setPage(page + 1)}
      footer={
        <Text variant="bodyMd" as="span">
          {f("common.labels.selected-customers-count", { count: selectedIds.length })}
        </Text>
      }
    >
      <Modal.Section>
        <CustomersResourceList
          page={page}
          resetPage={() => setPage(0)}
          selectedIds={selectedIds}
          existingCustomers={project.customers}
          onSelectionChange={setSelectedIds}
        />
      </Modal.Section>
      {addCustomersToProjectMutation.isError && <ErrorPanel message={addCustomersToProjectMutation.error} />}
    </Modal>
  );
};

export default AddCustomersToProjectModal;
