import React, { useState } from "react";
import { FormLayout, Modal, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ErrorType } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import KDKTextField from "../KDKTextField/KDKTextField";

type MeasureEditModalProps<D extends api.MeasureConfig> = {
  measure: D;
  existingMeasures: D[];
  isNewMeasure: boolean;
  readonly?: boolean;
  isLoading?: boolean;
  error?: ErrorType;
  onSave(measure: D): void;
  onClose(): void;
};

function MeasureEditModal<D extends api.MeasureConfig>(props: MeasureEditModalProps<D>) {
  const { measure, existingMeasures, isNewMeasure, readonly, isLoading, error, onSave, onClose } = props;

  const f = useFormatMessage();

  const [currentMeasure, setCurrentMeasure] = useState({ ...measure });

  const handleUpdate = (value: string, property: keyof D) => {
    const updatedMeasure = { ...currentMeasure, [property]: value };
    setCurrentMeasure(updatedMeasure);
  };

  const saveMeasure = () => onSave({ ...currentMeasure, title: currentMeasure.title.trim() });

  const disabled = readonly || isLoading;

  const modalTitle = disabled
    ? f("measures.modal.title.view")
    : isNewMeasure
      ? f("measures.modal.title.add")
      : f("measures.modal.title.update");

  const isDuplicateTitle = new Set(
    existingMeasures.map((measure) => (measure.id !== currentMeasure.id ? measure.title.toLowerCase() : ""))
  ).has(currentMeasure.title.trim().toLowerCase());

  // a measure title is invalid if it is empty or already exists in the unique Set of all other measures
  const isInvalidMeasure =
    isEmptyString(currentMeasure.title.trim()) || isEmptyString(currentMeasure.description.trim()) || isDuplicateTitle;

  const measureTitleError = isEmptyString(currentMeasure.title)
    ? f("measures.modal.errors.title-validation-error")
    : isDuplicateTitle
      ? f("measures.modal.errors.title-not-unique-validation-error")
      : undefined;

  const saveAction = {
    content: isNewMeasure ? f("default.add") : f("default.save"),
    onAction: saveMeasure,
    disabled: isInvalidMeasure || disabled,
    loading: isLoading
  };

  const cancelAction = {
    content: readonly ? f("default.close") : f("default.cancel"),
    onAction: onClose,
    disabled: isLoading
  };

  return (
    <Modal open title={modalTitle} onClose={onClose} primaryAction={saveAction} secondaryActions={[cancelAction]}>
      <Modal.Section>
        <VerticalStack gap="4">
          <FormLayout>
            <FormLayout.Group>
              <KDKTextField
                autoFocus
                label={f("common.labels.title")}
                value={currentMeasure.title}
                disabled={disabled}
                onChange={(value) => handleUpdate(value, "title")}
                autoComplete="off"
                error={measureTitleError}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <KDKTextField
                multiline={4}
                label={f("common.labels.description")}
                value={currentMeasure.description}
                disabled={disabled}
                onChange={(value) => handleUpdate(value, "description")}
                autoComplete="off"
                error={
                  isEmptyString(currentMeasure.description)
                    ? f("measures.modal.errors.description-validation-error")
                    : undefined
                }
              />
            </FormLayout.Group>
          </FormLayout>
          {error && <ErrorPanel message={error} />}
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
}

export default MeasureEditModal;
