import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";

// set the stale time to 1 hour to avoid multiple calls to getAllTags
const useTags = () => {
  const getAllTagsQuery = useQuery([QUERIES_KEYS.ALL_TAGS], () => api.getAllLabels(), { staleTime: STALE_TIME });

  const allTags = getAllTagsQuery.data?.labels || [];

  const tagsMap = useMemo(() => Object.fromEntries(allTags.map((tag) => [tag.id, tag])), [allTags]);

  const findTag = useCallback((tagId: string) => tagsMap[tagId], [tagsMap]);

  const findTagByTitle = useCallback(
    (title: string) => allTags.find((tag) => tag.title.nb === title || tag.title.en === title),
    [allTags]
  );

  return { allTags, findTag, findTagByTitle };
};

export default useTags;
