import React from "react";
import { Modal } from "@shopify/polaris";

import useFormatMessage from "../../../hooks/useFormatMessage";
import PlanConversionBanner, { PlanConversionBannerProps } from "../../PlanConversionBanner/PlanConversionBanner";

interface PlanConversionModalProps extends PlanConversionBannerProps {
  onClose(): void;
}

const PlanConversionModal = (props: PlanConversionModalProps) => {
  const { onClose, ...bannerProps } = props;

  const f = useFormatMessage();

  return (
    <Modal open title={f("plan-conversion.modal.title")} onClose={onClose}>
      <Modal.Section>
        <PlanConversionBanner {...bannerProps} />
      </Modal.Section>
    </Modal>
  );
};

export default PlanConversionModal;
