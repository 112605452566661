import React, { PropsWithChildren } from "react";
import { Text, VerticalStack, VerticalStackProps } from "@shopify/polaris";
import styled from "styled-components";

import ShowOnMobile from "../ShowOnMobile/ShowOnMobile";

// extend Polaris stack properties without original "vertical" property
interface SectionTileProps extends Omit<VerticalStackProps, "children"> {
  title: string;
  mobileOnly?: boolean;
}

const SectionTile = (props: PropsWithChildren<SectionTileProps>) => {
  const { title, children, mobileOnly, ...rest } = props;

  return (
    <VerticalStack {...rest}>
      {mobileOnly && (
        <ShowOnMobile>
          <StyledSubheading>
            <Text variant="headingXs" as="h3" color="subdued">
              {title}
            </Text>
          </StyledSubheading>
        </ShowOnMobile>
      )}
      {!mobileOnly && (
        <StyledSubheading>
          <Text variant="headingXs" as="h3" color="subdued">
            {title}
          </Text>
        </StyledSubheading>
      )}
      {children}
    </VerticalStack>
  );
};

const StyledSubheading = styled.span`
  white-space: break-spaces;
`;

export default SectionTile;
