import { Redirect } from "react-router-dom";

import { ROUTES } from "../../constants/routes";
import { REDIRECT_TO_AFTER_LOGOUT_KEY } from "../../constants/session-state";

const LogoutRedirect = () => {
  // check if a value was set in BeaufortAuthProvider.authenticateBeaufortUser (AuthProvider.tsx)
  // before logging the user out due to invalid token
  const redirectTo = localStorage.getItem(REDIRECT_TO_AFTER_LOGOUT_KEY) || ROUTES.HOME;
  localStorage.removeItem(REDIRECT_TO_AFTER_LOGOUT_KEY);

  return <Redirect to={redirectTo} />;
};

export default LogoutRedirect;
