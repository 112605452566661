import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { CRITICAL_COMPANY_DETAILS_FIELDS } from "../../constants/company-details";
import { getIndustryRiskCount } from "../../helpers/risk.helpers";
import { hasUnreviewedChanges } from "../../helpers/updates";
import useCountries from "../../hooks/useCountries";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import useYesNo from "../../hooks/useYesNo";
import { InfoUpdateFieldChange } from "../../types/CustomerUpdates";
import { hasContent } from "../../utils/collectionUtils";
import { isEmptyString, parseWebsite } from "../../utils/stringUtils";
import { isNil } from "../../utils/util";
import AddressInfo from "../AddressInfo/AddressInfo";
import { isFieldInCompanyCustomerForm } from "../CompanyCustomerForm/CompanyCustomerForm";
import CompanyInfoValue from "../CompanyInfoValue/CompanyInfoValue";
import Country from "../Country/Country";
import EditCustomerDetailsButton from "../EditCustomerDetailsButton/EditCustomerDetailsButton";
import InfoTable, { InfoTableRow } from "../InfoTable/InfoTable";

const formatIndustryCode = (industryCode: api.IndustryCode, language: api.LanguageEnum) =>
  [
    industryCode.code,
    (language === "en" ? industryCode.description_en : industryCode.description_nb) || industryCode.description
  ]
    .filter((e) => !!e)
    .join(" — ");

type Updates = {
  changes: InfoUpdateFieldChange[];
  ids: number[];
};

type CompanyInfoTableProp = {
  companyDetails: api.CompanyCustomerDetailResponse;
  infoUpdates: api.CompanyInfoUpdate[];
  readonly?: boolean;
};

const CompanyInfoTable = (props: CompanyInfoTableProp) => {
  const { companyDetails, infoUpdates, readonly } = props;

  const f = useFormatMessage();
  const { formatNumber } = useIntl();
  const { getCountryRiskDescription, isHighRiskCountry } = useCountries();
  const features = useFeatures();
  const yesNo = useYesNo();
  const language = useLanguage();

  const updates: Map<string, Updates> = useMemo(
    () =>
      infoUpdates.reduce<Map<string, Updates>>((fieldUpdates, currentInfoUpdate) => {
        currentInfoUpdate.field_changes.forEach((infoUpdateFieldChange) => {
          if (!fieldUpdates.has(infoUpdateFieldChange.field_name)) {
            fieldUpdates.set(infoUpdateFieldChange.field_name, { changes: [], ids: [] });
          }

          if (infoUpdateFieldChange.new !== infoUpdateFieldChange.old) {
            fieldUpdates.get(infoUpdateFieldChange.field_name)!.changes!.push({
              ...infoUpdateFieldChange,
              timestamp: currentInfoUpdate.timestamp,
              isReviewed: currentInfoUpdate.is_reviewed
            });
            fieldUpdates.get(infoUpdateFieldChange.field_name)!.ids!.push(currentInfoUpdate.id);
          }
        });

        return fieldUpdates;
      }, new Map()),
    [infoUpdates]
  );

  const hasUnreviewedUpdates = (...fieldNames: (keyof api.CompanyCustomerDetailResponse)[]) =>
    fieldNames.some((fieldName) => hasUnreviewedChanges(updates.get(fieldName)?.changes));

  const industryCodesMap = {
    industry_code_1: companyDetails.industry_code_1,
    industry_code_2: companyDetails.industry_code_2,
    industry_code_3: companyDetails.industry_code_3
  };
  const hasIndustryCode = Object.values(industryCodesMap).some((industryCode) => !isNil(industryCode));
  const hasIndustryCodeChanges = Object.keys(industryCodesMap).some((fieldName) =>
    hasContent(updates.get(fieldName)?.changes)
  );
  const shouldDisplayIndustryCode = hasIndustryCode || hasIndustryCodeChanges;

  const companyInfoRows: InfoTableRow[] = useMemo(() => {
    const createBooleanValueInfoRow = (
      fieldName: keyof api.CompanyCustomerDetailResponse,
      label: string
    ): InfoTableRow => ({
      label,
      isHighlight: hasUnreviewedUpdates(fieldName),
      isWarning: CRITICAL_COMPANY_DETAILS_FIELDS.includes(fieldName),
      value: (
        <CompanyInfoValue
          customerId={companyDetails.id}
          infoUpdateIds={updates.get(fieldName)?.ids}
          value={yesNo(companyDetails[fieldName] as boolean)}
          changes={updates.get(fieldName)?.changes}
          hideHistory
          status={CRITICAL_COMPANY_DETAILS_FIELDS.includes(fieldName) ? "critical" : "info"}
        />
      )
    });

    const hasValueOrUpdate = (field: keyof api.CompanyCustomerDetailResponse) =>
      !isNil(companyDetails[field]) || hasContent(updates.get(field)?.changes);

    /**
     * Checks if a company info field should be displayed or not
     * - If the field has a value or an update, it should be displayed
     * - If the company customer is manual, all fields that can be manually
     *    defined should be displayed even if they have no value
     * - Else, the field should not be displayed
     */
    const shouldDisplayField = (fieldName: keyof api.CompanyCustomerDetailResponse) => {
      if (hasValueOrUpdate(fieldName)) {
        return true;
      }

      if (companyDetails.is_manual) {
        return isFieldInCompanyCustomerForm(fieldName);
      }

      return false;
    };

    // Always display name, country and national ID
    const rows: InfoTableRow[] = [
      {
        label: f("company.details.caption.name"),
        value: (
          <CompanyInfoValue
            customerId={companyDetails.id}
            infoUpdateIds={updates.get("name")?.ids}
            value={companyDetails.name}
            changes={updates.get("name")?.changes}
          />
        ),
        isHighlight: hasUnreviewedUpdates("name")
      },
      {
        label: f("company.details.caption.country"),
        value: (
          <CompanyInfoValue
            customerId={companyDetails.id}
            value={companyDetails.country}
            render={(value) => <Country country={value} />}
          />
        ),
        isWarning: isHighRiskCountry(companyDetails.country),
        warningDescription: getCountryRiskDescription(companyDetails.country)
      },
      {
        label: f("company.details.caption.national_id"),
        value: <CompanyInfoValue customerId={companyDetails.id} value={companyDetails.national_id} />
      }
    ];

    if (shouldDisplayField("company_type")) {
      rows.push({
        label: f("company.details.caption.company.type"),
        isHighlight: hasUnreviewedUpdates("company_type"),
        value: (
          <CompanyInfoValue
            customerId={companyDetails.id}
            infoUpdateIds={updates.get("company_type")?.ids}
            value={
              companyDetails?.company_type?.code
                ? companyDetails.company_type.code +
                  (companyDetails.company_type?.description_nb && ` (${companyDetails.company_type.description_nb})`)
                : "-"
            }
            changes={updates.get("company_type")?.changes}
          />
        )
      });
    }

    if (shouldDisplayIndustryCode) {
      rows.push({
        label: f("company.details.caption.industry-codes"),
        isHighlight: hasUnreviewedUpdates(
          ...(Object.keys(industryCodesMap) as (keyof api.CompanyCustomerDetailResponse)[])
        ),
        isWarning: getIndustryRiskCount(companyDetails.risk_information) > 0,
        warningDescription: Object.values(companyDetails.risk_information?.industry_types || {})
          .map((industry) => industry?.risk_description)
          .filter((e) => !!e)
          .join("; "),
        value: (
          <VerticalStack gap="4">
            {Object.entries(industryCodesMap).map(([fieldName, industryCode]) =>
              industryCode ? (
                <CompanyInfoValue
                  customerId={companyDetails.id}
                  infoUpdateIds={updates.get(fieldName)?.ids}
                  key={fieldName}
                  value={formatIndustryCode(industryCode, language)}
                  changes={updates.get(fieldName)?.changes}
                />
              ) : null
            )}
          </VerticalStack>
        )
      });
    }

    if (shouldDisplayField("founded_date")) {
      rows.push({
        label: f("company.details.caption.est"),
        value: <CompanyInfoValue customerId={companyDetails.id} value={companyDetails.founded_date || "-"} />
      });
    }

    if (shouldDisplayField("number_of_employees")) {
      rows.push({
        label: f("company.details.caption.employees"),
        isHighlight: hasUnreviewedUpdates("number_of_employees"),
        value: (
          <CompanyInfoValue
            customerId={companyDetails.id}
            infoUpdateIds={updates.get("number_of_employees")?.ids}
            value={companyDetails.number_of_employees ? formatNumber(companyDetails.number_of_employees) : "-"}
            changes={updates.get("number_of_employees")?.changes}
          />
        )
      });
    }

    if (shouldDisplayField("business_address")) {
      rows.push({
        label: f("company.details.caption.address.visit"),
        isHighlight: hasUnreviewedUpdates("business_address"),
        isWarning: isHighRiskCountry(companyDetails.business_address?.country),
        warningDescription: getCountryRiskDescription(companyDetails.business_address?.country),
        value: (
          <CompanyInfoValue<api.Address>
            customerId={companyDetails.id}
            infoUpdateIds={updates.get("business_address")?.ids}
            value={companyDetails.business_address}
            changes={updates.get("business_address")?.changes}
            render={(value) => <AddressInfo address={value} />}
          />
        )
      });
    }

    if (shouldDisplayField("mailing_address")) {
      rows.push({
        label: f("company.details.caption.address.postal"),
        isHighlight: hasUnreviewedUpdates("mailing_address"),
        isWarning: isHighRiskCountry(companyDetails.mailing_address?.country),
        warningDescription: getCountryRiskDescription(companyDetails.mailing_address?.country),
        value: (
          <CompanyInfoValue<api.Address>
            customerId={companyDetails.id}
            infoUpdateIds={updates.get("mailing_address")?.ids}
            value={companyDetails.mailing_address}
            changes={updates.get("mailing_address")?.changes}
            render={(value) => <AddressInfo address={value} />}
          />
        )
      });
    }

    if (shouldDisplayField("office_address")) {
      rows.push({
        label: f("common.labels.office-address").toUpperCase(),
        isHighlight: hasUnreviewedUpdates("office_address"),
        isWarning: isHighRiskCountry(companyDetails.office_address?.country),
        warningDescription: getCountryRiskDescription(companyDetails.office_address?.country),
        value: (
          <CompanyInfoValue<api.Address>
            infoUpdateIds={updates.get("office_address")?.ids}
            customerId={companyDetails.id}
            value={companyDetails.office_address}
            changes={updates.get("office_address")?.changes}
            render={(value) => <AddressInfo address={value} />}
          />
        )
      });
    }

    if (shouldDisplayField("website")) {
      const website = parseWebsite(companyDetails.website);
      rows.push({
        label: f("company.details.caption.website"),
        isHighlight: hasUnreviewedUpdates("website"),
        value: (
          <CompanyInfoValue
            customerId={companyDetails.id}
            infoUpdateIds={updates.get("website")?.ids}
            value={website}
            changes={updates.get("website")?.changes}
            render={(value) =>
              isEmptyString(value) ? (
                <>{f("not.specified")}</>
              ) : (
                <a href={parseWebsite(value)} target="_blank" rel="noreferrer">
                  {value!.replace(/https?:\/\//, "")}
                </a>
              )
            }
          />
        )
      });
    }

    if (shouldDisplayField("registered_in_vat_registry")) {
      rows.push(
        createBooleanValueInfoRow("registered_in_vat_registry", f("company.details.caption.registered-in-vat"))
      );
    }

    if (shouldDisplayField("registered_in_foundation_registry")) {
      rows.push(
        createBooleanValueInfoRow(
          "registered_in_foundation_registry",
          f("company.details.caption.registered-in-foundation")
        )
      );
    }

    // The following fields should always be displayed if they have a truthy value,
    // as they could indicate a higher risk on the company customer
    if (companyDetails.is_bankrupt) {
      rows.push(createBooleanValueInfoRow("is_bankrupt", f("company.details.caption.is_bankrupt")));
    }

    if (companyDetails.under_liquidation) {
      rows.push(createBooleanValueInfoRow("under_liquidation", f("company.details.caption.under_liquidation")));
    }

    if (companyDetails.under_forced_liquidation_or_dissolution) {
      rows.push(
        createBooleanValueInfoRow(
          "under_forced_liquidation_or_dissolution",
          f("company.details.caption.under_forced_liquidation")
        )
      );
    }

    if (companyDetails.closure_date) {
      rows.push({
        label: f("company.details.caption.closure_date"),
        isHighlight: hasUnreviewedUpdates("closure_date"),
        value: (
          <CompanyInfoValue
            customerId={companyDetails.id}
            infoUpdateIds={updates.get("closure_date")?.ids}
            value={companyDetails.closure_date}
            changes={updates.get("closure_date")?.changes}
            hideHistory
          />
        )
      });
    }

    // Add external ID field first if feature is enabled and company has an external ID
    if (features.EXTERNAL_ID && companyDetails.external_id) {
      rows.unshift({
        label: f("company.details.caption.external-id"),
        value: <CompanyInfoValue customerId={companyDetails.id} value={companyDetails.external_id} />
      });
    }

    return rows;
  }, [companyDetails, updates, language]);

  return (
    <VerticalStack gap="4">
      <StyledButton customer={companyDetails} readonly={readonly} />
      <InfoTable rows={companyInfoRows} useCondensedLabels />
    </VerticalStack>
  );
};

const StyledButton = styled(EditCustomerDetailsButton)`
  margin: var(--p-space-4);
`;

export default CompanyInfoTable;
