import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import IconWaiting from "../../../assets/icons/inactive-icon.svg";
import IconApproved from "../../../assets/icons/status-approved.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { MessageKey } from "../../providers/LanguageProvider";
import Icon from "../extensions/Icon";

const PROJECT_STATUS_TRANSLATION_MAP: Record<api.ProjectStatus, MessageKey> = {
  ACTIVE: "project.status.ACTIVE",
  INACTIVE: "project.status.INACTIVE"
};

interface ProjectStatusProps {
  status: api.ProjectStatus;
}

const ProjectStatus = (props: ProjectStatusProps) => {
  const { status } = props;

  const f = useFormatMessage();
  const icon = status === "ACTIVE" ? IconApproved : IconWaiting;

  return (
    <HorizontalStack gap="1" blockAlign="center" wrap={false}>
      <Icon source={icon} width="8px" height="8px" />
      <Text as="span">{f(PROJECT_STATUS_TRANSLATION_MAP[status])}</Text>
    </HorizontalStack>
  );
};

export default ProjectStatus;
