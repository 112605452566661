import React from "react";
import { ButtonGroup } from "@shopify/polaris";

import api from "../../api";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../../constants/languages";
import useGetLanguageName from "../../hooks/useGetLanguageName";
import Button, { ButtonProps } from "../extensions/Button";

interface LanguageSelectProps extends Omit<ButtonProps, "label" | "value" | "onChange"> {
  value?: api.LanguageEnum;
  onChange: (language: api.LanguageEnum) => void;
}

const LanguageSelect = (props: LanguageSelectProps) => {
  const { value = DEFAULT_LANGUAGE, onChange, ...buttonProps } = props;

  const getLanguageName = useGetLanguageName();

  return (
    <ButtonGroup segmented fullWidth>
      {SUPPORTED_LANGUAGES.map((language) => (
        <Button key={language} {...buttonProps} primary={language === value} onClick={() => onChange(language)}>
          {getLanguageName(language)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default LanguageSelect;
