import React, { useState } from "react";
import { Card } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import PreviewModal from "../PreviewModal/PreviewModal";
import ReviewChecklistForm from "../ReviewChecklistForm/ReviewChecklistForm";

type ReviewChecklistPreviewModalProps = {
  checklist: api.ReviewChecklist;
  onClose(): void;
};

const ReviewChecklistPreviewModal = (props: ReviewChecklistPreviewModalProps) => {
  const { checklist, onClose } = props;

  const f = useFormatMessage();

  const [previewAnswers, setPreviewAnswers] = useState<Map<string, api.ChecklistQuestionAnswerRequest>>(new Map());

  const onChange = (questionId: string, answer: boolean, text?: string) => {
    setPreviewAnswers(
      (prevState) =>
        new Map([...prevState.entries(), [questionId, { question_id: questionId, answer, additional_info: text }]])
    );
  };

  return (
    <PreviewModal large title={f("review.checklist.preview.modal.title", { name: checklist.name })} onClose={onClose}>
      <Card>
        <Card.Section subdued>
          <ReviewChecklistForm checklist={checklist} answers={previewAnswers} onChange={onChange} />
        </Card.Section>
      </Card>
    </PreviewModal>
  );
};

export default ReviewChecklistPreviewModal;
