import React, { useState } from "react";
import { Card, DropZone, ProgressBar, Text, VerticalStack } from "@shopify/polaris";
import readXlsxFile from "read-excel-file";

import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import useDebouncedState from "../../hooks/useDebouncedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ImportRowData } from "../../types/ImportRow";
import { ErrorType } from "../../types/utilities";

import { convertRowToImportRowData } from "./ImportCustomersPage.helpers";

interface CompanyImportFileProps {
  filename: string;
  onRowsRead(rows: ImportRowData[], filename: string, columnsCounts: number): void;
}

const FileImport = (props: CompanyImportFileProps) => {
  const { filename, onRowsRead } = props;

  const f = useFormatMessage();

  const [showProgress, setShowProgress] = useDebouncedState(false, 1000);
  const [readFileError, setReadFileError] = useState<ErrorType>();
  const clearReadFileError = () => setReadFileError(undefined);

  const handleDropZoneDrop = async (_dropFiles: File[], acceptedFiles: File[]) => {
    clearReadFileError();

    if (acceptedFiles.length === 0) {
      return;
    }

    setShowProgress(true);

    try {
      const file = acceptedFiles[0];
      const fileRows = await readXlsxFile(file);

      const importDataRows: ImportRowData[] = [];
      let columnCount = 0;

      fileRows.forEach((row) => {
        importDataRows.push(convertRowToImportRowData(row));
        columnCount = Math.max(columnCount, row.filter(Boolean).length);
      });

      onRowsRead(importDataRows, file.name, columnCount);
    } catch (error) {
      setReadFileError(error);
    }
    setShowProgress(false);
  };

  return (
    <Card>
      <Card.Section>
        <VerticalStack gap="4">
          <Text variant="headingLg" as="p">
            {f("common.labels.file.upload")}
            {filename ? ": " + filename : ""}
          </Text>
          <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
            <DropZone.FileUpload />
          </DropZone>
          {showProgress && <ProgressBar progress={100} animated />}
          {readFileError && <ErrorPanel message={readFileError} actionTitle={"Read file"} />}
        </VerticalStack>
      </Card.Section>
    </Card>
  );
};

export default FileImport;
