import { generatePath } from "react-router-dom";

import api from "../../api";
import { ROUTES } from "../../constants/routes";

export const createDisclosurePagePath = (customerId: string, customerType: api.CustomerType, assignmentId?: string) => {
  if (assignmentId) {
    if (customerType === "company") {
      return generatePath(ROUTES.COMPANY_ASSIGNMENT_REQUEST_DISCLOSURES, { id: customerId, assignmentId });
    } else {
      return generatePath(ROUTES.PERSON_ASSIGNMENT_REQUEST_DISCLOSURES, { id: customerId, assignmentId });
    }
  } else {
    if (customerType === "company") {
      return generatePath(ROUTES.COMPANY_REQUEST_DISCLOSURES, { id: customerId });
    } else {
      return generatePath(ROUTES.PERSON_REQUEST_DISCLOSURES, { id: customerId });
    }
  }
};
