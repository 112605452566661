import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useSessionStorage } from "../../hooks/useStorage";
import { isEmptyString } from "../../utils/stringUtils";

const useRestoreLastWindowLocationSearch = (key: string) => {
  const [lastState, setLastState] = useSessionStorage(key, "");
  const history = useHistory();

  // whenever the last state or the current window.location.search changes - update the last state
  // to store the current window.location.search
  useEffect(() => {
    if (lastState !== window.location.search) {
      setLastState(window.location.search);
    }
  }, [lastState, window.location.search]);

  // if the last state was not empty, and it's different from the current window.location.search
  // change the current location search to the last state
  useEffect(() => {
    if (!isEmptyString(lastState) && lastState !== window.location.search) {
      console.debug("useRestoreLastWindowLocationSearch", history.location, lastState);
      history.replace({ ...history.location, search: lastState });
    }
  }, []);
};

export default useRestoreLastWindowLocationSearch;
