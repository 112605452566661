import React from "react";
import { Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../../../hooks/useFormatMessage";

const AttachmentUnsignableSection = () => {
  const f = useFormatMessage();

  return (
    <VerticalStack gap="4">
      <Text variant="headingMd" as="h2">
        {f("attachments.signatures.modal.labels.unsignable-attachment")}
      </Text>
      <Text as="span">{f("attachments.signatures.modal.descriptions.unsignable-attachment")}</Text>
    </VerticalStack>
  );
};

export default AttachmentUnsignableSection;
