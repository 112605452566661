import React, { useCallback, useState } from "react";
import { Card, List, Stack, Text, VerticalStack } from "@shopify/polaris";
import { TabDescriptor } from "@shopify/polaris/build/ts/latest/src/components/LegacyTabs/types";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import DisplayName from "../../components/DisplayName/DisplayName";
import Button from "../../components/extensions/Button";
import ImageDropZone from "../../components/ImageDropZone/ImageDropZone";
import KDKTabs from "../../components/KDKTabs/KDKTabs";
import PreviewKYCModal from "../../components/PreviewDisclosureModal/PreviewDisclosureModal";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import TranslationTextMarkdownEditor from "../../components/TranslationTextMarkdownEditor/TranslationTextMarkdownEditor";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import { ValueOf } from "../../types/utilities";
import { isTranslationTextEmpty } from "../../utils/util";

const LOGO_FILE_SIZE_LIMIT_IN_KB = 200;

type SettingsDisclosureProps = {
  settings: api.DisclosureSettings;
  readonly?: boolean;
};

const SettingsDisclosure = (props: SettingsDisclosureProps) => {
  const { settings: initialSettings, readonly } = props;

  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const { isPermittedTo } = usePermissions();
  const [customerType, setCustomerType] = useState<api.CustomerType>("person");
  const [settings, setSettings] = useState(initialSettings);
  const [isShowPreview, togglePreview] = useOpenClose();

  const { setQueryData } = useQueryData<api.DisclosureSettings>([QUERIES_KEYS.DISCLOSURE_SETTINGS]);

  const onSuccess = (response: api.DisclosureSettings) => {
    setSettings(response);
    setQueryData(response);
    queryClient.invalidateQueries([QUERIES_KEYS.DISCLOSURE_SETTINGS]);
  };

  const onFieldChange = useCallback(
    (field: keyof Partial<api.DisclosureSettings>, value: ValueOf<Partial<api.DisclosureSettings>>) => {
      // If the value of person_intro_text/company_intro_text is empty, set the field as undefined
      if (
        (field === "person_intro_text" || field === "company_intro_text") &&
        isTranslationTextEmpty(value as api.TranslationText)
      ) {
        value = undefined;
      }

      setSettings((prevState) => ({ ...prevState, [field]: value }));
    },
    []
  );

  const logoPresent = settings.logo !== undefined && settings.logo !== "";

  const disabled = readonly || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const tabs: TabDescriptor[] = [
    {
      id: "person",
      content: <DisplayName name={f("disclosure.settings.page.tabs.customer-type.person")} type={"person"} />
    },
    {
      id: "company",
      content: <DisplayName name={f("disclosure.settings.page.tabs.customer-type.company")} type={"company"} />
    }
  ];

  const handleTabSelectionChange = (selectedTabIndex: number) =>
    setCustomerType(selectedTabIndex === 0 ? "person" : "company");

  return (
    <SettingsPage<api.DisclosureSettings>
      readonly={readonly}
      apiCall={() => api.updateDisclosureSettings(settings)}
      onSaveAfter={onSuccess}
      title={f("disclosure.settings.page.title")}
      subtitle={f("disclosure.settings.page.description")}
      footer={
        <Button onClick={togglePreview} disabled={readonly}>
          {f("default.preview")}
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Stack vertical={logoPresent} distribution="equalSpacing" alignment={logoPresent ? "leading" : "center"}>
            <VerticalStack>
              <Text fontWeight="bold" as="legend">
                {f("disclosure.settings.page.labels.logo")}
              </Text>
              <ItalicText>
                <Text color="subdued" as="span">
                  {f("disclosure.settings.page.labels.supported-filetypes", { limit: LOGO_FILE_SIZE_LIMIT_IN_KB })}
                </Text>
              </ItalicText>
            </VerticalStack>

            <ImageDropZone
              readonly={disabled}
              image={settings.logo}
              filename={settings.logo_filename}
              filesizeLimit={LOGO_FILE_SIZE_LIMIT_IN_KB * 1024}
              onChange={(logo, filename) => {
                onFieldChange("logo", logo);
                onFieldChange("logo_filename", filename);
              }}
            />
          </Stack>
        </Card.Section>
      </Card>

      <Card>
        <Card.Section title={f("disclosure.settings.page.labels.text")}>
          <KDKTabs selected={customerType === "person" ? 0 : 1} tabs={tabs} onSelect={handleTabSelectionChange}>
            <VerticalStack gap="2">
              <SectionMargin size="medium" />
              <Text variant="headingLg" color="subdued" as="h1">
                {f(`disclosure.settings.page.labels.email.subject.${customerType}`)}
              </Text>
              <Text variant="headingSm" color="subdued" as="h1">
                {f("disclosure.settings.page.labels.email.header")}
              </Text>
              <TranslationTextMarkdownEditor
                translationText={
                  (customerType === "person" ? settings.person_intro_text : settings.company_intro_text) || { nb: "" }
                }
                readonly={disabled}
                onTextChange={(value) =>
                  onFieldChange(customerType === "person" ? "person_intro_text" : "company_intro_text", value)
                }
              />

              <Text color="subdued" as="legend">
                {f("disclosure.settings.page.labels.email.due-diligence.list.title")}
              </Text>
              <List spacing="extraTight" type="bullet">
                <List.Item>
                  <Text color="subdued" as="legend">
                    {f("disclosure.settings.page.labels.email.due-diligence.identification")}
                  </Text>
                </List.Item>
                <List.Item>
                  <Text color="subdued" as="legend">
                    {f("disclosure.settings.page.labels.email.due-diligence.kyc")}
                  </Text>
                </List.Item>
              </List>
            </VerticalStack>
          </KDKTabs>
        </Card.Section>
      </Card>
      {isShowPreview && <PreviewKYCModal customerType={customerType} settings={settings} onClose={togglePreview} />}
    </SettingsPage>
  );
};

const ItalicText = styled.span`
  font-style: italic;
`;

const SettingsDisclosurePage = ({ data }: AsyncPageChild<api.DisclosureSettings>) => {
  return <SettingsDisclosure settings={data} />;
};

export default withAsyncPage<api.DisclosureSettings>(SettingsDisclosurePage, {
  queryKey: QUERIES_KEYS.DISCLOSURE_SETTINGS,
  apiFunction: api.getDisclosureSettings,
  paramNames: [],
  skeleton: <SettingsDisclosure settings={{}} readonly />
});
