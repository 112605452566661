import React from "react";
import { flexRender, Table } from "@tanstack/react-table";
import classNames from "classnames";
import styled from "styled-components";

import { isImportDataError } from "../../helpers/import-customers.helpers";
import { ImportRowData } from "../../types/ImportRow";

interface ImportCustomersTableProps {
  table: Table<ImportRowData>;
}

const ImportCustomersTable = (props: ImportCustomersTableProps) => {
  const { table, ...rest } = props;

  return (
    <div className="Polaris-IndexTable" {...rest}>
      <StyledTable className="Polaris-IndexTable-ScrollContainer">
        <table className="Polaris-IndexTable__Table">
          <StyledStickyTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={"Polaris-IndexTable__TableHeading"} colSpan={header.colSpan}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </StyledStickyTableHeader>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const className = classNames("Polaris-IndexTable__TableRow", {
                "Polaris-IndexTable--statusSuccess": row.original.status === "IMPORTED",
                "Polaris-IndexTable--statusSubdued": isImportDataError(row.original)
              });
              return (
                <StyledTableRow key={row.id} className={className}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className={"Polaris-IndexTable__TableCell"}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </StyledTableRow>
              );
            })}
          </tbody>
        </table>
      </StyledTable>
    </div>
  );
};

const StyledTable = styled.div`
  max-height: 40vh;
  overflow-y: auto;
`;

const StyledStickyTableHeader = styled.thead`
  & > tr > th {
    position: sticky;
    top: 0;
    z-index: var(--p-z-1);
    background-color: var(--p-surface);
  }
`;

const StyledTableRow = styled.tr`
  cursor: default;
  --kdk-backgrorund-color: transparent;

  background-color: var(--kdk-backgrorund-color);
  & > td {
    background-color: var(--kdk-backgrorund-color);
  }

  &:hover {
    --kdk-backgrorund-color: var(--p-surface-hovered);
  }
`;

export default ImportCustomersTable;
