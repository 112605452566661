import React from "react";

import api from "../../../../api";
import ConfirmAction from "../../../../components/ConfirmAction/ConfirmAction";
import { QUERIES_KEYS } from "../../../../constants/queries-keys";
import { getCustomerName } from "../../../../helpers/display.helpers";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../../../hooks/useFormatMultipleNames";
import useQueryData from "../../../../hooks/useQueryData";
import useSnackbar from "../../../../hooks/useSnackbar";
import { Customer } from "../../../../types/utilities";

interface RemoveCustomersFromProjectConfirmProps {
  project: api.Project;
  customers: Customer[];
  onClose(): void;
  onSuccess(): void;
}

const RemoveCustomersFromProjectConfirm = (props: RemoveCustomersFromProjectConfirmProps) => {
  const { project, customers: customersToRemove, onClose, onSuccess } = props;

  const existingCustomers = project.customers;
  const customersIdsToRemove = customersToRemove.map((customer) => customer.id);

  const { setQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, project.id]);
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();
  const { setSnackbar } = useSnackbar();

  const customersNamesLabel = formatMultipleNames(customersToRemove.map(getCustomerName));

  const updatedProjectCustomersIds = existingCustomers
    .map((existingCustomer) => existingCustomer.id)
    .filter((existingCustomerId) => !customersIdsToRemove.includes(existingCustomerId));

  const handleSuccess = (updatedProject: api.Project) => {
    setSnackbar(f("project.remove-customers.success.message", { count: customersToRemove.length }));
    setQueryData(updatedProject);
    onSuccess();
  };

  return (
    <ConfirmAction
      isOpen
      apiCall={() => api.updateProject(project.id, { customer_ids: updatedProjectCustomersIds })}
      onSuccess={handleSuccess}
      title={f("project.remove-customers.confirmation.title")}
      description={f("project.remove-customers.confirmation.description", {
        names: customersNamesLabel,
        projectName: project.name
      })}
      onNo={onClose}
      actionTitle={f("project.remove-customers.confirmation.action.label")}
    />
  );
};

export default RemoveCustomersFromProjectConfirm;
