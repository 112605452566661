import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { generatePath, useLocation } from "react-router-dom";
import { Card, HorizontalStack, Page, Stack, Text, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import BackButton from "../../components/BackButton/BackButton";
import CustomersListBulkActions from "../../components/CustomersListBulkActions/CustomersListBulkActions";
import CustomersListEmptyState from "../../components/CustomersListEmptyState/CustomersListEmptyState";
import CustomersListFilters from "../../components/CustomersListFilters/CustomersListFilters";
import useRestoreLastWindowLocationSearch from "../../components/CustomersListFilters/useRestoreLastWindowLocationSearch";
import CustomersListFreshCustomerEmptyState from "../../components/CustomersListFreshCustomerEmptyState/CustomersListFreshCustomerEmptyState";
import CustomersListTable from "../../components/CustomersListTable/CustomersListTable";
import Badge from "../../components/extensions/Badge";
import KDKTabs from "../../components/KDKTabs/KDKTabs";
import RegisterCustomerDropdownMenu from "../../components/RegisterCustomerDropdownMenu/RegisterCustomerDropdownMenu";
import RelatedSearchResults from "../../components/RelatedSearchResults/RelatedSearchResults";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { ROUTES } from "../../constants/routes";
import { CUSTOMERS_LIST_SEARCH } from "../../constants/session-state";
import { MOBILE_BREAKPOINT } from "../../constants/styles";
import useAuth from "../../hooks/useAuth";
import useCustomersListBulkActions from "../../hooks/useCustomersListBulkActions";
import useCustomersListParams from "../../hooks/useCustomersListParams";
import useCustomersTableColumns from "../../hooks/useCustomersTableColumns";
import useFormatMessage from "../../hooks/useFormatMessage";
import useSelectionMap from "../../hooks/useSelectionMap";
import useShowResponsibleUserFilter from "../../hooks/useShowResponsibleUserFilter";
import HistoryLocationState from "../../types/HistoryLocationState";
import { Customer, isCompanySearchResult } from "../../types/utilities";

const CUSTOMERS_LIST_TAB = 0;
const PENDING_TASKS_LIST_TAB = 1;

const CustomersListPage = () => {
  useRestoreLastWindowLocationSearch(CUSTOMERS_LIST_SEARCH);

  const f = useFormatMessage();
  const { user } = useAuth();
  const { formatNumber } = useIntl();
  const location = useLocation<HistoryLocationState>();
  const showResponsibleUserFilter = useShowResponsibleUserFilter();

  const {
    searchParams,
    isEmptySearch,
    q,
    haveOwnershipUpdates,
    isArchived,
    pendingTasks,
    setPendingTasks,
    responsibleUserIds,
    setResponsibleUserIds
  } = useCustomersListParams();

  const selectedTabIndex = pendingTasks ? PENDING_TASKS_LIST_TAB : CUSTOMERS_LIST_TAB;
  const { isLoading, data } = useQuery([QUERIES_KEYS.CUSTOMERS_LIST, ...Object.values(searchParams)], () =>
    api.getCustomers(searchParams)
  );

  const count = data?.count || 0;
  const customersWithPendingTasksCount = data?.has_pending_tasks_count || 0;
  const customers = data?.customers || [];
  const paginationCount = pendingTasks ? customersWithPendingTasksCount : count;

  const isFreshCustomer = customers.length === 0 && isEmptySearch;
  const isFromDashboard = location.state?.fromDashboard === true;

  const renderRelatedSearchResults = (customer: Customer) => {
    const relatedSearchResults = isCompanySearchResult(customer) ? customer.related_search_results || [] : [];

    if (relatedSearchResults.length === 0) {
      return null;
    }

    return <RelatedSearchResults searchResults={relatedSearchResults} searchTerm={q} />;
  };

  const columns = useCustomersTableColumns(q, haveOwnershipUpdates, renderRelatedSearchResults);

  const [selectedCustomersIds, selectedCustomers, handleSelectionChange, clearSelection] =
    useSelectionMap<Customer>(customers);

  // switching between normal and archived customers changes the available actions in the page
  // clear the selected customers if the filter is changed so there won't be a mix of archived and regular customers
  useEffect(clearSelection, [isArchived]);

  // run this effect here and not (for example) in useCustomersListParams.tsx
  // in order to avoid re-running it in multiple components that use useCustomersListParams
  useEffect(() => {
    if (!user || !showResponsibleUserFilter) {
      return;
    }

    // if the users is a "plain" user (without groups) and there are no responsible users ids as parameters
    // set the default responsible user id to be the current user
    const useCurrentUserId = user.groups.length === 0 && responsibleUserIds.length === 0;

    if (useCurrentUserId) {
      setResponsibleUserIds([user.id]);
    }
  }, [user, showResponsibleUserFilter]);

  const selectedCount = selectedCustomersIds.length;

  const { isEmpty: areBulkActionEmpty } = useCustomersListBulkActions(selectedCount, isArchived);

  const isAllSelected = count === selectedCustomersIds.length;
  const selectedLabel = isAllSelected
    ? f("customers.list.header.customers.count.all", { count })
    : f("customers.list.header.customers.count", { count, selected: selectedCustomersIds.length });

  const countersLabel = count > 0 ? selectedLabel : "";

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setPendingTasks(selectedTabIndex === PENDING_TASKS_LIST_TAB);
  }, []);

  const tabs = [
    {
      id: "all-customers",
      content: f("customers.list.tabs.labels.all", { count })
    },
    {
      id: "customers-with-pending-tasks",
      content: (
        <Stack spacing="tight" distribution="center" wrap={false}>
          <Text variant="bodyMd" as="span">
            {f("customers.list.tabs.labels.pending-tasks")}
          </Text>
          <Badge status={customersWithPendingTasksCount > 0 ? "severe" : undefined}>
            {formatNumber(customersWithPendingTasksCount)}
          </Badge>
        </Stack>
      )
    }
  ];

  return (
    <Page>
      <VerticalStack gap="8">
        {isFromDashboard && (
          <BackButton url={generatePath(ROUTES.DASHBOARD)} displayLabel={f("nav-bar.dashboard")} showAsLink />
        )}
        <HorizontalStack align={"space-between"} blockAlign={"center"}>
          <Text variant="heading2xl" as="p">
            {f("customers.list.title")}
          </Text>
          <RegisterCustomerDropdownMenu />
        </HorizontalStack>
        <VerticalStack>
          <CustomersListFilters isLoading={isLoading} />
          <StyledTabs tabs={tabs} selected={selectedTabIndex} onSelect={handleTabChange} fitted centered>
            <VerticalStack gap="4">
              <HorizontalStack gap="4">
                <StyledSelectionWrapper>
                  <Text as="span">{countersLabel}</Text>
                </StyledSelectionWrapper>
                {selectedCount > 0 && (
                  <CustomersListBulkActions
                    customers={selectedCustomers}
                    isArchived={isArchived}
                    onClearSelection={clearSelection}
                  />
                )}
              </HorizontalStack>
              <StyledTable
                columns={columns}
                customers={customers}
                isLoading={isLoading}
                count={paginationCount || 0}
                selectable={!areBulkActionEmpty}
                selectedIds={selectedCustomersIds}
                onSelectionChange={handleSelectionChange}
              />
              {!isLoading && customers.length === 0 && (
                <Card>
                  <Card.Section>
                    {isFreshCustomer ? <CustomersListFreshCustomerEmptyState /> : <CustomersListEmptyState />}
                  </Card.Section>
                </Card>
              )}
            </VerticalStack>
          </StyledTabs>
        </VerticalStack>
      </VerticalStack>
    </Page>
  );
};

const StyledTable = styled(CustomersListTable)`
  & .Polaris-DataTable,
  & .Polaris-DataTable__ScrollContainer {
    overflow: unset;
  }

  & thead > tr > th {
    position: sticky;
    top: var(--kdk-toolbar-height);
    z-index: var(--p-z-1);
    background-color: var(--p-surface);
  }

  & table {
    border-collapse: separate;
    border-spacing: 0 var(--p-space-2);
  }

  & tbody > tr > td:first-child {
    border-top-left-radius: var(--p-border-radius-1);
    border-bottom-left-radius: var(--p-border-radius-1);
  }

  & tbody > tr > td:last-child {
    border-top-right-radius: var(--p-border-radius-1);
    border-bottom-right-radius: var(--p-border-radius-1);
  }

  .Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell {
    border-top: none;
  }

  padding: 0 var(--p-space-4) var(--p-space-4);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-1);
  box-shadow: var(--p-shadow-card);

  // For small viewports - reduce gap of page and adjust table row "card" corners
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    & table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    padding: 0;
    box-shadow: none;

    // select the second table cell since the first one (selection checkboxes) is hidden
    & tbody > tr > td:nth-child(2) {
      border-top-left-radius: var(--p-border-radius-2);
      border-top-right-radius: var(--p-border-radius-2);
    }

    & tbody > tr > td:last-child {
      border-bottom-left-radius: var(--p-border-radius-2);
      border-bottom-right-radius: var(--p-border-radius-2);
    }
  }
`;

const StyledTabs = styled(KDKTabs)`
  & > .Polaris-HorizontalStack {
    margin: 0 20%;
  }

  & .Polaris-Box {
    margin-bottom: var(--p-space-4);
  }

  // For small viewports - reduce gap of page
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    & > .Polaris-HorizontalStack {
      margin: 0;
    }
  }
`;

const StyledSelectionWrapper = styled.div`
  display: flex;
  min-height: calc(var(--p-font-line-height-4) + var(--p-border-width-1)); // 2px to account for border width
  align-items: center;
`;

export default CustomersListPage;
