import { generatePath } from "react-router-dom";

import IconApproveReview from "../../assets/icons/status-approve-review.svg";
import IconFollowup from "../../assets/icons/status-follow-up.svg";
import IconOpenMatches from "../../assets/icons/status-open-matches.svg";
import IconOpenMeasures from "../../assets/icons/status-open-measures.svg";
import IconOwnershipUpdates from "../../assets/icons/status-ownership-updates.svg";
import IconReady from "../../assets/icons/status-ready.svg";
import IconStarted from "../../assets/icons/status-started.svg";
import IconWaiting from "../../assets/icons/status-waiting.svg";
import api from "../api";
import { PAGE_ANCHORS } from "../constants/page-anchors";
import { ROUTES } from "../constants/routes";
import { PendingTask, TaskStatus, TaskStatuses } from "../types/PendingTask";
import { Customer, isManualCompany } from "../types/utilities";
import { daysBetweenDates } from "../utils/dateUtils";
import { isEmptyString } from "../utils/stringUtils";

import useCustomerPath from "./useCustomerPath";
import useFormatMessage from "./useFormatMessage";
import useFormatMultipleNames from "./useFormatMultipleNames";
import useGetUserName from "./useGetUserName";

const usePendingTask = (customer: Customer) => {
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();
  const { getUserDisplayName } = useGetUserName();
  const { getCustomerPath } = useCustomerPath();

  const customerUrl = getCustomerPath(customer.id, customer.type!);

  const getFinishOnboardingTask = (task: api.FinishOnboardingTask): TaskStatus => {
    const { onboarding_status } = task;

    return {
      title: f("onboarding.status.started"),
      icon: IconStarted,
      url: getCustomerPath(customer.id, customer.type!, onboarding_status, isManualCompany(customer)),
      urlLabel: f("pending.tasks.labels.finish-onboarding")
    };
  };

  const getPendingDisclosuresTask = (task: api.CheckCustomerDisclosuresTask): TaskStatus => {
    const { count } = task;

    const anchor = PAGE_ANCHORS.DISCLOSURES_SECTION;
    const url = `${customerUrl}#${anchor}`;
    const urlLabel = f("pending.tasks.labels.see-pending-disclosures", { count });

    return {
      title: f("pending-tasks.labels.awaiting-disclosures", { count }),
      icon: IconWaiting,
      url,
      urlLabel,
      anchor
    };
  };

  const getPendingDocumentsTask = (task: api.CheckCustomerAttachmentsTask): TaskStatus => {
    const { count } = task;

    const anchor = PAGE_ANCHORS.CUSTOMER_DOCUMENTS;
    const url = `${customerUrl}#${anchor}`;
    const urlLabel = f("pending.tasks.labels.see-pending-documents", { count });

    return {
      title: f("pending-tasks.labels.awaiting-documents", { count }),
      icon: IconWaiting,
      url,
      anchor,
      urlLabel
    };
  };

  const getReviewCustomerTask = () => {
    const reviewPageURL = generatePath(ROUTES.CUSTOMER_REVIEW_NEW, { customerId: customer.id });

    const url = reviewPageURL;

    return {
      title: f("onboarding.status.ready-for-review"),
      icon: IconReady,
      url,
      urlLabel: f("pending.tasks.labels.review-customer")
    };
  };

  const getDraftReviewTask = (task: api.ContinueDraftReviewsTask) => {
    const count = task.review_ids.length;

    if (count === 1) {
      const url = generatePath(ROUTES.CUSTOMER_REVIEW, { customerId: customer.id, id: task.review_ids[0] });

      return {
        title: f("onboarding.status.draft-review", { count }),
        icon: IconWaiting,
        url,
        urlLabel: f("pending.tasks.labels.open-draft-review")
      };
    } else {
      const anchor = PAGE_ANCHORS.PENDING_REVIEWS_SECTION;
      const url = `${customerUrl}#${anchor}`;

      return {
        title: f("onboarding.status.draft-review", { count }),
        icon: IconWaiting,
        url,
        urlLabel: f("pending.tasks.labels.see-draft-reviews"),
        anchor
      };
    }
  };

  const getOwnershipUpdatesTask = () => {
    const anchor = PAGE_ANCHORS.COMPANY_ROLES_AND_OWNERS_SECTION;
    const url = `${customerUrl}#${anchor}`;

    return {
      title: f("ownership-update.info.label"),
      icon: IconOwnershipUpdates,
      url,
      urlLabel: f("pending.tasks.labels.review-ownership-updates"),
      anchor
    };
  };

  const getReviewMatchesTask = (task: api.ReviewMatchesTask): TaskStatus => {
    const { count, match_case_ids } = task;

    const anchor = PAGE_ANCHORS.CUSTOMER_MATCH_CASES;
    const customerDetailsUrl = `${customerUrl}#${anchor}`;

    // if count === 1 -> change the URL to the single match case page
    const url =
      count === 1 && match_case_ids && match_case_ids.length === 1
        ? generatePath(ROUTES.MATCH_CASE_DETAILS, { customerId: customer.id, id: match_case_ids[0] })
        : customerDetailsUrl;

    // TODO if count > 1 -> generate multiple tasks and a summary task as well

    return {
      title: f("pending-tasks.labels.open-matches", { count }),
      icon: IconOpenMatches,
      url,
      urlLabel: f("pending.tasks.labels.review-matches", { count }),
      anchor: count === 1 ? undefined : anchor
    };
  };

  const getFollowUpCustomerTask = (task: api.FollowUpCustomerTask): TaskStatus => {
    const { has_expired, follow_up_date } = task;

    const daysUntilExpire = has_expired ? -1 : daysBetweenDates(follow_up_date, new Date().toISOString());

    return {
      title: f("pending.tasks.status.followup", { days: daysUntilExpire }),
      icon: IconFollowup,
      url: customerUrl,
      urlLabel: f("pending.tasks.labels.followup-customer")
    };
  };

  const getApproveCustomerReviewTask = (task: api.ApproveCustomerReviewTask): TaskStatus => {
    const { is_possible_to_approve, approval_users, approval_request_date, review_id } = task;

    if (is_possible_to_approve) {
      const pendingReviewsSectionURL = `${customerUrl}#${PAGE_ANCHORS.PENDING_REVIEWS_SECTION}`;
      const reviewPageURL = review_id
        ? generatePath(ROUTES.CUSTOMER_REVIEW, { customerId: customer.id, id: review_id })
        : pendingReviewsSectionURL;

      const url = review_id ? reviewPageURL : pendingReviewsSectionURL;
      const anchor = review_id ? undefined : PAGE_ANCHORS.PENDING_REVIEWS_SECTION;

      return {
        title: f("pending.tasks.status.approve-review"),
        icon: IconApproveReview,
        url,
        urlLabel: f("pending.tasks.labels.approve-review"),
        anchor
      };
    } else {
      const approversNames = formatMultipleNames(
        approval_users?.map((user) => getUserDisplayName(user)),
        1
      );

      return {
        title: f("pending.tasks.status.waiting-for-approval"),
        subtitle:
          !isEmptyString(approversNames) && approval_request_date
            ? f("pending.tasks.status.waiting-for-approval.subtitle", {
                names: approversNames,
                timestamp: new Date(approval_request_date)
              })
            : undefined,
        icon: IconApproveReview,
        anchor: PAGE_ANCHORS.PENDING_REVIEWS_SECTION
      };
    }
  };

  const getCompletePendingMeasureTask = (task: api.CompletePendingMeasuresTask): TaskStatus => {
    const { count } = task;

    const anchor = PAGE_ANCHORS.REVIEWS_SECTION;
    const url = `${customerUrl}#${anchor}`;

    return {
      title: f("pending.tasks.status.review-measures", { count }),
      icon: IconOpenMeasures,
      url,
      urlLabel: f("pending.tasks.labels.review-measures", { count }),
      anchor
    };
  };

  const getPendingTaskStatuses = (pendingTask: PendingTask): TaskStatuses => {
    switch (pendingTask.type) {
      case "APPROVE_CUSTOMER_REVIEW": {
        return [getApproveCustomerReviewTask(pendingTask)];
      }
      case "COMPLETE_PENDING_MEASURES": {
        return [getCompletePendingMeasureTask(pendingTask)];
      }
      case "FINISH_ONBOARDING": {
        return [getFinishOnboardingTask(pendingTask)];
      }
      case "FOLLOW_UP_CUSTOMER": {
        return [getFollowUpCustomerTask(pendingTask)];
      }
      case "REVIEW_CUSTOMER": {
        return [getReviewCustomerTask()];
      }
      case "CONTINUE_DRAFT_REVIEWS": {
        return [getDraftReviewTask(pendingTask)];
      }
      case "REVIEW_MATCHES": {
        return [getReviewMatchesTask(pendingTask)];
      }
      case "REVIEW_OWNERSHIP_UPDATES": {
        return [getOwnershipUpdatesTask()];
      }
      case "CHECK_CUSTOMER_DISCLOSURES": {
        return [getPendingDisclosuresTask(pendingTask)];
      }
      case "CHECK_CUSTOMER_ATTACHMENTS": {
        return [getPendingDocumentsTask(pendingTask)];
      }
      default:
        return [];
    }
  };

  return getPendingTaskStatuses;
};

export default usePendingTask;
