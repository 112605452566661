import React, { forwardRef } from "react";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import EditIcon from "../../../assets/icons/pencil.svg";
import api from "../../api";
import { isNewMeasure } from "../../helpers/measures.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import useOpenClose from "../../hooks/useOpenClose";
import { isEmptyString } from "../../utils/stringUtils";
import CondensedText from "../CondensedText/CondensedText";
import CustomerReviewMeasureTitle from "../CustomerReviewMeasureTitle/CustomerReviewMeasureTitle";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import KDKBanner from "../KDKBanner/KDKBanner";
import MeasureComment from "../MeasureComment/MeasureComment";
import MeasuresContainer from "../MeasureContainer/MeasuresContainer";
import MoveButtons, { Direction } from "../MoveButtons/MoveButtons";

import CustomerReviewMeasureDeleteButton from "./CustomerReviewMeasureDeleteButton";
import CustomerReviewMeasureForm, { CustomerReviewMeasureFormProps } from "./CustomerReviewMeasureForm";

interface CustomerReviewMeasureProps extends CustomerReviewMeasureFormProps {
  isFirst: boolean;
  isLast: boolean;
  onOrderChange(direction: Direction): void;
  onAfterRemove(measure: api.Measure): void;
}

const CustomerReviewMeasure = forwardRef<HTMLDivElement, CustomerReviewMeasureProps>((props, ref) => {
  const { measure, readonly, isFirst, isLast, onChange, onCancel, onAfterRemove, onOrderChange, ...measuresProps } =
    props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const [isEditing, toggleEditing, closeEditing] = useOpenClose(isNewMeasure(measure) && isFirst);

  const showComment = !isEditing;
  const showFooter = !isEditing && !measure.is_completed && !measure.completed_dt;
  const isEmptyComment = isEmptyString(measure.completed_comment);

  const editButtonLabel = isEmptyComment ? f("details.reviews.measures.edit-measure") : f("default.edit");

  const moveMeasureMutation = useMutation<unknown, unknown, Direction>(
    (direction) =>
      api.moveCustomerReviewMeasure(measuresProps.customerId, measuresProps.reviewId, measure.id, {
        direction: direction === "up" ? "UP" : "DOWN"
      }),
    {
      onMutate: onOrderChange
    }
  );

  const handleMeasureChange = (updatedMeasure: api.Measure) => {
    closeEditing();
    onChange(updatedMeasure);
  };

  const handleMeasureCancel = (measure: api.Measure) => {
    closeEditing();
    onCancel(measure);
  };

  return (
    <MeasuresContainer ref={ref}>
      <KDKBanner hideBorder hideIcon>
        <VerticalStack gap="4">
          <VerticalStack gap="2" inlineAlign={"start"}>
            <CustomerReviewMeasureTitle measure={measure} />
            <DescriptionText>{measure.description}</DescriptionText>
          </VerticalStack>
          {showComment && <MeasureComment measure={measure} />}
          {isEditing && (
            <CustomerReviewMeasureForm
              measure={measure}
              readonly={readonly}
              onChange={handleMeasureChange}
              onCancel={handleMeasureCancel}
              {...measuresProps}
            />
          )}
          <HorizontalStack align={"space-between"}>
            {measure.is_completed && measure.completed_dt ? (
              <CondensedText italic>
                {f("common.labels.completed-by", {
                  name: getUserDisplayName(measure.completed_by),
                  timestamp: new Date(measure.completed_dt)
                })}
              </CondensedText>
            ) : (
              <div />
            )}
            {showFooter && (
              <HorizontalStack gap="4">
                <CustomerReviewMeasureDeleteButton
                  measure={measure}
                  {...measuresProps}
                  readonly={readonly}
                  onAfterRemove={onAfterRemove}
                />
                <Button
                  primary
                  size={"slim"}
                  icon={isEmptyComment ? undefined : <Icon source={EditIcon} useMask />}
                  onClick={toggleEditing}
                  disabled={readonly}
                >
                  {editButtonLabel}
                </Button>
              </HorizontalStack>
            )}
          </HorizontalStack>
        </VerticalStack>
      </KDKBanner>
      <MoveButtons
        spacing="extraTight"
        disableMoveUp={isFirst}
        disableMoveDown={isLast}
        readonly={readonly}
        onOrderChange={(direction) => moveMeasureMutation.mutate(direction)}
      />
    </MeasuresContainer>
  );
});

const DescriptionText = styled.div`
  white-space: pre;
`;

export default CustomerReviewMeasure;
