import React from "react";
import { Divider, HorizontalStack, OptionList, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../../api";
import { LANGUAGES_FLAGS } from "../../../../constants/languages";
import { createNewChoice, isChoicesQuestion, isQuestionnaireQuestion } from "../../../../helpers/questions.helpers";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import { Question } from "../../../../types/Questions";
import PopoverButton from "../../../PopoverButton/PopoverButton";
import ToggleInput from "../../../ToggleInput/ToggleInput";
import TranslationTextField from "../../../TranslationTextField/TranslationTextField";
import VerticalDivider from "../../../VerticalDivider/VerticalDivider";

import ChoicesTable from "./ChoicesTable/ChoicesTable";
import useQuestionTypesOptions from "./useQuestionTypesOptions";

type QuestionEditorProps = {
  question: Question;
  autofocus: boolean;
  readonly?: boolean;
  isValidQuestion(question: Question): string | undefined;
  onChange(question: Question): void;
};

const QuestionEditor = (props: QuestionEditorProps) => {
  const { question, autofocus, readonly, isValidQuestion, onChange } = props;

  const f = useFormatMessage();

  const questionTypeOptions = useQuestionTypesOptions();

  const handleQuestionTextChange = (text: api.TranslationText) => onChange({ ...question, text });

  const handleQuestionTypeChange = (type: api.QuestionType) => {
    if (isQuestionnaireQuestion(question) && isChoicesQuestion(type)) {
      if (!question.choices || question.choices.length === 0) {
        // question support choices but the choices list is empty - add a default empty choice
        onChange({ ...question, type, choices: [createNewChoice()] });
      } else {
        // question support choices but the choices list is not empty - keep the choices
        // this means that the user switched between single/multiple question type and there's no need to clear the list
        onChange({ ...question, type });
      }
    } else {
      // question is not choices type so clear the choices list
      onChange({ ...question, type, choices: undefined });
    }
  };

  const handleChoicesChange = (choices: api.QuestionChoice[]) => onChange({ ...question, choices });

  const questionDescriptionLabels: Record<api.LanguageEnum, string> = {
    en: f("questionnaire.question.description.label", {
      language: `${f("common.language.english")} ${LANGUAGES_FLAGS["en"]}`
    }),
    nb: f("questionnaire.question.description.label", {
      language: `${f("common.language.norwegian")} ${LANGUAGES_FLAGS["nb"]}`
    })
  };

  const showChoicesTable = isQuestionnaireQuestion(question) && isChoicesQuestion(question.type);

  const errorMessage = isValidQuestion(question);

  return (
    <VerticalStack>
      <StyledContent>
        <VerticalStack gap="4">
          <TranslationTextField
            text={question.text}
            requiredIndicator
            vertical
            getLabel={(language) => questionDescriptionLabels[language]}
            autoComplete="on"
            disabled={readonly}
            autoFocus={autofocus}
            onChange={handleQuestionTextChange}
            error={errorMessage}
          />
          {showChoicesTable && (
            <ChoicesTable choices={question.choices || []} onChange={handleChoicesChange} readonly={readonly} />
          )}
        </VerticalStack>
      </StyledContent>
      {isQuestionnaireQuestion(question) && (
        <>
          <Divider />
          <StyledFooter>
            <HorizontalStack gap="4" blockAlign="stretch">
              <PopoverButton
                size="slim"
                disclosure="select"
                disabled={readonly}
                fluidContent
                label={questionTypeOptions.find((option) => option.value === question.type)?.label}
                render={(onClose) => (
                  <OptionList
                    selected={[question.type]}
                    options={questionTypeOptions}
                    onChange={(selected) => {
                      handleQuestionTypeChange(selected[0] as api.QuestionType);
                      onClose();
                    }}
                  />
                )}
              />
              <VerticalDivider />
              <ToggleInput
                label={f("default.mandatory")}
                checked={question.is_required}
                onChange={() => onChange({ ...question, is_required: !question.is_required })}
                disabled={readonly}
              />
            </HorizontalStack>
          </StyledFooter>
        </>
      )}
    </VerticalStack>
  );
};

const StyledContent = styled.div`
  padding: 0 var(--p-space-4) var(--p-space-4) var(--p-space-4);

  width: 75%;
`;

const StyledFooter = styled.div`
  padding: var(--p-space-4) var(--p-space-4) 0 var(--p-space-4);
`;

export default QuestionEditor;
