import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import ProjectIcon from "../../../assets/icons/project.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import AssignmentPurpose from "../AssignmentPurpose/AssignmentPurpose";
import Icon from "../extensions/Icon";
import VerticalDivider from "../VerticalDivider/VerticalDivider";

type OnboardingSummaryAssignmentInfoProps = {
  assignment?: api.Assignment;
};

const OnboardingSummaryAssignmentInfo = (props: OnboardingSummaryAssignmentInfoProps) => {
  const { assignment } = props;

  const f = useFormatMessage();

  return (
    <Container>
      <VerticalStack gap="2">
        <HorizontalStack gap="1" blockAlign={"center"} wrap={false}>
          <Icon source={ProjectIcon} width="16px" height="12px" />
          <Text as={"h3"} fontWeight={"bold"}>
            {f("customer-assignments.modal.assignment-name")}
          </Text>
        </HorizontalStack>
        <Text as={"p"}>{assignment?.name}</Text>
      </VerticalStack>
      <VerticalDivider />
      <VerticalStack gap="2">
        <HorizontalStack gap="1" blockAlign={"center"} wrap={false}>
          <Icon source={ProjectIcon} width="16px" height="12px" />
          <Text as={"h3"} fontWeight={"bold"}>
            {f("customer-assignments.details.assignment-purpose")}
          </Text>
        </HorizontalStack>
        <Text as={"p"}>{assignment ? <AssignmentPurpose assignment={assignment} /> : "-"}</Text>
      </VerticalStack>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr var(--p-space-4) 1fr;
`;

export default OnboardingSummaryAssignmentInfo;
