import React, { useMemo, useState } from "react";
import { ChoiceListProps } from "@shopify/polaris";

import api from "../../api";
import { getTagTitle } from "../../helpers/tag.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import IndeterminateChoiceList from "../IndeterminateChoiceList/IndeterminateChoiceList";
import KDKTag from "../KDKTag/KDKTag";

export interface TagsChoiceListProps {
  tags?: api.Label[];
  selectedTags?: api.Label[];
  partiallySelectedTags?: api.Label[];
  disabled?: boolean;
  filterValue?: string;
  titleHidden?: boolean;
  enableReadonlySelection?: boolean;
  onSelectionChange(selectedTags: api.Label[]): void;
}

const TagsChoiceList = (props: TagsChoiceListProps) => {
  const {
    tags = [],
    selectedTags: initialSelection = [],
    partiallySelectedTags = [],
    disabled,
    filterValue = "",
    titleHidden,
    enableReadonlySelection,
    onSelectionChange
  } = props;

  const f = useFormatMessage();
  const [selectedTags, setSelectedTags] = useState(initialSelection);

  const selectedTagIds = selectedTags.map((tag) => tag.id);
  const partiallySelectedTagIds = partiallySelectedTags.map((tag) => tag.id);

  const choices: ChoiceListProps["choices"] = useMemo(
    () =>
      tags
        .filter((tag) => getTagTitle(tag).toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()))
        .map((tag) => ({
          label: <KDKTag tag={tag} highlightText={filterValue.toLocaleLowerCase()} />,
          value: tag.id,
          disabled: !enableReadonlySelection && tag.read_only
        })),
    [tags, filterValue]
  );

  const handleSelectionChange = (selectedIds: string[]) => {
    const filteredTags = tags.filter((tag) => selectedIds.includes(tag.id));

    onSelectionChange(filteredTags);
    setSelectedTags(filteredTags);
  };

  if (choices.length === 0) {
    return null;
  }

  return (
    <IndeterminateChoiceList
      title={f("tags.editor.existing-tags")}
      titleHidden={titleHidden}
      allowMultiple
      choices={choices}
      selected={selectedTagIds}
      indeterminate={partiallySelectedTagIds}
      disabled={disabled}
      onChange={handleSelectionChange}
    />
  );
};

export default TagsChoiceList;
