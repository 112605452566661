import { HorizontalStack } from "@shopify/polaris";
import CheckIcon from "jsx:../../../assets/icons/checkmark-green.svg";
import styled from "styled-components";

import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetDisclosureCountryRiskInformation from "../../hooks/useGetDisclosureCountryRiskInformation";
import CondensedText from "../CondensedText/CondensedText";
import CustomDate from "../CustomDate/CustomDate";
import CompletedDisclosureDocumentSignature from "../DisclosureDocumentSection/DisclosureDocumentSignature/CompletedDisclosureDocumentSignature";
import DisplayName from "../DisplayName/DisplayName";
import FlatCard, { StyledFlatCardFooter } from "../FlatCard/FlatCard";
import WarningBadge from "../WarningBadge/WarningBadge";

type CompletedSignatureProps = {
  signature: api.Signature;
};

const CompletedSignature = (props: CompletedSignatureProps) => {
  const { signature } = props;

  const f = useFormatMessage();

  const { getSignatureRiskAnswersCount } = useGetDisclosureCountryRiskInformation();

  const signatureRiskAnswersCount = getSignatureRiskAnswersCount(signature);
  const haveHighRiskAnswers = signatureRiskAnswersCount > 0;

  return (
    <StyledFlatCard
      key={signature.id}
      footer={
        <CompletedDisclosureDocumentSignature signature={signature} hideSignedUserInfo useSmallButton vertical>
          {haveHighRiskAnswers && <WarningBadge>{f("common.labels.risk-response")}</WarningBadge>}
        </CompletedDisclosureDocumentSignature>
      }
    >
      <HorizontalStack gap="2" align={"space-between"} blockAlign={"center"}>
        <HorizontalStack gap="1" blockAlign={"center"} wrap={false}>
          <CheckIcon />
          <DisplayName name={getFullName(signature.person)} />
        </HorizontalStack>
        <CondensedText>
          <CustomDate date={signature.signed_dt} />
        </CondensedText>
      </HorizontalStack>
    </StyledFlatCard>
  );
};

const StyledFlatCard = styled(FlatCard)`
  border-color: var(--p-border-success-subdued);
  background-color: var(--p-surface-success-subdued);

  ${StyledFlatCardFooter} {
    border-top: none;
    border-color: var(--p-border-success-subdued);
    background-color: var(--p-surface-success-subdued);
  }
`;

export default CompletedSignature;
