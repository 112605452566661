import React, { forwardRef, PropsWithChildren } from "react";
import { VerticalStack, VerticalStackProps } from "@shopify/polaris";
import styled from "styled-components";

const DashboardVerticalStack = forwardRef<HTMLDivElement, PropsWithChildren<VerticalStackProps>>((props, ref) => {
  const { ...stackProps } = props;

  return (
    <StyledStackWrapper ref={ref}>
      <VerticalStack {...stackProps} />
    </StyledStackWrapper>
  );
});

const StyledStackWrapper = styled.div`
  height: 100%;

  & > .Polaris-VerticalStack {
    height: 100%;
  }

  & > .Polaris-VerticalStack > * + * {
    border-top: var(--p-border-divider);
  }
`;

export default DashboardVerticalStack;
