import { IMPORT_STATUS } from "../types/ImportRow";

export const ORG_ID_COLUMN = "Org. ID";
export const NAME_COLUMN = "Name";
export const RESPONSIBLE_USER_EMAIL_COLUMN = "Responsible user email";
export const EXTERNAL_ID_COLUMN = "External ID";
export const IGNORE_COLUMN = "Ignore";

export const ERROR_STATUSES: IMPORT_STATUS[] = ["VALIDATION_ERROR", "IMPORT_ERROR"];

export const PAGE_SIZES = ["10", "50", "100", "200", "500", "1000", "5000"];

export const DEFAULT_PAGE_SIZE = 200;
