import React, { ReactElement, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { QUERIES_KEYS } from "../../constants/queries-keys";
import usePageNavigation from "../../hooks/usePageNavigation";
import ErrorPage from "../../pages/ErrorPage";
import { removeAnchor } from "../../utils/browserUtils";

export type AsyncPageChild<DATA> = {
  data: DATA;
};

type AsyncProps<DATA> = {
  queryKey: QUERIES_KEYS;
  apiFunction: (...params: any[]) => Promise<DATA>;
  skeleton: JSX.Element;
  paramNames: string[];
  render(data: DATA, props: object): ReactElement;
};

function AsyncPage<DATA>(props: AsyncProps<DATA>) {
  const { queryKey, apiFunction, skeleton = <div>Loading...</div>, paramNames = [], render, ...pageProps } = props;

  const params: Record<string, string> = useParams();
  const paramValues = paramNames.map((paramName) => removeAnchor(params[paramName]));

  const queryClient = useQueryClient();
  const { navigateInPage } = usePageNavigation();

  const reactQueryKey = [queryKey, ...paramValues];

  const { isLoading, isError, error, data } = useQuery(
    reactQueryKey,
    ({ signal }) => apiFunction(...paramValues, { signal }),
    {
      /**
       * Default query retry function
       * Retry 3 times before failing
       */
      retry: 3
    }
  );

  useLayoutEffect(() => {
    // do not depend on data changes when navigating into a page
    // because that will cause any change in the data to re-scroll the page
    // use isLoading and isError in order to navigate into the page only once
    // after the data was fetched
    if (isLoading || isError) {
      return;
    }

    const handler = requestAnimationFrame(() => {
      navigateInPage(location.hash ? location.hash.substring(1) : "root", { block: "start" });
    });

    return () => {
      cancelAnimationFrame(handler);
    };
  }, [isLoading, isError]);

  if (isLoading) {
    return skeleton;
  }

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (!data) {
    const queryData = queryClient.getQueryData(reactQueryKey);
    Sentry.addBreadcrumb({
      category: "rendering",
      level: "warning",
      message: `Data is undefined for query key: ${reactQueryKey.toString()}`,
      data: { reactQueryKey, data, queryData }
    });
  }

  return render(data, pageProps);
}

export function withAsyncPage<DATA>(
  Component: React.FunctionComponent<AsyncPageChild<DATA>>,
  { ...props }: Omit<AsyncProps<DATA>, "render">
): React.FunctionComponent {
  return () => (
    <AsyncPage {...props} render={(data, ...componentProps) => <Component data={data} {...componentProps} />} />
  );
}
