import React from "react";
import { Checkbox, DataTable, Modal, Text, Toast } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../api";
import { getCustomerName, getFullName } from "../../../helpers/display.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../../hooks/useFormatMultipleNames";
import useOpenClose from "../../../hooks/useOpenClose";
import { Customer } from "../../../types/utilities";
import DisplayName from "../../DisplayName/DisplayName";
import EmailView from "../../EmailView/EmailView";
import ErrorPanel from "../../ErrorPanel/ErrorPanel";
import RowHighlighter from "../../extensions/RowHighlighter";

import useSetResponsibleUser from "./useSetResponsibleUser";

interface ResponsibleUsersModalProps {
  customers: Customer[];
  onClose(): void;
}

const ResponsibleUsersModal = (props: ResponsibleUsersModalProps) => {
  const { customers, onClose } = props;

  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();

  const {
    isLoadingUsers,
    hasError,
    error,
    users,
    isValid,
    isUpdating,
    areAllChecked,
    handleSelectAll,
    isUserChecked,
    handleUserSelection,
    setResponsibleUsers
  } = useSetResponsibleUser(customers);

  const saveAction = {
    content: f("default.save"),
    onAction: async () => {
      await setResponsibleUsers();
      toggleSuccessMessageToast();
    },
    disabled: !isValid,
    loading: isUpdating
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: () => onClose(),
    disabled: isLoadingUsers || isUpdating
  };

  const renderRow = (user: api.User) => [
    <Checkbox checked={isUserChecked(user)} onChange={() => handleUserSelection(user)} label="" labelHidden />,
    <RowHighlighter subdued={!user.is_active}>
      <DisplayName type="person" name={getFullName(user)} />
    </RowHighlighter>,
    <StyledEmailView>{user.email}</StyledEmailView>
  ];

  const customersNamesLabel = formatMultipleNames(customers.map(getCustomerName));

  const handleToastDismiss = () => {
    closeSuccessMessageToast();
    onClose();
  };

  return (
    <>
      <Modal
        open={!showSuccessMessageToast}
        title={f("responsible.modal.title")}
        onClose={onClose}
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
        noScroll
        loading={isLoadingUsers}
        footer={
          <Text variant="bodyMd" as="span">
            {f("common.labels.selected-customers-count", { count: customers.length })}
          </Text>
        }
      >
        <Modal.Section>
          <Text variant="bodyMd" as="span">
            {f("responsible.modal.message", { names: customersNamesLabel })}
          </Text>
          <DataTable
            columnContentTypes={["text", "text", "text"]}
            headings={[
              <Checkbox checked={areAllChecked} label="" labelHidden onChange={handleSelectAll} />,
              f("table.column.name"),
              f("table.column.email")
            ]}
            rows={users.map(renderRow)}
          />
        </Modal.Section>
        {hasError && <ErrorPanel message={error} />}
      </Modal>
      {showSuccessMessageToast && (
        <Toast onDismiss={handleToastDismiss} content={f("bulk.actions.responsible.success.message")} />
      )}
    </>
  );
};

const StyledEmailView = styled(EmailView)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 28ch;
`;

export default ResponsibleUsersModal;
