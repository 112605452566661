import { PropsWithChildren } from "react";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import CondensedText from "../CondensedText/CondensedText";
import DataTableCellChild from "../DataTableCellChild/DataTableCellChild";

type ComparisonTableCellProps = {
  label: string;
};

const ComparisonTableCell = (props: PropsWithChildren<ComparisonTableCellProps>) => {
  const { label, children } = props;

  return (
    <DataTableCellChild width={"50%"}>
      <VerticalStack>
        <StyledText>{label}</StyledText>
        <HorizontalStack align="space-between" blockAlign="baseline">
          {children}
        </HorizontalStack>
      </VerticalStack>
    </DataTableCellChild>
  );
};

const StyledText = styled(CondensedText)`
  text-transform: uppercase;
`;

export default ComparisonTableCell;
