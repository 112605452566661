import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import CheckmarkIcon from "../../../../../assets/icons/checkmark.svg";
import InfoIcon from "../../../../../assets/icons/info-circle.svg";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import Icon from "../../../extensions/Icon";

interface AttachmentSignaturesModalFooterProps {
  hasUnsavedChanges: boolean;
  savedSuccessfully: boolean;
}

const AttachmentSignaturesModalFooter = (props: AttachmentSignaturesModalFooterProps) => {
  const { hasUnsavedChanges, savedSuccessfully } = props;

  const f = useFormatMessage();

  if (!savedSuccessfully && !hasUnsavedChanges) {
    return null;
  }

  return (
    <StyledChangeLabel hasUnsavedChanges={hasUnsavedChanges}>
      <HorizontalStack gap="1" blockAlign="center">
        <Icon source={hasUnsavedChanges ? InfoIcon : CheckmarkIcon} useMask />
        <Text variant="bodySm" as="p">
          {hasUnsavedChanges && f("attachments.signatures.modal.labels.unsaved-changes")}
          {!hasUnsavedChanges && savedSuccessfully && f("attachments.signatures.modal.labels.saved-changes")}
        </Text>
      </HorizontalStack>
    </StyledChangeLabel>
  );
};

const StyledChangeLabel = styled.div<{ hasUnsavedChanges: boolean }>`
  color: ${({ hasUnsavedChanges }) => (hasUnsavedChanges ? "var(--p-text-subdued)" : "var(--kdk-dark-success)")};
`;

export default AttachmentSignaturesModalFooter;
