import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { convertOwnerToUpdateOwnerRequest } from "../helpers/owners.helpers";
import { getCustomerQueryKey } from "../types/utilities";
import { updateItem } from "../utils/collectionUtils";

import useQueryData from "./useQueryData";

const useRestoreOwnerMutation = (customerId: string, owner: api.Owner) => {
  const queryKey = getCustomerQueryKey({ id: customerId, type: "company" });
  const { updateQueryData } = useQueryData<api.CompanyCustomerDetailResponse>(queryKey);

  const updateOwnerRequest = { ...convertOwnerToUpdateOwnerRequest(owner), deleted: false };

  return useMutation(() => api.updateCompanyOwner(customerId, owner.id!, updateOwnerRequest), {
    onSuccess: (updateOwner) => {
      updateQueryData((customer) => (customer.beneficial_owners = updateItem(customer.beneficial_owners, updateOwner)));
    }
  });
};

export default useRestoreOwnerMutation;
