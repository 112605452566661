import { isEmptyString } from "../utils/stringUtils";

import { useSessionStorage } from "./useStorage";

const useClearLastWindowLocationSearch = (key: string) => {
  const [lastState, setLastState] = useSessionStorage(key, "");

  const clearLastWindowLocationSearch = () => {
    if (!isEmptyString(lastState)) {
      setLastState("");
    }
  };

  return clearLastWindowLocationSearch;
};

export default useClearLastWindowLocationSearch;
