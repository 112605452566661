import React from "react";
import { HorizontalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../../../api";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useOpenClose from "../../../../hooks/useOpenClose";
import { CustomerDetails } from "../../../../types/utilities";
import Button from "../../../extensions/Button";
import OwnershipUpdateRejectConfirmation from "../../OwnershipUpdateRejectConfirmation/OwnershipUpdateRejectConfirmation";

export interface OwnershipUpdateActionsProps {
  customer: CustomerDetails;
  update: api.OwnershipUpdate;
  readonly?: boolean;
  onOwnershipUpdateReject(update: api.OwnershipUpdate): void;
  onOwnershipUpdateConfirm(update: api.OwnershipUpdate, updatedOwner: api.Owner): void;
}

const OwnershipUpdateActions = (props: OwnershipUpdateActionsProps) => {
  const { customer, update, readonly, onOwnershipUpdateReject, onOwnershipUpdateConfirm } = props;

  const f = useFormatMessage();
  const [showRejectConfirmation, toggleRejectConfirmation, closeRejectConfirmation] = useOpenClose();

  const confirmUpdateMutation = useMutation(() => api.confirmOwnershipUpdate(customer.id, update.id), {
    onSuccess: (updatedOwner) => onOwnershipUpdateConfirm(update, updatedOwner)
  });

  const handleConfirm = () => confirmUpdateMutation.mutate();

  return (
    <>
      <HorizontalStack gap="2" wrap={false}>
        <Button
          onClick={toggleRejectConfirmation}
          plain
          destructive
          size="slim"
          disabled={confirmUpdateMutation.isLoading || readonly}
        >
          {f("ownership-update.actions.labels.ignore")}
        </Button>
        <Button onClick={handleConfirm} size="slim" loading={confirmUpdateMutation.isLoading} disabled={readonly}>
          {f("default.confirm")}
        </Button>
      </HorizontalStack>
      {showRejectConfirmation && (
        <OwnershipUpdateRejectConfirmation
          customer={customer}
          update={update}
          onClose={closeRejectConfirmation}
          onSuccess={onOwnershipUpdateReject}
        />
      )}
    </>
  );
};

export default OwnershipUpdateActions;
