import { useIntl } from "react-intl";
import { AppliedFilterInterface } from "@shopify/polaris";

import { DateRange } from "../../constants/pagination";
import useFormatMessage from "../../hooks/useFormatMessage";

interface useFollowupDataAppliedFiltersProps {
  followupRange?: DateRange;
  followupBefore?: string;
  followupAfter?: string;
  setFollowupRange(followupRange?: string): void;
  setFollowupAfter(followupBefore?: string): void;
  setFollowupBefore(followupAfter?: string): void;
}

const useFollowupDataAppliedFilters = (props: useFollowupDataAppliedFiltersProps) => {
  const { followupRange, followupBefore, followupAfter, setFollowupRange, setFollowupAfter, setFollowupBefore } = props;

  const f = useFormatMessage();
  const { formatDate } = useIntl();

  const appliedFollowupDateFilters: AppliedFilterInterface[] = [];

  if (followupRange && followupRange !== DateRange.CUSTOM) {
    appliedFollowupDateFilters.push({
      key: "followup",
      label: f(`component.filters.followup.applied.range.${followupRange}`),
      onRemove: () => setFollowupRange(undefined)
    });
  }

  if (followupAfter && followupRange === DateRange.CUSTOM) {
    appliedFollowupDateFilters.push({
      key: "followup-after",
      label: f("component.filters.followup.labels.after", { date: formatDate(followupAfter) }),
      onRemove: () => {
        setFollowupAfter(undefined);
        if (!followupBefore) {
          setFollowupRange(undefined);
        }
      }
    });
  }

  if (followupBefore && followupRange === DateRange.CUSTOM) {
    appliedFollowupDateFilters.push({
      key: "followup-before",
      label: f("component.filters.followup.labels.before", { date: formatDate(followupBefore) }),
      onRemove: (followupAfter) => {
        setFollowupBefore(undefined);
        if (!followupAfter) {
          setFollowupRange(undefined);
        }
      }
    });
  }

  return appliedFollowupDateFilters;
};

export default useFollowupDataAppliedFilters;
