import React from "react";

import api from "../../../../api";
import DisplayName from "../../../../components/DisplayName/DisplayName";
import EntitiesResourceList, {
  EntitiesResourceListProps
} from "../../../../components/EntitiesResourceList/EntitiesResourceList";
import { QUERIES_KEYS } from "../../../../constants/queries-keys";
import { getCustomerName } from "../../../../helpers/display.helpers";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import { Customer } from "../../../../types/utilities";

type CustomersResourceListProps = Pick<
  EntitiesResourceListProps<Customer>,
  "selectedIds" | "page" | "resetPage" | "onSelectionChange"
> & {
  existingCustomers: Customer[];
};

const CustomersResourceList = (props: CustomersResourceListProps) => {
  const { existingCustomers = [], ...resourceListProps } = props;

  const f = useFormatMessage();

  return (
    <EntitiesResourceList<Customer>
      getEntities={(searchParams) => api.getCustomers(searchParams).then((response) => response.customers)}
      queryKey={QUERIES_KEYS.CUSTOMERS_LIST}
      allowMultiple
      queryPlaceholder={f("component.search.customers.hint")}
      renderItem={(customer) => <DisplayName type={customer.type!} name={getCustomerName(customer)} />}
      existingEntities={existingCustomers}
      {...resourceListProps}
    />
  );
};

export default CustomersResourceList;
