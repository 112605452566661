import React, { createContext, PropsWithChildren, useState } from "react";

import { noop } from "../utils/util";

interface SnackbarProps {
  snackbar?: string;
  setSnackbar(message: string): void;
  clearSnackbar(): void;
}

const initialSnackbar: SnackbarProps = {
  setSnackbar: noop,
  clearSnackbar: noop
};

export const SnackbarContext = createContext<SnackbarProps>(initialSnackbar);

export const SnackbarProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [snackbar, setSnackbar] = useState<string>();

  const clearSnackbar = () => setSnackbar(undefined);

  const value = { snackbar, setSnackbar, clearSnackbar };

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};
