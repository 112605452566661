import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { getChecklistConditionType, getChecklistSelectionType } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetAssignmentIndustriesAndPurposes from "../../hooks/useGetAssignmentIndustriesAndPurposes";
import useRiskCategories from "../../hooks/useRiskCategories";
import { ReviewChecklistConditionType } from "../../types/ReviewChecklist";
import { first } from "../../utils/collectionUtils";
import { DISPLAY_BADGE_PROPS } from "../Badges/Badges";
import CondensedText from "../CondensedText/CondensedText";
import Badge from "../extensions/Badge";
import KDKTag from "../KDKTag/KDKTag";

type ReviewChecklistConditionsLabelProps = {
  checklist: api.ReviewChecklist;
};

const ReviewChecklistConditionsLabel = (props: ReviewChecklistConditionsLabelProps) => {
  const { checklist } = props;

  const f = useFormatMessage();
  const { getPurposeLabel } = useGetAssignmentIndustriesAndPurposes();
  const options = useRiskCategories();

  const { display_settings } = checklist;

  const selectionType = getChecklistSelectionType(display_settings);
  const conditionType = getChecklistConditionType(display_settings);

  const getConditionTypeLabel = (conditionType?: ReviewChecklistConditionType) => {
    switch (conditionType) {
      case "risk":
        return f("review.checklist.editor.condition-type.risk");
      case "tag":
        return f("review.checklist.editor.condition-type.tags");
      case "purpose":
        return f("review.checklist.editor.condition-type.purpose");
      default:
        return "";
    }
  };

  switch (selectionType) {
    case "manual":
      return f("review.checklist.editor.selection-type.manual");
    case "always":
      return f("review.checklist.editor.selection-type.always");
    case "automatic":
      return (
        <VerticalStack>
          <Text as={"span"}>{f("review.checklist.editor.selection-type.automatic")}</Text>
          <Text as={"span"}>{f("common.labels.triggered-when")}</Text>
          <HorizontalStack gap="2" blockAlign={"center"}>
            <CondensedText>{getConditionTypeLabel(conditionType)}:</CondensedText>
            {conditionType === "tag" && display_settings.labels.map((tag) => <KDKTag tag={tag} key={tag.id} />)}
            {conditionType === "purpose" && (
              <StyledLabel>{getPurposeLabel(first(display_settings.assignment_purposes))}</StyledLabel>
            )}
            {conditionType === "risk" &&
              display_settings.risk_categories.map((riskCategory) => (
                <Badge {...DISPLAY_BADGE_PROPS} key={riskCategory}>
                  {options.get(riskCategory)}
                </Badge>
              ))}
          </HorizontalStack>
        </VerticalStack>
      );
    default:
      return null;
  }
};

const StyledLabel = styled.span`
  white-space: normal;
  word-break: break-all;
`;

export default ReviewChecklistConditionsLabel;
