import React, { useState } from "react";
import { Card, HorizontalStack, RadioButton, Text, TextField, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import { isNumberInRange } from "../../utils/numbersUtils";

export const EMPTY_CUSTOMER_NOTIFICATIONS_SETTINGS: api.CustomerNotificationSettings = {
  email_notification_frequency: 1,
  max_amount_of_notifications: 1
};

const MAX_REMINDERS_OPTIONS = [3, 5, 7]; // maximum number of notifications to be sent

const MIN_REMINDERS_FREQUENCY = 0; // not set
const MAX_REMINDERS_FREQUENCY = 31; // 31 days

interface SettingsAutoReminderProps {
  autoRemindersSettings: api.CustomerNotificationSettings;
  readonly?: boolean;
}

const SettingsAutoReminder = (props: SettingsAutoReminderProps) => {
  const { autoRemindersSettings, readonly } = props;

  const f = useFormatMessage();
  const { isPermittedTo } = usePermissions();

  const FREQUENCIES_OPTIONS = new Map([
    [MIN_REMINDERS_FREQUENCY, f("auto-reminders-settings.send-options.not-set")],
    [7, f("auto-reminders-settings.send-options.weekly")],
    [14, f("auto-reminders-settings.send-options.bi-weekly")]
  ]);

  const [settings, setSettings] = useState(autoRemindersSettings);
  const [isCustom, setIsCustom] = useState(
    !FREQUENCIES_OPTIONS.has(autoRemindersSettings.email_notification_frequency)
  );

  const { setQueryData } = useQueryData<api.CustomerNotificationSettings>([
    QUERIES_KEYS.CUSTOMER_NOTIFICATIONS_SETTINGS
  ]);

  const disabled = readonly || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const setNotificationsFrequency = (frequency: number, custom: boolean) => {
    // validate frequency only if it is custom
    if (!custom || isNumberInRange(frequency, MIN_REMINDERS_FREQUENCY, MAX_REMINDERS_FREQUENCY)) {
      setSettings({ ...settings, email_notification_frequency: frequency });
      setIsCustom(custom);
    }
  };

  const setMaxNotificationsAmount = (amount: number) => {
    setSettings({ ...settings, max_amount_of_notifications: amount });
  };

  const handleIsCustomChange = () => {
    setNotificationsFrequency(1, true);
  };

  return (
    <SettingsPage<api.CustomerNotificationSettings>
      readonly={readonly}
      apiCall={() => api.updateCustomerNotificationSettings(settings)}
      onSaveAfter={setQueryData}
      title={f("auto-reminders-settings.page.title")}
      subtitle={f("auto-reminders-settings.description")}
    >
      <Card>
        <Card.Section>
          <VerticalStack gap="8">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {f("auto-reminders-settings.form.label")}
            </Text>
            <VerticalStack gap="2">
              {[...FREQUENCIES_OPTIONS].map(([frequency, label]) => (
                <RadioButton
                  label={label}
                  checked={settings.email_notification_frequency === frequency && !isCustom}
                  onChange={() => setNotificationsFrequency(frequency, false)}
                  disabled={disabled}
                  key={label}
                />
              ))}
              <HorizontalStack gap="4" blockAlign="center">
                <RadioButton
                  label={f("auto-reminders-settings.send-options.custom")}
                  checked={isCustom}
                  onChange={handleIsCustomChange}
                  disabled={disabled}
                />
                <StyledTextFieldWrapper>
                  <TextField
                    label={f("auto-reminders-settings.send-options.custom.placeholder")}
                    labelHidden
                    type={"number"}
                    placeholder={f("auto-reminders-settings.send-options.custom.placeholder")}
                    value={isCustom ? settings.email_notification_frequency.toString() : ""}
                    onChange={(value) => setNotificationsFrequency(parseInt(value), true)}
                    min={MIN_REMINDERS_FREQUENCY + 1}
                    max={MAX_REMINDERS_FREQUENCY}
                    autoComplete={"off"}
                    disabled={disabled}
                    suffix={isCustom ? f("default.days", { days: settings.email_notification_frequency }) : undefined}
                  />
                </StyledTextFieldWrapper>
              </HorizontalStack>
            </VerticalStack>
          </VerticalStack>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section>
          <VerticalStack gap="8">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {f("auto-reminders-settings.limit-options.label")}
            </Text>
            <VerticalStack gap="2">
              {MAX_REMINDERS_OPTIONS.map((amount) => (
                <RadioButton
                  label={f("default.times", { count: amount })}
                  checked={settings.max_amount_of_notifications === amount}
                  onChange={() => setMaxNotificationsAmount(amount)}
                  disabled={disabled}
                  key={amount}
                />
              ))}
            </VerticalStack>
          </VerticalStack>
        </Card.Section>
      </Card>
    </SettingsPage>
  );
};

const StyledTextFieldWrapper = styled.div`
  max-width: 20ch;
`;

const SettingsAutoRemindersPage = ({ data }: AsyncPageChild<api.CustomerNotificationSettings>) => {
  return <SettingsAutoReminder autoRemindersSettings={data} />;
};

export default withAsyncPage<api.CustomerNotificationSettings>(SettingsAutoRemindersPage, {
  queryKey: QUERIES_KEYS.CUSTOMER_NOTIFICATIONS_SETTINGS,
  apiFunction: api.getCustomerNotificationSettings,
  paramNames: [],
  skeleton: <SettingsAutoReminder readonly autoRemindersSettings={EMPTY_CUSTOMER_NOTIFICATIONS_SETTINGS} />
});
