import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Action, Toast } from "@shopify/polaris";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";

interface MatchReviewSuccessToastProps {
  isApproved: boolean;
  customerId: string;
  nextMatch?: api.MatchCaseSummary;
  onDismiss(): void;
}

const MatchReviewSuccessToast = (props: MatchReviewSuccessToastProps) => {
  const { isApproved, customerId, nextMatch, onDismiss } = props;

  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();

  const navigateToNextMatch = () => {
    if (nextMatch && customerId) {
      history.push(generatePath(ROUTES.MATCH_CASE_DETAILS, { customerId, id: nextMatch.id }), history.location.state);
    }
  };

  const content = isApproved ? f("matches.review.approved") : f("matches.review.denied");

  const action: Action | undefined = nextMatch
    ? { content: f("matches.button.review-next-match"), onAction: navigateToNextMatch }
    : undefined;

  // when showing the action to show next match make the toast visible for a longer time period
  // values are in milliseconds
  const duration = nextMatch ? 15_000 : 3_000;

  return <Toast onDismiss={onDismiss} content={content} action={action} duration={duration} />;
};

export default MatchReviewSuccessToast;
