import React, { createContext, PropsWithChildren, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IconProps } from "@shopify/polaris";

import { PAGE_ANCHORS } from "../constants/page-anchors";
import useTimeout from "../hooks/useTimeout";
import { scrollInPage } from "../utils/browserUtils";
import { noop } from "../utils/util";

interface NavigationItemOptions {
  count?: number;
  isNew?: boolean;
  external?: boolean;
}

export type NavigationSection = {
  title: string;
  icon?: IconProps["source"];
  items: NavigationItem[];
};

export interface NavigationItem {
  label: string;
  anchor?: string;
  url?: string;
  disabled?: boolean;
  options?: NavigationItemOptions;
}

export interface PageNavigation {
  navigationSections: NavigationSection[];
  setNavigationSections(sections: NavigationSection[]): void;
  clearNavigationSections(): void;
  showNavigationMenu: boolean;
  isNavigationScrolling: boolean;
  navigateInPage(anchor: string, options?: boolean | ScrollIntoViewOptions): void;
}

export const PageNavigationContext = createContext<PageNavigation>({
  navigationSections: [],
  // initial value for PageNavigationContext requires empty methods
  setNavigationSections: noop,
  clearNavigationSections: noop,
  showNavigationMenu: false,
  isNavigationScrolling: false,
  navigateInPage: noop
});

export const PageNavigationContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [navigationSections, setNavigationSections] = useState<NavigationSection[]>([]);
  const [isNavigationScrolling, setIsNavigationScrolling] = useState(false);

  useTimeout(() => setIsNavigationScrolling(false), 500, isNavigationScrolling);

  const location = useLocation();
  const history = useHistory();

  const showNavigationMenu = navigationSections.length > 0;

  const clearNavigationSections = () => setNavigationSections([]);

  const navigateInPage = useCallback(
    (anchor: string, options?: ScrollOptions) => {
      // remove hash if exists
      location.pathname = location.pathname.replace(/#.*$/, "");
      // add anchor
      location.hash = anchor === PAGE_ANCHORS.TOP_SECTION ? "" : `#${anchor}`;
      history.replace(location);

      setIsNavigationScrolling(true);
      scrollInPage(anchor, options || { behavior: "smooth" });
    },
    [history, location]
  );

  const value: PageNavigation = {
    navigationSections,
    setNavigationSections,
    clearNavigationSections,
    showNavigationMenu,
    isNavigationScrolling,
    navigateInPage
  };

  return <PageNavigationContext.Provider value={value}>{children}</PageNavigationContext.Provider>;
};
