import formatAccountNumber from "norwegian-utils/formatAccountNumber";
import { formatOrganizationNumber } from "norwegian-utils/formatOrganizationNumber";

// @see https://github.com/unfold/norwegian-utils
const SPACE_SEPARATOR = "\u00a0";

export const formatKARAccountNumber = (accountNumber: string) =>
  formatAccountNumber(accountNumber).replaceAll(SPACE_SEPARATOR, ".");

export const formatKARNationalNumber = (nationalNumber: string) =>
  formatOrganizationNumber(nationalNumber)?.replaceAll(SPACE_SEPARATOR, ".");
