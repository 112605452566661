import React from "react";

import api from "../../../api";
import { convertPersonToUpdateRequest } from "../../../helpers/person.helpers";
import VitecPersonSync from "../../VitecPersonSync/VitecPersonSync";

interface VitecRoleSyncProps {
  customerId: string;
  role: api.Role;
  onUpdate(roles: api.Role): void;
}

const VitecRoleSync = (props: VitecRoleSyncProps) => {
  const { customerId, role, onUpdate } = props;

  const syncToVitec = () => api.syncCompanyRole(customerId, { role_id: role.id! });

  const updateRole = (phone: string) =>
    api.updateCompanyRole(customerId, role.id!, {
      person: { ...convertPersonToUpdateRequest(role.person), phone },
      comment: role.comment,
      type: role.type,
      status: role.status
    });

  return (
    <VitecPersonSync<api.Role>
      entity={role}
      isSyncedToVitec={role.synced_with_vitec}
      syncToVitecAPICall={syncToVitec}
      updatePersonAPICall={updateRole}
      onAfterUpdate={onUpdate}
    />
  );
};

export default VitecRoleSync;
