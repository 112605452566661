import React, { useCallback, useState } from "react";
import { TextField, TextFieldProps } from "@shopify/polaris";
import styled from "styled-components";

import { ERROR_DEBOUNCE_DELAY_IN_MILLISECONDS } from "../../constants/durations";
import useDebounceValue from "../../hooks/useDebouncedValue";

type KDKTextFieldProps = TextFieldProps & {
  debounceError?: boolean;
  forceTouched?: boolean;
};

const KDKTextField = (props: KDKTextFieldProps) => {
  const { debounceError, forceTouched = false, error, onBlur, onChange, onClearButtonClick, ...rest } = props;

  // if there's no error - don't wait to remove the error
  const debouncedError = useDebounceValue(error, error ? ERROR_DEBOUNCE_DELAY_IN_MILLISECONDS : 0);

  const [touched, setTouched] = useState(forceTouched);

  const errorToDisplay = !touched ? undefined : debounceError ? debouncedError : error;

  const handleOnBlur = useCallback(() => {
    setTouched(true);

    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const handleOnChange = useCallback(
    (value: string, id: string) => {
      setTouched(true);

      if (onChange) {
        onChange(value, id);
      }
    },
    [onChange]
  );

  const handleOnClearButtonClick = useCallback(
    (id: string) => {
      setTouched(true);

      if (onClearButtonClick) {
        onClearButtonClick(id);
      }
    },
    [onClearButtonClick]
  );

  if (rest.verticalContent) {
    return (
      <StyledTextFieldWrapper>
        <TextField
          error={errorToDisplay}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onClearButtonClick={handleOnClearButtonClick}
          {...rest}
        />
      </StyledTextFieldWrapper>
    );
  }

  return (
    <TextField
      error={errorToDisplay}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onClearButtonClick={handleOnClearButtonClick}
      {...rest}
    />
  );
};

const StyledTextFieldWrapper = styled.span`
  & .Polaris-TextField__Input {
    resize: none;
  }

  & .Polaris-TextField__VerticalContent {
    overflow: auto;
  }
`;

export default KDKTextField;
