import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

import { ROUTES } from "../../constants/routes";
import { MOBILE_BREAKPOINT } from "../../constants/styles";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";
import Button from "../extensions/Button";

const OneStepOnboardingToolbar = () => {
  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();

  const createdFromProjectId = history.location.state?.createdFromProject?.id;

  const redirectURLAfterClose = createdFromProjectId
    ? generatePath(ROUTES.PROJECT_DETAILS, { id: createdFromProjectId })
    : generatePath(ROUTES.CUSTOMERS);

  return (
    <StyledToolbar>
      <Text variant="headingMd" as="h2">
        {f("onboarding.new.person.title")}
      </Text>
      <Button onClick={() => history.push(redirectURLAfterClose)}>{f("default.cancel")}</Button>
    </StyledToolbar>
  );
};

const StyledToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  // For small viewports - reduce heading line height
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    & > .Polaris-Heading {
      line-height: var(--p-font-line-height-2);
    }
  }
`;

export default OneStepOnboardingToolbar;
