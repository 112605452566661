import React from "react";

import api from "../../../../api";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import Badge from "../../../extensions/Badge";

interface OwnershipUpdateBadgeProps {
  update?: api.OwnershipUpdate;
}

const OwnershipUpdateBadge = (props: OwnershipUpdateBadgeProps) => {
  const { update } = props;

  const f = useFormatMessage();

  if (!update) {
    return null;
  }

  return (
    <>
      {update.update_type === "ADDED" && (
        <Badge size="xsmall" status="info">
          {f("default.new")}
        </Badge>
      )}
      {update.update_type === "UPDATED" && (
        <Badge size="xsmall" status="info">
          {f("default.change")}
        </Badge>
      )}
      {update.update_type === "DELETED" && <Badge size="xsmall">{f("default.expired")}</Badge>}
    </>
  );
};

export default OwnershipUpdateBadge;
