import React from "react";
import { Banner, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import CheckmarkIcon from "../../../../assets/icons/checkmark.svg";
import { LATEST_OWNERSHIP_REGISTRY_RECORD_YEAR } from "../../../constants/ownership-updates";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useRichFormatMessage from "../../../hooks/useRichFormatMessage";
import Button from "../../extensions/Button";

type CompanyOwnershipUpdatesBannerProps = {
  disabled: boolean;
  loading: boolean;
  onConfirmAll(): void;
};

const CompanyOwnershipUpdatesBanner = (props: CompanyOwnershipUpdatesBannerProps) => {
  const { disabled, loading, onConfirmAll } = props;

  const f = useFormatMessage();
  const richFormatMessage = useRichFormatMessage();

  return (
    <Banner status="info">
      <VerticalStack gap="4">
        <Text variant="bodyMd" as="span">
          {richFormatMessage("ownership-update.banner.description", { year: LATEST_OWNERSHIP_REGISTRY_RECORD_YEAR })}
        </Text>
        <HorizontalStack gap="4" align="end">
          <Button icon={CheckmarkIcon} disabled={disabled} loading={loading} onClick={onConfirmAll}>
            {f("ownership-update.actions.labels.confirm-all-updates")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Banner>
  );
};

export default CompanyOwnershipUpdatesBanner;
