import { DEFAULT_COUNTRY } from "../constants/countries";

import useAuth from "./useAuth";

const useDefaultCountry = () => {
  const { user } = useAuth();

  return user?.organization.country || DEFAULT_COUNTRY;
};

export default useDefaultCountry;
