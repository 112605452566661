import { VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import CondensedText from "../CondensedText/CondensedText";

type CompletedCustomerReviewMeasureLabelProps = {
  measure: api.Measure;
};
const CompletedMeasureInfoLabel = (props: CompletedCustomerReviewMeasureLabelProps) => {
  const { measure } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  return (
    <VerticalStack inlineAlign="start">
      {measure.is_completed && measure.completed_dt && (
        <CondensedText>
          {f("common.labels.completed-by", {
            name: getUserDisplayName(measure.completed_by),
            timestamp: new Date(measure.completed_dt)
          })}
        </CondensedText>
      )}
    </VerticalStack>
  );
};

export default CompletedMeasureInfoLabel;
