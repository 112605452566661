import React from "react";
import { VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import { Review } from "../../types/CustomerReview";
import { CustomerDetails } from "../../types/utilities";
import CustomerReviewCard from "../CustomerReviewCard/CustomerReviewCard";

export type CustomerReviewsListProps = {
  customer: CustomerDetails;
  reviews: Review[];
  readonly?: boolean;
  onReviewChange(review: api.CustomerReview): void;
};

const CustomerReviewsList = (props: CustomerReviewsListProps) => {
  const { customer, reviews, readonly, onReviewChange } = props;

  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const getPreviouslyMarkedLabel = (currentReview: Review, previousReview?: Review) => {
    if (!previousReview) {
      return undefined;
    }

    const previouslyMarkedLabels: string[] = [];
    if (!currentReview.is_pep && previousReview.is_pep) {
      previouslyMarkedLabels.push(f("badge.pep"));
    }
    if (!currentReview.is_sanctioned && previousReview.is_sanctioned) {
      previouslyMarkedLabels.push(f("badge.sanctioned"));
    }

    if (previouslyMarkedLabels.length > 0) {
      return f("reviews.labels.unmark-as", { badges: formatMultipleNames(previouslyMarkedLabels) });
    }
  };

  return (
    <VerticalStack gap="6">
      {reviews.map((review, index) => (
        <CustomerReviewCard
          customer={customer}
          review={review}
          readonly={readonly}
          additionalInfoLabel={getPreviouslyMarkedLabel(
            review,
            index < reviews.length - 1 ? reviews[index + 1] : undefined
          )}
          onChange={onReviewChange}
          key={review.id}
        />
      ))}
    </VerticalStack>
  );
};

export default CustomerReviewsList;
