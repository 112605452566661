import useFormatMessage from "./useFormatMessage";

/**
 * This hook return a function that takes a list of strings (usually names from the same type)
 * and returns a single string which is a join of these strings with commas and "and" keyword before the last name
 * It also determined how many of the given strings will be displayed fully and how many will be counted as "others".
 *
 * Example:
 *
 * const formatMultipleNames = useFormatMultipleNames();
 *
 * const namesLabel = formatMultipleNames(["John"]); // namesLabel = "John"
 * const namesLabel = formatMultipleNames(["John", "Paul"]); // namesLabel = "John and Paul"
 * const namesLabel = formatMultipleNames(["John", "Paul", "George"]); // namesLabel = "John, Paul and George"
 * const namesLabel = formatMultipleNames(["John", "Paul", "George", "Ringo"]); // namesLabel = "John, Paul, George and Ringo"
 * const namesLabel = formatMultipleNames(["John", "Paul", "George", "Ringo", "Pete"]); // namesLabel = "John, Paul, George and 2 others"
 * const namesLabel = formatMultipleNames(["John", "Paul", "George", "Ringo", "Pete"], 2); // namesLabel = "John, Paul and 3 others"
 *
 * cutoff - the number of names to display fully before counting the rest of the names.
 *
 */
const useFormatMultipleNames = () => {
  const f = useFormatMessage();

  return (names?: string[], cutoff = 3) => {
    if (!names || names.length === 0) {
      return "";
    }

    if (names.length === 1) {
      return names[0];
    }

    if (names.length - cutoff <= 1 || cutoff === 0) {
      const fullnames = names.slice(0, names.length - 1).join(", ");
      const lastname = names[names.length - 1];

      return f("common.multiple-names.short", { fullnames, lastname });
    }

    const fullnames = names.slice(0, cutoff).join(", ");
    const count = names.length - cutoff;

    return f("common.multiple-names.full", { fullnames, count });
  };
};

export default useFormatMultipleNames;
