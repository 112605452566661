import React from "react";
import { VerticalStack } from "@shopify/polaris";

import useCustomerReviewBanner from "../../hooks/useCustomerReviewBanner";
import { CustomerDetails } from "../../types/utilities";
import PendingDisclosuresBanner from "../PendingDisclosuresBanner/PendingDisclosuresBanner";
import UnreviewedMatchCasesBanner from "../UnreviewedMatchCasesBanner/UnreviewedMatchCasesBanner";

import EmptyReviewsBanner from "./EmptyReviewsBanner";

type CustomerReviewsSectionBannerProps = {
  customer: CustomerDetails;
  readonly?: boolean;
};

const CustomerReviewsSectionBanner = (props: CustomerReviewsSectionBannerProps) => {
  const { customer, readonly } = props;

  const {
    hasUnresolvedMatchCases,
    hasPendingDisclosures,
    unresolvedCaseCount,
    pendingDisclosuresCount,
    isNewCustomerReviewDisabled,
    navigateToMatchesSection,
    navigateToDisclosuresSection
  } = useCustomerReviewBanner(customer);

  return (
    <VerticalStack gap="2">
      {hasUnresolvedMatchCases && (
        <UnreviewedMatchCasesBanner
          unresolvedCaseCount={unresolvedCaseCount}
          onNavigateToMatchesSection={navigateToMatchesSection}
        />
      )}
      {hasPendingDisclosures && (
        <PendingDisclosuresBanner
          pendingDisclosuresCount={pendingDisclosuresCount}
          onNavigateToDisclosuresSection={navigateToDisclosuresSection}
        />
      )}
      {customer.reviews.length === 0 && (
        <EmptyReviewsBanner customer={customer} readonly={readonly || isNewCustomerReviewDisabled} />
      )}
    </VerticalStack>
  );
};

export default CustomerReviewsSectionBanner;
