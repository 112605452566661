import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useRDCMatch from "../../hooks/useRDCMatch";
import MatchAttributesCollapsibleCardSections from "../MatchAttributesCollapsibleCardSections/MatchAttributesCollapsibleCardSections";
import MatchCategorySection from "../MatchCategorySection/MatchCategorySection";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";

type MatchKeyInformationSectionProps = {
  matchDetails: api.MatchCaseDetailResponse;
};

const MatchKeyInformationSection = (props: MatchKeyInformationSectionProps) => {
  const { matchDetails } = props;

  const f = useFormatMessage();
  const { riskography, hasNewInfo } = useRDCMatch(matchDetails);

  if (riskography.length === 0) {
    return null;
  }

  return (
    <MatchCategorySection title={f("matches.titles.key-information")}>
      {riskography.length > 0 && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.summary")}
          elements={riskography}
          renderElement={(risk) => (
            <HorizontalStack>
              <Text as="span" fontWeight={risk ? "semibold" : undefined}>
                {risk.value}
              </Text>
              {hasNewInfo(risk) && <NewInfoBadge />}
            </HorizontalStack>
          )}
        />
      )}
    </MatchCategorySection>
  );
};

export default MatchKeyInformationSection;
