import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import AddButton from "../AddButton/AddButton";
import AssignmentModal, { AssignmentModalProps } from "../AssignmentModal/AssignmentModal";

type EmptyAssignmentBannerProps = Omit<AssignmentModalProps, "onCancel">;

const EmptyAssignmentBanner = (props: EmptyAssignmentBannerProps) => {
  const { onSave, ...assignmentModalProps } = props;

  const f = useFormatMessage();
  const [showModal, toggleShowModal, closeModal] = useOpenClose();

  const handleSave = (assignment: api.Assignment, isNew: boolean) => {
    closeModal();
    onSave(assignment, isNew);
  };

  return (
    <VerticalStack gap="4">
      <HorizontalStack align={"space-between"}>
        <Text as={"p"} variant={"headingSm"} fontWeight={"bold"}>
          {f("common.labels.include-assignment")}
        </Text>
        <AddButton onClick={toggleShowModal}>{f("common.buttons.actions.create-assignment")}</AddButton>
      </HorizontalStack>
      <div />
      {showModal && <AssignmentModal onCancel={closeModal} onSave={handleSave} {...assignmentModalProps} />}
    </VerticalStack>
  );
};

export default EmptyAssignmentBanner;
