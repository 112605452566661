import { useCallback } from "react";

import api from "../api";
import { getFullName } from "../helpers/display.helpers";
import { isEmptyString } from "../utils/stringUtils";

import useFormatMessage from "./useFormatMessage";
import useGetUsers from "./useGetUsers";

/**
 * returns a function that finds and returns the full username of a give user id
 */
const useGetUserName = (...useGetUserProps: Parameters<typeof useGetUsers>) => {
  const { users, isSingleUser, getUserGroupsDisplayName } = useGetUsers(...useGetUserProps);
  const f = useFormatMessage();

  const getUserDisplayName = (user?: api.User) => {
    const name = getFullName(user);
    const suffixes: string[] = [];

    const groupsNames = getUserGroupsDisplayName(user);
    if (!isEmptyString(groupsNames) && !isSingleUser) {
      suffixes.push(groupsNames);
    }

    if (user && !user.is_active) {
      suffixes.push(f("default.inactive").toLocaleLowerCase());
    }

    return `${name}${suffixes.length === 0 ? "" : ` (${suffixes.join(" - ")})`}`;
  };

  const getUserFullName = useCallback(
    (userId?: string) => getUserDisplayName(users.find((user) => user.id === userId)),
    [users]
  );

  return { getUserFullName, getUserDisplayName };
};

export default useGetUserName;
