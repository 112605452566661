import { generatePath } from "react-router-dom";

import PencilIcon from "../../../assets/icons/pencil.svg";
import { ROUTES } from "../../constants/routes";
import useCustomerReview from "../../hooks/useCustomerReview";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Review } from "../../types/CustomerReview";
import { CustomerDetails } from "../../types/utilities";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";

type CustomerReviewCardEditButtonProps = {
  customer: CustomerDetails;
  review: Review;
  readonly?: boolean;
};

const CustomerReviewCardEditButton = (props: CustomerReviewCardEditButtonProps) => {
  const { customer, review, readonly } = props;

  const f = useFormatMessage();
  const { isEditable, isRequiresApproval } = useCustomerReview(review);

  const url = generatePath(ROUTES.CUSTOMER_REVIEW, { customerId: customer.id, id: review.id });

  if (isRequiresApproval && review.is_possible_to_approve) {
    return (
      <Button primary disabled={readonly} url={url}>
        {f("reviews.labels.review.assessment")}
      </Button>
    );
  } else if (isEditable) {
    return (
      <Button primary size="slim" disabled={readonly} url={url} icon={<Icon source={PencilIcon} useMask />}>
        {f("default.edit")}
      </Button>
    );
  } else {
    return null;
  }
};

export default CustomerReviewCardEditButton;
