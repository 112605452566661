import React from "react";
import Toggle, { ToggleProps } from "react-toggle";
import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import "react-toggle/style.css";

interface ToggleInputProps extends ToggleProps {
  label?: string;
}

const ToggleInput = (props: ToggleInputProps) => {
  const { label, ...rest } = props;

  return (
    <HorizontalStack gap="2" blockAlign="center" wrap={false}>
      <StyledToggle id="toggle" {...rest} icons={false} />
      {label && <Text as="span">{label}</Text>}
    </HorizontalStack>
  );
};

const StyledToggle = styled(Toggle)`
  // Toggle Off
  &.react-toggle .react-toggle-track {
    background-color: var(--p-surface);
    border: solid var(--p-border-width-2) var(--p-border);
    width: 34px;
    height: 18px;
  }

  &.react-toggle .react-toggle-thumb {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    background-color: var(--p-border);
    border: none;
  }

  &.react-toggle:hover .react-toggle-track {
    background-color: var(--p-surface-selected-hovered);
  }

  // Toggle On
  &.react-toggle--checked .react-toggle-track {
    background-color: var(--p-surface);
    border-color: var(--kdk-blue-575);
  }

  &.react-toggle--checked .react-toggle-thumb {
    background-color: var(--kdk-blue-575);
    left: 19px;
  }
`;

export default ToggleInput;
