import React, { useState } from "react";
import { Popover } from "@shopify/polaris";

import PlusIcon from "../../../assets/icons/plus-major.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { toggleElement } from "../../utils/collectionUtils";
import Icon from "../extensions/Icon";
import PopoverButton from "../PopoverButton/PopoverButton";
import ReviewChecklistRemoveConfirm from "../ReviewChecklistRemoveConfirm/ReviewChecklistRemoveConfirm";

import ReviewChecklistsList from "./ReviewChecklistsList";

export type ReviewChecklistSelectProps = {
  checklistsIds: string[];
  customerType: api.CustomerType;
  readonly?: boolean;
  onChange(selectedChecklistsIds: string[]): void;
  shouldDisplayConfirmation(checklist: api.ReviewChecklist): boolean;
};

const ReviewChecklistSelect = (props: ReviewChecklistSelectProps) => {
  const { checklistsIds, customerType, readonly, onChange, shouldDisplayConfirmation } = props;

  const f = useFormatMessage();
  const [checklist, setChecklist] = useState<api.ReviewChecklist>();

  const fireSelectionEvent = (onClose: () => void, checklist?: api.ReviewChecklist) => {
    const selectedIds = !checklist ? [] : toggleElement(checklistsIds, checklist.id);
    onClose();
    setChecklist(undefined);
    onChange(selectedIds);
  };

  const handleChecklistSelect = (onClose: () => void, checklist?: api.ReviewChecklist) => {
    if (checklist && checklistsIds.includes(checklist?.id) && shouldDisplayConfirmation(checklist)) {
      setChecklist(checklist);
    } else {
      fireSelectionEvent(onClose, checklist);
    }
  };

  return (
    <PopoverButton
      label={f("component.review.checklist.select.button.label.add")}
      icon={<Icon source={PlusIcon} width={"12px"} height={"12px"} useMask />}
      size={"slim"}
      disabled={readonly}
      fullWidth={false}
      render={(onClose) => (
        <Popover.Section>
          <ReviewChecklistsList
            selectedIds={checklistsIds}
            customerType={customerType}
            readonly={readonly}
            onSelect={(checklist) => handleChecklistSelect(onClose, checklist)}
          />
          {checklist && (
            <ReviewChecklistRemoveConfirm
              checklistName={checklist.name}
              onYes={() => fireSelectionEvent(onClose, checklist)}
              onNo={() => setChecklist(undefined)}
            />
          )}
        </Popover.Section>
      )}
    />
  );
};

export default ReviewChecklistSelect;
