import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { $createLinkNode, $isLinkNode, AutoLinkNode, LinkNode } from "@lexical/link";
import {
  $createListItemNode,
  $createListNode,
  $isListItemNode,
  $isListNode,
  ListItemNode,
  ListNode,
  ListType
} from "@lexical/list";
import {
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  CHECK_LIST,
  CODE,
  ElementTransformer,
  HEADING,
  INLINE_CODE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  QUOTE,
  STRIKETHROUGH,
  TextFormatTransformer,
  TextMatchTransformer,
  Transformer
} from "@lexical/markdown";
import { InitialConfigType } from "@lexical/react/LexicalComposer";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { $createTextNode, $isTextNode, ElementNode } from "lexical";

import { MarkdownTheme } from "../components/MarkdownEditor/MarkdownStylesWrapper";

export const BASE_MARKDOWN_CONFIG: Partial<InitialConfigType> = {
  theme: MarkdownTheme,
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};

/**
 * This is a patch to the markdown transformers in order to support nested lists
 * @see: https://github.com/facebook/lexical/issues/2214
 */
const listReplace = (listType: ListType, indentSize: number): ElementTransformer["replace"] => {
  return (parentNode, children, match) => {
    const previousNode = parentNode.getPreviousSibling();
    const listItem = $createListItemNode(listType === "check" ? match[3] === "x" : undefined);
    if ($isListNode(previousNode) && previousNode.getListType() === listType) {
      previousNode.append(listItem);
      parentNode.remove();
    } else {
      const list = $createListNode(listType, listType === "number" ? Number(match[2]) : undefined);
      list.append(listItem);
      parentNode.replace(list);
    }
    listItem.append(...children);
    listItem.select(0, 0);
    const indent = Math.floor(match[1].length / indentSize);
    if (indent) {
      listItem.setIndent(indent);
    }
  };
};

const listExport = (
  listNode: ListNode,
  exportChildren: (node: ElementNode) => string,
  depth: number,
  indentSize: number
): string => {
  const output = [];
  const children = listNode.getChildren();
  let index = 0;
  for (const listItemNode of children) {
    if ($isListItemNode(listItemNode)) {
      if (listItemNode.getChildrenSize() === 1) {
        const firstChild = listItemNode.getFirstChild();
        if ($isListNode(firstChild)) {
          output.push(listExport(firstChild, exportChildren, depth + 1, indentSize));
          continue;
        }
      }
      const indent = " ".repeat(depth * indentSize);
      const listType = listNode.getListType();
      const prefix =
        listType === "number"
          ? `${listNode.getStart() + index}. `
          : listType === "check"
            ? `- [${listItemNode.getChecked() ? "x" : " "}] `
            : "- ";
      output.push(indent + prefix + exportChildren(listItemNode));
      index++;
    }
  }

  return output.join("\n");
};

const UNORDERED_LIST_TRANSFORM: ElementTransformer = {
  export: (node, exportChildren) => {
    return $isListNode(node) ? listExport(node, exportChildren, 0, 2) : null;
  },
  regExp: /^(\s*)[-*+]\s/,
  replace: listReplace("bullet", 2),
  type: "element",
  dependencies: []
};

const ORDERED_LIST_TRANSFORM: ElementTransformer = {
  export: (node, exportChildren) => {
    return $isListNode(node) ? listExport(node, exportChildren, 0, 3) : null;
  },
  regExp: /^(\s*)(\d{1,})\.\s/,
  replace: listReplace("number", 3),
  type: "element",
  dependencies: []
};

/***
 * This is a patch to the markdown transformers in order to support target and rel for links
 * @see: https://github.com/facebook/lexical/tree/main/packages/lexical-markdown
 */
export const LINK_TRANSFORM: TextMatchTransformer = {
  dependencies: [LinkNode],
  export: (node, exportChildren, exportFormat) => {
    if (!$isLinkNode(node)) {
      return null;
    }
    const title = node.getTitle();
    const linkContent = title
      ? `[${node.getTextContent()}](${node.getURL()} "${title}")`
      : `[${node.getTextContent()}](${node.getURL()})`;
    const firstChild = node.getFirstChild();
    // Add text styles only if link has single text node inside. If it's more
    // then one we ignore it as markdown does not support nested styles for links
    if (node.getChildrenSize() === 1 && $isTextNode(firstChild)) {
      return exportFormat(firstChild, linkContent);
    } else {
      return linkContent;
    }
  },
  importRegExp: /(?:\[([^[]+)\])(?:\((?:([^()\s]+)(?:\s"((?:[^"]*\\")*[^"]*)"\s*)?)\))/,
  regExp: /(?:\[([^[]+)\])(?:\((?:([^()\s]+)(?:\s"((?:[^"]*\\")*[^"]*)"\s*)?)\))$/,
  replace: (textNode, match) => {
    const [, linkText, linkUrl, linkTitle] = match;
    const linkNode = $createLinkNode(linkUrl, { title: linkTitle, rel: "noopener", target: "_target" });
    const linkTextNode = $createTextNode(linkText);
    linkTextNode.setFormat(textNode.getFormat());
    linkNode.append(linkTextNode);
    textNode.replace(linkNode);
  },
  trigger: ")",
  type: "text-match"
};

const ELEMENT_TRANSFORMERS: ElementTransformer[] = [
  HEADING,
  QUOTE,
  CODE,
  ORDERED_LIST_TRANSFORM,
  CHECK_LIST,
  UNORDERED_LIST_TRANSFORM
];

// Order of text format transformers matters:
//
// - code should go first as it prevents any transformations inside
// - then longer tags match (e.g. ** or __ should go before * or _)
const TEXT_FORMAT_TRANSFORMERS: TextFormatTransformer[] = [
  INLINE_CODE,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  STRIKETHROUGH
];

const TEXT_MATCH_TRANSFORMERS: TextMatchTransformer[] = [LINK_TRANSFORM];

export const TRANSFORMERS: Transformer[] = [
  ...ELEMENT_TRANSFORMERS,
  ...TEXT_FORMAT_TRANSFORMERS,
  ...TEXT_MATCH_TRANSFORMERS
];

/**
 * End of patch
 */
