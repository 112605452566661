import api from "../api";
import { isEmptyString } from "../utils/stringUtils";
import { isNil, uniqueId } from "../utils/util";

const NEW_MEASURE_PREFIX = "measure";

export const createNewMeasure = (): api.Measure => ({
  id: uniqueId(NEW_MEASURE_PREFIX),
  title: "",
  description: "",
  is_completed: false
});

export const isNewMeasure = (measure: api.Measure) => measure.id?.startsWith(NEW_MEASURE_PREFIX);

export const convertMeasureToCreateCustomMeasureRequest = (measure: api.Measure): api.CreateMeasureRequest => {
  return {
    id: isNewMeasure(measure) ? undefined : measure.id,
    title: measure.title,
    description: measure.description,
    measure_config_id: measure.measure_config_id,
    is_completed: measure.is_completed,
    completed_comment: measure.completed_comment
  };
};

export const isInvalidMeasure = ({ title, description }: api.CreateMeasureRequest) =>
  isEmptyString(title) || isEmptyString(description);

export const convertMeasureToUpdateCustomMeasureRequest = ({
  title,
  description,
  is_completed,
  completed_comment
}: api.Measure): api.UpdateMeasureRequest => ({
  title,
  description,
  is_completed,
  completed_comment
});

export const isMeasureCompleted = (measure: api.Measure) => measure.is_completed && !isNil(measure.updated_by);
