import { PropsWithChildren } from "react";
import { HorizontalStack } from "@shopify/polaris";
import styled from "styled-components";

import WarningIcon from "../../../assets/icons/triangle-filled-exclamation-monochrome.svg";
import Badge from "../extensions/Badge";
import Icon from "../extensions/Icon";

const WarningBadge = (props: PropsWithChildren<unknown>) => {
  const { children } = props;

  return (
    <StyledBadge status="warning" size="small">
      <HorizontalStack blockAlign="center" wrap={false}>
        <Icon source={WarningIcon} useMask width="16px" height="16px" />
        {children}
      </HorizontalStack>
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)`
  & > .Polaris-Badge {
    border-radius: var(--p-border-radius-1);
  }
`;

export default WarningBadge;
