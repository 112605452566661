import React, { forwardRef, useState } from "react";
import { HorizontalStack, InlineError, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import EditIcon from "../../../../assets/icons/pencil.svg";
import TrashIcon from "../../../../assets/icons/trash.svg";
import api from "../../../api";
import { Measure } from "../../../client.generated";
import { isNewMeasure } from "../../../helpers/measures.helpers";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import { isEmptyString } from "../../../utils/stringUtils";
import CompletedMeasureInfoLabel from "../../CompletedMeasureInfoLabel/CompletedMeasureInfoLabel";
import CompleteMeasureForm from "../../CompleteMeasureForm/CompleteMeasureForm";
import Confirm from "../../Confirm/Confirm";
import CustomerReviewMeasureTitle from "../../CustomerReviewMeasureTitle/CustomerReviewMeasureTitle";
import EditButton from "../../EditButton/EditButton";
import Button from "../../extensions/Button";
import Icon from "../../extensions/Icon";
import KDKBanner from "../../KDKBanner/KDKBanner";
import MeasureComment from "../../MeasureComment/MeasureComment";
import MeasuresContainer from "../../MeasureContainer/MeasuresContainer";
import MoveButtons, { Direction } from "../../MoveButtons/MoveButtons";

import { isCustomMeasure, isEmptyMeasure } from "./Measure.helpers";
import MeasureForm from "./MeasureForm";

type MeasureProps = {
  measure: Measure;
  existingMeasures: api.Measure[];
  isFirst: boolean;
  isLast: boolean;
  readonly?: boolean;
  onRemove(): void;
  onChange(measure: Measure): void;
  onOrderChange(direction: Direction): void;
};

const Measure = forwardRef<HTMLDivElement, MeasureProps>((props, ref) => {
  const { measure, existingMeasures, isFirst, isLast, readonly, onRemove, onChange, onOrderChange } = props;

  const f = useFormatMessage();
  const isCustom = isCustomMeasure(measure);

  const [isRemoveMeasureConfirmOpen, toggleRemoveMeasureConfirm, closeRemoveMeasureConfirm] = useOpenClose();
  const [isEditing, setIsEditing] = useState(isNewMeasure(measure) && isCustom);
  const [isCompleting, toggleCompleting, closeCompleting] = useOpenClose();

  const isEmpty = isEmptyMeasure(measure);

  const showComment = !isEditing && !isCompleting;
  const isEmptyComment = isEmptyString(measure.completed_comment);

  // if the user cancels the first custom measure (which is empty by default) then
  // remove the measure instead of saving it as an empty measure
  const shouldRemoveIfCanceled = isFirst && isCustom && isEmpty;

  const isDirty = !isEmpty;

  // show footer with edit/delete buttons only if:
  // 1. The current measure is not being edited (if it's a custom measure)
  // 2. It is not being completed (when the measure complete form is rendered)
  // 3. It was not completed outside the review
  const showFooter = !isEditing && !isCompleting && !readonly;

  const editButtonLabel = isEmptyComment ? f("details.reviews.measures.edit-measure") : f("default.edit");

  const startEditing = () => setIsEditing(true);

  const handleFormUpdate = (updatedMeasure: api.Measure) => {
    setIsEditing(false);
    closeCompleting();
    onChange(updatedMeasure);
  };

  const handleFormCancel = () => {
    setIsEditing(false);
    closeCompleting();
    if (shouldRemoveIfCanceled) {
      onRemove();
    }
  };

  const handleRemove = () => {
    if (isCustom && isDirty && !isRemoveMeasureConfirmOpen) {
      toggleRemoveMeasureConfirm();
    } else {
      closeRemoveMeasureConfirm();
      onRemove();
    }
  };

  return (
    <MeasuresContainer ref={ref}>
      <KDKBanner hideBorder hideIcon>
        <VerticalStack gap="4">
          <VerticalStack gap="2">
            <HorizontalStack align="space-between">
              <CustomerReviewMeasureTitle measure={measure} />
              {isCustom && !measure.is_completed && !measure.completed_dt && (
                <EditButton readonly={readonly} onEdit={startEditing} />
              )}
            </HorizontalStack>
            <DescriptionText>{measure.description}</DescriptionText>
          </VerticalStack>
          {showComment && <MeasureComment measure={measure} />}
          {isEditing && (
            <MeasureForm
              measure={measure}
              existingMeasures={existingMeasures}
              onSave={handleFormUpdate}
              onCancel={handleFormCancel}
            />
          )}
          {isCompleting && (
            <CompleteMeasureForm
              measure={measure}
              readonly={readonly}
              autoFocus
              onSave={handleFormUpdate}
              onCancel={handleFormCancel}
            />
          )}
          <VerticalStack gap="1">
            <HorizontalStack gap="2" blockAlign="start" align="space-between">
              <CompletedMeasureInfoLabel measure={measure} />
              {showFooter && (
                <HorizontalStack gap="4" blockAlign="center">
                  <Button disabled={readonly} size="slim" icon={TrashIcon} onClick={handleRemove}>
                    {f("default.delete")}
                  </Button>
                  <Button
                    primary
                    disabled={readonly}
                    size="slim"
                    icon={isEmptyComment ? undefined : <Icon source={EditIcon} useMask />}
                    onClick={toggleCompleting}
                  >
                    {editButtonLabel}
                  </Button>
                </HorizontalStack>
              )}
            </HorizontalStack>
            {isEmpty && <InlineError message={f("measures.errors.empty-measure")} fieldID="" />}
          </VerticalStack>
        </VerticalStack>
      </KDKBanner>
      <MoveButtons
        spacing="extraTight"
        disableMoveUp={isFirst}
        disableMoveDown={isLast}
        readonly={readonly}
        onOrderChange={onOrderChange}
      />
      {isRemoveMeasureConfirmOpen && (
        <Confirm
          title={f("measures.confirm-remove.title")}
          description={f("measures.confirm-remove.description", { name: measure.title })}
          actionTitle={f("default.remove")}
          onYes={handleRemove}
          onNo={closeRemoveMeasureConfirm}
        />
      )}
    </MeasuresContainer>
  );
});

const DescriptionText = styled.div`
  white-space: pre;
`;

export default Measure;
