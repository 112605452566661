import React, { PropsWithChildren } from "react";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

type CondensedTextProps = {
  italic?: boolean;
};

const CondensedText = (props: PropsWithChildren<CondensedTextProps & React.HTMLAttributes<HTMLSpanElement>>) => {
  const { italic, children, ...rest } = props;

  return (
    <StyledCondensedText italic={italic} {...rest}>
      <Text as="span" color="subdued">
        {children}
      </Text>
    </StyledCondensedText>
  );
};

const StyledCondensedText = styled.span<CondensedTextProps>`
  font-size: var(--kdk-font-size-50);
  font-weight: var(--p-font-weight-semibold);

  font-style: ${({ italic }) => (italic ? "italic" : undefined)};
`;

export default CondensedText;
