import React from "react";
import Xarrow, { xarrowPropsType } from "react-xarrows";
import classNames from "classnames";

import { TREE_LINE_COLOR } from "../../constants/styles";

type TreeLineProps = {
  isLeaf?: boolean;
};

const TreeLine = (props: TreeLineProps & xarrowPropsType) => {
  const { isLeaf, ...XArrowProps } = props;

  // @see GlobalStyles.tsx to see the styling adjustments for tree lines for leaves
  const classes = classNames("kdk-table-tree-line", { leaf: isLeaf });

  return (
    <Xarrow
      startAnchor={["bottom"]}
      endAnchor={["left"]}
      path={"grid"}
      gridBreak={"0%"}
      showHead={false}
      strokeWidth={2}
      color={TREE_LINE_COLOR}
      passProps={{ strokeLinecap: "round", strokeLinejoin: "round" }}
      divContainerProps={{ className: classes.toString() }}
      {...XArrowProps}
    />
  );
};

export default TreeLine;
