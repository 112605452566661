import { PERMISSIONS_TYPES } from "../constants/permissions";

import useAuth from "./useAuth";

const usePermissions = () => {
  const { user } = useAuth();

  const permissions = user?.permissions || [];

  const isPermittedTo = (permission: PERMISSIONS_TYPES) => permissions.includes(permission);

  return { isPermittedTo };
};

export default usePermissions;
