import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import AttachmentsSection from "../../components/AttachmentsSection/AttachmentsSection";
import CompanyDisclosureAssignmentSelector from "../../components/CompanyDisclosureAssignmentSelector/CompanyDisclosureAssignmentSelector";
import CustomerUploadDocumentButton from "../../components/CustomerUploadDocumentButton/CustomerUploadDocumentButton";
import EmptyAssignmentBanner from "../../components/EmptyAssignmentBanner/EmptyAssignmentBanner";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import OnboardingWizardPageSkeleton from "../../components/OnboardingWizardPageSkeleton/OnboardingWizardPageSkeleton";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isActiveAssignment } from "../../helpers/assignments.helpers";
import { isActiveAttachment } from "../../helpers/attachments";
import { convertSignatureToCompanyRoleSignatureConfiguration } from "../../helpers/onboarding.helpers";
import useDisclosureAssignment from "../../hooks/useDisclosureAssignment";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useNavigationToolbar from "../../hooks/useNavigationToolbar";
import useQueryData, { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import { IDParams } from "../../types/params";
import { hasContent } from "../../utils/collectionUtils";
import { OnboardingCompanyDisclosuresPageData } from "../OnboardingCompanyDisclosuresPage/OnboardingCompanyDisclosuresPage";

const OnboardingCompanyAssignmentsPage = ({ data }: AsyncPageChild<OnboardingCompanyDisclosuresPageData>) => {
  const { id } = useParams<IDParams>();

  const f = useFormatMessage();
  const features = useFeatures();
  const { onboardingStepIndex, goToStep } = useNavigationToolbar();

  const { setQueryData: setOnboardingDisclosuresQueryData } = useQueryData<OnboardingCompanyDisclosuresPageData>([
    QUERIES_KEYS.COMPANY_ONBOARDING_DISCLOSURES,
    id
  ]);
  const [signatureConfiguration, companyDetails] = data;
  const updateCustomerQueryData = useCustomerDetailsQueryData(companyDetails || { id, type: "company" });

  const [uploadingFilesCount, setUploadingFilesCount] = useState(0);

  const assignmentId = signatureConfiguration.assignment_id;
  const assignments = companyDetails?.assignments?.filter(isActiveAssignment);

  const { selectedAssignment, setSelectedAssignment, shouldUpdateAssignment, updateAssignmentMutation } =
    useDisclosureAssignment(id, assignmentId, assignments);

  const updateSignaturesMutation = useMutation(
    async () => {
      let assignmentId = selectedAssignment?.id;
      if (shouldUpdateAssignment) {
        const savedAssignment = await updateAssignmentMutation.mutateAsync();
        assignmentId = savedAssignment.id;
      }

      return api.updateCompanySignatureConfiguration(id, {
        signature_required: signatureConfiguration.signatures.length > 0,
        signatures: signatureConfiguration.signatures?.map(convertSignatureToCompanyRoleSignatureConfiguration),
        assignment_id: assignmentId
      });
    },
    {
      onSuccess: (response) => {
        setOnboardingDisclosuresQueryData([response, companyDetails!]);
        // Navigate to the next company onboarding step
        goToStep(onboardingStepIndex + 1);
      }
    }
  );

  const addNewAssignment = (assignment: api.Assignment) => {
    updateCustomerQueryData(
      (prevData) => (prevData.assignments = prevData.assignments ? [assignment, ...prevData.assignments] : [assignment])
    );

    // also update the onboarding wizard query cache
    companyDetails.assignments = companyDetails.assignments
      ? [assignment, ...companyDetails.assignments]
      : [assignment];
    setOnboardingDisclosuresQueryData([signatureConfiguration, companyDetails]);

    setSelectedAssignment(assignment);
  };

  const updateAssignment = () => updateSignaturesMutation.mutate();

  const disabled = updateSignaturesMutation.isLoading || uploadingFilesCount > 0;

  const pageTitle = features.ATTACHMENTS
    ? f("onboarding.assignments-and-attachments.title")
    : f("onboarding.assignments.title");
  const pageDescription = features.ATTACHMENTS
    ? f("onboarding.assignments-and-attachments.description")
    : f("onboarding.assignments.description");

  return (
    <Page title={pageTitle}>
      <VerticalStack gap="8">
        <Text variant="bodyMd" as="span">
          {pageDescription}
        </Text>
        <Card>
          <Card.Section>
            {hasContent(assignments) ? (
              <CompanyDisclosureAssignmentSelector
                assignments={assignments}
                selectedAssignment={selectedAssignment}
                setSelectedAssignment={setSelectedAssignment}
                readonly={updateSignaturesMutation.isLoading}
              />
            ) : (
              <EmptyAssignmentBanner customerId={companyDetails.id} onSave={addNewAssignment} />
            )}
          </Card.Section>
        </Card>
        {features.ATTACHMENTS && (
          <Card>
            <Card.Section>
              <HorizontalStack align={"space-between"} blockAlign={"start"}>
                <Text as={"h5"} fontWeight={"bold"}>
                  {f("attachments.section.title")}
                </Text>
                <StyledUploadButton
                  entity={companyDetails}
                  assignments={assignments}
                  selectedAssignmentId={selectedAssignment?.id}
                  onFileUploadingStart={(filesCount) => setUploadingFilesCount(filesCount)}
                  onFileUploadingFinish={() => setUploadingFilesCount(0)}
                />
              </HorizontalStack>
              <AttachmentsSection
                entity={companyDetails}
                uploadingFilesCount={uploadingFilesCount}
                hideTitle
                hideSignatures
                hideEmptyState
                filterAttachment={isActiveAttachment}
              />
            </Card.Section>
          </Card>
        )}
        {updateSignaturesMutation.isError && <ErrorPanel message={updateSignaturesMutation.error} />}
        <HorizontalStack gap="4" align="end">
          <Button disabled={disabled} onClick={() => goToStep(onboardingStepIndex - 1)}>
            {f("button.go.back")}
          </Button>
          <Button disabled={disabled} onClick={() => goToStep(onboardingStepIndex + 1)}>
            {f("common.buttons.actions.skip-step")}
          </Button>
          <Button
            primary
            disabled={uploadingFilesCount > 0}
            loading={updateSignaturesMutation.isLoading}
            onClick={updateAssignment}
          >
            {f("button.go.next")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Page>
  );
};

export default withAsyncPage<OnboardingCompanyDisclosuresPageData>(OnboardingCompanyAssignmentsPage, {
  queryKey: QUERIES_KEYS.COMPANY_ONBOARDING_DISCLOSURES,
  apiFunction: (id, options) =>
    Promise.all([api.getCompanySignatureConfiguration(id, options), api.getCompanyCustomerDetails(id, options)]),
  paramNames: ["id"],
  skeleton: <OnboardingWizardPageSkeleton />
});

const StyledUploadButton = styled(CustomerUploadDocumentButton)`
  position: revert;
`;
