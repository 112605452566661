import React from "react";

import useFormatMessage from "../../hooks/useFormatMessage";
import Badge from "../extensions/Badge";

const NewInfoBadge = () => {
  const f = useFormatMessage();

  return (
    <Badge status="info" size="xsmall">
      {f("common.badge.new-info")}
    </Badge>
  );
};

export default NewInfoBadge;
