import useFeatures from "./useFeatures";
import useFormatMessage from "./useFormatMessage";

const useGetAttachmentsDisplayTitle = () => {
  const f = useFormatMessage();
  const features = useFeatures();

  return features.ELECTRONIC_SIGNATURES
    ? f("attachments.section.title-with-signatures")
    : f("attachments.section.title");
};

export default useGetAttachmentsDisplayTitle;
