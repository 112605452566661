import { ActionListItemDescriptor } from "@shopify/polaris";

import ArchiveCustomerIcon from "../../assets/icons/archivebox.svg";
import FollowupDateIcon from "../../assets/icons/calendar.svg";
import NewProjectIcon from "../../assets/icons/folder-plus.svg";
import ExistingProjectIcon from "../../assets/icons/folders.svg";
import SettingsIcon from "../../assets/icons/gear.svg";
import UpdateResponsibleUserIcon from "../../assets/icons/person-arrow-toward.svg";
import RestoreCustomerIcon from "../../assets/icons/restore.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import { PERMISSIONS_TYPES } from "../constants/permissions";

import useFeatures from "./useFeatures";
import useFormatMessage from "./useFormatMessage";
import useOpenClose from "./useOpenClose";
import usePermissions from "./usePermissions";
import useProjectPermissions from "./useProjectPermissions";

const useCustomersListBulkActions = (selectedCustomersCount: number, isArchivedCustomers?: boolean) => {
  const f = useFormatMessage();
  const features = useFeatures();
  const { isPermittedTo } = usePermissions();
  const { isCreateOrAddToProjectEnabled } = useProjectPermissions();

  const [showResponsibleUserModal, toggleResponsibleUserModal, closeResponsibleUserModal] = useOpenClose();
  const [showArchiveConfirm, toggleArchiveConfirm, closeArchiveConfirm] = useOpenClose();
  const [showDeleteConfirm, toggleDeleteConfirm, closeDeleteConfirm] = useOpenClose();
  const [showRestoreConfirm, toggleRestoreConfirm, closeRestoreConfirm] = useOpenClose();
  const [showAddToProjectModal, toggleAddToProjectModal, closeAddToProjectModal] = useOpenClose();
  const [showAddToNewProjectModal, toggleAddToNewProjectModal, closeAddToNewProjectModal] = useOpenClose();
  const [showFollowupDateModal, toggleFollowupDateModal, closeFollowupDateModal] = useOpenClose();
  const [showChangeScreeningTypeModal, toggleChangeScreeningTypeModal, closesChangeScreeningTypeModal] = useOpenClose();

  const bulkActions: ActionListItemDescriptor[] = [];

  if (isArchivedCustomers) {
    bulkActions.push({
      content: f("bulk.actions.restore.confirmation.title"),
      image: RestoreCustomerIcon,
      onAction: toggleRestoreConfirm,
      disabled: selectedCustomersCount === 0
    });

    if (isPermittedTo(PERMISSIONS_TYPES.DELETE_CUSTOMER)) {
      bulkActions.push({
        content: f("bulk.actions.delete.confirmation.title"),
        image: TrashIcon,
        onAction: toggleDeleteConfirm,
        disabled: selectedCustomersCount === 0
      });
    }
  } else {
    if (features.RESPONSIBLE_USER) {
      bulkActions.push({
        content: f("bulk.actions.responsible.label"),
        image: UpdateResponsibleUserIcon,
        onAction: toggleResponsibleUserModal,
        disabled: selectedCustomersCount === 0
      });
    }

    bulkActions.push({
      content: f("bulk.actions.archive.confirmation.title"),
      image: ArchiveCustomerIcon,
      onAction: toggleArchiveConfirm,
      disabled: selectedCustomersCount === 0
    });

    if (isCreateOrAddToProjectEnabled) {
      bulkActions.push({
        content: f("bulk.actions.add-to-existing-project.label"),
        image: ExistingProjectIcon,
        onAction: toggleAddToProjectModal,
        disabled: selectedCustomersCount === 0
      });

      bulkActions.push({
        content: f("bulk.actions.add-to-new-project.label"),
        image: NewProjectIcon,
        onAction: toggleAddToNewProjectModal,
        disabled: selectedCustomersCount === 0
      });
    }

    bulkActions.push({
      content: f("bulk.actions.followup.label"),
      image: FollowupDateIcon,
      onAction: toggleFollowupDateModal,
      disabled: selectedCustomersCount === 0
    });

    if (features.CUSTOMERS_LIST__BATCH_CHANGE_SCREENING_ACTION && isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS)) {
      bulkActions.push({
        content: f("bulk.actions.change-screening-type.label"),
        image: SettingsIcon,
        onAction: toggleChangeScreeningTypeModal,
        disabled: selectedCustomersCount === 0
      });
    }
  }

  const isEmpty = bulkActions.length === 0;

  return {
    isEmpty,
    bulkActions,
    showResponsibleUserModal,
    showArchiveConfirm,
    showDeleteConfirm,
    showRestoreConfirm,
    showAddToProjectModal,
    showAddToNewProjectModal,
    showFollowupDateModal,
    showChangeScreeningTypeModal,
    closeResponsibleUserModal,
    closeArchiveConfirm,
    closeDeleteConfirm,
    closeRestoreConfirm,
    closeAddToProjectModal,
    closeAddToNewProjectModal,
    closeFollowupDateModal,
    closesChangeScreeningTypeModal
  };
};

export default useCustomersListBulkActions;
