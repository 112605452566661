import React from "react";
import { useParams } from "react-router-dom";
import { BannerStatus, Page, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import ComplyAdvantageMatch from "../../components/ComplyAdvantageMatch/ComplyAdvantageMatch";
import DetailsSkeleton from "../../components/DetailsSkeleton/DetailsSkeleton";
import MatchDetailsReviewSection from "../../components/MatchDetailsReviewSection/MatchDetailsReviewSection";
import MatchHeader from "../../components/MatchHeader/MatchHeader";
import MatchPageNavigation from "../../components/MatchPageNavigation/MatchPageNavigation";
import RDCMatch from "../../components/RDCMatch/RDCMatch";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isConfirmedMatch, isRDCMatch, isRejectedMatch } from "../../helpers/match.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useMatchPage from "../../hooks/useMatchPage";
import { IdentifiersParams } from "../../types/params";

const MatchPage = ({ data }: AsyncPageChild<api.MatchCaseDetailResponse>) => {
  const { id, customerId } = useParams<IdentifiersParams>();

  const f = useFormatMessage();
  const { match, avatarImages, reviews, matchCases, nextUnresolvedMatchCase } = useMatchPage(data);

  const isConfirmed = isConfirmedMatch(data.case);
  const isRejected = isRejectedMatch(data.case);

  const status: BannerStatus = isRejected ? "critical" : isConfirmed ? "warning" : "info";

  return (
    <Page>
      <VerticalStack gap="8">
        <MatchPageNavigation matchData={data} matchCases={matchCases} />
        <MatchHeader
          id={id}
          status={status}
          description={data.case.summary_text || f("common.banner.possible.match.title")}
          subjectName={match.entity_name}
          matchEntityName={match.entity_name}
          date={match.timestamp}
          lastUpdateDate={match.updated_timestamp}
          avatarImages={avatarImages}
          isRDC={isRDCMatch(match)}
        />
        {isRDCMatch(match) ? <RDCMatch matchDetails={data} /> : <ComplyAdvantageMatch matchDetails={data} />}
        <MatchDetailsReviewSection
          customerId={customerId}
          customerType={match.subject_type}
          id={id}
          reviews={reviews}
          isCustomerArchived={data.customer_is_archived}
          nextUnresolvedMatchCase={nextUnresolvedMatchCase}
        />
      </VerticalStack>
      <StyledFooter>
        <MatchPageNavigation matchData={data} matchCases={matchCases} />
      </StyledFooter>
    </Page>
  );
};

const StyledFooter = styled.div`
  margin: var(--p-space-8) 0;
`;

export default withAsyncPage<api.MatchCaseDetailResponse>(MatchPage, {
  queryKey: QUERIES_KEYS.MATCH_DETAILS,
  apiFunction: api.getCustomerMatchCaseDetails,
  paramNames: ["customerId", "id"],
  skeleton: <DetailsSkeleton />
});
