import React from "react";
import { Button, Stack } from "@shopify/polaris";
import { useIsMutating } from "@tanstack/react-query";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

import ImportCustomersProgress from "./ImportCustomersProgress";

interface ImportCustomersFooterProps {
  customerType: api.CustomerType;
  disableImport: boolean;
  actionItemsCount: number;
  onReset(): void;
  onImport(): void;
}

const ImportCustomersFooter = (props: ImportCustomersFooterProps) => {
  const { customerType, disableImport, actionItemsCount, onReset, onImport } = props;

  const f = useFormatMessage();

  const mutationsCount = useIsMutating();

  const loading = actionItemsCount > 0 && mutationsCount > 0;

  const importButtonLabel =
    customerType === "company"
      ? f("import-customers.wizard.labels.import-companies.button")
      : f("import-customers.wizard.labels.import-people.button");

  return (
    <Stack distribution={"trailing"} alignment={"center"}>
      <Stack.Item fill>
        <ImportCustomersProgress actionItemsCount={actionItemsCount} />
      </Stack.Item>
      <Button onClick={onReset}>{f("default.reset")}</Button>
      <Button onClick={onImport} primary disabled={disableImport} loading={loading}>
        {importButtonLabel}
      </Button>
    </Stack>
  );
};

export default ImportCustomersFooter;
