import { isPendingReview } from "../helpers/reviews.helpers";
import { Review } from "../types/CustomerReview";

import useAuth from "./useAuth";

const useCustomerReview = (review?: Review) => {
  const { user: currentUser } = useAuth();

  const fromCurrentUser = review && currentUser && review.reviewed_by?.id === currentUser.id;

  const isRequiresApproval = Boolean(review && review.requires_approval && !review.approval?.approved_by);
  const isEditable = Boolean(fromCurrentUser && isPendingReview(review));

  const hasUncompletedPendingMeasures = review?.measures?.some((measure) => !measure.is_completed);

  const isPending = isRequiresApproval || hasUncompletedPendingMeasures || (review && isPendingReview(review));

  return { isRequiresApproval, isEditable, hasUncompletedPendingMeasures, isPending };
};

export default useCustomerReview;
