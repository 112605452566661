import React from "react";
import { Divider, HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import WarningIcon from "../../../assets/icons/triangle-filled-exclamation-monochrome.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ComplyMatchPosition, HasNewInfoHandler } from "../../types/MatchDetails";
import { hasContent } from "../../utils/collectionUtils";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";
import MatchAttributesCollapsibleCardSections from "../MatchAttributesCollapsibleCardSections/MatchAttributesCollapsibleCardSections";
import MatchCategorySection from "../MatchCategorySection/MatchCategorySection";
import MatchEvent from "../MatchEvent/MatchEvent";
import MatchValuesCollapsible from "../MatchKeyInformationSection/MatchValuesCollapsible";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";
import Tag from "../Tag/Tag";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

import MatchDetailsPEPHelpText from "./MatchDetailsPEPHelpText";

type MatchDetailsPEPSectionProps = {
  pepEvents: api.MatchEvent[];
  positions: ComplyMatchPosition[];
  hasNewInfo: HasNewInfoHandler;
};

const MatchDetailsPEPSection = (props: MatchDetailsPEPSectionProps) => {
  const { pepEvents, positions, hasNewInfo } = props;

  const f = useFormatMessage();

  const showDescription = hasContent(positions) || hasContent(pepEvents);

  return (
    <MatchCategorySection
      title={f("matches.labels.pep")}
      description={
        showDescription ? (
          <TooltipWrapper content={f("matches.labels.pep.detailed.tooltip")}>
            <StyledWarningTag>
              <HorizontalStack blockAlign="center" wrap={false}>
                <Icon source={WarningIcon} useMask width="16px" height="16px" />
                {f("matches.labels.pep.detailed")}
              </HorizontalStack>
            </StyledWarningTag>
          </TooltipWrapper>
        ) : undefined
      }
    >
      {positions.length > 0 && (
        <MatchValuesCollapsible
          label={f("matches.titles.positions")}
          values={positions}
          renderValue={(position, index) => (
            <HorizontalStack key={index} gap="4">
              <HorizontalStack gap="1" blockAlign={"baseline"}>
                <Text as="span" variant="bodySm" fontWeight="semibold">
                  {position.value}
                </Text>
                {position.startDate && (
                  <CondensedText>
                    {f("common.labels.start-date")}: {position.startDate}
                  </CondensedText>
                )}
              </HorizontalStack>
              {hasNewInfo(position) && <NewInfoBadge />}
            </HorizontalStack>
          )}
        />
      )}

      {pepEvents.length > 0 && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.events")}
          elements={pepEvents}
          renderElement={(event) => <MatchEvent event={event} showNewInfo={hasNewInfo(event)} />}
        />
      )}

      {showDescription && (
        <>
          <Divider />
          <MatchDetailsPEPHelpText />
        </>
      )}
    </MatchCategorySection>
  );
};

const StyledWarningTag = styled(Tag)`
  & .Polaris-Tag {
    background-color: var(--kdk-yellow-500);
    border: solid var(--p-border-width-1) var(--kdk-yellow-600);
  }
`;

export default MatchDetailsPEPSection;
