import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ActionListItemDescriptor, HorizontalStack, Popover } from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import StarIcon from "../../../../assets/icons/star.svg";
import RemoveIcon from "../../../../assets/icons/trash.svg";
import { RESPONSIBLE_USERS_NAME_PARAMETER } from "../../../constants/customers-list-search-params";
import { MOBILE_BREAKPOINT } from "../../../constants/styles";
import useAuth from "../../../hooks/useAuth";
import useCustomersListParams from "../../../hooks/useCustomersListParams";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../../hooks/useFormatMultipleNames";
import useSnackbar from "../../../hooks/useSnackbar";
import Button from "../../extensions/Button";
import Icon from "../../extensions/Icon";
import PopoverButton from "../../PopoverButton/PopoverButton";
import TooltipWrapper from "../../TooltipWrapper/TooltipWrapper";
import useCustomersListFilters from "../useCustomersListFilters";

import FilterDetails from "./FilterDetails";
import useSavedFilters from "./useSavedFilters";

const SavedFilters = () => {
  const f = useFormatMessage();
  const feature = useFeatures();
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const { appliedFilters, resetAllFilters } = useCustomersListFilters(false);
  const { isEmptySearch } = useCustomersListParams();
  const formatMultipleNames = useFormatMultipleNames();
  const { activeFilterName, saveCurrentFilters, savedFilters, deleteSavedFilters, applyFilter } = useSavedFilters();
  const { setSnackbar } = useSnackbar();

  const appliedFiltersLabel = formatMultipleNames(appliedFilters.filter(Boolean).map((filter) => filter!.label));

  const onSaveNewFilter = (filterName: string, closePopover: () => void) => {
    closePopover();
    saveCurrentFilters(filterName);
  };

  const onFilterDelete = (filterName: string) => {
    deleteSavedFilters(filterName);
    setSnackbar(f("component.filters.saved-filters.deleted.message"));
  };

  const actions: ActionListItemDescriptor[] = [
    {
      content: f("component.filters.saved-filters.without-filters"),
      onAction: () => resetAllFilters(),
      active: isEmptySearch,
      suffix: isEmptySearch ? <Icon source={TickSmallMinor} /> : undefined
    }
  ];

  if (feature.RESPONSIBLE_USER && user) {
    const myCustomersSearch = `?${RESPONSIBLE_USERS_NAME_PARAMETER}=${user.id}`;
    const isMyCustomersFilter = location.search === myCustomersSearch;

    actions.push({
      content: f("component.filters.saved-filters.my-customers"),
      onAction: () => history.replace({ ...location, search: myCustomersSearch }),
      active: isMyCustomersFilter,
      suffix: isMyCustomersFilter ? <Icon source={TickSmallMinor} /> : undefined
    });
  }

  // add saved filters
  actions.push(
    ...savedFilters.map((filterName) => ({
      content: filterName,
      onAction: () => applyFilter(filterName),
      active: filterName === activeFilterName,
      suffix: (
        <HorizontalStack gap="4" wrap={false}>
          {filterName === activeFilterName && <Icon source={TickSmallMinor} />}
          <TooltipWrapper content={f("component.filters.saved-filters.delete-filter")} dismissOnMouseOut>
            <Button plain outline icon={RemoveIcon} onClick={() => onFilterDelete(filterName)} />
          </TooltipWrapper>
        </HorizontalStack>
      )
    }))
  );

  return (
    <StyledPopoverButton
      icon={StarIcon}
      connectedDisclosure={{ actions }}
      label={f("component.filters.saved-filters.save-filters.label")}
      render={(closePopover) => (
        <Popover.Section>
          <FilterDetails
            defaultFilterName={appliedFiltersLabel}
            onSave={(filterName) => onSaveNewFilter(filterName, closePopover)}
            onCancel={closePopover}
          />
        </Popover.Section>
      )}
    />
  );
};

const StyledPopoverButton = styled(PopoverButton)`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    .Polaris-Button__Text {
      display: none;
    }
  }
`;

export default SavedFilters;
