import React from "react";
import { ActionListItemDescriptor, Toast } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import EditIcon from "../../../../assets/icons/pen-line.svg";
import RestoreIcon from "../../../../assets/icons/restore.svg";
import EndIcon from "../../../../assets/icons/xmark-circle.svg";
import api from "../../../api";
import ActionsDropdown from "../../../components/ActionsDropdown/ActionsDropdown";
import ProjectInfoModal from "../../../components/ProjectInfoModal/ProjectInfoModal";
import { QUERIES_KEYS } from "../../../constants/queries-keys";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import useQueryData from "../../../hooks/useQueryData";

import ProjectEndConfirmation from "./ProjectEndConfirmation";

interface ProjectActionsMenuProps {
  project: api.Project;
  readonly?: boolean;
}

const ProjectActionsMenu = (props: ProjectActionsMenuProps) => {
  const { project, readonly } = props;

  const [showInfoModal, toggleShowInfoModal, closeInfoModal] = useOpenClose();
  const [showEndProjectConfirmation, toggleEndProjectConfirmation, closeEndProjectConfirmation] = useOpenClose();

  const f = useFormatMessage();
  const { setQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, project.id]);
  const disabled = readonly || project.status !== "ACTIVE";

  const reactivateProjectMutation = useMutation(() => api.updateProject(project.id, { status: "ACTIVE" }), {
    onSuccess: async (response) => setQueryData(response)
  });

  const reactivateProject = () => reactivateProjectMutation.mutate();

  const actions: ActionListItemDescriptor[] = [
    {
      content: f("project.actions.labels.edit"),
      onAction: toggleShowInfoModal,
      image: EditIcon,
      disabled
    }
  ];

  if (project.status === "ACTIVE") {
    actions.push({
      content: f("project.actions.labels.end-project"),
      onAction: toggleEndProjectConfirmation,
      image: EndIcon,
      disabled: reactivateProjectMutation.isLoading || readonly
    });
  }

  if (project.status === "INACTIVE") {
    actions.push({
      content: f("project.actions.labels.reactivate"),
      onAction: reactivateProject,
      image: RestoreIcon
    });
  }

  const resetErroneousMutation = () => {
    if (reactivateProjectMutation.isError) {
      reactivateProjectMutation.reset();
    }
  };

  return (
    <>
      <ActionsDropdown items={actions} loading={reactivateProjectMutation.isLoading} primary size={"medium"} />
      {reactivateProjectMutation.isError && (
        <Toast content={reactivateProjectMutation.error as string} error onDismiss={resetErroneousMutation} />
      )}
      {showInfoModal && <ProjectInfoModal project={project} onClose={closeInfoModal} />}
      {showEndProjectConfirmation && <ProjectEndConfirmation project={project} onClose={closeEndProjectConfirmation} />}
    </>
  );
};

export default ProjectActionsMenu;
