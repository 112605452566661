"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatOrganizationNumber = (orgNumber) => {
    if (!orgNumber)
        return;
    orgNumber = orgNumber.replace(/ /g, '');
    if (orgNumber.length !== 9) {
        return orgNumber;
    }
    return orgNumber.substring(0, 3) + '\u00a0' + orgNumber.substring(3, 6) + '\u00a0' + orgNumber.substring(6);
};
exports.isValidOrganizationNumber = (orgNumber) => {
    return !!/^\d{9}$/.test(orgNumber);
};
