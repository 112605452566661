import React from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import IndustryTypeWarningBadge from "../IndustryTypeWarningBadge/IndustryTypeWarningBadge";
import KDKBanner from "../KDKBanner/KDKBanner";

type IndustryInfoProps = {
  industry?: api.IndustryRiskDetail;
};

const IndustryInfo = (props: IndustryInfoProps) => {
  const { industry } = props;

  const f = useFormatMessage();

  if (!industry) {
    return null;
  }

  const industryCodeDescriptions = [industry.code, industry.industry_code_description].filter((e) => !!e).join(" — ");

  const showWarning = industry.has_risk;

  return (
    <KDKBanner status={showWarning ? "warning" : "info"} hideIcon>
      <VerticalStack gap="2">
        {showWarning && <IndustryTypeWarningBadge />}
        <VerticalStack>
          <HorizontalStack blockAlign="center" gap="2">
            <Text as="span" fontWeight="semibold">
              {f("industry-info.labels.industry-code")}:
            </Text>
            <Text as="span">{industryCodeDescriptions}</Text>
          </HorizontalStack>
          {industry.risk_description && (
            <>
              <Text as="span" fontWeight="semibold">{`${f("common.labels.editorial-explanation")}: `}</Text>
              <Text as="span">{industry.risk_description}</Text>
            </>
          )}
        </VerticalStack>
      </VerticalStack>
    </KDKBanner>
  );
};

export default IndustryInfo;
