import React from "react";
import { ActionListItemDescriptor, HorizontalStack } from "@shopify/polaris";

import CrossmarkIcon from "../../../../../assets/icons/xmark-circle.svg";
import api from "../../../../api";
import ActionsDropdown from "../../../../components/ActionsDropdown/ActionsDropdown";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useOpenClose from "../../../../hooks/useOpenClose";
import { Customer } from "../../../../types/utilities";

import ProjectCustomersLabelPopover from "./ProjectCustomersLabelPopover";
import RemoveCustomersFromProjectConfirm from "./RemoveCustomersFromProjectConfirm";

interface ProjectCustomersBulkActionsProps {
  project: api.Project;
  customers: Customer[];
  readonly?: boolean;
  onClearSelection(): void;
}

const ProjectCustomersBulkActions = (props: ProjectCustomersBulkActionsProps) => {
  const { project, customers, readonly, onClearSelection } = props;

  const f = useFormatMessage();

  const [showRemoveCustomersConfirm, toggleRemoveCustomersConfirm, closeRemoveCustomersConfirm] = useOpenClose();

  const actions: ActionListItemDescriptor[] = [
    {
      content: f("project.customers.actions.remove-from-project"),
      disabled: customers.length === 0 || readonly,
      image: CrossmarkIcon,
      onAction: toggleRemoveCustomersConfirm
    }
  ];

  const handleSuccess = () => {
    closeRemoveCustomersConfirm();
    onClearSelection();
  };

  return (
    <HorizontalStack gap="2">
      <ActionsDropdown items={actions} disabled={readonly} cutoff={1} />
      <ProjectCustomersLabelPopover project={project} customers={customers} readonly={readonly} />
      {showRemoveCustomersConfirm && (
        <RemoveCustomersFromProjectConfirm
          project={project}
          customers={customers}
          onClose={closeRemoveCustomersConfirm}
          onSuccess={handleSuccess}
        />
      )}
    </HorizontalStack>
  );
};

export default ProjectCustomersBulkActions;
