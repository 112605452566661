import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../api";
import { MOBILE_BREAKPOINT } from "../../../constants/styles";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { isEmailEnabled } from "../../../utils/util";
import AssignmentInfo from "../../AssignmentInfo/AssignmentInfo";
import CopyLabel from "../../CopyLabel/CopyLabel";
import SectionTile from "../../SectionTile/SectionTile";

type PendingDisclosureDocumentSignatureProps = {
  signature: api.Signature;
};

const PendingDisclosureDocumentSignature = (props: PendingDisclosureDocumentSignatureProps) => {
  const { signature } = props;

  const f = useFormatMessage();

  const title = isEmailEnabled(signature) ? f("disclosure-card.email-tile") : f("disclosure-card.link-tile");

  return (
    <StyledQuestionnaireName>
      {signature.assignment_id && (
        <AssignmentInfo assignmentName={signature.assignment_name} assignmentPurpose={signature.assignment_purpose} />
      )}
      <HorizontalStack gap="4">
        <SectionTile title={title} gap="1">
          {isEmailEnabled(signature) ? (
            <Text as="span">{signature.email}</Text>
          ) : (
            <CopyLabel size="slim">{signature.signing_page_url}</CopyLabel>
          )}
        </SectionTile>
        {signature.pin_code && (
          <SectionTile title={f("disclosure-card.pin-tile")} gap="1">
            <CopyLabel size="slim">{signature.pin_code}</CopyLabel>
          </SectionTile>
        )}
      </HorizontalStack>
    </StyledQuestionnaireName>
  );
};

const StyledQuestionnaireName = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: var(--p-space-8);

  // in mobile view - use one column
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: 1fr;
    gap: var(--p-space-4);
  }
`;

export default PendingDisclosureDocumentSignature;
