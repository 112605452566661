import { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import { convertToCreatePersonCustomerRequest } from "../../helpers/customer.helpers";
import useChangedState from "../../hooks/useChangedState";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useDisclosureAssignment from "../../hooks/useDisclosureAssignment";
import useErrorParser from "../../hooks/useErrorParser";
import useValidateDisclosureForm from "../../hooks/useValidateDisclosureForm";
import HistoryLocationState from "../../types/HistoryLocationState";
import { CustomerDetails } from "../../types/utilities";

import { OnboardingPersonFormProps } from "./OnboardingPersonForm";

const useOnboardingPersonForm = (props: OnboardingPersonFormProps) => {
  const {
    personId,
    personValues,
    screeningType: initialScreeningType,
    isSignatureRequired,
    signature: initialSignature,
    disableCancelOnboarding,
    responsibleUsersIds: initialResponsibleUsersIds,
    assignments
  } = props;

  const history = useHistory<HistoryLocationState>();
  const defaultCountry = useDefaultCountry();
  const validateDisclosureForm = useValidateDisclosureForm();

  const [person, setPerson, changed] = useChangedState(personValues);
  const [isFormValid, setIsFormValid] = useState(false);
  const [responsibleUsersIds, setResponsibleUsersIds, responsibleUsersChanged] =
    useChangedState(initialResponsibleUsersIds);
  const [screeningType, setScreeningType] = useState<api.ScreeningType>(initialScreeningType);
  const [signature, setSignature] = useState(initialSignature);
  const { selectedAssignment, setSelectedAssignment, shouldUpdateAssignment, updateAssignmentMutation } =
    useDisclosureAssignment(personId, signature.assignment_id, assignments);

  const [shouldSendDisclosure, setShouldSendDisclosure] = useState(isSignatureRequired);
  const [finishOnboarding, setFinishOnboarding] = useState(true);

  const parseError = useErrorParser("person");

  const createdFromProject = history.location.state?.createdFromProject;

  const isSavedPerson = personId !== undefined;

  useEffect(() => {
    // update the signature email only if send disclosure is enabled
    // and the signature should be sent as an email
    if (shouldSendDisclosure && signature.send_email) {
      setSignature((prevState) => ({ ...prevState, email: person.email }));
    }
  }, [shouldSendDisclosure, signature.send_email, person.email]);

  const navigateBack = (personId: string) => {
    const redirectURLAfterFinish = createdFromProject
      ? generatePath(ROUTES.PROJECT_DETAILS, { id: createdFromProject.id })
      : generatePath(ROUTES.PERSON_DETAILS, { id: personId });

    history.push(redirectURLAfterFinish);
  };

  const registerPerson = () => {
    setFinishOnboarding(true);
    savePersonMutation.mutate();
  };

  const savePersonMutation = useMutation(
    () => {
      const personCustomerRequest = convertToCreatePersonCustomerRequest(person, defaultCountry);

      return isSavedPerson
        ? api.updatePerson(personId, personCustomerRequest)
        : api.createPersonCustomer({ ...personCustomerRequest, project_id: createdFromProject?.id });
    },
    {
      onSuccess: (updatedPerson) => updateScreeningMutation.mutate(updatedPerson.id)
    }
  );

  const updateScreeningMutation = useMutation<api.PersonScreeningConfiguration, unknown, string>(
    (personId) =>
      api.updatePersonScreeningConfiguration(personId, {
        screening_type: screeningType
      }),
    {
      onSuccess: (response) => updateSignatureConfigurationMutation.mutate(response.customer_id)
    }
  );

  const updateSignatureConfigurationMutation = useMutation<api.PersonSignatureConfiguration, unknown, string>(
    async (personId) => {
      const request: api.PatchPersonSignatureConfigurationRequest = shouldSendDisclosure
        ? {
            ...signature,
            signature_required: true,
            assignment_id: selectedAssignment?.id
          }
        : { signature_required: false };

      // update the existing assignment with the purpose change (if there was any)
      // and only after that continue to save the signature
      if (shouldSendDisclosure && shouldUpdateAssignment) {
        await updateAssignmentMutation.mutateAsync();
      }

      return api.setPersonSignatureConfiguration(personId, request);
    },
    {
      onSuccess: (response, personId) => {
        if (finishOnboarding) {
          finishOnboardingMutation.mutate(personId);
        } else {
          handleCancel();
        }
      }
    }
  );

  const updateResponsibleUsersMutation = useMutation<CustomerDetails, unknown, string>((personId) =>
    api.updateCustomerDetails(personId, { responsible_users: responsibleUsersIds })
  );

  const finishOnboardingMutation = useMutation(api.finishOnboarding, {
    onSuccess: (response, personId) => {
      if (responsibleUsersChanged) {
        updateResponsibleUsersMutation.mutate(personId, {
          onSuccess: () => navigateBack(personId)
        });
      } else {
        navigateBack(personId);
      }
    }
  });

  const isSignatureValid = validateDisclosureForm([signature]);
  const invalid = !isFormValid || (shouldSendDisclosure && !isSignatureValid);

  const isLoading =
    savePersonMutation.isLoading ||
    updateScreeningMutation.isLoading ||
    updateSignatureConfigurationMutation.isLoading ||
    finishOnboardingMutation.isLoading ||
    updateResponsibleUsersMutation.isLoading;

  const { generalError, fieldsErrorsMap } = parseError(
    savePersonMutation.error ||
      updateScreeningMutation.error ||
      updateScreeningMutation.error ||
      finishOnboardingMutation.error ||
      updateResponsibleUsersMutation.error
  );

  const cancelOnboarding = () =>
    isSavedPerson && !disableCancelOnboarding ? api.cancelOnboarding(personId) : Promise.resolve<unknown>(undefined);

  const savePerson = () => {
    setFinishOnboarding(false);
    savePersonMutation.mutate();
  };

  const handleCancel = () =>
    createdFromProject
      ? history.push(generatePath(ROUTES.PROJECT_DETAILS, { id: createdFromProject.id }))
      : history.push(generatePath(ROUTES.HOME));

  return {
    createdFromProject,
    isSavedPerson,
    person,
    setPerson,
    changed,
    responsibleUsersIds,
    setResponsibleUsersIds,
    screeningType,
    setScreeningType,
    signature,
    setSignature,
    selectedAssignment,
    setSelectedAssignment,
    invalid,
    setIsFormValid,
    shouldSendDisclosure,
    setShouldSendDisclosure,
    finishOnboarding,
    isLoading,
    generalError,
    fieldsErrorsMap,
    registerPerson,
    savePerson,
    cancelOnboarding,
    handleCancel
  };
};

export default useOnboardingPersonForm;
