import React from "react";
import { Checkbox, Collapsible, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import { getBirthDate, getFullName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Optional } from "../../types/utilities";
import DisclosureSignatureForm, {
  DisclosureSignatureFormProps
} from "../DisclosureSignatureForm/DisclosureSignatureForm";
import DisplayName from "../DisplayName/DisplayName";
import SectionTile from "../SectionTile/SectionTile";

type DisclosureFormRowProps = Optional<DisclosureSignatureFormProps, "signature"> & {
  person?: api.Person;
  roleType: api.RoleType;
  selected?: boolean;
  onSelectionChanged(value: boolean): void;
};

const DisclosureFormRow = (props: DisclosureFormRowProps) => {
  const { person, roleType, selected = true, signature, onSelectionChanged, ...disclosureSignatureFormProps } = props;

  const f = useFormatMessage();

  const customerTitle = roleType ? f(RoleTypes[roleType]) : f("disclosure-request.column.customer");

  return (
    <VerticalStack gap="4">
      <Checkbox
        checked={selected}
        label={
          <SectionTile title={customerTitle} gap="1">
            <DisplayName type="person" name={getFullName(person)} birthDate={getBirthDate(person)} wrap strong />
          </SectionTile>
        }
        onChange={(value) => onSelectionChanged(value)}
      />
      <Collapsible open={selected} id={"DisclosureFormRow" + person?.id}>
        {signature && <DisclosureSignatureForm signature={signature} {...disclosureSignatureFormProps} />}
      </Collapsible>
    </VerticalStack>
  );
};

export default DisclosureFormRow;
