import KDKBanner from "../../components/KDKBanner/KDKBanner";
import useFormatMessage from "../../hooks/useFormatMessage";

const ImportCustomersInstructionsBanner = () => {
  const f = useFormatMessage();

  return (
    <KDKBanner status={"info"} hideIcon title={f("import-customers.wizard.page.welcome.title")}>
      <p>{f("import-customers.wizard.page.welcome.description")}</p>
    </KDKBanner>
  );
};

export default ImportCustomersInstructionsBanner;
