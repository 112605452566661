import React from "react";
import { Button, Card, HorizontalStack, Modal, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import MatchReviewCard from "../MatchReviewCard/MatchReviewCard";
import MatchReviewForm from "../MatchReviewForm/MatchReviewForm";
import MatchReviewSuccessToast from "../MatchReviewSuccessToast/MatchReviewSuccessToast";

import useMatchReview from "./useMatchReview";

type MatchDetailsReviewSectionProps = {
  customerId: string;
  customerType: api.CustomerType;
  id: string;
  reviews: api.MatchReview[];
  isCustomerArchived: boolean;
  nextUnresolvedMatchCase?: api.MatchCaseSummary;
};

const MatchDetailsReviewSection = (props: MatchDetailsReviewSectionProps) => {
  const { customerId, customerType, id, reviews, isCustomerArchived, nextUnresolvedMatchCase } = props;

  const f = useFormatMessage();
  const { onMatchReviewed, resolvedReview, clearResolvedReview } = useMatchReview(customerId, customerType, id);

  const [isReviewModalOpen, toggleReviewModal] = useOpenClose();

  const hasExistingReviews = reviews.length > 0;

  return (
    <Card>
      <Card.Section>
        <VerticalStack gap="6">
          <HorizontalStack align={"space-between"} wrap={false} blockAlign={"center"}>
            <Text as={"span"} variant={"bodyLg"} fontWeight={"semibold"}>
              {f("matches.titles.assessment")}
            </Text>
            {hasExistingReviews && (
              <Button primary onClick={toggleReviewModal}>
                {f("reviews.labels.change-review")}
              </Button>
            )}
          </HorizontalStack>
          {!hasExistingReviews && (
            <MatchReviewForm
              customerId={customerId}
              customerType={customerType}
              caseId={id}
              readonly={isCustomerArchived}
              onAfterMatchReviewed={onMatchReviewed}
            />
          )}
        </VerticalStack>
      </Card.Section>
      {hasExistingReviews && (
        <div id={PAGE_ANCHORS.REVIEWS_SECTION}>
          <Card.Section>
            <VerticalStack gap="4">
              {reviews.map((review) => (
                <MatchReviewCard review={review} key={review.id} />
              ))}
            </VerticalStack>
          </Card.Section>
        </div>
      )}
      {isReviewModalOpen && (
        <Modal open title={f("matches.titles.assessment")} large onClose={toggleReviewModal}>
          <Modal.Section>
            <MatchReviewForm
              customerId={customerId}
              customerType={customerType}
              caseId={id}
              readonly={isCustomerArchived}
              onClose={toggleReviewModal}
              onAfterMatchReviewed={onMatchReviewed}
            />
          </Modal.Section>
        </Modal>
      )}
      {resolvedReview && (
        <MatchReviewSuccessToast
          isApproved={resolvedReview?.is_match}
          customerId={customerId}
          nextMatch={nextUnresolvedMatchCase}
          onDismiss={clearResolvedReview}
        />
      )}
    </Card>
  );
};

export default MatchDetailsReviewSection;
