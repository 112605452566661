import styled from "styled-components";

import { WHATS_NEW_TRELLO_BOARD } from "../../constants/external-urls";
import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";

const WhatsNewButton = () => {
  const f = useFormatMessage();

  return (
    <StyledButton url={WHATS_NEW_TRELLO_BOARD} external size={"slim"} monochrome primary id={"whats-new"}>
      {f("menu.whats-new")}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  & .Polaris-Button--primary {
    --pc-button-color: var(--p-decorative-one-surface);
    --pc-button-text: var(--p-text);
    --pc-button-color-hover: hsl(38, 100%, 65%);
    --pc-button-color-active: hsl(38, 100%, 60%);
    --pc-button-color-depressed: hsl(38, 100%, 55%);

    border-radius: var(--p-border-radius-6);
    box-shadow: none;

    &:hover {
      background-color: var(--pc-button-color-hover);
    }
    &:active {
      background-color: var(--pc-button-color-active);
    }
  }
`;

export default WhatsNewButton;
