import React, { useState } from "react";

import api from "../../api";
import { createNewOwner } from "../../helpers/person.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { noop } from "../../utils/util";
import useCompanyOwners from "../CompanyOwners/useCompanyOwners";
import FloatPrimaryButton from "../FloatPrimaryButton/FloatPrimaryButton";
import OwnerModal from "../OwnerModal/OwnerModal";

interface AddOwnerButtonProps {
  customer: api.CompanyCustomerDetailResponse;
  readonly?: boolean;
}

const AddOwnerButton = (props: AddOwnerButtonProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();

  const { handleOwnerCreate } = useCompanyOwners(customer);
  const [owner, setOwner] = useState<api.Owner>();

  const openModal = () => setOwner(createNewOwner(customer.country));

  const closeModal = () => setOwner(undefined);

  const handleCreateOwner = (newOwner: api.Owner) => {
    handleOwnerCreate(newOwner);
    closeModal();
  };

  const disabled = readonly || (!customer.is_manual && !customer.can_have_shareholders);

  return (
    <>
      <FloatPrimaryButton onClick={openModal} disabled={disabled}>
        {f("owners.card.buttons.add-owner")}
      </FloatPrimaryButton>
      {owner && (
        <OwnerModal
          customerId={customer.id}
          ownerData={owner}
          isArchiveDisabled={false}
          onArchive={noop}
          onUpdate={noop}
          onCreate={handleCreateOwner}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default AddOwnerButton;
