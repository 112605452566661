import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";

import useQueryData from "./useQueryData";

const useChecklistArchiveMutation = () => {
  const { updateQueryData } = useQueryData<api.ReviewChecklistListResponse>([QUERIES_KEYS.REVIEW_CHECKLISTS]);

  const archiveChecklistMutation = useMutation<unknown, unknown, api.ReviewChecklist>(
    (checklist) => api.archiveReviewChecklist(checklist.id),
    {
      onSuccess: (_, checklist) => {
        updateQueryData((queryData) => {
          queryData.checklists = queryData.checklists.map((entry) =>
            entry.id === checklist.id ? { ...checklist, is_archived: true } : entry
          );
        });
      }
    }
  );

  return archiveChecklistMutation;
};

export default useChecklistArchiveMutation;
