import React from "react";
import { Card, HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import CardNavigationSection from "../../components/CardNavigationSection/CardNavigationSection";
import CollapsibleSection from "../../components/CollapsibleSection/CollapsibleSection";
import CompanyInfoTable from "../../components/CompanyInfoTable/CompanyInfoTable";
import CompanyScreeningSection from "../../components/CompanyScreeningSection/CompanyScreeningSection";
import useCompanyHasCriticalUpdates from "../../components/CustomerDetailsHeader/CustomerDetailsBanner/useCompanyHasCriticalUpdates";
import CustomerDetailsHeader from "../../components/CustomerDetailsHeader/CustomerDetailsHeader";
import CustomerFeaturesSection from "../../components/CustomerFeaturesSection/CustomerFeaturesSection";
import CustomerNotesSection from "../../components/CustomerNotesSection/CustomerNotesSection";
import CustomerReviewsSection from "../../components/CustomerReviewsSection/CustomerReviewsSection";
import DetailsSkeleton from "../../components/DetailsSkeleton/DetailsSkeleton";
import DisclosureDocumentSection from "../../components/DisclosureDocumentSection/DisclosureDocumentSection";
import MatchCasesSection from "../../components/MatchCasesSection/MatchCasesSection";
import NavigationSection from "../../components/NavigationSection/NavigationSection";
import NewInfoBadge from "../../components/NewInfoBadge/NewInfoBadge";
import RolesAndOwnersSection from "../../components/RolesAndOwnersSection/RolesAndOwnersSection";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isOnboardingFinished } from "../../helpers/onboarding.helpers";
import { isScreeningActive, isScreeningStarting } from "../../helpers/screening";
import useAppTitle from "../../hooks/useAppTitle";
import useCustomerDetailsRefresh from "../../hooks/useCustomerDetailsRefresh";
import useFormatMessage from "../../hooks/useFormatMessage";

const CompanyDetailsPage = ({ data: customer }: AsyncPageChild<api.CompanyCustomerDetailResponse>) => {
  const f = useFormatMessage();

  useAppTitle(customer.name);

  useCustomerDetailsRefresh(customer);

  const hasFinishedOnboarding = isOnboardingFinished(customer);
  const hasCriticalUpdates = useCompanyHasCriticalUpdates(customer);
  const readonly = customer.is_archived || !hasFinishedOnboarding;

  const hasNewInfo = customer.info_updates.some((info) => !info.is_reviewed);

  return (
    <Page>
      <VerticalStack gap="4">
        <Card>
          <Card.Section>
            <NavigationSection anchor={PAGE_ANCHORS.TOP_SECTION}>
              <CustomerDetailsHeader customer={customer} />
            </NavigationSection>
          </Card.Section>
          <Card.Section flush>
            <NavigationSection anchor={PAGE_ANCHORS.CUSTOMER_DETAILS}>
              <CollapsibleSection
                showPrefix
                expanded={hasCriticalUpdates}
                title={
                  <HorizontalStack gap="4">
                    <Text variant="bodyMd" as="span">
                      {f("company.details.title")}
                    </Text>
                    {hasNewInfo && <NewInfoBadge />}
                  </HorizontalStack>
                }
              >
                <CompanyInfoTable companyDetails={customer} infoUpdates={customer.info_updates} readonly={readonly} />
              </CollapsibleSection>
            </NavigationSection>
          </Card.Section>
        </Card>

        <NavigationSection anchor={PAGE_ANCHORS.CUSTOMER_MATCH_CASES}>
          <Card>
            <Card.Section>
              <MatchCasesSection
                customerId={customer.id}
                customerType={customer.type!}
                hasFinishedOnboarding={hasFinishedOnboarding}
                matchCases={customer.match_cases}
                isScreeningStarting={isScreeningStarting(customer.screening)}
                isScreeningActive={isScreeningActive(customer.screening)}
                readonly={readonly}
              />
            </Card.Section>
            <Card.Section flush>
              <CollapsibleSection
                showPrefix
                title={
                  <Text variant="bodyMd" as="span">
                    {f("company.details.screening.title")}
                  </Text>
                }
              >
                <CompanyScreeningSection customer={customer} />
              </CollapsibleSection>
            </Card.Section>
          </Card>
        </NavigationSection>

        <CardNavigationSection anchor={PAGE_ANCHORS.COMPANY_ROLES_AND_OWNERS_SECTION}>
          <RolesAndOwnersSection customer={customer} readonly={readonly} />
        </CardNavigationSection>

        <CardNavigationSection anchor={PAGE_ANCHORS.DISCLOSURES_SECTION}>
          <DisclosureDocumentSection
            customerId={customer.id}
            customerType="company"
            disclosureDocuments={customer.disclosure_documents}
            readonly={readonly}
            addButtonLabel={f("common.buttons.actions.new-disclosure")}
            emptyLabel={f("disclosure-documents.empty")}
            assignments={customer.assignments}
          />
        </CardNavigationSection>

        <CardNavigationSection>
          <CustomerFeaturesSection customer={customer} readonly={readonly} />
        </CardNavigationSection>

        <CardNavigationSection anchor={PAGE_ANCHORS.REVIEWS_SECTION} bottom>
          <CustomerReviewsSection customer={customer} readonly={readonly} />
        </CardNavigationSection>

        <SectionMargin size={"medium"} />

        <CardNavigationSection>
          <CustomerNotesSection customer={customer} readonly={readonly} />
        </CardNavigationSection>
      </VerticalStack>
    </Page>
  );
};

export default withAsyncPage<api.CompanyCustomerDetailResponse>(CompanyDetailsPage, {
  queryKey: QUERIES_KEYS.COMPANY_DETAILS,
  apiFunction: api.getCompanyCustomerDetails,
  paramNames: ["id"],
  skeleton: <DetailsSkeleton />
});
