import React from "react";
import { Card, Layout, SkeletonBodyText, SkeletonPage, Stack, Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

const CustomerReviewSkeleton = () => {
  const f = useFormatMessage();

  return (
    <SkeletonPage>
      <VerticalStack gap="8">
        <Text variant="heading2xl" as="p">
          {f("reviews.titles.risk-review")}
        </Text>
        <Layout>
          <Layout.Section>
            <VerticalStack gap="8">
              <Stack.Item />
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={6} />
                </Card.Section>
              </Card>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={6} />
                </Card.Section>
              </Card>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={6} />
                </Card.Section>
              </Card>
            </VerticalStack>
          </Layout.Section>
          <Layout.Section oneThird>
            <VerticalStack gap="8">
              <Stack.Item />
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={6} />
                </Card.Section>
              </Card>
            </VerticalStack>
          </Layout.Section>
        </Layout>
      </VerticalStack>
    </SkeletonPage>
  );
};

export default CustomerReviewSkeleton;
