import React from "react";

import api from "../../api";
import UsersSelect from "../../components/UsersSelect/UsersSelect";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { convertAssignmentToUpdateRequest } from "../../helpers/assignments.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData from "../../hooks/useQueryData";

type AssignmentResponsibleUsersSelectProps = {
  assignment: api.CustomerAssignmentDetails;
  readonly?: boolean;
};

const AssignmentResponsibleUsersSelect = (props: AssignmentResponsibleUsersSelectProps) => {
  const { assignment, readonly } = props;

  const f = useFormatMessage();
  const { updateQueryData } = useQueryData<api.CustomerAssignmentDetails>([
    QUERIES_KEYS.ASSIGNMENT_DETAILS,
    assignment.customer_id,
    assignment.id
  ]);

  const updateResponsibleUsers = (responsibleUserIds: string[]) =>
    api
      .updateCustomerAssignment(assignment.customer_id, assignment.id, {
        ...convertAssignmentToUpdateRequest(assignment),
        responsible_users: responsibleUserIds
      })
      .then((response) => response.responsible_users);

  return (
    <UsersSelect
      selectedUsers={assignment.responsible_users || []}
      emptySelectionLabel={f("responsible.empty.users")}
      updateErrorMessage={"responsible.error"}
      readonly={readonly}
      strong
      onUserSelection={updateResponsibleUsers}
      onAfterUserSelection={(responsibleUsers) => {
        updateQueryData((queryData) => (queryData.responsible_users = responsibleUsers));
      }}
    />
  );
};

export default AssignmentResponsibleUsersSelect;
