import React from "react";
import { Checkbox, CheckboxProps } from "@shopify/polaris";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";

const FullWidthCheckbox = (props: CheckboxProps) => {
  const { checked, disabled } = props;

  return (
    <StyledCheckboxWrapper checked={checked === "indeterminate" || checked === true} disabled={disabled}>
      <Checkbox {...props} />
    </StyledCheckboxWrapper>
  );
};

const StyledCheckboxWrapper = styled.span<{ checked?: boolean; disabled?: boolean }>`
  border-radius: var(--p-border-radius-1);
  padding: var(--p-space-4);
  background-color: ${({ checked, disabled }) =>
    checked && !disabled ? "var(--p-surface-selected)" : "var(--p-surface)"};
  border: var(--p-border-width-1) solid
    ${({ checked, disabled }) => (checked && !disabled ? "var(--p-interactive)" : "var(--p-border-subdued)")};

  & .Polaris-Choice__Label {
    user-select: none;
  }

  & .Polaris-Choice__Descriptions {
    padding-left: var(--p-space-1);
  }

  width: 100%;

  // For small viewports - render button as a regular block element
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: var(--p-space-2);
  }
`;

export default FullWidthCheckbox;
