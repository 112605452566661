import React from "react";
import { HorizontalStack, IconSource, Text } from "@shopify/polaris";

import IconConfirmed from "../../../../assets/icons/checkmark-green.svg";
import IconError from "../../../../assets/icons/circle-cross.svg";
import IconWaiting from "../../../../assets/icons/hourglass.svg";
import api from "../../../api";
import Icon from "../../../components/extensions/Icon";
import useFormatMessage from "../../../hooks/useFormatMessage";

interface VitecReportUploadStatusProps {
  reportStatus?: api.VitecReportStatus;
}

const VitecReportStatusIcon: Record<api.VitecReportStatus, IconSource> = {
  COMPLETED: IconConfirmed,
  WAITING: IconWaiting,
  FAILED: IconError
};

const VitecReportUploadStatus = (props: VitecReportUploadStatusProps) => {
  const { reportStatus } = props;
  const f = useFormatMessage();

  const getReportStatusLabel = () => {
    switch (reportStatus) {
      case "WAITING":
        return f("vitec.report.upload.status.WAITING");
      case "COMPLETED":
        return f("vitec.report.upload.status.COMPLETED");
      case "FAILED":
        return f("vitec.report.upload.status.FAILED");
      default:
        return "-";
    }
  };

  return (
    <HorizontalStack gap="1" blockAlign="center" wrap={false}>
      {reportStatus && <Icon source={VitecReportStatusIcon[reportStatus]} />}
      <Text as="span">{getReportStatusLabel()}</Text>
    </HorizontalStack>
  );
};

export default VitecReportUploadStatus;
