import api from "../../../api";
import { DEFAULT_COUNTRY } from "../../../constants/countries";
import { UNKNOWN_OWNER_SUPPORT_PAGE } from "../../../constants/external-urls";
import { getOwnerCountries } from "../../../helpers/owners.helpers";
import useCountries from "../../../hooks/useCountries";
import useFormatMessage from "../../../hooks/useFormatMessage";

const useGetOwnerWarning = () => {
  const f = useFormatMessage();
  const { isHighRiskCountry } = useCountries();

  const getOwnerWarning = (owner: api.Owner): { description: string; url?: string } | undefined => {
    const isForeignCompany = owner.company && owner.company.address?.country !== DEFAULT_COUNTRY;

    if (isForeignCompany) {
      return {
        description: f("company.owners.warning.foreign-company"),
        url: UNKNOWN_OWNER_SUPPORT_PAGE
      };
    }

    if (owner.is_unknown) {
      return {
        description: f("company.owners.warning.unidentified-owner"),
        url: UNKNOWN_OWNER_SUPPORT_PAGE
      };
    }
  };

  const hasWarnings = (owner: api.Owner) => {
    const countries = getOwnerCountries(owner);

    const highRiskCountriesSet = new Set([...countries.values()].flat().filter(isHighRiskCountry));
    const warning = getOwnerWarning(owner);

    return warning !== undefined || highRiskCountriesSet.size > 0;
  };

  return { getOwnerWarning, hasWarnings };
};

export default useGetOwnerWarning;
