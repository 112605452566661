import { Divider, HorizontalStack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import { CustomerDetails } from "../../types/utilities";
import KarLookupForm from "../KarLookupForm/KarLookupForm";
import KarLookupsHistory from "../KarLookupsHistory/KarLookupsHistory";

type KarSectionProps = {
  customer: CustomerDetails;
};

const KarLookupSection = (props: KarSectionProps) => {
  const { customer } = props;

  return (
    <HorizontalStack gap="3">
      <div /> {/* spacer */}
      <Flex>
        <VerticalStack gap="4">
          <KarLookupForm customer={customer} />
          <Divider />
          <KarLookupsHistory customerId={customer.id} customerType={customer.type!} />
        </VerticalStack>
      </Flex>
    </HorizontalStack>
  );
};

const Flex = styled.div`
  flex: 1;
`;

export default KarLookupSection;
