import React, { memo, useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";

import api from "../../api";
import { MAIN_OWNERS_COLUMN_SIZE } from "../../constants/tables";
import { getOwnerName } from "../../helpers/owners.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatOwnershipPercentage from "../../hooks/useFormatOwnershipPercentage";
import { isEmpty, uniqueId } from "../../utils/util";
import CondensedText from "../CondensedText/CondensedText";
import OwnerCountries from "../OwnersCard/OwnersTable/OwnerCountries";
import OwnerName from "../OwnersCard/OwnersTable/OwnerName/OwnerName";
import useGetOwnerWarning from "../OwnersCard/OwnersTable/useGetOwnerWarning";
import ReactTable from "../ReactTable/ReactTable";
import WarningWrapperTooltip from "../WarningWrapperTooltip/WarningWrapperTooltip";

const calcRelativePercentage = (ownership: api.BranchOwnership[]) =>
  Math.max(...ownership.map((branch) => branch.percent_owned));

type OwnershipNode = {
  owner: api.Owner;
  nodes: OwnershipNode[];
  relative_percentage: string;
};

// convert api.OwnershipTreeNode to a simpler node structure
const convertOwnershipTreeNode = (treeNode: api.OwnershipTreeNode, relative_percentage?: number) => {
  const owner = treeNode.owner;

  const node: OwnershipNode = {
    owner: {
      ...owner,
      id: owner.id + uniqueId("ownership-node")
    },
    nodes: treeNode.children.map((child) =>
      convertOwnershipTreeNode(child.node, calcRelativePercentage(child.ownership))
    ),
    relative_percentage: relative_percentage ? relative_percentage.toString() : ""
  };

  return node;
};

type OwnershipStructureTreeProps = {
  rootNode: api.OwnershipTreeNode;
  searchTerm?: string;
};

const OwnershipStructureTree = (props: OwnershipStructureTreeProps) => {
  const { rootNode, searchTerm } = props;

  const f = useFormatMessage();
  const formatPercent = useFormatOwnershipPercentage();

  const { getOwnerWarning, hasWarnings } = useGetOwnerWarning();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<OwnershipNode>();

    return [
      columnHelper.accessor((row) => getOwnerName(row.owner), {
        header: f("table.column.name"),
        id: "name",
        filterFn: "includesStringSensitive",
        size: MAIN_OWNERS_COLUMN_SIZE,

        cell: (props) => {
          const owner = props.row.original.owner;

          const showWarning = hasWarnings(owner);
          const warning = getOwnerWarning(owner);

          return (
            <WarningWrapperTooltip showWarning={showWarning} description={warning?.description}>
              <OwnerName owner={owner} searchTerm={searchTerm} />
            </WarningWrapperTooltip>
          );
        }
      }),
      columnHelper.accessor((row) => row.owner.percent_share, {
        header: f("table.column.shares"),
        id: "percent",
        cell: ({ getValue }) => (isEmpty(getValue()) ? "" : formatPercent(getValue()))
      }),
      columnHelper.accessor((row) => row.owner.id!, {
        header: f("table.column.country"),
        enableSorting: false,
        id: "countries",
        cell: (props) => <OwnerCountries owner={props.row.original.owner} />
      })
    ] as ColumnDef<OwnershipNode>[];
  }, [searchTerm]);

  const nodes = useMemo(() => [convertOwnershipTreeNode(rootNode)], [rootNode.owner.id]);

  return (
    <StyledTable
      columns={columns}
      data={nodes}
      expandable
      showTreeLines
      globalFilter={searchTerm}
      sortLocally
      getRowId={(node) => node.owner.id!}
      getSubRows={(node) => node.nodes}
      getRowLineLabel={(node) => <CondensedText>{formatPercent(node.relative_percentage, 0)}</CondensedText>}
    />
  );
};

const StyledTable = styled(ReactTable<OwnershipNode>)`
  & .Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell {
    border-top: none;
  }

  & .Polaris-DataTable__Cell {
    padding-top: var(--p-space-2);
    padding-bottom: var(--p-space-2);
  }
`;

export default memo(OwnershipStructureTree);
