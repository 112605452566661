import fontColorContrast from "font-color-contrast";

const colorsCache: Map<string, undefined | "#ffffff"> = new Map();

const useGetFontColorContrast = (color?: string) => {
  if (!color) {
    return undefined;
  }

  if (!colorsCache.has(color)) {
    const fontColor = fontColorContrast(color);
    // set #000000 (black) color to undefined in order to render this color with polaris text color (--p-text)
    // which is the default and it will not be override if returned value is undefined
    colorsCache.set(color, fontColor === "#000000" ? undefined : fontColor);
  }

  return colorsCache.get(color);
};

export default useGetFontColorContrast;
