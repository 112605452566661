import api from "../api";
import { DEFAULT_LANGUAGE } from "../constants/languages";

import useLanguage from "./useLanguage";

const useTranslatedText = () => {
  const language = useLanguage();

  const getTranslatedText = (text: api.TranslationText) => text[language] || text[DEFAULT_LANGUAGE];

  return getTranslatedText;
};

export default useTranslatedText;
