import styled from "styled-components";

import FullWidthRadioButton from "../FullWidthRadioButton/FullWidthRadioButton";

export type BUTTON_COLOR = "red" | "orange" | "green";

const FullWidthStatusRadioButton = styled(FullWidthRadioButton)<{ color?: BUTTON_COLOR; checked?: boolean }>`
  ${({ color }) =>
    color === "red" &&
    `
      --background-color-hovered: var(--p-color-bg-critical-subdued-hover);
      --background-color-active: var(--p-color-bg-critical-subdued-active);
      
      --background-color-selected: var(--p-color-bg-critical-subdued-hover);
      --background-color-selected-hovered: var(--p-color-bg-critical-subdued);
      --background-color-selected-active: var(--p-color-bg-critical-subdued-active);
      
      --border-color: var(--p-color-border-critical-subdued);
      --border-color-hovered: var(--p-color-border-critical);
      --border-color-active: var(--p-color-border-critical-hover);
      
      --border-color-selected: var(--p-color-bg-critical-strong);
      --border-color-selected-hovered: var(--p-color-border-critical);
      --border-color-selected-active: var(--p-color-border-critical-hover);
  `}

  ${({ color }) =>
    color === "orange" &&
    `
      --background-color-hovered: var(--p-color-bg-caution-subdued-hover);
      --background-color-active: var(--p-color-bg-caution-subdued-active);
      
      --background-color-selected: var(--p-color-bg-caution-subdued-hover);
      --background-color-selected-hovered: var(--p-color-bg-caution-subdued);
      --background-color-selected-active: var(--p-color-bg-caution-subdued-active);
      
      --border-color: var(--p-color-border-caution-subdued);
      --border-color-hovered: var(--p-color-border-caution);
      --border-color-active: var(--p-color-bg-caution-strong);
      
      --border-color-selected: var(--p-color-bg-caution-strong);
      --border-color-selected-hovered: var(--p-color-border-caution);
      --border-color-selected-active: var(--p-color-bg-caution-strong);
  `}
  
  ${({ color }) =>
    color === "green" &&
    `
      --background-color-hovered: var(--p-color-bg-success-subdued-hover);
      --background-color-active: var(--p-color-bg-success-subdued-active);
      
      --background-color-selected: var(--p-color-bg-success-subdued);
      --background-color-selected-hovered: var(--p-color-bg-success-subdued);
      --background-color-selected-active: var(--p-color-bg-success-subdued-active);
      
      --border-color: var(--p-color-border-success);
      --border-color-hovered: var(--p-color-border-success);
      --border-color-active: var(--p-color-bg-success-strong);
      
      --border-color-selected: var(--p-color-bg-success-strong);
      --border-color-selected-hovered: var(--p-color-border-success);
      --border-color-selected-active: var(--p-color-bg-success-strong);
  `}

  & .Polaris-Choice {
    border-width: var(--p-border-width-2);
  }
`;

export default FullWidthStatusRadioButton;
