import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Link } from "@shopify/polaris";
import { isFunction } from "@tanstack/react-table";
import styled from "styled-components";

import ChevronIcon from "../../../assets/icons/chevron-left.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";

type BackButtonProps = {
  url?: string;
  onClick?(): void;
  displayLabel?: string;
  showAsLink?: boolean;
  disabled?: boolean;
};

const BackButton = (props: BackButtonProps) => {
  const { url, onClick, displayLabel, showAsLink, disabled } = props;

  const history = useHistory();
  const f = useFormatMessage();

  const handleClick = () => {
    if (url) {
      history.push(url);
    } else if (isFunction(onClick)) {
      onClick();
    } else {
      history.goBack();
    }
  };

  if (showAsLink) {
    return (
      <Link url={url} onClick={!url ? handleClick : undefined}>
        <StyledBackIcon width="10px" height="12px" source={ChevronIcon} />
        {f("links.go-back", displayLabel ? { name: displayLabel } : undefined)}
      </Link>
    );
  } else {
    return (
      <Button onClick={handleClick} disabled={disabled}>
        {f("button.go.back")}
      </Button>
    );
  }
};

const StyledBackIcon = styled(Icon)`
  display: inline-block;
`;

export default BackButton;
