import React from "react";
import { useIntl } from "react-intl";
import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import CompanyIcon from "../../../assets/icons/company.svg";
import PersonIcon from "../../../assets/icons/person.svg";
import api from "../../api";
import Icon from "../../components/extensions/Icon";
import { CUSTOMER_TYPE_PARAMS } from "../../constants/customers-list-search-params";
import useFormatMessage from "../../hooks/useFormatMessage";

import DashboardContentCircle from "./DashboardContentCircle";
import DashboardNavigation from "./DashboardNavigation";
import DashboardNumbersItem from "./DashboardNumbersItem";
import DashboardStack from "./DashboardStack";
import DashboardVerticalStack from "./DashboardVerticalStack";
import DashboardWidget from "./DashboardWidget";

interface DashboardCustomerTypeTemplateWidgetProps {
  title: string;
  totalCount: number;
  allCustomersCount: number;
  values?: api.CustomerTypeMetric;
  loading?: boolean;
  onNavigation(customerType?: CUSTOMER_TYPE_PARAMS): void;
}

const DashboardCustomerTypeTemplateWidget = (props: DashboardCustomerTypeTemplateWidgetProps) => {
  const { title, totalCount, allCustomersCount, values, loading, onNavigation } = props;

  const f = useFormatMessage();
  const { formatNumber } = useIntl();

  const circleWidth = totalCount > 999 ? "55%" : "45%";

  return (
    <DashboardWidget title={title} loading={loading}>
      <DashboardVerticalStack>
        <DashboardNavigation onClick={() => onNavigation()} highlight>
          <StyledDashboardContentCircle
            title={
              <Text variant="heading4xl" as="p">
                {formatNumber(totalCount)}
              </Text>
            }
            subtitle={
              <Text as="span" fontWeight="bold">
                {formatNumber(!allCustomersCount ? 0 : totalCount / allCustomersCount, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  style: "percent"
                })}
              </Text>
            }
            width={circleWidth}
          />
        </DashboardNavigation>
        <StyledShrinkItem>
          <DashboardStack distribution={"fillEvenly"}>
            <DashboardNavigation onClick={() => onNavigation("companies")}>
              <DashboardNumbersItem
                value={values?.COMPANY || 0}
                percent={!totalCount ? 0 : (values?.COMPANY || 0) / totalCount}
                label={
                  <HorizontalStack gap="1" blockAlign="center" wrap={false}>
                    <Text as="span">{f("component.filter.customer.option.company.title")}</Text>
                    <Icon source={CompanyIcon} />
                  </HorizontalStack>
                }
              />
            </DashboardNavigation>
            <DashboardNavigation onClick={() => onNavigation("persons")}>
              <DashboardNumbersItem
                value={values?.PERSON || 0}
                percent={!totalCount ? 0 : (values?.PERSON || 0) / totalCount}
                label={
                  <HorizontalStack gap="1" blockAlign="center" wrap={false}>
                    <Text as="span">{f("component.filter.customer.option.person.title")}</Text>
                    <Icon source={PersonIcon} />
                  </HorizontalStack>
                }
              />
            </DashboardNavigation>
          </DashboardStack>
        </StyledShrinkItem>
      </DashboardVerticalStack>
    </DashboardWidget>
  );
};

const StyledShrinkItem = styled.div`
  & {
    flex: 0;
  }
`;

const StyledDashboardContentCircle = styled(DashboardContentCircle)`
  border: solid var(--p-border-width-4) var(--kdk-dashboard-risk-high-gradient-start);
  box-shadow: var(--kdk-dashboard-widget-shadow);
  background: var(--p-surface);

  & svg.kdk-divider {
    color: var(--kdk-dashboard-risk-high-gradient-start);
  }
`;

export default DashboardCustomerTypeTemplateWidget;
