import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";
import PlainSelect from "../PlainSelect/PlainSelect";

interface PaginationControlsProps {
  isLoading?: boolean;
  page?: number;
  limit?: number;
  count: number;
  itemsCount: number;
  setPage(page: number): void;
  setLimit(limit: number): void;
}

const PaginationControls = (props: PaginationControlsProps) => {
  const { isLoading, page = 0, limit = 0, count, itemsCount, setPage, setLimit } = props;

  const hasNext = (page + 1) * limit < count;
  const hasPrev = page > 0;

  const f = useFormatMessage();

  return (
    <HorizontalStack gap="4">
      <PlainSelect
        label={f("component.pagination.labels.select-limit", { limit })}
        labelInline
        options={["20", "50", "100", "200"]}
        onChange={(limitValue) => setLimit(parseInt(limitValue, 10))}
        value={limit + ""}
        disabled={isLoading}
      />
      <Text as="span">
        {f("component.pagination.labels.showing", {
          start: limit * page || 1,
          end: Math.min(limit * page + itemsCount, count),
          count: count
        })}
      </Text>
      {hasPrev && (
        <Button plain onClick={() => setPage(page - 1)} disabled={isLoading}>
          {f("default.previous")}
        </Button>
      )}
      {hasNext && (
        <Button plain onClick={() => setPage(page + 1)} disabled={isLoading}>
          {f("default.next")}
        </Button>
      )}
    </HorizontalStack>
  );
};

export default PaginationControls;
