import React from "react";
import { Button, Card, HorizontalStack, ProgressBar, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import OnboardingPersonForm from "../../components/OnboardingPersonForm/OnboardingPersonForm";
import { ONBOARDING_SCREENING_TYPES } from "../../constants/onboarding";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isActiveAssignment } from "../../helpers/assignments.helpers";
import { isVitecProject } from "../../helpers/integration.helpers";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useFormatMessage from "../../hooks/useFormatMessage";

type PersonOnboardingData = [
  api.PersonCustomerDetailResponse,
  api.PersonScreeningConfiguration,
  api.PersonSignatureConfiguration
];

const OnboardingPersonEditPage = ({ data }: AsyncPageChild<PersonOnboardingData>) => {
  const defaultCountry = useDefaultCountry();
  const [personData, screeningData, disclosureData] = data;

  const country = personData?.country_of_citizenship || defaultCountry;

  // set missing country in person data fields
  const person = {
    ...personData,
    address: personData.address || { country },
    birth_date: personData.birth_date || "",
    country_of_origin: personData.country_of_origin || country,
    country_of_citizenship: personData.country_of_citizenship || country,
    country_of_residence: personData.country_of_residence || country
  };

  // do not allow to cancel onboarding if the customer belongs to a vitec project
  const disableCancelOnboarding = personData.projects?.find(isVitecProject) !== undefined;

  const responsibleUsersIds = personData.responsible_users?.map((user) => user.id) || [];

  const screeningType = ONBOARDING_SCREENING_TYPES.includes(screeningData.screening_type)
    ? screeningData.screening_type
    : "MONITORING";

  const assignments = personData.assignments?.filter(isActiveAssignment);

  return (
    <OnboardingPersonForm
      personId={person.id}
      personValues={person}
      screeningType={screeningType}
      isSignatureRequired={!!disclosureData.signature_required}
      signature={{ ...disclosureData, email: disclosureData.email || person.email }}
      disableCancelOnboarding={disableCancelOnboarding}
      responsibleUsersIds={responsibleUsersIds}
      assignments={assignments}
    />
  );
};

const PersonSkeleton = () => {
  const f = useFormatMessage();

  return (
    <Card>
      <Card.Section>
        <ProgressBar progress={85} size="small" />
      </Card.Section>

      <Card.Section>
        <VerticalStack gap="4">
          <HorizontalStack gap="4" align="end">
            <Button disabled>{f("default.cancel")}</Button>
            <Button disabled primary>
              {f("default.next")}
            </Button>
          </HorizontalStack>
        </VerticalStack>
      </Card.Section>
    </Card>
  );
};

export default withAsyncPage<PersonOnboardingData>(OnboardingPersonEditPage, {
  queryKey: QUERIES_KEYS.PERSON_ONBOARDING_DETAILS,
  apiFunction: (id, options) =>
    Promise.all([
      api.getPersonCustomerDetails(id, options),
      api.getPersonScreeningConfiguration(id, options),
      api.getPersonSignatureConfiguration(id, options)
    ]),
  paramNames: ["id"],
  skeleton: <PersonSkeleton />
});
