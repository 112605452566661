import { PropsWithChildren } from "react";
import styled from "styled-components";

import DashedBorder from "../../../assets/images/dashed-border.svg";
import Badge, { BadgeProps } from "../extensions/Badge";

const StatusBadge = (props: PropsWithChildren<BadgeProps>) => {
  return <StyledBadge {...props} />;
};

const StyledBadge = styled(Badge)`
  & .Polaris-Badge {
    white-space: nowrap;
    border-style: solid;
    border-width: var(--p-border-width-2);
    --pc-badge-vertical-padding: var(--p-space-1);
  }

  & .Polaris-Badge--statusInfo {
    // hide border in order for SVG dashed border to render properly
    border: none;

    // add missing border with to internal padding in order to make the badge the same size as other statuses
    --pc-badge-vertical-padding: calc(var(--p-space-1) + var(--p-border-width-2));
    --pc-badge-horizontal-padding: calc(var(--p-space-2) + var(--p-border-width-2));

    // set background color to white
    background-color: var(--p-color-bg);

    // set background image as an SVG that will render the border (as in Figma design)
    background-image: url(${DashedBorder});
  }

  & .Polaris-Badge--statusSuccess {
    border-color: var(--p-color-border-success);
    background-color: var(--p-color-bg-success-subdued);
  }

  & .Polaris-Badge--statusWarning {
    border-color: var(--p-color-border-caution);
    background-color: var(--p-color-bg-caution-subdued);
  }

  & .Polaris-Badge--statusCritical {
    border-color: var(--p-color-border-critical);
    background-color: var(--p-color-bg-critical-subdued);
  }
`;

export default StatusBadge;
