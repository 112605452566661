import React, { PropsWithChildren } from "react";
import { ComplexAction, Modal, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import { isNil } from "../../utils/util";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

export type ConfirmProps = PropsWithChildren<{
  title: string;
  description?: string;
  descriptionAlign?: "left" | "center" | "right";
  error?: string | unknown;
  isDestructive?: boolean;
  actionTitle: string;
  cancelTitle?: string;
  isActionInProgress?: boolean;
  isActionDisabled?: boolean;
  isOpen?: boolean;
  onYes(): void;
  onNo(): void;
}>;

const Confirm = (props: ConfirmProps) => {
  const {
    title,
    actionTitle,
    cancelTitle,
    description,
    descriptionAlign,
    error,
    onYes,
    onNo,
    isActionInProgress,
    isActionDisabled,
    isDestructive = true,
    isOpen = true,
    children
  } = props;

  const f = useFormatMessage();

  const primaryAction: ComplexAction = {
    content: actionTitle,
    onAction: onYes,
    destructive: isDestructive,
    loading: isActionInProgress,
    disabled: isActionDisabled
  };

  const cancelAction: ComplexAction = {
    content: cancelTitle || f("default.cancel"),
    onAction: onNo,
    disabled: isActionInProgress
  };

  return (
    <Modal open={isOpen} onClose={onNo} title={title} primaryAction={primaryAction} secondaryActions={[cancelAction]}>
      <Modal.Section>
        <VerticalStack align="center" gap="4">
          {description && <StyledContent align={descriptionAlign}>{description}</StyledContent>}
          {children}
          {!isNil(error) && <ErrorPanel message={error} actionTitle={actionTitle} />}
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};

const StyledContent = styled.p<{ align?: "left" | "center" | "right" }>`
  white-space: pre-wrap;
  text-align: ${({ align }) => align || "center"};
`;

export default Confirm;
