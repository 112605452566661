import React, { useState } from "react";
import { Button, HorizontalStack, Text, TextField, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import FullWidthCheckbox from "../FullWidthCheckbox/FullWidthCheckbox";
import FullWidthRadioButton from "../FullWidthRadioButton/FullWidthRadioButton";

const DEFAULT_REVIEW: api.CreateMatchReviewRequest = {
  is_match: false,
  is_pep: false,
  is_sanctioned: false,
  negative_news: false,
  other: false,
  description: ""
};

type CreateMatchReviewRequestValue = api.CreateMatchReviewRequest[keyof api.CreateMatchReviewRequest];

type MatchReviewFormProps = {
  customerId: string;
  customerType: api.CustomerType;
  caseId: string;
  readonly: boolean;
  onClose?(): void;
  onAfterMatchReviewed(review: api.MatchReview): void;
};

const MatchReviewForm = (props: MatchReviewFormProps) => {
  const { customerId, customerType, caseId, readonly, onClose, onAfterMatchReviewed } = props;

  const [review, setReview] = useState({ ...DEFAULT_REVIEW });
  const [isTouched, setIsTouched] = useState(false);

  const f = useFormatMessage();

  const reset = () => {
    saveReviewMutation.reset();
    setReview({ ...DEFAULT_REVIEW });
    setIsTouched(false);
    onClose && onClose();
  };

  const saveReviewMutation = useMutation(() => api.createMatchReview(customerId, caseId, review), {
    onSuccess: (confirmedReview) => {
      setReview({ ...review, description: "" });
      onAfterMatchReviewed(confirmedReview);
      onClose && onClose();
    }
  });

  const handleReviewChange = (key: keyof api.CreateMatchReviewRequest, value: CreateMatchReviewRequestValue) => {
    const updatedReview = { ...review, [key]: value };

    if (key === "is_match") {
      setIsTouched(true);

      if (!updatedReview.is_match) {
        updatedReview.is_pep = false;
        updatedReview.is_sanctioned = false;
        updatedReview.negative_news = false;
        updatedReview.other = false;
      }
    }

    setReview(updatedReview);
  };

  const disabled = readonly || saveReviewMutation.isLoading;

  return (
    <VerticalStack gap="6">
      <HorizontalStack gap="4" align="space-evenly">
        <StyledRadioButton
          label={
            <VerticalStack>
              <Text as="span" fontWeight="semibold">
                {f("default.confirm")}
              </Text>
              <Text as="span">
                {customerType === "person"
                  ? f("matches.button.confirm.match.description-person")
                  : f("matches.button.confirm.match.description-company")}
              </Text>
            </VerticalStack>
          }
          checked={isTouched && review.is_match}
          id="true"
          name="approve"
          onChange={(_, newValue) => handleReviewChange("is_match", newValue == "true")}
          disabled={disabled}
        />
        <StyledRadioButton
          label={
            <VerticalStack>
              <Text as="span" fontWeight="semibold">
                {f("default.reject")}
              </Text>
              <Text as="span">
                {customerType === "person"
                  ? f("matches.button.decline.match.description-person")
                  : f("matches.button.decline.match.description-company")}
              </Text>
            </VerticalStack>
          }
          id="false"
          name="approve"
          checked={isTouched && !review.is_match}
          onChange={(_, newValue) => {
            document.getElementById("text")?.focus();
            handleReviewChange("is_match", newValue == "true");
          }}
          disabled={disabled}
        />
      </HorizontalStack>

      <VerticalStack gap="2">
        <Text as="span" fontWeight="semibold">
          {f("matches.labels.details")}
        </Text>
        <StyledFormLayout>
          <FullWidthCheckbox
            disabled={!review.is_match || disabled || customerType === "company"}
            label={
              <Text as="span" fontWeight="semibold">
                {f("matches.labels.pep")}
              </Text>
            }
            helpText={<p>{f("matches.labels.pep.helper-text")}</p>}
            checked={review.is_pep}
            onChange={(checked) => handleReviewChange("is_pep", checked)}
          />

          <FullWidthCheckbox
            disabled={!review.is_match || disabled}
            label={
              <Text as="span" fontWeight="semibold">
                {f("matches.labels.sanction")}
              </Text>
            }
            helpText={
              <p>
                {customerType === "person"
                  ? f("matches.labels.sanction.helper-text-person")
                  : f("matches.labels.sanction.helper-text-company")}
              </p>
            }
            checked={review.is_sanctioned}
            onChange={(checked) => handleReviewChange("is_sanctioned", checked)}
          />

          <FullWidthCheckbox
            disabled={!review.is_match || disabled}
            label={
              <Text as="span" fontWeight="semibold">
                {f("matches.labels.negative-news")}
              </Text>
            }
            checked={review.negative_news}
            onChange={(checked) => handleReviewChange("negative_news", checked)}
            helpText={
              <p>
                {customerType === "person"
                  ? f("matches.labels.negative-news.helper-text-person")
                  : f("matches.labels.negative-news.helper-text-company")}
              </p>
            }
          />

          <FullWidthCheckbox
            disabled={!review.is_match || disabled}
            label={
              <Text as="span" fontWeight="semibold">
                {f("matches.labels.other")}
              </Text>
            }
            helpText={
              <p>
                {customerType === "person"
                  ? f("matches.labels.other.helper-text-person")
                  : f("matches.labels.other.helper-text-company")}
              </p>
            }
            checked={review.other}
            onChange={(checked) => handleReviewChange("other", checked)}
          />
        </StyledFormLayout>
      </VerticalStack>

      <TextField
        value={review.description || ""}
        onChange={(newValue) => handleReviewChange("description", newValue)}
        multiline={4}
        id="text"
        label={
          <Text as="span" fontWeight="semibold">
            {f("matches.labels.review.description")}
          </Text>
        }
        autoComplete="off"
        disabled={disabled}
      />

      {saveReviewMutation.isError && <ErrorPanel message={saveReviewMutation.error} />}

      <HorizontalStack gap="4" align="end">
        <Button onClick={() => reset()} disabled={disabled}>
          {f("default.cancel")}
        </Button>
        <Button
          disabled={saveReviewMutation.isLoading || !isTouched || review.description === "" || readonly}
          loading={saveReviewMutation.isLoading}
          primary
          onClick={() => saveReviewMutation.mutate()}
        >
          {f("default.save")}
        </Button>
      </HorizontalStack>
    </VerticalStack>
  );
};

const StyledFormLayout = styled.div`
  display: grid;
  grid-gap: var(--p-space-4);
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 15ch), 1fr));
`;

const StyledRadioButton = styled(FullWidthRadioButton)`
  flex: 1;

  // do not make selected radio button font bold in order to make the description stay with regular font width
  & .Polaris-Choice__Label {
    font-weight: revert !important;
  }
`;

export default MatchReviewForm;
