import React from "react";
import { HorizontalStack, RadioButton } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

type CustomerTypeFormProps = {
  customerType?: api.QuestionnaireCustomerType | api.ReviewChecklistCustomerType;
  readonly?: boolean;
  onChange(customerType: api.QuestionnaireCustomerType | api.ReviewChecklistCustomerType): void;
};

const CustomerTypeForm = (props: CustomerTypeFormProps) => {
  const { customerType, readonly, onChange } = props;

  const f = useFormatMessage();
  return (
    <HorizontalStack gap="4">
      <RadioButton
        label={f("common.labels.customer-type.company")}
        name="companyType"
        disabled={readonly}
        checked={customerType === "company"}
        onChange={() => onChange("company")}
      />
      <RadioButton
        label={f("common.labels.customer-type.person")}
        name="companyType"
        disabled={readonly}
        checked={customerType === "person"}
        onChange={() => onChange("person")}
      />
      <RadioButton
        label={f("common.labels.both-customer-types")}
        name="companyType"
        disabled={readonly}
        checked={customerType === "both"}
        onChange={() => onChange("both")}
      />
    </HorizontalStack>
  );
};

export default CustomerTypeForm;
