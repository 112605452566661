import { HorizontalStack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";
import usePageNavigation from "../../hooks/usePageNavigation";
import usePendingTask from "../../hooks/usePendingTask";
import { CustomerDetails } from "../../types/utilities";
import Button from "../extensions/Button";
import KDKBanner from "../KDKBanner/KDKBanner";
import SeparatorStackWrapper from "../SeparatorStackWrapper/SeparatorStackWrapper";

interface PendingTasksBannerProps {
  customer: CustomerDetails;
}

const PendingTasksBanner = (props: PendingTasksBannerProps) => {
  const { customer } = props;

  const { navigateInPage } = usePageNavigation();
  const getPendingTaskStatuses = usePendingTask(customer);

  const pendingTasks = customer.pending_tasks;

  // filter "Ready for assessment" (REVIEW_CUSTOMER) if there is more than 1 task
  // since reviewing is not possible while other tasks are not completed yet
  const pendingDisplayTasks =
    !pendingTasks || pendingTasks.length < 2
      ? pendingTasks
      : pendingTasks.filter((pendingTask) => pendingTask.type !== "REVIEW_CUSTOMER");

  const tasks = pendingDisplayTasks?.map(getPendingTaskStatuses).flat() || [];

  const showAsList = tasks.length > 3;

  return (
    <KDKBanner hideIcon hideBorder status={"info"}>
      {showAsList && (
        <VerticalStack inlineAlign="start">
          {tasks.map((task, index) => (
            <Button
              plain
              monochrome={!task.anchor && !task.url}
              removeUnderline={!task.anchor && !task.url}
              icon={task.icon}
              url={task.anchor ? undefined : task.url}
              onClick={task.anchor ? () => navigateInPage(task.anchor!) : undefined}
              key={index}
            >
              {task.title}
            </Button>
          ))}
        </VerticalStack>
      )}
      {!showAsList && (
        <StyledWrapper>
          <HorizontalStack gap="4">
            {tasks.map((task, index) => (
              <Button
                plain
                monochrome={!task.anchor && !task.url}
                removeUnderline={!task.anchor && !task.url}
                icon={task.icon}
                url={task.anchor ? undefined : task.url}
                onClick={task.anchor ? () => navigateInPage(task.anchor!) : undefined}
                key={index}
              >
                {task.title}
              </Button>
            ))}
          </HorizontalStack>
        </StyledWrapper>
      )}
    </KDKBanner>
  );
};

const StyledWrapper = styled(SeparatorStackWrapper)`
  // For small viewports - always render as a list
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    & .Polaris-Stack {
      flex-direction: column;

      & .Polaris-Stack__Item + .Polaris-Stack__Item {
        border-left: none;
        padding-left: 0;
      }
    }

    & .Polaris-Button {
      text-align: left;
    }
  }
`;

export default PendingTasksBanner;
