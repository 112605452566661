import React, { PropsWithChildren, ReactNode } from "react";
import { BadgeProps, Collapsible, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import Button from "../extensions/Button";

type CollapsibleSectionProps = {
  title: ReactNode;
  expanded?: boolean;
  showPrefix?: boolean;
  withoutHeader?: boolean;
  status?: BadgeProps["status"];
  standalone?: boolean;
  className?: string;
};

const CollapsibleSection = (props: PropsWithChildren<CollapsibleSectionProps>) => {
  const { title, expanded, showPrefix, withoutHeader, status, standalone, className, children } = props;

  const f = useFormatMessage();

  const [isExpanded, toggleExpanded] = useOpenClose(expanded);

  const ButtonTitle = () => (
    <HorizontalStack gap="1">
      {showPrefix && <Text as="span">{isExpanded ? f("default.hide") : f("default.show")}</Text>}
      {title}
    </HorizontalStack>
  );

  return (
    <VerticalStack gap="4">
      <StyledButton
        onClick={toggleExpanded}
        removeUnderline
        monochrome
        fullWidth
        plain
        textAlign={"left"}
        disclosure={isExpanded ? "up" : "down"}
        withPadding={!withoutHeader}
        isExpanded={isExpanded}
        status={status}
        standalone={standalone}
        className={className}
      >
        {withoutHeader && <ButtonTitle />}
        {!withoutHeader && (
          <Text variant="bodyMd" as="span" fontWeight={"semibold"}>
            <ButtonTitle />
          </Text>
        )}
      </StyledButton>
      <Collapsible open={isExpanded} id="">
        {children}
      </Collapsible>
    </VerticalStack>
  );
};

const StyledButton = styled(Button)<{
  withPadding: boolean;
  isExpanded: boolean;
  status?: CollapsibleSectionProps["status"];
  standalone?: boolean; // sets top border radius to var(--p-border-radius-2) otherwise it is 0
}>`
  display: inline-block;
  padding: var(--p-space-2) 1px;

  & .Polaris-Button {
    border-top-left-radius: ${({ standalone }) => (standalone ? "var(--p-border-radius-2)" : 0)};
    border-top-right-radius: ${({ standalone }) => (standalone ? "var(--p-border-radius-2)" : 0)};
    padding: ${({ withPadding }) => (withPadding ? "var(--p-space-3)" : "0")};

    border-bottom-left-radius: ${({ isExpanded }) => (isExpanded ? 0 : "var(--p-border-radius-2)")};
    border-bottom-right-radius: ${({ isExpanded }) => (isExpanded ? 0 : "var(--p-border-radius-2)")};
  }

  & .Polaris-Button.Polaris-Button--plain {
    border: var(--p-border-width-1) solid transparent;
  }

  & .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content {
    width: 100%;
    padding: ${({ withPadding }) => (withPadding ? "var(--p-space-2)" : "0")};
    background: ${({ status }) => (status ? `var(--p-color-bg-${status}-subdued)` : undefined)};
  }

  & .Polaris-Button.Polaris-Button--plain:active .Polaris-Button__Content {
    margin: 0;
  }

  &:hover .Polaris-Button {
    background: ${({ status }) => (status ? undefined : "var(--kdk-plain-button-hovered)")};
  }

  &:hover .Polaris-Button .Polaris-Button__Content {
    background: ${({ status }) => (status ? `var(--p-color-bg-${status}-subdued-hover)` : undefined)};
  }
`;

export default CollapsibleSection;
