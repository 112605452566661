import React from "react";

import api from "../../api";
import { convertPreviewReviewToNote } from "../../helpers/notes.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useProjectCreateNoteMutation from "../../hooks/useProjectCreateNoteMutation";
import NotesSection from "../NotesSection/NotesSection";

type ProjectNotesSectionProps = {
  project: api.Project;
  readonly?: boolean;
};

const ProjectNotesSection = (props: ProjectNotesSectionProps) => {
  const { project, readonly } = props;

  const f = useFormatMessage();
  const createNoteMutation = useProjectCreateNoteMutation(project.id);

  return (
    <NotesSection
      notes={project.reviews.map(convertPreviewReviewToNote)}
      readonly={readonly}
      placeholder={f("project.notes.placeholder")}
      createNoteMutation={createNoteMutation}
    />
  );
};

export default ProjectNotesSection;
