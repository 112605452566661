import React from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import { CustomerDetails } from "../../types/utilities";
import UsersSelect from "../UsersSelect/UsersSelect";

interface CustomerResponsibleUsersSelectProps {
  customer: CustomerDetails;
  readonly?: boolean;
}

const CustomerResponsibleUsersSelect = (props: CustomerResponsibleUsersSelectProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();
  const updateQueryData = useCustomerDetailsQueryData(customer);

  const updateResponsibleUsers = (responsibleUserIds: string[]) =>
    api
      .updateCustomerDetails(customer.id, { responsible_users: responsibleUserIds })
      .then((response) => response.responsible_users || []);

  const updateQueryCache = (responsibleUsers: api.User[]) =>
    updateQueryData((queryData) => (queryData.responsible_users = responsibleUsers));

  return (
    <UsersSelect
      selectedUsers={customer.responsible_users || []}
      emptySelectionLabel={f("responsible.empty.users")}
      updateErrorMessage={f("responsible.error")}
      readonly={readonly}
      strong
      onUserSelection={updateResponsibleUsers}
      onAfterUserSelection={updateQueryCache}
    />
  );
};

export default CustomerResponsibleUsersSelect;
