import { PropsWithChildren } from "react";
import { generatePath } from "react-router-dom";

import { ROUTES } from "../../constants/routes";
import { CustomerDetails } from "../../types/utilities";
import AddButton from "../AddButton/AddButton";
import { ButtonProps } from "../extensions/Button";

type NewCustomerReviewButtonProps = Omit<ButtonProps, "url"> & {
  customer: CustomerDetails;
};

const NewCustomerReviewButton = (props: PropsWithChildren<NewCustomerReviewButtonProps>) => {
  const { customer, ...buttonProps } = props;

  const url = generatePath(ROUTES.CUSTOMER_REVIEW_NEW, { customerId: customer.id });

  return <AddButton url={url} {...buttonProps} />;
};

export default NewCustomerReviewButton;
