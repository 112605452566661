import api, { TranslationText } from "../api";
import { toKebabCase } from "../utils/stringUtils";

import { EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS } from "./checklists.helpers";

const CHECKLIST_TEMPLATE_ID_PREFIX = "template";

export const createChecklistTemplate = (
  name: string,
  description: string,
  customerType: api.ReviewChecklistCustomerType,
  questions: TranslationText[] = []
): api.ReviewChecklist => {
  return {
    id: `${CHECKLIST_TEMPLATE_ID_PREFIX}-${toKebabCase(name)}`,
    name,
    description,
    version: -1,
    customer_type: customerType,
    created_dt: "",
    is_archived: false,
    is_required: false,
    questions: questions.map((text) => ({ id: toKebabCase(text.en), text })),
    display_settings: { ...EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS }
  };
};
