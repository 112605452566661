import React from "react";

import OnboardingPersonForm from "../../components/OnboardingPersonForm/OnboardingPersonForm";
import { createNewPerson } from "../../helpers/person.helpers";
import useAuth from "../../hooks/useAuth";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useGetOnboardingConfiguration from "../../hooks/useGetOnboardingConfiguration";

const OnboardingPersonNewPage = () => {
  const defaultCountry = useDefaultCountry();
  const { user } = useAuth();
  const defaultSignatureConfiguration = useGetOnboardingConfiguration("person");

  const responsibleUsersIds = user ? [user.id] : [];

  if (!defaultSignatureConfiguration) {
    return null;
  }

  return (
    <OnboardingPersonForm
      personValues={createNewPerson(defaultCountry)}
      responsibleUsersIds={responsibleUsersIds}
      screeningType={defaultSignatureConfiguration.screening_type}
      isSignatureRequired
      signature={defaultSignatureConfiguration.signature_settings}
    />
  );
};

export default OnboardingPersonNewPage;
