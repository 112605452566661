import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import useConvertToDeletedEntity from "../../hooks/useConvertToDeletedEntity";
import useOpenClose from "../../hooks/useOpenClose";
import useQueryData from "../../hooks/useQueryData";
import { getCustomerQueryKey } from "../../types/utilities";
import { updateItem } from "../../utils/collectionUtils";

const UPDATE_TYPE_SORT_VALUE: Record<api.OwnershipUpdateType, number> = {
  ADDED: 1,
  UPDATED: 2,
  DELETED: 3
};

// sort ownership updates by their type: ADDED first, then UPDATED and lastly DELETED
const sortOwnershipUpdatesByUpdateType = (updates: api.OwnershipUpdate[]) =>
  updates
    .filter(Boolean)
    .sort(
      (updateA, updateB) => UPDATE_TYPE_SORT_VALUE[updateA.update_type] - UPDATE_TYPE_SORT_VALUE[updateB.update_type]
    );

// ownership update of type ADDED do not have an owner_id - so use the update.id as the owner.id
// until the ownership update is confirmed or rejected
const getOwnershipUpdateOwnerId = (update: api.OwnershipUpdate) =>
  update.update_type === "ADDED" ? update.id : update.owner_id!;

/*
 * Merges an existing owner (if exists) with an ownership update to display values from the update
 * This is used to show updates info (for example, Added owner) in the OwnersTable.
 * If the owner exists - return the owner with info from the update.
 * Otherwise, create an owner from the update info
 */
const mergeOwnershipUpdateWithOwner = (update: api.OwnershipUpdate, existingOwner?: api.Owner): api.Owner =>
  Object.assign({}, existingOwner, {
    id: getOwnershipUpdateOwnerId(update),
    person: Object.assign({}, existingOwner?.person, {
      id: existingOwner?.person?.id,
      first_name: update.first_name,
      last_name: update.last_name,
      birth_year: update.birth_year,
      country_of_residence: update.country_code
    }),
    percent_share:
      update.update_type === "DELETED"
        ? existingOwner?.percent_share
        : update.field_changes.find((change) => change.field_name === "percent_share")?.new,
    is_ubo: update.is_ubo
  });

const useCompanyOwners = (customer: api.CompanyCustomerDetailResponse) => {
  const { beneficial_owners } = customer;

  const queryClient = useQueryClient();
  const convertToArchivedEntity = useConvertToDeletedEntity<api.Owner>();

  const updates = sortOwnershipUpdatesByUpdateType(customer.updates.ownership || []);

  const getOwnershipUpdate = useCallback(
    (owner: api.Owner) => updates.find((update) => getOwnershipUpdateOwnerId(update) === owner.id),
    [updates]
  );

  const { updateQueryData } = useQueryData<api.CompanyCustomerDetailResponse>(getCustomerQueryKey(customer));
  const [showArchivedOwners, toggleArchivedOwners] = useOpenClose(updates.length > 0);

  // sort owners by ownership updates first, then owners without updates
  const owners = [
    ...updates.map((update) =>
      mergeOwnershipUpdateWithOwner(
        update,
        beneficial_owners.find((owner) => owner.id === update.owner_id)
      )
    ),
    ...beneficial_owners.filter((owner) => !getOwnershipUpdate(owner))
  ];

  const ownersToDisplay = showArchivedOwners ? owners : owners.filter((owner) => !owner.deleted);

  const hasArchivedOwners = owners.find((owner) => owner.deleted);

  const handleOwnerCreate = (newOwner: api.Owner) => {
    updateQueryData(
      (companyDetails) => (companyDetails.beneficial_owners = [newOwner, ...companyDetails.beneficial_owners])
    );
  };

  const handleOwnerUpdate = (updatedOwner: api.Owner) => {
    updateQueryData(
      (companyDetails) =>
        (companyDetails.beneficial_owners = updateItem(companyDetails.beneficial_owners, updatedOwner))
    );
    // invalidate the entire customer query in case the owner pep/sanctioned properties were changed
    // which could also change the roles if the owner also has a role in the company
    queryClient.invalidateQueries(getCustomerQueryKey(customer));
  };

  const handleOwnerArchive = (ownerId: string) => {
    updateQueryData(
      (companyDetails) =>
        (companyDetails.beneficial_owners = companyDetails.beneficial_owners.map((currentOwner) =>
          currentOwner.id === ownerId
            ? {
                ...convertToArchivedEntity(currentOwner),
                deleted: true
              }
            : currentOwner
        ))
    );
  };

  const handleOwnershipUpdateReject = (update: api.OwnershipUpdate) => {
    updateQueryData((companyDetails) => {
      // remove update from updates list
      companyDetails.updates.ownership = companyDetails.updates.ownership?.filter(
        (prevUpdate) => prevUpdate.id !== update.id
      );

      if (update.update_type === "ADDED") {
        // for ADDED owners - remove them from the owners table using their full name because their ID are null
        companyDetails.beneficial_owners = companyDetails.beneficial_owners.filter(
          (owner) => getOwnershipUpdateOwnerId(update) !== owner.id
        );
      } else {
        // for UPDATED or DELETED owners - revert to the original owner from beneficial_owners
        companyDetails.beneficial_owners = companyDetails.beneficial_owners.map((owner) =>
          update.owner_id !== owner.id ? owner : beneficial_owners.find((owner) => owner.id === update.owner_id)!
        );
      }
    });
  };

  const handleOwnershipUpdateConfirm = (update: api.OwnershipUpdate, updatedOwner: api.Owner) => {
    updateQueryData((companyDetails) => {
      // remove update from updates list
      companyDetails.updates.ownership = companyDetails.updates.ownership?.filter(
        (prevUpdate) => prevUpdate.id !== update.id
      );
      // update owner with updatedOwner
      companyDetails.beneficial_owners = updateItem(companyDetails.beneficial_owners, updatedOwner);
    });
  };

  return {
    owners: ownersToDisplay,
    hasArchivedOwners,
    showArchivedOwners,
    toggleArchivedOwners,
    getOwnershipUpdate,
    handleOwnerCreate,
    handleOwnerUpdate,
    handleOwnerArchive,
    handleOwnershipUpdateReject,
    handleOwnershipUpdateConfirm
  };
};

export default useCompanyOwners;
