import React, { useContext } from "react";
import { ActionListItemDescriptor, IconableAction, TopBar } from "@shopify/polaris";

import LogoutIcon from "../../../assets/icons/arrow-right-offset-circle.svg";
import EmailIcon from "../../../assets/icons/email.svg";
import GlobeIcon from "../../../assets/icons/globe.svg";
import ImportCompanyIcon from "../../../assets/icons/import-company.svg";
import { SUPPORT_EMAIL, SUPPORT_PORTAL } from "../../constants/external-urls";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { ROUTES } from "../../constants/routes";
import { getFullName } from "../../helpers/display.helpers";
import useAuth from "../../hooks/useAuth";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import { LanguageContext } from "../../providers/LanguageProvider";
import Country from "../Country/Country";
import Icon from "../extensions/Icon";

const UserMenu = () => {
  const features = useFeatures();
  const { isPermittedTo } = usePermissions();
  const f = useFormatMessage();

  const [language, setLanguage] = useContext(LanguageContext);

  const { logout, user } = useAuth();
  const [isUserMenuOpen, toggleUserMenu] = useOpenClose();

  const openSupportEmail = () => (window.location.href = `mailto:${SUPPORT_EMAIL}`);

  const actions: { title?: string; items: IconableAction[] }[] = [];

  const userMenuItems: ActionListItemDescriptor[] = [];

  userMenuItems.push(
    {
      content: f("menu.support-portal"),
      prefix: <Icon source={GlobeIcon} />,
      url: SUPPORT_PORTAL,
      external: true
    },
    {
      content: f("menu.support-email"),
      prefix: <Icon source={EmailIcon} />,
      onAction: openSupportEmail
    }
  );

  if (features.IMPORT_WIZARD && isPermittedTo(PERMISSIONS_TYPES.ADMIN_USER)) {
    userMenuItems.push({
      content: f("menu.import.companies"),
      image: ImportCompanyIcon,
      url: ROUTES.IMPORT_CUSTOMERS
    });
  }

  userMenuItems.push({
    content: language === "en" ? f("menu.language.english") : f("menu.language.norwegian"),
    prefix: <Country country={language === "en" ? "US" : "NO"} hideLabel />,
    onAction: () => setLanguage(language === "en" ? "nb" : "en")
  });

  userMenuItems.push({
    content: f("menu.logout"),
    prefix: <Icon source={LogoutIcon} />,
    onAction: () => logout()
  });

  actions.push({ items: userMenuItems });

  return (
    <TopBar.UserMenu
      actions={actions}
      name={getFullName(user)}
      open={isUserMenuOpen}
      onToggle={toggleUserMenu}
      initials=""
    />
  );
};

export default UserMenu;
