import React from "react";
import { HorizontalStack, RadioButton, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import DateInput from "../DateInput/DateInput";

const MIN_DATE = new Date("2020-01-01");
const MAX_DATE = new Date();

const INTERVAL_OPTIONS: api.DashboardDateInterval[] = [
  "DAILY",
  "WEEKLY",
  "MONTHLY",
  "QUARTERLY",
  "ANNUALLY",
  "CUSTOM_DATE_RANGE"
];

type DashboardDateIntervalFilterProps = {
  isLoading?: boolean;
  dateInterval?: api.DashboardDateInterval;
  startDate?: string;
  endDate?: string;
  setDateInterval(dateInterval?: api.DashboardDateInterval): void;
  setStartDate(startDate?: string): void;
  setEndDate(endDate?: string): void;
};

const DashboardDateIntervalFilter = (props: DashboardDateIntervalFilterProps) => {
  const { isLoading, dateInterval, startDate, endDate, setDateInterval, setStartDate, setEndDate } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="2">
      <RadioButton
        name="date-interval"
        label={f("component.filters.all.title")}
        disabled={isLoading}
        checked={isEmptyString(dateInterval)}
        onChange={() => setDateInterval(undefined)}
      />
      {INTERVAL_OPTIONS.map((rangeOption) => (
        <RadioButton
          key={rangeOption}
          name="date-interval"
          label={f(`dashboard.filters.date.interval.${rangeOption}`)}
          disabled={isLoading}
          checked={dateInterval === rangeOption}
          onChange={() => setDateInterval(rangeOption)}
        />
      ))}
      <HorizontalStack gap="4">
        <DateInput
          label={f("dashboard.filters.date.from", { date: "" })}
          value={startDate || ""}
          onChange={setStartDate}
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
          disabled={isLoading || dateInterval !== "CUSTOM_DATE_RANGE"}
          autoComplete="off"
        />
        <DateInput
          label={f("dashboard.filters.date.until", { date: "" })}
          value={endDate || ""}
          onChange={setEndDate}
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
          disabled={isLoading || dateInterval !== "CUSTOM_DATE_RANGE"}
          autoComplete="off"
        />
      </HorizontalStack>
    </VerticalStack>
  );
};

export default DashboardDateIntervalFilter;
