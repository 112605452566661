import React, { ReactNode } from "react";
import { Popover, PopoverProps } from "@shopify/polaris";
import styled from "styled-components";

import useOpenClose from "../../hooks/useOpenClose";
import { noop } from "../../utils/util";
import Button, { ButtonProps } from "../extensions/Button";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export interface PopoverButtonProps extends Omit<ButtonProps, "onClick" | "children"> {
  label?: ReactNode;
  fluidContent?: boolean;
  tooltip?: string;
  preferredPosition?: PopoverProps["preferredPosition"];
  preferredAlignment?: PopoverProps["preferredAlignment"];
  autofocusTarget?: PopoverProps["autofocusTarget"];
  useCaret?: boolean;
  render(onClose: () => void): ReactNode;
  onClose?(): void;
}

const PopoverButton = (props: PopoverButtonProps) => {
  const {
    label,
    fluidContent,
    tooltip,
    preferredPosition,
    preferredAlignment,
    autofocusTarget = "first-node",
    useCaret,
    render,
    onClose = noop,
    disabled,
    ...buttonProps
  } = props;

  const [openPopover, togglePopover, closePopover] = useOpenClose();

  const disclosure: ButtonProps["disclosure"] = useCaret ? (openPopover ? "up" : "down") : buttonProps.disclosure;

  const activatorButton = (
    <StyledActivatorButton
      {...buttonProps}
      subdued={disabled}
      disclosure={disclosure}
      disabled={disabled}
      // Due to the latest Polaris upgrade, disabling a `Button` also disables the `connectedDisclosure` menu (the split button).
      // This is a workaround that blocks the button onClick if the disabled property is true but without passing it to the
      // Button component itself
      onClick={() => (disabled ? noop : togglePopover())}
    >
      {label}
    </StyledActivatorButton>
  );

  const handleClose = () => {
    onClose();
    closePopover();
  };

  return (
    <Popover
      active={openPopover}
      fluidContent={fluidContent}
      preferredPosition={preferredPosition}
      preferredAlignment={preferredAlignment}
      activator={
        tooltip ? (
          <TooltipWrapper content={tooltip} dismissOnMouseOut preferredPosition="above">
            {activatorButton}
          </TooltipWrapper>
        ) : (
          <>{activatorButton}</>
        )
      }
      onClose={handleClose}
      autofocusTarget={autofocusTarget}
    >
      {render(handleClose)}
    </Popover>
  );
};

// Due to the latest Polaris upgrade, disabling a `Button` also disables the `connectedDisclosure` menu (the split button).
// This is a workaround that renders the button as disabled without passing it the disabled property
const StyledActivatorButton = styled(Button)<{ subdued?: boolean; plain?: boolean }>`
  & .Polaris-Button {
    color: ${({ subdued }) => (subdued ? "var(--p-text-disabled)" : undefined)};
    padding: ${({ plain }) => (plain ? "0" : undefined)};
`;

export default PopoverButton;
