import { useContext } from "react";

import { Features } from "../constants/features";
import { AuthContext } from "../providers/AuthProvider/AuthProvider";

const useFeatures: () => Partial<Features> = () => {
  const { user } = useContext(AuthContext);

  const features = user?.features || {};

  return features;
};

export default useFeatures;
