import { useMemo } from "react";

import useFormatMessage from "../../../../hooks/useFormatMessage";
import useLanguage from "../../../../hooks/useLanguage";

const useQuestionTypesOptions = () => {
  const f = useFormatMessage();
  const language = useLanguage();

  return useMemo(() => {
    return [
      {
        value: "YES_NO",
        label: f("questionnaire.question.type.boolean")
      },
      {
        value: "TEXT",
        label: f("questionnaire.question.type.text")
      },
      {
        value: "SINGLE_CHOICE",
        label: f("questionnaire.question.type.single-choice")
      },
      {
        value: "MULTIPLE_CHOICE",
        label: f("questionnaire.question.type.multiple-choice")
      },
      {
        value: "SINGLE_COUNTRY_CHOICE",
        label: f("questionnaire.question.type.single-country-choice")
      },
      {
        value: "MULTIPLE_COUNTRY_CHOICE",
        label: f("questionnaire.question.type.multiple-country-choice")
      }
    ];
  }, [language]);
};

export default useQuestionTypesOptions;
