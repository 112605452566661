import React from "react";

import TrashIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import Button, { ButtonProps } from "../extensions/Button";

import RemoveMeasureConfirm from "./RemoveMeasureConfirm";
import { CustomerReviewMeasureProps } from "./types";

type CustomerReviewMeasureFooterProps = CustomerReviewMeasureProps &
  ButtonProps & {
    onAfterRemove(measure: api.Measure): void;
  };

const CustomerReviewMeasureDeleteButton = (props: CustomerReviewMeasureFooterProps) => {
  const { customerId, reviewId, measure, readonly, onAfterRemove, ...buttonProps } = props;

  const f = useFormatMessage();
  const [showRemoveConfirm, toggleRemoveConfirm, closeRemoveConfirm] = useOpenClose();

  return (
    <>
      <Button onClick={toggleRemoveConfirm} size="slim" disabled={readonly} icon={TrashIcon} {...buttonProps}>
        {f("default.delete")}
      </Button>
      {showRemoveConfirm && (
        <RemoveMeasureConfirm
          customerId={customerId}
          reviewId={reviewId}
          measure={measure}
          onNo={closeRemoveConfirm}
          onSuccess={() => onAfterRemove(measure)}
        />
      )}
    </>
  );
};

export default CustomerReviewMeasureDeleteButton;
