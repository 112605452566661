import React from "react";
import { generatePath } from "react-router-dom";
import { HorizontalStack, Spinner, Text } from "@shopify/polaris";

import QuestionnaireIcon from "../../../../../assets/icons/doc-text.svg";
import api from "../../../../api";
import { ROUTES } from "../../../../constants/routes";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useGetQuestionnairesSummary from "../../../../hooks/useGetQuestionnairesSummary";
import EntitiesList, { EntitiesListProps } from "../../../EntitiesList/EntitiesList";
import ErrorPanel from "../../../ErrorPanel/ErrorPanel";
import Icon from "../../../extensions/Icon";
import MarkdownContent from "../../../MarkdownContent/MarkdownContent";

type QuestionnairesListProps = Omit<
  EntitiesListProps<api.Questionnaire>,
  "entities" | "getEntityLabel" | "getEntityPrefix" | "isEntityDisabled" | "filterLabel"
> & {
  customerType: api.CustomerType;
};

const QuestionnairesList = (props: QuestionnairesListProps) => {
  const { customerType, ...entitiesListProps } = props;

  const f = useFormatMessage();
  const { questionnairesSummary, isLoading, error } = useGetQuestionnairesSummary(customerType);

  const questionnaireSettingsURL = generatePath(ROUTES.SETTINGS_QUESTIONNAIRES_LIST);

  if (isLoading) {
    return (
      <HorizontalStack gap="4" blockAlign="center">
        <Spinner size="small"></Spinner>
        <Text as="span">{f("component.questionnaires-list.loading")}</Text>;
      </HorizontalStack>
    );
  }

  if (error) {
    return <ErrorPanel message={error} actionTitle={f("component.questionnaires-list.loading.error-action")} />;
  }

  if (questionnairesSummary.length === 0) {
    return (
      <MarkdownContent
        content={f("component.questionnaires-list.empty", { link: questionnaireSettingsURL })}
      ></MarkdownContent>
    );
  }

  return (
    <EntitiesList<api.QuestionnaireSummary>
      entities={questionnairesSummary}
      getEntityLabel={(questionnaire) => questionnaire.name}
      getEntityHelpText={(questionnaire) => questionnaire.description}
      getEntityPrefix={() => <Icon source={QuestionnaireIcon} />}
      isEntityDisabled={(questionnaire) => questionnaire.is_archived}
      filterLabel={f("component.questionnaires-list.filter.label")}
      {...entitiesListProps}
    />
  );
};

export default QuestionnairesList;
