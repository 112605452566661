import { validateNorwegianIdNumber } from "norwegian-national-id-validator";

enum NameValidationErrorType {
  EMPTY = "common.errors.invalid.empty.name",
  MISSING_LAST_NAME = "common.errors.invalid.missing.last.name",
  INVALID_FIRST_NAME = "common.errors.invalid.first.name",
  INVALID_LAST_NAME = "common.errors.invalid.last.name"
}

export const isNationalIdValid = (nationalId?: string) => nationalId && validateNorwegianIdNumber(nationalId);

// a valid name must consists of at least 2 parts - separated by a space - each with a length of at least 2 characters
export const isNameValid = (name?: string): NameValidationErrorType | undefined => {
  if (!name || name.trim().length === 0) {
    return NameValidationErrorType.EMPTY;
  }

  name = name.trim();

  const lastSeparatorIndex = name.lastIndexOf(" ");
  if (lastSeparatorIndex <= 0) {
    if (name.length < 2) {
      return NameValidationErrorType.INVALID_FIRST_NAME;
    } else {
      return NameValidationErrorType.MISSING_LAST_NAME;
    }
  }

  const lastName = name.substring(lastSeparatorIndex + 1);
  if (lastName.length < 2) {
    return NameValidationErrorType.INVALID_LAST_NAME;
  }
};
