import React from "react";
import { generatePath, matchPath, useHistory } from "react-router-dom";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import CopyIcon from "../../../assets/icons/copy.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import api from "../../api";
import AddButton from "../../components/AddButton/AddButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import BackButton from "../../components/BackButton/BackButton";
import CondensedText from "../../components/CondensedText/CondensedText";
import Button from "../../components/extensions/Button";
import ReviewChecklistDuplicateModal from "../../components/ReviewChecklistDuplicateModal/ReviewChecklistDuplicateModal";
import ReviewChecklistEditor from "../../components/ReviewChecklistEditor/ReviewChecklistEditor";
import ReviewChecklistPageHeader from "../../components/ReviewChecklistPageHeader/ReviewChecklistPageHeader";
import ReviewChecklistPageSkeleton from "../../components/ReviewChecklistPageSkeleton/ReviewChecklistPageSkeleton";
import ReviewChecklistPreviewModal from "../../components/ReviewChecklistPreviewModal/ReviewChecklistPreviewModal";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { ROUTES } from "../../constants/routes";
import {
  convertChecklistToCreateChecklistRequest,
  createNewReviewChecklistQuestion
} from "../../helpers/checklists.helpers";
import useChangedState from "../../hooks/useChangedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import useSnackbar from "../../hooks/useSnackbar";
import { updateItem } from "../../utils/collectionUtils";
import { isEmptyString } from "../../utils/stringUtils";
import { clone } from "../../utils/util";

import { createNewReviewChecklist, isInvalidReviewChecklist } from "./SettingsReviewChecklistDetailsPage.helpers";

const SettingsReviewChecklistDetailsPage = ({ data }: AsyncPageChild<api.ReviewChecklist>) => {
  const { isPermittedTo } = usePermissions();
  const f = useFormatMessage();
  const history = useHistory();
  const { setSnackbar } = useSnackbar();
  const { getUserDisplayName } = useGetUserName();

  const [checklist, setChecklist, changed] = useChangedState<api.ReviewChecklist>(() => clone(data));

  const { updateQueryData } = useQueryData<api.ReviewChecklistListResponse>([QUERIES_KEYS.REVIEW_CHECKLISTS]);

  const [showPreviewModal, togglePreviewModal, closePreviewModal] = useOpenClose();
  const [showDuplicateModal, toggleDuplicateModal, closeDuplicateModal] = useOpenClose();

  const isNew = matchPath(history.location.pathname, ROUTES.SETTINGS_REVIEW_CHECKLIST_DETAILS_NEW) !== null;

  const saveChecklistMutation = useMutation(
    () =>
      isNew
        ? api.createReviewChecklist(convertChecklistToCreateChecklistRequest(checklist))
        : api.updateReviewChecklist(checklist.id, convertChecklistToCreateChecklistRequest(checklist)),
    {
      onSuccess: (newChecklist) => {
        setSnackbar(
          isNew
            ? f("review.checklist.details.page.messages.created-successfully", { name: checklist.name })
            : f("common.labels.saved-successfully")
        );

        // after a new checklist is saved, route to the same page but with the saved checklist id
        if (isNew) {
          history.push(generatePath(ROUTES.SETTINGS_REVIEW_CHECKLIST_DETAILS, { id: newChecklist.id }));
        }

        updateQueryData((queryData) => {
          if (isNew) {
            queryData.checklists = [newChecklist, ...queryData.checklists];
          } else {
            queryData.checklists = updateItem(queryData.checklists, checklist);
          }
        });
      }
    }
  );

  const readonly = !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const handleChange = (updatedChecklist: api.ReviewChecklist) => {
    setChecklist(() => clone(updatedChecklist));
    saveChecklistMutation.reset();
  };

  const navigateToReviewChecklistsListPage = () => history.push(ROUTES.SETTINGS_REVIEW_CHECKLISTS_LIST);

  const invalid = isInvalidReviewChecklist(checklist);

  const title = isNew
    ? f("review.checklist.details.page.title.new-checklist")
    : f("review.checklist.details.page.title.edit-checklist");

  const questions = checklist.questions;

  const addNewQuestion = () =>
    handleChange({ ...checklist, questions: [...questions, createNewReviewChecklistQuestion()] });

  return (
    <SettingsPage>
      <VerticalStack gap="8">
        <BackButton
          showAsLink
          url={generatePath(ROUTES.SETTINGS_REVIEW_CHECKLISTS_LIST)}
          displayLabel={f("review.checklists.page.title")}
        />
        <ReviewChecklistPageHeader title={title} isArchived={checklist.is_archived} />
        <ReviewChecklistEditor
          checklist={checklist}
          readonly={saveChecklistMutation.isLoading || checklist.is_archived || readonly}
          isNew={isNew}
          onChange={handleChange}
          error={saveChecklistMutation.error}
        />
        {checklist.updated_by && checklist.updated_dt && (
          <CondensedText italic>
            {f("common.labels.modified-by", {
              name: getUserDisplayName(checklist.updated_by),
              timestamp: new Date(checklist.updated_dt)
            })}
          </CondensedText>
        )}
        <HorizontalStack align={questions.length > 0 ? "space-between" : "end"}>
          {questions.length > 0 && (
            <AddButton
              onClick={addNewQuestion}
              disabled={readonly || checklist.is_archived || saveChecklistMutation.isLoading}
            >
              {f("questions.editor.buttons.add-question.label")}
            </AddButton>
          )}
          <HorizontalStack gap="4">
            <Button
              onClick={toggleDuplicateModal}
              icon={CopyIcon}
              disabled={invalid || checklist.is_archived || saveChecklistMutation.isLoading}
            >
              {f("default.copy")}
            </Button>
            <Button onClick={togglePreviewModal} icon={EyeIcon} disabled={invalid || saveChecklistMutation.isLoading}>
              {f("default.preview")}
            </Button>
            <Button onClick={navigateToReviewChecklistsListPage} disabled={saveChecklistMutation.isLoading}>
              {checklist.is_archived || readonly ? f("default.close") : f("default.cancel")}
            </Button>
            <Button
              primary
              onClick={() => saveChecklistMutation.mutate()}
              loading={saveChecklistMutation.isLoading}
              disabled={invalid || !changed || readonly}
            >
              {f("default.save")}
            </Button>
          </HorizontalStack>
        </HorizontalStack>
      </VerticalStack>
      {showPreviewModal && <ReviewChecklistPreviewModal checklist={checklist} onClose={closePreviewModal} />}
      {showDuplicateModal && (
        <ReviewChecklistDuplicateModal
          checklist={checklist}
          navigateToChecklistListPageAfterCopy
          onClose={closeDuplicateModal}
        />
      )}
    </SettingsPage>
  );
};

export default withAsyncPage<api.ReviewChecklist>(SettingsReviewChecklistDetailsPage, {
  queryKey: QUERIES_KEYS.REVIEW_CHECKLISTS,
  apiFunction: (id, options) =>
    isEmptyString(id) ? Promise.resolve(createNewReviewChecklist()) : api.getReviewChecklist(id, options),
  paramNames: ["id"],
  skeleton: <ReviewChecklistPageSkeleton />
});
