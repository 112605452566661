import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { isFunction } from "@tanstack/react-table";

import { clone } from "../utils/util";

type UseStateReturnType<S> = [S, Dispatch<SetStateAction<S>>];

type StorageReturnValue<S> = [...UseStateReturnType<S>, () => void, boolean];

const createStorageHook = (storage: Storage) =>
  function useStorage<T>(key: string, initialState: T | (() => T)): StorageReturnValue<T> {
    const getInitialValue = () => (isFunction(initialState) ? initialState() : initialState);

    const initialStateJSONStr = useRef<string>();

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>((): T => {
      try {
        // Get from storage by key
        const item = storage.getItem(key);

        // Parse stored json or if doesn't exists return initialValue
        const initialValue = item ? (JSON.parse(item) as T) : getInitialValue();

        initialStateJSONStr.current = JSON.stringify(initialValue);

        return initialValue;
      } catch (error) {
        Sentry.captureException(error, { level: "warning" });
        // If error also return initialValue
        return getInitialValue();
      }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to storage.
    function setValue(value: SetStateAction<T>): void {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save to storage
        storage.setItem(key, JSON.stringify(valueToStore));
        // Save state
        setStoredValue(clone(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
        Sentry.captureException(error, { level: "warning" });
      }
    }

    const clear = () => {
      storage.removeItem(key);
      initialStateJSONStr.current = JSON.stringify(storedValue);
    };

    const changed = useMemo(
      () => JSON.stringify(storedValue) !== initialStateJSONStr.current,
      [storedValue, initialStateJSONStr.current]
    );

    return [storedValue, setValue, clear, changed];
  };

export const useLocalStorage = createStorageHook(window.localStorage);

export const useSessionStorage = createStorageHook(window.sessionStorage);
