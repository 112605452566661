import React from "react";
import { RadioButton, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

interface ScreeningTypeCardProps {
  readonly?: boolean;
  screeningType: api.ScreeningType;
  onScreeningTypeChange(screeningType: api.ScreeningType): void;
}

const ScreeningTypeCard = (props: ScreeningTypeCardProps) => {
  const { readonly, screeningType, onScreeningTypeChange } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="4">
      <RadioButton
        label={
          <Text as="span" fontWeight="semibold">
            {f("screening-type.label.monitoring")}
          </Text>
        }
        helpText={f("onboarding.screening-type.monitoring.helper.text")}
        checked={screeningType === "MONITORING"}
        id={"MONITORING"}
        name="screeningType"
        onChange={() => onScreeningTypeChange("MONITORING")}
        disabled={readonly}
      />
      <RadioButton
        label={
          <Text as="span" fontWeight="semibold">
            {f("screening-type.label.one-time")}
          </Text>
        }
        helpText={f("onboarding.screening-type.one-time.helper.text")}
        id={"ONE_TIME"}
        name="screeningType"
        checked={screeningType === "ONE_TIME"}
        onChange={() => onScreeningTypeChange("ONE_TIME")}
        disabled={readonly}
      />
    </VerticalStack>
  );
};

export default ScreeningTypeCard;
