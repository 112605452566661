export const ACCEPTED_FILE_TYPES = [
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".csv",
  ".ppt",
  ".pptx",
  ".txt",
  ".jpg",
  ".jpeg",
  ".png",
  ".heic"
];

export const MAXIMUM_FILE_SIZE_IN_BYTES_FOR_UPLOAD = 25_000_000; // 25mb;
