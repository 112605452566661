import React from "react";
import { Card, HorizontalStack, TextField } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

interface AssociateToProjectSectionProps {
  projectName: string;
}

const AssociatedProjectSection = (props: AssociateToProjectSectionProps) => {
  const { projectName } = props;

  const f = useFormatMessage();

  return (
    <Card.Section title={f("onboarding.associated-project.title")}>
      <HorizontalStack>
        <TextField
          disabled
          value={projectName}
          label={f("onboarding.associated-project.title")}
          labelHidden
          autoComplete="off"
          maxLength={50}
        />
      </HorizontalStack>
    </Card.Section>
  );
};

export default AssociatedProjectSection;
