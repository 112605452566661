import * as React from "react";
import { QueryKey } from "@tanstack/react-query/";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { hasOwnProperty } from "../utils/util";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorType = unknown | any;

export type EntityWithId = { id: string };

export type Customer = api.PersonCustomer | api.CompanyCustomer | api.PersonSearchResult | api.CompanySearchResult;

export type CustomerDetails = api.PersonCustomerDetailResponse | api.CompanyCustomerDetailResponse;

export type Entity =
  | Customer
  | CustomerDetails
  | api.Project
  | api.CustomerAssignmentDetails
  | (api.Assignment & { customer_id: string });

export type CustomerKeyType = { id: string; type?: api.CustomerType };

export type PersonInformation = Pick<
  api.OwnerPerson,
  keyof (api.RolePerson | api.OwnerPerson | api.UpdatePersonCustomerRequest)
>;

export type PersonInformationKeys = Array<keyof PersonInformation>;

export const isPersonCustomer = (entity: Entity): entity is api.PersonCustomer =>
  (entity as api.PersonCustomer).type === "person";

export const isCompany = (
  customer: Customer | CustomerDetails
): customer is api.CompanyCustomer | api.CompanySearchResult | api.CompanyCustomerDetailResponse =>
  customer.type === "company";

export const isManualCompany = (customer: Customer | CustomerDetails) => isCompany(customer) && customer.is_manual;

export const isCompanySearchResult = (customer: Customer): customer is api.CompanySearchResult =>
  isCompany(customer) && Array.isArray((customer as api.CompanySearchResult).related_search_results);

export const isPersonCustomerDetails = (entity: CustomerDetails): entity is api.PersonCustomerDetailResponse =>
  (entity as api.PersonCustomerDetailResponse).type === "person";

export const isProject = (entity: Entity): entity is api.Project => (entity as api.Project).customers !== undefined;

export const isAssignment = (entity: Entity): entity is api.CustomerAssignmentDetails =>
  hasOwnProperty(entity, "is_accepted");

export const getCustomerQueryKey = (customer: CustomerKeyType): QueryKey => {
  const queryKey = [
    customer.type === "company" ? QUERIES_KEYS.COMPANY_DETAILS : QUERIES_KEYS.PERSON_DETAILS,
    customer.id
  ];

  return queryKey;
};

/**
 * Helper type to get a union of all the values types of T
 */
export type ValueOf<T> = T[keyof T];

/**
 * Helper type that removes all undefined values from a union
 */
export type Concrete<T> = T extends undefined ? never : T;

/**
 * Helper type that makes a property optional
 */
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

/**
 * Helper type that removes null or undefined values from a given type T (should be used mainly with objects)
 * see: https://medium.com/mizyind-singularity/remove-blank-attributes-from-an-object-in-typescript-with-type-safe-ad4fd78a061c
 */
export type Valuable<T> = { [K in keyof T as T[K] extends null | undefined ? never : K]: T[K] };

/**
 * Helper function that enables using generics in Props passed to React.forwardRef
 * see: https://www.totaltypescript.com/forwardref-with-generic-components#the-solution
 */
export function fixedForwardRef<T, P = NonNullable<unknown>>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.forwardRef(render) as any;
}
