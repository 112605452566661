import React from "react";

import useNavigationToolbar from "../../hooks/useNavigationToolbar";

import OnboardingWizardToolbar from "./OnboardingWizardToolbar";
import OneStepOnboardingToolbar from "./OneStepOnboardingToolbar";

const OnboardingToolbar = () => {
  const { onboardingType } = useNavigationToolbar();
  const isOneStepOnboarding = onboardingType === "person";

  if (isOneStepOnboarding) {
    return <OneStepOnboardingToolbar />;
  } else {
    return <OnboardingWizardToolbar />;
  }
};

export default OnboardingToolbar;
