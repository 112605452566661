import React from "react";
import { HorizontalStack } from "@shopify/polaris";
import styled from "styled-components";

import EnvelopeIcon from "../../../../../../assets/icons/envelope.svg";
import useFormatMessage from "../../../../../hooks/useFormatMessage";
import CopyLabel from "../../../../CopyLabel/CopyLabel";
import Icon from "../../../../extensions/Icon";
import TooltipWrapper from "../../../../TooltipWrapper/TooltipWrapper";
import { AttachmentSignature } from "../../../AttachmentsSection.types";

interface AttachmentSignatureEmailOrLinkLabelProps {
  attachmentSignature: AttachmentSignature;
}

const AttachmentSignatureEmailOrLinkLabel = (props: AttachmentSignatureEmailOrLinkLabelProps) => {
  const { attachmentSignature } = props;

  const f = useFormatMessage();

  if (attachmentSignature.send_email) {
    return (
      <TooltipWrapper content={attachmentSignature.email} preferredPosition="above">
        <HorizontalStack gap="1" blockAlign="center">
          <Icon source={EnvelopeIcon} />
          <StyledEmailLabel>{attachmentSignature.email}</StyledEmailLabel>
        </HorizontalStack>
      </TooltipWrapper>
    );
  }

  if (!attachmentSignature.signing_page_url) {
    return <div>{f("attachments.signatures.modal.labels.missing-url")}</div>;
  }

  return (
    <CopyLabel size="slim" maxWidth="15ch">
      {attachmentSignature.signing_page_url}
    </CopyLabel>
  );
};

const StyledEmailLabel = styled.div`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default AttachmentSignatureEmailOrLinkLabel;
