import React from "react";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { convertMeasureToCreateCustomMeasureRequest, createNewMeasure } from "../../helpers/measures.helpers";
import useOpenClose from "../../hooks/useOpenClose";
import { difference } from "../../utils/collectionUtils";
import MeasureEditModal from "../MeasureEditModal/MeasureEditModal";
import MeasurePopover from "../MeasurePopover/MeasurePopover";

import MeasuresHeader from "./MeasuresHeader";

type CustomerReviewMeasuresHeaderProps = {
  customerId: string;
  reviewId: string;
  measures: api.Measure[];
  readonly?: boolean;
  onAfterAdd(measure: api.Measure): void;
};

const CustomerReviewMeasuresHeader = (props: CustomerReviewMeasuresHeaderProps) => {
  const { customerId, reviewId, measures, readonly, onAfterAdd } = props;

  const [showEditMeasureModal, toggleEditMeasureModal, closeEditMeasureModal] = useOpenClose();

  const addCustomerReviewMeasureMutation = useMutation<api.Measure, unknown, api.CreateMeasureRequest>(
    (measure) => api.addCustomerReviewMeasure(customerId, reviewId, measure),
    {
      onSuccess: (newMeasure) => {
        closeEditMeasureModal();
        onAfterAdd(newMeasure);
      }
    }
  );

  const handleSelectionChange = (selectedMeasures: api.Measure[]) => {
    const toggledMeasure = difference(selectedMeasures, measures)[0];
    if (toggledMeasure) {
      addCustomerReviewMeasureMutation.mutate(convertMeasureToCreateCustomMeasureRequest(toggledMeasure));
    }
  };

  const handleNewMeasure = () => toggleEditMeasureModal();

  const closeAndClear = () => {
    addCustomerReviewMeasureMutation.reset();
    closeEditMeasureModal();
  };

  return (
    <MeasuresHeader measures={measures}>
      <MeasurePopover
        measures={measures}
        disabled={readonly}
        onChange={handleSelectionChange}
        onNewMeasure={handleNewMeasure}
        loading={addCustomerReviewMeasureMutation.isLoading}
      />
      {showEditMeasureModal && (
        <MeasureEditModal<api.Measure>
          measure={createNewMeasure()}
          existingMeasures={measures}
          isNewMeasure
          isLoading={addCustomerReviewMeasureMutation.isLoading}
          error={addCustomerReviewMeasureMutation.error}
          onSave={(measure) =>
            addCustomerReviewMeasureMutation.mutate(convertMeasureToCreateCustomMeasureRequest(measure))
          }
          onClose={closeAndClear}
        />
      )}
    </MeasuresHeader>
  );
};

export default CustomerReviewMeasuresHeader;
