import React from "react";
import { Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import DetailsGrid from "../../components/DetailsGrid/DetailsGrid";
import KDKBanner from "../../components/KDKBanner/KDKBanner";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import AssignmentResponsibleUsersSelect from "../../pages/CustomerAssignmentDetailsPage/AssignmentResponsibleUsersSelect";
import AssignmentIndustry from "../AssignmentIndustry";
import AssignmentPurpose from "../AssignmentPurpose/AssignmentPurpose";
import CondensedText from "../CondensedText/CondensedText";
import CustomDate from "../CustomDate/CustomDate";
import Risk from "../Risk/Risk";

type BasicAssignmentInfoProps = {
  assignment: api.CustomerAssignmentDetails;
  readonly?: boolean;
};

const BasicAssignmentInfo = (props: BasicAssignmentInfoProps) => {
  const { assignment, readonly } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  return (
    <KDKBanner hideIcon hideBorder>
      <VerticalStack gap="4">
        <DetailsGrid rows={3}>
          {/* TODO show assignment status after the backend implements setting it during customer review */}
          {/*<Text as="span">{f("default.status")}:</Text>*/}
          {/*<Text as="span" fontWeight="bold">*/}
          {/*  <AssignmentStatus assignment={assignment} />*/}
          {/*</Text>*/}

          <Text as="span">{f("common.labels.start-date")}:</Text>
          <Text as="span" fontWeight="bold">
            <CustomDate date={assignment.start_date} />
          </Text>

          <Text as="span">{f("common.labels.end-date")}:</Text>
          <Text as="span" fontWeight="bold">
            <CustomDate date={assignment.end_date} />
          </Text>

          <Text as="span">{f("responsible.empty.users")}:</Text>
          <AssignmentResponsibleUsersSelect assignment={assignment} readonly={readonly} />

          <Text as="span">{f("customer-assignments.details.assignment-risk")}:</Text>
          <Text as="span" fontWeight="bold">
            <Risk risk={assignment.risk_level} />
          </Text>

          {features.ASSIGNMENT_INDUSTRIES && assignment.industry_type && (
            <>
              <Text as="span">{f("customer-assignments.details.assignment-industry-type")}:</Text>
              <AssignmentIndustry assignment={assignment} />
            </>
          )}

          <Text as="span">{f("customer-assignments.details.assignment-purpose")}:</Text>
          <Text as="span" fontWeight="bold">
            <AssignmentPurpose assignment={assignment} />
          </Text>

          {/* empty div to create a nice gap between left and right column */}
          {/* TODO can be removed after assignment status is shown again */}
          <div />
          <div />

          <Text as="span">{f("customer-assignments.details.assignment-description")}:</Text>
          <Text as="span" fontWeight="bold">
            {assignment.description}
          </Text>
        </DetailsGrid>
        <VerticalStack>
          <CondensedText>
            {f("common.labels.created-by", {
              name: getUserDisplayName(assignment.created_by),
              timestamp: new Date(assignment.created_dt)
            })}
          </CondensedText>
          {assignment.archived_dt && (
            <CondensedText>
              {f("common.labels.archived-by", {
                name: getUserDisplayName(assignment.archived_by),
                timestamp: new Date(assignment.archived_dt)
              })}
            </CondensedText>
          )}
        </VerticalStack>
      </VerticalStack>
    </KDKBanner>
  );
};

export default BasicAssignmentInfo;
