import React from "react";
import { HorizontalStack } from "@shopify/polaris";

import WarningIcon from "../../../../assets/icons/triangle-filled-exclamation.svg";
import api from "../../../api";
import RoleTypes from "../../../constants/role-types";
import useFormatMessage from "../../../hooks/useFormatMessage";
import CustomDate from "../../CustomDate/CustomDate";
import Badge from "../../extensions/Badge";
import Icon from "../../extensions/Icon";
import RowHighlighter from "../../extensions/RowHighlighter";
import PersonName from "../../PersonName/PersonName";

type RoleNameProps = {
  role: api.Role;
  screeningType?: api.ScreeningType;
  companyType?: string;
  showUpdates?: boolean;
  showWarning?: boolean;
  deleted: boolean;
};

const RoleName = (props: RoleNameProps) => {
  const { role, screeningType, companyType, showUpdates, showWarning, deleted } = props;

  const f = useFormatMessage();

  const isNew = role.is_new && showUpdates;

  const expired = role.status === "EXPIRED" && showUpdates;

  const uboTooltip = role.custom_is_ubo
    ? f("common.labels.manual-ubo")
    : f("screening.roles.is_ubo.description", {
        companyType: companyType,
        roleType: f(RoleTypes[role.type])
      });

  return (
    <RowHighlighter highlight={isNew} subdued={expired}>
      <HorizontalStack gap={showWarning ? "4" : "0"} wrap={false}>
        {showWarning && <Icon source={WarningIcon} />}
        <PersonName entity={role} deleted={deleted} uboTooltip={uboTooltip} screeningType={screeningType} wrap>
          {isNew && (
            <Badge size="xsmall" status="info">
              {f("default.new")} ({<CustomDate date={role.updated_dt} />})
            </Badge>
          )}
        </PersonName>
      </HorizontalStack>
    </RowHighlighter>
  );
};

export default RoleName;
