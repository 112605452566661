import React, { useEffect, useMemo } from "react";
import { Checkbox, HorizontalStack, VerticalStack } from "@shopify/polaris";
import {
  CellContext,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  HeaderContext,
  RowSelectionState,
  useReactTable
} from "@tanstack/react-table";

import TrashIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import CondensedText from "../../components/CondensedText/CondensedText";
import Button from "../../components/extensions/Button";
import ImportStatus from "../../components/ImportCustomersTable/ImportCustomerStatus";
import TextHighlighter from "../../components/TextHighlighter/TextHighlighter";
import TooltipWrapper from "../../components/TooltipWrapper/TooltipWrapper";
import { DEFAULT_PAGE_SIZE, IGNORE_COLUMN } from "../../constants/import-customers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ImportCustomersFilters, ImportRowData } from "../../types/ImportRow";
import { isEmptyString } from "../../utils/stringUtils";

import ColumnNameSelect from "./ColumnNameSelect";

interface UseImportCustomersTableProps {
  rows: ImportRowData[];
  columnsNames: string[];
  headers: string[];
  customerType: api.CustomerType;
  rowSelection: RowSelectionState;
  filters: ImportCustomersFilters;
  onColumnChange(columnsNames: string[]): void;
  onSelectionChange: React.Dispatch<React.SetStateAction<RowSelectionState>>;
}

const useImportCustomersTable = (props: UseImportCustomersTableProps) => {
  const { rows, columnsNames, customerType, headers, filters, rowSelection, onSelectionChange, onColumnChange } = props;

  const f = useFormatMessage();

  const columns = useMemo<ColumnDef<ImportRowData>[]>(() => {
    const searchWords = filters.searchText.split(" ");

    const updateColumn = (index: number, name: string) => {
      const columns = [...columnsNames];
      columns[index] = name;
      onColumnChange(columns);
    };

    return [
      {
        id: "select",
        enablePinning: true,
        enableGlobalFilter: false,
        header: ({ table }) => {
          const checkedState = table.getIsSomeRowsSelected() ? "indeterminate" : table.getIsAllRowsSelected();
          return <Checkbox labelHidden label="" checked={checkedState} onChange={table.toggleAllRowsSelected} />;
        },
        cell: ({ row }) => (
          <Checkbox labelHidden label="" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        )
      },
      {
        accessorKey: "status",
        header: f("table.column.status"),
        enableGlobalFilter: false,
        enablePinning: true,
        filterFn: "arrIncludesSome",
        cell: (props) => <ImportStatus importRowData={props.row.original} />
      },
      ...columnsNames.map((columnName, index) => ({
        accessorFn: (row: ImportRowData) => row.record[index],
        id: "column_" + index,
        enableGlobalFilter: columnName !== IGNORE_COLUMN,
        enablePinning: true,
        header: ({ column }: HeaderContext<ImportRowData, unknown>) => (
          <HorizontalStack gap="1" wrap={false}>
            <VerticalStack>
              <ColumnNameSelect
                columnName={columnName}
                customerType={customerType}
                onChange={(name) => updateColumn(index, name)}
              />
              {!isEmptyString(headers[index]) && <CondensedText>{headers[index].toUpperCase()}</CondensedText>}
            </VerticalStack>
            <TooltipWrapper
              content={f("import-customers.wizard.table.hide-column-button.tooltip")}
              preferredPosition={"above"}
            >
              <Button
                plain
                removeUnderline
                destructive
                hoverable
                icon={TrashIcon}
                onClick={() => {
                  column.toggleVisibility(false);
                  // mark column as ignored so it will not be included in row validation
                  updateColumn(index, IGNORE_COLUMN);
                }}
              />
            </TooltipWrapper>
          </HorizontalStack>
        ),
        cell: (props: CellContext<ImportRowData, unknown>) => (
          <TextHighlighter searchWords={searchWords} textToHighlight={props.row.original.record[index] || ""} />
        )
      })),
      {
        accessorKey: "nameFromSearch",
        header: f("table.column.name-from-search"),
        enablePinning: true,
        cell: (props: CellContext<ImportRowData, unknown>) => (
          <TextHighlighter searchWords={searchWords} textToHighlight={props.getValue() || ""} />
        )
      }
    ];
  }, [filters.searchText, columnsNames.join("-"), headers.join("-")]);

  const table = useReactTable<ImportRowData>({
    columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: true,
    state: {
      rowSelection,
      globalFilter: filters.searchText
    },
    initialState: {
      pagination: {
        pageSize: DEFAULT_PAGE_SIZE
      }
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: onSelectionChange
  });

  useEffect(() => {
    if (filters.statuses.length > 0) {
      table.getColumn("status")?.setFilterValue(filters.statuses);
    } else {
      table.getColumn("status")?.setFilterValue(undefined);
    }
  }, [table, filters.statuses.join("-")]);

  useEffect(() => {
    table.getAllColumns().forEach((colum) => {
      if (colum.id.startsWith(IGNORE_COLUMN)) {
        colum.pin(false);
      } else if (colum.id === "nameFromSearch") {
        colum.pin("right");
      } else {
        colum.pin("left");
      }
    });
  }, [table, columnsNames.join("-")]);

  return table;
};

export default useImportCustomersTable;
