import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";

import useLanguage from "./useLanguage";

const useGetCustomerReviewGuideline = (customerType: api.CustomerType, timestamp?: string) => {
  // use useLanguage and not useTranslatedText in order to NOT fallback to the default language in case of an empty value
  const language = useLanguage();
  const timestampISOString = timestamp ? new Date(timestamp).toISOString() : undefined;

  const { data } = useQuery(
    [QUERIES_KEYS.CUSTOMER_REVIEW_GUIDELINE, customerType, timestamp].flat(),
    () => api.getReviewGuideline(customerType, { timestamp: timestampISOString }),
    { staleTime: STALE_TIME }
  );

  const guideline = data ? { title: data.title[language], text: data.text_md[language] } : undefined;

  return guideline;
};

export default useGetCustomerReviewGuideline;
