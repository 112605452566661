import React, { useEffect, useState } from "react";
import { Modal, Text, Toast, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import api from "../../../../api";
import { DURATION_MEDIUM_IN_MS } from "../../../../constants/durations";
import { QUERIES_KEYS } from "../../../../constants/queries-keys";
import { getFullName } from "../../../../helpers/display.helpers";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import useOpenClose from "../../../../hooks/useOpenClose";
import { CustomerDetails, Entity } from "../../../../types/utilities";
import { cleanupFilename } from "../../../../utils/filenameUtils";
import { isNil, noop } from "../../../../utils/util";
import AddItemButton from "../../../AddItemButton/AddItemButton";
import Confirm from "../../../Confirm/Confirm";
import ErrorPanel from "../../../ErrorPanel/ErrorPanel";
import {
  hasSignedAttachment,
  isAttachmentSignable,
  isAttachmentSigned,
  wasSignedAttachmentDeleted
} from "../../AttachmentsSection.helpers";
import { AttachmentSignature } from "../../AttachmentsSection.types";

import AttachmentSignaturesTable from "./AttachmentSignaturesTable/AttachmentSignaturesTable";
import AttachmentSignaturesModalFooter from "./AttachmentSignaturesModalFooter";
import AttachmentSignedDocumentSection from "./AttachmentSignedDocumentSection";
import AttachmentUnsignableSection from "./AttachmentUnsignableSection";
import useAttachmentSignaturesModal from "./useAttachmentSignaturesModal";

/**
Save action label:
  When email is chosen for all recipients, the call to action is labeled "Send"
    - Clicking Send will send the emails and close the modal
    - Present a toast as a confirmation: "Emails sent" (Disappears after 4 seconds)

  When link is chosen for all recipients, the call to action is labeled "Create links"
    - Clicking Create links will not close the modal

  When email and link are mixed for the recipients, the call to action is labeled "Send emails and create links"
    - Clicking Send emails and create links will not close the modal

  When doing changes that does not involve sending emails / creating links, the call to action is labeled "Save"
    - Example: changing a recipient's name, turning on/off birth number authentication
    - Clicking Save will save and close the modal
    - Present a toast as a confirmation: "Changes saved" (Disappears after 4 seconds)

Cancel action label:
  When changes are saved, use the label "Close" instead of "Cancel"
 **/

type AttachmentSignaturesModalProps = {
  entity: Entity;
  attachment: api.AttachmentDetails;
  initialPerson?: api.PersonCustomer;
  fetchSuggestedSigners?: () => Promise<CustomerDetails[]>;
  readonly?: boolean;
  onModalClose(): void;
};

const AttachmentSignaturesModal = (props: AttachmentSignaturesModalProps) => {
  const { entity, attachment, initialPerson, fetchSuggestedSigners = noop, readonly, onModalClose } = props;

  const [savingError, setSavingError] = useState<unknown>();
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showSendEmailsToast, toggleSendEmailsToast, closeSendEmailsToast] = useOpenClose();
  const [isModalVisible, setModalVisible] = useState(true);

  const isSigned = isAttachmentSigned(attachment);
  const isSignable = isAttachmentSignable(attachment);
  const isAttachmentReadonly = readonly || isSigned || !isSignable;
  const showSignedDocumentSection = hasSignedAttachment(attachment) || wasSignedAttachmentDeleted(attachment);

  const f = useFormatMessage();

  const {
    isInitialEmpty,
    rows,
    isLoading,
    isSaving,
    isError,
    error,
    hasInvalidRows,
    hasUnsavedChanges,
    isEmailOnly,
    isLinkOnly,
    hasUnsentEmails,
    hasUnsavedLinks,
    createRow,
    saveChanges,
    ...rowsMethods
  } = useAttachmentSignaturesModal(entity, attachment);

  const { data } = useQuery([QUERIES_KEYS.SUGGESTED_SIGNERS, entity.id], fetchSuggestedSigners, {
    enabled: fetchSuggestedSigners !== undefined
  });

  const customers: CustomerDetails[] = data || [];

  useEffect(() => {
    // creates a default signature if the table is empty
    if (isInitialEmpty && !isAttachmentReadonly) {
      const initialValues: Partial<AttachmentSignature> = {
        fullName: getFullName(initialPerson),
        email: initialPerson?.email,
        signer_id: initialPerson?.person_id,
        national_id: initialPerson?.national_id,
        send_email: true
      };

      createRow(initialValues, initialPerson !== undefined);
    }
  }, [isInitialEmpty, initialPerson, isAttachmentReadonly]);

  const handleSaveChanges = async () => {
    try {
      setSavingError(undefined);
      setSavedSuccessfully(false);
      await saveChanges();
      setSavedSuccessfully(true);
      if (isEmailOnly && hasUnsentEmails) {
        // hide the modal
        setModalVisible(false);
        // show the success message toast
        toggleSendEmailsToast();
      }
    } catch (error) {
      setSavingError(error);
    }
  };

  const handleCancel = () => {
    if (hasUnsavedChanges) {
      setShowCancelConfirmation(true);
    } else {
      closeModal();
    }
  };

  const handleToastDismiss = () => {
    closeSendEmailsToast();
    closeModal();
  };

  const closeModal = () => {
    setSavingError(undefined);
    onModalClose();
  };

  const showTable = !isLoading && rows;
  const showError = isError || !isNil(savingError);
  const isSaveDisabled = isLoading || hasInvalidRows || isAttachmentReadonly || (isInitialEmpty && rows.length === 0);

  const title = f("attachments.signatures.modal.title", { filename: cleanupFilename(attachment.filename) });

  const getSaveActionLabel = () => {
    if (isEmailOnly && hasUnsentEmails) {
      return f("default.send");
    } else if (isLinkOnly && hasUnsavedLinks) {
      return f("attachments.signatures.modal.labels.create-links");
    } else if (hasUnsentEmails || hasUnsavedLinks) {
      return f("attachments.signatures.modal.labels.send-emails-and-create-links");
    } else {
      return f("default.save-changes");
    }
  };

  const saveActionLabel = getSaveActionLabel();

  const saveAction = {
    content: saveActionLabel,
    onAction: () => handleSaveChanges(),
    loading: isSaving,
    disabled: isSaveDisabled || !hasUnsavedChanges
  };

  const cancelAction = {
    content: hasUnsavedChanges ? f("default.cancel") : f("default.close"),
    onAction: () => handleCancel()
  };

  return (
    <>
      <Modal
        // modal should remain visible until send email success Toast is displayed
        open={isModalVisible}
        large
        title={
          <Text variant="headingMd" as="h2">
            {title}
          </Text>
        }
        onClose={handleCancel}
        footer={
          <AttachmentSignaturesModalFooter
            hasUnsavedChanges={hasUnsavedChanges}
            savedSuccessfully={savedSuccessfully}
          />
        }
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
      >
        <Modal.Section>
          <VerticalStack gap="2">
            <Text variant="headingMd" as="h2">
              {f("attachments.signatures.modal.heading")}
            </Text>
            {showError && <ErrorPanel message={savingError || error} />}
            {isLoading && (
              <Text variant="headingXl" as="p">
                {f("default.loading")}
              </Text>
            )}
          </VerticalStack>
        </Modal.Section>
        {showTable && (
          <AttachmentSignaturesTable
            customerId={entity.id}
            rows={rows}
            customers={customers}
            readonly={readonly || !isSignable}
            {...rowsMethods}
          />
        )}
        {!isSignable && (
          <Modal.Section>
            <AttachmentUnsignableSection />
          </Modal.Section>
        )}
        {showSignedDocumentSection && (
          <Modal.Section>
            <AttachmentSignedDocumentSection entity={entity} attachment={attachment} readonly={readonly} />
          </Modal.Section>
        )}
        {!isAttachmentReadonly && (
          <StyledStickySection>
            <Modal.Section>
              <AddItemButton disabled={isSaving || isAttachmentReadonly} onClick={() => createRow()}>
                {f("attachments.signatures.modal.button.add-recipient")}
              </AddItemButton>
            </Modal.Section>
          </StyledStickySection>
        )}
      </Modal>
      {showCancelConfirmation && (
        <Confirm
          title={f("attachments.cancel.confirmation.title")}
          description={f("attachments.cancel.confirmation.description")}
          onYes={closeModal}
          onNo={() => setShowCancelConfirmation(false)}
          cancelTitle={f("button.go.back")}
          actionTitle={f("attachments.cancel.confirmation.yes")}
        />
      )}
      {showSendEmailsToast && (
        <Toast
          content={f("attachments.signatures.modal.labels.email-sent")}
          onDismiss={handleToastDismiss}
          duration={DURATION_MEDIUM_IN_MS}
        />
      )}
    </>
  );
};

const StyledStickySection = styled.div`
  position: sticky;
  bottom: 0;
  background-color: var(--p-surface);
  z-index: var(--p-z-1);
  border-top: var(--p-border-divider);
`;

export default AttachmentSignaturesModal;
