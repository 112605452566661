import { Card, Stack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { RiskTypes } from "../../constants/risk-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import FullWidthStatusRadioButton, { BUTTON_COLOR } from "../FullWidthStatusRadioButton/FullWidthStatusRadioButton";
import KDKBanner from "../KDKBanner/KDKBanner";

const RISK_LEVEL_TO_BUTTON_COLOR: Record<api.RiskLevel, BUTTON_COLOR | undefined> = {
  HIGH: "red",
  MEDIUM: "orange",
  LOW: "green",
  NOT_SET: undefined
};

type CustomerReviewRiskLevelFormProps = {
  riskLevel: api.RiskLevel;
  readonly?: boolean;
  onRiskLevelChange(riskLevel: api.RiskLevel): void;
};

const CustomerReviewRiskLevelForm = (props: CustomerReviewRiskLevelFormProps) => {
  const { riskLevel, readonly, onRiskLevelChange } = props;

  const f = useFormatMessage();

  return (
    <Card>
      <Card.Section>
        <KDKBanner hideIcon hideBorder>
          <VerticalStack gap="6">
            <Text variant="headingSm" as="h2">
              {f("reviews.labels.relationship.change")}
            </Text>
            <Stack distribution="fillEvenly">
              {RiskTypes.map((risk) => (
                <FullWidthStatusRadioButton
                  id={`risk-level-${risk}`}
                  label={f(`risklabel.${risk}`)}
                  name="risk"
                  checked={riskLevel === risk}
                  onChange={() => onRiskLevelChange(risk)}
                  disabled={readonly}
                  key={`risk-level-${risk}`}
                  color={RISK_LEVEL_TO_BUTTON_COLOR[risk]}
                />
              ))}
            </Stack>
          </VerticalStack>
        </KDKBanner>
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewRiskLevelForm;
