import { useHistory, useLocation } from "react-router-dom";

import { useLocalStorage } from "../../../hooks/useStorage";
import { isEmptyString } from "../../../utils/stringUtils";

const useSavedFilters = () => {
  const history = useHistory();
  const location = useLocation();

  const [filtersMap, setFiltersMap] = useLocalStorage<Record<string, string>>("savedFilters", {});

  const saveCurrentFilters = (filterName: string) => {
    if (isEmptyString(location.search)) {
      return;
    }

    filtersMap[filterName] = location.search;
    setFiltersMap(filtersMap);
  };

  const deleteSavedFilters = (filterName: string) => {
    delete filtersMap[filterName];
    setFiltersMap(filtersMap);
  };

  const applyFilter = (filterName: string) => {
    if (!filtersMap[filterName]) {
      return;
    }
    history.replace({ ...location, search: filtersMap[filterName] });
  };

  const savedFilters = Object.keys(filtersMap);

  const activeFilter = Object.entries(filtersMap).find(([, search]) => location.search === search);
  const activeFilterName = activeFilter ? activeFilter[0] : undefined;

  return {
    savedFilters,
    activeFilterName,
    saveCurrentFilters,
    deleteSavedFilters,
    applyFilter
  };
};

export default useSavedFilters;
