import { Stack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import FullWidthStatusRadioButton from "../FullWidthStatusRadioButton/FullWidthStatusRadioButton";

type CustomerReviewApprovalSectionProps = {
  accepted?: boolean;
  readonly?: boolean;
  onAcceptedChange(accepted: boolean): void;
};

const CustomerReviewAcceptForm = (props: CustomerReviewApprovalSectionProps) => {
  const { accepted, readonly, onAcceptedChange } = props;

  const f = useFormatMessage();

  return (
    <Stack distribution="fill">
      <FullWidthStatusRadioButton
        label={f("common.labels.approve")}
        checked={accepted === true} // compare to boolean value because review.accepted can also be undefined
        name="relationship"
        color={"green"}
        onChange={() => onAcceptedChange(true)}
        disabled={readonly}
      />
      <FullWidthStatusRadioButton
        label={f("common.labels.reject")}
        checked={accepted === false} // compare to boolean value because review.accepted can also be undefined
        name="relationship"
        color={"red"}
        onChange={() => onAcceptedChange(false)}
        disabled={readonly}
      />
    </Stack>
  );
};

export default CustomerReviewAcceptForm;
