import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { CustomerDetails } from "../types/utilities";

import useQueryData from "./useQueryData";

const useCustomerUpdateNoteMutation = (customer: CustomerDetails) => {
  const { updateQueryData } = useQueryData<api.CustomerNotation[]>([QUERIES_KEYS.CUSTOMER_NOTES, customer.id]);

  const updateNoteMutation = useMutation<api.CustomerNotation, unknown, { noteId: string; comment: string }>(
    ({ noteId, comment }) => api.updateCustomerNotation(customer.id, noteId, { comment }),
    {
      onSuccess: (updatedNote: api.CustomerNotation) => {
        updateQueryData((prevNotes) => {
          const updatedNoteIndex = prevNotes.findIndex((note) => note.id === updatedNote.id);
          if (updatedNoteIndex > -1) {
            prevNotes.splice(updatedNoteIndex, 1, updatedNote);
          }
        });
      }
    }
  );

  return updateNoteMutation;
};

export default useCustomerUpdateNoteMutation;
