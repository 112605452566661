import React from "react";
import { Toast, ToastProps } from "@shopify/polaris";

import { DURATION_VERY_LONG_IN_MS } from "../../constants/durations";
import { getChecklistAnchorId } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { scrollInPage } from "../../utils/browserUtils";
import { first } from "../../utils/collectionUtils";

type CustomerReviewAddedChecklistsToastProps = Omit<ToastProps, "content" | "action"> & {
  addedChecklistsIds: string[];
};

const CustomerReviewAddedChecklistsToast = (props: CustomerReviewAddedChecklistsToastProps) => {
  const { addedChecklistsIds, ...toastProps } = props;

  const f = useFormatMessage();

  if (addedChecklistsIds.length === 0) {
    return null;
  }

  return (
    <Toast
      content={f("component.review.checklist.added.toast.message")}
      duration={DURATION_VERY_LONG_IN_MS}
      action={{
        content: f("component.review.checklist.added.toast.scroll.label"),
        onAction: () => scrollInPage(getChecklistAnchorId(first(addedChecklistsIds)!), { behavior: "smooth" })
      }}
      {...toastProps}
    />
  );
};

export default CustomerReviewAddedChecklistsToast;
