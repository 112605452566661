import React, { forwardRef } from "react";
import { BannerStatus, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { isReviewRejected } from "../../helpers/reviews.helpers";
import useCustomerReview from "../../hooks/useCustomerReview";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Review } from "../../types/CustomerReview";
import { CustomerDetails } from "../../types/utilities";
import { isNil } from "../../utils/util";
import AssignmentInfo from "../AssignmentInfo/AssignmentInfo";
import Badges from "../Badges/Badges";
import CustomerReviewApprovalReason from "../CustomerReviewApprovalReason/CustomerReviewApprovalReason";
import CustomerReviewCardPendingLabel from "../CustomerReviewCardPendingLabel/CustomerReviewCardPendingLabel";
import CustomerReviewMeasures from "../CustomerReviewMeasures/CustomerReviewMeasures";
import CustomerReviewStatus from "../CustomerReviewStatus/CustomerReviewStatus";
import DisplayTagsWrapper from "../DisplayTagsWrapper/DisplayTagsWrapper";
import ReviewCard from "../ReviewCard/ReviewCard";
import ReviewChecklistAnswers from "../ReviewChecklistAnswers/ReviewChecklistAnswers";
import Risk from "../Risk/Risk";

export type CustomerReviewCardProps = {
  customer: CustomerDetails;
  review: Review;
  readonly?: boolean;
  additionalInfoLabel?: string;
  onChange(review: Review): void;
};

const CustomerReviewCard = forwardRef<HTMLDivElement, CustomerReviewCardProps>((props, ref) => {
  const { customer, review, additionalInfoLabel, readonly, onChange } = props;

  const { isPending } = useCustomerReview(review);

  const f = useFormatMessage();
  const features = useFeatures();

  const isRejected = isReviewRejected(review);

  const status: BannerStatus | undefined =
    isPending || isNil(review.accepted) || isRejected ? undefined : review.accepted ? "success" : "critical";

  const handleMeasuresChange = (measures: api.Measure[]) => onChange({ ...review, measures });

  return (
    <ReviewCard
      ref={ref}
      reviewStatus={status}
      reviewDate={review.date}
      reviewedBy={review.reviewed_by}
      approvedBy={review.approval?.approved_by}
      isRejected={isRejected}
      additionalTitleContent={
        <>
          <CustomerReviewCardPendingLabel customer={customer} review={review} readonly={readonly} />
          <CustomerReviewApprovalReason customerId={customer.id} reviewId={review.id} />
        </>
      }
      reviewHeader={
        <HorizontalStack gap="8" blockAlign={"center"}>
          <CustomerReviewStatus review={review} />
          {review.risk_level && <Risk risk={review.risk_level} labelSuffix={f("common.labels.customer.risk")} />}
          <Badges isPep={review.is_pep} isSanctioned={review.is_sanctioned} />
          {additionalInfoLabel && <Text as="span">{additionalInfoLabel}</Text>}
          <DisplayTagsWrapper tags={review.labels} />
          <AssignmentInfo assignmentName={review.assignment?.name} assignmentPurpose={review.assignment?.purpose} />
        </HorizontalStack>
      }
      reviewContent={
        <VerticalStack gap="2">
          <ReviewChecklistAnswers checklistAnswers={review.checklist_answers} />
          <Text as={"p"} breakWord>
            {review.comment || ""}
          </Text>
        </VerticalStack>
      }
    >
      {features.MEASURES && (
        <CustomerReviewMeasures
          customerId={customer.id}
          reviewId={review.id}
          measures={review.measures}
          onChange={handleMeasuresChange}
          readonly={readonly || isRejected}
        />
      )}
    </ReviewCard>
  );
});

export default CustomerReviewCard;
