// add separator lines between all stack items that are not the first or the last
import styled from "styled-components";

const SeparatorStackWrapper = styled.div`
  & > .Polaris-Stack > .Polaris-Stack__Item + .Polaris-Stack__Item {
    border-left: var(--p-border-divider);
    padding-left: var(--p-space-4);
  }
`;

export default SeparatorStackWrapper;
