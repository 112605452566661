import React from "react";
import { Stack, Text } from "@shopify/polaris";

import api from "../../../../../api";
import useFormatMessage from "../../../../../hooks/useFormatMessage";
import { MessageKey } from "../../../../../providers/LanguageProvider";
import Icon from "../../../../extensions/Icon";
import { AttachmentSignatureStatusIconsMap } from "../../../AttachmentsSection.constants";

const AttachmentSignatureStatusTranslationKeys: Record<api.AttachmentSignatureStatus, MessageKey> = {
  PENDING: "attachments.labels.signature-status.pending",
  EXPIRED: "attachments.labels.signature-status.expired",
  SIGNED: "attachments.labels.signature-status.signed",
  INVALID_FOR_SIGNING: "attachments.labels.signature-status.unsignable"
};

interface AttachmentSignatureStatusLabelProps {
  status?: api.AttachmentSignatureStatus;
}

const AttachmentSignatureStatusLabel = (props: AttachmentSignatureStatusLabelProps) => {
  const { status } = props;

  const f = useFormatMessage();

  if (!status) {
    return <Text as="span">-</Text>;
  }

  const icon = AttachmentSignatureStatusIconsMap[status];

  return (
    <Stack spacing="extraTight">
      <Stack.Item>
        <Icon source={icon} />
      </Stack.Item>
      <Stack.Item>{f(AttachmentSignatureStatusTranslationKeys[status])}</Stack.Item>
    </Stack>
  );
};

export default AttachmentSignatureStatusLabel;
