import React, { useState } from "react";
import FlipMove from "react-flip-move";
import {
  ActionListItemDescriptor,
  Card,
  EmptyState,
  HorizontalStack,
  Spinner,
  Stack,
  Text,
  VerticalStack
} from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";

import EditIcon from "../../../assets/icons/pen-line.svg";
import DeleteIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import ActionsDropdown from "../../components/ActionsDropdown/ActionsDropdown";
import AddButton from "../../components/AddButton/AddButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import CondensedText from "../../components/CondensedText/CondensedText";
import ConfirmAction from "../../components/ConfirmAction/ConfirmAction";
import Badge from "../../components/extensions/Badge";
import Button from "../../components/extensions/Button";
import CardSection from "../../components/extensions/CardSection";
import MeasureEditModal from "../../components/MeasureEditModal/MeasureEditModal";
import MoveButtons, { Direction } from "../../components/MoveButtons/MoveButtons";
import SettingsPage from "../../components/SettingsPage/SettingsPage";
import { PERMISSIONS_TYPES } from "../../constants/permissions";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import useQueryData from "../../hooks/useQueryData";
import { swapElements } from "../../utils/collectionUtils";
import { noop } from "../../utils/util";

const EMPTY_MEASURES: api.GlobalMeasureConfigList = { measure_configs: [] };

export const EMPTY_MEASURE: api.MeasureConfig = {
  id: "",
  title: "",
  description: ""
};

interface SettingsMeasuresProps {
  measuresList: api.GlobalMeasureConfigList;
  readonly?: boolean;
  loading?: boolean;
}

const SettingsMeasures = (props: SettingsMeasuresProps) => {
  const { measuresList, readonly, loading } = props;

  const [isShowingArchivedMeasures, toggleShowArchivedMeasures] = useOpenClose();
  const [isModalOpen, toggleModalOpen, closeModal] = useOpenClose();
  const [isDeleteConfirmOpen, toggleDeleteConfirm, closeDeleteConfirm] = useOpenClose();
  const [currentMeasure, setCurrentMeasure] = useState(EMPTY_MEASURE);

  const { updateQueryData } = useQueryData<api.GlobalMeasureConfigList>([QUERIES_KEYS.MEASURES_SETTINGS]);
  const f = useFormatMessage();
  const { isPermittedTo } = usePermissions();

  const measures = isShowingArchivedMeasures
    ? measuresList.measure_configs
    : measuresList.measure_configs.filter((measureConfig) => !measureConfig.is_archived);

  const isNewMeasure = !currentMeasure.id;

  const saveMeasureMutation = useMutation<api.MeasureConfig, unknown, api.CreateUpdateGlobalMeasureConfigRequest>(
    ({ title, description }) => {
      return isNewMeasure
        ? api.createGlobalMeasureConfig({ title, description })
        : api.updateGlobalMeasureConfig(currentMeasure.id, { title, description });
    },
    {
      onSuccess: (updatedMeasure) => {
        if (isNewMeasure) {
          updateQueryData((queryData) => (queryData.measure_configs = [updatedMeasure, ...queryData.measure_configs]));
        } else {
          updateQueryData(
            (queryData) =>
              (queryData.measure_configs = queryData.measure_configs.map((measure) =>
                measure.id === updatedMeasure.id ? updatedMeasure : measure
              ))
          );
        }
        closeAndClearModal();
      }
    }
  );

  const moveMeasureMutation = useMutation<unknown, unknown, { measure: api.MeasureConfig; direction: Direction }>(
    ({ measure, direction }) =>
      api.moveGlobalMeasureConfig(measure.id, { direction: direction === "up" ? "UP" : "DOWN" }),
    {
      onMutate: ({ measure, direction }) => {
        const index = measures.indexOf(measure);
        const targetIndex = index + (direction === "up" ? -1 : 1);

        updateQueryData(
          (queryData) => (queryData.measure_configs = swapElements(queryData.measure_configs, index, targetIndex))
        );
      }
    }
  );

  const disabled = readonly || !isPermittedTo(PERMISSIONS_TYPES.EDIT_SETTINGS);

  const deleteMeasureMutation = useMutation(api.archiveGlobalMeasureConfig, {
    onSuccess: (response, deletedMeasureId) => {
      updateQueryData(
        (queryData) =>
          (queryData.measure_configs = queryData.measure_configs.filter((measure) => measure.id !== deletedMeasureId))
      );
    }
  });

  const closeAndClearModal = () => {
    closeModal();
    setCurrentMeasure(EMPTY_MEASURE);
    saveMeasureMutation.reset();
  };

  const editMeasure = (measure: api.MeasureConfig) => {
    setCurrentMeasure(measure);
    toggleModalOpen();
  };

  const getMeasureActions = (measure: api.MeasureConfig): ActionListItemDescriptor[] =>
    measure.is_archived
      ? []
      : [
          {
            content: disabled ? f("default.view") : f("default.edit"),
            image: EditIcon,
            disabled: readonly,
            onAction: () => (readonly ? noop : editMeasure(measure))
          },
          {
            content: f("default.archive"),
            image: DeleteIcon,
            disabled: disabled,
            onAction: () => {
              if (!disabled) {
                setCurrentMeasure(measure);
                toggleDeleteConfirm();
              }
            }
          }
        ];

  const filterUsersLabel = isShowingArchivedMeasures
    ? f("measures-settings.page.filter.hide-archived-measures")
    : f("measures-settings.page.filter.show-archived-measures");

  return (
    <SettingsPage
      readonly={readonly}
      title={f("measures-settings.page.title")}
      subtitle={f("measures-settings.page.description")}
      primaryAction={
        <AddButton onClick={toggleModalOpen} disabled={disabled}>
          {f("measures.modal.title.add")}
        </AddButton>
      }
    >
      <Card>
        {measures.length > 0 && (
          <>
            <Card.Section>
              <Stack spacing={"extraLoose"}>
                <Stack.Item fill>
                  <CondensedText>{f("table.column.measure")}</CondensedText>
                </Stack.Item>
                <CondensedText>{f("common.buttons.actions.button").toUpperCase()}</CondensedText>
              </Stack>
            </Card.Section>
            <FlipMove enterAnimation={false} leaveAnimation={false} appearAnimation={false}>
              {measures.map((measure, index) => (
                <StyledCardSection key={measure.id}>
                  <Stack wrap={false} spacing={"extraLoose"}>
                    <Stack.Item fill>
                      <StyledMeasureConfigRow
                        onClick={measure.is_archived ? noop : () => editMeasure(measure)}
                        disabled={measure.is_archived}
                      >
                        <VerticalStack gap="4">
                          <HorizontalStack gap={measure.is_archived ? "2" : "0"}>
                            <Text variant="bodyMd" as="span" fontWeight="semibold">
                              {measure.title}
                            </Text>
                            {measure.is_archived && <Badge size="xsmall">{f("badge.archived")}</Badge>}
                          </HorizontalStack>
                          <Text as="span" color={measure.is_archived ? "subdued" : undefined}>
                            <DescriptionText>{measure.description}</DescriptionText>
                          </Text>
                        </VerticalStack>
                      </StyledMeasureConfigRow>
                    </Stack.Item>
                    <HorizontalStack gap="4" blockAlign="center" wrap={false}>
                      <ActionsDropdown items={getMeasureActions(measure)} hideTitle />
                      {!measure.is_archived && (
                        <MoveButtons
                          readonly={disabled}
                          disableMoveUp={index === 0}
                          disableMoveDown={index === measures.length - 1 || measures[index + 1]?.is_archived}
                          onOrderChange={(direction) => moveMeasureMutation.mutate({ measure, direction })}
                        />
                      )}
                    </HorizontalStack>
                  </Stack>
                </StyledCardSection>
              ))}
            </FlipMove>
          </>
        )}
        {!loading && measures.length === 0 && <EmptyState image="" heading={f("measures.modal.empty-state")} />}
        {loading && (
          <EmptyState image="" heading={f("default.loading")}>
            <Spinner />
          </EmptyState>
        )}
      </Card>
      <HorizontalStack>
        <Button onClick={toggleShowArchivedMeasures} disclosure={isShowingArchivedMeasures ? "up" : "down"}>
          {filterUsersLabel}
        </Button>
      </HorizontalStack>
      {isModalOpen && (
        <MeasureEditModal
          measure={currentMeasure}
          existingMeasures={measures}
          isNewMeasure={isNewMeasure}
          readonly={disabled}
          isLoading={saveMeasureMutation.isLoading}
          error={saveMeasureMutation.error}
          onSave={(measure) => saveMeasureMutation.mutate(measure)}
          onClose={closeAndClearModal}
        />
      )}
      {isDeleteConfirmOpen && (
        <ConfirmAction
          apiCall={() => deleteMeasureMutation.mutateAsync(currentMeasure.id)}
          title={f("measures.confirm-archive.title")}
          description={f("measures.confirm-archive.description", {
            name: currentMeasure.title
          })}
          onNo={() => {
            closeDeleteConfirm();
            setCurrentMeasure(EMPTY_MEASURE);
          }}
          actionTitle={f("measures.confirm-archive.labels.archive.button")}
        />
      )}
    </SettingsPage>
  );
};

const StyledCardSection = styled(CardSection)`
  border-top: var(--p-border-divider);
  transition: background-color var(--p-duration-200) var(--p-ease-in-out);

  &:hover {
    background-color: var(--p-surface-hovered);
  }
`;

const StyledMeasureConfigRow = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

const DescriptionText = styled.div`
  white-space: pre;
`;

const SettingsMeasuresPage = ({ data }: AsyncPageChild<api.GlobalMeasureConfigList>) => {
  return <SettingsMeasures measuresList={data} />;
};

export default withAsyncPage<api.GlobalMeasureConfigList>(SettingsMeasuresPage, {
  queryKey: QUERIES_KEYS.MEASURES_SETTINGS,
  apiFunction: api.getGlobalMeasureConfigs,
  paramNames: [],
  skeleton: <SettingsMeasures readonly loading measuresList={EMPTY_MEASURES} />
});
