import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { updateItem } from "../utils/collectionUtils";

import useQueryData from "./useQueryData";

const useChecklistRestoreMutation = () => {
  const queryClient = useQueryClient();

  const { updateQueryData } = useQueryData<api.ReviewChecklistListResponse>([QUERIES_KEYS.REVIEW_CHECKLISTS]);

  const restoreChecklistMutation = useMutation(api.restoreReviewChecklist, {
    onSuccess: (restoredReviewChecklist) => {
      updateQueryData((queryData) => {
        queryData.checklists = updateItem(queryData.checklists, restoredReviewChecklist);
      });
      queryClient.invalidateQueries([QUERIES_KEYS.REVIEW_CHECKLISTS]);
    }
  });

  return restoreChecklistMutation;
};

export default useChecklistRestoreMutation;
