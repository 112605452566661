import { generatePath, useHistory } from "react-router-dom";
import { ActionListItemDescriptor, Spinner } from "@shopify/polaris";
import { PlusMinor } from "@shopify/polaris-icons";
import { useMutation } from "@tanstack/react-query";

import ArchiveIcon from "../../../assets/icons/archivebox.svg";
import EditIcon from "../../../assets/icons/pencil.svg";
import RestoreIcon from "../../../assets/icons/restore.svg";
import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useQueryData, { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import { updateItem } from "../../utils/collectionUtils";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import AssignmentArchiveConfirmation from "../AssignmentArchiveConfirmation/AssignmentArchiveConfirmation";
import AssignmentModal from "../AssignmentModal/AssignmentModal";

type AssignmentActionsMenuProps = {
  assignment: api.CustomerAssignmentDetails;
  readonly?: boolean;
};

const AssignmentActionsMenu = (props: AssignmentActionsMenuProps) => {
  const { assignment, readonly } = props;

  const customerId = assignment.customer_id;

  const f = useFormatMessage();
  const history = useHistory();

  const [isEditAssignment, toggleEditAssignment, closeEditAssignment] = useOpenClose();
  const [showArchiveConfirm, toggleArchiveConfirm, closeArchiveConfirm] = useOpenClose();

  const { updateQueryData } = useQueryData<api.CustomerAssignmentDetails>([
    QUERIES_KEYS.ASSIGNMENT_DETAILS,
    customerId,
    assignment.id
  ]);
  const updateCustomerQueryData = useCustomerDetailsQueryData({ id: customerId, type: assignment.customer_type });

  const restoreAssignmentMutation = useMutation(() => api.restoreCustomerAssignment(customerId, assignment.id), {
    onSuccess: async (updatedAssignment) => {
      updateQueryData((queryData) => Object.assign(queryData, updatedAssignment));
      updateCustomerQueryData(
        (customerData) => (customerData.assignments = updateItem(customerData.assignments!, updatedAssignment))
      );
    }
  });

  const actions: ActionListItemDescriptor[] = [
    {
      content: f("common.buttons.actions.edit-assignment"),
      image: EditIcon,
      disabled: readonly,
      onAction: () => toggleEditAssignment()
    },
    {
      content: f("common.buttons.actions.new-assignment-disclosure"),
      icon: PlusMinor,
      disabled: readonly,
      onAction: () =>
        history.push(
          generatePath(ROUTES.COMPANY_ASSIGNMENT_REQUEST_DISCLOSURES, { id: customerId, assignmentId: assignment.id })
        )
    }
  ];

  if (assignment.is_archived) {
    actions.push({
      content: f("common.buttons.actions.restore-assignment"),
      onAction: () => restoreAssignmentMutation.mutateAsync(),
      image: restoreAssignmentMutation.isLoading ? <Spinner size={"small"} /> : RestoreIcon
    });
  } else {
    actions.push({
      content: f("common.buttons.actions.archive-assignment"),
      onAction: toggleArchiveConfirm,
      image: ArchiveIcon
    });
  }

  return (
    <>
      <ActionsDropdown
        items={actions}
        title={f("common.buttons.actions.button")}
        primary
        size={"medium"}
        forceOpen={restoreAssignmentMutation.isLoading}
      />
      {isEditAssignment && (
        <AssignmentModal
          assignment={assignment}
          customerId={customerId}
          onCancel={closeEditAssignment}
          onSave={(updatedAssignment) => {
            updateCustomerQueryData(
              (queryData) =>
                (queryData.assignments = queryData.assignments?.map((assignment) =>
                  assignment.id === updatedAssignment.id ? updatedAssignment : assignment
                ))
            );
            updateQueryData((queryData) => queryData);
            closeEditAssignment();
          }}
        />
      )}
      {showArchiveConfirm && <AssignmentArchiveConfirmation assignment={assignment} onClose={closeArchiveConfirm} />}
    </>
  );
};

export default AssignmentActionsMenu;
