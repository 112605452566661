import React, { PropsWithChildren } from "react";
import { HorizontalStack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { getBirthDate, getFullName } from "../../helpers/display.helpers";
import { isManuallyCreated, isUBO } from "../../helpers/person.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import Badges from "../Badges/Badges";
import CondensedText from "../CondensedText/CondensedText";
import DisplayName, { DisplayNameProps } from "../DisplayName/DisplayName";
import Badge from "../extensions/Badge";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

import { isManuallyUpdated } from "./PersonName.helpers";
import ScreeningType from "./ScreeningType";

export type Subtitle = { subtitle: string; dateString: string };

export type PersonNameProps = Omit<DisplayNameProps, "birthDate" | "comment"> & {
  entity: api.Role | api.Owner;
  deleted: boolean;
  additionalSubtitles?: Subtitle[];
  screeningType?: api.ScreeningType;
  uboTooltip?: string;
};

const PersonName = (props: PropsWithChildren<PersonNameProps>) => {
  const { entity, deleted, additionalSubtitles = [], screeningType, uboTooltip, children, ...displayNameProps } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const subtitles: Subtitle[] = [...additionalSubtitles];
  if (isManuallyCreated(entity) && entity.created_dt) {
    subtitles.push({
      subtitle: f("person-name.description.manually-created", {
        name: getUserDisplayName(entity.created_by),
        timestamp: new Date(entity.created_dt)
      }),
      dateString: entity.created_dt
    });
  } else if (isManuallyUpdated(entity) && entity.updated_dt) {
    subtitles.push({
      subtitle: f("common.labels.modified-by", {
        name: getUserDisplayName(entity.updated_by),
        timestamp: new Date(entity.updated_dt)
      }),
      dateString: entity.updated_dt
    });
  }

  // sort subtitles based on their dates
  subtitles.sort((entryA, entryB) => new Date(entryA.dateString).getTime() - new Date(entryB.dateString).getTime());

  const updateInfoSubtitles =
    subtitles.length === 0 ? undefined : (
      <VerticalStack>
        {subtitles.map(({ subtitle }) => (
          <CondensedText italic key={subtitle}>
            {subtitle}
          </CondensedText>
        ))}
      </VerticalStack>
    );

  return (
    <StyledPersonNameWrapper subdued={deleted}>
      <HorizontalStack gap="2" wrap={false} blockAlign="center">
        {screeningType && <ScreeningType screeningType={screeningType} />}
        <DisplayName
          birthDate={getBirthDate(entity.person)}
          comment={entity.comment}
          subtitle={updateInfoSubtitles}
          type={displayNameProps.type || entity.person ? "person" : undefined}
          {...displayNameProps}
          name={displayNameProps.name || getFullName(entity.person)}
        >
          <HorizontalStack gap="1">
            {deleted && <Badge size="xsmall">{f("default.expired")}</Badge>}
            {isUBO(entity) && (
              <TooltipWrapper content={uboTooltip}>
                <Badge size="xsmall" status="warning">
                  {f("common.labels.ubo")}
                </Badge>
              </TooltipWrapper>
            )}
            <Badges isPep={entity.person?.is_pep} isSanctioned={entity.person?.is_sanctioned} />
            {children}
          </HorizontalStack>
        </DisplayName>
      </HorizontalStack>
    </StyledPersonNameWrapper>
  );
};

const StyledPersonNameWrapper = styled.div<{ subdued?: boolean }>`
  ${({ subdued }) => subdued && `opacity: 0.5; filter: grayscale(1);`}
`;

export default PersonName;
