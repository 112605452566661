import { useMutation } from "@tanstack/react-query";
import FileSaver from "file-saver";

import api from "../api";
import { convertQuestionnaireToCreateQuestionnaireRequest } from "../helpers/questionnaire.helpers";
import { convertToValidFilename } from "../utils/filenameUtils";

const useExportQuestionnaireMutation = () => {
  const exportQuestionnaire = async (questionnaire: api.Questionnaire) => {
    const questionnaireRequest = convertQuestionnaireToCreateQuestionnaireRequest(questionnaire);

    const questionnaireExportString = JSON.stringify(questionnaireRequest, null, "\t");

    const filename = convertToValidFilename(questionnaire.name) || "questionnaire";

    // @ts-ignore
    if (window.showSaveFilePicker) {
      const myblob = new Blob([questionnaireExportString], { type: "text/plain" });
      const opts = {
        types: [
          {
            description: "Questionnaire",
            accept: { "text/plain": [".json"] }
          }
        ],
        suggestedName: filename
      };
      // @ts-ignore
      const handle = await window.showSaveFilePicker(opts);
      // @ts-ignore
      const writable = await handle.createWritable();
      // @ts-ignore
      writable.write(myblob);

      writable.close();
    } else {
      const file = new File([questionnaireExportString], `${filename}.json`, { type: "text/plain" });
      FileSaver.saveAs(file);
    }
  };

  return useMutation(exportQuestionnaire);
};

export default useExportQuestionnaireMutation;
