import React from "react";
import { Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { getRiskDescriptions } from "../../helpers/disclosures.helpers";
import useTranslatedText from "../../hooks/useTranslatedText";
import FilesetActions from "../DisclosureDocumentSection/DisclosureDocumentSignature/FilesetActions";
import InfoTable, { InfoTableProps, InfoTableRow } from "../InfoTable/InfoTable";

import DisclosureAnswer from "./DisclosureAnswer";

type DisclosureAnswersProps = Omit<InfoTableProps, "rows"> & {
  disclosureSections: api.DisclosureAnswerSection[];
};

const DisclosureAnswers = (props: DisclosureAnswersProps) => {
  const { disclosureSections, ...infoTableProps } = props;

  const t = useTranslatedText();

  const rows: InfoTableRow[] = [];

  disclosureSections.forEach((disclosureSection) => {
    const header = disclosureSection.title && t(disclosureSection.title);

    if (header) {
      rows.push({ label: header, value: "", isHeading: true });
    }

    disclosureSection.answers.forEach((answer) => {
      const label = t(answer.question.text);
      const value = <DisclosureAnswer answer={answer} />;

      rows.push({
        label,
        value,
        isWarning: answer.risk_indicators !== undefined && answer.risk_indicators.length > 0,
        warningDescription: (
          <VerticalStack gap="2">
            {answer.risk_indicators?.flatMap(getRiskDescriptions).map((description, index) => (
              <Text as="span" key={index}>
                {t(description)}
              </Text>
            ))}
          </VerticalStack>
        ),
        suffix:
          answer.uploaded_documents && answer.uploaded_documents.length > 0 ? (
            <FilesetActions filesets={answer.uploaded_documents} />
          ) : undefined
      });
    });
  });

  return <InfoTable rows={rows} showActionsColumn {...infoTableProps} />;
};

export default DisclosureAnswers;
