import React from "react";
import { Toast } from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useTags from "../../hooks/useTags";
import { getIndeterminateSelection } from "../../utils/selectionUtils";
import TagsEditorPopover from "../TagsEditorPopover/TagsEditorPopover";

type EntityWithLabels = {
  id: string;
  labels?: api.Label[];
};

export type AssignedTags = {
  entitiesIds: string[];
  tagIds: string[];
};

/**
 * This component handles tags selection for a collection of entities
 * (an entity is an object with an id and a list of tags, for example a Customer or a Project Customer)
 *
 * It handles splitting the selected entities tags into selected tags (tags that are selected
 * by all the entities) and partially selected tags (tags which are only select by some entities)
 *
 * It accepts 3 event handlers (onUpdateEntities, onAssignTags and onCreatedAndAssignTag) that
 * provide APIs for updating the entities, assigning tags to entities and creating a new tags for entities
 */
interface EntitiesTagPopoverProps<T extends EntityWithLabels> {
  entities: T[];
  readonly?: boolean;
  onUpdateEntities(updatedEntities: T[]): void;
  onAssignTags(props: AssignedTags): Promise<T[]>;
  onCreatedAndAssignTag(ids: string[], text: api.TranslationText): Promise<T[]>;
}

function EntitiesTagPopover<T extends EntityWithLabels>(props: EntitiesTagPopoverProps<T>) {
  const { entities, readonly, onAssignTags, onCreatedAndAssignTag, onUpdateEntities } = props;

  const entitiesIds = entities.map((entity) => entity.id);
  const { selectedItems: selectedTags, partiallySelectedItems: partiallySelectedTags } = getIndeterminateSelection(
    entities,
    (entity) => entity.labels
  );

  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const { allTags } = useTags();
  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();

  const assignTagMutation = useMutation(onAssignTags, {
    onSuccess: (updatedEntities) => {
      toggleSuccessMessageToast();
      onUpdateEntities(updatedEntities);
    }
  });

  const createAndAssignTagMutation = useMutation<T[], unknown, api.TranslationText>(
    (text) => onCreatedAndAssignTag(entitiesIds, text),
    {
      onSuccess: async (updatedEntities) => {
        await queryClient.invalidateQueries([QUERIES_KEYS.ALL_TAGS]);
        onUpdateEntities(updatedEntities);
      }
    }
  );

  const handleSelectionChange = async (selectedTags: api.Label[]) => {
    const selectedTagIds = selectedTags.map((tag) => tag.id);
    // remove selected tags from the partiallySelectedTags because a selected tag means it's not partial anymore
    const partiallySelectedTagIds = partiallySelectedTags
      .filter((tag) => !selectedTagIds.includes(tag.id))
      .map((tag) => tag.id);

    // if there are no partially selected tags, set all the selected tags to all the entities in a single mutation
    if (partiallySelectedTagIds.length === 0) {
      assignTagMutation.mutate({ entitiesIds, tagIds: selectedTagIds });
    } else {
      // there is at least one entity with tags that were partially selected (which means they will not
      // be included in the selectedTags)
      // in order to avoid removing these tags from the entities,
      // set entities with tags that are partially selected in a different mutation that also includes their tags

      const mutations: Array<Promise<T[]>> = [];
      const entitiesWithoutPartialtagIds: string[] = [];

      entities.forEach((entity) => {
        // if the entity has a tag that's in partiallySelected tagIds it means this tag should be included
        // when setting tags for that entity
        if (entity.labels?.find((tag) => partiallySelectedTagIds.includes(tag.id))) {
          const tags = entity.labels || [];
          // tags for entity with partially selected tag(s)
          // includes: all selected tags, partially selected tags that were already assigned to the entity
          const tagIds = [
            ...selectedTagIds,
            ...tags.filter((tag) => partiallySelectedTagIds.includes(tag.id)).map((tag) => tag.id)
          ];

          mutations.push(
            assignTagMutation.mutateAsync({
              entitiesIds: [entity.id],
              tagIds: tagIds
            })
          );
        } else {
          // the entity does not have a tag that's in partiallySelectedTagIds
          // which means it could be set all the selected tags (without adding any previous tags)
          entitiesWithoutPartialtagIds.push(entity.id);
        }
      });

      // if there are entities without partial selected tags, add another mutation to set the selected tags
      // for all of them
      if (entitiesWithoutPartialtagIds.length > 0) {
        mutations.push(
          assignTagMutation.mutateAsync({
            entitiesIds: entitiesWithoutPartialtagIds,
            tagIds: selectedTagIds
          })
        );
      }

      await Promise.all(mutations);
    }
  };

  const createTagAndAssign = async (text: api.TranslationText) => await createAndAssignTagMutation.mutateAsync(text);

  const isLoading = assignTagMutation.isLoading || createAndAssignTagMutation.isLoading;

  return (
    <>
      <TagsEditorPopover
        tags={allTags}
        selectedTags={selectedTags}
        partiallySelectedTags={partiallySelectedTags}
        onSelectionChange={(selectedTags) => handleSelectionChange(selectedTags)}
        onNewTag={createTagAndAssign}
        size="slim"
        disabled={readonly || isLoading}
      />
      {showSuccessMessageToast && (
        <Toast onDismiss={closeSuccessMessageToast} content={f("tags.popover.success.message")} />
      )}
    </>
  );
}

export default EntitiesTagPopover;
