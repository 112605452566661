import api from "../api";
import { Note } from "../types/Notes";

export const convertNotationToNote = (customerNotation: api.CustomerNotation | api.CustomerReviewNotation): Note => ({
  id: customerNotation.id,
  text: customerNotation.comment,
  user: customerNotation.user,
  date: customerNotation.updated_dt || customerNotation.created_dt
});

export const convertPreviewReviewToNote = (projectReview: api.ProjectReview): Note => ({
  id: projectReview.id,
  text: projectReview.comment,
  user: projectReview.reviewed_by,
  date: projectReview.created_dt
});

export const sortByNoteDate = (a1: Note, a2: Note) => new Date(a2.date).getTime() - new Date(a1.date).getTime();
