import api from "../api";
import { isEmptyString } from "../utils/stringUtils";

export const convertAssignmentToUpdateRequest = (assignment: api.Assignment): api.CreateOrUpdateAssignmentRequest => {
  const { name, description, industry_type, purpose, purpose_desc, responsible_users, start_date, end_date } =
    assignment;

  const responsibleUsersIds = responsible_users.map((user) => user.id);

  return {
    name,
    description,
    industry_type,
    purpose,
    purpose_desc,
    start_date,
    end_date,
    responsible_users: responsibleUsersIds
  };
};

export const sortAssignmentsByDate = (a1: api.Assignment, a2: api.Assignment) =>
  new Date(a2.created_dt).getTime() - new Date(a1.created_dt).getTime();

export const isInvalidAssignment = (assignment: api.Assignment | api.CreateOrUpdateAssignmentRequest) =>
  isEmptyString(assignment.name) || isEmptyString(assignment.description);

export const isActiveAssignment = (assignment: api.Assignment) => !assignment.is_archived;
