import React from "react";
import { Card } from "@shopify/polaris";

import GlobeIcon from "../../../assets/icons/globe.svg";
import MatchIcon from "../../../assets/icons/match.svg";
import useComplyAdvantageMatch from "../../hooks/useComplyAdvantageMatch";
import useFormatMessage from "../../hooks/useFormatMessage";
import { MatchDetailsProps } from "../../types/MatchDetails";
import { hasContent } from "../../utils/collectionUtils";
import AddressInfo from "../AddressInfo/AddressInfo";
import ComparisonTable from "../ComparisonTable/ComparisonTable";
import { createComparisonTableEntry } from "../ComparisonTable/ComparisonTable.helpers";
import ComparisonTableHeader from "../ComparisonTable/ComparisonTableHeader";
import Country from "../Country/Country";
import MatchAddressInfo from "../MatchAddressInfo/MatchAddressInfo";

const ComplyAdvantageMatchDetailsGeographicComparisonSection = (props: MatchDetailsProps) => {
  const { matchDetails } = props;

  const f = useFormatMessage();
  const {
    subjectCountries,
    matchCountries,
    subjectAddresses,
    matchAddresses,
    subjectNationalities,
    matchNationalities,
    hasNewInfo
  } = useComplyAdvantageMatch(matchDetails);

  const rows = [
    createComparisonTableEntry(
      f("common.labels.country"),
      subjectCountries.map((countyCode) => <Country country={countyCode} shortName />),
      matchCountries,
      (attribute) => <Country country={attribute?.value} shortName />,
      hasNewInfo,
      true
    ),
    createComparisonTableEntry(
      f("common.labels.address"),
      subjectAddresses.map((address) => <AddressInfo address={address} shortCountryName />),
      matchAddresses,
      (address) => <MatchAddressInfo address={address} />,
      hasNewInfo,
      true
    )
  ];

  if (hasContent(subjectNationalities)) {
    rows.push(
      createComparisonTableEntry(
        f("common.labels.nationality"),
        subjectNationalities.map((countyCode) => <Country country={countyCode} shortName />),
        matchNationalities,
        (attribute) => <Country country={attribute?.value} shortName />,
        hasNewInfo
      )
    );
  }

  return (
    <Card>
      <ComparisonTable
        headings={[
          <ComparisonTableHeader icon={GlobeIcon} label={f("matches.titles.geographic-affiliation")} />,
          <ComparisonTableHeader icon={MatchIcon} label={f("matches.titles.data-source")} />
        ]}
        rows={rows}
      />
    </Card>
  );
};

export default ComplyAdvantageMatchDetailsGeographicComparisonSection;
