import { HorizontalStack, Label, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import TrashIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import { isChecklistEmpty } from "../../helpers/checklists.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useTranslatedText from "../../hooks/useTranslatedText";
import { OptionalChecklistQuestionAnswerRequest } from "../../types/ReviewChecklist";
import Button from "../extensions/Button";
import ReviewChecklistAnswerForm from "../ReviewChecklistAnswerForm/ReviewChecklistAnswerForm";
import ReviewChecklistRemoveConfirm from "../ReviewChecklistRemoveConfirm/ReviewChecklistRemoveConfirm";

type ReviewChecklistFormProps = {
  checklist: api.ReviewChecklist;
  answers: Map<string, OptionalChecklistQuestionAnswerRequest>;
  readonly?: boolean;
  onChange(questionId: string, answer: boolean, text?: string): void;
  onRemove?(): void;
};

const ReviewChecklistForm = (props: ReviewChecklistFormProps) => {
  const { checklist, answers, readonly, onChange, onRemove } = props;

  const f = useFormatMessage();
  const t = useTranslatedText();

  const [showConfirm, toggleShowConfirm, closeConfirm] = useOpenClose();

  const onConfirmRemove = () => {
    closeConfirm();
    onRemove && onRemove();
  };

  return (
    <VerticalStack gap="4">
      <HorizontalStack align={"space-between"} blockAlign={"start"} wrap={false}>
        <VerticalStack gap="1">
          <Label id="" requiredIndicator={checklist.is_required}>
            <Text as="span" variant={"headingMd"}>
              {checklist.name}
            </Text>
          </Label>
          <Text as={"p"} color={"subdued"}>
            {checklist.description}
          </Text>
        </VerticalStack>
        {onRemove && (
          <StyledRemoveButton
            icon={TrashIcon}
            disabled={readonly || checklist.is_required}
            onClick={isChecklistEmpty(answers) ? onRemove : toggleShowConfirm}
          >
            {f("default.remove")}
          </StyledRemoveButton>
        )}
      </HorizontalStack>
      {checklist.questions.map((question) => (
        <ReviewChecklistAnswerForm
          label={t(question.text)}
          answer={answers.get(question.id!)?.answer}
          text={answers.get(question.id!)?.additional_info}
          readonly={readonly}
          onChange={(answer, text) => onChange(question.id!, answer, text)}
          key={question.id}
        />
      ))}
      {showConfirm && (
        <ReviewChecklistRemoveConfirm checklistName={checklist.name} onYes={onConfirmRemove} onNo={closeConfirm} />
      )}
    </VerticalStack>
  );
};

const StyledRemoveButton = styled(Button).attrs({ plain: true, monochrome: true, removeUnderline: true })`
  // prevent button from shrinking in case the checklist description is too long
  flex: 0;
`;

export default ReviewChecklistForm;
