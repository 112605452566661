import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import CompletedIcon from "../../../assets/icons/checkmark-circle-filled-success-dark.svg";
import WarningIcon from "../../../assets/icons/triangle-filled-exclamation-monochrome.svg";
import api from "../../api";
import Icon from "../extensions/Icon";
import KDKBanner from "../KDKBanner/KDKBanner";

type CustomerReviewMeasureTitleProps = {
  measure: api.Measure;
};
const CustomerReviewMeasureTitle = (props: CustomerReviewMeasureTitleProps) => {
  const { measure } = props;

  const statusIcon = measure.is_completed ? CompletedIcon : WarningIcon;

  return (
    <StyledKDKBanner status={measure.is_completed ? "success" : "warning"} hideIcon>
      <HorizontalStack gap="1" wrap={false} blockAlign={"center"}>
        <Icon source={statusIcon} />
        <Text as="span" fontWeight="semibold" breakWord>
          {measure.title}
        </Text>
      </HorizontalStack>
    </StyledKDKBanner>
  );
};

const StyledKDKBanner = styled(KDKBanner)`
  & > .Polaris-Banner--withinContentContainer {
    padding: var(--p-space-1) var(--p-space-2) var(--p-space-05) var(--p-space-2);
  }
`;

export default CustomerReviewMeasureTitle;
