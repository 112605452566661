import { PersonInformation } from "../types/utilities";

export const MIN_BIRTH_DATE = new Date("1900-01-01");
export const MAX_BIRTH_DATE = new Date();

export const REQUIRED_FIELDS_FOR_NEW_PERSON: Array<keyof PersonInformation> = [
  "country_of_residence",
  "country_of_citizenship"
];

// technically it could be 254 but 250 is simpler
export const MAX_LENGTH_OF_TEXT_INPUT_FIELD = 250;
