import { useState } from "react";

import useDebounce from "./useDebounce";

function useDebounceValue<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDebounce(() => setDebouncedValue(value), delay, [value]);

  return debouncedValue;
}

export default useDebounceValue;
