import React from "react";
import { Card, DataTable, Page, SkeletonBodyText, VerticalStack } from "@shopify/polaris";

import AddButton from "../../components/AddButton/AddButton";
import DataTableCellChild from "../../components/DataTableCellChild/DataTableCellChild";
import useFormatMessage from "../../hooks/useFormatMessage";
import useSettingsPage from "../../hooks/useSettingsPage";

const SettingsTagsSkeleton = () => {
  const f = useFormatMessage();

  useSettingsPage();

  return (
    <Page title={f("tags.page.title")} primaryAction={<AddButton disabled>{f("tags.modal.title.add-tag")}</AddButton>}>
      <VerticalStack gap="4">
        <Card>
          <DataTable
            columnContentTypes={["text", "numeric"]}
            headings={[f("table.column.name"), ""]}
            rows={Array(4).fill([
              <SkeletonBodyText lines={1} />,
              <DataTableCellChild width="10%">
                <SkeletonBodyText lines={1} />
              </DataTableCellChild>
            ])}
          />
        </Card>
      </VerticalStack>
    </Page>
  );
};

export default SettingsTagsSkeleton;
