import { HorizontalStack, Tag as PolarisTag } from "@shopify/polaris";

import { AppliedFilter } from "../../helpers/filters.helpers";
import Tag from "../Tag/Tag";

type AppliedFiltersProps = {
  appliedFilters: AppliedFilter[];
};

const AppliedFilters = (props: AppliedFiltersProps) => {
  const { appliedFilters } = props;

  return (
    <HorizontalStack gap="2" blockAlign="center">
      {appliedFilters.map((appliedFilter) =>
        appliedFilter.isTag ? (
          <Tag
            key={appliedFilter.key}
            color={appliedFilter.tagColor}
            onRemove={() => appliedFilter.onRemove(appliedFilter.key)}
          >
            {appliedFilter.label}
          </Tag>
        ) : (
          <PolarisTag key={appliedFilter.key} onRemove={() => appliedFilter.onRemove(appliedFilter.key)}>
            {appliedFilter.label}
          </PolarisTag>
        )
      )}
    </HorizontalStack>
  );
};

export default AppliedFilters;
