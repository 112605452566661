import api from "../../api";
import { convertOptionalChecklistAnswersRequestToChecklistAnswersRequest } from "../../helpers/checklists.helpers";
import { convertMeasureToCreateCustomMeasureRequest } from "../../helpers/measures.helpers";
import { OptionalCustomerReviewRequest } from "../../types/CustomerReview";
import { removeProperty } from "../../utils/util";

// do not pass review id in request when creating or updating
export const convertToCreateCustomerReviewRequest = (
  review: OptionalCustomerReviewRequest
): api.CreateCustomerReviewRequest => ({
  ...removeProperty(review, "id"),
  accepted: Boolean(review.accepted),
  measures: review.measures?.map(convertMeasureToCreateCustomMeasureRequest),
  checklist_answers: review.checklist_answers?.map(convertOptionalChecklistAnswersRequestToChecklistAnswersRequest)
});

// do not pass review id in request when creating or updating
// if risk_level is "NOT_SET" then pass undefined instead
export const convertToCreateDraftCustomerReviewRequest = (
  review: OptionalCustomerReviewRequest
): api.CreateDraftCustomerReviewRequest => ({
  ...removeProperty(review, "id"),
  risk_level: review.risk_level === "NOT_SET" ? undefined : review.risk_level,
  accepted: review.accepted,
  measures: review.measures?.map(convertMeasureToCreateCustomMeasureRequest),
  checklist_answers: review.checklist_answers?.map(convertOptionalChecklistAnswersRequestToChecklistAnswersRequest)
});
