import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { CustomerDetails } from "../types/utilities";

import useQueryData from "./useQueryData";

const useCustomerCreateNoteMutation = (customer: CustomerDetails) => {
  const { updateQueryData } = useQueryData<api.CustomerNotation[]>([QUERIES_KEYS.CUSTOMER_NOTES, customer.id]);

  const createNoteMutation = useMutation<api.CustomerNotation, unknown, string>(
    (comment) => api.createCustomerNotation(customer.id, { comment }),
    {
      onSuccess: (newNote: api.CustomerNotation) => {
        updateQueryData((prevNotes) => prevNotes.unshift(newNote));
      }
    }
  );

  return createNoteMutation;
};

export default useCustomerCreateNoteMutation;
