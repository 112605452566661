import React, { useState } from "react";
import { Modal, Text, Toast, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { getCustomerName } from "../../helpers/display.helpers";
import useBatchUpdateScreeningConfiguration from "../../hooks/useBatchUpdateScreeningConfiguration";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import useOpenClose from "../../hooks/useOpenClose";
import { Customer } from "../../types/utilities";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import ScreeningTypeCard from "../ScreeningTypeCard/ScreeningTypeCard";

type ChangeScreeningTypeModalProps = {
  customers: Customer[];
  onClose(): void;
};

const ChangeScreeningTypeModal = (props: ChangeScreeningTypeModalProps) => {
  const { customers, onClose } = props;

  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();
  const [screeningType, setScreeningType] = useState<api.ScreeningType>("MONITORING");

  const saveScreeningTypeMutation = useBatchUpdateScreeningConfiguration(customers);

  const saveAction = {
    content: f("default.save"),
    onAction: () => saveScreeningTypeMutation.mutate(screeningType, { onSuccess: toggleSuccessMessageToast }),
    loading: saveScreeningTypeMutation.isLoading
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: () => onClose(),
    disabled: saveScreeningTypeMutation.isLoading
  };

  const customersNamesLabel = formatMultipleNames(customers.map(getCustomerName));

  const handleToastDismiss = () => {
    closeSuccessMessageToast();
    onClose();
  };

  return (
    <>
      <Modal
        open={!showSuccessMessageToast}
        title={f("screening-type.modal.title")}
        onClose={onClose}
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
      >
        <Modal.Section>
          <VerticalStack gap="4">
            <Text variant="bodyMd" as="span">
              {f("screening-type.modal.message", { names: customersNamesLabel })}
            </Text>
            <ScreeningTypeCard
              screeningType={screeningType}
              readonly={saveScreeningTypeMutation.isLoading}
              onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
            />
          </VerticalStack>
        </Modal.Section>
        {saveScreeningTypeMutation.isError && <ErrorPanel message={saveScreeningTypeMutation.error} />}
      </Modal>
      {showSuccessMessageToast && (
        <Toast onDismiss={handleToastDismiss} content={f("bulk.actions.screening-type.success.message")} />
      )}
    </>
  );
};

export default ChangeScreeningTypeModal;
