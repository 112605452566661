import styled from "styled-components";

type Size = "medium" | "large";

export default styled.div<{ size?: Size; divider?: boolean }>`
  height: ${({ size }) => (size === "medium" ? "1.5rem" : "var(--kdk-toolbar-height)")};

  &::before {
    content: "";
    display: ${({ divider }) => (divider ? "block" : "none")};
    height: 50%;
    width: 100%;
    border-bottom: var(--p-border-divider);
  }
`;
