import React from "react";
import { Stack } from "@shopify/polaris";

import PlusIcon from "../../../assets/icons/plus-major.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { difference, toggleElement } from "../../utils/collectionUtils";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import PopoverButton, { PopoverButtonProps } from "../PopoverButton/PopoverButton";

import GlobalMeasuresList, { GlobalMeasuresListProps } from "./GlobalMeasuresList/GlobalMeasuresList";
import { convertMeasureConfigToMeasure } from "./MeasurePopover.helpers";

type MeasurePopoverProps = Omit<PopoverButtonProps, "render"> &
  Omit<GlobalMeasuresListProps, "selectedGlobalMeasuresIds" | "onSelectionChange"> & {
    measures: api.Measure[];
    // disable option to add new custom measure by default
    // this feature might be removed in the future
    allowCustomMeasure?: boolean;
    onChange(measures: api.Measure[]): void;
    onNewMeasure(): void;
  };

const MeasurePopover = (props: MeasurePopoverProps) => {
  const { measures, allowCustomMeasure, onChange, onNewMeasure, ...popoverProps } = props;

  const f = useFormatMessage();

  const handleNewMeasure = (onClose: () => void) => {
    onClose();
    onNewMeasure();
  };

  const selectedGlobalMeasuresIds = measures.map((measure) => measure.measure_config_id).filter(Boolean);

  const onSelectionChange = (selectedGlobalMeasures: Record<string, api.MeasureConfig>) => {
    // selection changes only contain 1 change - either a global measure was selected or deselected

    // get the global measure id that was toggled (selected or deselected)
    const toggledGlobalMeasureId = difference(Object.keys(selectedGlobalMeasures), selectedGlobalMeasuresIds)[0];

    // search for the global measure in the given measure list - if found, then the global measure should be toggled
    // if the global measure was not found, then use the global measure selectedGlobalMeasures to toggle
    const toggledMeasure = measures.find((measure) => measure.measure_config_id === toggledGlobalMeasureId);

    if (toggledMeasure) {
      onChange(toggleElement(measures, toggledMeasure));
    } else {
      const newMeasure = convertMeasureConfigToMeasure(selectedGlobalMeasures[toggledGlobalMeasureId]);
      onChange(toggleElement(measures, newMeasure));
    }
  };

  return (
    <PopoverButton
      {...popoverProps}
      autofocusTarget={"none"}
      label={f("measures.popover.labels.button")}
      icon={<Icon source={PlusIcon} useMask width="12px" height="12px" />}
      size="slim"
      render={(onClose) => (
        <GlobalMeasuresList
          disabled={popoverProps.loading}
          selectedGlobalMeasuresIds={selectedGlobalMeasuresIds}
          onSelectionChange={onSelectionChange}
        >
          {allowCustomMeasure && (
            <Stack distribution={"fill"}>
              <Button
                disabled={popoverProps.loading}
                icon={<Icon source={PlusIcon} useMask width="12px" height="12px" />}
                plain
                onClick={() => handleNewMeasure(onClose)}
              >
                {f("measures.popover.labels.new-empty-measure")}
              </Button>
            </Stack>
          )}
        </GlobalMeasuresList>
      )}
    />
  );
};

export default MeasurePopover;
