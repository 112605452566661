import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import api from "../../api";
import CustomDate from "../../components/CustomDate/CustomDate";
import MultipleUsersInitials from "../../components/MultipleUsersInitials/MultipleUsersInitials";
import PaginationControls from "../../components/PaginationControls/PaginationControls";
import ProjectName from "../../components/ProjectName/ProjectName";
import ProjectStatus from "../../components/ProjectStatus/ProjectStatus";
import ReactTable from "../../components/ReactTable/ReactTable";
import { SORT_DIRECTION } from "../../components/ReactTable/types";
import TableColumnHeader from "../../components/TableColumnHeader/TableColumnHeader";
import { SORT_BY_ADDED_DATE_PARAMETER, SORT_BY_NAME_PARAMETER } from "../../constants/pagination";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";

import useProjectListParams from "./useProjectListParams";

interface ProjectsListTableProps {
  isLoading: boolean;
  projects: api.ProjectListItem[];
  count: number;
  selectedIds?: string[];
  onSelectionChange?(projectsIds: string[]): void;
}

const ProjectsListTable = (props: ProjectsListTableProps) => {
  const { isLoading, projects, count, selectedIds, onSelectionChange, ...rest } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const { formatNumber } = useIntl();
  const { page, setPage, limit, setLimit, order, setOrder } = useProjectListParams();

  const columnHelper = createColumnHelper<api.ProjectListItem>();

  const columns = [
    columnHelper.accessor("status", {
      header: f("projects.list.column.status"),
      cell: (props) => <ProjectStatus status={props.getValue()} />
    }),
    columnHelper.accessor("id", {
      header: f("projects.list.column.name"),
      id: SORT_BY_NAME_PARAMETER,
      enableSorting: true,
      cell: (props) => <ProjectName project={props.row.original} renderAsLink />
    }),
    columnHelper.accessor("customer_count", {
      header: () => <TableColumnHeader centered>{f("customers.list.column.customers.count")}</TableColumnHeader>,
      meta: {
        label: f("customers.list.column.customers.count")
      },
      cell: (props) => <div>{formatNumber(props.getValue())}</div>
    }),
    features.RESPONSIBLE_USER
      ? columnHelper.accessor("responsible_users", {
          header: () => <TableColumnHeader centered>{f("customers.list.column.responsible.users")}</TableColumnHeader>,
          meta: {
            label: f("customers.list.column.responsible.users")
          },
          cell: (props) => <MultipleUsersInitials users={props.getValue()} responsive />
        })
      : undefined,
    columnHelper.accessor("created_dt", {
      header: () => <TableColumnHeader centered>{f("customers.list.column.added")}</TableColumnHeader>,
      meta: {
        label: f("customers.list.column.added")
      },
      id: SORT_BY_ADDED_DATE_PARAMETER,
      sortingFn: "datetime",
      enableSorting: true,
      cell: (props) => (
        <div>
          <CustomDate date={props.getValue()} />
        </div>
      )
    })
  ].filter(Boolean) as ColumnDef<api.ProjectListItem>[];

  const getRowId = useCallback((project: api.ProjectListItem) => project.id, []);

  const handleSortChange = useCallback(
    (columnId: string, sortDirection: SORT_DIRECTION) => {
      const orderDirection = sortDirection === "ascending" ? "" : "-";
      const newOrder = orderDirection + columnId;

      setOrder(newOrder);
      setPage(0);
    },
    [setOrder, setPage]
  );

  const sortedColumnId = order?.includes(SORT_BY_NAME_PARAMETER)
    ? SORT_BY_NAME_PARAMETER
    : SORT_BY_ADDED_DATE_PARAMETER;
  const sortedDirection = !order || order.startsWith("-") ? "descending" : "ascending";

  return (
    <ReactTable<api.ProjectListItem>
      columns={columns}
      data={projects}
      getRowId={getRowId}
      sortedColumnId={sortedColumnId}
      sortedDirection={sortedDirection}
      onSortChange={handleSortChange}
      selectedIds={selectedIds || []}
      onSelectionChange={onSelectionChange}
      isLoading={isLoading}
      footerContent={
        <PaginationControls
          isLoading={isLoading}
          page={page}
          limit={limit}
          count={count}
          itemsCount={projects.length}
          setPage={setPage}
          setLimit={setLimit}
        />
      }
      {...rest}
    />
  );
};

export default ProjectsListTable;
