import { useMemo } from "react";

import {
  EXTERNAL_ID_COLUMN,
  IGNORE_COLUMN,
  NAME_COLUMN,
  ORG_ID_COLUMN,
  RESPONSIBLE_USER_EMAIL_COLUMN
} from "../../constants/import-customers";
import useFormatMessage from "../../hooks/useFormatMessage";

const useGetColumnLabel = () => {
  const f = useFormatMessage();

  const ColumnTranslation: Record<string, string> = useMemo(
    () => ({
      [ORG_ID_COLUMN]: f("company.details.caption.national_id"),
      [NAME_COLUMN]: f("table.column.name"),
      [RESPONSIBLE_USER_EMAIL_COLUMN]: f("table.column.responsible-user"),
      [EXTERNAL_ID_COLUMN]: f("customers.list.column.external-id"),
      [IGNORE_COLUMN]: f("table.column.ignore")
    }),
    []
  );

  const getColumnLabel = (columnName: string) => ColumnTranslation[columnName];

  return getColumnLabel;
};

export default useGetColumnLabel;
