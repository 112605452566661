import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Badges from "../Badges/Badges";
import MatchCaseStatus from "../MatchCaseStatus/MatchCaseStatus";
import ReviewCard from "../ReviewCard/ReviewCard";

const hasBadges = (review: api.MatchReview) =>
  Boolean(review.is_pep || review.is_sanctioned || review.negative_news || review.other);

type MatchReviewCardProps = {
  review: api.MatchReview;
};

const MatchReviewCard = (props: MatchReviewCardProps) => {
  const { review } = props;

  const f = useFormatMessage();

  return (
    <ReviewCard
      reviewStatus={review.is_match ? "warning" : undefined}
      reviewDate={review.created_dt}
      reviewedBy={review.reviewed_by}
      reviewHeader={
        <HorizontalStack gap="1" blockAlign="center">
          <MatchCaseStatus isResolved isConfirmed={review.is_match} />
          <Text as="span">{review.is_match ? f("matches.review.approved") : f("matches.review.denied")}</Text>
          {hasBadges(review) && (
            <Badges
              isPep={review.is_pep}
              isSanctioned={review.is_sanctioned}
              hasNegativeNews={review.negative_news}
              isOther={review.other}
            />
          )}
        </HorizontalStack>
      }
      reviewContent={review.description}
    />
  );
};

export default MatchReviewCard;
