import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";
import { useMutation, useQuery } from "@tanstack/react-query";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import CompanyDisclosureSignatureForm, {
  SignaturesMap
} from "../../components/CompanyDisclosureSignatureForm/CompanyDisclosureSignatureForm";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import OnboardingWizardPageSkeleton from "../../components/OnboardingWizardPageSkeleton/OnboardingWizardPageSkeleton";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isActiveAssignment } from "../../helpers/assignments.helpers";
import { convertSignatureToCompanyRoleSignatureConfiguration } from "../../helpers/onboarding.helpers";
import useAttachmentsAPI from "../../hooks/useAttachmentsAPI";
import useDisclosureAssignment from "../../hooks/useDisclosureAssignment";
import useFormatMessage from "../../hooks/useFormatMessage";
import useNavigationToolbar from "../../hooks/useNavigationToolbar";
import useQueryData from "../../hooks/useQueryData";
import { IDParams } from "../../types/params";

export type OnboardingCompanyDisclosuresPageData = [
  api.CompanySignatureConfiguration,
  api.CompanyCustomerDetailResponse
];

const OnboardingCompanyDisclosuresPage = ({ data }: AsyncPageChild<OnboardingCompanyDisclosuresPageData>) => {
  const { id } = useParams<IDParams>();

  const [signatureConfiguration, companyDetails] = data;

  const f = useFormatMessage();
  const { onboardingStepIndex, goToStep } = useNavigationToolbar();

  const { setQueryData: setOnboardingDisclosuresQueryData } = useQueryData<OnboardingCompanyDisclosuresPageData>([
    QUERIES_KEYS.COMPANY_ONBOARDING_DISCLOSURES,
    id
  ]);
  const { updateQueryData: updateCompanyCustomerDetailsQueryData } = useQueryData<api.CompanyCustomerDetailResponse>([
    QUERIES_KEYS.COMPANY_ONBOARDING_SUMMARY,
    id
  ]);
  const [signatures, setSignatures] = useState<SignaturesMap>(new Map());
  const [isFormValid, setFormValid] = useState(false);

  const assignments = companyDetails?.assignments?.filter(isActiveAssignment);
  const assignmentId = signatureConfiguration.assignment_id;

  const { selectedAssignment, setSelectedAssignment, shouldUpdateAssignment, updateAssignmentMutation } =
    useDisclosureAssignment(id, assignmentId, assignments);

  const { entityId, getAttachments } = useAttachmentsAPI(
    selectedAssignment ? { ...selectedAssignment, customer_id: id } : companyDetails
  );
  const attachmentsQueryKey = [QUERIES_KEYS.ATTACHMENTS, entityId];
  const { data: attachmentsList } = useQuery(attachmentsQueryKey, () => getAttachments(entityId));

  const updateSignaturesMutation = useMutation(
    async () => {
      const signaturesArray = [...signatures.values()];

      if (shouldUpdateAssignment) {
        await updateAssignmentMutation.mutateAsync();
      }

      return api.updateCompanySignatureConfiguration(id, {
        signature_required: signaturesArray.length > 0,
        signatures: signaturesArray,
        assignment_id: selectedAssignment?.id
      });
    },
    {
      onSuccess: (response) => {
        onSignatureConfigurationUpdated(response);
      }
    }
  );

  const onSignatureConfigurationUpdated = (response: api.CompanySignatureConfiguration) => {
    // Update the current data with the response of api.updateCompanySignatureConfiguration
    setOnboardingDisclosuresQueryData([response, companyDetails]);

    // Update the company onboarding summary data
    updateCompanyCustomerDetailsQueryData((queryData) => {
      queryData.disclosure_documents[0] = {
        ...queryData.disclosure_documents[0],
        signature_required: response.signatures.length > 0,
        signatures: response.signatures
      };
    });

    // Navigate to the next company onboarding summary step
    goToStep(onboardingStepIndex + 1);
  };

  const skipSignatureStepMutation = useMutation(
    () => api.updateCompanySignatureConfiguration(id, { signature_required: false }),
    {
      onSuccess: (response) => onSignatureConfigurationUpdated(response)
    }
  );

  const sendSignatures = () => updateSignaturesMutation.mutate();

  useEffect(() => {
    const updatedSignaturesMap: SignaturesMap = new Map(
      signatureConfiguration.signatures.map((signature) => [
        signature.role_id!,
        convertSignatureToCompanyRoleSignatureConfiguration(signature)
      ])
    );
    setSignatures(updatedSignaturesMap);
  }, [data]);

  const isMutating = skipSignatureStepMutation.isLoading || updateSignaturesMutation.isLoading;

  return (
    <Page title={f("onboarding.disclosures.title")}>
      <VerticalStack gap="4">
        <Text variant="bodyMd" as="span">
          {f("onboarding.disclosures.company-description")}
        </Text>
        <CompanyDisclosureSignatureForm
          companyDetails={companyDetails}
          companySignatureConfiguration={signatureConfiguration}
          signaturesByRoleId={signatures}
          readonly={updateSignaturesMutation.isLoading}
          onChange={(signatures, isValid) => {
            setSignatures(signatures);
            setFormValid(isValid);
          }}
          assignments={assignments}
          selectedAssignment={selectedAssignment}
          setSelectedAssignment={setSelectedAssignment}
          attachments={attachmentsList?.attachment_list}
        />
        {(updateSignaturesMutation.isError || skipSignatureStepMutation.isError) && (
          <ErrorPanel message={updateSignaturesMutation.error || skipSignatureStepMutation.error} />
        )}
        <HorizontalStack gap="4" align="end">
          <Button disabled={isMutating} onClick={() => goToStep(onboardingStepIndex - 1)}>
            {f("button.go.back")}
          </Button>
          <Button
            disabled={isMutating}
            loading={skipSignatureStepMutation.isLoading}
            onClick={() => skipSignatureStepMutation.mutate()}
          >
            {f("common.buttons.actions.skip-step")}
          </Button>
          <Button
            primary
            disabled={isMutating || !isFormValid}
            loading={updateSignaturesMutation.isLoading}
            onClick={sendSignatures}
          >
            {f("button.go.next")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Page>
  );
};

export default withAsyncPage<OnboardingCompanyDisclosuresPageData>(OnboardingCompanyDisclosuresPage, {
  queryKey: QUERIES_KEYS.COMPANY_ONBOARDING_DISCLOSURES,
  apiFunction: (id, options) =>
    Promise.all([api.getCompanySignatureConfiguration(id, options), api.getCompanyCustomerDetails(id, options)]),
  paramNames: ["id"],
  skeleton: <OnboardingWizardPageSkeleton />
});
