import React from "react";
import { useParams } from "react-router-dom";
import { Button, Card, HorizontalStack, Page, SkeletonBodyText, Stack, Text, VerticalStack } from "@shopify/polaris";

import useCustomerDetailsBackButton from "../../hooks/useCustomerDetailsBackButton";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IdentifiersParams } from "../../types/params";
import BackButton from "../BackButton/BackButton";

const PersonDisclosureSkeleton = () => {
  const { id, assignmentId } = useParams<IdentifiersParams>();

  const f = useFormatMessage();
  const { backButtonURL } = useCustomerDetailsBackButton(id, "person", assignmentId);

  return (
    <Page>
      <VerticalStack gap="4">
        <VerticalStack gap="4">
          <BackButton url={backButtonURL} displayLabel={"..."} showAsLink disabled />
          <Text variant="heading2xl" as="p">
            {f("disclosure-request.title")}
          </Text>
          {/* spacing */}
          <Stack.Item />
        </VerticalStack>
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={5} />
          </Card.Section>
        </Card>
        <HorizontalStack gap="4" align="end">
          <BackButton url={backButtonURL} disabled />
          <Button disabled primary>
            {f("disclosure-request.button.send")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Page>
  );
};

export default PersonDisclosureSkeleton;
