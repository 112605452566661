import { generatePath, matchPath } from "react-router-dom";

import api from "../api";
import { ROUTES } from "../constants/routes";

export const getDetailsPageURL = (customerId: string, customerType: api.CustomerType, assignmentId?: string) => {
  return assignmentId
    ? generatePath(ROUTES.ASSIGNMENT_DETAILS, { customerId, id: assignmentId })
    : generatePath(customerType === "company" ? ROUTES.COMPANY_DETAILS : ROUTES.PERSON_DETAILS, {
        id: customerId
      });
};

export const getManualCompanyPath = (customerId: string, status?: api.OnboardingStatus) => {
  switch (status) {
    case "FETCHING_DATA":
    case "SCREENING_CONFIG":
      return generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING, { id: customerId });
    // case "ASSIGNMENT_CONFIG":
    //   return generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_ASSIGNMENTS, { id: customerId });
    case "SIGNATURE_CONFIG":
      return generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_DISCLOSURES, { id: customerId });
    case "READY":
      return generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_SUMMARY, { id: customerId });
    default:
      return generatePath(ROUTES.COMPANY_DETAILS, { id: customerId });
  }
};

export const getCompanyPath = (customerId: string, status?: api.OnboardingStatus) => {
  switch (status) {
    case "FETCHING_DATA":
    case "SCREENING_CONFIG":
      return generatePath(ROUTES.COMPANY_ONBOARDING_SCREENING, { id: customerId });
    // case "ASSIGNMENT_CONFIG":
    //   return generatePath(ROUTES.COMPANY_ONBOARDING_ASSIGNMENTS, { id: customerId });
    case "SIGNATURE_CONFIG":
      return generatePath(ROUTES.COMPANY_ONBOARDING_DISCLOSURES, { id: customerId });
    case "READY":
      return generatePath(ROUTES.COMPANY_ONBOARDING_SUMMARY, { id: customerId });
    default:
      return generatePath(ROUTES.COMPANY_DETAILS, { id: customerId });
  }
};

export const shouldShowToolbar = () => matchPath(location.pathname, { path: [ROUTES.EXPIRED] }) !== null;
