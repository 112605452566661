import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getCustomerName } from "../../helpers/display.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import { Customer } from "../../types/utilities";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

interface ArchiveCustomersProps {
  customers: Customer[];
  onClose(): void;
  onSuccess(): void;
}

const ArchiveCustomers = (props: ArchiveCustomersProps) => {
  const { customers, onClose, onSuccess } = props;

  const queryClient = useQueryClient();
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const customerIds = customers.map((customer) => customer.id);
  const customersNamesLabel = formatMultipleNames(customers.map(getCustomerName));

  const archiveCustomersApiCall = () => api.archiveCustomers({ customer_ids: customerIds });

  const handleSuccess = async () => {
    await queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMERS_LIST]);
    onSuccess();
  };

  return (
    <ConfirmAction
      apiCall={archiveCustomersApiCall}
      onSuccess={handleSuccess}
      isOpen
      isDestructive
      title={f("bulk.actions.archive.confirmation.title")}
      description={f("bulk.actions.archive.confirmation.description", { names: customersNamesLabel })}
      onNo={onClose}
      actionTitle={f("default.archive")}
      useSuccessToast
      successTitle={f("bulk.actions.archive.success.message", { count: customers.length })}
    />
  );
};

export default ArchiveCustomers;
