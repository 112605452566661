import api from "../api";
import useRiskIndicators from "../components/RiskIndicators/useRiskIndicators";
import { isChecklistActive } from "../helpers/checklists.helpers";
import { isCustomerPEP, isCustomerSanctioned } from "../helpers/customer.helpers";
import { OptionalCustomerReviewRequest } from "../types/CustomerReview";
import { RiskIndicatorsType } from "../types/RiskIndicators";
import { CustomerDetails } from "../types/utilities";
import { hasContent, includesAllItems } from "../utils/collectionUtils";

import useGetReviewChecklists from "./useGetReviewChecklists";

const useReviewChecklistConditions = (customer: CustomerDetails, riskIndicators: RiskIndicatorsType) => {
  const { data } = useGetReviewChecklists();
  const { showGeographicRisk, showIndustryRisk, disclosuresRisk } = useRiskIndicators(riskIndicators);

  const isMatched = (checklist: api.ReviewChecklist, review?: OptionalCustomerReviewRequest) => {
    // check if checklist is not archived
    if (!isChecklistActive(checklist)) {
      return false;
    }

    const condition = checklist.display_settings;

    const reviewPEP = review?.is_pep;
    const reviewSanction = review?.is_sanctioned;
    const reviewAssignmentId = review?.assignment_id;
    const assignmentPurpose = customer.assignments?.find((assignment) => assignment.id === reviewAssignmentId)?.purpose;

    // check customer type
    if (
      (customer.type === "person" && checklist.customer_type === "company") ||
      (customer.type === "company" && checklist.customer_type === "person")
    ) {
      return false;
    }

    if (condition.is_always_select)
      // check always select
      return true;

    // check tags

    if (
      hasContent(condition.labels) &&
      (includesAllItems(customer.labels, condition.labels) || includesAllItems(review?.labels, condition.labels))
    )
      return true;

    // check assignment purpose
    if (assignmentPurpose && condition.assignment_purposes?.includes(assignmentPurpose)) return true;

    // check risk categories
    // check pep
    const disclosurePEP = disclosuresRisk.some(
      ({ riskIndicator }) => riskIndicator?.type === "PEP" || riskIndicator?.type === "PEP_RCA"
    );
    if (condition.risk_categories?.includes("PEP") && (disclosurePEP || reviewPEP || isCustomerPEP(customer))) {
      return true;
    }

    // check sanction
    if (condition.risk_categories?.includes("SANCTION") && (reviewSanction || isCustomerSanctioned(customer))) {
      return true;
    }

    // check geographic risk
    const disclosureGeographicRisk = disclosuresRisk.some(
      ({ riskIndicator }) => riskIndicator?.type === "HIGH_RISK_COUNTRY"
    );
    if (condition.risk_categories?.includes("GEOGRAPHIC_RISK") && (disclosureGeographicRisk || showGeographicRisk)) {
      return true;
    }

    // check industry risk
    const disclosureIndustryRisk = disclosuresRisk.some(
      ({ riskIndicator }) => riskIndicator?.type === "HIGH_RISK_INDUSTRY"
    );
    if (condition.risk_categories?.includes("INDUSTRY_RISK") && (disclosureIndustryRisk || showIndustryRisk)) {
      return true;
    }

    return false;
  };

  const getSelectedReviewChecklists = (review?: OptionalCustomerReviewRequest) => {
    return data?.checklists.filter((checklist) => isMatched(checklist, review)) || [];
  };

  return getSelectedReviewChecklists;
};

export default useReviewChecklistConditions;
