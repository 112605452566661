import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Text, VerticalStack } from "@shopify/polaris";
import { TabDescriptor } from "@shopify/polaris/build/ts/latest/src/components/Tabs/types";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import { CustomerFeatureEnum } from "../../constants/customer-tabbed-features";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isActiveAttachment } from "../../helpers/attachments";
import useAttachmentsAPI from "../../hooks/useAttachmentsAPI";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetAttachmentsDisplayTitle from "../../hooks/useGetAttachmentsDisplayTitle";
import useProjectPermissions from "../../hooks/useProjectPermissions";
import { CustomerDetails } from "../../types/utilities";
import { appendCounter } from "../../utils/stringUtils";
import AddAssignmentButton from "../AddAssignmentButton/AddAssignmentButton";
import AddToProjectButton from "../AddToProjectButton/AddToProjectButton";
import CustomerAttachmentsSection from "../CustomerAttachmentsSection/CustomerAttachmentsSection";
import CustomerUploadDocumentButton from "../CustomerUploadDocumentButton/CustomerUploadDocumentButton";
import KDKTabs from "../KDKTabs/KDKTabs";

import AssignmentsSection from "./AssignmentsSection";
import KarLookupSection from "./KarLookupSection";
import ProjectsSection from "./ProjectsSection";

type CustomerFeaturesSectionProps = {
  customer: CustomerDetails;
  readonly?: boolean;
};

const CustomerFeaturesSection = (props: CustomerFeaturesSectionProps) => {
  const { customer, readonly } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const location = useLocation();

  // get the anchor of the page from the location and remove the hash sign if exists
  const pageAnchor = location.hash.startsWith("#") ? location.hash.substring(1) : location.hash;

  const attachmentsDisplayTitle = useGetAttachmentsDisplayTitle();
  const { entityId, getAttachments } = useAttachmentsAPI(customer);

  const attachmentsQueryKey = [QUERIES_KEYS.ATTACHMENTS, entityId];
  const { data } = useQuery(attachmentsQueryKey, () => getAttachments(entityId));

  // do not count archived assignments in tab header
  const activeAssignmentCount = customer.assignments?.filter((assignment) => !assignment.is_archived).length;
  const assignmentsTitle = appendCounter(f("customer-assignments.title"), activeAssignmentCount);

  // do not count inactive projects in tab header
  const activeProjectsCount = customer.projects?.filter((project) => project.status === "ACTIVE").length;
  const projectsTitle = appendCounter(f("projects.page.title"), activeProjectsCount);

  // do not count attachments from archived assignments
  const activeAttachmentsCount = data?.attachment_list.filter(isActiveAttachment).length;
  const attachmentsTitle = appendCounter(attachmentsDisplayTitle, activeAttachmentsCount);

  const { isProjectsEnabled } = useProjectPermissions();
  const [uploadingFilesCount, setUploadingFilesCount] = useState(0);

  const tabs: TabDescriptor[] = [];

  const assignmentsTab: TabDescriptor = {
    id: CustomerFeatureEnum.ASSIGNMENTS,
    content: (
      <Text as="span" fontWeight="semibold">
        {assignmentsTitle}
      </Text>
    )
  };

  const projectsTab: TabDescriptor = {
    id: CustomerFeatureEnum.PROJECTS,
    content: (
      <Text as="span" fontWeight="semibold">
        {projectsTitle}
      </Text>
    )
  };

  const documentsTab: TabDescriptor = {
    id: CustomerFeatureEnum.ATTACHMENTS,
    content: (
      <Text as="span" fontWeight="semibold">
        {attachmentsTitle}
      </Text>
    )
  };

  const karTab: TabDescriptor = {
    id: CustomerFeatureEnum.KAR,
    content: (
      <Text as="span" fontWeight="semibold">
        {f("kar.title")}
      </Text>
    )
  };

  if (!features.ASSIGNMENTS__HIDE_DETAILS) {
    tabs.push(assignmentsTab);
  }

  if (isProjectsEnabled || !isProjectsEnabled) {
    tabs.push(projectsTab);
  }

  if (features.ATTACHMENTS || !features.ATTACHMENTS) {
    tabs.push(documentsTab);
  }

  if (features.CUSTOMER__KAR_INTEGRATION) {
    tabs.push(karTab);
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(() =>
    // if pageAnchor is not found, return 0 and not -1
    Math.max(
      tabs.findIndex((tab) => tab.id === pageAnchor),
      0
    )
  );

  useEffect(() => {
    const selectedTabIndex = tabs.findIndex((tab) => tab.id === pageAnchor);
    if (selectedTabIndex >= 0) {
      setSelectedTabIndex(selectedTabIndex);
    }
  }, [pageAnchor, tabs]);

  const showAssignments = selectedTabIndex === tabs.indexOf(assignmentsTab);
  const showProjects = selectedTabIndex === tabs.indexOf(projectsTab);
  const showAttachments = selectedTabIndex === tabs.indexOf(documentsTab);
  const showKar = selectedTabIndex === tabs.indexOf(karTab);

  const assignments = customer.assignments?.filter((assignment) => !assignment.is_archived);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <StyledFeaturesWrapper id={tabs[selectedTabIndex].id}>
      {showAssignments && <AddAssignmentButton customer={customer} readonly={readonly} />}
      {showProjects && <AddToProjectButton customer={customer} readonly={readonly} />}
      {showAttachments && (
        <CustomerUploadDocumentButton
          entity={customer}
          assignments={assignments}
          readonly={readonly}
          onFileUploadingStart={(filesCount) => setUploadingFilesCount(filesCount)}
          onFileUploadingFinish={() => setUploadingFilesCount(0)}
        />
      )}
      <KDKTabs tabs={tabs} selected={selectedTabIndex} onSelect={setSelectedTabIndex}>
        <VerticalStack gap="4">
          {showAssignments && <AssignmentsSection customer={customer} />}
          {showProjects && <ProjectsSection projects={customer.projects} />}
          {showAttachments && (
            <CustomerAttachmentsSection
              customer={customer}
              uploadingFilesCount={uploadingFilesCount}
              readonly={readonly}
            />
          )}
          {showKar && <KarLookupSection customer={customer} />}
        </VerticalStack>
      </KDKTabs>
    </StyledFeaturesWrapper>
  );
};

const StyledFeaturesWrapper = styled.div`
  padding-top: var(--p-space-2);
  position: relative;
`;

export default CustomerFeaturesSection;
