import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import { SCREENING_TYPE_FILTERS } from "../constants/filters";
import { QUERIES_KEYS } from "../constants/queries-keys";
import { Customer } from "../types/utilities";
import { resolveAfter } from "../utils/promiseUtils";

import useCustomersListParams from "./useCustomersListParams";

async function updateCompanySignatureConfiguration(customerId: string, screeningType: api.ScreeningType) {
  const companyScreeningConfiguration = await api.getCompanyScreeningConfiguration(customerId);

  return api.updateCompanyScreeningConfiguration(customerId, {
    roles: companyScreeningConfiguration.roles.map((role) => role.role.id).filter(Boolean),
    owners: companyScreeningConfiguration.beneficial_owners.map((owner) => owner.owner.id).filter(Boolean),
    screening_type: screeningType
  });
}

async function startCustomerMonitoring(customer: Customer) {
  return customer.type === "person"
    ? api.updatePersonScreeningConfiguration(customer.id, { screening_type: "MONITORING" })
    : updateCompanySignatureConfiguration(customer.id, "MONITORING");
}

async function stopCustomerMonitoring(customer: Customer) {
  return customer.type === "person"
    ? await api.stopPersonScreening(customer.id)
    : await api.stopCompanyScreening(customer.id);
}

const useBatchUpdateScreeningConfiguration = (customers: Customer[]) => {
  const queryClient = useQueryClient();
  const { screeningType } = useCustomersListParams();

  return useMutation<unknown, unknown, api.ScreeningType>(
    (screeningType) =>
      Promise.all(
        customers.map((customer, i) =>
          resolveAfter(
            () =>
              screeningType === "MONITORING" ? startCustomerMonitoring(customer) : stopCustomerMonitoring(customer),
            i * 500 // delay execution of each api call with 500 milliseconds
          )
        )
      ),
    {
      // invalidate the customers list results
      onSuccess: async () => {
        // if the customers filters contains a screening type filter then invalidate the customers list results cache
        if (screeningType === SCREENING_TYPE_FILTERS.ONE_TIME || screeningType === SCREENING_TYPE_FILTERS.MONITORING) {
          await queryClient.invalidateQueries([QUERIES_KEYS.CUSTOMERS_LIST]);
        }
      }
    }
  );
};

export default useBatchUpdateScreeningConfiguration;
