import React, { useState } from "react";
import { TextFieldProps } from "@shopify/polaris";

import api from "../../../api";
import useCountries from "../../../hooks/useCountries";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useNonInitialEffect from "../../../hooks/useNonInitialEffect";
import { isEmptyString } from "../../../utils/stringUtils";
import Country from "../../Country/Country";
import CountrySelect from "../../CountrySelect/CountrySelect";
import KDKTextField from "../../KDKTextField/KDKTextField";
import PopoverButton from "../../PopoverButton/PopoverButton";

import useNationalIdValidator from "./useNationalIdValidator";

type NationalIdInputProps = Omit<TextFieldProps, "value" | "onChange" | "label" | "autoComplete"> & {
  country?: api.CountryEnum;
  nationalId?: string;
  enableCountrySelection?: boolean;
  onChange: (isValid: boolean, country?: api.CountryEnum, nationalId?: string) => void;
};

const NationalIdInput = (props: NationalIdInputProps) => {
  const {
    country: initialCountry,
    nationalId: initialNationalId,
    enableCountrySelection,
    onChange,
    error,
    ...textFieldProps
  } = props;

  const f = useFormatMessage();
  const [country, setCountry] = useState<api.CountryEnum | undefined>(initialCountry);
  const [nationalId, setNationalId] = useState(initialNationalId || "");
  const { getCountryName } = useCountries();
  const { placeholder, validator, length } = useNationalIdValidator(country);

  const isCountryMissing = !country && !isEmptyString(nationalId);
  const isNationalIdValid = nationalId && country ? validator(nationalId) : true;

  const nationalIdError = !isNationalIdValid
    ? f("common.errors.invalid.person.national-id")
    : isCountryMissing
      ? f("common.errors.invalid.person.national-id-country")
      : error;

  const isCountrySelectDisabled = textFieldProps.disabled && !enableCountrySelection;

  // call onChange action only after updating the internal state and calculating the validity of the national id
  useNonInitialEffect(() => onChange(isNationalIdValid, country, nationalId), [country, nationalId, isNationalIdValid]);

  return (
    <KDKTextField
      prefix={
        <PopoverButton
          label={country ? <Country country={country} hideLabel /> : undefined}
          plain
          removeUnderline
          disclosure="down"
          disabled={isCountrySelectDisabled}
          tooltip={country ? getCountryName(country) : undefined}
          render={(onClose) => {
            return (
              <CountrySelect
                value={country}
                onSelect={(newCountryValue) => {
                  setCountry(newCountryValue);
                  onClose();
                }}
                label={f("common.labels.national-id-country")}
                labelHidden
                renderAsOptionsList
                disabled={isCountrySelectDisabled}
              />
            );
          }}
        />
      }
      onChange={setNationalId}
      value={nationalId}
      placeholder={placeholder}
      label={f("person.customer.details.personal.number")}
      maxLength={length}
      minLength={length}
      autoComplete="off"
      forceTouched={isCountryMissing}
      error={nationalIdError}
      {...textFieldProps}
    />
  );
};

export default NationalIdInput;
