import React from "react";
import { HorizontalStack, Link, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import useRDCMatch from "../../hooks/useRDCMatch";
import { MatchDetailsProps } from "../../types/MatchDetails";
import { parseWebsite } from "../../utils/stringUtils";
import MatchAttributesCollapsibleCardSections from "../MatchAttributesCollapsibleCardSections/MatchAttributesCollapsibleCardSections";
import MatchCategorySection from "../MatchCategorySection/MatchCategorySection";
import MatchEvent from "../MatchEvent/MatchEvent";
import MatchSource from "../MatchSource/MatchSource";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";

const MatchDetailsOtherSection = (props: MatchDetailsProps) => {
  const { matchDetails } = props;

  const f = useFormatMessage();

  const { otherEvents, remarks, sources, links, hasNewInfo } = useRDCMatch(matchDetails);

  return (
    <MatchCategorySection title={f("matches.labels.other")}>
      {otherEvents.length > 0 && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.events")}
          elements={otherEvents}
          renderElement={(event) => <MatchEvent event={event} showNewInfo={hasNewInfo(event)} />}
        />
      )}
      {remarks.length > 0 && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.comments")}
          elements={remarks}
          renderElement={(remark) => (
            <HorizontalStack gap="4">
              <Text as="span">{remark.value}</Text>
              {hasNewInfo(remark) && <NewInfoBadge />}
            </HorizontalStack>
          )}
        />
      )}
      {sources.length > 0 && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.sources")}
          elements={sources}
          renderElement={(source) => <MatchSource source={source} showNewInfo={hasNewInfo(source)} />}
        />
      )}
      {links.length > 0 && (
        <MatchAttributesCollapsibleCardSections
          title={f("matches.titles.relevant-links")}
          elements={links}
          renderElement={(link) => (
            <VerticalStack gap="1">
              {hasNewInfo(link) && <NewInfoBadge />}
              <StyledLinkWrapper>
                <Link url={parseWebsite(link.value)} external>
                  {decodeURI(link.value)}
                </Link>
              </StyledLinkWrapper>
            </VerticalStack>
          )}
        />
      )}
    </MatchCategorySection>
  );
};

const StyledLinkWrapper = styled.div`
  word-break: break-all;
`;

export default MatchDetailsOtherSection;
