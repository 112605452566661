import React from "react";
import { HorizontalStack, Text } from "@shopify/polaris";
import styled from "styled-components";

import WaitingIcon from "../../../assets/icons/status-waiting.svg";
import { Review } from "../../types/CustomerReview";
import { CustomerDetails } from "../../types/utilities";
import CustomerReviewCardEditButton from "../CustomerReviewCardEditButton/CustomerReviewCardEditButton";
import Icon from "../extensions/Icon";

import useCustomerReviewCardPendingLabel from "./useCustomerReviewCardPendingLabel";

export type CustomerReviewCardPendingLabelProps = {
  customer: CustomerDetails;
  review: Review;
  readonly?: boolean;
};

const CustomerReviewCardPendingLabel = (props: CustomerReviewCardPendingLabelProps) => {
  const { customer, review, readonly } = props;

  const { pendingLabel } = useCustomerReviewCardPendingLabel(review);

  if (!pendingLabel) {
    return null;
  }

  return (
    <HorizontalStack blockAlign="center" align={"space-between"}>
      <HorizontalStack gap="1" blockAlign="center" wrap={false}>
        <Icon width="10px" height="12px" source={WaitingIcon} />
        <StyledItalicWrapper>
          <Text as="span" color="subdued">
            {pendingLabel}
          </Text>
        </StyledItalicWrapper>
      </HorizontalStack>
      <CustomerReviewCardEditButton customer={customer} review={review} readonly={readonly} />
    </HorizontalStack>
  );
};

const StyledItalicWrapper = styled.span`
  font-style: italic;
`;

export default CustomerReviewCardPendingLabel;
