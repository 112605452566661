import { HorizontalStack, RadioButton } from "@shopify/polaris";

import api from "../../api";
import useRiskCategories from "../../hooks/useRiskCategories";

type RiskCategorySelectProps = {
  riskCategory?: api.RiskCategory;
  readonly?: boolean;
  onChange(riskCategory: api.RiskCategory): void;
};

const RiskCategorySelect = (props: RiskCategorySelectProps) => {
  const { riskCategory, readonly, onChange } = props;

  const options = useRiskCategories();

  return (
    <HorizontalStack gap="4">
      {[...options.entries()].map(([category, label]) => (
        <RadioButton
          label={label}
          checked={riskCategory === category}
          name={"risk-category"}
          disabled={readonly}
          onChange={(checked) => (checked ? onChange(category) : undefined)}
          key={category}
        />
      ))}
    </HorizontalStack>
  );
};

export default RiskCategorySelect;
