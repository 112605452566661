import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface TreeProps {
  vOffset?: number;
}

const Tree = (props: PropsWithChildren<TreeProps>) => {
  const { children, vOffset = 0, ...rest } = props;

  return (
    // @ts-ignore
    <StyledTree {...rest} style={{ "--v-offset": vOffset + "px" }}>
      {children}
    </StyledTree>
  );
};

const StyledTree = styled.ul`
  list-style: none;
  padding-left: var(--p-space-6);
  margin-bottom: 0;
`;

Tree.Item = styled.li<{ disabled?: boolean }>`
  position: relative;
  --border-style: ${({ disabled }) => (disabled ? "dotted" : "solid")};

  &:last-of-type::before {
    border-bottom-left-radius: ${({ disabled }) => (disabled ? "0" : "var(--p-border-radius-05)")};
  }

  // draw the |_ tree line before the element
  &::before {
    content: "";
    position: absolute;
    top: calc(-1 * var(--v-offset));
    height: ${({ disabled }) => (disabled ? "calc(50% - var(--p-border-width-1))" : "50%")};
    width: 1rem;
    left: -1rem;

    border-bottom: var(--p-border-dark);
    border-left: var(--p-border-dark);
    border-width: var(--p-border-width-2);
    border-bottom-style: var(--border-style);
    border-left-style: var(--border-style);
  }

  // for adjacent elements, extend the height of the vertical line tree line so it will intersect with the element
  // above it (this creating the illusion of a continuous line)
  // |
  // |_
  & + &::before {
    top: calc(-50% - var(--v-offset) + 2 * var(--p-border-width-2));
    height: calc(100% - 2 * var(--p-border-width-2));
  }
`;

export default Tree;
