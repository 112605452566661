import { useIntl } from "react-intl";
import { AppliedFilterInterface } from "@shopify/polaris";

import { DateRange } from "../constants/pagination";

import useFormatMessage from "./useFormatMessage";

interface useCreatedDateAppliedFiltersProps {
  createdRange?: DateRange;
  createdBefore?: string;
  createdAfter?: string;
  setCreatedRange(createdRange?: string): void;
  setCreatedAfter(createdAfter?: string): void;
  setCreatedBefore(createdBefore?: string): void;
}

const useCreatedDateAppliedFilters = (props: useCreatedDateAppliedFiltersProps) => {
  const { createdRange, createdBefore, createdAfter, setCreatedRange, setCreatedAfter, setCreatedBefore } = props;

  const f = useFormatMessage();
  const { formatDate } = useIntl();

  const appliedCreatedDateFilters: AppliedFilterInterface[] = [];

  if (createdRange && createdRange !== DateRange.CUSTOM) {
    appliedCreatedDateFilters.push({
      key: "created",
      label: f(`component.filters.created.applied.range.${createdRange}`),
      onRemove: () => setCreatedRange(undefined)
    });
  }

  if (createdAfter && createdRange === DateRange.CUSTOM) {
    appliedCreatedDateFilters.push({
      key: "created-after",
      label: f("component.filters.created.labels.after", { date: formatDate(createdAfter) }),
      onRemove: () => {
        setCreatedAfter(undefined);
        if (!createdBefore) {
          setCreatedRange(undefined);
        }
      }
    });
  }

  if (createdBefore && createdRange === DateRange.CUSTOM) {
    appliedCreatedDateFilters.push({
      key: "created-before",
      label: f("component.filters.created.labels.before", { date: formatDate(createdBefore) }),
      onRemove: () => {
        setCreatedBefore(undefined);
        if (!createdAfter) {
          setCreatedRange(undefined);
        }
      }
    });
  }

  return appliedCreatedDateFilters;
};

export default useCreatedDateAppliedFilters;
