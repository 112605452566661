import api from "../api";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../constants/languages";
import { NEW_QUESTION_CHOICE_PREFIX, NEW_QUESTION_PREFIX } from "../constants/questions";
import { Question } from "../types/Questions";
import { isEmptyString } from "../utils/stringUtils";
import { isNil, uniqueId } from "../utils/util";

export const createNewQuestion = (): Question => ({
  id: uniqueId(NEW_QUESTION_PREFIX),
  text: {
    en: "",
    nb: ""
  },
  type: "YES_NO",
  is_required: false,
  show_infobox: false,
  infobox: undefined
});

export const createNewChoice = (): api.QuestionChoice => ({
  id: uniqueId(NEW_QUESTION_CHOICE_PREFIX),
  text: Object.fromEntries(SUPPORTED_LANGUAGES.map((language) => [language, ""])) as api.TranslationText
});

export const isQuestionnaireQuestion = (question: Question): question is api.Question =>
  !isNil((question as api.Question).is_required);

export const duplicateQuestion = (question: Question): Question =>
  isQuestionnaireQuestion(question)
    ? {
        ...question,
        id: uniqueId(NEW_QUESTION_PREFIX),
        choices: question.choices?.map((choice) => ({ ...choice, id: uniqueId(NEW_QUESTION_CHOICE_PREFIX) }))
      }
    : { ...question, id: uniqueId(NEW_QUESTION_PREFIX) };

export const isChoicesQuestion = (questionType: api.QuestionType) =>
  questionType === "SINGLE_CHOICE" || questionType === "MULTIPLE_CHOICE";

export const isCountryQuestion = (
  questionAnswer: api.QuestionnaireAnswer["answers"][0]
): questionAnswer is api.CountryChoiceQuestionAnswer =>
  questionAnswer.question.type === "SINGLE_COUNTRY_CHOICE" ||
  questionAnswer.question.type === "MULTIPLE_COUNTRY_CHOICE";

export const isInvalidChoice = (choice: api.QuestionChoice): boolean => isEmptyString(choice.text[DEFAULT_LANGUAGE]);

const areDuplicateChoices = (choices: api.QuestionChoice[] = []): boolean =>
  choices.length > 1 && new Set(choices.map((choice) => choice.text[DEFAULT_LANGUAGE])).size < choices.length;

export const isInvalidQuestion = (question: api.Question) =>
  // question text are empty
  isEmptyString(question.text[DEFAULT_LANGUAGE]) ||
  (isChoicesQuestion(question.type) && question.choices?.length && question.choices.length < 2) ||
  (isChoicesQuestion(question.type) && question.choices?.some(isInvalidChoice)) ||
  (isChoicesQuestion(question.type) && areDuplicateChoices(question.choices));
