import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS, STALE_TIME } from "../../constants/queries-keys";
import useAuth from "../../hooks/useAuth";
import useFeatures from "../../hooks/useFeatures";

/**
 * This setting must be set BEFORE loading Hubspot's live chat script (aka tracking script)
 * It prevents the live chat widget to load until window.HubSpotConversations.widget.load() is called
 * This is done in order to create a user identification token using Hubspot's API in beaufort-api
 * and set is before loading the live chat widget
 */
window.hsConversationsSettings = {
  loadImmediately: false
};

/**
 * Loads Hubspot's live chat script which will render a live chat widget in KDK
 *
 * Chat flows are defined in Hubspot (https://app.hubspot.com/chatflows/8006423/) and are rendered using
 * target rules (in which URLs should the chat flow be rendered).
 *
 * The chat flow for KDK is called "KDK chat" and it would be rendered only if the url starts with
 * https://app.test.kjenndinkunde.no/ or https://app.kjenndinkunde.no/
 */
const LiveChat = () => {
  const { isAuthenticated, user: currentUser } = useAuth();
  const features = useFeatures();

  // do not try to fetch if the user is not authenticated and fetched
  const enabled = Boolean(features.HUBSPOT_LIVE_CHAT && isAuthenticated && currentUser !== undefined);

  useQuery(
    [QUERIES_KEYS.HUBSPOT_LIVE_CHAT, currentUser?.id],
    () =>
      api.generateHubspotIdentificationToken({
        email: currentUser!.email,
        first_name: currentUser!.first_name,
        last_name: currentUser!.last_name
      }),
    {
      enabled,
      staleTime: STALE_TIME,
      onSuccess: ({ token }) => {
        window.hsConversationsSettings = {
          identificationEmail: currentUser?.email,
          identificationToken: token
        };

        // after setting the current user email and identification token - load the live chat widget
        window.HubSpotConversations?.widget?.load();
      }
    }
  );

  // if the feature is not enabled do not even load the live chat widget
  if (!features.HUBSPOT_LIVE_CHAT) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript" id="hs-script-loader" async defer src={process.env.HUBSPOT_LIVE_CHAT_URL}></script>
    </Helmet>
  );
};

export default LiveChat;
