import useFormatMessage from "../../hooks/useFormatMessage";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import KarLookupHistoryTable, { KarLookupHistoryTableProps } from "../KarLookupHistoryTable/KarLookupHistoryTable";

const KarLookupsHistory = (props: KarLookupHistoryTableProps) => {
  const f = useFormatMessage();

  return (
    <CollapsibleSection showPrefix title={f("default.history")} withoutHeader>
      <KarLookupHistoryTable {...props} />
    </CollapsibleSection>
  );
};

export default KarLookupsHistory;
