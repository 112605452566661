import React, { PropsWithChildren, ReactNode } from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import IconCompany from "../../../assets/icons/company.svg";
import InfoBlueIcon from "../../../assets/icons/info-blue.svg";
import IconPerson from "../../../assets/icons/person.svg";
import api from "../../api";
import RoleTypes from "../../constants/role-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isNil } from "../../utils/util";
import Icon from "../extensions/Icon";
import TextHighlighter from "../TextHighlighter/TextHighlighter";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export interface DisplayNameProps {
  type?: api.CustomerType;
  name?: string;
  birthDate?: string;
  comment?: string;
  strong?: boolean;
  subdued?: boolean;
  roleType?: api.RoleType;
  subtitle?: ReactNode;
  wrap?: boolean;
  searchTerm?: string;
}

const DisplayName = (props: PropsWithChildren<DisplayNameProps>) => {
  const { type, name, birthDate, comment, strong, subdued, roleType, subtitle, wrap, searchTerm, children } = props;

  const f = useFormatMessage();

  const displayName = roleType ? `${name} (${f(RoleTypes[roleType])})` : name;

  const showIcon = type === "person" || type === "company";

  return (
    <HorizontalStack gap="1" blockAlign={subtitle ? "start" : "center"} wrap={false}>
      {showIcon && (
        <StyledIcon
          source={type === "person" ? IconPerson : IconCompany}
          width="20px"
          height="20px"
          useMask
          subdued={subdued}
        />
      )}
      <VerticalStack>
        <StyledContainer flowWrap={wrap}>
          {!isNil(displayName) && (
            <StyledName title={displayName} whiteSpaceWrap={wrap}>
              <Text as="span" fontWeight={strong ? "semibold" : undefined} color={subdued ? "subdued" : undefined}>
                {searchTerm && <TextHighlighter searchWords={[searchTerm]} textToHighlight={displayName} />}
                {!searchTerm && displayName}
              </Text>
            </StyledName>
          )}
          {birthDate && (
            <StyledBirthYear>
              {f("display-name.suffixes.labels.birth-date", { birthDate: new Date(birthDate) })}
            </StyledBirthYear>
          )}
          {comment && (
            <TooltipWrapper content={comment} dismissOnMouseOut preferredPosition="above">
              <img src={InfoBlueIcon} />
            </TooltipWrapper>
          )}
          {children}
        </StyledContainer>
        {subtitle}
      </VerticalStack>
    </HorizontalStack>
  );
};

const StyledIcon = styled(Icon)<{ subdued?: boolean }>`
  color: ${({ subdued }) => (subdued ? "var(--p-text-subdued)" : "inherit")};
`;

const StyledContainer = styled.div<{ flowWrap?: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--p-space-05);

  flex-flow: ${({ flowWrap }) => (flowWrap ? "wrap" : "row")};

  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledName = styled.span<{ whiteSpaceWrap?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ whiteSpaceWrap }) => (whiteSpaceWrap ? "break-spaces" : "nowrap")};
`;

const StyledBirthYear = styled.div`
  white-space: nowrap;
`;

export default DisplayName;
