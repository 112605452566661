import React, { PropsWithChildren } from "react";
import { Collapsible, Text } from "@shopify/polaris";
import styled from "styled-components";

import useOpenClose from "../../hooks/useOpenClose";
import Button from "../extensions/Button";
import KDKBanner from "../KDKBanner/KDKBanner";

type HelpTextProps = {
  title: string;
};

const HelpText = (props: PropsWithChildren<HelpTextProps>) => {
  const { title, children } = props;

  const [isExpanded, toggleExpanded] = useOpenClose();

  return (
    <StyledBanner hideIcon status="info">
      <Button
        plain
        monochrome
        removeUnderline
        fullWidth
        textAlign={"left"}
        disclosure={isExpanded ? "up" : "down"}
        onClick={toggleExpanded}
      >
        <Text as={"span"} fontWeight={"semibold"}>
          {title}
        </Text>
      </Button>
      <Collapsible open={isExpanded} id="">
        {children}
      </Collapsible>
    </StyledBanner>
  );
};

const StyledBanner = styled(KDKBanner)`
  margin: var(--p-space-4);

  & > .Polaris-Banner {
    padding: var(--p-space-2) var(--p-space-4);
  }

  & > .Polaris-Banner .Polaris-Button {
    padding: 0;
  }

  & > .Polaris-Banner .Polaris-Button__Content {
    flex: 1;
  }
`;

export default HelpText;
