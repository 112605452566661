import { createGlobalStyle } from "styled-components";

import BatonTurboMediumEOT from "../../../assets/fonts/BatonTurboWeb-Medium.eot";
import BatonTurboMediumWOFF from "../../../assets/fonts/BatonTurboWeb-Medium.woff";
import BatonTurboRegularEOT from "../../../assets/fonts/BatonTurboWeb-Regular.eot";
import BatonTurboRegularWOFF from "../../../assets/fonts/BatonTurboWeb-Regular.woff";
import { MOBILE_BREAKPOINT } from "../../constants/styles";

const GlobalStyles = createGlobalStyle`
    // fonts
    @font-face {
        font-family: BatonTurboWeb;
        src: url(${BatonTurboRegularEOT});
        src: url(${BatonTurboRegularWOFF}) format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: BatonTurboWeb;
        src: url(${BatonTurboMediumEOT});
        src: url(${BatonTurboMediumWOFF}) format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    html {
        font-size: 120%;
    }

    html,
    body,
    button {
        font-family: var(--p-font-family-sans);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    // Polaris theme overrides
    :root {
        --p-font-family-sans: BatonTurboWeb, Arial, "sans-serif";
        --p-color-bg-primary: rgba(91, 105, 195, 1);
        --p-color-bg-primary-hover: rgba(74, 89, 179, 1);
        --p-color-bg-primary-active: rgba(62, 76, 154, 1);
        --p-color-bg-primary-depressed: rgba(39, 48, 102, 1);
        --p-color-text-primary: rgba(86, 101, 192, 1);
        --p-color-text-primary-hover: rgba(72, 87, 175, 1);
        --p-color-text-primary-active: rgba(60, 74, 150, 1);
        --p-background: rgba(246, 246, 248, 1);
        --p-background-selected-hovered: rgba(199, 200, 214, 1);
        --p-background-selected-pressed: rgba(179, 181, 199, 1);
    }

    // Polaris components overrides

    // Increase default page width from 63.5rem to 70rem
    .Polaris-Page:not(.Polaris-Page--fullWidth) {
        max-width: 70rem;

        // For small viewports - reduce gap of page
        @media (max-width: ${MOBILE_BREAKPOINT}) {
            margin: 0 var(--p-space-4);
        }
    }

    // Make Polaris top bar component use the same toolbar height as the rest of the design
    .Polaris-Frame__TopBar {
        height: var(--kdk-toolbar-height);
        box-shadow: var(--p-shadow-top-bar);
    }

    // Reduce default width of secondary layout element from 20rem to rem 10rem
    .Polaris-Layout__Section--secondary {
        flex: 10rem;
    }

    .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
        padding-top: calc(var(--kdk-toolbar-height) + var(--kdk-message-offset));
    }

    .Polaris-TextField__Input {
        height: 1.5rem;
    }

    // Fix border of tables inside cards with the last row having a status background color by adding a border radius
    // to the first and last cells of the last table row (if it is inside a card)
    .Polaris-Card .Polaris-DataTable .Polaris-DataTable__TableRow:last-child .Polaris-DataTable__Cell:first-child {
        border-bottom-left-radius: var(--p-border-radius-2);
    }

    .Polaris-Card .Polaris-DataTable .Polaris-DataTable__TableRow:last-child .Polaris-DataTable__Cell:last-child {
        border-bottom-right-radius: var(--p-border-radius-2);
    }

    /*
    Set max-width of first column for Polaris DataTable
   */
    .Polaris-DataTable__Cell--firstColumn {
        max-width: 300px;
    }

    // Style for DataTable cells and headers with subdued Text
    .Polaris-DataTable__Cell--header,
    .Polaris-DataTable__Heading,
    .Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--header {
        color: var(--p-text-subdued);
        font-size: var(--kdk-font-size-50);
        font-weight: var(--p-font-weight-semibold);
        max-width: fit-content;
    }

    // Bump the z-index for modal on top of another modal to extends the backdrop to cover the lower modal
    #PolarisPortalsContainer > [data-portal-id|="modal"]:last-of-type .Polaris-Modal-Dialog__Container {
        z-index: var(--p-z-12);
    }

    #PolarisPortalsContainer > [data-portal-id|="modal"]:last-of-type .Polaris-Backdrop {
        z-index: var(--p-z-11);
    }

    // Move Hubspot chat widget when the Filters drawer is open
    body[data-lock-scrolling]:is(:has(.Polaris-Filters__FiltersContainer)) > #hubspot-messages-iframe-container.widget-align-right {
        right: 150px !important;
    }

    // disable closing Modals when clicking outside
    .Polaris-Backdrop {
        pointer-events: none;
    }

    // prevent the page from scrolling when reaching the end of the scrollable area
    .Polaris-Scrollable--vertical {
        overscroll-behavior-y: contain;
    }

    // make sure the spinner size is a square
    .Polaris-Spinner {
        line-height: 1;
    }

    .Polaris-Spinner--sizeSmall svg {
        max-height: 1.25rem;
    }

    // KDK colors

    :root {
        --kdk-toolbar-height: 3.5rem;
        --kdk-message-offset: 0px;
        --kdk-column-max-width: 300px;
        --kdk-followup-number-max-width: 15ch;
        --kdk-table-tree-node-gap: 4rem;
        --kdk-tag-height: var(--p-space-4);
        --kdk-font-size-50: 0.625rem;
        --kdk-surface-tag: rgb(255, 234, 138, 1);
        --kdk-red: hsl(350, 90%, 49%);
        --kdk-light-blue: hsl(187, 65%, 52%);
        --kdk-plain-button-hovered: hsl(240, 2%, 92%);

        // For small viewports - change max column width to be relative to the viewport
        @media (max-width: ${MOBILE_BREAKPOINT}) {
            --kdk-column-max-width: 75vw;
        }

        /* vitec external link */
        --kdk-blue-500: hsl(209, 100%, 58%);
        /* hover */
        --kdk-blue-525: hsl(209, 100%, 48%);
        /* active */
        --kdk-blue-550: hsl(209, 100%, 40%);
        /* toggle button */
        --kdk-blue-575: hsl(215, 64%, 48%);
        /* onboarding link bottom border, add item button */
        --kdk-blue-600: hsl(229, 100%, 61%);

        /* match page PEP tag */
        --kdk-yellow-500: hsl(43, 87%, 91%);
        /* match page PEP tag border */
        --kdk-yellow-600: hsl(42, 89%, 66%);

        /* match page Sanction tag */
        --kdk-red-500: hsl(0, 100%, 97%);

        --kdk-dark-grey: hsl(0, 0%, 40%);
        --kdk-dark-success: hsl(165, 100%, 25%);
        --kdk-dashboard-dark-border: hsl(211, 26%, 28%);
        --kdk-dashboard-dark-gradient-start: hsl(249, 37%, 11%);
        --kdk-dashboard-dark-gradient-end: hsl(212, 28%, 36%);
        --kdk-dashboard-customer-type-company: hsl(212, 55%, 31%);
        --kdk-dashboard-customer-type-person: hsl(212, 65%, 47%);
        --kdk-dashboard-risk-high-gradient-start: hsl(351, 100%, 69%);
        --kdk-dashboard-risk-high-gradient-end: hsl(350, 58%, 45%);
        --kdk-dashboard-risk-medium-gradient-start: hsl(39, 100%, 67%);
        --kdk-dashboard-risk-medium-gradient-end: hsl(39, 98%, 53%);
        --kdk-dashboard-risk-low-gradient-start: hsl(126, 41%, 39%);
        --kdk-dashboard-risk-low-gradient-end: hsl(126, 46%, 28%);
        --kdk-dashboard-widget-shadow: 0px 3px 20px rgba(0, 0, 0, 0.25);
    }

    // KDK Table

    // target the label inside a tree line - this selection is a workaround
    // for the lack of option to add a classname on an XArrow label
    // the class kdk-table-tree-line will be added to the XArrow container
    .kdk-table-tree-line > svg + div > * {
        // position the label above the line
        display: inline-block;
        transform: translate(-15%, -35%);
        padding: 0 2px;
        // hide tree line underneath the label
        background-color: var(--p-surface);

        transition: background-color var(--p-duration-200) var(--p-ease-in-out);
    }

    .Polaris-DataTable__TableRow:hover .kdk-table-tree-line > svg + div > * {
        background-color: var(--p-surface-hovered);
    }

`;

export default GlobalStyles;
