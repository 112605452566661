import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";
import { useMutation, useQuery } from "@tanstack/react-query";

import api from "../../api";
import AddItemButton from "../../components/AddItemButton/AddItemButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import BackButton from "../../components/BackButton/BackButton";
import CompanyDisclosureSignatureForm, {
  SignaturesMap
} from "../../components/CompanyDisclosureSignatureForm/CompanyDisclosureSignatureForm";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import OnboardingWizardPageSkeleton from "../../components/OnboardingWizardPageSkeleton/OnboardingWizardPageSkeleton";
import RoleModal from "../../components/RoleModal/RoleModal";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { isActiveAssignment } from "../../helpers/assignments.helpers";
import { getCompanyDisplayName } from "../../helpers/display.helpers";
import { createNewRole } from "../../helpers/person.helpers";
import useCustomerDetailsBackButton from "../../hooks/useCustomerDetailsBackButton";
import useDisclosureAssignment from "../../hooks/useDisclosureAssignment";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData, { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import { IdentifiersParams } from "../../types/params";
import { noop } from "../../utils/util";

const CompanyRequestDisclosuresPage = ({ data }: AsyncPageChild<api.CompanySignatureConfiguration>) => {
  const { id, assignmentId } = useParams<IdentifiersParams>();

  const f = useFormatMessage();
  const features = useFeatures();
  const history = useHistory();

  const updateCompanyQueryData = useCustomerDetailsQueryData({ id, type: "company" });
  const { updateQueryData: updateAssignmentQueryData } = useQueryData<api.CustomerAssignmentDetails>([
    QUERIES_KEYS.ASSIGNMENT_DETAILS,
    id,
    assignmentId
  ]);

  const [signatures, setSignatures] = useState<SignaturesMap>(new Map());

  const [possibleSignatureRoles, setPossibleSignatureRoles] = useState<api.SignatureRole[]>(
    data.possible_signers || []
  );
  const [isFormValid, setFormValid] = useState(false);
  const [roleModal, setRoleModal] = useState<api.Role>();

  const { data: companyDetails } = useQuery([QUERIES_KEYS.COMPANY_DETAILS, id], () =>
    api.getCompanyCustomerDetails(id)
  );

  const assignments = companyDetails?.assignments?.filter(
    (assignment) => isActiveAssignment(assignment) || assignment.id === assignmentId
  );

  const { selectedAssignment, setSelectedAssignment, shouldUpdateAssignment, updateAssignmentMutation } =
    useDisclosureAssignment(companyDetails?.id, assignmentId, assignments);

  // if features.ASSIGNMENTS__HIDE_DETAILS is enabled, do not use the assignment id to return to the
  // assignment details page since it is going to be hidden
  const { backButtonURL } = useCustomerDetailsBackButton(
    id,
    "company",
    features.ASSIGNMENTS__HIDE_DETAILS ? undefined : selectedAssignment?.id
  );
  const backPageLabel = `${getCompanyDisplayName(companyDetails)}${
    !features.ASSIGNMENTS__HIDE_DETAILS && selectedAssignment ? ` / ${selectedAssignment.name}` : ""
  }`;

  const updateSignaturesMutation = useMutation(
    async () => {
      const signaturesArray = [...signatures.values()];

      if (shouldUpdateAssignment) {
        await updateAssignmentMutation.mutateAsync();
      }

      return api.createCompanyCustomerDisclosureDocument(id, {
        signatures: signaturesArray as api.CompanyRoleSignatureConfiguration[],
        assignment_id: selectedAssignment?.id
      });
    },
    {
      onSuccess: async (response) => {
        if (assignmentId) {
          // Add the newly created disclosure document to the start of the array of assignment details
          updateAssignmentQueryData((queryData) => queryData.disclosure_documents.unshift(response));
        } else {
          // Add the newly created disclosure document to the start of the array of customer details
          updateCompanyQueryData((queryData) => queryData.disclosure_documents.unshift(response));
        }

        if (backButtonURL) {
          history.push(backButtonURL);
        } else {
          history.goBack();
        }
      }
    }
  );

  const sendSignatures = () => updateSignaturesMutation.mutate();

  return (
    <Page>
      <VerticalStack gap="4">
        <BackButton url={backButtonURL} displayLabel={backPageLabel} showAsLink />
        <Text variant="heading2xl" as="p">
          {f("disclosure-request.title")}
        </Text>
        <CompanyDisclosureSignatureForm
          companySignatureConfiguration={data}
          companyDetails={companyDetails}
          signaturesByRoleId={signatures}
          onChange={(signatures, isValid) => {
            setSignatures(signatures);
            setFormValid(isValid);
          }}
          assignments={assignments}
          selectedAssignment={selectedAssignment}
          setSelectedAssignment={setSelectedAssignment}
          readonly={updateSignaturesMutation.isLoading}
          footer={
            <AddItemButton onClick={() => setRoleModal(createNewRole(companyDetails!.country))}>
              {f("screening.buttons.add-role")}
            </AddItemButton>
          }
        />
        {updateSignaturesMutation.isError && <ErrorPanel message={updateSignaturesMutation.error} />}
        <HorizontalStack gap="4" align="end">
          <BackButton url={backButtonURL} />
          <Button
            primary
            disabled={updateSignaturesMutation.isLoading || !isFormValid || signatures.size == 0}
            loading={updateSignaturesMutation.isLoading}
            onClick={sendSignatures}
          >
            {f("disclosure-request.button.send")}
          </Button>
        </HorizontalStack>
      </VerticalStack>
      {/* Render the role modal for adding a new role */}
      {roleModal && companyDetails && (
        <RoleModal
          customer={companyDetails}
          dataRole={roleModal}
          onClose={() => setRoleModal(undefined)}
          isDeleteDisabled={true}
          onDelete={(deleteRoleId) => {
            if (signatures.has(deleteRoleId)) {
              signatures.delete(deleteRoleId);
              setSignatures(new Map(signatures));
            }
            setRoleModal(undefined);
          }}
          onUpdate={noop}
          onCreate={(role) => {
            const possibleSigners = possibleSignatureRoles;
            possibleSigners.push(role);
            setPossibleSignatureRoles(possibleSigners);
            setRoleModal(undefined);
          }}
        />
      )}
    </Page>
  );
};

export default withAsyncPage<api.CompanySignatureConfiguration>(CompanyRequestDisclosuresPage, {
  queryKey: QUERIES_KEYS.COMPANY_REQUEST_DISCLOSURES,
  apiFunction: api.getCompanySignatureConfiguration,
  paramNames: ["id", "assignmentId"],
  skeleton: <OnboardingWizardPageSkeleton />
});
