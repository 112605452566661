import React from "react";
import { Text } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

const QuestionnairePageHeader = () => {
  const f = useFormatMessage();

  return (
    <Text as="span" color="subdued">
      {f("questionnaire.page.header.description")}
    </Text>
  );
};

export default QuestionnairePageHeader;
