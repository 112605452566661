import api from "../api";
import useRiskIndicators from "../components/RiskIndicators/useRiskIndicators";
import { createChecklistAnswers } from "../helpers/checklists.helpers";
import { isCustomerPEP, isCustomerSanctioned } from "../helpers/customer.helpers";
import { OptionalCustomerReviewRequest } from "../types/CustomerReview";
import { RiskIndicatorsType } from "../types/RiskIndicators";
import { CustomerDetails } from "../types/utilities";

import useFeatures from "./useFeatures";
import useReviewChecklistConditions from "./useReviewChecklistConditions";

const useInitialCustomerReview = (customer: CustomerDetails, riskIndicators: RiskIndicatorsType) => {
  const features = useFeatures();
  const { showGeographicRisk, showDisclosuresRisk, showIndustryRisk } = useRiskIndicators(riskIndicators);
  const getMatchedReviewChecklists = useReviewChecklistConditions(customer, riskIndicators);

  const getInitialRiskLevel = (): api.RiskLevel => {
    if (customer.is_pep || customer.is_sanctioned) {
      return "HIGH";
    }

    if (features.REVIEW__AUTO_SET_RISK && (showGeographicRisk || showDisclosuresRisk || showIndustryRisk)) {
      return "HIGH";
    }

    return customer.risk_level;
  };

  const preselectedReviewChecklists = getMatchedReviewChecklists();

  const defaultAnswer = features.REVIEW__CHECKLIST_DEFAULT_TO_NO ? false : undefined;

  const customerReview: OptionalCustomerReviewRequest = {
    // if accepted is undefined then this is the first review (customer relationship was not defined yet)
    accepted: customer.review_status === "APPROVED" ? true : customer.review_status === "REJECTED" ? false : undefined,
    risk_level: getInitialRiskLevel(),
    is_pep: isCustomerPEP(customer),
    is_sanctioned: isCustomerSanctioned(customer),
    requires_approval: false,
    comment: "",
    labels: [],
    checklist_answers: preselectedReviewChecklists.map((checklist) => createChecklistAnswers(checklist, defaultAnswer))
  };

  return customerReview;
};

export default useInitialCustomerReview;
