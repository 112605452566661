import { ReactNode } from "react";
import { Icon, TextField, TextFieldProps } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

import useFormatMessage from "../../hooks/useFormatMessage";

type SearchTextFieldProps = Omit<TextFieldProps, "prefix" | "autoComplete" | "label"> & {
  label?: ReactNode;
};

const SearchTextField = (props: SearchTextFieldProps) => {
  const { label, ...textFieldProps } = props;

  const f = useFormatMessage();

  return (
    <TextField
      label={label || textFieldProps.placeholder || f("default.search")}
      autoComplete="off"
      inputMode="search"
      prefix={<Icon source={SearchMinor} />}
      {...textFieldProps}
    />
  );
};

export default SearchTextField;
