import React, { PropsWithChildren } from "react";
import { Card, Image, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

type KDKEmptyStateProps = {
  header: string;
  description: string;
  image: string;
  altImage: string;
};

const KDKEmptyState = (props: PropsWithChildren<KDKEmptyStateProps>) => {
  const { header, description, image, altImage, children, ...rest } = props;

  return (
    <StyledContainer {...rest}>
      <Card>
        <Card.Section>
          <VerticalStack gap="10" inlineAlign={"center"}>
            <Image height={75} alt={altImage} source={image} />
            <VerticalStack gap="2" inlineAlign={"center"}>
              <Text as={"h2"} variant={"headingLg"}>
                {header}
              </Text>
              <Text as={"p"} alignment={"center"}>
                {description}
              </Text>
            </VerticalStack>
            {children}
          </VerticalStack>
        </Card.Section>
      </Card>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 30%;
  display: grid;
  place-content: center;

  .Polaris-Card {
    padding: var(--p-space-8) var(--p-space-16);
  }
`;

export default KDKEmptyState;
