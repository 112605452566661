import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { ButtonGroup, Stack, Text } from "@shopify/polaris";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextFormatType
} from "lexical";
import styled from "styled-components";

import NumberedListIcon from "../../../assets/icons/list-ol.svg";
import BulletListIcon from "../../../assets/icons/list-ul.svg";
import QuoteIcon from "../../../assets/icons/text-bubble.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";

const LowPriority = 1;

type MarkdownEditorToolbarProps = {
  disabled?: boolean;
};

const MarkdownEditorToolbar = (props: PropsWithChildren<MarkdownEditorToolbarProps>) => {
  const { disabled, children } = props;

  const f = useFormatMessage();

  const [editor] = useLexicalComposerContext();

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const formatText = (format: TextFormatType) => () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <Stack spacing={"tight"}>
      <ButtonGroup segmented>
        <Button disabled={disabled} outline pressed={isBold} onClick={formatText("bold")}>
          <Text as="span" fontWeight="semibold">
            B
          </Text>
        </Button>
        <Button disabled={disabled} outline pressed={isItalic} onClick={formatText("italic")}>
          <ItalicText>I</ItalicText>
        </Button>
        <Button disabled={disabled} outline pressed={isStrikethrough} onClick={formatText("strikethrough")}>
          <StrikeThroughText>S</StrikeThroughText>
        </Button>
      </ButtonGroup>
      <ButtonGroup segmented>
        <Button disabled={disabled} outline pressed={blockType === "paragraph"} onClick={formatParagraph}>
          {f("default.normal")}
        </Button>
        <Button disabled={disabled} outline pressed={blockType === "h1"} onClick={formatLargeHeading}>
          H1
        </Button>
        <Button disabled={disabled} outline pressed={blockType === "h2"} onClick={formatSmallHeading}>
          H2
        </Button>
        <Button
          disabled={disabled}
          icon={<Icon source={QuoteIcon} useMask />}
          outline
          pressed={blockType === "quote"}
          onClick={formatQuote}
        />
      </ButtonGroup>
      <ButtonGroup segmented>
        <Button
          disabled={disabled}
          icon={<Icon source={BulletListIcon} useMask />}
          outline
          pressed={blockType === "ul"}
          onClick={formatBulletList}
        />
        <Button
          disabled={disabled}
          icon={<Icon source={NumberedListIcon} useMask />}
          outline
          pressed={blockType === "ol"}
          onClick={formatNumberedList}
        />
      </ButtonGroup>
      <Stack.Item fill>{children}</Stack.Item>
    </Stack>
  );
};

const ItalicText = styled.span`
  font-style: italic;
`;

const StrikeThroughText = styled.span`
  text-decoration: line-through;
`;

export default MarkdownEditorToolbar;
