import React, { forwardRef } from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import RemoveIcon from "../../../../../../../assets/icons/remove.svg";
import api from "../../../../../../api";
import useFormatMessage from "../../../../../../hooks/useFormatMessage";
import { isEmptyString } from "../../../../../../utils/stringUtils";
import Button from "../../../../../extensions/Button";
import MoveButtons, { Direction } from "../../../../../MoveButtons/MoveButtons";
import TranslationTextField from "../../../../../TranslationTextField/TranslationTextField";
import VerticalDivider from "../../../../../VerticalDivider/VerticalDivider";

import { isNewQuestionChoice } from "./ChoiceEditor.helpers";

type ChoiceEditorProps = {
  choice: api.QuestionChoice;
  position: number;
  isFirst: boolean;
  isLast: boolean;
  readonly?: boolean;
  onChange(choice: api.QuestionChoice): void;
  onOrderChange(choice: api.QuestionChoice, direction: Direction): void;
  onDelete(choice: api.QuestionChoice): void;
};

const ChoiceEditor = forwardRef<HTMLDivElement, ChoiceEditorProps>((props, ref) => {
  const { choice, position, isFirst, isLast, readonly, onChange, onOrderChange, onDelete } = props;

  const f = useFormatMessage();

  const handleChoiceChange = (text: api.TranslationText) => onChange({ ...choice, text });

  const handleOrderChange = (direction: Direction) => onOrderChange(choice, direction);

  return (
    <StyledChoiceEditor ref={ref}>
      <VerticalStack gap="1">
        <Text variant="bodyMd" as="span">
          {f("questionnaire.choices-table.description.label", { index: position })}
        </Text>
        <HorizontalStack blockAlign={"start"} align={"space-between"}>
          <TranslationTextField
            text={choice.text}
            showFlagPrefix
            requiredIndicator
            autoComplete="on"
            autoFocus={isLast && isNewQuestionChoice(choice)}
            disabled={readonly}
            onChange={handleChoiceChange}
            error={isEmptyString(choice.text.nb) ? f("questionnaire.choices-table.errors.empty-option") : undefined}
          />
          <HorizontalStack gap="4" blockAlign="stretch">
            <MoveButtons
              disableMoveUp={isFirst}
              disableMoveDown={isLast}
              onOrderChange={handleOrderChange}
              readonly={readonly}
            />
            <VerticalDivider />
            <Button
              destructive
              plain
              removeUnderline
              hoverable
              icon={RemoveIcon}
              onClick={() => onDelete(choice)}
              disabled={readonly}
            />
          </HorizontalStack>
        </HorizontalStack>
      </VerticalStack>
    </StyledChoiceEditor>
  );
});

const StyledChoiceEditor = styled.div`
  & + & {
    margin-top: var(--p-space-3);
  }
`;

export default ChoiceEditor;
