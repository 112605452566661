import React, { useState } from "react";
import { TextField } from "@shopify/polaris";

import EmailIcon from "../../../assets/icons/email.svg";
import { MAX_LENGTH_OF_TEXT_INPUT_FIELD } from "../../constants/person";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ErrorType } from "../../types/utilities";
import { isEmail, isEmptyString } from "../../utils/stringUtils";
import Icon from "../extensions/Icon";

type EmailFieldProps = {
  label?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  placeholder?: string;
  error?: ErrorType;
  onChange(email: string): void;
};

const EmailField = (props: EmailFieldProps) => {
  const { label = "", disabled, required, placeholder, value = "", error, onChange } = props;

  const [focused, setFocused] = useState(false);
  const f = useFormatMessage();

  const getErrorMessage = () => {
    if (required && focused && isEmptyString(value)) {
      return f("email.required.error");
    }

    return !isEmptyString(value) && !isEmail(value) ? f("email.invalid.address.error") : undefined;
  };

  return (
    <TextField
      value={value || ""}
      type="email"
      disabled={disabled}
      prefix={<Icon source={EmailIcon} useMask />}
      placeholder={placeholder || f("email.placeholder.text")}
      error={error || getErrorMessage()}
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      onChange={onChange}
      label={label}
      labelHidden={isEmptyString(label)}
      autoComplete="off"
      maxLength={MAX_LENGTH_OF_TEXT_INPUT_FIELD}
    />
  );
};

export default EmailField;
