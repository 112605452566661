import React from "react";

import api from "../../../../api";
import {
  LATEST_OWNERSHIP_REGISTRY_DATE,
  LATEST_OWNERSHIP_REGISTRY_RECORD_YEAR
} from "../../../../constants/ownership-updates";
import { getOwnerName } from "../../../../helpers/owners.helpers";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import { isEmptyString } from "../../../../utils/stringUtils";
import RowHighlighter from "../../../extensions/RowHighlighter";
import PersonName, { PersonNameProps, Subtitle } from "../../../PersonName/PersonName";

import { isUpdatedByOwnershipRegistry } from "./OwnerName.helpers";
import OwnershipUpdateBadge from "./OwnershipUpdateBadge";

interface OwnerNameProps extends Omit<PersonNameProps, "entity" | "deleted"> {
  owner: api.Owner;
  update?: api.OwnershipUpdate;
  screeningType?: api.ScreeningType;
  searchTerm?: string;
}

const OwnerName = (props: OwnerNameProps) => {
  const { owner, update, screeningType, searchTerm, ...personNameProps } = props;

  const f = useFormatMessage();

  // if there is an update OR that owner was previously updated from the ownership registry
  const showUpdateFromOwnersRegistry = update || isUpdatedByOwnershipRegistry(owner);

  const additionalSubtitles: Subtitle[] = [];

  if (showUpdateFromOwnersRegistry) {
    additionalSubtitles.push({
      subtitle: f("ownership-update.description.update", { year: LATEST_OWNERSHIP_REGISTRY_RECORD_YEAR }),
      dateString: LATEST_OWNERSHIP_REGISTRY_DATE.toString()
    });
  }

  const ownerName = isEmptyString(getOwnerName(owner)) ? f("default.unknown") : getOwnerName(owner);

  const uboTooltip = owner.custom_is_ubo ? f("common.labels.manual-ubo") : f("screening.owners.is_ubo.description");

  return (
    <RowHighlighter highlight={update !== undefined}>
      <PersonName
        entity={owner}
        type={owner.company ? "company" : owner.person ? "person" : undefined}
        name={ownerName}
        deleted={owner.deleted === true}
        screeningType={screeningType}
        additionalSubtitles={additionalSubtitles}
        uboTooltip={uboTooltip}
        searchTerm={searchTerm}
        {...personNameProps}
      >
        <OwnershipUpdateBadge update={update} />
      </PersonName>
    </RowHighlighter>
  );
};

export default OwnerName;
