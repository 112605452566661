import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  ColumnContentType,
  DataTable,
  HorizontalStack,
  SortDirection,
  Spinner,
  Text,
  VerticalStack
} from "@shopify/polaris";

import api from "../../api";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import { Entity } from "../../types/utilities";
import { cleanupFilename, isPDFAttachment } from "../../utils/filenameUtils";
import AssignmentInfo from "../AssignmentInfo/AssignmentInfo";
import CondensedText from "../CondensedText/CondensedText";
import CustomDate from "../CustomDate/CustomDate";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

import AttachmentActions from "./AttachmentActions/AttachmentActions";
import AttachmentDownloadButton from "./AttachmentDownloadButton";
import AttachmentSignaturesStatus from "./AttachmentSignaturesStatus";
import AttachmentUploader from "./AttachmentUploader";

type AttachmentsTableProps = {
  entity: Entity;
  attachments: api.AttachmentDetails[];
  uploadingFilesCount: number;
  readonly?: boolean;
  hideSignatures?: boolean;
  onAttachmentSelected(attachment: api.AttachmentDetails): void;
};

const AttachmentsTable = (props: AttachmentsTableProps) => {
  const { entity, attachments, uploadingFilesCount, readonly, hideSignatures, onAttachmentSelected } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const { getUserDisplayName } = useGetUserName();
  const { formatDate } = useIntl();

  const [sortState, setSortState] = useState<{ headerIndex: number; direction: SortDirection }>({
    headerIndex: 0,
    direction: "none"
  });

  const signaturesEnabled = features.ELECTRONIC_SIGNATURES && !hideSignatures;

  const handleStatusClick = (attachment: api.AttachmentDetails) => {
    const isPDF = isPDFAttachment(attachment);

    if (isPDF) {
      onAttachmentSelected(attachment);
    }
  };

  const renderRow = (attachment: api.AttachmentDetails) => {
    const row = [
      <AttachmentDownloadButton entity={entity} attachment={attachment} />,
      <Text as="span" truncate>
        <VerticalStack>
          <TooltipWrapper content={attachment.assignment_name}>
            <AssignmentInfo
              assignmentName={attachment.assignment_name}
              assignmentPurpose={attachment.assignment_purpose}
              isArchived={attachment.assignment_is_archived}
            />
          </TooltipWrapper>
          <Text as={"span"} truncate>
            <CondensedText>
              <AttachmentUploader user={attachment.created_by} />
            </CondensedText>
          </Text>
        </VerticalStack>
      </Text>,
      <CustomDate date={new Date(attachment.created_dt).toString()} />
    ];

    if (signaturesEnabled) {
      row.push(<AttachmentSignaturesStatus attachment={attachment} onClick={() => handleStatusClick(attachment)} />);
    }

    row.push(
      <AttachmentActions
        entity={entity}
        attachment={attachment}
        readonly={readonly}
        signaturesEnabled={signaturesEnabled}
        onSelectAttachment={onAttachmentSelected}
      />
    );

    return row;
  };

  const tableContentTypes: ColumnContentType[] = signaturesEnabled
    ? ["text", "text", "text", "text", "numeric"]
    : ["text", "text", "text", "numeric"];

  const tableHeadings = [
    f("attachments.table.header.document"),
    `${f("attachments.table.header.assignment")} / ${f("attachments.table.header.created.by")}`,
    f("attachments.table.header.created.date")
  ];

  if (signaturesEnabled) {
    tableHeadings.push(f("attachments.table.header.signature-status"));
  }

  // an empty header for the actions column
  tableHeadings.push("");

  const getSortValue = useCallback(
    (attachment: api.AttachmentDetails) => {
      switch (sortState.headerIndex) {
        case 0:
          return cleanupFilename(attachment.filename) || "";
        case 1:
          return attachment.assignment_name || getUserDisplayName(attachment.created_by);
        case 2:
          return formatDate(new Date(attachment.created_dt));
        case 3:
          return attachment.signature_status || "";
        default:
          return "";
      }
    },
    [sortState.headerIndex]
  );

  const rows = useMemo(() => {
    const sortedAttachments = [...attachments];

    if (sortState.direction !== "none") {
      sortedAttachments.sort(
        (a1, a2) => getSortValue(a1).localeCompare(getSortValue(a2)) * (sortState.direction === "ascending" ? 1 : -1)
      );
    }

    return sortedAttachments.map((attachment) => renderRow(attachment));
  }, [attachments, sortState.headerIndex, sortState.direction]);

  const handleSort = (headerIndex: number, direction: SortDirection) => setSortState({ headerIndex, direction });

  const rowsToRender =
    uploadingFilesCount > 0
      ? [
          [
            <HorizontalStack gap="4">
              <Spinner size={"small"} />
              <Text as="span">{f("attachments.table.messages.uploading", { count: uploadingFilesCount })}</Text>
            </HorizontalStack>
          ],
          ...rows
        ]
      : rows;

  return (
    <DataTable
      truncate
      columnContentTypes={tableContentTypes}
      headings={tableHeadings}
      rows={rowsToRender}
      sortable={[true, true, true, true, true, false]}
      onSort={handleSort}
      hideScrollIndicator
    />
  );
};

export default AttachmentsTable;
