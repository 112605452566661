import React from "react";
import { Spinner, Truncate } from "@shopify/polaris";
import styled from "styled-components";

import DocumentIcon from "../../../assets/icons/doc-text.svg";
import InfoBlueIcon from "../../../assets/icons/info-blue.svg";
import api from "../../api";
import useGetQuestionnairesSummary from "../../hooks/useGetQuestionnairesSummary";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface QuestionnaireDisplayNameProps {
  questionnaireId?: string;
  questionnaire?: api.QuestionnaireSummary;
}

const QuestionnaireDisplayName = (props: QuestionnaireDisplayNameProps) => {
  const { questionnaireId, questionnaire: questionnaireName, ...rest } = props;

  const { questionnairesSummary, isLoading } = useGetQuestionnairesSummary();

  if (!questionnaireId && !questionnaireName) {
    return null;
  }

  if (isLoading) {
    return <Spinner size="small" />;
  }

  const questionnaire =
    questionnaireName || questionnairesSummary.find((questionnaire) => questionnaire.id === questionnaireId);

  if (!questionnaire) {
    return null;
  }

  return (
    <StyledWrapper title={questionnaire.name} {...rest}>
      <Icon source={DocumentIcon} />
      <Truncate>{questionnaire.name}</Truncate>
      {questionnaire.description && (
        <TooltipWrapper content={questionnaire.description} dismissOnMouseOut preferredPosition="above">
          <Icon source={InfoBlueIcon} width="17px" height="16px" />
        </TooltipWrapper>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--p-space-1);
`;

export default QuestionnaireDisplayName;
