import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS, STALE_TIME } from "../constants/queries-keys";

const useGetCountryRiskAndTaxHavens = () => {
  const getCountryRiskAndTaxHavens = useQuery(
    [QUERIES_KEYS.COUNTRY_RISK_AND_TAX_HAVENS],
    () => api.getCountryRiskAndTaxHavens(),
    { staleTime: STALE_TIME }
  );

  const highRiskCountries = getCountryRiskAndTaxHavens.data?.countries || {};

  const getCountryRiskAndTaxHavenList = (country_code?: api.CountryEnum): api.DefinedCountryRiskSourceEnum[] => {
    if (!country_code) {
      return [];
    }

    const highRiskCountriesListNames: api.DefinedCountryRiskSourceEnum[] = [];

    const countryEntries = highRiskCountries[country_code];

    if (countryEntries) {
      highRiskCountriesListNames.push(...(Object.keys(countryEntries) as api.DefinedCountryRiskSourceEnum[]));
    }

    return highRiskCountriesListNames;
  };

  return { getCountryRiskAndTaxHavenList };
};

export default useGetCountryRiskAndTaxHavens;
