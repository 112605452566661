import { HorizontalStack, Text } from "@shopify/polaris";

import IconApproved from "../../../assets/icons/checkmark-green.svg";
import IconRejected from "../../../assets/icons/subtract-red.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Customer } from "../../types/utilities";
import Icon from "../extensions/Icon";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

const ReviewStatusIcons: Record<api.ReviewStatus, string | undefined> = {
  APPROVED: IconApproved,
  REJECTED: IconRejected,
  NOT_READY: undefined,
  REVIEW_REQUIRED: undefined
};

interface CustomerApprovalStatusProps {
  customer: Customer;
  detailed?: boolean;
}

const CustomerApprovalStatus = (props: CustomerApprovalStatusProps) => {
  const { customer, detailed, ...rest } = props;

  const { review_status } = customer;
  const f = useFormatMessage();

  const icon = ReviewStatusIcons[review_status];
  const title = f(`review.status.${review_status}`);

  // in detailed view - render status with a label
  if (detailed) {
    return (
      <HorizontalStack gap="1" wrap={false} blockAlign="center">
        {icon && <Icon source={icon} height={"16px"} width={"16px"} />}
        <Text as="span">{title}</Text>
      </HorizontalStack>
    );
  }

  if (review_status !== "APPROVED" && review_status !== "REJECTED") {
    return null;
  }

  // in status mode - render status as an icon with a tooltip
  return (
    <div {...rest}>
      <TooltipWrapper content={title} preferredPosition={"above"} dismissOnMouseOut>
        {icon && <Icon source={icon} height={"16px"} width={"16px"} />}
      </TooltipWrapper>
    </div>
  );
};

export default CustomerApprovalStatus;
