import { generatePath } from "react-router-dom";

import api from "../api";
import { ONBOARDING_STATUSES } from "../constants/onboarding";
import { ROUTES } from "../constants/routes";
import { getCompanyPath, getManualCompanyPath } from "../helpers/navigation.helpers";

const useCustomerPath = () => {
  const getCustomerPath = (
    customerId: string,
    customerType: api.CustomerType,
    onboardingStatus?: api.OnboardingStatus,
    isManual?: boolean
  ) => {
    const isOnboarding = onboardingStatus && ONBOARDING_STATUSES.includes(onboardingStatus);

    if (customerType === "person") {
      if (isOnboarding) {
        return generatePath(ROUTES.ONBOARDING_SAVED_PERSON, { id: customerId });
      } else {
        return generatePath(ROUTES.PERSON_DETAILS, { id: customerId });
      }
    }

    // If onboarding a company manually, the link should start with "manual-companies"
    const isManualCompany = isOnboarding && customerType === "company" && isManual;

    if (isManualCompany) {
      return getManualCompanyPath(customerId, onboardingStatus);
    } else {
      return getCompanyPath(customerId, onboardingStatus);
    }
  };

  return { getCustomerPath };
};

export default useCustomerPath;
