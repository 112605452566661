import React from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

interface StopScreeningModalProps {
  id: string;
  isOpen: boolean;
  isPerson?: boolean;
  onStop(): void;
  onClose(): void;
}

const StopScreeningModal = (props: StopScreeningModalProps) => {
  const { id, isOpen, isPerson = false, onStop, onClose } = props;

  const f = useFormatMessage();

  if (!isOpen) {
    return null;
  }

  return (
    <ConfirmAction
      isDestructive
      isOpen={isOpen}
      actionTitle={f("change-screening.buttons.stop-monitoring")}
      cancelTitle={f("default.close")}
      title={f("change-screening.stop-screening.title")}
      apiCall={() => (isPerson ? api.stopPersonScreening(id) : api.stopCompanyScreening(id))}
      description={
        isPerson
          ? f("change-screening.stop-screening.description-person")
          : f("change-screening.stop-screening.description")
      }
      onNo={onClose}
      onSuccess={onStop}
    />
  );
};

export default StopScreeningModal;
