import React from "react";
import { Checkbox, HorizontalStack, Text, TextField, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useChangedState from "../../hooks/useChangedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import { ValueOf } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import Button from "../extensions/Button";

type CompleteMeasureFormProps = {
  measure: api.Measure;
  readonly?: boolean;
  isLoading?: boolean;
  autoFocus?: boolean;
  onSave(measure: api.Measure): void;
  onCancel(measure: api.Measure): void;
};

const CompleteMeasureForm = (props: CompleteMeasureFormProps) => {
  const { measure, readonly, isLoading, autoFocus, onSave, onCancel } = props;

  const f = useFormatMessage();

  // mark measure that is edited for the first time as completed by default
  const isCompleted = measure.is_completed || (!measure.updated_by && isEmptyString(measure.completed_comment));

  const [workingMeasure, setWorkingMeasure, changed] = useChangedState({
    ...measure,
    completed_comment: measure.completed_comment || "",
    is_completed: isCompleted
  });

  const handleMeasureChange = (field: keyof api.UpdateMeasureRequest, value: ValueOf<api.UpdateMeasureRequest>) =>
    setWorkingMeasure({ ...workingMeasure, [field]: value });

  const save = () => onSave(workingMeasure);
  const cancel = () => onCancel({ ...measure });

  const disabled = readonly || isLoading;

  const invalid = workingMeasure.is_completed && isEmptyString(workingMeasure.completed_comment);

  const isSaveDisabled = readonly || invalid || !changed;

  return (
    <VerticalStack gap="4">
      <VerticalStack gap="2">
        <Text as="span" fontWeight="semibold">
          {f("details.reviews.measures.form.description.label")}
        </Text>
        <TextField
          label={f("details.reviews.measures.form.description.label")}
          labelHidden
          placeholder={f("details.reviews.measures.form.description.placeholder")}
          multiline={4}
          value={workingMeasure.completed_comment}
          onChange={(value) => handleMeasureChange("completed_comment", value)}
          autoComplete="off"
          autoFocus={autoFocus}
          disabled={disabled}
        />
      </VerticalStack>

      <HorizontalStack align={"space-between"}>
        <VerticalStack>
          <Text as="span" fontWeight="semibold">
            {f("details.reviews.measures.form.completed.label")}
          </Text>
          <Checkbox
            label={f("default.yes")}
            checked={workingMeasure.is_completed}
            onChange={(value) => handleMeasureChange("is_completed", value)}
            disabled={disabled}
          />
        </VerticalStack>
        <HorizontalStack gap="4" blockAlign="end">
          <Button onClick={cancel} size="slim" disabled={isLoading}>
            {f("default.cancel")}
          </Button>
          <Button onClick={save} size="slim" primary disabled={isSaveDisabled} loading={isLoading}>
            {f("default.save")}
          </Button>
        </HorizontalStack>
      </HorizontalStack>
    </VerticalStack>
  );
};

export default CompleteMeasureForm;
