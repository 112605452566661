import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { Card } from "@shopify/polaris";
import styled from "styled-components";

export interface PopoverStickyWrapperProps {
  filter?: ReactNode;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
}

const PopoverStickyWrapper = (props: PropsWithChildren<PopoverStickyWrapperProps>) => {
  const { filter, width = "25ch", height = "18.75rem", children } = props;

  return (
    <StyledFormWrapper $width={width} $height={height}>
      <Card>
        {filter && <Card.Section>{filter}</Card.Section>}
        {children && <Card.Section>{children}</Card.Section>}
      </Card>
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled.div<{ $width: CSSProperties["width"]; $height?: CSSProperties["height"] }>`
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: auto;

  & > .Polaris-Card {
    flex: 1;
    ƒlex: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & .Polaris-Card__Section:first-of-type {
    padding-bottom: var(--p-space-1);
  }

  & .Polaris-Card__Section:last-of-type {
    flex: auto;
    overflow-y: auto;
  }

  & .Polaris-Card__Section:last-of-type > :first-child {
    overflow-x: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
`;

export default PopoverStickyWrapper;
