import React from "react";
import { Banner, HorizontalStack, Text } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";

interface UnreviewedMatchCasesBannerProps {
  unresolvedCaseCount: number;
  onNavigateToMatchesSection(): void;
}

const UnreviewedMatchCasesBanner = (props: UnreviewedMatchCasesBannerProps) => {
  const { unresolvedCaseCount, onNavigateToMatchesSection } = props;

  const f = useFormatMessage();

  return (
    <Banner status="info">
      <HorizontalStack blockAlign="center" align="space-between">
        <Text as="span">
          {f("reviews.header.banner.unreviewed-match-cases.description", { count: unresolvedCaseCount })}
        </Text>
        <Button plain onClick={onNavigateToMatchesSection}>
          {f("reviews.header.banner.unreviewed-match-cases.button.label", { count: unresolvedCaseCount })}
        </Button>
      </HorizontalStack>
    </Banner>
  );
};
export default UnreviewedMatchCasesBanner;
