import { useQuery } from "@tanstack/react-query";

import api from "../../../api";
import { QUERIES_KEYS, STALE_TIME } from "../../../constants/queries-keys";

// set the stale time to 1 hour to avoid multiple calls to listMeasures
const useGetMeasures = () => {
  return useQuery([QUERIES_KEYS.MEASURES_SETTINGS], api.getGlobalMeasureConfigs, {
    staleTime: STALE_TIME
  });
};

export default useGetMeasures;
