import React from "react";

import api from "../../../api";
import ConfirmAction from "../../../components/ConfirmAction/ConfirmAction";
import { QUERIES_KEYS } from "../../../constants/queries-keys";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useQueryData from "../../../hooks/useQueryData";

interface ProjectEndConfirmationProps {
  project: api.Project;
  onClose(): void;
}

const ProjectEndConfirmation = (props: ProjectEndConfirmationProps) => {
  const { project, onClose } = props;

  const { setQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, project.id]);
  const f = useFormatMessage();

  return (
    <ConfirmAction<api.Project>
      apiCall={() => api.updateProject(project.id, { status: "INACTIVE" })}
      onSuccess={setQueryData}
      title={f("project.end.confirmation.title")}
      description={f("project.end.confirmation.description", { name: project.name })}
      actionTitle={f("project.end.confirmation.action.label", { name: project.name })}
      onNo={onClose}
    />
  );
};

export default ProjectEndConfirmation;
