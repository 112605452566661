import React from "react";
import { Card, Page, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import CardNavigationSection from "../../components/CardNavigationSection/CardNavigationSection";
import CollapsibleSection from "../../components/CollapsibleSection/CollapsibleSection";
import CustomerDetailsHeader from "../../components/CustomerDetailsHeader/CustomerDetailsHeader";
import CustomerFeaturesSection from "../../components/CustomerFeaturesSection/CustomerFeaturesSection";
import CustomerNotesSection from "../../components/CustomerNotesSection/CustomerNotesSection";
import CustomerReviewsSection from "../../components/CustomerReviewsSection/CustomerReviewsSection";
import DetailsSkeleton from "../../components/DetailsSkeleton/DetailsSkeleton";
import DisclosureDocumentSection from "../../components/DisclosureDocumentSection/DisclosureDocumentSection";
import MatchCasesSection from "../../components/MatchCasesSection/MatchCasesSection";
import NavigationSection from "../../components/NavigationSection/NavigationSection";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getPersonDisplayName } from "../../helpers/display.helpers";
import { isOnboardingFinished } from "../../helpers/onboarding.helpers";
import { isScreeningActive, isScreeningStarting } from "../../helpers/screening";
import useAppTitle from "../../hooks/useAppTitle";
import useCustomerDetailsRefresh from "../../hooks/useCustomerDetailsRefresh";
import useFormatMessage from "../../hooks/useFormatMessage";

import PersonInfoSection from "./PersonInfoSection";

const PersonDetailsPage = ({ data: person }: AsyncPageChild<api.PersonCustomerDetailResponse>) => {
  const f = useFormatMessage();

  useAppTitle(getPersonDisplayName(person));

  useCustomerDetailsRefresh(person);

  const hasFinishedOnboarding = isOnboardingFinished(person);
  const readonly = person.is_archived || !hasFinishedOnboarding;

  return (
    <Page>
      <VerticalStack gap="4">
        <Card>
          <Card.Section>
            <NavigationSection anchor={PAGE_ANCHORS.TOP_SECTION}>
              <CustomerDetailsHeader customer={person} />
            </NavigationSection>
          </Card.Section>
          <Card.Section flush>
            <NavigationSection anchor={PAGE_ANCHORS.CUSTOMER_DETAILS}>
              <CollapsibleSection
                showPrefix
                title={
                  <Text variant="bodyMd" as="span">
                    {f("person.details.title")}
                  </Text>
                }
              >
                <PersonInfoSection person={person} readonly={readonly} />
              </CollapsibleSection>
            </NavigationSection>
          </Card.Section>
        </Card>

        <CardNavigationSection anchor={PAGE_ANCHORS.CUSTOMER_MATCH_CASES}>
          <MatchCasesSection
            customerId={person.id}
            customerType={person.type!}
            hasFinishedOnboarding={hasFinishedOnboarding}
            matchCases={person.match_cases}
            isScreeningStarting={isScreeningStarting(person.screening)}
            isScreeningActive={isScreeningActive(person.screening)}
            readonly={readonly}
          />
        </CardNavigationSection>

        <CardNavigationSection anchor={PAGE_ANCHORS.DISCLOSURES_SECTION}>
          <DisclosureDocumentSection
            customerId={person.id}
            customerType="person"
            disclosureDocuments={person.disclosure_documents}
            readonly={readonly}
            addButtonLabel={f("common.buttons.actions.new-disclosure")}
            emptyLabel={f("disclosure-documents.empty")}
            assignments={person.assignments}
          />
        </CardNavigationSection>

        <CardNavigationSection>
          <CustomerFeaturesSection customer={person} readonly={readonly} />
        </CardNavigationSection>

        <CardNavigationSection anchor={PAGE_ANCHORS.REVIEWS_SECTION} bottom>
          <CustomerReviewsSection customer={person} readonly={readonly} />
        </CardNavigationSection>

        <SectionMargin size={"medium"} />

        <CardNavigationSection>
          <CustomerNotesSection customer={person} readonly={readonly} />
        </CardNavigationSection>
      </VerticalStack>
    </Page>
  );
};

export default withAsyncPage<api.PersonCustomerDetailResponse>(PersonDetailsPage, {
  queryKey: QUERIES_KEYS.PERSON_DETAILS,
  apiFunction: api.getPersonCustomerDetails,
  paramNames: ["id"],
  skeleton: <DetailsSkeleton />
});
