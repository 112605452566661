import React from "react";
import { HorizontalStack } from "@shopify/polaris";
import { CaretDownMinor, CaretUpMinor } from "@shopify/polaris-icons";
import { flexRender, Header } from "@tanstack/react-table";
import classNames from "classnames";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isNumber } from "../../utils/numbersUtils";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";

interface TableHeaderCellProps<T> {
  header: Header<T, unknown>;
  expandable: boolean;
  onSortChange(header: Header<T, unknown>): void;
}

function TableHeaderCell<T>(props: TableHeaderCellProps<T>) {
  const { header, expandable, onSortChange } = props;

  const f = useFormatMessage();

  const table = header.getContext().table;

  const className = classNames("Polaris-DataTable__Cell Polaris-DataTable__Cell--header", {
    "Polaris-DataTable__Cell--firstColumn": expandable,
    "Polaris-DataTable__Cell--sortable": header.column.getCanSort(),
    "Polaris-DataTable__Cell--sorted": header.column.getIsSorted()
  });

  const headerLabel = header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());

  const canExpand = expandable && table.getCanSomeRowsExpand();

  const size = header.column.columnDef.size;

  // @ts-ignore
  const centered = header.column.columnDef.meta?.centered === true;

  return (
    <StyledTableHead key={header.id} className={className} colSpan={header.colSpan} size={size}>
      <HorizontalStack gap="1" blockAlign="center" align={centered ? "center" : undefined}>
        {canExpand && (
          <Button size={"slim"} onClick={() => table.toggleAllRowsExpanded()}>
            {table.getIsAllRowsExpanded() ? f("common.labels.collapse-all") : f("common.labels.expand-all")}
          </Button>
        )}
        {!header.column.getCanSort() && <StyledTableHeader>{headerLabel}</StyledTableHeader>}
        {header.column.getCanSort() && (
          <StyledHeaderButton className="Polaris-DataTable__Heading" onClick={() => onSortChange(header)}>
            <StyledTableHeader>{headerLabel}</StyledTableHeader>
            {header.column.getIsSorted() && (
              <span className="Polaris-DataTable__Icon">
                <Icon source={header.column.getIsSorted() === "asc" ? CaretUpMinor : CaretDownMinor} />
              </span>
            )}
          </StyledHeaderButton>
        )}
      </HorizontalStack>
    </StyledTableHead>
  );
}

const StyledTableHead = styled.th<{ size?: number }>`
  width: ${({ size }) => (size && isNumber(size) ? `${size}%` : undefined)};

  // For small viewports - hide table headers (they will be shown above each cell)
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const StyledHeaderButton = styled.button`
  display: flex;
  align-items: center;
`;

const StyledTableHeader = styled.div`
  white-space: normal;
  line-height: 1.2;
  user-select: none;
`;

export default TableHeaderCell;
