import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Modal, Toast } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import ProjectIcon from "../../../../assets/icons/folder.svg";
import api from "../../../api";
import { QUERIES_KEYS } from "../../../constants/queries-keys";
import { ROUTES } from "../../../constants/routes";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import useQueryData, { useCustomerDetailsQueryData } from "../../../hooks/useQueryData";
import { Customer } from "../../../types/utilities";
import ErrorPanel from "../../ErrorPanel/ErrorPanel";

import ProjectsResourceList from "./ProjectsResourceList";

interface AddToProjectModalProps {
  customers: Customer[];
  hideCustomersCount?: boolean;
  onClose(): void;
}

const AddToProjectModal = (props: AddToProjectModalProps) => {
  const { customers, hideCustomersCount, onClose } = props;

  const f = useFormatMessage();
  const history = useHistory();

  const [showSuccessToast, toggleSuccessToast, closeSuccessToast] = useOpenClose();
  const [selectedProjectsIds, setSelectedProjectIds] = useState<string[]>([]);
  const [selectedProject, setSelectedProject] = useState<api.Project>();
  const [page, setPage] = useState(0);

  const updateQueryData = useCustomerDetailsQueryData(customers[0]);
  const { setQueryData } = useQueryData<api.Project>([QUERIES_KEYS.PROJECT_DETAILS, selectedProjectsIds[0]]);

  const assignToProjectMutation = useMutation(
    async () => {
      const project = await api.getProject(selectedProjectsIds[0]);

      const existingCustomersIds = project.customers.map((customer) => customer.id);
      const selectedCustomersIds = customers.map((customer) => customer.id);
      const customersIds = [...new Set([...existingCustomersIds, ...selectedCustomersIds])];

      return api.updateProject(selectedProjectsIds[0], { customer_ids: customersIds });
    },
    {
      onSuccess: (updatedProject) => {
        setQueryData(updatedProject);
        setSelectedProject(updatedProject);

        // if a single customer was provided, update the customer details
        if (customers.length === 1) {
          updateQueryData(
            (queryData) =>
              (queryData.projects = [
                ...(queryData.projects || []),
                { ...updatedProject, customer_count: updatedProject.customers.length }
              ])
          );
        }

        toggleSuccessToast();
      }
    }
  );

  const saveAction = {
    content: f("default.add"),
    onAction: () => assignToProjectMutation.mutate(),
    disabled: assignToProjectMutation.isLoading || selectedProjectsIds.length === 0,
    loading: assignToProjectMutation.isLoading
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: () => onClose(),
    disabled: assignToProjectMutation.isLoading
  };

  const navigateToProjectAction = {
    content: selectedProject?.name || "",
    icon: ProjectIcon,
    onAction: () => history.push(generatePath(ROUTES.PROJECT_DETAILS, { id: selectedProject!.id })),
    disabled: !selectedProject
  };

  const handleToastDismiss = () => {
    closeSuccessToast();
    onClose();
  };

  return (
    <>
      <Modal
        // if the toast is visible, hide the modal;
        open={!showSuccessToast}
        title={f("projects.modal.title")}
        onClose={onClose}
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
        onScrolledToBottom={() => setPage(page + 1)}
        footer={
          hideCustomersCount ? undefined : f("common.labels.selected-customers-count", { count: customers.length })
        }
      >
        <Modal.Section>
          <ProjectsResourceList
            selectedIds={selectedProjectsIds}
            page={page}
            resetPage={() => setPage(0)}
            onSelectionChange={setSelectedProjectIds}
          />
        </Modal.Section>
        {assignToProjectMutation.isError && <ErrorPanel message={assignToProjectMutation.error} />}
      </Modal>
      {showSuccessToast && (
        <Toast
          content={f("projects.modal.success.message", { count: customers.length })}
          action={navigateToProjectAction}
          onDismiss={handleToastDismiss}
          duration={8_000}
        />
      )}
    </>
  );
};

export default AddToProjectModal;
