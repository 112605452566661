import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";
import AddButton from "../AddButton/AddButton";

const FloatPrimaryButton = styled(AddButton)`
  float: right;

  // For small viewports - render button as a regular block element
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    float: revert;
    display: inline-block;
  }
`;

export default FloatPrimaryButton;
