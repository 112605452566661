// used to change the minimum search text field width in order to show all the placeholder text
import styled from "styled-components";

const FilterContainer = styled.div`
  display: flex;

  & .Polaris-TextField {
    min-width: 25vw;
  }
`;

export default FilterContainer;
