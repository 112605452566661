import React, { useState } from "react";
import { Button, Checkbox, HorizontalStack, Modal, Stack, Text, TextField, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import { isValidOwner } from "../../helpers/owners.helpers";
import {
  convertPersonToCreateRequest,
  convertPersonToUpdateRequest,
  isUBO,
  isUserCreatedPerson
} from "../../helpers/person.helpers";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useRestoreOwnerMutation from "../../hooks/useRestoreOwnerMutation";
import useSnackbar from "../../hooks/useSnackbar";
import { ValueOf } from "../../types/utilities";
import { isNumber, isNumberInRange } from "../../utils/numbersUtils";
import CondensedText from "../CondensedText/CondensedText";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import KDKTextField from "../KDKTextField/KDKTextField";
import OwnerArchiveConfirm from "../OwnerArchiveConfirm/OwnerArchiveConfirm";
import PersonForm from "../PersonForm/PersonForm";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import UBOManualOverride from "../UBOManualOverride/UBOManualOverride";

export type OwnerModalProps = {
  customerId: string;
  ownerData: api.Owner;
  isArchiveDisabled: boolean;
  onArchive(ownerId: string): void;
  onUpdate(data: api.Owner): void;
  onCreate(data: api.Owner): void;
  onClose(): void;
};

const OwnerModal = (props: OwnerModalProps) => {
  const { ownerData, customerId, isArchiveDisabled, onArchive, onUpdate, onCreate, onClose } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const { setSnackbar } = useSnackbar();
  const digits = features.HIGHER_ACCURACY_PERCENTAGES ? 3 : 2;
  const step = features.HIGHER_ACCURACY_PERCENTAGES ? 0.001 : 0.1;

  const formatPercentShare = (value?: string) => (isNumber(value) ? Number(value).toFixed(digits) : "");

  const [owner, setOwner] = useState(() => ({
    ...ownerData,
    percent_share: formatPercentShare(ownerData.percent_share)
  }));
  const [showArchiveConfirm, toggleArchiveConfirm, closeArchiveConfirm] = useOpenClose();
  const [isFormValid, setIsFormValid] = useState(owner.id !== undefined);

  const isUpdatingOwner = Boolean(owner.id);
  const isUserCreatedOwner = isUserCreatedPerson(owner);

  const saveOwnerMutation = useMutation(
    () => {
      const { percent_share, comment } = owner;
      const percentShare = isNumber(percent_share) ? parseFloat(percent_share) : undefined;

      return isUpdatingOwner && owner.id
        ? api.updateCompanyOwner(customerId, owner.id, {
            percent_share: percentShare,
            comment,
            custom_is_ubo: owner.custom_is_ubo,
            person: convertPersonToUpdateRequest(owner.person!)
          })
        : api.createCompanyOwner(customerId, {
            percent_share: percentShare || 0,
            comment,
            custom_is_ubo: owner.custom_is_ubo,
            person: convertPersonToCreateRequest(owner.person!)
          });
    },
    {
      onSuccess: isUpdatingOwner ? onUpdate : onCreate
    }
  );

  const restoreOwnerMutation = useRestoreOwnerMutation(customerId, owner);

  const handleRestore = () => {
    restoreOwnerMutation.mutate(undefined, {
      onSuccess: (updatedOwner) => {
        setSnackbar(f("modals.owner.messages.owner-restored", { name: getFullName(updatedOwner.person) }));
        onClose();
      }
    });
  };

  const handleChange = (key: keyof api.Owner, value?: ValueOf<api.Owner>) =>
    setOwner((prevOwner) => ({ ...prevOwner, [key]: value }));

  const saveNewOwner = () => saveOwnerMutation.mutate();

  const invalidPercentShareValue = !isNumberInRange(owner.percent_share, 0, 100);

  const isSaveDisabled = saveOwnerMutation.isLoading || !isFormValid || !isValidOwner(owner) || ownerData.deleted;

  const percentSharePlaceholder = features.HIGHER_ACCURACY_PERCENTAGES ? "0.000" : "0.00";

  const readonly = ownerData.deleted || saveOwnerMutation.isLoading;

  const hasError = saveOwnerMutation.isError || restoreOwnerMutation.isError;
  const error = saveOwnerMutation.error || restoreOwnerMutation.error;

  return (
    <Modal
      open
      large
      onClose={onClose}
      title={isUpdatingOwner ? f("modals.owner.title.update") : f("modals.owner.title.add")}
      primaryAction={{
        content: isUpdatingOwner ? f("default.save") : f("default.add"),
        onAction: saveNewOwner,
        disabled: isSaveDisabled,
        loading: saveOwnerMutation.isLoading
      }}
      secondaryActions={[
        {
          content: f("default.cancel"),
          onAction: onClose
        }
      ]}
      footer={
        <HorizontalStack gap="4" blockAlign="center">
          {isUpdatingOwner && (
            <TooltipWrapper
              content={isArchiveDisabled ? f("modals.owner.tooltips.archive-disabled") : undefined}
              preferredPosition="above"
            >
              <Button destructive disabled={isArchiveDisabled || readonly} onClick={toggleArchiveConfirm}>
                {f("default.archive")}
              </Button>
            </TooltipWrapper>
          )}
          {ownerData.deleted && (
            <Button loading={restoreOwnerMutation.isLoading} onClick={handleRestore}>
              {f("default.restore")}
            </Button>
          )}
          {hasError && <ErrorPanel message={error} />}
        </HorizontalStack>
      }
    >
      <Modal.Section>
        <VerticalStack gap="4">
          <Text variant="headingMd" as="h2">
            {f("modals.headings.personal-information")}
          </Text>
          <PersonForm<api.OwnerPerson>
            value={owner.person!}
            disabled={readonly}
            birthDateIsRequired={isUserCreatedOwner}
            isCreating={isUserCreatedOwner}
            allowBirthYear={!isUserCreatedOwner}
            onChange={(person) => setOwner((prevOwner) => ({ ...prevOwner, person }))}
            onValidityChange={setIsFormValid}
            requiredFields={["country"]}
          />
        </VerticalStack>
      </Modal.Section>

      <Modal.Section>
        <VerticalStack gap="4">
          <VerticalStack>
            <Text variant="headingMd" as="h2">
              {f("modals.owner.headings.ownership-in-company")}
            </Text>
            {owner.is_ubo && <CondensedText italic>{f("common.labels.calculated-ubo")}</CondensedText>}
          </VerticalStack>
          <Stack distribution={"fillEvenly"}>
            <KDKTextField
              error={invalidPercentShareValue ? f("default.invalid") : undefined}
              placeholder={percentSharePlaceholder}
              min={0}
              max={100}
              label={f("modals.owner.labels.percent_share")}
              value={owner.percent_share || ""}
              onBlur={() => handleChange("percent_share", formatPercentShare(owner.percent_share))}
              onChange={(newValue) => handleChange("percent_share", newValue)}
              disabled={readonly}
              suffix={"%"}
              step={step}
              type="number"
              autoComplete="off"
              requiredIndicator
            />
            {/* use 2 empty stack items to create a 3 columns layout */}
            <Stack.Item />
            <Stack.Item />
          </Stack>
          <VerticalStack gap="6">
            <UBOManualOverride
              checked={isUBO(owner)}
              onChange={(newValue) => handleChange("custom_is_ubo", newValue)}
              disabled={readonly}
            />
            <VerticalStack inlineAlign="start">
              <Checkbox
                label={f("common.labels.mark-person-as-pep")}
                checked={owner.person?.is_pep}
                onChange={(newValue) =>
                  setOwner((prevOwner) => ({ ...prevOwner, person: { ...prevOwner.person!, is_pep: newValue } }))
                }
                disabled={readonly}
              />
              <Checkbox
                label={f("common.labels.mark-person-as-sanctioned")}
                checked={owner.person?.is_sanctioned}
                onChange={(newValue) =>
                  setOwner((prevOwner) => ({ ...prevOwner, person: { ...prevOwner.person!, is_sanctioned: newValue } }))
                }
                disabled={readonly}
              />
            </VerticalStack>
          </VerticalStack>
        </VerticalStack>
      </Modal.Section>
      <Modal.Section>
        <TextField
          placeholder={f("common.labels.comment")}
          label={f("common.labels.comment")}
          multiline={3}
          value={owner.comment || ""}
          onChange={(newValue) => handleChange("comment", newValue)}
          disabled={readonly}
          autoComplete="off"
        />
      </Modal.Section>
      {showArchiveConfirm && (
        <OwnerArchiveConfirm
          customerId={customerId}
          owner={owner}
          onClose={closeArchiveConfirm}
          onAfterArchive={onArchive}
        />
      )}
    </Modal>
  );
};

export default OwnerModal;
