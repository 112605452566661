import React from "react";
import { Stack, Text, VerticalStack } from "@shopify/polaris";

import CompletedIcon from "../../../../assets/icons/checkmark-green.svg";
import WaitingIcon from "../../../../assets/icons/hourglass.svg";
import ExpiredIcon from "../../../../assets/icons/subtract-red.svg";
import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import CondensedText from "../../CondensedText/CondensedText";
import Icon from "../../extensions/Icon";

interface DisclosureStatusProps {
  signature: api.Signature;
}
const DisclosureStatus = (props: DisclosureStatusProps) => {
  const { signature } = props;

  const f = useFormatMessage();

  const hasNextNotification = signature.status === "PENDING" && signature.next_notification_date;

  const getStatusInfo = () => {
    switch (signature.status) {
      case "SIGNED":
        return {
          statusIcon: CompletedIcon,
          statusLabel: f("disclosure-status.completed"),
          statusSubtitle: signature.signed_dt
            ? f("disclosure-card.completed.subtitle", { timestamp: new Date(signature.signed_dt) })
            : ""
        };
      case "PENDING":
        return {
          statusIcon: WaitingIcon,
          statusLabel: f("disclosure-status.waiting"),
          statusSubtitle: signature.previous_notification_date
            ? f("disclosure-card.notified.subtitle", { timestamp: new Date(signature.previous_notification_date) })
            : ""
        };
      case "EXPIRED":
        return {
          statusIcon: ExpiredIcon,
          statusLabel: f("disclosure-status.expired"),
          statusSubtitle: signature.previous_notification_date
            ? f("disclosure-card.notified.subtitle", { timestamp: new Date(signature.previous_notification_date) })
            : ""
        };
    }
  };

  const statusInfo = getStatusInfo();

  if (!statusInfo) {
    return null;
  }

  const { statusIcon, statusLabel, statusSubtitle } = statusInfo;

  return (
    <VerticalStack>
      <Stack spacing={"tight"} wrap={false}>
        <Icon source={statusIcon} />
        <Stack.Item fill>
          <Text as="span">{statusLabel}</Text>
        </Stack.Item>
      </Stack>
      <VerticalStack>
        <CondensedText>{statusSubtitle}</CondensedText>
        {hasNextNotification && (
          <CondensedText>
            {f("disclosure-card.next-notification.subtitle", {
              timestamp: new Date(signature.next_notification_date!)
            })}
          </CondensedText>
        )}
      </VerticalStack>
    </VerticalStack>
  );
};

export default DisclosureStatus;
