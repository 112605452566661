import React, { useState } from "react";
import { Banner, HorizontalStack, Text, Toast } from "@shopify/polaris";
import { MutationFunction, useMutation } from "@tanstack/react-query";

import ArrowCircularIcon from "../../../assets/icons/arrow-circular.svg";
import CheckMarkIcon from "../../../assets/icons/checkmark-green.svg";
import api from "../../api";
import { getFullName } from "../../helpers/display.helpers";
import useErrorParser from "../../hooks/useErrorParser";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { isEmptyString } from "../../utils/stringUtils";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import PersonPhoneNumberModal from "../OwnersCard/OwnersTable/VitecOwnerSync/PersonPhoneNumberModal";

interface VitecPersonSyncProps<T extends api.Role | api.Owner> {
  entity: T;
  isSyncedToVitec?: boolean;
  readonly?: boolean;
  syncToVitecAPICall: MutationFunction<T>;
  updatePersonAPICall: MutationFunction<T, string>;
  onAfterUpdate(entity: T): void;
}

function VitecPersonSync<T extends api.Role | api.Owner>(props: VitecPersonSyncProps<T>) {
  const { entity, isSyncedToVitec, readonly, syncToVitecAPICall, updatePersonAPICall, onAfterUpdate } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const [phoneNumber, setPhoneNumber] = useState(entity.person?.phone || "");
  const [showPhoneNumberModal, togglePhoneNumberModal, closePhoneNumberModal] = useOpenClose();
  const [showResultToast, toggleResultToast, closeResultToast] = useOpenClose();
  const parseError = useErrorParser("person");

  const syncToVitecMutation = useMutation(syncToVitecAPICall, {
    onSuccess: (updatedPerson) => {
      onAfterUpdate(updatedPerson);
      toggleResultToast();
    },
    onError: toggleResultToast
  });

  const { generalError } = parseError(syncToVitecMutation.error);

  const syncToVitec = (entityToSync = entity) => syncToVitecMutation.mutate(entityToSync);

  const handlePhoneNumberModalClose = () => {
    closePhoneNumberModal();
    setPhoneNumber(""); // clear
  };

  const handleUpdate = (entity: T) => {
    handlePhoneNumberModalClose();
    syncToVitec(entity);
  };

  const handleSyncButtonClick = () => {
    const isPhoneNumberMissing = !features.VITEC_ROLES_SYNC_WITHOUT_PHONE && isEmptyString(phoneNumber);

    if (isPhoneNumberMissing) {
      togglePhoneNumberModal();
    } else {
      syncToVitec();
    }
  };

  if (isSyncedToVitec) {
    return (
      <HorizontalStack gap="1" blockAlign="center" wrap={false}>
        <Icon source={CheckMarkIcon} />
        <Text as="span">{f("common.vitec.synced")}</Text>
      </HorizontalStack>
    );
  }

  return (
    <>
      <Button
        size="slim"
        icon={<Icon source={ArrowCircularIcon} useMask />}
        onClick={handleSyncButtonClick}
        loading={syncToVitecMutation.isLoading}
        disabled={readonly}
      >
        {f("common.vitec.synchronize")}
      </Button>
      {showPhoneNumberModal && (
        <PersonPhoneNumberModal<T>
          phoneNumber={phoneNumber}
          onChange={setPhoneNumber}
          apiCall={() => updatePersonAPICall(phoneNumber)}
          onUpdate={handleUpdate}
          onClose={handlePhoneNumberModalClose}
        >
          <Banner status="info" title={f("vitec.modal.banner.description")} />
        </PersonPhoneNumberModal>
      )}
      {showResultToast && (
        <Toast
          content={
            syncToVitecMutation.isError
              ? f("vitec.modal.toast.message.error", { name: getFullName(entity.person), error: generalError })
              : f("vitec.modal.toast.message.success", { name: getFullName(entity.person) })
          }
          onDismiss={closeResultToast}
          error={syncToVitecMutation.isError}
        />
      )}
    </>
  );
}

export default VitecPersonSync;
