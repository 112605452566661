import React from "react";
import * as Sentry from "@sentry/react";
import { Button, Page } from "@shopify/polaris";

import ErrorImage from "../../assets/images/error.svg";
import KDKEmptyState from "../components/KDKEmptyState/KDKEmptyState";
import useFormatMessage from "../hooks/useFormatMessage";
import { hasOwnProperty } from "../utils/util";

import NotFound from "./NotFound";

type ErrorPageProps = {
  error?: Error | unknown | null;
};

const ErrorPage = (props: ErrorPageProps) => {
  const { error } = props;
  const f = useFormatMessage();

  if (process.env.NODE_ENV === "production") {
    Sentry.captureException(error, { level: "warning" });
  } else {
    // Log error if it's not a production build
    console.error(error);
  }

  // Check if it's a 404-error
  let is404 = false;
  if (hasOwnProperty(error, "status")) {
    is404 = error.status === 404;
  } else if (hasOwnProperty(error, "response") && hasOwnProperty(error.response, "status")) {
    is404 = error.response.status === 404;
  }

  if (is404) {
    return <NotFound />;
  }

  return (
    <Page>
      <KDKEmptyState
        header={f("error.page.title")}
        description={f("error.page.description")}
        image={ErrorImage}
        altImage={"Unknown error"}
      >
        <Button primary onClick={() => window.location.reload()}>
          {f("common.labels.try-again")}
        </Button>
      </KDKEmptyState>
    </Page>
  );
};

export default ErrorPage;
