import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Badge, Button, Card, HorizontalStack, Layout, Page, Text, VerticalStack } from "@shopify/polaris";
import { useMutation, useQuery } from "@tanstack/react-query";

import api from "../../api";
import BackButton from "../../components/BackButton/BackButton";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import OwnersCard from "../../components/OwnersCard/OwnersCard";
import RolesCard from "../../components/RolesCard/RolesCard";
import ScreeningEmptyState from "../../components/ScreeningEmptyState/ScreeningEmptyState";
import ScreeningSkeleton from "../../components/ScreeningSkeleton/ScreeningSkeleton";
import ScreeningStatusBadge from "../../components/ScreeningStatusBadge/ScreeningStatusBadge";
import ScreeningTypeCard from "../../components/ScreeningTypeCard/ScreeningTypeCard";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import StopScreeningModal from "../../components/StopScreeningModal/StopScreeningModal";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getCompanyType } from "../../helpers/customer.helpers";
import { isOwnerPerson } from "../../helpers/owners.helpers";
import { isUBO } from "../../helpers/person.helpers";
import { isScreeningActive } from "../../helpers/screening";
import useCustomerDetailsBackButton from "../../hooks/useCustomerDetailsBackButton";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetCompanyScreeningConfiguration from "../../hooks/useGetCompanyScreeningConfiguration";
import useLanguage from "../../hooks/useLanguage";
import { IDParams } from "../../types/params";
import ErrorPage from "../ErrorPage";

const CompanyChangeScreeningPage = () => {
  const { id: customerId } = useParams<IDParams>();

  const [customerName, setCustomerName] = useState("");

  const [owners, setOwners] = useState<api.Owner[]>([]);
  const [roles, setRoles] = useState<api.Role[]>([]);

  const [screeningType, setScreeningType] = useState<api.ScreeningType>("MONITORING");
  const [screeningStatus, setScreeningStatus] = useState<api.ScreeningStatus>("MONITORING");
  const [isNewScreening, setIsNewScreening] = useState(false);

  const [isControlConfirmationActive, setControlConfirmationActive] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const f = useFormatMessage();
  const language = useLanguage();

  const { backButtonURL, goToCustomerDetails } = useCustomerDetailsBackButton(customerId, "company");

  // Get screening config and company customer details
  const companyCustomerDetailsQuery = useQuery([QUERIES_KEYS.COMPANY_DETAILS, customerId], () =>
    api.getCompanyCustomerDetails(customerId)
  );
  const companyScreeningConfigQuery = useGetCompanyScreeningConfiguration(customerId);

  useEffect(() => {
    if (companyScreeningConfigQuery.isLoading || !companyScreeningConfigQuery.data) {
      return;
    }

    const tmp = companyScreeningConfigQuery.data;

    setCustomerName(tmp.customer_name);

    const { beneficial_owners: tmp_owners, roles: tmp_roles } = tmp;

    setRoles(tmp_roles.map((r) => r.role));

    // Make all "active" (ie. not deleted) owners OR owners currently in screening config available for (de)selection
    setOwners(tmp_owners.filter((o) => !o.owner.deleted || o.screening_required).map((o) => o.owner));

    // Only preselect owners that currently are in the screening config
    // -> do not preselect other owners (eg. UBOs) to avoid confusion
    setSelectedOwners(
      tmp_owners
        .filter((beneficialOwner) => beneficialOwner.screening_required && isOwnerPerson(beneficialOwner.owner))
        .map((beneficialOwner) => beneficialOwner.owner.id!)
    );

    setSelectedRoles(tmp_roles.filter((r) => r.screening_required).map((r) => r.role.id!));

    if (tmp.screening_type) {
      setScreeningType(tmp.screening_type);
      setScreeningStatus(tmp.screening_status);
      setIsNewScreening(tmp.screening_status === "DONE");
    }
  }, [companyScreeningConfigQuery.data, companyScreeningConfigQuery.isLoading]);

  const changeScreeningMutation = useMutation(
    () =>
      api.updateCompanyScreeningConfiguration(customerId, {
        roles: selectedRoles,
        owners: selectedOwners,
        screening_type: screeningType
      }),
    {
      onSuccess: () => {
        companyScreeningConfigQuery.invalidateQuery();
        const snackbarMessage = isNewScreening ? f("screening.snackbar.started") : f("screening.snackbar.updated");
        goToCustomerDetails(snackbarMessage);
      }
    }
  );

  if (companyScreeningConfigQuery.isError || companyCustomerDetailsQuery.isError) {
    if (companyScreeningConfigQuery.isMaxRetriesReached) {
      return <ScreeningEmptyState />;
    } else {
      return <ErrorPage error={companyScreeningConfigQuery.error || companyCustomerDetailsQuery.error} />;
    }
  }

  const displaySkeleton =
    companyScreeningConfigQuery.isLoading ||
    !companyScreeningConfigQuery.data ||
    companyCustomerDetailsQuery.isLoading ||
    !companyCustomerDetailsQuery.data;

  if (displaySkeleton) {
    return (
      <ScreeningSkeleton
        progress={companyScreeningConfigQuery.loadingProgress}
        message={f("screening.messages.retrieving-details")}
      />
    );
  }

  const companyCustomer = companyCustomerDetailsQuery.data;

  const screening = {
    screening_status: screeningStatus,
    screening_type: screeningType
  };

  return (
    <Page>
      <VerticalStack gap="4">
        <BackButton url={backButtonURL} displayLabel={customerName} showAsLink />

        <Text variant="heading2xl" as="p">
          {!isNewScreening ? f("change-screening.titles.screening-title") : f("change-screening.titles.new-screening")}
        </Text>

        <Layout>
          <Layout.Section>
            <VerticalStack gap="4">
              {!isNewScreening && (
                <HorizontalStack blockAlign="start" align="space-between">
                  <HorizontalStack gap="2" blockAlign="center">
                    <Text variant="bodyMd" as="span" fontWeight="semibold">
                      {f("default.status")}:
                    </Text>
                    <Text variant="bodyMd" as="span">
                      {f(`screening-type.${screeningType}`)}
                    </Text>
                    <ScreeningStatusBadge screening={screening} />
                    {isScreeningActive(screening) && (
                      <Badge status="success" progress="complete">
                        {f("screening.status.active")}
                      </Badge>
                    )}
                  </HorizontalStack>
                  <Button onClick={() => setControlConfirmationActive(true)}>
                    {f("change-screening.buttons.stop-monitoring")}
                  </Button>
                </HorizontalStack>
              )}

              <Text variant="headingLg" as="p">
                {f("change-screening.titles.screening-owners")}
              </Text>

              <Card>
                <OwnersCard
                  customer={companyCustomer}
                  isArchiveDisabled={() => true}
                  selectable
                  owners={owners}
                  selectedOwners={selectedOwners}
                  onSelectionChange={setSelectedOwners}
                  onArchive={(archivedOwnerId) => {
                    setOwners(owners.filter((owner) => owner.id !== archivedOwnerId));
                    if (selectedOwners.includes(archivedOwnerId)) {
                      setSelectedOwners(selectedOwners.filter((selectedOwner) => selectedOwner !== archivedOwnerId));
                    }
                  }}
                  onUpdate={(updatedOwner) => {
                    setOwners(
                      owners.map((owner) => (owner.id === updatedOwner.id ? Object.assign(owner, updatedOwner) : owner))
                    );
                    if (isUBO(updatedOwner) && !selectedOwners.includes(updatedOwner.id!)) {
                      setSelectedOwners([...selectedOwners, updatedOwner.id!]);
                    }
                  }}
                  onCreate={(createdOwner) => {
                    setOwners([createdOwner].concat(owners));
                    if (isUBO(createdOwner)) {
                      setSelectedOwners([...selectedOwners, createdOwner.id!]);
                    }
                  }}
                />
              </Card>

              <SectionMargin size="medium" />
              <Text variant="headingLg" as="p">
                {f("change-screening.titles.screening-roles")}
              </Text>

              <Card>
                <RolesCard
                  customer={companyCustomer}
                  roles={roles}
                  selectedRoles={selectedRoles}
                  selectable
                  companyCountry={companyCustomerDetailsQuery.data?.country}
                  companyType={getCompanyType(language, companyCustomerDetailsQuery.data?.company_type)}
                  getIsDeleteDisabled={() => true}
                  onSelectionChange={setSelectedRoles}
                  onDelete={(deletedRoleId) => {
                    setRoles(roles.filter((role) => role.id !== deletedRoleId));
                    if (selectedRoles.includes(deletedRoleId)) {
                      setSelectedRoles(selectedRoles.filter((selectedRole) => selectedRole !== deletedRoleId));
                    }
                  }}
                  onUpdate={(updatedRole) =>
                    setRoles(roles.map((role) => (role.id === updatedRole.id ? updatedRole : role)))
                  }
                  onCreate={(createdRole) => setRoles([createdRole].concat(roles))}
                />
              </Card>

              {isNewScreening && (
                <>
                  <SectionMargin size="medium" />
                  <Text variant="headingLg" as="p">
                    {f("change-screening.titles.choose-screening-type")}
                  </Text>
                </>
              )}

              {isNewScreening && (
                <Card>
                  <Card.Section>
                    <ScreeningTypeCard
                      screeningType={screeningType}
                      onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
                    />
                  </Card.Section>
                </Card>
              )}
              {changeScreeningMutation.isError && <ErrorPanel message={changeScreeningMutation.error} />}
              <HorizontalStack gap="4" align="end">
                <Button disabled={changeScreeningMutation.isLoading} onClick={() => goToCustomerDetails()}>
                  {f("default.cancel")}
                </Button>
                <Button
                  primary
                  disabled={changeScreeningMutation.isLoading || !screeningType}
                  loading={changeScreeningMutation.isLoading}
                  onClick={changeScreeningMutation.mutate}
                >
                  {isNewScreening ? f("change-screening.buttons.start-screening") : f("change-screening.buttons.save")}
                </Button>
              </HorizontalStack>
            </VerticalStack>
          </Layout.Section>
        </Layout>
      </VerticalStack>
      {isControlConfirmationActive && (
        <StopScreeningModal
          id={customerId}
          isOpen={isControlConfirmationActive}
          isPerson={false}
          onStop={() => {
            setControlConfirmationActive(false);
            goToCustomerDetails(f("screening.snackbar.stopped"));
          }}
          onClose={() => {
            setControlConfirmationActive(false);
          }}
        />
      )}
    </Page>
  );
};

export default CompanyChangeScreeningPage;
