import React from "react";

import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import { Customer } from "../../types/utilities";
import { DISPLAY_BADGE_PROPS } from "../Badges/Badges";
import Badge from "../extensions/Badge";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

type ArchivedBadgeProps = {
  customer?: Customer;
};

const ArchivedBadge = (props: ArchivedBadgeProps) => {
  const { customer } = props;

  const f = useFormatMessage();
  const { getUserDisplayName } = useGetUserName();

  const tooltipContent =
    customer?.archived_by && customer?.archiving_dt
      ? f("customer.details.archived-date", {
          name: getUserDisplayName(customer.archived_by),
          timestamp: new Date(customer.archiving_dt)
        })
      : null;

  if (tooltipContent) {
    return (
      <TooltipWrapper content={tooltipContent} dismissOnMouseOut>
        <Badge {...DISPLAY_BADGE_PROPS} status={undefined}>
          {f("badge.archived")}
        </Badge>
      </TooltipWrapper>
    );
  } else {
    return (
      <Badge {...DISPLAY_BADGE_PROPS} status={undefined}>
        {f("badge.archived")}
      </Badge>
    );
  }
};

export default ArchivedBadge;
