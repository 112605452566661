import React from "react";
import { HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import useRestoreLastWindowLocationSearch from "../../components/CustomersListFilters/useRestoreLastWindowLocationSearch";
import DashboardFilters from "../../components/DashboardFilters/DashboardFilters";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { DASHBOARD_SEARCH } from "../../constants/session-state";
import useDashboardParams from "../../hooks/useDashboardParams";
import useFormatMessage from "../../hooks/useFormatMessage";
import ErrorPage from "../ErrorPage";

import Dashboard from "./Dashboard";
import { EMPTY_DASHBOARD_VALUES } from "./DashboardPage.helpers";

const DashboardPage = () => {
  useRestoreLastWindowLocationSearch(DASHBOARD_SEARCH);

  const f = useFormatMessage();
  const { dashboardParams, ...filters } = useDashboardParams();

  const { isLoading, isError, error, data } = useQuery(
    [QUERIES_KEYS.DASHBOARD, ...Object.values(dashboardParams)],
    () => api.getDashboard(dashboardParams)
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <VerticalStack gap="8">
        <HorizontalStack>
          <Text variant="heading2xl" as="p">
            {f("nav-bar.dashboard")}
          </Text>
        </HorizontalStack>
        <DashboardFilters isLoading={isLoading} {...filters} />
        <Dashboard metrics={isLoading ? EMPTY_DASHBOARD_VALUES : data} loading={isLoading} />
      </VerticalStack>
    </Page>
  );
};

export default DashboardPage;
