import { Checkbox, CheckboxProps, Text } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";

const UBOManualOverride = (props: Omit<CheckboxProps, "label" | "helpText">) => {
  const f = useFormatMessage();

  return (
    <Checkbox
      {...props}
      label={f("ubo.manual-override.title")}
      helpText={
        <StyledHelpText>
          <Text as="span">{f("ubo.manual-override.description")}</Text>
        </StyledHelpText>
      }
    />
  );
};

const StyledHelpText = styled.span`
  white-space: pre-wrap;
`;

export default UBOManualOverride;
