import React from "react";
import { Divider, Text, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import { Note } from "../../types/Notes";

import NoteForm, { NoteFormProps } from "./NoteForm";
import NotesList, { NotesListProps } from "./NotesList";

type NotesSectionProps<T extends Note> = NotesListProps<T> & NoteFormProps;

const NotesSection = <T extends Note>(props: NotesSectionProps<T>) => {
  const { notes = [], onDelete, onSave, readonly, placeholder, ...noteFormProps } = props;

  const f = useFormatMessage();

  return (
    <VerticalStack gap="4">
      <Text variant="headingMd" as="h2">
        {f("component.notes.title", { count: notes.length })}
      </Text>
      <NoteForm readonly={readonly} placeholder={placeholder} {...noteFormProps} />
      {notes.length > 0 && (
        <>
          <Divider />
          <NotesList
            notes={notes}
            placeholder={placeholder}
            onDelete={readonly ? undefined : onDelete}
            onSave={readonly ? undefined : onSave}
          />
        </>
      )}
    </VerticalStack>
  );
};

export default NotesSection;
