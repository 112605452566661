import React, { useCallback, useEffect, useState } from "react";
import { ButtonGroup, HorizontalStack, Spinner, Text, VerticalStack } from "@shopify/polaris";
import { TabDescriptor } from "@shopify/polaris/build/ts/latest/src/components/Tabs/types";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS, STALE_TIME } from "../../constants/queries-keys";
import useFormatMessage from "../../hooks/useFormatMessage";
import CondensedText from "../CondensedText/CondensedText";
import Button from "../extensions/Button";
import HideOnMobile from "../HideOnMobile/HideOnMobile";
import OwnersTable, { OwnersTableProps } from "../OwnersCard/OwnersTable/OwnersTable";
import OwnershipStructureTree from "../OwnershipStructureTree/OwnershipStructureTree";
import SearchTextField from "../SearchTextField/SearchTextField";

const TABLE_VIEW_INDEX = 0;
const TREE_VIEW_INDEX = 1;

export type OwnershipViewProps = OwnersTableProps & {
  onViewTypeChange?(viewType: "table" | "tree"): void;
};

const OwnershipView = (props: OwnershipViewProps) => {
  const { customer, allowOwnershipTree, onViewTypeChange, ...ownersTableProps } = props;

  const f = useFormatMessage();
  const [selectedTabIndex, setSelectedTabIndex] = useState(TABLE_VIEW_INDEX);
  const [queryText, setQueryText] = useState("");

  const clearQueryText = useCallback(() => setQueryText(""), []);

  const { isLoading, isError, data } = useQuery(
    [QUERIES_KEYS.COMPANY_OWNERSHIP_TREE, customer.id],
    () => api.getCustomerOwnershipTree(customer.id),
    {
      retry: false,
      staleTime: STALE_TIME,
      retryOnMount: false,
      enabled: customer.has_ownership_tree && allowOwnershipTree
    }
  );

  useEffect(() => {
    if (onViewTypeChange) {
      onViewTypeChange(selectedTabIndex === TREE_VIEW_INDEX ? "tree" : "table");
    }
  }, [selectedTabIndex]);

  const showTable = selectedTabIndex === TABLE_VIEW_INDEX;
  const showTree = selectedTabIndex === TREE_VIEW_INDEX;

  const tabs: TabDescriptor[] = [
    {
      id: "table",
      content: <Text as="span">{f("ownership.view.table")}</Text>
    },
    {
      id: "tree",
      content: <Text as="span">{f("ownership.view.tree")}</Text>
    }
  ];

  if (customer.has_ownership_tree && isLoading) {
    return (
      <HorizontalStack blockAlign="center" gap="1">
        <Spinner size="small" />
        <Text as="h2" variant="headingMd">
          {f("default.loading")}
        </Text>
      </HorizontalStack>
    );
  }

  if (!allowOwnershipTree || isError || !customer.has_ownership_tree || !data || !data.ownership_tree) {
    return <OwnersTable customer={customer} {...ownersTableProps} />;
  }

  return (
    <VerticalStack gap="4">
      <HideOnMobile>
        <HorizontalStack gap="4">
          <ButtonGroup segmented>
            {tabs.map((tab, index) => (
              <Button
                pressed={selectedTabIndex === index}
                onClick={() => setSelectedTabIndex(index)}
                outline
                key={tab.id}
              >
                {tab.content}
              </Button>
            ))}
          </ButtonGroup>
          {showTree && (
            <SearchTextField
              value={queryText}
              onChange={setQueryText}
              labelHidden
              placeholder={f("default.search")}
              clearButton
              onClearButtonClick={clearQueryText}
            />
          )}
        </HorizontalStack>
      </HideOnMobile>
      {showTable && <OwnersTable customer={customer} {...ownersTableProps} />}
      {showTree && (
        <VerticalStack gap="4">
          <OwnershipStructureTree rootNode={data.ownership_tree.root_node} searchTerm={queryText} />
          <CondensedText italic>{f("ownership.view.tree.info")}</CondensedText>
        </VerticalStack>
      )}
    </VerticalStack>
  );
};

export default OwnershipView;
