import React, { PropsWithChildren } from "react";
import { Link, VerticalStack } from "@shopify/polaris";

import { getCustomerName } from "../../helpers/display.helpers";
import useCustomerPath from "../../hooks/useCustomerPath";
import { Customer, isManualCompany } from "../../types/utilities";
import ArchivedBadge from "../ArchivedBadge/ArchivedBadge";
import Badges from "../Badges/Badges";
import DisplayName from "../DisplayName/DisplayName";
import DisplayTagsWrapper from "../DisplayTagsWrapper/DisplayTagsWrapper";
import TextHighlighter from "../TextHighlighter/TextHighlighter";

type CustomerNameProps = {
  customer: Customer;
  searchTerm?: string;
};

const CustomerName = (props: PropsWithChildren<CustomerNameProps>) => {
  const { customer, searchTerm, children } = props;

  const { getCustomerPath } = useCustomerPath();

  const customerName = getCustomerName(customer);
  const url = getCustomerPath(customer.id, customer.type!, customer.onboarding_status, isManualCompany(customer));
  const searchWords = searchTerm ? searchTerm.split(" ") : [];

  return (
    <VerticalStack>
      <DisplayTagsWrapper tags={customer.labels}>
        <DisplayName type={customer.type}>
          <Link url={url}>
            <TextHighlighter searchWords={searchWords} textToHighlight={customerName} />
          </Link>
        </DisplayName>
      </DisplayTagsWrapper>
      <Badges isPep={customer.is_pep} isSanctioned={customer.is_sanctioned}>
        {customer.is_archived && <ArchivedBadge customer={customer} />}
      </Badges>
      {children}
    </VerticalStack>
  );
};

export default CustomerName;
