import React from "react";

import api from "../../../api";
import { noop } from "../../../utils/util";
import EditButton from "../../EditButton/EditButton";

interface RoleActionsProps {
  role: api.Role;
  readonly?: boolean;
  onEdit?(role: api.Role): void;
}

const RoleActions = (props: RoleActionsProps) => {
  const { role, readonly, onEdit = noop } = props;

  return <EditButton onEdit={() => onEdit(role)} readonly={readonly} />;
};
export default RoleActions;
