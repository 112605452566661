import api from "../api";

import useFormatMessage from "./useFormatMessage";

const useRiskCategories = () => {
  const f = useFormatMessage();

  const riskCategoriesMap = new Map<api.RiskCategory, string>([
    ["PEP", f("common.labels.risk-category.pep")],
    ["SANCTION", f("common.labels.risk-category.sanction")],
    ["GEOGRAPHIC_RISK", f("common.labels.risk-category.geographical")],
    ["INDUSTRY_RISK", f("common.labels.risk-category.industry")]
  ]);

  return riskCategoriesMap;
};

export default useRiskCategories;
