import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";

const MeasuresContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--p-space-4);

  & > :first-child {
    flex: 1;
  }

  & > :last-child {
    padding-top: var(--p-space-2);
  }

  & + & {
    margin-top: var(--p-space-4);
  }

  // hide move button in mobile view
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    & > :last-child {
      display: none;
    }
  }
`;

export default MeasuresContainer;
