import React from "react";
import { Card, Page, SkeletonBodyText, VerticalStack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";
import useSettingsPage from "../../hooks/useSettingsPage";
import AddButton from "../AddButton/AddButton";
import FilterContainer from "../FilterContainer/FilterContainer";
import QuestionnairePageHeader from "../QuestionnairePageHeader/QuestionnairePageHeader";
import SearchTextField from "../SearchTextField/SearchTextField";

const QuestionnairePageSkeleton = () => {
  const f = useFormatMessage();

  useSettingsPage();

  return (
    <Page
      title={f("questionnaire.page.title")}
      primaryAction={<AddButton disabled>{f("questionnaire.page.add-questionnaire.button.label")}</AddButton>}
    >
      <VerticalStack gap="8">
        <QuestionnairePageHeader />
        <FilterContainer>
          <SearchTextField placeholder={f("questionnaire.page.filter.placeholder")} labelHidden disabled />
        </FilterContainer>
        <Card>
          <Card.Section>
            <SkeletonBodyText />
          </Card.Section>
        </Card>
      </VerticalStack>
    </Page>
  );
};

export default QuestionnairePageSkeleton;
