import React from "react";
import { HorizontalStack, Spinner, Text } from "@shopify/polaris";

import ChecklistIcon from "../../../assets/icons/Checklist.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetReviewChecklists from "../../hooks/useGetReviewChecklists";
import EntitiesList, { EntitiesListProps } from "../EntitiesList/EntitiesList";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Icon from "../extensions/Icon";

type ReviewChecklistsListProps = Omit<
  EntitiesListProps<api.ReviewChecklist>,
  "entities" | "getEntityLabel" | "getEntityPrefix" | "isEntityDisabled" | "filterLabel"
> & {
  customerType: api.CustomerType;
};

const ReviewChecklistsList = (props: ReviewChecklistsListProps) => {
  const { customerType, selectedIds, ...entitiesListProps } = props;

  const f = useFormatMessage();
  const { data, isLoading, error } = useGetReviewChecklists();

  const checklistsToDisplay = data?.checklists.filter(
    (checklist) =>
      // filter by customer type
      (!checklist.customer_type || checklist.customer_type === customerType || checklist.customer_type === "both") &&
      // filter archived
      (!checklist.is_archived || selectedIds.includes(checklist.id))
  );

  if (isLoading) {
    return (
      <HorizontalStack gap="4" blockAlign="center">
        <Spinner size="small"></Spinner>
        <Text as="span">{f("component.review.checklist.select.checklists-list.loading")}</Text>
      </HorizontalStack>
    );
  }

  if (error) {
    return (
      <ErrorPanel message={error} actionTitle={f("component.review.checklist.select.checklists-list.loading.error")} />
    );
  }

  return (
    <EntitiesList
      entities={checklistsToDisplay || []}
      selectedIds={selectedIds}
      getEntityLabel={(checklist) => checklist.name}
      getEntityHelpText={(checklist) => checklist.description}
      getEntityPrefix={() => <Icon source={ChecklistIcon} />}
      isEntityDisabled={(checklist) =>
        checklist.is_archived || (checklist.is_required && selectedIds.includes(checklist.id))
      }
      selectedFirst
      filterLabel={f("component.review.checklist.select.checklists-list.filter.label")}
      {...entitiesListProps}
    />
  );
};

export default ReviewChecklistsList;
