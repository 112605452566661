import React, { createContext, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppState, Auth0ContextInterface, Auth0Provider, LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

import api from "../../api";
import { REDIRECT_TO_AFTER_LOGOUT_KEY } from "../../constants/session-state";
import HistoryLocationState from "../../types/HistoryLocationState";

import { authSessionService } from "./AuthProvider.authSessionService";

/**
 * Auth provider that wraps Auth0Provider around BeaufortAuthProvider
 * -> An "extension" to Auth0Provider with the Beaufort user's authentication
 * state
 */
export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const history = useHistory<HistoryLocationState>();

  // A function that routes the user to the right place
  // after login (we currently use Auth0's universal login form)
  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://beaufort.io"
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <BeaufortAuthProvider>{children}</BeaufortAuthProvider>
    </Auth0Provider>
  );
};

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <AuthProvider>.");
};

interface AuthContext extends Omit<Auth0ContextInterface, "user"> {
  user?: api.AuthUser;
}

const initialContext: AuthContext = {
  isAuthenticated: false,
  isLoading: true,
  getAccessTokenSilently: stub,
  getAccessTokenWithPopup: stub,
  getIdTokenClaims: stub,
  loginWithRedirect: stub,
  loginWithPopup: stub,
  logout: stub,
  handleRedirectCallback: stub
};

export const AuthContext = createContext<AuthContext>(initialContext);

/**
 * This provider extends Auth0's auth context and performs the
 * authentication of the user at both Auth0 and Beaufort.
 *
 * It gets and refreshes the access token for the API, and handles session
 * expiry due to user inactivity.
 *
 * It returns a consumable auth context with the auth state and, if authenticated,
 * the "Beaufort user". If the user authentication fails, e.g. no Beaufort-user exists
 * for the Auth0-user/email, the user is immediately logged out.
 */
const BeaufortAuthProvider = ({ children }: { children: ReactNode }) => {
  const auth0context = useAuth0();

  const [beaufortUser, setBeaufortUser] = useState<api.AuthUser>();

  /**
   * Override Auth0's logout function to default to return to window.location.origin
   */
  const logout = useCallback(
    (options?: LogoutOptions) => {
      return auth0context.logout(options || { logoutParams: { returnTo: `${window.location.origin}/logout` } });
    },
    [auth0context.logout]
  );

  /**
   * Init and terminate auth session service which gets and refreshes
   * the user session and API access token, and handles session expiry
   */
  useEffect(() => {
    if (auth0context.isAuthenticated) {
      authSessionService.init(auth0context.getAccessTokenSilently);

      return () => {
        authSessionService.terminate();
      };
    }
  }, [auth0context.getAccessTokenSilently, auth0context.isAuthenticated]);

  /**
   * Function that authenticates the "Beaufort-user"
   *
   * If successful, it should return the authenticated Beaufort-user
   *
   * If no Beaufort-user exists for the Auth0-user (ie. there are no
   * Beaufort-user with the Auth0-user's email), the request will result
   * in a 401. In this case, log out immediately
   */
  const authenticateBeaufortUser = () => {
    api
      .getLoggedInUser()
      .then((beaufortUser) => {
        // User successfully authenticated with Beaufort!
        setBeaufortUser(beaufortUser);
      })
      .catch(() => {
        const returnTo = `${window.location.pathname}${window.location.search}${window.location.hash}`;
        localStorage.setItem(REDIRECT_TO_AFTER_LOGOUT_KEY, returnTo);

        // Assume 401
        // -> No Beaufort user found for the Auth0-user/email
        // -> Log out immediately as the user should have no access
        // --> The user should basically never be considered "logged in"
        logout();
      });
  };

  /**
   * Once the Auth0-user is authenticated, try to authenticate
   * the "Beaufort-user"
   */
  useEffect(() => {
    if (auth0context.isAuthenticated && !beaufortUser) {
      authenticateBeaufortUser();
    }
  }, [auth0context.isAuthenticated, beaufortUser]);

  /**
   * Set Sentry-user to the Beaufort user or the Auth0 user
   * depending on what is defined
   */
  useEffect(() => {
    if (beaufortUser) {
      Sentry.setUser({
        email: beaufortUser.email,
        provider: "Beaufort",
        organization: beaufortUser.organization.name,
        features: beaufortUser.features || {},
        permission: beaufortUser.permissions
      });
    } else if (auth0context.user?.email) {
      Sentry.setUser({
        email: auth0context.user.email,
        provider: "Auth0"
      });
    }
  }, [auth0context.user, beaufortUser]);

  /**
   * Capture Auth0-error if one occurs
   */
  useEffect(() => {
    if (auth0context.error) {
      Sentry.captureException(auth0context.error);
    }
  }, [auth0context.error]);

  const context: AuthContext = {
    ...auth0context,
    user: beaufortUser,
    logout,
    // isLoading should be true as long as auth0 is loading or we haven't
    // authenticated the Beaufort user
    isLoading: auth0context.isLoading || beaufortUser === undefined,
    // isAuthenticated should only be true when the user has been authenticated
    // with both Auth0 and Beaufort
    isAuthenticated: auth0context.isAuthenticated && beaufortUser !== undefined
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
