import React, { useEffect, useMemo, useState } from "react";
import { ChoiceList, ChoiceListProps, HorizontalStack, Spinner, Text } from "@shopify/polaris";

import api from "../../api";
import useDebouncedState from "../../hooks/useDebouncedState";
import useFormatMessage from "../../hooks/useFormatMessage";
import useIndustryTypes from "../../hooks/useIndustryTypes";
import { isEmptyString } from "../../utils/stringUtils";
import SearchTextField from "../SearchTextField/SearchTextField";
import { StickyWrapper } from "../StickyWrapper/StickyWrapper";
import TextHighlighter from "../TextHighlighter/TextHighlighter";

type IndustryTypesFilterProps = {
  industryCodes: api.IndustryCodeEnum[];
  setIndustryCodes(industryCodes: api.IndustryCodeEnum[]): void;
};

const IndustryTypesFilter = (props: IndustryTypesFilterProps) => {
  const { industryCodes, setIndustryCodes } = props;

  const f = useFormatMessage();

  const [enableAPICall, setEnableAPICall] = useState(false);
  const [filterValue, setFilterValue, debouncedValue] = useDebouncedState("");
  const { industriesWithoutCategories, getIndustryLabel, isLoading } = useIndustryTypes(enableAPICall);

  // only call get all industry codes end point after initial mount
  useEffect(() => setEnableAPICall(true), []);

  const choices: ChoiceListProps["choices"] = useMemo(() => {
    const filteredIndustries = isEmptyString(filterValue)
      ? industriesWithoutCategories
      : industriesWithoutCategories.filter((industry) =>
          getIndustryLabel(industry.code).toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
        );

    return filteredIndustries.map((industry) => ({
      value: industry.code,
      label: <TextHighlighter searchWords={[debouncedValue]} textToHighlight={getIndustryLabel(industry.code)} />
    }));
  }, [industriesWithoutCategories, debouncedValue]);

  return (
    <StickyWrapper>
      <SearchTextField
        label={f("industry-types.filter.label")}
        value={filterValue}
        onChange={setFilterValue}
        disabled={isLoading}
        clearButton
        onClearButtonClick={() => setFilterValue("")}
      />
      {isLoading && (
        <HorizontalStack gap="1">
          <Spinner size="small" />
          <Text as="span">{f("default.loading")}</Text>
        </HorizontalStack>
      )}
      {!isLoading && (
        <ChoiceList
          title=""
          choices={choices}
          selected={industryCodes}
          onChange={setIndustryCodes}
          titleHidden
          allowMultiple
        />
      )}
    </StickyWrapper>
  );
};

export default IndustryTypesFilter;
