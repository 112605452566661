import api from "../api";
import { CompanyCustomer, CompanySearchResult, TranslationText } from "../client.generated";
import { DEFAULT_LANGUAGE } from "../constants/languages";
import { Customer, CustomerDetails, isCompany } from "../types/utilities";
import { isNumber } from "../utils/numbersUtils";

export const getFullName = (person?: { first_name?: string; last_name?: string }) =>
  `${person?.first_name || ""} ${person?.last_name || ""}`;

export const getBirthDate = (person?: { birth_date?: string; birth_year?: number }) =>
  person?.birth_date || (isNumber(person?.birth_year) ? person?.birth_year?.toFixed(0) : undefined);

// use a separate function for getting customer person display name (instead of using getFullName)
// in order to make TypeScript aware that first_name and last_name are required fields in the person customer types
export const getPersonDisplayName = (
  person: api.PersonCustomer | api.PersonSearchResult | api.PersonCustomerDetailResponse
) => `${person.first_name} ${person.last_name}`;

export const getCompanyDisplayName = (company?: CompanyCustomer | CompanySearchResult) =>
  `${company?.name || company?.national_id}`;

export const getCustomerName = (customer: Customer | CustomerDetails | Customer) =>
  isCompany(customer) ? getCompanyDisplayName(customer) : getPersonDisplayName(customer);

export const getTranslationText = (language: api.LanguageEnum, text?: TranslationText) =>
  text?.[language] || text?.[DEFAULT_LANGUAGE] || "";
