import React from "react";
import { Card, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetCustomerReviewGuideline from "../../hooks/useGetCustomerReviewGuideline";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import KDKBanner from "../KDKBanner/KDKBanner";
import MarkdownContent from "../MarkdownContent/MarkdownContent";

type CustomerReviewGuidelineSectionProps = {
  customerType: api.CustomerType;
  timestamp?: string;
};

const CustomerReviewGuidelineSection = (props: CustomerReviewGuidelineSectionProps) => {
  const { customerType, timestamp } = props;

  const f = useFormatMessage();
  const guideline = useGetCustomerReviewGuideline(customerType, timestamp);

  return (
    <Card>
      <CollapsibleSection title={guideline?.title || f("reviews.titles.guideline-tips")} status={"info"} standalone>
        <Card.Section>
          <VerticalStack gap="4">
            <KDKBanner hideIcon status="info">
              <MarkdownContent content={guideline?.text || ""} />
            </KDKBanner>
          </VerticalStack>
        </Card.Section>
      </CollapsibleSection>
    </Card>
  );
};

export default CustomerReviewGuidelineSection;
