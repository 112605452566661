import React, { ReactElement } from "react";
import { BadgeProps, Button, Collapsible, HorizontalStack, Popover, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import { hasUnreviewedChanges } from "../../helpers/updates";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { InfoUpdateFieldChange } from "../../types/CustomerUpdates";
import { first, hasContent } from "../../utils/collectionUtils";
import { isEmpty } from "../../utils/util";
import CompanyInfoValueTooltip from "../CompanyInfoValueTooltip/CompanyInfoValueTooltip";
import CondensedText from "../CondensedText/CondensedText";
import CustomDate from "../CustomDate/CustomDate";
import Badge from "../extensions/Badge";

// If a value has more than 3 of changes, render the first 3 changes and the rest inside a collapsible panel
const UPDATES_CUTOFF = 3;

const defaultRenderer = <T = string,>(value?: T) =>
  typeof value === "string" ? (
    <Text variant="bodyMd" as="span">
      {value}
    </Text>
  ) : (
    <>{value}</>
  );

type ExpiredValueProps<T> = {
  change: InfoUpdateFieldChange;
  render(value?: T): ReactElement;
};

const ExpiredValue = <T = string,>(props: ExpiredValueProps<T>) => {
  const f = useFormatMessage();

  return (
    <HorizontalStack gap="1" blockAlign={"baseline"}>
      <Text variant="bodyMd" as="span" color="subdued">
        {props.render(props.change.old || "-")}
      </Text>
      <CondensedText>
        ({f("default.expired")} <CustomDate date={props.change.timestamp} />)
      </CondensedText>
    </HorizontalStack>
  );
};

export type CompanyInfoValueProps<T> = {
  customerId?: string;
  infoUpdateIds?: number[];
  value?: T;
  changes?: InfoUpdateFieldChange[];
  hideHistory?: boolean;
  status?: BadgeProps["status"];
  render?(value?: T): ReactElement;
};

const CompanyInfoValue = <T = string,>(props: CompanyInfoValueProps<T>) => {
  const {
    customerId,
    infoUpdateIds,
    value,
    changes = [],
    hideHistory,
    status = "info",
    render = defaultRenderer
  } = props;

  const f = useFormatMessage();

  const [showTooltip, toggleTooltip, closeTooltip] = useOpenClose();
  const [showMoreChanges, toggleShowMoreChanges] = useOpenClose();

  if (!hasContent(changes)) {
    return render(value);
  }

  const lastUpdatedDate = first(changes)?.timestamp;
  const showNewInfoBadge = customerId !== undefined && infoUpdateIds !== undefined && hasUnreviewedChanges(changes);

  const displayChange = changes.filter((change) => !isEmpty(change.old));

  const collapsibleLabel = showMoreChanges
    ? f("common.labels.show-less")
    : f("common.labels.show-more-with-count", { count: displayChange.length - UPDATES_CUTOFF });

  return (
    <VerticalStack gap="2">
      <HorizontalStack gap="1" blockAlign="center">
        {render(value)}
        {showNewInfoBadge && (
          <Popover
            preferredPosition="above"
            active={showTooltip}
            onClose={toggleTooltip}
            activator={
              <StyledNewInfoBadge onClick={toggleTooltip}>
                <Badge size="small" status={status}>
                  <Text variant="bodySm" as="span" fontWeight="semibold">
                    {f("default.new")} ({<CustomDate date={lastUpdatedDate} />})
                  </Text>
                </Badge>
              </StyledNewInfoBadge>
            }
          >
            <CompanyInfoValueTooltip customerId={customerId} infoUpdateIds={infoUpdateIds} onClose={closeTooltip} />
          </Popover>
        )}
      </HorizontalStack>

      {!hideHistory && displayChange.length > 0 && (
        <VerticalStack gap="2">
          {displayChange.slice(0, UPDATES_CUTOFF).map((change, index) => (
            <ExpiredValue change={change} render={render} key={index} />
          ))}
          <Collapsible id="" open={showMoreChanges}>
            <VerticalStack gap="2">
              {displayChange.slice(UPDATES_CUTOFF).map((change, index) => (
                <ExpiredValue change={change} render={render} key={index} />
              ))}
            </VerticalStack>
          </Collapsible>
          {displayChange.length > UPDATES_CUTOFF && (
            <HorizontalStack>
              <Button plain onClick={toggleShowMoreChanges}>
                {collapsibleLabel}
              </Button>
            </HorizontalStack>
          )}
        </VerticalStack>
      )}
    </VerticalStack>
  );
};

const StyledNewInfoBadge = styled.div`
  cursor: pointer;
  & .Polaris-Badge {
    padding: 0 0.5rem;
    border-color: transparent;
  }

  &:hover .Polaris-Badge {
    filter: brightness(85%);
  }

  &:active .Polaris-Badge {
    filter: brightness(75%);
  }
`;

export default CompanyInfoValue;
