import React from "react";
import { Link, Stack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import Logo from "../../../../assets/images/kdk-logo.svg";
import { ROUTES } from "../../../constants/routes";
import { MOBILE_BREAKPOINT } from "../../../constants/styles";
import useNavigationToolbar from "../../../hooks/useNavigationToolbar";
import OnboardingToolbar from "../../OnboardingToolbar/OnboardingToolbar";
import SettingsButton from "../../SettingsButton/SettingsButton";
import UserMenu from "../../UserMenu/UserMenu";
import WhatsNewButton from "../../WhatsNewButton/WhatsNewButton";

import StatusMessage from "./StatusMessage/StatusMessage";
import MainToolbar from "./MainToolbar";

const NavigationToolbar = () => {
  const { onboardingType, onboardingStepIndex } = useNavigationToolbar();

  const isOnboardingWizard = onboardingType !== undefined;
  const isMainMenu = onboardingStepIndex < 0;

  return (
    <VerticalStack>
      <StatusMessage />
      <StyledNavigationToolbar>
        <Stack alignment="fill" wrap={false} spacing={"none"}>
          <Link url={ROUTES.HOME}>
            <StyledLogo src={Logo} />
          </Link>
          <Stack.Item fill>
            <StyledMainButtons>
              {isMainMenu && <MainToolbar />}
              {isOnboardingWizard && <OnboardingToolbar />}
            </StyledMainButtons>
          </Stack.Item>
          <StyledSecondaryButtons>
            <WhatsNewButton />
            <SettingsButton />
          </StyledSecondaryButtons>
          <UserMenu />
        </Stack>
      </StyledNavigationToolbar>
    </VerticalStack>
  );
};

const StyledNavigationToolbar = styled.div`
  height: var(--kdk-toolbar-height);
  display: flex;
  max-width: 100vw;

  & > * {
    flex: 1;
  }

  background-color: var(--p-surface);
`;

const StyledLogo = styled.img`
  height: var(--kdk-toolbar-height);
  padding: var(--p-space-2);
`;

const StyledMainButtons = styled.div`
  padding: 0 var(--p-space-12);
  height: 100%;

  // For small viewports - remove paddings and margins to compact main toolbar
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0;
    --pc-stack-spacing: var(--p-space-1);

    .Polaris-Stack.Polaris-Stack--spacingExtraLoose {
      --pc-stack-spacing: var(--p-space-1);
    }
  }
`;

const StyledSecondaryButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  height: 100%;
  gap: var(--p-space-4);
  margin-right: var(--p-space-2);

  > * + * {
    border-right: var(--p-border-divider);
    border-left: var(--p-border-divider);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

export default NavigationToolbar;
