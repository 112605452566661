import React from "react";
import { Modal } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData from "../../hooks/useQueryData";
import { getCustomerQueryKey } from "../../types/utilities";
import CompanyCustomerForm from "../CompanyCustomerForm/CompanyCustomerForm";

interface EditCompanyCustomerInfoModalProps {
  companyCustomer: api.CompanyCustomerDetailResponse;
  onClose(): void;
}

const EditCompanyCustomerInfoModal = (props: EditCompanyCustomerInfoModalProps) => {
  const { companyCustomer, onClose } = props;

  const f = useFormatMessage();
  const { getQueryData, setQueryData } = useQueryData<api.CompanyCustomerDetailResponse>(
    getCustomerQueryKey(companyCustomer)
  );

  return (
    <Modal open onClose={onClose} title={f("modals.company.edit.title")}>
      <CompanyCustomerForm
        companyCustomer={companyCustomer}
        submitLabel={f("default.save")}
        onCancel={onClose}
        onAfterSubmit={(updatedCompanyDetails) => {
          // Update query data on QUERIES_KEYS.COMPANY_DETAILS query
          const currentCompanyDetailsData = getQueryData();

          if (currentCompanyDetailsData) {
            setQueryData({
              ...currentCompanyDetailsData,
              ...updatedCompanyDetails,
              // NOTE: `api.CompanyCustomer.address` is the same as `business_address`
              // This will/should be fixed/improved in beaufort-api, but
              // do this as a quick-fix for now
              business_address: updatedCompanyDetails.address
            });
          }
          // Close modal
          onClose();
        }}
      />
    </Modal>
  );
};

export default EditCompanyCustomerInfoModal;
