import React from "react";
import { Card, Checkbox, Divider, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { OptionalCustomerReviewRequest } from "../../types/CustomerReview";
import { ValueOf } from "../../types/utilities";
import KDKBanner from "../KDKBanner/KDKBanner";
import KDKTextField from "../KDKTextField/KDKTextField";
import ReviewChecklistAnswers from "../ReviewChecklistAnswers/ReviewChecklistAnswers";
import TagsEditor from "../TagsEditor/TagsEditor";

type CustomerReviewFormSectionProps = {
  review: OptionalCustomerReviewRequest;
  readonly?: boolean;
  onFieldChange(field: keyof api.CreateCustomerReviewRequest, value: ValueOf<OptionalCustomerReviewRequest>): void;
};

const CustomerReviewFormSection = (props: CustomerReviewFormSectionProps) => {
  const { review, readonly, onFieldChange } = props;

  const f = useFormatMessage();
  const features = useFeatures();

  return (
    <Card>
      <Card.Section>
        <KDKBanner hideIcon hideBorder>
          <VerticalStack gap="4">
            <Text variant="headingSm" as="h2">
              {f("reviews.labels.review-header")}
            </Text>
            <VerticalStack gap="2">
              <Text variant="headingXs" as="h3">
                {f("reviews.labels.review-label")}
              </Text>
              <Text as="p" color="subdued">
                <ReviewChecklistAnswers checklistAnswers={review.checklist_answers} />
              </Text>
              <KDKTextField
                label={f("reviews.labels.review-label")}
                labelHidden
                value={review.comment}
                placeholder={f("reviews.placeholders.review-rational")}
                onChange={(value) => onFieldChange("comment", value)}
                multiline={4}
                id="text"
                autoComplete="off"
                disabled={readonly}
              />
            </VerticalStack>
            {features.REVIEW_LABELS && (
              <>
                <Divider />
                <TagsEditor tags={review.labels} readonly={readonly} onChange={(tags) => onFieldChange("labels", tags)}>
                  <Text variant="headingXs" as="h3">
                    {f("reviews.labels.risk-tags")}
                  </Text>
                </TagsEditor>
              </>
            )}
            <Divider />
            <VerticalStack gap="2">
              <Text variant="headingXs" as="h3">
                {f("reviews.labels.mark-as")}
              </Text>
              <HorizontalStack gap="8" blockAlign="center">
                <Checkbox
                  label={f("reviews.labels.mark-as.pep")}
                  checked={review.is_pep}
                  disabled={readonly}
                  onChange={(value) => onFieldChange("is_pep", value)}
                />
                <Checkbox
                  label={f("reviews.labels.mark-as.sanction")}
                  checked={review.is_sanctioned}
                  disabled={readonly}
                  onChange={(value) => onFieldChange("is_sanctioned", value)}
                />
              </HorizontalStack>
            </VerticalStack>
          </VerticalStack>
        </KDKBanner>
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewFormSection;
