import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { QUERIES_KEYS } from "../constants/queries-keys";

import useQueryData from "./useQueryData";

const useCustomerReviewCreateNoteMutation = (customerId: string, reviewId: string) => {
  const { updateQueryData } = useQueryData<api.CustomerReviewNotation[]>([
    QUERIES_KEYS.CUSTOMER_REVIEW_NOTES,
    customerId,
    reviewId
  ]);

  const createNoteMutation = useMutation<api.CustomerReviewNotation, unknown, string>(
    (comment) => api.createCustomerReviewNotation(customerId, reviewId, { comment }),
    {
      onSuccess: (newNote: api.CustomerReviewNotation) => {
        updateQueryData((prevNotes) => prevNotes.unshift(newNote));
      }
    }
  );

  return createNoteMutation;
};

export default useCustomerReviewCreateNoteMutation;
