import React from "react";
import FlipMove from "react-flip-move";
import { VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { isNewMeasure } from "../../helpers/measures.helpers";
import { removeItem, swapElements, updateItem } from "../../utils/collectionUtils";
import { Direction } from "../MoveButtons/MoveButtons";

import CustomerReviewMeasure from "./CustomerReviewMeasure";
import CustomerReviewMeasuresHeader from "./CustomerReviewMeasuresHeader";

type CustomerReviewMeasuresProps = {
  customerId: string;
  reviewId: string;
  measures?: api.Measure[];
  readonly?: boolean;
  onChange(measures: api.Measure[]): void;
};

const CustomerReviewMeasures = (props: CustomerReviewMeasuresProps) => {
  const { customerId, reviewId, measures = [], readonly, onChange } = props;

  const handleMeasureAdd = (measure: api.Measure) => onChange([measure, ...measures]);

  const handleMeasureChange = (measure: api.Measure) => onChange(updateItem(measures, measure));

  const handleMeasureRemove = (measure: api.Measure) => onChange(removeItem(measures, measure));

  const handleMeasureCancel = (measure: api.Measure) => {
    if (isNewMeasure(measure)) {
      handleMeasureRemove(measure);
    } else {
      handleMeasureChange(measure);
    }
  };

  const handleOrderChange = (index: number, direction: Direction) => {
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    onChange(swapElements(measures, index, targetIndex));
  };

  return (
    <VerticalStack gap="4">
      <CustomerReviewMeasuresHeader
        customerId={customerId}
        reviewId={reviewId}
        measures={measures}
        readonly={readonly}
        onAfterAdd={handleMeasureAdd}
      />
      {measures.length > 0 && (
        <VerticalStack gap="4">
          <FlipMove>
            {measures.map((measure, index) => (
              <CustomerReviewMeasure
                key={measure.id}
                customerId={customerId}
                reviewId={reviewId}
                measure={measure}
                isFirst={index === 0}
                isLast={index === measures.length - 1}
                onChange={handleMeasureChange}
                onCancel={handleMeasureCancel}
                onAfterRemove={handleMeasureRemove}
                onOrderChange={(direction) => handleOrderChange(index, direction)}
                readonly={readonly}
              />
            ))}
          </FlipMove>
        </VerticalStack>
      )}
    </VerticalStack>
  );
};

export default CustomerReviewMeasures;
