import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { convertQuestionnaireToCreateQuestionnaireRequest } from "../helpers/questionnaire.helpers";

import useFetch from "./useFetch";
import useLanguage from "./useLanguage";

const usePreviewQuestionnaireMutation = () => {
  const language = useLanguage();
  const fetch = useFetch();

  const previewQuestionnaireMutation = useMutation<string, unknown, api.Questionnaire>(async (questionnaire) => {
    const createQuestionnaireRequest = convertQuestionnaireToCreateQuestionnaireRequest(questionnaire);
    const previewData = await api.generateQuestionnairePreviewRequest(createQuestionnaireRequest);

    const previewUrl = `${previewData.preview_url}?language=${language}`;

    return await fetch(previewUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(previewData.payload)
    })
      // convert the response to an Object URL string that can be used inside an iFrame
      .then((res) => res.blob())
      .then((blob) => URL.createObjectURL(blob));
  });

  return previewQuestionnaireMutation;
};

export default usePreviewQuestionnaireMutation;
