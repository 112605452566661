import { ACCEPTED_FILE_TYPES } from "../../constants/files";
import useFormatMessage from "../../hooks/useFormatMessage";
import InfoTooltipLabel from "../InfoTooltipLabel/InfoTooltipLabel";

const AcceptedFileTypesLabel = () => {
  const f = useFormatMessage();

  return (
    <InfoTooltipLabel
      label={f("attachments.upload.button.available-file-types")}
      content={ACCEPTED_FILE_TYPES.join(", ")}
    />
  );
};

export default AcceptedFileTypesLabel;
