import { useMemo } from "react";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import { getRoleOrOwnerKey } from "../../helpers/person.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { groupBy } from "../../utils/collectionUtils";

type ScreeningEntity = (api.Role | api.Owner) & { types: string[] };

export const isRole = (ownerOrRole: api.Role | api.Owner): ownerOrRole is api.Role =>
  (ownerOrRole as api.Role).type !== undefined;

const useCompanyScreeningSection = (customer: api.CompanyCustomerDetailResponse) => {
  const f = useFormatMessage();

  const screeningRoles = (customer.screening?.roles || []).map((role) => role.role);
  const screeningOwners = (customer.screening?.beneficial_owners || []).map((beneficialOwner) => beneficialOwner.owner);

  // TODO compare roles/owners persons by person id once the backend starts deduplicating them
  // const getEntityKey = (entity: api.Role | api.Owner) => entity.person!.id;

  const entitiesToDisplay = useMemo(() => {
    const screening = [...screeningRoles, ...screeningOwners].filter((entity) => entity.person?.id !== undefined);

    const entitiesMap = groupBy(screening, getRoleOrOwnerKey);

    const entitiesToDisplay: ScreeningEntity[] = [...entitiesMap.values()].map((entities) => ({
      ...Object.assign({}, ...entities),
      types: entities.map((entity) => (isRole(entity) ? f(RoleTypes[entity.type]) : f("table.row.owner")))
    }));

    return entitiesToDisplay;
  }, [screeningRoles, screeningOwners]);

  return { entitiesToDisplay };
};

export default useCompanyScreeningSection;
