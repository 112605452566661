import { getBirthDate, getFullName } from "../helpers/display.helpers";

import useFormatMessage from "./useFormatMessage";

type PersonInfo = Parameters<typeof getFullName>[0] & Parameters<typeof getBirthDate>[0];

const useDisplayName = () => {
  const f = useFormatMessage();

  const formatDisplayName = (person: PersonInfo) => {
    const birthYear = getBirthDate(person);
    const birthYearStr = birthYear
      ? f("display-name.suffixes.labels.birth-date", { birthDate: new Date(birthYear) })
      : undefined;

    const displayName = [getFullName(person), birthYearStr].filter(Boolean).join(" ");

    return displayName;
  };

  return formatDisplayName;
};

export default useDisplayName;
