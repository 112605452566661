import React from "react";
import { Popover } from "@shopify/polaris";

import PlusIcon from "../../../../../assets/icons/plus-major.svg";
import api from "../../../../api";
import useFormatMessage from "../../../../hooks/useFormatMessage";
import { toggleElement } from "../../../../utils/collectionUtils";
import Icon from "../../../extensions/Icon";
import PopoverButton from "../../../PopoverButton/PopoverButton";

import QuestionnairesList from "./QuestionnairesList";

export interface QuestionnairesSelectProps {
  questionnairesIds: string[];
  customerType: api.CustomerType;
  readonly?: boolean;
  onChange(selectedQuestionnairesIds: string[]): void;
}

const QuestionnairesSelect = (props: QuestionnairesSelectProps) => {
  const { questionnairesIds, customerType, readonly, onChange } = props;

  const f = useFormatMessage();

  const handleQuestionnaireSelect = (onClose: () => void, questionnaire?: api.Questionnaire) => {
    const selectedIds = !questionnaire ? [] : toggleElement(questionnairesIds, questionnaire.id);
    onClose();
    onChange(selectedIds);
  };

  return (
    <PopoverButton
      label={f("default.add")}
      icon={<Icon source={PlusIcon} width={"12px"} height={"12px"} useMask />}
      size={"slim"}
      disabled={readonly}
      fullWidth={false}
      render={(onClose) => (
        <Popover.Section>
          <QuestionnairesList
            selectedIds={questionnairesIds}
            customerType={customerType}
            readonly={readonly}
            onSelect={(questionnaire?: api.Questionnaire) => handleQuestionnaireSelect(onClose, questionnaire)}
            hideFilter
          />
        </Popover.Section>
      )}
    />
  );
};

export default QuestionnairesSelect;
