import React, { useState } from "react";
import { Stack, TextField, VerticalStack } from "@shopify/polaris";

import useCustomersListParams from "../../../hooks/useCustomersListParams";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useSnackbar from "../../../hooks/useSnackbar";
import { isEmptyString } from "../../../utils/stringUtils";
import Button from "../../extensions/Button";

import useSavedFilters from "./useSavedFilters";

interface FilterDetailsProps {
  defaultFilterName: string;
  onSave(filterName: string): void;
  onCancel(): void;
}

const FilterDetails = (props: FilterDetailsProps) => {
  const { defaultFilterName = "", onSave, onCancel } = props;

  const f = useFormatMessage();
  const { isEmptySearch } = useCustomersListParams();
  const [filterName, setFilterName] = useState(defaultFilterName);
  const { savedFilters } = useSavedFilters();
  const { setSnackbar } = useSnackbar();

  const disabled = isEmptySearch || isEmptyString(filterName) || savedFilters.includes(filterName);

  const handleFormSubmit = () => {
    onSave(filterName);
    setFilterName("");
    setSnackbar(f("component.filters.saved-filters.saved.message"));
  };

  return (
    <VerticalStack gap="4">
      <TextField
        value={filterName}
        onChange={setFilterName}
        selectTextOnFocus
        label={f("component.filters.saved-filters.filter-name")}
        autoComplete="off"
        placeholder={f("component.filters.saved-filters.filter-name")}
        disabled={isEmptySearch}
      />
      <Stack spacing="extraTight" wrap={false} distribution="trailing">
        <Button primary disabled={disabled} onClick={handleFormSubmit}>
          {f("component.filters.saved-filters.save-filters.label")}
        </Button>
        <Button onClick={onCancel}>{f("default.cancel")}</Button>
      </Stack>
    </VerticalStack>
  );
};

export default FilterDetails;
