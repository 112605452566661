import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { HorizontalStack, Page, Text, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import AddFileButton from "../../components/AddFileButton/AddFileButton";
import ArchivedBadge from "../../components/ArchivedBadge/ArchivedBadge";
import AssignmentActionsMenu from "../../components/AssignmentActionsMenu/AssignmentActionsMenu";
import AssignmentReviewsSection from "../../components/AssignmentReviewsSection/AssignmentReviewsSection";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage/AsyncPage";
import AttachmentsSection from "../../components/AttachmentsSection/AttachmentsSection";
import BackButton from "../../components/BackButton/BackButton";
import BasicAssignmentInfo from "../../components/BasicAssignmentInfo/BasicAssignmentInfo";
import CardNavigationSection from "../../components/CardNavigationSection/CardNavigationSection";
import DetailsSkeleton from "../../components/DetailsSkeleton/DetailsSkeleton";
import DisclosureDocumentSection from "../../components/DisclosureDocumentSection/DisclosureDocumentSection";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getDetailsPageURL } from "../../helpers/navigation.helpers";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IdentifiersParams } from "../../types/params";

const CustomerAssignmentDetailsPage = ({ data: assignment }: AsyncPageChild<api.CustomerAssignmentDetails>) => {
  const { id, customerId } = useParams<IdentifiersParams>();

  const f = useFormatMessage();

  const [uploadingFilesCount, setUploadingFilesCount] = useState(0);

  const customerDetailsPageUrl = getDetailsPageURL(customerId, assignment.customer_type);

  const readonly = assignment.is_archived;

  return (
    <Page>
      <VerticalStack gap="4">
        <CardNavigationSection>
          <VerticalStack gap="4">
            <BackButton url={customerDetailsPageUrl} displayLabel={assignment.customer_name} showAsLink />
            <HorizontalStack blockAlign={"baseline"} align={"space-between"}>
              <HorizontalStack blockAlign={"baseline"} gap={"2"}>
                <Text variant="heading2xl" as="p">
                  {assignment.name}
                </Text>
                {assignment.is_archived && <ArchivedBadge />}
              </HorizontalStack>
              <AssignmentActionsMenu assignment={assignment} readonly={readonly} />
            </HorizontalStack>
            <BasicAssignmentInfo assignment={assignment} readonly={readonly} />
          </VerticalStack>
        </CardNavigationSection>

        <CardNavigationSection>
          <DisclosureDocumentSection
            assignmentId={id}
            customerId={assignment.customer_id}
            customerType={assignment.customer_type}
            disclosureDocuments={assignment.disclosure_documents}
            readonly={readonly}
            addButtonLabel={f("common.buttons.actions.new-assignment-disclosure")}
            emptyLabel={f("customer-assignments.no-disclosures.text")}
          />
        </CardNavigationSection>

        <CardNavigationSection>
          <AttachmentsSection entity={assignment} uploadingFilesCount={uploadingFilesCount} readonly={readonly}>
            <AddFileButton
              entity={assignment}
              onFileUploadingStart={(filesCount) => setUploadingFilesCount(filesCount)}
              onFileUploadingFinish={() => setUploadingFilesCount(0)}
              readonly={readonly}
            />
          </AttachmentsSection>
        </CardNavigationSection>

        <CardNavigationSection>
          <AssignmentReviewsSection assignment={assignment} readonly={readonly} />
        </CardNavigationSection>
      </VerticalStack>
    </Page>
  );
};

export default withAsyncPage<api.CustomerAssignmentDetails>(CustomerAssignmentDetailsPage, {
  queryKey: QUERIES_KEYS.ASSIGNMENT_DETAILS,
  apiFunction: api.getCustomerAssignmentDetails,
  paramNames: ["customerId", "id"],
  skeleton: <DetailsSkeleton />
});
