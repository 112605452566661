import React, { CSSProperties } from "react";
import { Stack, Text, VerticalStack } from "@shopify/polaris";

import { TaskStatus } from "../../types/PendingTask";
import CondensedText from "../CondensedText/CondensedText";
import Icon from "../extensions/Icon";

type StatusProps = Partial<Omit<TaskStatus, "url">> & {
  size?: CSSProperties["width"];
};

const Status = (props: StatusProps) => {
  const { title = "", subtitle, icon, size = "12px" } = props;

  return (
    <Stack alignment="center" spacing="extraTight" wrap={false}>
      {icon && <Icon source={icon} width={size} height={size} />}
      <Stack.Item fill>
        <VerticalStack gap="1">
          <Text as={"span"} truncate>
            {title}
          </Text>
          {subtitle && <CondensedText>{subtitle}</CondensedText>}
        </VerticalStack>
      </Stack.Item>
    </Stack>
  );
};

export default Status;
