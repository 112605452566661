import React from "react";
import { Grid, HorizontalStack, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import PlusIcon from "../../../assets/icons/plus-major.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import { EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS } from "../../helpers/checklists.helpers";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import IndustryChoicesSelect from "../AssignmentModal/IndustryChoicesSelect";
import Button from "../extensions/Button";
import PurposeChoicesSelect from "../PurposeChoicesSelect/PurposeChoicesSelect";

type ReviewChecklistRiskConditionsEditorProps = {
  checklist: api.ReviewChecklist;
  readonly?: boolean;
  isNew?: boolean;
  onChange(checklist: api.ReviewChecklist): void;
};

const ReviewChecklistPurposesConditionsEditor = (props: ReviewChecklistRiskConditionsEditorProps) => {
  const { checklist, readonly, isNew, onChange } = props;

  const f = useFormatMessage();
  const features = useFeatures();

  const handleIndustryTypeChange = (value?: string) => {
    onChange({
      ...checklist,
      display_settings: { ...EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS, assignment_industry_type: value }
    });
  };

  const handlePurposeChange = (purposes: string[]) => {
    // when changing a purpose - keep the selected industry type
    const industryType = checklist.display_settings?.assignment_industry_type;
    onChange({
      ...checklist,
      display_settings: {
        ...EMPTY_REVIEW_CHECKLIST_DISPLAY_SETTINGS,
        assignment_industry_type: industryType,
        assignment_purposes: purposes
      }
    });
  };

  const updatePurpose = (purpose: string | undefined = "", index: number) => {
    // replace current purpose at index with the new purpose value
    const purposes =
      checklist.display_settings?.assignment_purposes.length === 0
        ? [purpose]
        : [...checklist.display_settings.assignment_purposes];

    purposes.splice(index, 1, purpose);
    handlePurposeChange(purposes);
  };

  const addPurpose = () => {
    handlePurposeChange([...checklist.display_settings.assignment_purposes, ""]);
  };

  const removePurpose = (purpose: string) => {
    handlePurposeChange(
      checklist.display_settings.assignment_purposes.filter((assignmentPurpose) => assignmentPurpose !== purpose)
    );
  };

  // make sure that the first purpose exists (even as an empty string) even if the list of purposes is empty
  const purposesToDisplay =
    checklist.display_settings?.assignment_purposes.length === 0
      ? [""]
      : checklist.display_settings?.assignment_purposes;

  return (
    <HorizontalStack gap="4">
      <Spacer />
      <FlexFill>
        <Grid columns={{ md: 2 }} gap={{ xs: "4" }}>
          {features.ASSIGNMENT_INDUSTRIES && (
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4 }}>
              <IndustryChoicesSelect
                label={f("customer-assignments.modal.assignment-industry")}
                autoComplete="off"
                placeholder={f("customer-assignments.modal.assignment-industry.placeholder")}
                selectedIndustry={checklist.display_settings?.assignment_industry_type}
                onChange={(industryType) => handleIndustryTypeChange(industryType)}
                disabled={readonly}
              />
            </Grid.Cell>
          )}
          <Grid.Cell columnSpan={{ xs: 6, sm: 6 }}>
            <VerticalStack gap="2">
              {purposesToDisplay.map((purpose, index) => (
                <PurposeChoicesSelect
                  label={index === 0 ? f("customer-assignments.details.assignment-purpose") : f("default.or")}
                  hasPurposeDesc={false}
                  showDeprecatedPurposes={!isNew}
                  disablePurposeDescription
                  industryType={checklist.display_settings?.assignment_industry_type}
                  value={purpose}
                  onChange={(purpose) => updatePurpose(purpose, index)}
                  disabled={readonly}
                  excludedOptions={checklist.display_settings?.assignment_purposes}
                  connectedRight={
                    index === 0 ? (
                      <Button icon={PlusIcon} onClick={addPurpose} disabled={readonly} />
                    ) : (
                      <Button icon={TrashIcon} onClick={() => removePurpose(purpose)} disabled={readonly} />
                    )
                  }
                  key={`purpose-${purpose}-${index}`}
                />
              ))}
            </VerticalStack>
          </Grid.Cell>
        </Grid>
      </FlexFill>
    </HorizontalStack>
  );
};

const Spacer = styled.div`
  margin-left: var(--p-space-3);
`;

const FlexFill = styled.div`
  flex: 1;
`;

export default ReviewChecklistPurposesConditionsEditor;
