import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";

import CommentIcon from "../../../assets/icons/text-bubble.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import Icon from "../extensions/Icon";

type CompletedCustomerReviewMeasureLabelProps = {
  measure: api.Measure;
};
const MeasureComment = (props: CompletedCustomerReviewMeasureLabelProps) => {
  const { measure } = props;

  const f = useFormatMessage();

  if (isEmptyString(measure.completed_comment)) {
    return null;
  }

  return (
    <VerticalStack gap="2">
      <Text as="span" fontWeight={"semibold"}>
        {f("details.reviews.measures.comment.label")}
      </Text>
      <HorizontalStack gap="1" blockAlign="start">
        <Icon source={CommentIcon} />
        <CommentText>{measure.completed_comment}</CommentText>
      </HorizontalStack>
    </VerticalStack>
  );
};

const CommentText = styled.div`
  white-space: pre;
`;

export default MeasureComment;
