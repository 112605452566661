import React, { useMemo } from "react";
import { HorizontalStack } from "@shopify/polaris";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import api from "../../../api";
import { MAIN_COLUMN_SIZE, ROLES_AND_OWNERS_INFO_COLUMN_SIZE } from "../../../constants/tables";
import { isVitecProject } from "../../../helpers/integration.helpers";
import useCountries from "../../../hooks/useCountries";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { CustomerDetails } from "../../../types/utilities";
import { noop } from "../../../utils/util";
import ReactTable from "../../ReactTable/ReactTable";
import RoleType from "../../RoleType/RoleType";

import RoleActions from "./RoleActions";
import RoleCountries from "./RoleCountries";
import RoleName from "./RoleName";
import VitecRoleSync from "./VitecRoleSync";

export interface RolesTableProps {
  customer: CustomerDetails;
  roles: api.Role[];
  selectedRoles?: string[];
  expiredRoles?: string[];
  selectable?: boolean;
  readonly?: boolean;
  showUpdates?: boolean;
  companyType?: string;
  getRoleScreeningType?(role: api.Role): api.ScreeningType | undefined;
  onEdit(role: api.Role): void;
  onUpdate(owner: api.Role): void;
  onSelectionChange?(selectedRolesIds: string[]): void;
}

const RolesTable = (props: RolesTableProps) => {
  const {
    customer,
    roles,
    selectedRoles = [],
    expiredRoles = [],
    selectable,
    readonly,
    showUpdates,
    companyType,
    getRoleScreeningType = () => undefined,
    onEdit,
    onUpdate,
    onSelectionChange = noop,
    ...dataTableProps
  } = props;

  const { hasHighRiskCountries } = useCountries();
  const f = useFormatMessage();
  const features = useFeatures();

  const showVitecSync = features.VITEC_ROLES_SYNC && features.VITEC_PROJECTS && isVitecProject(customer);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<api.Role>();

    const columns = [
      columnHelper.accessor((row) => row.id!, {
        header: f("table.column.name"),
        enableSorting: false,
        size: MAIN_COLUMN_SIZE,
        cell: (props) => {
          const role = props.row.original;

          const showWarning = hasHighRiskCountries(role.person);

          return (
            <RoleName
              role={role}
              showWarning={showWarning}
              showUpdates={showUpdates}
              screeningType={getRoleScreeningType(role)}
              companyType={companyType}
              deleted={expiredRoles.includes(role.id!)}
            />
          );
        }
      }),
      columnHelper.accessor((row) => row.id!, {
        header: f("table.column.title"),
        enableSorting: false,
        size: ROLES_AND_OWNERS_INFO_COLUMN_SIZE,
        id: "title",
        cell: (props) => <RoleType role={props.row.original} />
      }),
      columnHelper.accessor((row) => row.id!, {
        header: f("table.column.country"),
        enableSorting: false,
        id: "countries",
        cell: (props) => <RoleCountries role={props.row.original} />
      }),
      columnHelper.accessor((row) => row.id!, {
        id: "actions",
        header: "",
        size: 1,
        enableSorting: false,
        cell: (props) => {
          const role = props.row.original;

          return (
            <HorizontalStack gap="4" blockAlign="baseline" align={"space-between"} wrap={false}>
              {showVitecSync && <VitecRoleSync customerId={customer.id} role={role} onUpdate={onUpdate} />}
              <RoleActions role={role} readonly={readonly} onEdit={onEdit} />
            </HorizontalStack>
          );
        }
      })
    ] as ColumnDef<api.Role>[];

    return columns;
  }, []);

  return (
    <ReactTable<api.Role>
      columns={columns}
      data={roles}
      selectable={selectable}
      selectedIds={selectedRoles}
      getRowId={(role) => role.id!}
      getRowProps={(role) => ({ warningBanner: hasHighRiskCountries(role.person) })}
      onSelectionChange={onSelectionChange}
      {...dataTableProps}
    />
  );
};

export default RolesTable;
