import React from "react";
import { Card } from "@shopify/polaris";

import GlobeIcon from "../../../assets/icons/globe.svg";
import MatchIcon from "../../../assets/icons/match.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import useRDCMatch from "../../hooks/useRDCMatch";
import { MatchDetailsProps } from "../../types/MatchDetails";
import AddressInfo from "../AddressInfo/AddressInfo";
import ComparisonTable from "../ComparisonTable/ComparisonTable";
import { createComparisonTableEntry } from "../ComparisonTable/ComparisonTable.helpers";
import ComparisonTableHeader from "../ComparisonTable/ComparisonTableHeader";
import Country from "../Country/Country";
import MatchAddressInfo from "../MatchAddressInfo/MatchAddressInfo";

const RDCMatchDetailsGeographicComparisonSection = (props: MatchDetailsProps) => {
  const { matchDetails } = props;

  const f = useFormatMessage();
  const {
    match,
    subjectAddresses,
    addresses,
    nationalities,
    citizenships,
    countries_of_incorporations,
    isSubjectPerson,
    hasNewInfo
  } = useRDCMatch(matchDetails);

  const subject = match.subject;

  const areAddressesOnlyCountries = match.addresses.every(
    (address) => [address.addr1, address.addr2, address.city, address.state_prov].filter(Boolean).length === 0
  );

  const rows = [
    createComparisonTableEntry(
      f("common.labels.address"),
      subjectAddresses.map((address) => <AddressInfo address={address} shortCountryName />),
      addresses,
      (address) => <MatchAddressInfo address={address} />,
      hasNewInfo,
      areAddressesOnlyCountries
    )
  ];

  if (isSubjectPerson(subject)) {
    rows.push(
      createComparisonTableEntry(
        f("common.labels.citizenship"),
        <Country country={subject.country_of_citizenship} shortName />,
        citizenships,
        (attribute) => <Country country={attribute?.value} shortName />,
        hasNewInfo
      ),
      createComparisonTableEntry(
        f("common.labels.national-id-country"),
        <Country country={subject.national_id_country} shortName />,
        nationalities,
        (attribute) => <Country country={attribute?.value} shortName />,
        hasNewInfo
      )
    );
  } else {
    createComparisonTableEntry(
      f("common.labels.country"),
      <Country country={subject.country} shortName />,
      countries_of_incorporations,
      (attribute) => <Country country={attribute?.value} shortName />,
      hasNewInfo
    );
  }

  return (
    <Card>
      <ComparisonTable
        headings={[
          <ComparisonTableHeader icon={GlobeIcon} label={f("matches.titles.geographic-affiliation")} />,
          <ComparisonTableHeader icon={MatchIcon} label={f("matches.titles.data-source")} />
        ]}
        rows={rows}
      />
    </Card>
  );
};

export default RDCMatchDetailsGeographicComparisonSection;
