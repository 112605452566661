import React, { ReactNode } from "react";
import { HorizontalStack, Text, VerticalStack } from "@shopify/polaris";

import { first, hasContent } from "../../utils/collectionUtils";
import { isEmpty } from "../../utils/util";
import NewInfoBadge from "../NewInfoBadge/NewInfoBadge";

import ComparisonCellContent from "./ComparisonCellContent";
import ComparisonTableCell from "./ComparisonTableCell";
import ComparisonTableOtherValuesCollapsible from "./ComparisonTableOtherValuesCollapsible";

export function createComparisonTableEntry<T extends { is_reviewed?: boolean }>(
  label: string,
  subjectValue: ReactNode | ReactNode[],
  matchAttributes: T[] | undefined,
  getMatchEntryValue: (matchEntry: T, first?: boolean) => ReactNode,
  hasNewInfo: (entity: { is_reviewed?: boolean }) => boolean,
  displayAsList?: boolean
) {
  const firstValue = first(matchAttributes) ? getMatchEntryValue(first(matchAttributes)!, true) : undefined;
  const showEmphasis = !isEmpty(subjectValue) && !isEmpty(firstValue);

  return [
    <ComparisonTableCell label={label}>
      {Array.isArray(subjectValue) && hasContent(subjectValue) ? (
        <HorizontalStack gap={showEmphasis ? "4" : "2"} align="start">
          {subjectValue.map((item, index) => (
            <ComparisonCellContent emphasis={showEmphasis} key={index}>
              {item}
            </ComparisonCellContent>
          ))}
        </HorizontalStack>
      ) : (
        <ComparisonCellContent emphasis={showEmphasis}>{subjectValue}</ComparisonCellContent>
      )}
    </ComparisonTableCell>,
    <ComparisonTableCell label={label}>
      {!displayAsList && (
        <VerticalStack gap="2" inlineAlign="start">
          <ComparisonCellContent emphasis={showEmphasis}>{firstValue}</ComparisonCellContent>
          <ComparisonTableOtherValuesCollapsible
            values={matchAttributes?.slice(1)}
            renderValue={(matchEntry, index) => (
              <HorizontalStack key={`${getMatchEntryValue(matchEntry) || ""}-${index}`} gap="4">
                <Text as="span" variant="bodySm" color="subdued" truncate>
                  {getMatchEntryValue(matchEntry)}
                </Text>
                {hasNewInfo && hasNewInfo(matchEntry) && <NewInfoBadge />}
              </HorizontalStack>
            )}
          />
        </VerticalStack>
      )}
      {displayAsList && (
        <HorizontalStack gap="4" align="start">
          {hasContent(matchAttributes)
            ? matchAttributes?.map((matchAttribute, index) => (
                <ComparisonCellContent key={index} emphasis={showEmphasis}>
                  {getMatchEntryValue(matchAttribute)}
                </ComparisonCellContent>
              ))
            : "---"}
        </HorizontalStack>
      )}
    </ComparisonTableCell>
  ];
}
