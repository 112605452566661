import React, { CSSProperties } from "react";
import styled from "styled-components";

export interface ChartBar {
  value: number; // in percentages
  startColor: CSSProperties["color"];
  endColor: CSSProperties["color"];
}

interface DashboardBarChartProps {
  bars: ChartBar[];
}

const DashboardBarChart = (props: DashboardBarChartProps) => {
  const { bars } = props;

  return (
    <StyledBarChart>
      {bars.map((bar, index) => (
        <StyledVerticalBar key={index} {...bar} />
      ))}
    </StyledBarChart>
  );
};

const StyledBarChart = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: var(--p-space-4);
  gap: var(--p-space-4);

  & > div {
    flex: 1;
  }
`;

const StyledVerticalBar = styled.div<ChartBar>`
  height: ${({ value }) => value}%;
  border-radius: 6rem 6rem 0 0;
  box-shadow: var(--kdk-dashboard-widget-shadow);
  background: linear-gradient(${({ startColor }) => startColor}, ${({ endColor }) => endColor});
  padding: 0 var(--p-space-2);
  transition: height var(--p-ease) var(--p-duration-500);
`;

export default DashboardBarChart;
