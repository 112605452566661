import React, { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

import { StyledResponsiveCircle } from "./StyledResponsiveCircle";

interface DashboardContentCircleProps {
  title: ReactNode;
  subtitle?: ReactNode;
  width?: CSSProperties["width"];
}

const DashboardContentCircle = (props: DashboardContentCircleProps) => {
  const { title, subtitle, width, ...rest } = props;

  return (
    <StyledResponsiveCircle $width={width} {...rest}>
      {subtitle && (
        <StyledContent>
          {title}
          {subtitle && (
            <StyledDivider className={"kdk-divider"}>
              <line x1={0} y1={0} x2={"100%"} y2={0} strokeWidth={2} stroke={"currentColor"} strokeDasharray={"6 7"} />
            </StyledDivider>
          )}
          {subtitle}
        </StyledContent>
      )}
      {!subtitle && title}
    </StyledResponsiveCircle>
  );
};

const StyledContent = styled.div`
  padding-top: var(--p-space-5);

  & > :first-child {
    font-size: 3.5rem;
    align-self: flex-end;
  }

  & > :last-child {
    align-self: flex-start;
  }
`;

const StyledDivider = styled.svg`
  width: 75%;
  height: 2px;
`;

export default DashboardContentCircle;
