import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Banner, Button, Card, HorizontalStack, Layout, Page, Text, VerticalStack } from "@shopify/polaris";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import ErrorPanel from "../../components/ErrorPanel/ErrorPanel";
import InfoBox from "../../components/InfoBox/InfoBox";
import OwnersCard from "../../components/OwnersCard/OwnersCard";
import RolesCard from "../../components/RolesCard/RolesCard";
import ScreeningEmptyState from "../../components/ScreeningEmptyState/ScreeningEmptyState";
import ScreeningSkeleton from "../../components/ScreeningSkeleton/ScreeningSkeleton";
import ScreeningTypeCard from "../../components/ScreeningTypeCard/ScreeningTypeCard";
import SectionMargin from "../../components/SectionMargin/SectionMargin";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import { getCompanyType } from "../../helpers/customer.helpers";
import { isOwnerPerson } from "../../helpers/owners.helpers";
import { isUBO } from "../../helpers/person.helpers";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetCompanyScreeningConfiguration from "../../hooks/useGetCompanyScreeningConfiguration";
import useGetOnboardingConfiguration from "../../hooks/useGetOnboardingConfiguration";
import useLanguage from "../../hooks/useLanguage";
import useNavigationToolbar from "../../hooks/useNavigationToolbar";
import { IDParams } from "../../types/params";
import ErrorPage from "../ErrorPage";

import CompanyDetailsCard from "./CompanyDetailsCard";

const OnboardingCompanyScreeningPage = () => {
  const { id: customerId } = useParams<IDParams>();

  const f = useFormatMessage();
  const features = useFeatures();
  const language = useLanguage();

  const { onboardingStepIndex, goToStep } = useNavigationToolbar();
  const queryClient = useQueryClient();

  const [owners, setOwners] = useState<api.Owner[]>([]);
  const [roles, setRoles] = useState<api.Role[]>([]);

  const [screeningType, setScreeningType] = useState<api.ScreeningType>();

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);

  const defaultSignatureConfiguration = useGetOnboardingConfiguration("company");

  // Get screening config and company customer details
  const companyCustomerDetailsQuery = useQuery([QUERIES_KEYS.COMPANY_DETAILS, customerId], () =>
    api.getCompanyCustomerDetails(customerId)
  );
  const companyScreeningConfigQuery = useGetCompanyScreeningConfiguration(customerId);

  // mutation
  const updateScreeningConfiguration = useMutation(
    () => {
      return api.updateCompanyScreeningConfiguration(customerId, {
        roles: selectedRoles,
        owners: selectedOwners,
        screening_type: screeningType!
      });
    },
    {
      onSuccess: () => {
        companyScreeningConfigQuery.invalidateQuery();
        queryClient.invalidateQueries([QUERIES_KEYS.COMPANY_ONBOARDING_DISCLOSURES, customerId]);
        goToStep(onboardingStepIndex + 1);
      }
    }
  );
  // mutation end

  useEffect(() => {
    if (companyScreeningConfigQuery.isLoading || !companyScreeningConfigQuery.data) {
      return;
    }

    const screening = companyScreeningConfigQuery.data;

    setRoles(screening.roles.map((role) => role.role));
    setSelectedRoles(screening.roles.filter((r) => r.screening_required).map((r) => r.role.id!));

    // Make all "active" (ie. not deleted) owners OR owners currently in screening config available for (de)selection
    setOwners(screening.beneficial_owners.filter((o) => !o.owner.deleted || o.screening_required).map((o) => o.owner));

    // Preselect owners currently in the screening config
    // or UBOs (and if features.SCREENING__MARK_ALL_OWNERS is enabled then all owners)
    setSelectedOwners(
      screening.beneficial_owners
        .filter(
          (beneficialOwner) =>
            isOwnerPerson(beneficialOwner.owner) &&
            (beneficialOwner.screening_required ||
              ((isUBO(beneficialOwner.owner) || features.SCREENING__MARK_ALL_OWNERS) && !beneficialOwner.owner.deleted))
        )
        .map((beneficialOwner) => beneficialOwner.owner.id!)
    );

    // if a previous screening type was saved - use it
    if (screening.screening_type === "MONITORING" || screening.screening_type === "ONE_TIME") {
      setScreeningType(screening.screening_type);
    } else if (defaultSignatureConfiguration?.screening_type) {
      setScreeningType(defaultSignatureConfiguration.screening_type);
    }
  }, [companyScreeningConfigQuery.data, defaultSignatureConfiguration]);

  if (companyScreeningConfigQuery.isError || companyCustomerDetailsQuery.isError) {
    if (companyScreeningConfigQuery.isMaxRetriesReached) {
      return <ScreeningEmptyState />;
    } else {
      return <ErrorPage error={companyScreeningConfigQuery.error || companyCustomerDetailsQuery.error} />;
    }
  }

  const displaySkeleton =
    companyScreeningConfigQuery.isLoading ||
    !companyScreeningConfigQuery.data ||
    companyCustomerDetailsQuery.isLoading ||
    !companyCustomerDetailsQuery.data ||
    !screeningType;

  if (displaySkeleton) {
    return (
      <ScreeningSkeleton
        progress={companyScreeningConfigQuery.loadingProgress}
        message={f("screening.messages.retrieving-details")}
      />
    );
  }

  const companyCustomer = companyCustomerDetailsQuery.data;
  const canHaveShareholders = companyCustomer.can_have_shareholders === false ? false : true;

  return (
    <Page title={f("screening.titles.screening")}>
      <Layout>
        <Layout.Section>
          <VerticalStack gap="4">
            <Text variant="bodyMd" as="span">
              {companyCustomer.is_manual
                ? f("screening.titles.screening.manual.description")
                : canHaveShareholders
                  ? f("screening.titles.screening.description")
                  : f("screening.titles.screening.without-shareholders.description")}
            </Text>

            <Banner title={f("screening.titles.banner.title")} status="info">
              {f("screening.titles.banner.description")}
            </Banner>

            <SectionMargin size="medium" />
            <Text variant="headingMd" as="h2">
              {f("screening.titles.customer")}
            </Text>
            <CompanyDetailsCard />
          </VerticalStack>
        </Layout.Section>
        <Layout.Section secondary />

        <Layout.Section>
          <SectionMargin size="medium" />
          <Text variant="headingMd" as="h2">
            {f("screening.titles.owners")}
          </Text>
          {companyCustomer.is_manual && (
            <Text variant="bodyMd" as="span">
              {f("screening.messages.specify-owners")}
            </Text>
          )}
        </Layout.Section>
        <Layout.Section secondary />
        <Layout.Section>
          <Card>
            <OwnersCard
              customer={companyCustomer}
              selectable
              owners={owners}
              selectedOwners={selectedOwners}
              onSelectionChange={setSelectedOwners}
              isArchiveDisabled={(ownerId: string) => selectedOwners.includes(ownerId)}
              onArchive={(archivedOwnerId) => {
                setOwners(owners.filter((owner) => owner.id !== archivedOwnerId));
                if (selectedOwners.includes(archivedOwnerId)) {
                  setSelectedOwners(selectedOwners.filter((selectedOwner) => selectedOwner !== archivedOwnerId));
                }
              }}
              onUpdate={(updatedOwner) => {
                setOwners(
                  owners.map((owner) => (owner.id === updatedOwner.id ? Object.assign(owner, updatedOwner) : owner))
                );
                if (isUBO(updatedOwner) && !selectedOwners.includes(updatedOwner.id!)) {
                  setSelectedOwners([...selectedOwners, updatedOwner.id!]);
                }
              }}
              onCreate={(createdOwner) => {
                setOwners([createdOwner].concat(owners));
                if (isUBO(createdOwner)) {
                  setSelectedOwners([...selectedOwners, createdOwner.id!]);
                }
              }}
            />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <InfoBox title={f("screening.infobox.owners.title")}>
            <p>{f("screening.infobox.owners.definition")}</p>
            <p>
              {companyCustomer.is_manual
                ? f("screening.infobox.owners.text.manual")
                : f("screening.infobox.owners.text.norwegian")}
            </p>
          </InfoBox>
        </Layout.Section>

        <Layout.Section>
          <SectionMargin size="medium" />
          <Text variant="headingMd" as="h2">
            {f("screening.titles.roles")}
          </Text>
          {companyCustomer.is_manual && (
            <Text variant="bodyMd" as="span">
              {f("screening.messages.specify-roles")}
            </Text>
          )}
        </Layout.Section>
        <Layout.Section>
          <Card>
            <RolesCard
              customer={companyCustomer}
              roles={roles}
              selectedRoles={selectedRoles}
              selectable
              companyType={getCompanyType(language, companyCustomer.company_type)}
              companyCountry={companyCustomerDetailsQuery.data?.country}
              getIsDeleteDisabled={(roleId) => selectedRoles.includes(roleId)}
              onSelectionChange={setSelectedRoles}
              onDelete={(deletedRoleId) => {
                setRoles(roles.filter((role) => role.id !== deletedRoleId));
                if (selectedRoles.includes(deletedRoleId)) {
                  setSelectedRoles(selectedRoles.filter((selectedRole) => selectedRole !== deletedRoleId));
                }
              }}
              onUpdate={(updatedRole) =>
                setRoles(roles.map((role) => (role.id === updatedRole.id ? updatedRole : role)))
              }
              onCreate={(createdRole) => setRoles([createdRole].concat(roles))}
            />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <InfoBox title={f("screening.infobox.roles.title")}>
            <p>{f("screening.infobox.roles.definition")}</p>
            <p>
              {companyCustomer.is_manual
                ? f("screening.infobox.roles.text.manual")
                : f("screening.infobox.roles.text.norwegian")}
            </p>
          </InfoBox>
        </Layout.Section>

        <Layout.Section>
          <SectionMargin size="medium" />
          <Text variant="headingMd" as="h2">
            {f("screening.titles.screening-frequency")}
          </Text>
        </Layout.Section>
        <Layout.Section>
          <VerticalStack gap="4">
            <Card>
              <Card.Section>
                <ScreeningTypeCard screeningType={screeningType} onScreeningTypeChange={setScreeningType} />
              </Card.Section>
            </Card>
            {updateScreeningConfiguration.isError && <ErrorPanel message={updateScreeningConfiguration.error} />}
            <HorizontalStack gap="4" align="end">
              {companyCustomer.is_manual && (
                <Button
                  onClick={() => goToStep(onboardingStepIndex - 1)}
                  disabled={updateScreeningConfiguration.isLoading}
                >
                  {f("button.go.back")}
                </Button>
              )}
              <Button
                primary
                disabled={updateScreeningConfiguration.isLoading || !screeningType || screeningType === "NONE"}
                loading={updateScreeningConfiguration.isLoading}
                onClick={() => updateScreeningConfiguration.mutate()}
              >
                {f("button.go.next")}
              </Button>
            </HorizontalStack>
          </VerticalStack>
        </Layout.Section>
        <Layout.Section secondary />
      </Layout>
    </Page>
  );
};

export default OnboardingCompanyScreeningPage;
