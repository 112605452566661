import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { Text } from "@shopify/polaris";
import { PrimitiveType } from "intl-messageformat";

import { MessageKey } from "../providers/LanguageProvider";

const useRichFormatMessage = () => {
  const intl = useIntl();

  // only accepts valid keys, not any string, values
  return (id: MessageKey, values?: Record<string, PrimitiveType>) =>
    intl.formatMessage<ReactNode>(
      { id },
      {
        ...values,
        bold: (str) => (
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            {str}
          </Text>
        )
      }
    );
};

export default useRichFormatMessage;
