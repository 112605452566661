import { useHistory } from "react-router-dom";

import api from "../../api";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useCustomerPath from "../../hooks/useCustomerPath";
import useFormatMessage from "../../hooks/useFormatMessage";
import useQueryData, { useCustomerDetailsQueryData } from "../../hooks/useQueryData";
import HistoryLocationState from "../../types/HistoryLocationState";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

type AssignmentArchiveConfirmationProps = {
  assignment: api.CustomerAssignmentDetails;
  onClose(): void;
};

const AssignmentArchiveConfirmation = (props: AssignmentArchiveConfirmationProps) => {
  const { assignment, onClose } = props;

  const customerId = assignment.customer_id;

  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();
  const { getCustomerPath } = useCustomerPath();

  const { updateQueryData: updateAssignmentQueryData } = useQueryData<api.CustomerAssignmentDetails>([
    QUERIES_KEYS.ASSIGNMENT_DETAILS,
    customerId,
    assignment.id
  ]);

  const updateCustomerQueryData = useCustomerDetailsQueryData({
    id: customerId,
    type: assignment.customer_type
  });

  const navigateToCustomerPage = () => {
    const customerURL = getCustomerPath(assignment.customer_id, assignment.customer_type);
    history.push(`${customerURL}#${PAGE_ANCHORS.CUSTOMER_ASSIGNMENTS}`);
  };

  const name = assignment.name;

  return (
    <ConfirmAction
      title={f("archive-assignment.confirmation-modal.title")}
      description={f("archive-assignment.confirmation-modal.description", { name })}
      apiCall={() => api.archiveCustomerAssignment(customerId, assignment.id)}
      onSuccess={() => {
        updateAssignmentQueryData((queryData) => (queryData.is_archived = true));
        updateCustomerQueryData(
          (customerData) =>
            (customerData.assignments = customerData.assignments?.map((customerAssignment) =>
              customerAssignment.id === assignment.id
                ? { ...customerAssignment, is_archived: true }
                : customerAssignment
            ))
        );
        navigateToCustomerPage();
      }}
      onNo={onClose}
      actionTitle={f("archive-assignment.confirmation-modal.labels.button.archive", { name })}
      isDestructive
    />
  );
};

export default AssignmentArchiveConfirmation;
