import React from "react";
import { Grid } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useDashboardParams from "../../hooks/useDashboardParams";

import CustomersByIndustryTypeWidget from "./CustomersByIndustryTypeWidget/CustomersByIndustryTypeWidget";
import CustomersByReviewStatusWidget from "./CustomersByReviewStatusWidget";
import CustomersByRiskWidget from "./CustomersByRiskWidget";
import CustomersByTypeWidget from "./CustomersByTypeWidget";
import CustomersMarkedAsPepWidget from "./CustomersMarkedAsPepWidget";
import CustomersMarkedAsSanctionedWidget from "./CustomersMarkedAsSanctionedWidget";
import CustomersWithPendingTasksWidget from "./CustomersWithPendingTasksWidget";

type DashboardProps = {
  metrics: api.DashboardResponse;
  loading?: boolean;
};

const Dashboard = (props: DashboardProps) => {
  const { metrics, loading } = props;

  const { customerType } = useDashboardParams();

  const {
    customers_active_count,
    customers_pep_count,
    customers_pep_count_by_customer_type,
    customers_sanctioned_count,
    customers_sanctioned_count_by_customer_type,
    customers_with_pending_tasks_count,
    customers_count_by_risk_level,
    customers_count_by_customer_type,
    customers_count_by_review_status,
    customers_count_by_industry_code
  } = metrics;

  const showIndustriesWidget = customerType !== "PERSON";

  return (
    <StyledColumnsContainer>
      <Grid columns={{ xs: 1, sm: 2, lg: 3 }}>
        <CustomersWithPendingTasksWidget
          loading={loading}
          customersWithPendingTasksCount={customers_with_pending_tasks_count}
        />
        <CustomersByRiskWidget
          values={customers_count_by_risk_level}
          customersCount={customers_active_count}
          loading={loading}
        />
        <CustomersMarkedAsPepWidget
          loading={loading}
          values={customers_pep_count_by_customer_type}
          total={customers_pep_count}
          customersCount={customers_active_count}
        />
        <CustomersByTypeWidget
          values={customers_count_by_customer_type}
          customersCount={customers_active_count}
          loading={loading}
        />
        <CustomersByReviewStatusWidget
          values={customers_count_by_review_status}
          total={customers_active_count}
          loading={loading}
        />
        <CustomersMarkedAsSanctionedWidget
          loading={loading}
          values={customers_sanctioned_count_by_customer_type}
          total={customers_sanctioned_count}
          customersCount={customers_active_count}
        />
        {showIndustriesWidget && (
          <Grid.Cell columnSpan={{ xs: 1, md: 3 }}>
            <CustomersByIndustryTypeWidget
              loading={loading}
              industries={customers_count_by_industry_code.metrics}
              categories={customers_count_by_industry_code.categories}
              customersCount={customers_count_by_customer_type.COMPANY}
            />
          </Grid.Cell>
        )}
      </Grid>
    </StyledColumnsContainer>
  );
};

const StyledColumnsContainer = styled.div`
  & > .Polaris-Grid > .Polaris-Card + .Polaris-Card {
    margin-top: 0;
  }
`;

export default Dashboard;
