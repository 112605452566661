import React from "react";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

const Warning = (props: React.HTMLAttributes<HTMLSpanElement>) => {
  const { children, ...rest } = props;

  return (
    <StyledWarningText {...rest}>
      <Text as="span" color="subdued">
        {children}
      </Text>
    </StyledWarningText>
  );
};

const StyledWarningText = styled.span`
  white-space: pre-wrap;
  font-size: var(--p-font-size-75);
  font-style: italic;
  line-height: var(--p-font-line-height-1);
`;

export default Warning;
