import React, { useState } from "react";

import useCustomerReviewApproveReject from "../../hooks/useCustomerReviewApproveReject";
import useFormatMessage from "../../hooks/useFormatMessage";
import useSnackbar from "../../hooks/useSnackbar";
import { CustomerDetails } from "../../types/utilities";
import Confirm from "../Confirm/Confirm";
import KDKTextField from "../KDKTextField/KDKTextField";

type CustomerReviewRejectConfirmProps = {
  customer: CustomerDetails;
  reviewId: string;
  onSuccess(): void;
  onCancel(): void;
};

const CustomerReviewRejectConfirm = (props: CustomerReviewRejectConfirmProps) => {
  const { customer, reviewId, onSuccess, onCancel } = props;

  const f = useFormatMessage();
  const { setSnackbar } = useSnackbar();

  const [reason, setReason] = useState("");
  const { rejectReviewMutation } = useCustomerReviewApproveReject(customer, reviewId);

  const handleReviewReject = () => {
    setReason("");
    onSuccess();
    setSnackbar(f("common.labels.rejected"));
  };

  return (
    <Confirm
      isOpen
      title={f("details.reviews.confirm.approve-reject.title")}
      onYes={() => rejectReviewMutation.mutate(reason, { onSuccess: handleReviewReject })}
      onNo={onCancel}
      actionTitle={f("details.reviews.confirm.reject")}
      cancelTitle={f("default.cancel")}
      isActionInProgress={rejectReviewMutation.isLoading}
      descriptionAlign="left"
      description={f("details.reviews.confirm.reject.description")}
      error={rejectReviewMutation.error}
    >
      <KDKTextField
        label={f("details.reviews.labels.reason")}
        value={reason}
        multiline={6}
        autoFocus
        autoComplete="on"
        disabled={rejectReviewMutation.isLoading}
        onChange={setReason}
      />
    </Confirm>
  );
};

export default CustomerReviewRejectConfirm;
