import useCustomerReview from "../../hooks/useCustomerReview";
import useFormatMessage from "../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../hooks/useFormatMultipleNames";
import useGetUserName from "../../hooks/useGetUserName";
import { Review } from "../../types/CustomerReview";
import { isEmptyString } from "../../utils/stringUtils";

const useCustomerReviewCardPendingLabel = (review: Review) => {
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();
  const { getUserDisplayName } = useGetUserName();
  const { isRequiresApproval, hasUncompletedPendingMeasures, isPending } = useCustomerReview(review);

  const approversNames = formatMultipleNames(review.approval_users?.map(getUserDisplayName));
  const approvalLabel = isEmptyString(approversNames)
    ? f("details.reviews.awaiting-approval")
    : f("details.reviews.awaiting-approval-with-names", { names: approversNames });

  const pendingMeasuresLabel = hasUncompletedPendingMeasures ? f("details.reviews.awaiting-measures") : undefined;

  let pendingLabel: string | undefined = undefined;
  if (isRequiresApproval) {
    pendingLabel = approvalLabel;
  } else if (hasUncompletedPendingMeasures) {
    pendingLabel = pendingMeasuresLabel;
  } else if (isPending) {
    pendingLabel = f("details.reviews.draft");
  }

  return {
    pendingLabel
  };
};

export default useCustomerReviewCardPendingLabel;
