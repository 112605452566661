import React from "react";
import { VerticalStack } from "@shopify/polaris";

import { CustomerDetails } from "../../../types/utilities";

import CriticalUpdatesBanner from "./CriticalUpdatesBanner";
import FollowupExpiredBanner from "./FollowupExpiredBanner";
import OwnershipUpdatesBanner from "./OwnershipUpdatesBanner";
import useCustomerDetailsBanners from "./useCustomerDetailsBanners";

interface CustomerDetailsBannerProps {
  customer: CustomerDetails;
}

const CustomerDetailsBanners = (props: CustomerDetailsBannerProps) => {
  const { customer } = props;

  const { hasCriticalUpdates, hasOwnershipUpdates, hasFollowupDateExpired } = useCustomerDetailsBanners(customer);

  return (
    <VerticalStack gap="2">
      {hasCriticalUpdates && <CriticalUpdatesBanner />}
      {hasOwnershipUpdates && <OwnershipUpdatesBanner />}
      {hasFollowupDateExpired && <FollowupExpiredBanner customer={customer} />}
    </VerticalStack>
  );
};

export default CustomerDetailsBanners;
