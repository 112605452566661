import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Navigation } from "@shopify/polaris";
import { ItemProps } from "@shopify/polaris/build/ts/latest/src/components/Navigation/components";
import styled from "styled-components";

import { PAGE_ANCHORS } from "../../../constants/page-anchors";
import { ROUTES } from "../../../constants/routes";
import usePageNavigation from "../../../hooks/usePageNavigation";
import { NavigationItem } from "../../../providers/PageNavigation";

const isActive = (anchor: string) =>
  location.hash === `#${anchor}` || (anchor === PAGE_ANCHORS.TOP_SECTION && location.hash === "");

const PageNavigationMenu = () => {
  const location = useLocation();

  const { showNavigationMenu, navigationSections, navigateInPage } = usePageNavigation();

  if (!showNavigationMenu) {
    return null;
  }

  const createItemProp = ({
    url,
    anchor,
    label,
    disabled,
    options: { count, isNew, external } = {}
  }: NavigationItem): ItemProps => ({
    url,
    onClick: anchor ? () => navigateInPage(anchor) : undefined,
    label,
    selected: anchor ? isActive(anchor) : matchPath(location.pathname, { path: url }) !== null,
    badge: count && count > 0 ? `${count}` : undefined,
    disabled,
    external,
    // in order to show new indicator in Polaris, we need to set a sub navigation item with a "new" property set to true
    subNavigationItems: isNew
      ? [
          {
            url: "",
            label: "",
            new: true
          }
        ]
      : undefined
  });

  return (
    <StyledWrapper>
      <Navigation location={ROUTES.HOME}>
        {navigationSections.map(({ title, icon, items }, index) => (
          <Navigation.Section title={title} icon={icon} items={items.map(createItemProp)} key={index} />
        ))}
      </Navigation>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  & .Polaris-Navigation {
    border-right: none;
    margin-top: calc(var(--kdk-message-offset) + var(--p-space-4));
  }

  & .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
    display: none;
  }

  & .Polaris-Navigation__SectionHeading {
    padding-left: var(--p-space-5);

    & > .Polaris-Text--root {
      font-weight: var(--p-font-weight-bold);
      font-size: revert;
      color: var(--p-text);
    }
  }

  & .Polaris-Navigation__Item {
    font-weight: var(--p-font-weight-semibold);
  }
`;

export default PageNavigationMenu;
