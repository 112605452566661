import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import api from "../api";
import { updateItem } from "../utils/collectionUtils";

import useDisclosureAssignment from "./useDisclosureAssignment";
import useGetOnboardingConfiguration from "./useGetOnboardingConfiguration";
import { useCustomerDetailsQueryData } from "./useQueryData";

const useCreatePersonDisclosure = (
  customerId: string,
  email?: string,
  assignments?: api.Assignment[],
  assignmentId?: string // the assignment id from the URL param
) => {
  const defaultSignatureConfiguration = useGetOnboardingConfiguration("person");

  const updatePersonQueryData = useCustomerDetailsQueryData({ type: "person", id: customerId });

  const updateAssignmentInPersonDetails = (assignment: api.Assignment) =>
    updatePersonQueryData((queryData) => {
      if (queryData.assignments) {
        queryData.assignments = updateItem(queryData.assignments, assignment);
      }
    });

  const [signature, setSignature] = useState<api.CreatePersonCustomerDisclosureDocumentRequest>();
  const { selectedAssignment, setSelectedAssignment, shouldUpdateAssignment, updateAssignmentMutation } =
    useDisclosureAssignment(customerId, assignmentId, assignments, updateAssignmentInPersonDetails);

  // set default signature if current signature is empty
  useEffect(() => {
    if (defaultSignatureConfiguration && !signature) {
      setSignature({
        ...defaultSignatureConfiguration.signature_settings,
        email,
        assignment_id: selectedAssignment?.id
      });
    }
  }, [defaultSignatureConfiguration, signature === undefined]);

  const createDisclosureMutation = useMutation(
    async () => {
      // if SHOW_ASSIGNMENTS_IN_DISCLOSURE_FORM is enabled and was changed - update the assignment be
      if (shouldUpdateAssignment) {
        await updateAssignmentMutation.mutateAsync();
      }

      return api.createPersonCustomerDisclosureDocument(customerId, {
        ...signature,
        assignment_id: selectedAssignment?.id
      });
    },
    {
      onSuccess: async (response) => {
        // Add the newly created disclosure document to the start of the array of customer details
        updatePersonQueryData((queryData) => queryData.disclosure_documents.unshift(response));
      }
    }
  );

  return { createDisclosureMutation, signature, setSignature, selectedAssignment, setSelectedAssignment };
};

export default useCreatePersonDisclosure;
