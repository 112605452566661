import React from "react";
import { Button, ButtonGroup } from "@shopify/polaris";

import AccessDeniedImage from "../../assets/images/access-denied.svg";
import KDKEmptyState from "../components/KDKEmptyState/KDKEmptyState";
import { ROUTES } from "../constants/routes";
import useFormatMessage from "../hooks/useFormatMessage";

const AccessDenied = () => {
  const f = useFormatMessage();

  return (
    <KDKEmptyState
      header={f("access-denied.title")}
      description={f("access-denied.description")}
      image={AccessDeniedImage}
      altImage={"Access denied"}
    >
      <ButtonGroup>
        <Button primary url={ROUTES.HOME}>
          {f("common.labels.take-me-back")}
        </Button>
      </ButtonGroup>
    </KDKEmptyState>
  );
};

export default AccessDenied;
