import React, { PropsWithChildren, ReactNode } from "react";
import { $convertFromMarkdownString, $convertToMarkdownString } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { Card, VerticalStack } from "@shopify/polaris";
import { EditorState } from "lexical";
import styled from "styled-components";

import { BASE_MARKDOWN_CONFIG, TRANSFORMERS } from "../../constants/markdown";
import { ErrorType } from "../../types/utilities";
import { isValidUrl } from "../../utils/browserUtils";

import AutoLinkPlugin from "./AutoLinkPlugin";
import MarkdownEditorToolbar from "./MarkdownEditorToolbar";
import { MarkdownStylesWrapper } from "./MarkdownStylesWrapper";
import UpdateStatePlugin from "./UpdateStatePlugin";

type MarkdownEditorProps = {
  value?: string;
  toolbarRightSection?: ReactNode;
  disabled?: boolean;
  onChange(value: string): void;
};

const MarkdownEditor = (props: PropsWithChildren<MarkdownEditorProps>) => {
  const { value = "", toolbarRightSection, disabled, onChange, children } = props;

  function handleChange(editorState: EditorState) {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      onChange(markdown);
    });
  }

  function onError(error: ErrorType) {
    console.error(error);
  }

  const initialConfig: InitialConfigType = {
    ...BASE_MARKDOWN_CONFIG,
    namespace: "markdown-editor",
    editable: !disabled,
    editorState: () => $convertFromMarkdownString(value, TRANSFORMERS),
    onError
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Card.Section>
        <MarkdownEditorToolbar disabled={disabled}>{toolbarRightSection}</MarkdownEditorToolbar>
      </Card.Section>
      <Card.Section subdued>
        <StyledEditor>
          <RichTextPlugin
            contentEditable={
              <VerticalStack gap="4">
                {children}
                <ContentEditable className={"editor-inner"} />
              </VerticalStack>
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={handleChange} />
          <UpdateStatePlugin value={value} />
          <AutoFocusPlugin />
          <TabIndentationPlugin />
          <ListPlugin />
          <LinkPlugin validateUrl={isValidUrl} />
          <AutoLinkPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </StyledEditor>
      </Card.Section>
    </LexicalComposer>
  );
};

const StyledEditor = styled(MarkdownStylesWrapper)`
  & .editor-inner {
    background-color: var(--p-surface);
    min-height: 20rem;
    font-size: var(--p-font-size-100);
    font-weight: var(--p-font-weight-regular);
    line-height: var(--p-font-line-height-2);
    font-family: var(--p-font-family-sans);
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    padding: var(--p-space-2);
    border: var(--p-border-base);
    border-radius: var(--p-border-radius-1);
  }
`;

export default MarkdownEditor;
