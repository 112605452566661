import { HorizontalStack, VerticalStack } from "@shopify/polaris";
import { UseMutationResult } from "@tanstack/react-query";

import useCustomerReviewDelete from "../../hooks/useCustomerReviewDelete";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { Review } from "../../types/CustomerReview";
import { CustomerDetails } from "../../types/utilities";
import Confirm from "../Confirm/Confirm";
import ConfirmAction from "../ConfirmAction/ConfirmAction";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
import Button from "../extensions/Button";

export type CustomerReviewEditToolbarProps = {
  customer: CustomerDetails;
  reviewId: string;
  isInvalidReview?: boolean;
  readonly?: boolean;
  canBeDeleted?: boolean;
  isDirty?: boolean;
  onBackNavigation(): void;
  saveReviewMutation: UseMutationResult<Review, unknown, void>;
  completeReviewMutation: UseMutationResult<Review, unknown, void>;
};

const CustomerReviewEditToolbar = (props: CustomerReviewEditToolbarProps) => {
  const {
    customer,
    reviewId,
    isInvalidReview,
    readonly,
    canBeDeleted,
    isDirty,
    onBackNavigation,
    saveReviewMutation,
    completeReviewMutation
  } = props;

  const f = useFormatMessage();
  const features = useFeatures();

  const [isDeleteConfirmOpen, toggleDeleteConfirm, closeDeleteConfirm] = useOpenClose();
  const [isCancelConfirmOpen, toggleCancelConfirm, closeCancelConfirm] = useOpenClose();
  const deleteCustomerReviewMutation = useCustomerReviewDelete(customer, reviewId);

  const isLoading = saveReviewMutation.isLoading || completeReviewMutation.isLoading;
  const isError = saveReviewMutation.isError || completeReviewMutation.isError || deleteCustomerReviewMutation.isError;
  const error = saveReviewMutation.error || completeReviewMutation.error || deleteCustomerReviewMutation.error;
  const disabled = readonly || isLoading;

  return (
    <VerticalStack gap="2">
      <HorizontalStack align={"space-between"} blockAlign="center">
        <HorizontalStack gap="2">
          {canBeDeleted && (
            <Button disabled={disabled} onClick={toggleDeleteConfirm}>
              {f("details.reviews.labels.delete")}
            </Button>
          )}
          <Button onClick={isDirty && !readonly ? toggleCancelConfirm : onBackNavigation} disabled={isLoading}>
            {f("default.cancel")}
          </Button>
        </HorizontalStack>
        <HorizontalStack gap="2">
          {features.REVIEW__SAVE_AND_CONTINUE && (
            <Button onClick={saveReviewMutation.mutate} disabled={disabled} loading={saveReviewMutation.isLoading}>
              {f("details.reviews.labels.save-and-continue")}
            </Button>
          )}
          <Button
            onClick={completeReviewMutation.mutate}
            primary
            disabled={disabled || isInvalidReview}
            loading={completeReviewMutation.isLoading}
          >
            {f("common.labels.complete")}
          </Button>

          {isDeleteConfirmOpen && (
            <ConfirmAction
              apiCall={() => deleteCustomerReviewMutation.mutateAsync()}
              title={f("details.reviews.confirm.delete.title")}
              description={f("details.reviews.confirm.delete.description")}
              onNo={closeDeleteConfirm}
              onSuccess={onBackNavigation}
              cancelTitle={f("default.no")}
              actionTitle={f("details.reviews.labels.confirm-delete")}
            />
          )}

          {isCancelConfirmOpen && (
            <Confirm
              title={f("details.reviews.confirm.cancel.title")}
              description={f("details.reviews.confirm.cancel.description")}
              onNo={closeCancelConfirm}
              onYes={onBackNavigation}
              cancelTitle={f("default.no")}
              actionTitle={f("details.reviews.labels.cancel")}
            />
          )}
        </HorizontalStack>
      </HorizontalStack>
      {isError && <ErrorPanel message={error} />}
    </VerticalStack>
  );
};

export default CustomerReviewEditToolbar;
