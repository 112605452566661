import { ReactNode } from "react";
import { Collapsible, VerticalStack } from "@shopify/polaris";

import useOpenClose from "../../hooks/useOpenClose";
import Button from "../extensions/Button";

type ComparisonTableOtherValuesCollapsibleProps<T> = {
  values?: T[];
  label?: ReactNode;
  renderValue(value: T, index: number): ReactNode;
};

function ComparisonTableOtherValuesCollapsible<T>(props: ComparisonTableOtherValuesCollapsibleProps<T>) {
  const { values, label, renderValue } = props;

  const [isOpen, toggleOpen] = useOpenClose();

  if (!values || values.length === 0) {
    return null;
  }

  if (values.length === 1) {
    return renderValue(values[0], 0);
  }

  return (
    <VerticalStack gap="1">
      <Button
        plain
        size="micro"
        monochrome
        removeUnderline
        textAlign="left"
        disclosure={isOpen ? "up" : "down"}
        onClick={toggleOpen}
      >
        {label || renderValue(values[0], 0)}
      </Button>
      <Collapsible id="" open={isOpen}>
        <VerticalStack gap="1">{values.slice(1).map(renderValue)}</VerticalStack>
      </Collapsible>
    </VerticalStack>
  );
}

export default ComparisonTableOtherValuesCollapsible;
