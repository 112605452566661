import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { QUERIES_KEYS } from "../../constants/queries-keys";
import useAttachmentsAPI from "../../hooks/useAttachmentsAPI";
import { noop } from "../../utils/util";
import AttachmentsTable from "../AttachmentsSection/AttachmentsTable";

type OnboardingCompanyAttachmentsInfoProps = {
  customer: api.CompanyCustomerDetailResponse;
};

const OnboardingCompanyAttachmentsInfo = (props: OnboardingCompanyAttachmentsInfoProps) => {
  const { customer } = props;

  const { entityId, getAttachments } = useAttachmentsAPI(customer);
  const attachmentsQueryKey = [QUERIES_KEYS.ATTACHMENTS, entityId];
  const { isError, data } = useQuery(attachmentsQueryKey, () => getAttachments(entityId));

  const attachments = data?.attachment_list?.filter((attachment) => !attachment.assignment_is_archived) || [];

  if (isError) {
    return null;
  }

  return (
    <AttachmentsTable
      entity={customer}
      attachments={attachments}
      uploadingFilesCount={0}
      hideSignatures
      onAttachmentSelected={noop}
    />
  );
};

export default OnboardingCompanyAttachmentsInfo;
