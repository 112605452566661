import React from "react";
import { VerticalStack } from "@shopify/polaris";

import { isActiveAttachment } from "../../helpers/attachments";
import useFormatMessage from "../../hooks/useFormatMessage";
import { CustomerDetails } from "../../types/utilities";
import AttachmentsSection from "../AttachmentsSection/AttachmentsSection";

type CustomerAttachmentsSectionProps = {
  customer: CustomerDetails;
  uploadingFilesCount: number;
  readonly?: boolean;
};

const CustomerAttachmentsSection = (props: CustomerAttachmentsSectionProps) => {
  const { customer, uploadingFilesCount, readonly } = props;

  const f = useFormatMessage();

  const fetchSuggestedSigners = () => Promise.resolve<CustomerDetails[]>([customer]);

  return (
    <VerticalStack gap="2">
      <AttachmentsSection
        entity={customer}
        fetchSuggestedSigners={fetchSuggestedSigners}
        readonly={readonly}
        hideTitle
        uploadingFilesCount={uploadingFilesCount}
        filterAttachment={isActiveAttachment}
        getFilterLabels={(filteredAttachmentsCount) => ({
          hide: f("customer-assignments.list.filter.hide-archived-assignments", { count: filteredAttachmentsCount }),
          show: f("customer-assignments.list.filter.show-archived-assignments", { count: filteredAttachmentsCount })
        })}
      />
    </VerticalStack>
  );
};

export default CustomerAttachmentsSection;
