import React, { useState } from "react";
import { Button, Card, HorizontalStack, TextField, VerticalStack } from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";

import api from "../../api";
import { COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS } from "../../constants/integrations";
import useErrorParser from "../../hooks/useErrorParser";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useProjectPermissions from "../../hooks/useProjectPermissions";
import { isEmptyString } from "../../utils/stringUtils";
import AssociatedProjectSection from "../AssociatedProjectSection/AssociatedProjectSection";
import ErrorPanel from "../ErrorPanel/ErrorPanel";

import CompanyForm, { CompanyFormValues } from "./CompanyForm";

type KeysEnum<T> = { [P in keyof Required<T>]: boolean };

const CompanyCustomerFormFields: KeysEnum<api.CreateCompanyCustomerRequest> = {
  name: true,
  country: true,
  national_id: true,
  business_address: true,
  external_id: true,
  project_id: false,
  responsible_user_email: false
};

/**
 * Returns true if it is a field that can be manually defined in
 * CompanyCustomerForm, else false
 */
export const isFieldInCompanyCustomerForm = (fieldName: string) => {
  return fieldName in CompanyCustomerFormFields;
};

interface CompanyCustomerFormProps {
  companyCustomer?: api.CompanyCustomerDetailResponse;
  onAfterSubmit: (company: api.CompanyCustomer) => void;
  submitLabel?: string;
  projectId?: string;
  projectName?: string;
  onCancel?(): void;
}

const CompanyCustomerForm = (props: CompanyCustomerFormProps) => {
  const { companyCustomer, onAfterSubmit, submitLabel, projectId, projectName, onCancel } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const { isProjectsEnabled } = useProjectPermissions();

  const parseError = useErrorParser("company");
  const [companyFormValues, setCompanyFormValues] = useState<CompanyFormValues>();
  const [externalId, setExternalId] = useState(companyCustomer?.external_id || "");

  // If companyCustomer was provided, we're updating an existing company customer
  // Else, we're creating a new company customer
  const saveCompanyCustomerMutation = useMutation<
    api.CompanyCustomer | api.CompanyCustomerDetailResponse,
    unknown,
    api.CreateCompanyCustomerRequest
  >(
    (payload) =>
      companyCustomer
        ? api.updateCompanyCustomer(companyCustomer.id, payload)
        : api.createCompanyCustomer({ ...payload, project_id: projectId }),
    {
      onSuccess: (response) => {
        onAfterSubmit(response);
      }
    }
  );

  const { generalError, fieldsErrorsMap } = parseError(saveCompanyCustomerMutation.error);

  /**
   * Form/payload is valid if the name, national_id, country and business_address is set
   * Additionally, country must be a country we haven't integrated with
   */
  const isValid = Boolean(
    companyFormValues?.name &&
      companyFormValues?.nationalId &&
      companyFormValues?.country &&
      !isEmptyString(companyFormValues?.address.country)
  );

  /**
   * Create or update company customer, then call the onAfterSubmit-callback
   */
  const handleSubmit = () => {
    if (!isValid || !companyFormValues) return;

    const payload: api.CreateCompanyCustomerRequest = {
      name: companyFormValues.name,
      national_id: companyFormValues.nationalId!,
      country: companyFormValues.country!,
      business_address: companyFormValues.address as api.Address,
      external_id: externalId
    };

    saveCompanyCustomerMutation.mutate(payload);
  };

  return (
    <Card>
      <CompanyForm
        company={companyCustomer}
        excludedCountries={COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS}
        onChange={(v) => setCompanyFormValues(v)}
        disabled={saveCompanyCustomerMutation.isLoading}
        nationalIdError={fieldsErrorsMap.get("national_id")}
      />
      {isProjectsEnabled && projectId && projectName && <AssociatedProjectSection projectName={projectName} />}
      {features?.EXTERNAL_ID && (
        <Card.Section>
          <HorizontalStack>
            <TextField
              value={externalId}
              disabled={saveCompanyCustomerMutation.isLoading}
              label={f("company-form.labels.external-id")}
              placeholder={f("company-form.labels.external-id")}
              onChange={(v) => setExternalId(v)}
              maxLength={50}
              error={fieldsErrorsMap.get("external_id")}
              autoComplete="off"
            />
          </HorizontalStack>
        </Card.Section>
      )}
      <Card.Section>
        <VerticalStack gap="4">
          {generalError && <ErrorPanel message={generalError} />}
          <HorizontalStack gap="4" align="end">
            {onCancel && (
              <Button onClick={onCancel} disabled={saveCompanyCustomerMutation.isLoading}>
                {f("default.cancel")}
              </Button>
            )}
            <Button primary loading={saveCompanyCustomerMutation.isLoading} disabled={!isValid} onClick={handleSubmit}>
              {submitLabel || f("default.next")}
            </Button>
          </HorizontalStack>
        </VerticalStack>
      </Card.Section>
    </Card>
  );
};

export default CompanyCustomerForm;
