import React from "react";
import { EmptySearchResult } from "@shopify/polaris";

import api from "../../../api";
import { QUERIES_KEYS } from "../../../constants/queries-keys";
import useFormatMessage from "../../../hooks/useFormatMessage";
import EntitiesResourceList, { EntitiesResourceListProps } from "../../EntitiesResourceList/EntitiesResourceList";
import ProjectName from "../../ProjectName/ProjectName";

type ProjectsResourceListProps = Pick<
  EntitiesResourceListProps<api.ProjectListItem>,
  "selectedIds" | "page" | "resetPage" | "onSelectionChange"
>;

const ProjectsResourceList = (props: ProjectsResourceListProps) => {
  const { ...resourceListProps } = props;

  const f = useFormatMessage();

  return (
    <EntitiesResourceList<api.ProjectListItem>
      getEntities={(searchParams) =>
        // exclude Vitec projects when adding customers to existing projects
        // since customers are added to these projects from Vitec
        api.getProjects({ ...searchParams, status: "ACTIVE", isVitec: "0" }).then((response) => response.projects)
      }
      queryKey={QUERIES_KEYS.PROJECTS}
      queryPlaceholder={f("projects.modal.filters.search.hint")}
      renderItem={(projectListItem) => <ProjectName project={projectListItem} />}
      emptySearchState={
        <EmptySearchResult
          title={f("projects.modal.empty.search.title")}
          description={f("projects.modal.empty.search.description")}
          withIllustration
        />
      }
      {...resourceListProps}
    />
  );
};

export default ProjectsResourceList;
