import React, { forwardRef, PropsWithChildren } from "react";
import styled from "styled-components";

import ChevronRightIcon from "../../../assets/icons/link-chevron-right.svg";
import Icon from "../../components/extensions/Icon";

interface DashboardNavigationProps {
  highlight?: boolean;
  spacing?: "medium" | "large";
  hideCaret?: boolean;
  onClick?(): void;
}

const DashboardNavigation = forwardRef<HTMLButtonElement, PropsWithChildren<DashboardNavigationProps>>((props, ref) => {
  const { highlight, spacing = "large", hideCaret, onClick, children, ...rest } = props;

  const showCaret = !hideCaret && onClick !== undefined;

  return (
    <StyledDashboardNavigation $spacing={spacing} $highlight={highlight} onClick={onClick} ref={ref} {...rest}>
      <article>{children}</article>
      {showCaret && <Icon source={ChevronRightIcon} />}
    </StyledDashboardNavigation>
  );
});

const StyledDashboardNavigation = styled.button<{
  $highlight?: boolean;
  $spacing: DashboardNavigationProps["spacing"];
}>`
  --kdk-navigation-spacing: ${({ $spacing }) => ($spacing === "medium" ? "var(--p-space-2)" : "var(--p-space-4)")};
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  height: 100%;
  width: 100%;

  padding: var(--kdk-navigation-spacing) calc(var(--kdk-navigation-spacing) / 2) var(--kdk-navigation-spacing)
    var(--kdk-navigation-spacing);

  text-align: left;
  border: none;
  transition: background-color var(--p-motion-duration-100);
  background-color: ${({ $highlight }) => ($highlight ? "var(--p-surface-highlight-subdued)" : "inherit")};
  cursor: ${({ onClick }) => (onClick ? "pointer" : undefined)};

  &:hover {
    background-color: ${({ $highlight }) =>
      $highlight ? "var(--p-surface-highlight-subdued-hovered)" : "var(--p-surface-hovered)"};
  }

  &:active {
    background-color: ${({ $highlight }) =>
      $highlight ? "var(--p-surface-highlight-subdued-pressed)" : "var(--p-surface-pressed)"};
  }
`;

export default DashboardNavigation;
