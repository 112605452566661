import React from "react";

import api from "../../api";
import Tree from "../Tree/Tree";

import RelatedSearchResult from "./RelatedSearchResult";
import useRelatedSearchResults from "./useRelatedSearchResults";

interface RelatedSearchResultsProps {
  searchResults: (api.RelatedRole | api.RelatedOwner)[];
  searchTerm?: string;
}

const RelatedSearchResults = (props: RelatedSearchResultsProps) => {
  const { searchResults, searchTerm } = props;

  const { searchResultsToDisplay } = useRelatedSearchResults(searchResults);

  return (
    <Tree vOffset={8}>
      {searchResultsToDisplay.map((relatedSearchResult) => (
        <Tree.Item key={relatedSearchResult.id} disabled={relatedSearchResult.archived}>
          <RelatedSearchResult searchResult={relatedSearchResult} searchTerm={searchTerm} />
        </Tree.Item>
      ))}
    </Tree>
  );
};

export default RelatedSearchResults;
